import Close from "@mui/icons-material/Close";
import React from "react";
import { useState } from "react";
import {
  useAddUnitOfMeasurementMutation,
  useGetProjectsQuery,
  useGetUsersQuery,
} from "../../../features/SCMApi";
import "./modal.css";

export default function AddUnitofMeasurement(props) {
  const [UnitOfMeasuremente, res] = useAddUnitOfMeasurementMutation();
  const [name, setName] = useState(null);
  const [is_countable, setCountable] = useState(false);
  const [description, setDescription] = useState(null);
  let toolType = {
    name,
    is_countable,
    description,
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    UnitOfMeasuremente(toolType)
      .unwrap()
      .then((response) => {
        
        props.modal(false);
        props.setMessages("Payment Term created successfully");
      })
      .then((error) => {
       
      });
  };
  function HandleCancel() {
    props.modal(false);
  }
  const CheckBoxHandel = () => {
    setCountable(true);
  };
  const { data: response } = useGetProjectsQuery();
  const { data: users } = useGetUsersQuery();

  return (
    <div className="pi-modal" onClick={HandleCancel}>
      <div className="pi-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="pi-modal-header">
          <div className="d-flex justify-content-between">
            <h5>Add Unit of Measurement</h5>
            <Close onClick={HandleCancel} />
          </div>
        </div>
        <hr />
        <div className="pi-modal-content">
          <form className="pi-form" onSubmit={HandleSubmit}>
            <div className="d-grid gap-4">
              <div className="d-flex gap-0 align-items-baseline justify-content-center mt-2">
                <label className="d-flex gap-2 ">
                  <input
                    type="checkbox"
                    className="checkmark"
                    onChange={CheckBoxHandel}
                  />
                  <span className="h6">is Countable</span>
                </label>
              </div>
              <div className="d-flex gap-2 align-items-baseline">
                <div className="supplier-input-label">Name</div>
                <input
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  className={`stock-text-input`}
                  required
                />
              </div>

              <div className="d-flex gap-0 align-items-baseline">
                <div className="supplier-input-label">Description</div>
                <textarea
                  cols="30"
                  rows="5"
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="pi-modal-footer">
              <button onClick={HandleCancel}>Cancel</button>
              <button type="submit">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
