import React from "react";
import Grid from "@mui/material/Grid";

const RmaDetailExportContainer = (props) => {
  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={3.5}
        xl={3.5}
        marginTop={{
          xs: "20px",
          sm: "0px",
          md: "0px",
          lg: "20px",
          xl: "20px",
        }}
        marginRight={{
          xs: "16px",
          sm: "16px",
          md: "5px",
          lg: "0px",
          xl: "0px",
        }}
        marginLeft={{ xs: "16px", sm: "16px", md: "0px" }}
      >
        {props?.children}
      </Grid>
    </>
  );
};

export default RmaDetailExportContainer;
