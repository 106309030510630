import React from "react";
import { NavLink } from "react-router-dom";
import Supplier from "../../assets/SVG/supply.png";
import Manufacturer from "../../assets/SVG/manufacturing.png";
import Mapping from "../../assets/SVG/mapping.png";
import Freight from "../../assets/SVG/freightForwarder.png";
import SupplierIndex from "../../pages/Supplier";
import { TbReport } from "react-icons/tb";

export default function SupplierMenu() {
  const icons = {
    fontSize: "70px",
    color: "#186569",
    textAlign: "center",
  };

  return (
    <SupplierIndex>
      <div className="supplier-menu-container">
        <NavLink to={"/smm/suppliers"} className="supplier-menu-card">
          <div className="text-center">
            <img
              src={Supplier}
              style={{ width: 80, height: 80, borderRadius: "0px" }}
            />
          </div>
          <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
            Suppliers
          </span>
        </NavLink>
        <NavLink to={"/smm/manufacturers"} className="supplier-menu-card">
          <div className="text-center">
            <img
              src={Manufacturer}
              style={{ width: 80, height: 80, borderRadius: "0px" }}
            />
          </div>
          <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
            Manufacturers
          </span>
        </NavLink>
        <NavLink to={"/smm/mapping"} className="supplier-menu-card">
          <div className="text-center">
            <img
              src={Mapping}
              style={{ width: 80, height: 80, borderRadius: "0px" }}
            />
          </div>
          <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
            SPMS Mapping
          </span>
        </NavLink>
        <NavLink to={"/smm/freightforwarder"} className="supplier-menu-card">
          <div className="text-center">
            <img
              src={Freight}
              style={{ width: 80, height: 80, borderRadius: "0px" }}
            />
          </div>
          <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
            Freight Forwarders
          </span>
        </NavLink>
        <NavLink to={"/smm/reports"} className="supplier-menu-card">
          <div className="text-center">
            <TbReport style={icons} />
          </div>
          <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
            Reports
          </span>
        </NavLink>
      </div>
    </SupplierIndex>
  );
}
