import React from "react";
import div from "../../../components/Payment/ForexApproval/PaymentSideBar";
import { useGetPrfBudgetQuery } from "../../../features/SCMApi";
import { date } from "../../../components/dateFormator";

export default function CashinFlow({ data }) {

  const sampleData = {
    project1: [
      {
        b_no: "123",
        a_no: "456",
        a_description: "Operating Expenses",
        m_budget: "Milestone A",
        expected_date: "2024-01-20",
        b_holder: "John Doe",
        b_usd: 5000,
        b_etb: 150000,
      },
      {
        b_no: "124",
        a_no: "457",
        a_description: "Capital Expenditure",
        m_budget: "Milestone B",
        expected_date: "2024-01-25",
        b_holder: "Jane Smith",
        b_usd: 8000,
        b_etb: 0,
      },
      {
        b_no: "124",
        a_no: "457",
        a_description: "Capital Expenditure",
        m_budget: "Milestone B",
        expected_date: "2024-01-25",
        b_holder: "Jane Smith",
        b_usd: 8000,
        b_etb: 0,
      },
      {
        b_no: "124",
        a_no: "457",
        a_description: "Capital Expenditure",
        m_budget: "Milestone B",
        expected_date: "2024-01-25",
        b_holder: "Jane Smith",
        b_usd: 8000,
        b_etb: 0,
      },
    ],
    project2: [
      {
        b_no: "123",
        a_no: "456",
        a_description: "Operating Expenses",
        m_budget: "Milestone A",
        expected_date: "2024-01-20",
        b_holder: "John Doe",
        b_usd: 5000,
        b_etb: 150000,
      },
      {
        b_no: "124",
        a_no: "457",
        a_description: "Capital Expenditure",
        m_budget: "Milestone B",
        expected_date: "2024-01-25",
        b_holder: "Jane Smith",
        b_usd: 8000,
        b_etb: 240000,
      },
      {
        b_no: "124",
        a_no: "457",
        a_description: "Capital Expenditure",
        m_budget: "Milestone B",
        expected_date: "2024-01-25",
        b_holder: "Jane Smith",
        b_usd: 8000,
        b_etb: 240000,
      },
      {
        b_no: "124",
        a_no: "457",
        a_description: "Capital Expenditure",
        m_budget: "Milestone B",
        expected_date: "2024-01-25",
        b_holder: "Jane Smith",
        b_usd: 8000,
        b_etb: 240000,
      },
    ],
    project3: [
      {
        b_no: "123",
        a_no: "456",
        a_description: "Operating Expenses",
        m_budget: "Milestone A",
        expected_date: "2024-01-20",
        b_holder: "John Doe",
        b_usd: 5000,
        b_etb: 150000,
      },
      {
        b_no: "124",
        a_no: "457",
        a_description: "Capital Expenditure",
        m_budget: "Milestone B",
        expected_date: "2024-01-25",
        b_holder: "Jane Smith",
        b_usd: 8000,
        b_etb: 240000,
      },
      {
        b_no: "124",
        a_no: "457",
        a_description: "Capital Expenditure",
        m_budget: "Milestone B",
        expected_date: "2024-01-25",
        b_holder: "Jane Smith",
        b_usd: 8000,
        b_etb: 240000,
      },
      {
        b_no: "124",
        a_no: "457",
        a_description: "Capital Expenditure",
        m_budget: "Milestone B",
        expected_date: "2024-01-25",
        b_holder: "Jane Smith",
        b_usd: 8000,
        b_etb: 240000,
      },
    ],
  };
  const projectTotals = Object.keys(data ? data : {}).map((projectName) => {
    const projectData = data[projectName];

    const totalUSD = projectData.reduce(
      (acc, data) => (acc ? acc : 0 + data?.ci_value ? data?.ci_value : 0),
      0
    );
    const totalETB = projectData.reduce(
      (acc, data) =>
        Number(acc ? acc : 0 + data?.planned_amount ? data?.planned_amount : 0),
      0
    );

    return {
      projectName,
      totalUSD,
      totalETB,
    };
  });
  let totalBudgetUSD = projectTotals.reduce(
    (acc, data) => (acc ? acc : 0 + data.totalUSD),
    0
  );
  let totalBudgetETB = projectTotals.reduce(
    (acc, data) => (acc ? acc : 0 + data.totalETB),
    0
  );

  return (
    <div title={"Budget"} name={"Budget"} navlink={`/payment`}>
      <div className="card mb-3" style={{ width: "18rem" }}>
        <div
          style={{
            backgroundColor: "#064b4f",
            color: "#fff",
            letterSpacing: "0.105em",
            fontWeight: "bold",
            fontSize: "14px",
            padding: "5px",
          }}
        >
          Total Budget cost
        </div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item prg">
            {Number(totalBudgetUSD)?.toLocaleString()} in USD
          </li>
          <li className="list-group-item prg">
            {Number(totalBudgetETB)?.toLocaleString()} in ETB
          </li>
        </ul>
      </div>
      <table
        className="table table-bordered"
        style={{ borderCollapse: "collapse", width: "100%" }}
      >
        <thead
          style={{
            fontSize: "10px",
            backgroundColor: "#000",
            letterSpacing: "0.105em",
            fontWeight: "400",
            color: "#fff ",
          }}
        >
          <tr>
            <th>BUDGET NO.</th>
            <th>ACCOUNT NO.</th>
            <th>ACCOUNT DESCRIPTION</th>
            <th>MILESTONE LINKED TO THE BUDGET</th>
            <th>EXPECTED DATE OF COLLECTION WITHIN THE MONTH</th>
            <th>BUDGET HOLDER</th>
            <th>BUDGET (USD)</th>
            <th>BUDGET (ETB)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              colSpan="8"
              style={{
                backgroundColor: "#fff",
                color: "black",
                letterSpacing: "0.105em",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              Cash in Flow
            </td>
          </tr>
          {data ? (
            <>
              {Object?.keys(data ? data : [])?.map((projectName, index) => (
                <React.Fragment key={projectName}>
                  <tr>
                    <td
                      colSpan="8"
                      style={{
                        backgroundColor: "#064b4f",
                        color: "#fff",
                        letterSpacing: "0.105em",
                        fontWeight: "bold",
                        fontSize: "14px",
                      }}
                    >
                      {projectName}
                    </td>
                  </tr>
                  {data[projectName].map((data, index) => (
                    <tr
                      className="text-start"
                      key={`${projectName}-${index}`}
                      style={{
                        backgroundColor: "#fff",
                        color: "#000",
                        letterSpacing: "0.105em",
                        fontWeight: "400",
                        fontSize: "14px",
                        border: "1px solid #e2e7f0",
                      }}
                    >
                      <td>{`IE-${projectName}-00${index}`}</td>
                      <td>{data.a_no}</td>
                      <td>{data.a_description}</td>
                      <td>
                        {data.milestone ? `Milestone ${data.milestone}` : "-"}
                      </td>
                      <td>
                        {date(
                          data.shipment_document_colletion_date
                            ? data?.shipment_document_colletion_date
                            : data?.planed_collection_date
                        )}
                      </td>
                      <td>
                        {data?.collection_project
                          ? data.collection_project[0]?.project_participants?.find(
                              (items) =>
                                items?.pivot?.role_id ==
                                "96f8c153-79d5-40d1-b0b7-7fd70921f02e"
                            )?.name
                          : "-"}
                      </td>
                      <td>
                        {Number(
                          data.ci_value ? data.ci_value : 0
                        )?.toLocaleString()}
                      </td>
                      <td>
                        {Number(
                          data.planned_amount ? data.planned_amount : 0
                        )?.toLocaleString()}
                      </td>
                    </tr>
                  ))}
                  <tr
                    style={{
                      backgroundColor: "#064b4f",
                      color: "#fff",
                      letterSpacing: "0.105em",
                      fontWeight: "bolder",
                      fontSize: "14px",
                    }}
                  >
                    <td style={{ textAlign: "end" }} colSpan="6">
                      Total cost of {projectName}{" "}
                    </td>
                    <td>
                      {Number(projectTotals[index]?.totalUSD)?.toLocaleString()}
                    </td>
                    <td>
                      {Number(projectTotals[index]?.totalETB)?.toLocaleString()}
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </>
          ) : (
            <td
              colSpan="8"
              style={{
                backgroundColor: "#fff",
                color: "black",
                letterSpacing: "0.105em",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              <div className="d-flex gap-3">
                <div
                  className="spinner-border"
                  style={{ color: "#064b4f" }}
                  role="status"
                ></div>
                <span className="visually">Wait a few second</span>
              </div>
            </td>
          )}
        </tbody>
      </table>
    </div>
  );
}
