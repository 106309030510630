import { Alert, Box } from "@mui/material";

export default function ErrorMessage(props) {
  return (
    <Box
      sx={{
        width: "100%",
        color: "#FFF",
        marginTop: props?.style?.marginTop,
        marginBottom: props?.style?.marginBottom,
      }}
    >
      <Alert
        sx={{
          mb: props?.style?.mb ? props?.style?.mb : 2,
          mt: props?.style?.mt ? props?.style?.mt : 2,
          width: props?.style?.width,
          marginLeft: props?.style?.marginLeft,
          marginRight: props?.style?.marginLeft,
          backgroundColor: props?.style?.backgroundColor,
          height: props?.style?.height,
          "& .MuiAlert-icon": {
            fontSize: 25,
            color: "#FF5252",
          },
        }}
        severity="error"
      >
        <span className="success_messages_text_style">{props?.Message}</span>
      </Alert>
    </Box>
  );
}
