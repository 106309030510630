import React from "react";
import { Workbook } from "exceljs";
import saveAs from "file-saver";

import { Tooltip } from "@mui/material";

const AllGuaranteeReport = ({ data, mapped }) => {
  let summary = [
    {
      name: "",
      limit: "",
      amount: 0,
      balance: 0,
    },
  ];
  function amount(numbers) {
    let sum = 0;
    for (let i = 0; i < numbers.length; i++) {
      sum += numbers[i];
    }
    return sum;
  }
  let finalSummary = Object.keys(data == undefined ? [] : data).map(
    (key, index) =>
      (summary[index] = {
        name: `Summary of ${key}`,
        balance: data[key][0]?.balance,
        amount: amount(data[key]?.map((items) => items?.amount)),
        cost: amount(data[key]?.map((items) => items?.cost)),
      })
  );

 

  

  let count = 0;
  const downloadExcel = async () => {
    // Create a new workbook and set the default sheet
    const workbook = new Workbook();
    const worksheet = data?.map((items, index) =>
      workbook.addWorksheet(data[index][0]?.guarante_type?.guarantee_type_name)
    );
    // const worksheet = workbook.addWorksheet("Summary");
    const titleCell = data?.map((items, index) =>
      worksheet[index]?.getCell("A1")
    );

    // for all delinquent title
    titleCell.alignment = { vertical: "middle", horizontal: "center" };
    titleCell.border = {
      top: { style: "thin", color: { argb: "000000" } },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
    };
    titleCell.font = { size: 12, name: "Times New Roman", bold: true };
    data?.map(
      (items, index) =>
        (titleCell[index].value =
          data[index][0]?.guarante_type?.guarantee_type_name)
    );
    data?.map(
      (items, index) => (titleCell[index].alignment = { horizontal: "center" })
    );
    data?.map(
      (items, index) =>
        (titleCell[index].fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "00B0F0" },
        })
    );

    data?.map((items, index) => worksheet[index].mergeCells("A1:M1"));

    // for Mapped delinquent

    //end of title for Mapped
    //  for not Mapped delinquent

    //end on not clear

    const itemsTotal = [];

    function sumArray(numbers) {
      let sum = 0;
      for (let i = 0; i < numbers.length; i++) {
        sum += numbers[i];
      }
      return sum;
    }

    // Create the table headers for all
    const headerRow = data?.map((items, index) =>
      worksheet[index].addRow([
        "NU",
        "START DATE",
        "END DATE",
        " AMOUNT",
        "BALANCE",
        "DESCRIPTION",
        "CLIENT NAME",
        "TOTAL DATE",
        "REF NU BANK",
        "REF NU IE",
        "Period",
        "RATE",
        "COST",
      ])
    );
    data?.map((items, index) =>
      headerRow[index].eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "B8CCE4" },
        };
        cell.font = {
          color: { argb: "000000" },
          bold: true,
          size: 9,
          name: "Times New Roman",
        };
        cell.border = {
          top: { style: "thin", color: { argb: "000000" } },
          left: { style: "thin", color: { argb: "000000" } },
          bottom: { style: "thin", color: { argb: "000000" } },
          right: { style: "thin", color: { argb: "000000" } },
        };
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
        };
      })
    );
    //end of all header row
    //Mapped row

    //end of Mapped
    // for not clear

    //end of not clear
    // Set column widths
    data?.map(
      (items, index) => (
        (worksheet[index].getColumn(1).width = 5),
        (worksheet[index].getColumn(2).width = 40),
        (worksheet[index].getColumn(3).width = 20),
        (worksheet[index].getColumn(4).width = 20),
        (worksheet[index].getColumn(5).width = 20),
        (worksheet[index].getColumn(6).width = 20),
        (worksheet[index].getColumn(7).width = 40),
        // Set the header row to bold
        (worksheet[index].getRow(1).font = { bold: true })
      )
    );

    // Populate the table with data from the map

    data?.forEach((element, index) => {
      
      element.map((items) => {
        const DataRow = worksheet[index]?.addRow([
          (count += 1),
          items?.start_date,
          items?.end_date,
          Number(items?.amount)?.toLocaleString(),
          Number(
            items?.guarantee_balance?.balance - items?.amount
          )?.toLocaleString(),
          items?.description == null ? "-" : items?.description,
          items?.project?.project_name,
          items?.total_dates,
          items?.bank_ref_num == null ? "-" : items?.bank_ref_num,
          items?.ref_num == null ? "-" : items?.ref_num,
          items?.period,
          items?.rate,
          Number(items?.cost)?.toLocaleString(),
        ]);

        DataRow.eachCell((cell) => {
          cell.font = {
            color: { argb: "000000" },
            bold: true,
            size: 9,
            name: "Times New Roman",
          };
          cell.border = {
            top: { style: "thin", color: { argb: "000000" } },
            left: { style: "thin", color: { argb: "000000" } },
            bottom: { style: "thin", color: { argb: "000000" } },
            right: { style: "thin", color: { argb: "000000" } },
          };
          cell.alignment = {
            vertical: "middle",
            horizontal: "right",
          };
        });
      });
    });

    // data clear
    // end data clear
    // data not clear

    // const FooterRow = worksheet.addRow([
    //   "Total",
    //   " ",
    //   Number(finalSummary[0]?.balance)?.toLocaleString(),

    //   Number(
    //     amount(finalSummary?.map((items) => items?.amount))
    //   )?.toLocaleString(),
    //   Number(
    //     amount(finalSummary?.map((items) => items?.cost))
    //   )?.toLocaleString(),
    //   Number(
    //     finalSummary[0]?.balance -
    //       amount(finalSummary?.map((items) => items?.amount))
    //   )?.toLocaleString(),
    // ]);

    // FooterRow.eachCell((cell) => {
    //   cell.fill = {
    //     type: "pattern",
    //     pattern: "solid",
    //     fgColor: { argb: "B8CCE4" },
    //   };
    //   cell.font = {
    //     color: { argb: "000000" },
    //     bold: true,
    //     size: 9,
    //     name: "Times New Roman",
    //   };
    //   cell.border = {
    //     top: { style: "thin", color: { argb: "000000" } },
    //     left: { style: "thin", color: { argb: "000000" } },
    //     bottom: { style: "thin", color: { argb: "000000" } },
    //     right: { style: "thin", color: { argb: "000000" } },
    //   };
    //   cell.alignment = {
    //     vertical: "middle",
    //     horizontal: "left",
    //   };
    // });

    // Save the workbook and download the Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "Guarantee Report.xlsx");
  };

  return (
    <Tooltip title="Guarantee Report">
      <button onClick={downloadExcel} className="add-client-representative">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14V11H2V14H14V11H16V14C16 14.55 15.8043 15.021 15.413 15.413C15.021 15.8043 14.55 16 14 16H2ZM8 12L3 7L4.4 5.55L7 8.15V0H9V8.15L11.6 5.55L13 7L8 12Z"
            fill="white"
          />
        </svg>
      </button>
    </Tooltip>
  );
};

export default AllGuaranteeReport;
