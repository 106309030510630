import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { InputLabel } from "@mui/material";

export default function ForexProjectFilterSelector(props) {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 0;
  const style = {
    width: props?.style?.width ? props?.style?.width : 226,
    height: 32,
    backgroundColor: "#ececec",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: props?.style?.fontSize ? props?.style?.fontSize : "11px",
    lineHeight: "14px",
    letterSpacing: "0.105em",
    color: "#353945",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiOutlinedInput-input": {
      marginTop: props?.style?.marginTop,
    },
  };
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: props?.width ? props?.width : 226,
        color: "#186569",
        marginTop: -10,
      },
    },
    disableScrollLock: true,
  };

  const [value, setValue] = React.useState("");

  const handleChange = (event) => {
    // setValue(event.target.value);
    props?.onChange(event.target.value);
  };
  const selectAllForProject = {
    id: "ALL",
    project_name: "All",
  };
  const selectAllForSupplier = {
    id: "ALL",
    distributor_name: "All",
  };
  const selectAllForBank = {
    id: "ALL",
    bank_name: "All",
  };

  const filterSelectorDataList =
    props?.name === "project"
      ? props?.data && [selectAllForProject, ...props?.data?.data]
      : props?.name === "supplier"
      ? props?.data && [selectAllForSupplier, ...props?.data?.data]
      : props?.name === "bank"
      ? props?.data && [selectAllForBank, ...props?.data?.data]
      : [];

  const selectDataList = [];
  for (let data in filterSelectorDataList) {
    selectDataList?.push({
      id: filterSelectorDataList[data]?.id,
      name:
        props?.name === "project"
          ? filterSelectorDataList[data]?.project_name
          : props?.name === "supplier"
          ? filterSelectorDataList[data]?.distributor_name
          : props?.name === "bank"
          ? filterSelectorDataList[data]?.bank_name
          : "",
    });
  }

  return (
    <div>
      <FormControl
        sx={{
          "& .css-67xo4a-MuiFormLabel-root-MuiInputLabel-root": {
            marginTop: "-7px",
          },
          "& .css-1qicbd3-MuiFormLabel-root-MuiInputLabel-root": {
            color: "#fff",
          },
          marginTop: 1,
          width: 226,
          color: "#186569",
        }}
      >
        {props?.selected === null ? (
          <InputLabel
            sx={{
              color: "#AEB8C9",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: props?.style?.fontSize
                ? props?.style?.fontSize
                : "11px",
              lineHeight: "14px",
              letterSpacing: "0.105em",
            }}
            disabled={true}
            id="demo-simple-select-label"
          >
            {props?.placeHolder}
          </InputLabel>
        ) : (
          <></>
        )}

        <Select
          defaultValue={value}
          onChange={handleChange}
          // onClose={(e) => props?.onChange(value)}
          input={<OutlinedInput label="Name" />}
          MenuProps={MenuProps}
          sx={style}
          value={props?.selected}
        >
          {selectDataList &&
            selectDataList?.map((data) => (
              <MenuItem key={data?.id} value={data?.id}>
                {data?.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
}
