import React from "react";
import ForexSideBar from "../../../components/Payment/ForexApproval/PaymentSideBar";
import SupplierTrackingNav from "../../../components/Payment/SupplierTracking/SupplierTrackingNav";

const SupplierPaymentIndex = () => {
  return (
    <ForexSideBar>
      <SupplierTrackingNav />
    </ForexSideBar>
  );
};

export default SupplierPaymentIndex;
