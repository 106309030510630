import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddAssetCategory from "./SettingModal/AddAssetCategory";
import EditAssetCatagories from "./SettingModal/EditAssetCategory";
import {
  useDeleteAssetCatagoriesMutation,
  useGetAssetCatagoriesQuery,
} from "../../features/SCMApi";
import DeleteModal from "../Supplier/SupplierModal/DeleteModal";

export default function AssetCatagories() {
  const [addAssetCatagories, setAddAssetCatagories] = useState(false);
  const [editAssetCatagories, setEditAssetCatagories] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [assetToDeleteId, setAssetToDeleteId] = useState(null);
  const [updateAssetCatagories, setUpdateAssetCatagories] = useState(null);

  const { data: asset_catagories } = useGetAssetCatagoriesQuery();
  const [deleteAssetCategory] = useDeleteAssetCatagoriesMutation();

  const handleAddAssetCategoryModal = () => {
    setAddAssetCatagories(true);
  };

  const handleEditAssetCategoryModal = (item) => {
    setEditAssetCatagories(true);
    setUpdateAssetCatagories(item);
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const handleOpenDeleteModal = (id) => {
    setAssetToDeleteId(id);
    toggleDeleteModal();
  };

  const handleDeleteAssetCategory = () => {
    const deleted = { id: assetToDeleteId };
    deleteAssetCategory(deleted)
      .unwrap()
      .then(() => {
        console.log("Asset category deleted successfully");
      })
      .catch((error) => {
        console.error("Error deleting asset category:", error);
      });
    toggleDeleteModal();
  };

  return (
    <div className="pcard incoterm">
      <section className="pi-header">
        <h3>Asset Category</h3>
      </section>
      {asset_catagories?.data?.map((items) => (
        <section className="pi-body" key={items.id}>
          <p>{items?.name}</p>
          <p>{items?.description}</p>
          <div>
            <ModeEditOutlineOutlinedIcon
              onClick={() => handleEditAssetCategoryModal(items)}
              sx={{
                color: "#52FFAC",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#52FFAC",
                  border: "1px solid #52FFAC",
                  borderRadius: 25,
                },
              }}
            />
            <DeleteOutlineOutlinedIcon
              onClick={() => handleOpenDeleteModal(items.id)}
              sx={{
                color: "#FF5252",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#FF5252",
                  border: "1px solid #FF5252",
                  borderRadius: 25,
                },
              }}
            />
          </div>
        </section>
      ))}

      <section className="pi-footer">
        <div onClick={handleAddAssetCategoryModal} className="pi-footer-icon">
          <AddIcon />
        </div>
      </section>
      {addAssetCatagories && <AddAssetCategory modal={setAddAssetCatagories} />}
      {editAssetCatagories && (
        <EditAssetCatagories
          modal={setEditAssetCatagories}
          data={updateAssetCatagories}
        />
      )}

      <DeleteModal
        show={showDeleteModal}
        handleDeleteModal={toggleDeleteModal}
        name={"asset category"}
        deletedId={assetToDeleteId}
        navigationto={"smm/asset-categories"}
        handleDelete={handleDeleteAssetCategory}
      />
    </div>
  );
}
