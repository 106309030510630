import React from "react";
import Card from "react-bootstrap/Card";
import { Link, useParams } from "react-router-dom";

const BatchGroupItems = ({ totalItems, number, name, itemData }) => {
  const { projectid } = useParams();
  const { ProjectId, deliverdItems } = useParams();
  return (
    <>
      <Link
        to={`/logistics/deliveredBatches/${ProjectId}/details/${deliverdItems}/${itemData?.id}`}
      >
        <Card style={{ width: "18rem" }}>
          <Card.Body>
            <Card.Title
              className="fs-6 font-bold"
              style={{ fontFamily: "sans-serif" }}
            >
              {name}
            </Card.Title>
            <div className="ml-5">
              <Card.Subtitle className="mb-2 text-muted">
                Group ID: {number}
              </Card.Subtitle>
              <Card.Text>Item Number: {totalItems}</Card.Text>
            </div>
          </Card.Body>
        </Card>
      </Link>
    </>
  );
};

export default BatchGroupItems;
