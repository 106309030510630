import React from "react";
import noDataSvg from "./.././../assets/SVG/isSupplier.svg";

export default function IsSupplier(props) {
  return (
    <div className="is-supplier">
      <img src={noDataSvg} alt="" />
      <div className="prg-pri text-center">
        {" "}
        {props?.name} is also a Supplier
      </div>
    </div>
  );
}
