import { useStepContext } from "@mui/material";
import React, { useState } from "react";
import { useGetProjectsQuery } from "../../features/SCMApi";

export default function Projectselect({ setProject }) {
  const { data: projects } = useGetProjectsQuery();
  const list = [];
  projects?.data?.map((items) => {
    if (items?.isActive == 1) list.push(items);
  });
  return (
    <div>
      <select
        className="report-project-selector"
        onChange={(e) => setProject(e.target.value)}
      >
        <option value="">All</option>
        {list?.map((items) => (
          <option value={items?.id}>{items?.project_name}</option>
        ))}
      </select>
    </div>
  );
}
