import React from "react";
import { useState } from "react";
import {
  useGetUserInfoQuery,
  useGetApproverQuery,
  useGetBatchInventoryReturnQuery,
  useGetStoreQuery,
} from "../../../../features/SCMApi";

import NoRecord from "../../../common/NoRecord";
import { IMG_BASE_URL } from "../../../../api/endPoint";
import NavBar from "../../../../Layout/NavBar";
import { NavLink, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BatchAccept from "../../StockModal/BatchAccept";
export default function ConsumedBatchInventory(props) {
  const HandleFragment = (tool) => {
    props?.setFragment("detail");
    props?.setData(tool);
  };
  const params = useParams();
  const { data: BatchInventory } = useGetBatchInventoryReturnQuery(params?.id);

  //
  const { data: store } = useGetStoreQuery();

  const [dialog, setDialog] = useState(false);
  const [Accept, setAccept] = useState(false);
  const [reject, setReject] = useState(false);
  function HandleAccept() {
    setAccept(true);
  }
  function HandleReject() {
    setReject(true);
  }

  let items =
    BatchInventory?.data == undefined
      ? "[]"
      : BatchInventory?.data?.return_items;
  let BatchItems = JSON.parse(items)?.filter(
    (tool) => tool?.current_consum_amount > 0
  );
  const { data: getApprover } = useGetApproverQuery({
    module_name: "Inventory",
    id: params?.id,
  });
  const { data: loggedUser } = useGetUserInfoQuery();
  const [success, setSuccess] = useState(false);
  const storeKeeperId = store?.data?.filter(
    (storeKeeper) => storeKeeper?.id == BatchItems[0]?.store_id
  );

  success &&
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  return (
    <>
      <NavBar
        placeholder={props?.placeholder}
        handleSearchChange={props?.handleSearchChange}
      />
      <div className="page">
        {" "}
        <div className="d-flex justify-content-start my-2">
          <NavLink to={`/stocks/requests`} className="price-comparison">
            <span className="d-flex justify-content-center gap-4 px-3 py-3 align-items-center">
              <ArrowBackIcon />
              Go Back
            </span>
          </NavLink>
        </div>
        {BatchItems?.length !== 0 ? (
          <>
            <div className="inventory-header-container mt-4">
              <div className=""></div>

              <div className="items-counter">
                <div className="text-center">{BatchItems?.length}</div>
                <div>Items Count</div>
              </div>
            </div>
            <table style={{ width: "100%" }}>
              <thead className="stock-table-header">
                <tr>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "10%" }}
                  ></th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "17%" }}
                  >
                    Item Name
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "14%" }}
                  >
                    Model
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "10%" }}
                  >
                    Type
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "15%" }}
                  >
                    Store
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "10%" }}
                  >
                    Consumed Amount
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "11%" }}
                  >
                    Status
                  </th>
                  {/* <th
                    className="stock-table-header-text"
                    style={{ width: "20%" }}
                  >
                    Action
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {BatchItems?.map((tool, index) => (
                  <tr className="stock-table-row">
                    <td
                      className=""
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      <img
                        className="stock-img"
                        src={`${IMG_BASE_URL}${tool?.image}`}
                        alt=""
                      />
                    </td>
                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {tool?.item_name}
                    </td>
                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {tool?.model === null ? "-" : tool?.model}
                    </td>
                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {tool?.tool_type_id === null
                        ? "-"
                        : tool?.tool_type?.tool_type}
                    </td>
                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {" "}
                      {tool?.store_id === null ? "-" : tool?.store?.store_name}
                    </td>
                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {`${
                        tool?.current_consum_amount
                          ? tool?.current_consum_amount
                          : "-"
                      } ${tool?.uom_id == null ? "-" : tool?.uom?.name}`}
                    </td>

                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {tool?.status_id == null
                        ? "-"
                        : tool?.status?.status_name}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div
              className="batch-header-container text-start mt-2"
              style={{ display: "flex" }}
            >
              {loggedUser?.id == storeKeeperId[0]?.store_keeper_id ? (
                <>
                  <button
                    className="form-save"
                    onClick={() => {
                      HandleAccept();
                      setDialog(getApprover?.data.current[0]);
                    }}
                  >
                    Confirm
                  </button>
                </>
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          <NoRecord />
        )}
      </div>

      {Accept ? (
        <BatchAccept
          modal={setAccept}
          data={BatchItems}
          items={BatchInventory}
          is_item={true}
          is_consumed={true}
        />
      ) : (
        ""
      )}
    </>
  );
}
