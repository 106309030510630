import React from "react";
import NavBar from "../../Layout/NavBar";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { NavLink } from "react-router-dom";
export default function Projectindex(props) {
  return (
    <>
      <NavBar
        placeholder={props?.placeholder}
        handleSearchChange={props.handleSearchChange}
      />
      <div className="all-breadcrumb">
        <Breadcrumb className="ms-3">
          <Breadcrumb.Item className="prg" href="/projects">
            Projects
          </Breadcrumb.Item>
          <Breadcrumb.Item className="prg" href={props.navlink}>
            {props.title}
          </Breadcrumb.Item>
          {props.name === undefined ? (
            ""
          ) : (
            <Breadcrumb.Item className="prg-pri" active>
              {props.name}
            </Breadcrumb.Item>
          )}
        </Breadcrumb>
      </div>
      <div className="row">
        <div className="page ">
          <>{props.children}</>
        </div>
      </div>
    </>
  );
}
