import React from "react";
// import OrderCard from "../../components/Sourcing/OrderCard";
import "../../pages/Sourcing/orders.style.css";
// import NavBar from "../../Layout/NavBar";
import PoStage from "./PoStage";
import { useGetProjectsQuery } from "../../features/SCMApi";
import { useParams } from "react-router-dom";
import SourcingIndex from "../../pages/Sourcing/SourcingIndex";
import { useState } from "react";
import { Tabs } from "react-bootstrap";
import { Tab } from "bootstrap";

export default function PoStageService() {
  const { data: response } = useGetProjectsQuery();
  const Projectdata = [];
  const params = useParams();
  response?.data?.map((items) => {
    if (params.id === items.id) {
      Projectdata.push(items);
    }
  });
 
  const [search, setSearch] = useState("");

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const [order_type, setOrderType] = useState("Service");

  return (
    <SourcingIndex
      name={Projectdata[0]?.project_name}
      names="PO Stage"
      handleSearchChange={handleSearchChange}
      placeholder={"search PO order..."}
    >
      <Tabs
        defaultActiveKey="Product"
        id="uncontrolled-tab-example"
        className="mb-3 d-flex justify-content-center bg-white rounded gap-4 border forexApprovalNav"
      >
        <Tab eventKey="Product" title="Service Material">
          <PoStage
            name={Projectdata[0]?.project_name}
            search={search}
            order_type={order_type}
            serviceType={"Material"}
          />{" "}
        </Tab>
        <Tab eventKey="Service" title="Service traning">
          <PoStage
            name={Projectdata[0]?.project_name}
            search={search}
            order_type={order_type}
            serviceType={"Training"}
          />{" "}
        </Tab>
      </Tabs>
    </SourcingIndex>
  );
}
