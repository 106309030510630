import React, { useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import "../forex.style.css";
import usePagination from "../../../components/Pagination/Pagination";
import { Pagination } from "@mui/material";
import { styled } from "@mui/material/styles";
import SupplierUtilities from "../../../pages/Supplier/SupplierUtilities";
import {
  useGetBanksQuery,
  useGetUserInfoQuery,
  useGetProjectsQuery,
  useGetSuppliersQuery,
} from "../../../features/SCMApi";
import BankSelect from "./Select";
import SuccessMessage from "../SuccessMessage";
import CheckPermission from "../../common/checkPermission/checkPermission";
import { Tooltip } from "@mui/material";
import ForexFilterSelector from "./ForexFilterSelector";
import FilterActions from "./FilterActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import EditPaymentButton from "./EditPaymentButton";
import UpdateForexNeedStatusButton from "./updateForexNeedStatusButton";

const TCell = styled(TableCell)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "10.5px",
  lineHeight: "12px",
  color: "#353945",
  width: "100px",
  padding: "0 0px 0 0",
  borderRight: "1px #E0E0E0 solid",
  borderLeft: "1px #E0E0E0 solid",
}));
const TCellHeader = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#ececec",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "11px",
  lineHeight: "12px",
  color: "#353945",
}));
const SpecialTcell = styled(TableCell)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12.5px",
  lineHeight: "12px",
  color: "#353945",
  padding: "0 0px 0 0",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  height: "200px",
}));
const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  // marginTop: "10px",
}));
const GrandTotal = styled(TableCell)(({ theme }) => ({
  display: "flex",
  justifyContent: "end",
  borderBottom: "#fff",
}));
const TotalDisplay = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "20px",
  lineHeight: "28px",
  color: "#FFFFFF",
  backgroundColor: "#186569",
  width: "220px",
  height: "50px",
  borderRadius: "5px",
  marginLeft: "auto",
  marginRight: "20px",
}));

export default function EnhancedTable(props) {
  const [successMessage, setSuccessMessage] = React.useState(false);

  successMessage &&
    setTimeout(() => {
      setSuccessMessage(false);
    }, 3000);

  const { data: userinfo } = useGetUserInfoQuery();
  const { data: bankLists } = useGetBanksQuery();
  const { data: projectList } = useGetProjectsQuery();
  const { data: supplierList } = useGetSuppliersQuery();

  /**General filter selector states */
  const [projectFilter, setProjectFilter] = React.useState(false);
  const [supplierFilter, setSupplierFilter] = React.useState(false);
  const [bankFilter, setBankFilter] = React.useState(false);

  //Selected data for filter
  const [selectedProject, setSelectedProject] = React.useState(null);
  const [selectedSupplier, setSelectedSupplier] = React.useState(null);
  const [selectedBank, setSelectedBank] = React.useState(null);

  const handleCancelProjectFilter = (e) => {
    setProjectFilter(false);
    setSelectedProject(null);
  };

  const handleCancelSupplierFilter = (e) => {
    setSupplierFilter(false);
    setSelectedSupplier(null);
  };

  const handleCancelBankFilter = (e) => {
    setBankFilter(false);
    setSelectedBank(null);
  };

  /**Project  filter logic starting */
  const filterForexProject = (isProject, rowData, projectId) => {
    const project = projectId == "ALL" ? null : projectId;
    if (isProject == true && project == null) {
      return rowData;
    } else if (isProject == true && project != null) {
      return rowData?.filter((project) => project?.project_id == projectId);
    } else {
      return rowData;
    }
  };
  /**Project  filter logic ending */

  /**Supplier  filter logic starting */
  const filterForexSupplier = (isSupplier, rowData, supplierId) => {
    const supplier = supplierId == "ALL" ? null : supplierId;
    if (isSupplier == true && supplier == null) {
      return rowData;
    } else if (isSupplier == true && supplier != null) {
      return rowData?.filter((project) => project?.supplier_id == supplierId);
    } else {
      return rowData;
    }
  };
  /**Supplier filter logic ending **/

  /**Bank filter logic starting */
  const filterForexBank = (isBank, bankData, bankId) => {
    const bank = bankId == "ALL" ? null : bankId;
    if (isBank == true && bank == null) {
      return bankData;
    } else if (isBank == true && bankId != null) {
      if (
        bankData?.filter((detail) => detail?.bank_id == bankId)?.length !== 0
      ) {
        return bankData?.filter((detail) => detail?.bank_id == bankId);
      } else {
        return null;
      }
    } else {
      return bankData;
    }
  };
  /**Bank filter logic ending */

  const FormatResponseFunc = (response) => {
    function groupby(arr, key) {
      return arr.reduce((acc, x) => {
        (acc[x[key]] = acc[x[key]] || []).push(x);
        return acc;
      }, {});
    }

    const forex_need_project_filter =
      filterForexProject(
        projectFilter,
        response?.data?.forex_need?.filter((item) => item?.is_need == 1),
        selectedProject
      ) || [];

    const forex_need_project_supplier_filter =
      filterForexSupplier(
        supplierFilter,
        forex_need_project_filter,
        selectedSupplier
      ) || [];

    const forex_need = forex_need_project_supplier_filter || [];

    const finance_officer = response?.data?.finance_officer || [];
    const sourcing_officer = response?.data?.sourcing_officer || [];
    const forexe_bank = response?.data?.forexe_bank || [];
    const _rows = [];

    

    const projectsTemp = groupby(forex_need, "project_name");
    Object.keys(projectsTemp)?.forEach((key, index) => {
      const data = {};
      data.no = index + 1;
      data.project = {
        id: index + 1,
        project_id: projectsTemp[key][0].project_id,
        name: projectsTemp[key][0].project_name,
      };

      const projectId = projectsTemp[key][0]?.project_id;
      const milestoneTemp = groupby(projectsTemp[key], "milestone_name");
      data.milestone = [];
      data.supplier = [];
      data.pfo =
        finance_officer.find((item) => item.project_id === projectId)?.name ||
        "";
      data.seSo =
        sourcing_officer.find((item) => item.project_id === projectId)?.name ||
        "";
      Object?.keys(milestoneTemp)?.forEach((key) => {
        data?.milestone?.push({
          id: milestoneTemp[key][0]?.milestone_id,
          project_id: milestoneTemp[key][0]?.project_id,
          milestone_name: milestoneTemp[key][0]?.milestone_name,
          milestoneData: filterForexBank(
            bankFilter,
            milestoneTemp[key]
              .flat()
              .sort((a, b) => (a?.milestone_id > b?.milestone_id ? 1 : -1))
              .map((item, index) => ({
                id: index + 1,
                ...item,
                noOfSupplier: milestoneTemp[key]?.length,
                bank:
                  forexe_bank.find((bank) => bank?.id === item?.forex_need_id)
                    ?.forex_need_bank !== null
                    ? forexe_bank.find(
                        (bank) => bank?.id === item?.forex_need_id
                      )?.forex_need_bank?.bank_name
                    : null,
                bank_id:
                  forexe_bank.find((bank) => bank?.id === item?.forex_need_id)
                    ?.forex_need_bank !== null
                    ? forexe_bank.find(
                        (bank) => bank?.id === item?.forex_need_id
                      )?.forex_need_bank?.id
                    : null,
              })),
            selectedBank
          ),
        });
      });
      data.projectTotal =
        Math.round(
          (data.milestone
            .map((milestone) => milestone?.milestoneData)
            .flat()
            ?.reduce(
              (total, item) => (total += Number(item?.amount) || 0),
              0
            ) || 0 + Number.EPSILON) * 100
        ) / 100;

      data.milestone = data?.milestone.sort((a, b) => (a.id > b.id ? 1 : -1));
      data.isBankNull =
        data?.milestone?.filter((data) => data?.milestoneData !== null)
          ?.length === 0
          ? true
          : false;

      _rows.push(data);
    });

    return _rows;
  };

  const memoData = React.useMemo(() => props?.data, [props?.data]);
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    setRows(FormatResponseFunc(memoData));
  }, [
    selectedProject,
    projectFilter,
    selectedBank,
    bankFilter,
    selectedSupplier,
    supplierFilter,
    memoData,
  ]);

  

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const headCells = [
    {
      id: "no",
      numeric: true,
      disablePadding: true,
      label: "\xa0\xa0\xa0NO",
      width: "5%",
    },
    {
      id: "project",
      numeric: true,
      disablePadding: false,
      label: "\xa0\xa0\xa0\xa0\xa0PROJECT",
      width: "10%",
    },
    {
      id: "milestone",
      numeric: true,
      disablePadding: false,
      label: "\xa0\xa0\xa0\xa0\xa0MILESTONE",
      width: "10%",
    },
    {
      id: "supplier",
      numeric: true,
      disablePadding: false,
      label: "\xa0\xa0\xa0\xa0\xa0SUPPLIER",
      width: "15%",
    },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label:
        "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0DESCRIPTION\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0",
    },
    {
      id: "paymentMode",
      numeric: true,
      disablePadding: false,
      label: "\xa0PAYMENT MODE",
      width: "10%",
    },
    {
      id: "bank",
      numeric: true,
      disablePadding: false,
      label: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0BANK",
      width: "10%",
    },
    {
      id: "pfo",
      numeric: true,
      disablePadding: false,
      label: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0PFO",
      width: "10%",
    },
    {
      id: "so",
      numeric: true,
      disablePadding: false,
      label: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0SO",
      width: "10%",
    },
    {
      id: "amount",
      numeric: true,
      disablePadding: false,
      label: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0AMOUNT",
      width: "10%",
    },
  ];

  const grandTotals = [];
  const sumup = rows;
  for (const total in sumup) {
    grandTotals.push(sumup[total]?.projectTotal);
  }

  let grandTotal =
    Math.round(
      (grandTotals
        .filter((e) => typeof e == "number")
        .reduce((prev, acc) => prev + acc, 0) +
        Number.EPSILON) *
        100
    ) / 100;

  function EnhancedTableHead(props) {
    const { order, orderBy } = props;

    return (
      <TableHead
        className="paymentTableHeader"
        sx={{
          backgroundColor: "#ececec",
          color: "#353945",
        }}
      >
        <TableRow>
          {headCells.map((headCell) => (
            <TCellHeader
              key={headCell.id}
              align={headCell.numeric ? "left" : "left"}
              padding="none"
              sortDirection={orderBy === headCell.id ? order : false}
              height="50px"
              width={headCell?.width}
            >
              {headCell.label}
            </TCellHeader>
          ))}
          <TCellHeader></TCellHeader>
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <>
            {successMessage === true ? (
              <>
                <SuccessMessage
                  style={{
                    marginTop: "10px",
                    backgroundColor: "#52ffab96",
                    marginRight: "10px",
                  }}
                  Message={"Forex bank updated successfully!!!"}
                />
              </>
            ) : (
              <>
                <div className="paymentTrackingSelectProject">
                  <FilterActions
                    isfinance={true}
                    onClickProject={setProjectFilter}
                    onClickBank={setBankFilter}
                    onClickSupplier={setSupplierFilter}
                  />

                  <div style={{ display: "flex" }}>
                    {projectFilter === true ? (
                      <>
                        <ForexFilterSelector
                          name={"project"}
                          data={projectList}
                          placeHolder={"Select Project..."}
                          onChange={setSelectedProject}
                          selected={selectedProject}
                          style={{ width: 200, fontSize: 11, marginTop: 1.5 }}
                        />
                        <IconButton
                          sx={{
                            backgroundColor: "#ececec",
                            color: "#FF5252",
                            borderTopRightRadius: "5px",
                            borderBottomRightRadius: "5px",
                            borderBottomLeftRadius: "0px",
                            borderTopLeftRadius: "0px",
                            borderLeft: "1px solid #AEB8C9",
                            width: "31px",
                            height: "32px",
                            marginTop: "8px",
                            marginLeft: "-30px",
                            marginRight: "8px",
                            "&:hover": {
                              backgroundColor: "#ececec",
                            },
                          }}
                          onClick={handleCancelProjectFilter}
                        >
                          <CloseIcon />
                        </IconButton>
                      </>
                    ) : (
                      <></>
                    )}

                    {supplierFilter === true ? (
                      <>
                        <ForexFilterSelector
                          name={"supplier"}
                          data={supplierList}
                          placeHolder={"Select Supplier..."}
                          onChange={setSelectedSupplier}
                          selected={selectedSupplier}
                          style={{ width: 200, fontSize: 11, marginTop: 1.5 }}
                        />
                        <IconButton
                          sx={{
                            backgroundColor: "#ececec",
                            color: "#FF5252",
                            borderTopRightRadius: "5px",
                            borderBottomRightRadius: "5px",
                            borderBottomLeftRadius: "0px",
                            borderTopLeftRadius: "0px",
                            borderLeft: "1px solid #AEB8C9",
                            width: "31px",
                            height: "32px",
                            marginTop: "8px",
                            marginLeft: "-30px",
                            marginRight: "8px",
                            "&:hover": {
                              backgroundColor: "#ececec",
                            },
                          }}
                          onClick={handleCancelSupplierFilter}
                        >
                          <CloseIcon />
                        </IconButton>
                      </>
                    ) : (
                      <></>
                    )}
                    {bankFilter === true ? (
                      <>
                        <ForexFilterSelector
                          name={"bank"}
                          data={bankLists}
                          placeHolder={"Select Bank..."}
                          onChange={setSelectedBank}
                          selected={selectedBank}
                          style={{ width: 200, fontSize: 11, marginTop: 1.5 }}
                        />
                        <IconButton
                          sx={{
                            backgroundColor: "#ececec",
                            color: "#FF5252",
                            borderTopRightRadius: "5px",
                            borderBottomRightRadius: "5px",
                            borderBottomLeftRadius: "0px",
                            borderTopLeftRadius: "0px",
                            borderLeft: "1px solid #AEB8C9",
                            width: "31px",
                            height: "32px",
                            marginTop: "8px",
                            marginLeft: "-30px",

                            "&:hover": {
                              backgroundColor: "#ececec",
                            },
                          }}
                          onClick={handleCancelBankFilter}
                        >
                          <CloseIcon />
                        </IconButton>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <TotalDisplay>
                  {grandTotal?.toLocaleString()}
                  <span className="projectTotal">Grand Total</span>
                </TotalDisplay>
              </>
            )}
          </>
        )}
      </Toolbar>
    );
  };

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("no");
  const [selected, setSelected] = React.useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows?.map((n) => n.no);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const isSelected = (name) => selected?.indexOf(name) !== -1;

  const rowData = rows?.filter((data) => data?.isBankNull === false);

  // Pagination
  let [page, setPage] = React?.useState(1);
  const PER_PAGE = 1;
  const count = Math.ceil(rowData?.length / PER_PAGE);
  const _DATA = usePagination(rowData, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA?.jump(p);
  };

  return (
    <Box sx={{ width: props?.isfinance ? "100%" : "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <EnhancedTableToolbar numSelected={selected?.length} />
        <TableContainer>
          <Table
            sx={{ width: "inherit" }}
            aria-labelledby="tableTitle"
            size="small"
          >
            <EnhancedTableHead
              numSelected={selected?.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rowData?.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(
                _DATA?.currentData(),
                getComparator(order, orderBy)
              )?.map((row, index) => {
                const isItemSelected = isSelected(row.no);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <>
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.no}
                      selected={isItemSelected}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        align="left"
                        sx={{
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "10.5px",
                          lineHeight: "12px",
                          color: "#353945",
                        }}
                      >
                        {row?.no}
                      </TableCell>
                      <TCell align="center">{row.project?.name}</TCell>
                      <TCell>
                        {row.milestone
                          ?.filter(
                            (milestone) =>
                              milestone?.project_id === row?.project?.project_id
                          )
                          .map((milestone) => (
                            <Container>
                              <SpecialTcell align="center">
                                {milestone?.milestone_name}
                              </SpecialTcell>
                            </Container>
                          ))}
                      </TCell>

                      <TCell>
                        {row.milestone
                          ?.filter(
                            (milestone) =>
                              milestone?.project_id === row?.project?.project_id
                          )
                          .map((milestoneData) => milestoneData?.milestoneData)
                          ?.flat()
                          .map((supplier) => (
                            <Container>
                              <Tooltip title={supplier?.distributor_name} arrow>
                                <SpecialTcell
                                  align="center"
                                  sx={{
                                    height:
                                      supplier?.noOfSupplier > 10
                                        ? `calc(1000px / ${supplier?.noOfSupplier})`
                                        : `calc(200px / ${supplier?.noOfSupplier})`,
                                  }}
                                >
                                  {supplier?.distributor_name?.length >= 11
                                    ? supplier?.distributor_name?.slice(0, 10) +
                                      "..."
                                    : supplier?.distributor_name}
                                </SpecialTcell>
                              </Tooltip>
                            </Container>
                          ))}
                      </TCell>
                      <TCell>
                        {row.milestone
                          ?.filter(
                            (milestone) =>
                              milestone?.project_id === row?.project?.project_id
                          )
                          .map((milestoneData) => milestoneData?.milestoneData)
                          ?.flat()
                          .map((supplier) => (
                            <Container>
                              <Tooltip title={supplier?.description} arrow>
                                <TableCell
                                  align="left"
                                  sx={{
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    lineHeight: "12px",
                                    color: "#353945",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    padding: "0 0px 0 10px",
                                    height:
                                      supplier?.noOfSupplier > 10
                                        ? `calc(1000px / ${supplier?.noOfSupplier})`
                                        : `calc(200px / ${supplier?.noOfSupplier})`,
                                  }}
                                >
                                  {supplier?.description?.length >= 21
                                    ? supplier?.description?.slice(0, 20) +
                                      "..."
                                    : supplier?.description}
                                </TableCell>
                              </Tooltip>
                            </Container>
                          ))}
                      </TCell>
                      <TCell>
                        {row.milestone
                          ?.filter(
                            (milestone) =>
                              milestone?.project_id === row?.project?.project_id
                          )
                          .map((milestoneData) => milestoneData?.milestoneData)
                          ?.flat()
                          .map((supplier) => (
                            <Container>
                              <SpecialTcell
                                align="center"
                                sx={{
                                  height:
                                    supplier?.noOfSupplier > 10
                                      ? `calc(1000px / ${supplier?.noOfSupplier})`
                                      : `calc(200px / ${supplier?.noOfSupplier})`,
                                  width: "130px",
                                }}
                              >
                                {supplier?.payment_mode !== null
                                  ? supplier?.payment_mode?.length > 18
                                    ? supplier?.payment_mode?.slice(0, 18) +
                                      "..."
                                    : supplier?.payment_mode
                                  : ""}
                              </SpecialTcell>
                            </Container>
                          ))}
                      </TCell>
                      <TCell>
                        {row.milestone
                          ?.filter(
                            (milestone) =>
                              milestone?.project_id === row?.project?.project_id
                          )
                          .map((milestoneData) => milestoneData?.milestoneData)
                          ?.flat()
                          .map((supplier) => (
                            <Container>
                              <SpecialTcell
                                align="center"
                                sx={{
                                  height:
                                    supplier?.noOfSupplier > 10
                                      ? `calc(1000px / ${supplier?.noOfSupplier})`
                                      : `calc(200px / ${supplier?.noOfSupplier})`,
                                  width: "140px",
                                }}
                              >
                                <BankSelect
                                  placeHolder={"Select Bank..."}
                                  data={bankLists?.data}
                                  passedValue={supplier?.bank}
                                  forexId={supplier?.forex_need_id}
                                  successMessage={setSuccessMessage}
                                  disabled={
                                    CheckPermission(
                                      "edit_forex_need_bank",
                                      userinfo
                                    )
                                      ? false
                                      : true
                                  }
                                />
                              </SpecialTcell>
                            </Container>
                          ))}
                      </TCell>

                      <TCell align="center">{row.pfo}</TCell>
                      <TCell align="center">{row.seSo}</TCell>
                      <TCell>
                        {row.milestone
                          ?.filter(
                            (milestone) =>
                              milestone?.project_id === row?.project?.project_id
                          )
                          .map((milestoneData) => milestoneData?.milestoneData)
                          ?.flat()
                          .map((supplier) => (
                            <Container>
                              <SpecialTcell
                                align="center"
                                sx={{
                                  height:
                                    supplier?.noOfSupplier > 10
                                      ? `calc(1000px / ${supplier?.noOfSupplier})`
                                      : `calc(200px / ${supplier?.noOfSupplier})`,
                                }}
                              >
                                {Number(supplier?.amount).toLocaleString()}
                              </SpecialTcell>
                            </Container>
                          ))}
                      </TCell>
                      <TableCell>
                        {row.milestone
                          ?.filter(
                            (milestone) =>
                              milestone?.project_id === row?.project?.project_id
                          )
                          .map((milestoneData) => milestoneData?.milestoneData)
                          ?.flat()
                          .map((supplier) => (
                            <Container>
                              <SpecialTcell
                                align="center"
                                sx={{
                                  height:
                                    supplier?.noOfSupplier > 10
                                      ? `calc(1000px / ${supplier?.noOfSupplier})`
                                      : `calc(200px / ${supplier?.noOfSupplier})`,
                                }}
                              >
                                <UpdateForexNeedStatusButton
                                  disabled={
                                    CheckPermission(
                                      "receive_receivable",
                                      userinfo
                                    )
                                      ? false
                                      : true
                                  }
                                  updateRow={supplier?.forex_need_id}
                                  // message={setMessage}
                                  // successMessage={setSuccessMessage}
                                />
                              </SpecialTcell>
                            </Container>
                          ))}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={10}>
                        <GrandTotal>
                          <span className="paymentTrackingProjectTotal">
                            Project Total
                          </span>
                          <span className="paymentTrackingTotalAmount">
                            {row?.projectTotal?.toLocaleString()}
                          </span>
                        </GrandTotal>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {count > 1 ? (
          <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              margin: "5px",
              paddingBottom: "15px",
              paddingTop: "15px",
            }}
          />
        ) : (
          ""
        )}
      </Paper>
    </Box>
  );
}
