import React, { useState } from "react";
import Supplier from "../../pages/Supplier";
import FreightForwarderModal from "./SupplierModal/FreightForworderModal";
import { NavLink } from "react-router-dom";
import {
  useGetFrightForwardersQuery,
  useGetUserInfoQuery,
} from "../../features/SCMApi";
import ItemsTracker from "../project/ItemsTracker";
import SupplierRepresentativeEditModal from "./SupplierModal/SupplierRepresentativeEditModal";
import SupplierBankDetailEditModal from "./SupplierModal/SupplierBankDetailEditModal";
import AddIcon from "@mui/icons-material/Add";
import CheckPermission from "../common/checkPermission/checkPermission";
import NoRecord from "../common/NoRecord";
import { Pagination, Tooltip } from "@mui/material";
import usePagination from "../Pagination/Pagination";

export default function FreightForwarder() {
  const [createFreightForwarderModal, setFreightForwarderModal] =
    useState(false);
  function createFreightForwarderHandler() {
    setFreightForwarderModal(true);
  }

  const {
    data: Freightforwarders,
    isError,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetFrightForwardersQuery();

  const [search, setSearch] = useState("");

  const handleSearchChange = (e) => {
    setSearch(e.target.value);

  };
  const [messages, setMessages] = useState(null);
  const { data: userinfo } = useGetUserInfoQuery();

  let [page, setPage] = useState(0);
  const PER_PAGE = 12;
  const count = Math.ceil(
    Freightforwarders?.data.filter((items) => {
      if (search === "") {
        return items;
      } else if (
        items.frieght_forwarder_name
          ?.toLowerCase()
          .includes(search.toLowerCase())
      ) {
        return items;
      }
      return false;
    }).length / PER_PAGE
  );
  const pageVisted = page * PER_PAGE;
  const handleChange = ({ selected }) => {
    setPage(selected);
  };
  return (
    <Supplier
      handleSearchChange={handleSearchChange}
      title={"Freight Forwarders"}
      navlink={"/freightforwarder"}
      Message={messages}
      placeholder={"search Freight Forwarder..."}
    >
      <div className="every-index-page">
        {CheckPermission("add_freight_forwarder", userinfo) ? (
          <div
            className="create-project-btn"
            onClick={createFreightForwarderHandler}
          >
            <AddIcon />
            Add New
          </div>
        ) : (
          ""
        )}
        {Freightforwarders?.data?.length !== 0 ? (
          <div className="supplier-module-container">
            {Freightforwarders?.data
              ?.filter((items) => {
                if (search == "") {
                  return items;
                } else if (
                  items.frieght_forwarder_name?.toLowerCase().includes(search)
                ) {
                  return items;
                }
              })
              .slice(pageVisted, pageVisted + PER_PAGE)
              .map((items) => (
                <NavLink
                  to={items.id}
                  className="card-supplier shadow-md p-3 mb-2"
                >
                  <Tooltip title={items.frieght_forwarder_name}>
                    <div className="text-center h5-supplier mb-5">
                      {items.frieght_forwarder_name?.length >= 22
                        ? items?.frieght_forwarder_name.slice(0, 18) + "..."
                        : items?.frieght_forwarder_name}
                    </div>
                  </Tooltip>
                  <div className="text-center">
                    <p className="name-supplier prg">
                      {items?.frieght_forwarder_origin?.name}, {items.city}
                    </p>
                  </div>
                </NavLink>
              ))}
          </div>
        ) : (
          <NoRecord />
        )}
        <div className="mt-3">
          {count > 1 ? (
            <Pagination
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            />
          ) : (
            ""
          )}
        </div>
        {createFreightForwarderModal ? (
          <FreightForwarderModal
            modal={setFreightForwarderModal}
            setMessages={setMessages}
          />
        ) : (
          <></>
        )}
      </div>
    </Supplier>
  );
}
