import { Tab, Tabs } from "react-bootstrap";
import Button from "@mui/material/Button";
import {
  useGetChamberuizedDocumentQuery,
  useGetPermitDocumentQuery,
  useGetRMADocumentQuery,
  useGetSampleDocumentQuery,
} from "../../features/SCMApi";
import RMADocument from "./RMADocument";
import PermitDocumet from "./PermitDocumet";
import PIDocument from "./PIDocument";
import CIDocument from "./CIDocument";
import PLDocument from "./PLDocument";
import ChamberizedDocument from "./ChamberizedDocument";
import PoDocument from "./PoDocument";
import NoRecord from "../common/NoRecord";
import RequestLetter from "./RequestLetter";
import UnderTakingForm from "./UnderTakingForm";
import BankSubmission from "./BankSubmission";
import SwiftPermit from "./SwiftPermit";
export default function BatchAndOrderNav(props) {
  const { data: Rma } = useGetRMADocumentQuery(props.selectedbatch);
  const { data: Permit } = useGetPermitDocumentQuery(props.selectedbatch);
  const { data: Sample } = useGetSampleDocumentQuery();
  const { data: Chamberized } = useGetChamberuizedDocumentQuery(
    props.selectedbatch
  );
  
  return (
    <div>
      {props?.name === "batch" ? (
        <Tabs
          defaultActiveKey="all"
          id="uncontrolled-tab-example"
          className="mb-3 d-flex justify-content-center bg-white gap-3"
        >
          <Tab eventKey="all" title="All" className="mb-3">
            <PIDocument batchName={props.batch_name} data={Sample} />
            <CIDocument
              data={Sample}
              batchName={props.batch_name}
              selectedbatch={props.selectedbatch}
            />
            <PLDocument
              data={Sample}
              batchName={props.batch_name}
              selectedbatch={props.selectedbatch}
            />
            <PermitDocumet
              data={Permit}
              batchName={props.batch_name}
              selectedbatch={props.selectedbatch}
            />
            <RMADocument
              data={Rma}
              batchName={props.batch_name}
              selectedbatch={props.selectedbatch}
            />
          </Tab>
          <Tab eventKey="pi" title="PI" className="mb-3">
            <PIDocument batchName={props.batch_name} data={Sample} />
          </Tab>

          <Tab eventKey="cl" title="CI" className="mb-3">
            <CIDocument
              data={Sample}
              batchName={props.batch_name}
              selectedbatch={props.selectedbatch}
            />
          </Tab>
          <Tab eventKey="pl" title="PL" className="mb-3">
            <PLDocument
              data={Sample}
              batchName={props.batch_name}
              selectedbatch={props.selectedbatch}
            />
          </Tab>
          <Tab eventKey="permit" title="Permit" className="mb-3">
            <PermitDocumet
              data={Permit}
              batchName={props.batch_name}
              selectedbatch={props.selectedbatch}
            />
          </Tab>
          <Tab eventKey="chamberized" title="Chamberized" className="mb-3">
            <ChamberizedDocument
              data={Chamberized}
              batchName={props.batch_name}
              selectedbatch={props.selectedbatch}
            />
          </Tab>
          <Tab eventKey="RMA" title="RMA" className="mb-3">
            <RMADocument data={Rma} />
          </Tab>
        </Tabs>
      ) : props?.name === "order" ? (
        <Tabs
          defaultActiveKey="all"
          id="uncontrolled-tab-example"
          className="mb-3 d-flex justify-content-center bg-white gap-3"
        >
          <Tab eventKey="all" title="All" className="mb-3">
            <PIDocument orderName={props.orderName} data={Sample} />
            <PoDocument
              data={Sample}
              orderName={props.orderName}
              SelectedOrder={props.SelectedOrder}
            />
          </Tab>
          <Tab eventKey="pi" title="PI" className="mb-3">
            <PIDocument orderName={props.orderName} data={Sample} />
          </Tab>
          <Tab eventKey="po" title="PO" className="mb-3">
            <PoDocument
              data={Sample}
              orderName={props.orderName}
              SelectedOrder={props.SelectedOrder}
            />
          </Tab>
        </Tabs>
      ) : props?.name === "forex" ? (
        <Tabs
          defaultActiveKey="RequestLetter"
          id="uncontrolled-tab-example"
          className="mb-3 d-flex justify-content-center bg-white gap-3"
        >
          <Tab eventKey="RequestLetter" title="Request Letter" className="mb-3">
            <RequestLetter Selectedforex={props?.Selectedforex} />
          </Tab>
          <Tab
            eventKey="Undertakingforms"
            title="Undertaking Forms"
            className="mb-3"
          >
            <UnderTakingForm Selectedforex={props?.Selectedforex} />
          </Tab>
          <Tab
            eventKey="BankSubmission"
            title="Bank Submission"
            className="mb-3"
          >
            <BankSubmission Selectedforex={props?.Selectedforex} />
          </Tab>
          <Tab
            eventKey="swiftpermit"
            title="Swift 	&#38; Permit"
            className="mb-3"
          >
            <SwiftPermit Selectedforex={props?.Selectedforex} />
          </Tab>
        </Tabs>
      ) : (
        <></>
      )}
    </div>
  );
}
