import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import LaunchIcon from "@mui/icons-material/Launch";
import RmaPDModal from "../RmaModals/RmaPreviousDocumentModal";
import RmaSCModal from "../RmaModals/RmaSecurityClearanceModal";
import RmaAWBModal from "../RmaModals/RmaAttachAWBModal";
import RmaCCModal from "../RmaModals/RmaCustomsClearance";
import RmaSAModal from "../RmaModals/RmaShipmentArrangementModal";
import RmaDetailImportContainer from "./RmaDetailImportContainer";

import CheckProjectPermission from "../../../common/checkPermission/CheckProjectPermission";
import {
  useGetUserInfoQuery,
  useGetProjectQuery,
  useGetProjectRolePermissionQuery,
} from "../../../../features/SCMApi";

const Import = styled(Paper)(({ theme }) => ({
  elevation: "0px",
  height: "230px",
  [theme.breakpoints.between("md", "lg")]: {
    height: "230px",
    marginBottom: "-55%",
  },
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  border: "1px solid #AEB8C9",
  borderRadius: "6px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const RmaDetailImport = (props) => {
  //Rma Export Modal states
  const [rmaExportPD, setRmaExportPD] = React.useState(false);
  const [rmaExportSC, setRmaExportSC] = React.useState(false);
  const [rmaExportAWB, setRmaExportAWB] = React.useState(false);
  const [rmaExportCC, setRmaExportCC] = React.useState(false);

  //Rma Import Modal states
  const [rmaImportSA, setRmaImportSA] = React.useState(false);
  const [componentName, setComponentName] = React.useState("");

  let importNames = [
    { name: "Shipment Arrangement", id: 5 },
    { name: "Previous Document", id: 6 },
    {
      name: "Collect AWB",
      id: 7,
    },
    { name: "Security Clearance", id: 8 },
    { name: "Customs Clearance", id: 9 },
  ];

  //Rma Export Modal handlers
  function rmaExportPDHandler() {
    setRmaExportPD(true);
  }
  function rmaExportSCHandler() {
    setRmaExportSC(true);
  }
  function rmaExportAWBHandler() {
    setRmaExportAWB(true);
  }
  function rmaExportCCHandler() {
    setRmaExportCC(true);
  }

  //Rma Import Modal handlers
  function rmaImportSAHandler() {
    setRmaImportSA(true);
  }

  const handleExportImportButtonClick = (e, id) => {
    return id === 1 ? (
      (rmaExportPDHandler(), setComponentName("pdEx"))
    ) : id === 2 ? (
      (rmaExportSCHandler(), setComponentName("scEx"))
    ) : id === 3 ? (
      (rmaExportAWBHandler(), setComponentName("awbEx"))
    ) : id === 4 ? (
      (rmaExportCCHandler(), setComponentName("ccEx"))
    ) : id === 5 ? (
      (rmaImportSAHandler(), setComponentName("saIm"))
    ) : id === 6 ? (
      (rmaExportPDHandler(), setComponentName("pdIm"))
    ) : id === 7 ? (
      (rmaExportAWBHandler(), setComponentName("awbIm"))
    ) : id === 8 ? (
      (rmaExportSCHandler(), setComponentName("scIm"))
    ) : id === 9 ? (
      (rmaExportCCHandler(), setComponentName("ccIm"))
    ) : (
      <></>
    );
  };

  const { data: userinfo } = useGetUserInfoQuery();
  const { data: projectmembers, isSuccess } = useGetProjectQuery(
    props?.projectId
  );
  const { data: roleRoles } = useGetProjectRolePermissionQuery(
    props?.projectId
  );
  return (
    <>
      <RmaDetailImportContainer>
        <Import elevation={0}>
          <div
            className="forexApprovalStageValue"
            style={{
              color: CheckProjectPermission(
                "rma_import_docs",
                projectmembers,
                userinfo,
                roleRoles
              )
                ? "#186569"
                : "#aeb8c9",
            }}
          >
            Import
          </div>
          <div className="rmaImportExportContainer">
            {importNames?.map((item) => (
              <>
                <Button
                  sx={{
                    width: "100%",
                    fontSize: "16px",
                    fontWeight: "400",
                    justifyContent: "flex-start",
                    marginLeft: "30px",
                    color: CheckProjectPermission(
                      "rma_import_docs",
                      projectmembers,
                      userinfo,
                      roleRoles
                    )
                      ? "#353945"
                      : "#aeb8c9",
                    "&:hover": {
                      color: "#186569",
                    },
                  }}
                  onClick={(e) => handleExportImportButtonClick(e, item?.id)}
                  disabled={
                    CheckProjectPermission(
                      "rma_import_docs",
                      projectmembers,
                      userinfo,
                      roleRoles
                    )
                      ? false
                      : true
                  }
                >
                  <LaunchIcon className="rmaExportImportLinkIcon" />
                  <span className="forexApprovalStageLabels">{item?.name}</span>
                </Button>
              </>
            ))}
          </div>
        </Import>
      </RmaDetailImportContainer>
      {rmaExportPD && (
        <RmaPDModal
          modal={setRmaExportPD}
          name={componentName}
          rmaId={props?.rmaId}
          batchId={props?.batchId}
          successMessage={props.successMessage}
          message={props.message}
        />
      )}
      {rmaExportSC && (
        <RmaSCModal
          modal={setRmaExportSC}
          name={componentName}
          rmaId={props?.rmaId}
          batchId={props?.batchId}
          successMessage={props.successMessage}
          message={props.message}
          previousDataIm={props?.importData?.rma_security_clearance?.filter(
            (doc) => doc?.isImport == 1
          )}
        />
      )}
      {rmaExportAWB && (
        <RmaAWBModal
          modal={setRmaExportAWB}
          name={componentName}
          rmaId={props?.rmaId}
          batchId={props?.batchId}
          successMessage={props.successMessage}
          message={props.message}
          previousDataIm={props?.importData?.rma_loading_bills_document?.filter(
            (doc) => doc?.isImport == 1
          )}
        />
      )}
      {rmaExportCC && (
        <RmaCCModal
          modal={setRmaExportCC}
          name={componentName}
          rmaId={props?.rmaId}
          batchId={props?.batchId}
          successMessage={props.successMessage}
          message={props.message}
          previousDataIm={props?.importData?.rma_freight_tax_and_warehouse_fee_document?.filter(
            (doc) => doc?.isImport == 1
          )}
        />
      )}
      {rmaImportSA ? (
        <RmaSAModal
          modal={setRmaImportSA}
          rmaId={props?.rmaId}
          batchId={props?.batchId}
          successMessage={props.successMessage}
          message={props.message}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default RmaDetailImport;
