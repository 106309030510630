import React from "react";
import { useState, useEffect } from "react";
import {
  useGetInventorysRequestsQuery,
  useGetItemCategorysQuery,
  useGetStoreQuery,
  useGetUserInfoQuery,
  useItemsInventoryConsumeMutation,
  useItemsInventoryReceiveMutation,
  useItemsInventoryReturnMutation,
} from "../../../features/SCMApi";
import { Pagination } from "@mui/material";
import StockDialog from "../../common/StockDialog";
import NoRecord from "../../common/NoRecord";
import { IMG_BASE_URL } from "../../../api/endPoint";
import usePagination from "../../Pagination/Pagination";
import { NavLink } from "react-router-dom";
import BatchItems from "../../../assets/SVG/Batchitems.png";
import { date } from "../../dateFormator";
import { useLocation } from "react-router-dom";

export default function OnUseInventory({ inventory }) {
  const { data: RequestedInventory } = useGetInventorysRequestsQuery();

  const { data: loggedUser } = useGetUserInfoQuery();
  let notRequest = [];
  RequestedInventory?.data?.map((items) =>
    items?.is_confirmed == 1 && items?.requester_id == loggedUser?.id
      ? notRequest.push(items)
      : ""
  );

  const [search, setSearch] = useState("");
  const [store_id, setStore_id] = useState("");
  const [category, setCategory] = useState("");
  const { data: store } = useGetStoreQuery();
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const filteredData = (search, category, store_id) => {
    return notRequest
      ?.filter((items) => {
        if (search == "") {
          return items;
        } else if (
          items.item_name?.toLowerCase().includes(search.toLowerCase()) ||
          items.model?.toLowerCase().includes(search.toLowerCase()) ||
          items.serial_number?.toLowerCase().includes(search.toLowerCase())
        ) {
          return items;
        }
      })
      ?.filter((items) => {
        if (category === "") {
          return items;
        } else if (items?.requested_date?.substring(0, 10) == category) {
          return items;
        }
      })
      ?.filter((items) => {
        if (store_id === "") {
          return items;
        } else if (items?.store_id == store_id) {
          return items;
        }
      })
      .map((items) => items);
  };
  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pagenum = parseInt(queryParams.get("page"), 10);
  useEffect(() => {
    if (pagenum) {
      setPage(pagenum);
    }
  }, [pagenum]);

  const count = Math.ceil(
    filteredData(search, category, store_id)?.length / PER_PAGE
  );
  const _DATA = usePagination(
    filteredData(search, category, store_id),
    PER_PAGE
  );
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  const [confirm, setConfirm] = useState(false);
  const [confirmReturn, setConfirmReturn] = useState(false);
  const [confirmConsume, setConfirmConsume] = useState(false);
  const [dialog, setDialog] = useState(false);

  const [returned_quantity, setQuantity] = useState(null);
  const [consumed_quantity, setConsumedQuantity] = useState(null);

  function HandleReceive() {
    setConfirm(true);
  }
  function HandleReturn() {
    setConfirmReturn(true);
  }
  function HandleConsumed() {
    setConfirmConsume(true);
  }
  const [ItemRequest, src] = useItemsInventoryReceiveMutation();
  const [ItemReturn, retrun] = useItemsInventoryReturnMutation();
  const [ItemConsume, consume] = useItemsInventoryConsumeMutation();

  const [error, setError] = useState();
  function HandleToolReceive(e, dialog) {
    let requested = {
      id: dialog?.id,
      received_by: userinfo?.id,
      tool_id: dialog?.tool_id,
      requested_quantity: dialog?.requested_quantity,
      project_id: dialog?.requested_project,
      approved_at: dialog?.approved_date,
      transaction_type: "request",
      inventory_id: dialog?.inventory_id,
      transaction_id: dialog?.id,
      inventory_type: "item",
      qty: dialog?.requested_quantity,
      source: dialog?.store_name,
      destination: dialog?.requested_project,
      requested_by: dialog?.requester_id,
      approved_by: dialog?.confirmed_by,
      status: dialog?.status_name,
      remark: dialog?.remark,
    };

    ItemRequest(requested)
      .unwrap()
      .then((response) => {
        setConfirm(false);
      })
      .catch((error) => {
        setError(error?.data?.message);
      });
  }
  function HandleToolReturn(e, items) {
    let requested = {
      id: items?.id,
      tool_id: items?.tool_id,
      returned_quantity,
    };

    ItemReturn(requested)
      .unwrap()
      .then((response) => {
        setConfirmReturn(false);
      })
      .then((error) => {});
  }

  function HandleToolConsumed(e, items) {
    e.preventDefault();
    let consumedData = {
      id: items?.id,
      consumed_quantity,
    };

    ItemConsume(consumedData)
      .unwrap()
      .then((response) => {
        setConfirmConsume(false);
      })
      .catch((error) => {
        setError(error?.data?.message);
        console.log("Response for consumption error:", error);
      });
  }

  const { data: userinfo } = useGetUserInfoQuery();
  let SK = userinfo?.role?.role_name == "Store Keeper";
  let FE = userinfo?.role?.role_name == "Field Engineer";
  let PM = userinfo?.role?.role_name == "Project Manager";

  return (
    <div>
      <div className="tool-header-container d-flex gap-3 justify-content-center">
        <input
          onChange={handleSearchChange}
          placeholder="search"
          type="search"
          className="inventory-search-input"
        />
        <select
          type="search"
          className="inventory-search-input"
          onChange={(e) => setStore_id(e.target.value)}
        >
          <option value="">Store</option>
          {store?.data?.map((items) => (
            <option value={items?.id}>{items?.store_name}</option>
          ))}
        </select>
        <span>Start Date</span>
        <input
          type="date"
          className="inventory-search-input"
          onChange={(e) => setStartDate(e.target.value)}
        />{" "}
        <span>End Date</span>
        <input
          type="date"
          className="inventory-search-input"
          onChange={(e) => setEndDate(e.target.value)}
        />
        {/* <select
          type="search"
          className="inventory-search-input"
          onChange={(e) => setCategory(e.target.value)}
        >
          <option value="">Item Group</option>
          {ItemCategory?.data?.map((items) => (
            <option value={items?.id}>{items?.category}</option>
          ))}
        </select> */}
      </div>
      {_DATA?.currentData()?.length !== 0 ? (
        <div className="finance-table-container">
          <table
            className="table table-striped table-bordered "
            style={{
              width: "auto",
              minWidth: "2000px",
              borderCollapse: "collapse",
            }}
          >
            <thead className="stock-table-header">
              <tr>
                <th style={{ width: "5%" }}></th>
                <th style={{ width: "5%" }}>ID</th>
                <th style={{ width: "10%" }}>Item Name</th>
                <th style={{ width: "8%" }}>Model</th>
                <th style={{ width: "8%" }}>Type</th>
                <th style={{ width: "8%" }}>Location</th>
                <th style={{ width: "8%" }}>Project</th>
                <th style={{ width: "8%" }}>Requested Date</th>
                <th style={{ width: "8%" }}>Approved By</th>
                <th style={{ width: "8%" }}>Approved Date</th>
                <th style={{ width: "8%" }}>Returned Date</th>
                <th style={{ width: "12%" }}>Action</th>
                <th style={{ width: "10%" }}>Remark</th>
              </tr>
            </thead>
            <tbody>
              {_DATA?.currentData()?.map((tool) => (
                <tr className="stock-table-row">
                  <td className="">
                    {tool?.image != null ? (
                      <img
                        className="stock-img"
                        src={`${IMG_BASE_URL}${tool?.image}`}
                        alt=""
                      />
                    ) : (
                      <img
                        sx={{ width: 50, height: 50, borderRadius: 0 }}
                        src={BatchItems}
                        alt=""
                      />
                    )}
                  </td>
                  <td className="text-start">{tool?.id}</td>
                  <td className="text-start">
                    {" "}
                    {tool?.is_batch == 1 ? "Batch requests" : tool?.item_name}
                  </td>
                  <td className="text-start">
                    {tool?.model === null ? "-" : tool?.model}
                  </td>
                  <td className="text-start">
                    {tool?.tool_type_id === null ? "-" : tool?.tool_type}
                  </td>
                  <td className="text-start">
                    {" "}
                    {tool?.store_name === null ? "-" : tool?.store_name}
                  </td>
                  <td className="text-start">
                    {tool?.requested_project_name || "-"}
                  </td>
                  <td className="text-start">
                    {tool?.requested_date ? date(tool?.requested_date) : "-"}
                  </td>
                  <td className="text-start">
                    {tool?.store_keeper_name ? tool?.store_keeper_name : "-"}
                  </td>
                  <td className="text-start">
                    {tool?.confirmed_date ? date(tool?.confirmed_date) : "-"}
                  </td>
                  <td className="text-start">
                    {tool?.is_returned
                      ? tool?.is_batch
                        ? `Returned at ${tool?.returned_date}`
                        : `Returned ${tool?.returned_quantity} ${tool?.uom_name} at ${tool?.returned_date.split(" ")[0]}`
                      : ""}
                    <br />
                    {tool?.consumed_amount > 1
                      ? `Consumed ${tool?.consumed_amount} ${tool?.uom_name}`
                      : ""}
                    {/* {`Returned ${date(tool?.returned_date)}`}
                    {tool?.is_storekeeper_received &&
                      ` Store Keeper Received ${date(tool?.storekeeper_received_date)}`} */}
                  </td>

                  <td className="text-start">
                    {true ? (
                      tool.is_requester_received == 0 &&
                      userinfo?.id == tool?.requester_id ? (
                        tool?.is_batch == 1 ? (
                          <NavLink
                            className="form-save p-2"
                            to={`ReceivedBatchItems/${tool?.id}`}
                          >
                            View More
                          </NavLink>
                        ) : (
                          <button
                            className="form-save"
                            onClick={() => {
                              HandleReceive();
                              setDialog(tool);
                            }}
                          >
                            Receive
                          </button>
                        )
                      ) : userinfo?.id == tool?.requester_id ? (
                        tool?.is_batch == 1 ? (
                          <NavLink
                            className="form-save p-2"
                            to={`ReceivedBatchItems/${tool?.id}`}
                          >
                            View More
                          </NavLink>
                        ) : tool?.returned_quantity + tool?.consumed_amount ==
                          tool?.requested_quantity ? (
                          ""
                        ) : (
                          <>
                            <button
                              className="form-save"
                              onClick={() => {
                                HandleReturn();
                                setDialog(tool);
                              }}
                            >
                              Return
                            </button>

                            <button
                              className="form-save"
                              onClick={() => {
                                HandleConsumed();
                                setDialog(tool);
                              }}
                            >
                              Consumed
                            </button>
                          </>
                        )
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="text-start">
                    {tool?.is_storekeeper_received ? (
                      <>
                        {/* Received by :{" "}
                        {tool?.store_keeper_name
                          ? tool?.store_keeper_name
                          : "-"}
                        ,
                        <br /> */}
                        Remark : {tool?.remark ? tool?.remark : "no remark"}
                      </>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <NoRecord />
      )}
      {count > 1 ? (
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
          className="p-2"
        />
      ) : (
        ""
      )}
      {confirm ? (
        <StockDialog
          project_id={1}
          setDialog={setConfirm}
          dialog={`${dialog?.item_name} `}
          data={dialog}
          error={error}
          name={"Receive"}
          action={(e) => HandleToolReceive(e, dialog)}
        />
      ) : (
        ""
      )}
      {confirmReturn ? (
        <StockDialog
          project_id={1}
          setDialog={setConfirmReturn}
          dialog={`${dialog?.item_name} `}
          receive_title={"You Received"}
          data={dialog}
          action={(e) => HandleToolReturn(e, dialog)}
          setQuantity={setQuantity}
          is_return={true}
          name="return"
          quantity={returned_quantity}
        />
      ) : (
        ""
      )}
      {confirmConsume ? (
        <StockDialog
          project_id={1}
          setDialog={setConfirmConsume}
          dialog={`${dialog?.item_name} `}
          receive_title={"You Consumed"}
          data={dialog}
          action={(e) => HandleToolConsumed(e, dialog)}
          setQuantity={setConsumedQuantity}
          is_return={true}
          name="consume"
          quantity={consumed_quantity}
        />
      ) : (
        ""
      )}
    </div>
  );
}
