import React from "react";
import "./home.style.css";
import NavBar from "./../../Layout/NavBar";
import Task from "../../components/home/Task";
import Rma from "../../components/home/Rma";
import PurchaseOrder from "../../components/home/PurchaseOrder";
import ProjectClient from "../../components/home/ProjectClient";
import ForexNeed from "../../components/home/ForexNeed";
import TopSuppliers from "../../components/home/TopSuppliers";
import InventoryDashboard from "../../components/home/InventoryDashboard";
import ToolsDashboard from "../../components/home/ToolsDashboard";
import ForexDasboard from "../../components/home/ForexDasboard";
import PaymentTrackingDashboard from "../../components/home/PaymentTrackingDashboard";
import { useGetNotificationQuery } from "../../features/SCMApi";

const Index = () => {
  const { data: notifications } = useGetNotificationQuery();
  const unreadNotifications =
    notifications &&
    notifications?.filter((notification) => notification?.read_at == null);
  let Notask = unreadNotifications?.length != 0;
  return (
    <>
      <NavBar />
      <div className="page">
        <div className="main-content">
          <div className="task-rma-card">
            <Task />
          </div>
          <div className="">
            <ProjectClient />
            <ForexNeed />
          </div>
        </div>
        <div className="topsupplier-inventory-tool">
          <TopSuppliers />

          <InventoryDashboard />

          <ToolsDashboard />
        </div>
        <div
          className={
            unreadNotifications?.length != 0 ? `forex-payment` : "mt-4"
          }
        >
          <ForexDasboard Notask={Notask} />
          {unreadNotifications?.length != 0 ? (
            <PaymentTrackingDashboard Notask={Notask} />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};
export default Index;
