export default function CheckProjectPermission(
  Permission,
  projectmembers,
  userinfo,
  roleRoles
) {
  
  if (userinfo?.permissions[0]?.slug === "all") {
    const userId = userinfo?.id;
    
    let isAuthorized = true;
    let permissions = userinfo?.permissions;
    const condition = permissions?.filter(
      (item) => item?.slug === Permission || item?.slug === "all"
    );
    condition?.length ? (isAuthorized = true) : (isAuthorized = false);
    
    return isAuthorized;
  } else {
    const userId = userinfo?.id;
    
    let isAuthorized = false;
    let usersRole = [];
    let setOfUserRole = [];
    let members =
      projectmembers?.data?.users === undefined
        ? []
        : projectmembers?.data?.users;
   
    const isMember = [...members?.filter((member) => member?.id === userId)];

   
    if (isMember?.length > 0) {
      
      isMember?.map((useRoles) => usersRole.push(useRoles?.pivot?.role_id));
      setOfUserRole = roleRoles?.data?.roles?.filter((role) =>
        usersRole?.includes(role?.id)
      );
    }
    
    let permissions = [];
    let bool = setOfUserRole?.filter((items) =>
      permissions.push(...items?.permissions)
    );

    const condition = permissions?.filter((item) => item?.slug === Permission); // permissions = [...permissions];
    condition.length ? (isAuthorized = true) : (isAuthorized = false);
    
    return isAuthorized;
  }
}
