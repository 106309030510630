import React from "react";
import ReportTopBar from "../../components/Payment/ForexApproval/PaymentSideBar";
import SupplierReportsOffShore from "./SupplierReportsOffShore";
import SupplierReportsOnShore from "./SupplierReportsOnShore";
import { Tab, Tabs } from "react-bootstrap";
import Supplier from "../../pages/Supplier";

export default function SupplierReportsIndex() {
  return (
    <Supplier title={"Reports"}>
      <Tabs
        defaultActiveKey="onShore"
        id="uncontrolled-tab-example"
        className="mb-3 d-flex justify-content-center bg-white rounded gap-4 border forexApprovalNav"
      >
        <Tab eventKey="onShore" title="OnShore">
          <SupplierReportsOnShore />
        </Tab>
        <Tab eventKey="offShore" title="OffShore">
          <SupplierReportsOffShore />
        </Tab>
      </Tabs>
    </Supplier>
  );
}
