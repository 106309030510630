import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import LaunchIcon from "@mui/icons-material/Launch";
import MissedItemPDModal from "../MissedItemModals/PreviousDocumentModal";
import MissedItemRLModal from "../MissedItemModals/ReportLetter";
import MissedItemSCModal from "../MissedItemModals/SecurityClearanceModal";
import MissedItemDCModal from "../MissedItemModals/DocumentCollection";
import MissedItemCCModal from "../MissedItemModals/CustomsClearance";
import MissedItemSAModal from "../MissedItemModals/ShipmentArrangementModal";
import MissedItemIDModal from "../MissedItemModals/ItemDeliveryModal";
import MissedItemDetailStagesContainer from "./MissedItemDetailStagesontainer";

import CheckProjectPermission from "../../../common/checkPermission/CheckProjectPermission";
import {
  useGetUserInfoQuery,
  useGetProjectQuery,
  useGetProjectRolePermissionQuery,
} from "../../../../features/SCMApi";

const Stages = styled(Paper)(({ theme }) => ({
  elevation: "0px",
  height: "310px",
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  marginTop: "20px",
  border: "1px solid #AEB8C9",
  borderRadius: "6px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const MissedItemDetailStages = (props) => {

  //MissedItem Stages Modal states
  const [MissedItemStagesRL, setMissedItemStagesRL] = React.useState(false);
  const [MissedItemStagesPD, setMissedItemStagesPD] = React.useState(false);
  const [MissedItemStagesSC, setMissedItemStagesSC] = React.useState(false);
  const [MissedItemStagesDC, setMissedItemStagesDC] = React.useState(false);
  const [MissedItemStagesCC, setMissedItemStagesCC] = React.useState(false);
  const [MissedItemStagesSA, setMissedItemStagesSA] = React.useState(false);
  const [MissedItemStagesId, setMissedItemStagesId] = React.useState(false);

  const [componentName, setComponentName] = React.useState("");

  //MissedItem Stages Modal handlers
  function MissedItemStagesRLHandler() {
    setMissedItemStagesRL(true);
  }
  function MissedItemStagesPDHandler() {
    setMissedItemStagesPD(true);
  }
  function MissedItemStagesSCHandler() {
    setMissedItemStagesSC(true);
  }
  function MissedItemStagesDCHandler() {
    setMissedItemStagesDC(true);
  }
  function MissedItemStagesCCHandler() {
    setMissedItemStagesCC(true);
  }
  //MissedItem Import Modal handlers
  function MissedItemStagesSAHandler() {
    setMissedItemStagesSA(true);
  }
  function MissedItemStagesIdHandler() {
    setMissedItemStagesId(true);
  }

  let importNames = [
    {
      name: "Report Letter",
    },
    { name: "Shipment Arrangement" },
    { name: "Previous Document" },
    { name: "Document Collection" },
    { name: "Security Clearance" },
    { name: "Customs Clearance" },
    {
      name: "Item Delivery",
    },
  ];

  const handleStagesButtonClick = (e, name) => {
    return name === "Report Letter" ? (
      (MissedItemStagesRLHandler(), setComponentName("rl"))
    ) : name === "Shipment Arrangement" ? (
      (MissedItemStagesSAHandler(), setComponentName("sc"))
    ) : name === "Previous Document" ? (
      (MissedItemStagesPDHandler(), setComponentName("pd"))
    ) : name === "Document Collection" ? (
      (MissedItemStagesDCHandler(), setComponentName("dc"))
    ) : name === "Security Clearance" ? (
      (MissedItemStagesSCHandler(), setComponentName("sc"))
    ) : name === "Customs Clearance" ? (
      (MissedItemStagesCCHandler(), setComponentName("cc"))
    ) : name === "Item Delivery" ? (
      (MissedItemStagesIdHandler(), setComponentName("ID"))
    ) : (
      <></>
    );
  };

  const { data: userinfo } = useGetUserInfoQuery();
  const { data: projectmembers, isSuccess } = useGetProjectQuery(
    props?.projectId
  );
  const { data: roleRoles } = useGetProjectRolePermissionQuery(
    props?.projectId
  );
  return (
    <>
      <MissedItemDetailStagesContainer>
        <Stages elevation={0}>
          <div
            className="forexApprovalStageValue"
            style={{
              color: CheckProjectPermission(
                "missed_item_update_status",
                projectmembers,
                userinfo,
                roleRoles
              )
                ? "#186569"
                : "#aeb8c9",
            }}
          >
            Stages
          </div>
          <div className="rmaImportExportContainer">
            {importNames?.map((item) => (
              <>
                <Button
                  sx={{
                    width: "100%",
                    fontSize: "16px",
                    fontWeight: "400",
                    color: CheckProjectPermission(
                      "missed_item_update_status",
                      projectmembers,
                      userinfo,
                      roleRoles
                    )
                      ? "#353945"
                      : "#aeb8c9",
                    justifyContent: "flex-start",
                    "&:hover": {
                      color: "#186569",
                    },
                  }}
                  onClick={(e) => handleStagesButtonClick(e, item?.name)}
                  disabled={
                    CheckProjectPermission(
                      "missed_item_update_status",
                      projectmembers,
                      userinfo,
                      roleRoles
                    )
                      ? false
                      : true
                  }
                >
                  <LaunchIcon className="rmaExportImportLinkIcon" />
                  <span className="forexApprovalStageLabels">{item?.name}</span>
                </Button>
              </>
            ))}
          </div>
        </Stages>
      </MissedItemDetailStagesContainer>
      {MissedItemStagesRL && (
        <MissedItemRLModal
          modal={setMissedItemStagesRL}
          name={componentName}
          missedItemId={props?.missedItemId}
          batchId={props?.batchId}
          successMessage={props.successMessage}
          message={props.message}
          previousData={props?.stageData?.missed_item_email_communication_and_support_letter?.filter(
            (doc) => doc?.report_letter !== null
          )}
        />
      )}
      {MissedItemStagesPD && (
        <MissedItemPDModal
          modal={setMissedItemStagesPD}
          name={componentName}
          missedItemId={props?.missedItemId}
          batchId={props?.batchId}
          successMessage={props.successMessage}
          message={props.message}
          previousData={props?.stageData?.missed_item_email_communication_and_support_letter?.filter(
            (doc) => doc?.email_communication !== null
          )}
        />
      )}
      {MissedItemStagesSC && (
        <MissedItemSCModal
          modal={setMissedItemStagesSC}
          name={componentName}
          missedItemId={props?.missedItemId}
          batchId={props?.batchId}
          successMessage={props.successMessage}
          message={props.message}
          previousData={props?.stageData?.missed_item_security_clearance}
        />
      )}
      {MissedItemStagesDC && (
        <MissedItemDCModal
          modal={setMissedItemStagesDC}
          name={componentName}
          missedItemId={props?.missedItemId}
          batchId={props?.batchId}
          successMessage={props.successMessage}
          message={props.message}
          previousData={props?.stageData?.missed_item_loading_bills_document}
        />
      )}
      {MissedItemStagesCC && (
        <MissedItemCCModal
          modal={setMissedItemStagesCC}
          name={componentName}
          missedItemId={props?.missedItemId}
          batchId={props?.batchId}
          successMessage={props.successMessage}
          message={props.message}
          previousData={props?.stageData?.missed_item_custom_clearance}
        />
      )}
      {MissedItemStagesSA && (
        <MissedItemSAModal
          modal={setMissedItemStagesSA}
          name={componentName}
          missedItemId={props?.missedItemId}
          batchId={props?.batchId}
          successMessage={props.successMessage}
          message={props.message}
        />
      )}
      {MissedItemStagesId && (
        <MissedItemIDModal
          modal={setMissedItemStagesId}
          name={componentName}
          missedItemId={props?.missedItemId}
          batchId={props?.batchId}
          successMessage={props.successMessage}
          message={props.message}
          previousData={props?.stageData?.missed_item_item_delivery}
        />
      )}
    </>
  );
};

export default MissedItemDetailStages;
