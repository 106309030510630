import Close from "@mui/icons-material/Close";
import React from "react";
import { useState } from "react";
import {
  useBatchRequestInventoryMutation,
  useBatchRequestToolsMutation,
  useGetInventoryQuery,
  useGetItemCategorysQuery,
  useGetStoreQuery,
  useGetToolsQuery,
  useGetUserInfoQuery,
} from "../../../features/SCMApi";
import DeleteIcon from "@mui/icons-material/Delete";
import BatchRequest from "./BatchRequest";
import BatchRequestTools from "./BatchRequestTools";

export default function ConfirmRequest(props) {
  function HandleCancel() {
    props.modal(false);
  }
  const [confirmRequestItems, setConfirmRequestItems] = useState(props?.data);

  function handleBatchRequest(e, items, i) {
    e.preventDefault();
    const value = e.target.value;
    const tools = [...confirmRequestItems];
    tools[i] = { ...items, requested_amount: value };
    setConfirmRequestItems(tools);
  }

  const { data: userinfo } = useGetUserInfoQuery();

  let batchRequest = {
    requested_items: JSON.stringify(confirmRequestItems),
    requested_by: userinfo?.id,
    is_batch: true,
  };
  const [batchRequestInventory, res] = useBatchRequestInventoryMutation();
  const [modal, setModal] = useState(false);

  const HandleRequest = async (e) => {
    setModal(true);
  };

  const [formData, setFormData] = useState(props?.data);

  const removeForm = (id) => {
    const filteredArray = formData.filter((obj) => obj.id !== id);
    setFormData(filteredArray);
  };

  let notRequest = [];
  const { data: getTools } = useGetToolsQuery();

  props?.assets == undefined
    ? getTools?.data?.map((items) =>
        (items?.is_requested == 0 && items?.store?.is_permanent == 1) ||
        (items?.is_requested == 1 &&
          items?.store?.is_permanent == 1 &&
          items?.amount != 0)
          ? notRequest.push(items)
          : ""
      )
    : props?.assets?.map((items) =>
        items?.is_requested == 0 ||
        (items?.is_requested == 1 && items?.amount != 0)
          ? notRequest.push(items)
          : ""
      );

  const { data: store } = useGetStoreQuery();
  const { data: ItemCategory } = useGetItemCategorysQuery();
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const [store_id, setStore_id] = useState("");
  const [category, setCategory] = useState("");
  const [search, setSearch] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [batch_items, setBatchItems] = useState([]);
  const [Items, setItems] = useState("FixedAssets");

  const filteredData = (search, category, store_id) => {
    return notRequest

      ?.filter((items) => {
        if (search === "" || items?.store_id == null) {
          return items;
        } else if (
          items.item_name?.toLowerCase().includes(search.toLowerCase()) ||
          items.model?.toLowerCase().includes(search.toLowerCase()) ||
          items.serial_number?.toLowerCase().includes(search.toLowerCase())
        ) {
          return items;
        }
      })
      ?.filter((items) => {
        if (category === "") {
          return items;
        } else if (items?.category_id == category) {
          return items;
        }
      })
      ?.filter((items) => {
        if (store_id === "") {
          return items;
        } else if (items?.store?.id == store_id) {
          return items;
        }
      })
      ?.filter((items) => {
        if (
          Items === "FixedAssets" &&
          items?.is_consumable == 0 &&
          items?.fixed_asset_category_id !=
            "abc87cad-0096-4113-8e50-6c3d583692ec"
        ) {
          return items;
        } else if (Items === "consumableAssets" && items?.is_consumable == 1) {
          return items;
        } else if (
          Items === "ItAssets" &&
          items?.fixed_asset_category_id ==
            "abc87cad-0096-4113-8e50-6c3d583692ec"
        ) {
          return items;
        }
      })
      .map((items) => items);
  };
  const toggleSelection = (id, items) => {
    const selectedIndex = selectedItems.indexOf(id);
    let newSelectedItems = [...selectedItems];

    if (selectedIndex === -1) {
      newSelectedItems.push(id);
    } else {
      newSelectedItems.splice(selectedIndex, 1);
    }

    setSelectedItems(newSelectedItems);
  };

  const getSelectedItems = () => {
    return filteredData(search, category, store_id)?.filter((item) =>
      selectedItems.includes(item.id)
    );
  };
  let items = getSelectedItems().flat(1);

  return (
    <div id="mymodal" className="modal" onClick={() => HandleCancel()}>
      <div className="modal-content-lg" onClick={(e) => e.stopPropagation()}>
        <div className="create-project-modal-title">
          <h5>Batch Request </h5>
          <Close onClick={HandleCancel} />
        </div>
        <div className="d-flex justify-content-center gap-3">
          <div className="d-grid gap-1">
            <div className="project-name">Search</div>
            <input
              onChange={handleSearchChange}
              placeholder="search"
              type="search"
              className="inventory-search-input"
            />
          </div>
          <div className="d-grid gap-1">
            <div className="project-name">Store </div>
            <select
              type="search"
              className="inventory-search-input"
              onChange={(e) => setStore_id(e.target.value)}
            >
              <option value="">Store</option>
              {store?.data?.map((items) => (
                <option value={items?.id}>{items?.store_name}</option>
              ))}
            </select>
          </div>
          <div className="d-grid gap-1">
            <div className="project-name">Item category</div>
            <select
              type="search"
              className="inventory-search-input"
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="">Item Group</option>
              {ItemCategory?.data?.map((items) => (
                <option value={items?.id}>{items?.category}</option>
              ))}
            </select>
          </div>
          <div className="pi-modal-footer d-flex justify-content-center">
            <button onClick={HandleCancel}>Cancel</button>
            {store_id != "" ? (
              <button
                disabled={items?.length >= 2 ? false : true}
                type="submit"
                className={
                  items?.length >= 2
                    ? "form-save "
                    : "form-save bg-secondary text-white"
                }
                onClick={(e) => HandleRequest(e)}
              >
                Next
              </button>
            ) : (
              <small className="prg-error text-danger">
                Please select store to proceed
              </small>
            )}
          </div>
        </div>
        {props?.isAsset ? (
          <div className="tool-header-container d-flex gap-3 justify-content-center">
            <input
              checked={Items == "FixedAssets"}
              id="default-radio-2"
              type="radio"
              name="default-radio"
              className="cursor-pointer"
              onClick={() => setItems("FixedAssets")}
            />
            <label for="default-radio-2" className="prg">
              Fixed Asset
            </label>
            <input
              checked={Items == "consumableAssets"}
              id="default-radio-2"
              type="radio"
              name="default-radio"
              className="cursor-pointer"
              onClick={() => setItems("consumableAssets")}
            />
            <label for="default-radio-2" className="prg">
              Consumable
            </label>
            <input
              checked={Items == "ItAssets"}
              id="default-radio-2"
              type="radio"
              name="default-radio"
              className="cursor-pointer"
              onClick={() => setItems("ItAssets")}
            />
            <label for="default-radio-2" className="prg">
              IT Asset
            </label>
          </div>
        ) : (
          ""
        )}
        <div
          className="d-grid p-5"
          style={{ gridTemplateColumns: "20% 20% 20% 20% 20%" }}
        >
          {filteredData(search, category, store_id)?.map((items) => (
            <label className="d-flex gap-2 align-items-baseline ">
              <input
                type="checkbox"
                checked={selectedItems.includes(items.id)}
                onChange={() => toggleSelection(items.id, items)}
              />
              <span className="group-prg">{items.item_name}</span>
            </label>
          ))}
        </div>
        {modal ? (
          <BatchRequestTools
            modal={setModal}
            data={items}
            isTools={props?.isTools}
            module_name={Items}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
