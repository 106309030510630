import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { NavLink } from "react-router-dom";
import "./logistics.css";

const SettingBreadCramp = () => {
  function handleClick(event) {
    event.preventDefault();
  }

  return (
    <div
      style={{
        marginLeft: "0px",
        backgroundColor: "#eff2f3",
        borderBottom: "1px solid #AEB8C9",
      }}
      role="presentation"
      onClick={handleClick}
    >
      <Breadcrumbs aria-label="breadcrumb">
        <NavLink
          className="breadCrampLogNav"
          to="/logistics"
          activeClassName="active"
        >
          Logistics
        </NavLink>
      </Breadcrumbs>
    </div>
  );
};

export default SettingBreadCramp;
