import React, { useState } from "react";
import DocumentCollectionModal from "../../../components/Logistics/DocumentCollectionModal";
import ShipmentArrangementModal from "../../../components/Logistics/ShipmentArrangementModal";
import NavBar from "../../../Layout/NavBar";
import BatchAccordion from "./BatchAcrodion";

export default function LogisticsDocumentApproval() {
  return (
    <>
      <NavBar />
      <div className="page ">
        <div className="logistics-batch-detail-main mt-4">
          <div className="logistics-batch-detail">
            <div className="batch-header">Documents</div>
            <div className="according-wrapper">
              <BatchAccordion key={0} />
              <BatchAccordion key={1} />
              <BatchAccordion key={2} />
              <BatchAccordion key={3} />
            </div>
          </div>
          <div className="row gap-3" style={{ height: "fit-content" }}>
            <div className="col-12 py-14 date-card  ">
              <div className="date-value-logistics">April 1, 2022</div>
              <div className="card-sm-label-logistics">ETA</div>
            </div>
            <div className="col-12 py-14 date-card">
              <div className="country-value-logistics">UAE</div>
              <div className="card-sm-label-logistics">Origin</div>
            </div>
            <div className="col-12  status-card">
              <div className="country-value-logistics">UAE</div>
              <div className="card-md-label-note">
                Lorem ipsum doret sit amets Lorem ipsum doret sit amets
              </div>
              <button className="add-client-representative">
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.5 17.5004H2.6L13.675 6.42536L12.575 5.32536L1.5 16.4004V17.5004ZM16.85 5.35036L13.65 2.15036L14.7 1.10036C14.9833 0.817024 15.3375 0.679524 15.7625 0.687857C16.1875 0.69619 16.5417 0.842024 16.825 1.12536L17.9 2.20036C18.1833 2.48369 18.325 2.83369 18.325 3.25036C18.325 3.66702 18.1833 4.01702 17.9 4.30036L16.85 5.35036ZM0.75 19.0004C0.533333 19.0004 0.354167 18.9295 0.2125 18.7879C0.0708334 18.6462 0 18.467 0 18.2504V16.1004C0 16.0004 0.0166666 15.9087 0.05 15.8254C0.0833333 15.742 0.141667 15.6587 0.225 15.5754L12.6 3.20036L15.8 6.40036L3.425 18.7754C3.34167 18.8587 3.25833 18.917 3.175 18.9504C3.09167 18.9837 3 19.0004 2.9 19.0004H0.75ZM13.125 5.87536L12.575 5.32536L13.675 6.42536L13.125 5.87536Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
            <div className="col-12 py-14 px-30 logistics-officer-card">
              <div className="row align-items-center justify-content-center h-100">
                <div className="col-2  justify-content-center">
                  <img
                    src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80"
                    alt=""
                  />
                </div>
                <div className="col-8">
                  <div className="row">
                    <div className="date-value-logistics">Mikiyas Girma</div>
                    <div className="card-sm-label-logistics">
                      Logistics Officer
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
