import { useEffect, useState } from "react";
import { useGetProjectsQuery } from "../../../features/SCMApi";
import axios from "axios";
import { API_BASE_URL } from "../../../api/endPoint";

function RecievableFilter({
  searchQuery,
  searchOptionProjectId,
  searchOptionDate,
  handleSearchSubmit,
  setSearchQuery,
  setSearchOptionProjectId,
  setSearchOptionDate,
}) {
  const [currecy, setCurrency] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/scm/currencies`)
      .then((response) => {
     
        setCurrency(response.data.data);
      })
      .catch((err) => {
        console.error("error", err);
      });
  }, []);

  const { data: project } = useGetProjectsQuery();


  return (
    <div>
      <form onSubmit={handleSearchSubmit}>
        <div className="topBar my-2">
          <div className="">
            <select
              className="border rounded border-#184b4f px-3 border-3 py-1"
              style={{ width: "180px" }}
              name="search-query"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            >
              <option value="">Select Currency</option>
              {currecy.map((items, index) => (
                <option key={index} className="w-full m-5" value={items.code}>
                  {items.code}
                </option>
              ))}
            </select>
          </div>
          <div>
            <input
              className="border rounded border-#184b4f px-3 border-3 py-1"
              style={{ width: "180px" }}
              type="date"
              placeholder="Select Date"
              value={searchOptionDate}
              onChange={(e) => setSearchOptionDate(e.target.value)}
            />
          </div>
          <div>
            <select
              className="border rounded border-#184b4f px-3 border-3 py-1"
              style={{ width: "180px" }}
              value={searchOptionProjectId}
              onChange={(e) => setSearchOptionProjectId(e.target.value)}
            >
              <option value="">Select Project</option>
              {project?.data?.map((items, index) => (
                <option key={index} className="w-full m-5" value={items?.id}>
                  {items?.project_name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </form>
    </div>
  );
}

export default RecievableFilter;
