import React, { useState } from "react";
import {
  useAddClientMutaion,
  useGetRmaMissedItemDocumentsQuery,
} from "../../../../features/SCMApi";
import { DOC_BASE_URL } from "../../../../api/endPoint";
import axios from "axios";
import { API_BASE_URL } from "../../../../api/endPoint";
import "../../logistics.css";
import Button from "@mui/material/Button";
import attachmentIcon from "../../../../assets/SVG/attach.svg";

export default function RmaCustomsClearance(props) {
  // const [addClient, response] = useAddClientMutaion();
  const { data: customerClearanceDocumentData } =
    useGetRmaMissedItemDocumentsQuery();

  const [freightTax, setFreightTax] = useState(null);
  const [carrierFreight, setCarrierFreight] = useState(null);
  const [exportDeclaration, setExportDeclaration] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  let params = {
    batch_id: props?.batchId,
    rma_id: props?.rmaId,
    freight_tax: freightTax,
    carrier_freight: carrierFreight,
    export_declaration: exportDeclaration,
  };

  let paramsImport = {
    batch_id: props?.batchId,
    rma_id: props?.rmaId,
    freight_tax: freightTax,
    warehouse_fee: carrierFreight,
  };
  const handleFreightTax = (e) => {
    setFreightTax(e?.target?.files[0]);
  };
  const handleCarrierFreight = (e) => {
    setCarrierFreight(e?.target?.files[0]);
  };
  const handleExportDeclaration = (e) => {
    setExportDeclaration(e?.target?.files[0]);
  };
  function HandleCancel() {
    props.modal(false);
  }

  const HandleSubmit = async (e) => {
    e.preventDefault();
    props?.previousDataEx?.length === 0 || props?.previousDataIm?.length === 0
      ? axios
          .post(
            props.name === "ccEx" ? (
              `${API_BASE_URL}/scm/rma/export/custom-clearance`
            ) : props.name === "ccIm" ? (
              `${API_BASE_URL}/scm/rma/import/custom-clearance`
            ) : (
              <></>
            ),
            props.name === "ccEx" ? (
              params
            ) : props.name === "ccIm" ? (
              paramsImport
            ) : (
              <></>
            ),
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            props?.successMessage(true);
            props?.message(
              "Customs clearance document attached successfully!!!"
            );
            
            HandleCancel();
          })
          .then((error) => {
            
          })
      : axios
          .post(
            props?.name === "ccEx" ? (
              `${API_BASE_URL}/scm/rma/export/custom-clearance/${props?.previousDataEx[0]?.id}`
            ) : props?.name === "ccIm" ? (
              `${API_BASE_URL}/scm/rma/import/custom-clearance/${props?.previousDataIm[0]?.id}`
            ) : (
              <></>
            ),

            params,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            
            HandleCancel();
            props?.successMessage(true);
            props?.message("Custom clearance document updated successfully!!!");
          })
          .then((error) => {
           
          });
    // addClient(params)
    //   .unwrap()
    //   .then((response) => {
    
    //   })
    //   .then((error) => {
    
    //   });
  };

  const exportFilesLabelExport =
    props.name === "ccEx" ? [{ label: "Import Declaration" }] : <></>;
  const exportFilesLabelImport =
    props.name === "ccIm" ? (
      [{ label: "Import Declaration" }, { label: "Export Declaration" }]
    ) : (
      <></>
    );
  const importFilesLabel =
    props.name === "ccEx" ? (
      [
        { label: "Freight Tax" },
        { label: "Carrier Freight" },
        { label: "Export Declaration" },
      ]
    ) : props.name === "ccIm" ? (
      [{ label: "Freight Tax" }, { label: "Warehouse Fee" }]
    ) : (
      <></>
    );

  return (
    <>
      <div id="myModal" className="modal" onClick={HandleCancel}>
        <div
          className="mini-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="logisticsRma-create-project-modal-title">
            <div className="previousDocumnetTitle">Customs Clearance</div>
            <div onClick={HandleCancel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="42"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>
          {props.name === "ccEx" ? (
            exportFilesLabelExport?.map((item) => (
              <div className="rmaDownloadBox">
                <span className="rmaDownloadLabel">{item?.label}</span>
                <a
                  href={`${DOC_BASE_URL}scm/document/download?file_path=${
                    item?.label === "Import Declaration"
                      ? customerClearanceDocumentData?.data[0]
                          ?.import_declaration
                      : ""
                  }`}
                >
                  <Button>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="#186569"
                      class="bi bi-download"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                    </svg>
                  </Button>
                </a>
              </div>
            ))
          ) : (
            <></>
          )}
          {props.name === "ccIm" ? (
            exportFilesLabelImport?.map((item) => (
              <div className="rmaDownloadBox">
                <span className="rmaDownloadLabel">{item?.label}</span>
                <a
                  href={`${DOC_BASE_URL}scm/document/download?file_path=${
                    item?.label === "Import Declaration"
                      ? customerClearanceDocumentData?.data[0]
                          ?.import_declaration
                      : item?.label === "Export Declaration"
                      ? customerClearanceDocumentData?.data[0]
                          ?.export_declaration
                      : ""
                  }`}
                >
                  <Button sx={{}}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="#186569"
                      class="bi bi-download"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                    </svg>
                  </Button>
                </a>
              </div>
            ))
          ) : (
            <></>
          )}
          <div className="rmaFileAttachLabel">
            {props.name === "ccIm" ? "Attach Freight Tax" : "Attach Freight"}
          </div>
          <div className="rmaFileAttachLabelDivider" />

          <form
            className="form-container rmaMissedItemForm forexApprovalForms"
            onSubmit={HandleSubmit}
          >
            <div className="form-card-container">
              <div className="form-card-information">
                {errorMessage === true ? (
                  <>
                    <p
                      className="forex-attached-file-warning"
                      style={{ marginTop: "0px", marginBottom: "20px" }}
                    >
                      Please select a file !!!
                    </p>
                  </>
                ) : props?.name === "ccEx" ? (
                  props?.previousDataEx?.length !== 0 ? (
                    <>
                      <p
                        className="forex-attached-file-warning"
                        style={{ marginTop: "0px", marginBottom: "20px" }}
                      >
                        Custom clearance document already attached !!!
                      </p>
                    </>
                  ) : (
                    <></>
                  )
                ) : props?.name === "ccIm" ? (
                  props?.previousDataIm?.length !== 0 ? (
                    <>
                      <p
                        className="forex-attached-file-warning"
                        style={{ marginTop: "0px", marginBottom: "20px" }}
                      >
                        Custom clearance document already attached !!!
                      </p>
                    </>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}

                <div className="card-center">
                  <div className="card-form">
                    <div className="input-labels inputLabelsHolder">
                      {importFilesLabel?.map((item) => (
                        <div
                          className="rmaFileInputLabel"
                          style={
                            item?.label.length > 15
                              ? { marginLeft: "-30px", marginTop: "10px" }
                              : { marginLeft: "0px", marginTop: "10px" }
                          }
                        >
                          {item?.label}
                        </div>
                      ))}
                    </div>
                    <div className="input-fields">
                      <div
                        className="file-chooser rmaMissedItemFile-choosers"
                        style={{
                          backgroundImage: `url(${attachmentIcon})`,
                        }}
                      >
                        <input
                          required={
                            props?.name === "ccEx"
                              ? props?.previousDataEx?.length === 0
                                ? true
                                : false
                              : props?.name === "ccIm"
                              ? props?.previousDataIm?.length === 0
                                ? true
                                : false
                              : null
                          }
                          type="file"
                          className="file-input"
                          name="boq"
                          onChange={(e) => handleFreightTax(e)}
                        />
                        <div className="rmaFileUploader">
                          {freightTax
                            ? freightTax?.name.slice(0, 10) + "...."
                            : props?.name === "ccEx"
                            ? props?.previousDataEx?.length !== 0
                              ? "File exist"
                              : "File"
                            : props?.name === "ccIm"
                            ? props?.previousDataEx?.length !== 0
                              ? "File exist"
                              : "File"
                            : " File"}
                        </div>
                      </div>
                      <div
                        className="file-chooser rmaMissedItemFile-choosers"
                        style={{
                          backgroundImage: `url(${attachmentIcon})`,
                        }}
                      >
                        <input
                          required={
                            props?.name === "ccEx"
                              ? props?.previousDataEx?.length === 0
                                ? true
                                : false
                              : props?.name === "ccIm"
                              ? props?.previousDataIm?.length === 0
                                ? true
                                : false
                              : null
                          }
                          type="file"
                          className="file-input"
                          name="boq"
                          onChange={(e) => handleCarrierFreight(e)}
                        />
                        <div className="rmaFileUploader">
                          {carrierFreight
                            ? carrierFreight?.name.slice(0, 10) + "...."
                            : props?.name === "ccEx"
                            ? props?.previousDataEx?.length !== 0
                              ? "File exist"
                              : "File"
                            : props?.name === "ccIm"
                            ? props?.previousDataIm?.length !== 0
                              ? "File exist"
                              : "File"
                            : " File"}
                        </div>
                      </div>
                      {props.name === "ccEx" ? (
                        <div
                          className="file-chooser rmaMissedItemFile-choosers"
                          style={{
                            backgroundImage: `url(${attachmentIcon})`,
                          }}
                        >
                          <input
                            required={
                              props?.name === "ccEx"
                                ? props?.previousDataEx?.length === 0
                                  ? true
                                  : false
                                : props?.name === "ccIm"
                                ? props?.previousDataIm?.length === 0
                                  ? true
                                  : false
                                : null
                            }
                            type="file"
                            className="file-input"
                            name="boq"
                            onChange={(e) => handleExportDeclaration(e)}
                          />
                          <div className="rmaFileUploader">
                            {exportDeclaration
                              ? exportDeclaration?.name.slice(0, 10) + "...."
                              : props?.name === "ccEx"
                              ? props?.previousDataEx?.length !== 0
                                ? "File exist"
                                : "File"
                              : props?.name === "ccIm"
                              ? props?.previousDataIm?.length !== 0
                                ? "File exist"
                                : "File"
                              : " File"}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-buttons rma-form-submit">
                <button
                  type="cancel"
                  className="form-cancel clientCancel"
                  style={{ width: "112px", height: "32px" }}
                  onClick={HandleCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="form-save clientSave"
                  style={{ width: "112px", height: "32px" }}
                >
                  {props?.name === "ccEx"
                    ? props?.previousDataEx?.length !== 0
                      ? "Update"
                      : "Save"
                    : props?.name === "ccIm"
                    ? props?.previousDataIm?.length !== 0
                      ? "Update"
                      : "Save"
                    : ""}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
