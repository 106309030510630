import { IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { API_BASE_URL, IMG_BASE_URL } from "../../api/endPoint";
import {
  useAddEmailHandoverMutation,
  useAddEtadateMutation,
  useAddEtaDateMutation,
  usEditEmailHandoverMutation,
  useEditEmailHandoverMutation,
  useEditEtadateMutation,
  useeEditEtadateMutation,
  useFinalEtaMutation,
  useGetEtaDetailQuery,
  useGetProjectQuery,
  useGetProjectRolePermissionQuery,
  useGetProjectRolesQuery,
  useGetUserInfoQuery,
} from "../../features/SCMApi";
import NavBar from "../../Layout/NavBar";
import SourcingIndex from "../../pages/Sourcing/SourcingIndex";
import EtaPoDetail from "./EtaPoDetail";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import NoRecord from "../common/NoRecord";
import CheckProjectPermission from "../common/checkPermission/CheckProjectPermission";
import CheckPermission from "../common/checkPermission/checkPermission";

export default function EmailSubjectHandoverDate({ setMessages }) {
  const params = useParams();
  const { data: etaDetail } = useGetEtaDetailQuery(params.id);
  
  const location = useLocation();
  const [show, setShow] = useState();
  const [showedit, setShowEdit] = useState();
  const [handover_date, setHandoverDate] = useState(
    etaDetail?.data[0]?.handover_date
  );
  const [email_subject, setEmailSubject] = useState(
    etaDetail?.data[0]?.email_subject
  );
  let emailhandover = {
    id: params?.id,
    email_subject,
    handover_date,
  };

  let total_line_price = 0;
  const [AddEmailHandoverDate, res] = useAddEmailHandoverMutation();
  const HandleEditSubmit = async (e) => {
    e.preventDefault();
    AddEmailHandoverDate(emailhandover)
      .unwrap()
      .then((response) => {
        
        setMessages("Update Successfully");
        setShowEdit(false);
      })
      .then((error) => {
        
      });
  };
  const HandleAddSubmit = async (e) => {
    e.preventDefault();
    AddEmailHandoverDate(emailhandover)
      .unwrap()
      .then((response) => {
        
        setMessages("Added Successfully");
        setShow(false);
      })
      .then((error) => {
        
      });
  };

  const projectid = etaDetail?.data[0]?.project_id;
  
  let count = 0;
  const { data: projectmembers, isSuccess } = useGetProjectQuery(projectid);
  const { data: roleRoles } = useGetProjectRolePermissionQuery(projectid);
  const { data: userinfo } = useGetUserInfoQuery();
  const { data: roles } = useGetProjectRolesQuery();
  let avatar = [];
  return (
    <div className="eta-card p-1 mt-2">
      <div className="">
        {etaDetail?.data?.map((items) => (
          <>
            <div className="lc-value mt-3">Email Subject and Handover Date</div>
            <div className="d-grid  gap-1 mt-2">
              {!show && !showedit && (
                <div className="d-grid">
                  <div className="d-grid">
                    <span className="prg">Email Subject</span>
                    <div className="lc-label">
                      {items?.email_subject == null
                        ? "Unknown"
                        : items?.email_subject}
                    </div>
                  </div>
                  <div className="d-grid">
                    <span className="prg">Handover Date</span>
                    <div className="lc-label">
                      {items?.email_subject == null
                        ? "Unknown"
                        : items?.handover_date}
                    </div>
                  </div>
                </div>
              )}
              {show && (
                <div className="d-grid">
                  <div className="d-grid">
                    <span className="prg">Handover Date</span>
                    <input
                      style={{ width: "294px" }}
                      type="date"
                      className="supplier-text-input"
                      onChange={(e) => setHandoverDate(e.target.value)}
                    />
                  </div>
                  <div className="d-grid">
                    <span className="prg">Email Subject</span>
                    <input
                      style={{ width: "294px" }}
                      type="text"
                      className="supplier-text-input"
                      onChange={(e) => setEmailSubject(e.target.value)}
                    />
                  </div>
                </div>
              )}

              {showedit && (
                <div className="d-grid">
                  <div className="d-grid">
                    <span className="prg">Handover Date</span>
                    <input
                      defaultValue={
                        handover_date != undefined
                          ? handover_date
                          : etaDetail?.data[0]?.handover_date
                      }
                      style={{ width: "294px" }}
                      type="date"
                      className="supplier-text-input"
                      onChange={(e) => setHandoverDate(e.target.value)}
                    />
                  </div>
                  <div className="d-grid">
                    <span className="prg">Email Subject</span>
                    <input
                      defaultValue={
                        email_subject != undefined
                          ? email_subject
                          : etaDetail?.data[0]?.email_subject
                      }
                      style={{ width: "294px" }}
                      type="text"
                      className="supplier-text-input"
                      onChange={(e) =>
                        setEmailSubject(
                          email_subject != undefined
                            ? e.target.value
                            : etaDetail?.data[0]?.email_subject
                        )
                      }
                    />
                  </div>
                  <div className="d-flex justify-content-center gap-2">
                    <IconButton
                      variant="text"
                      size="small"
                      sx={{
                        zIndex: 1,
                        backgroundColor: "#fff",
                        color: "#186569",

                        "&:hover": {
                          backgroundColor: "#186569",
                          color: "#fff",
                        },
                      }}
                      onClick={HandleEditSubmit}
                    >
                      <CheckIcon />
                    </IconButton>
                    <IconButton
                      variant="text"
                      size="small"
                      sx={{
                        zIndex: 1,
                        backgroundColor: "#fff",
                        color: "#186569",

                        "&:hover": {
                          backgroundColor: "#186569",
                          color: "#fff",
                        },
                      }}
                      onClick={() => setShowEdit((prev) => !prev)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
              )}

              {items?.email_subject != null ? (
                <div className="d-flex justify-content-center">
                  {CheckPermission("add_eta_followup", userinfo)
                    ? !showedit && (
                        <IconButton
                          variant="text"
                          size="small"
                          sx={{
                            zIndex: 1,
                            backgroundColor: "#fff",
                            color: "#186569",
                            width: "30px",
                            height: "30px",
                            "&:hover": {
                              backgroundColor: "#186569",
                              color: "#fff",
                            },
                          }}
                        >
                          <ModeEditOutlinedIcon
                            onClick={() => setShowEdit((prev) => !prev)}
                          />
                        </IconButton>
                      )
                    : ""}
                </div>
              ) : (
                <div className="d-flex justify-content-center">
                  {CheckProjectPermission(
                    "add_eta_followup",
                    projectmembers,
                    userinfo,
                    roleRoles
                  )
                    ? !show && (
                        <IconButton
                          variant="text"
                          size="small"
                          sx={{
                            zIndex: 1,
                            backgroundColor: "#fff",
                            color: "#186569",
                            width: "30px",
                            height: "30px",

                            "&:hover": {
                              backgroundColor: "#186569",
                              color: "#fff",
                              width: "30px",
                              height: "30px",
                            },
                          }}
                          onClick={() => setShow((prev) => !prev)}
                        >
                          <AddIcon />
                        </IconButton>
                      )
                    : ""}

                  {show && (
                    <>
                      <IconButton
                        variant="text"
                        size="small"
                        sx={{
                          zIndex: 1,
                          backgroundColor: "#fff",
                          color: "#186569",

                          "&:hover": {
                            backgroundColor: "#186569",
                            color: "#fff",
                          },
                        }}
                        onClick={HandleAddSubmit}
                      >
                        <CheckIcon />
                      </IconButton>
                      <IconButton
                        variant="text"
                        size="small"
                        sx={{
                          zIndex: 1,
                          backgroundColor: "#fff",
                          color: "#186569",

                          "&:hover": {
                            backgroundColor: "#186569",
                            color: "#fff",
                          },
                        }}
                        onClick={() => setShow((prev) => !prev)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </>
                  )}
                </div>
              )}
            </div>
          </>
        ))}
      </div>
    </div>
  );
}
