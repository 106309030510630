import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import profile from "../mule.jpg";
import { IMG_BASE_URL } from "../../../../api/endPoint";

const PFO = styled(Paper)(({ theme }) => ({
  height: "90px",
  [theme.breakpoints.between("md", "lg")]: {
    marginLeft: "60px",
    marginRight: "-45px",
  },
  [theme.breakpoints.between("xs", "sm", "md")]: {
    marginTop: "-40px",
  },
  color: theme.palette.text.secondary,
  alignItems: "center",
  border: "1px solid #AEB8C9",
}));
const ForexDetailPFO = (props) => {
  const roles = props?.data
    ?.map((data) => data?.forex_project?.users)
    .flat()
    .map((data) => data?.role)
    ?.filter((data) => data?.role_name === "Project Finance Officer")
    .map((data) => data?.id)
    .flat();
  const users = props?.data?.map((data) => data?.forex_project?.users);

  const pfoRole = roles && roles?.toString();
  const pfo = users?.flat()?.find((data) => data?.role_id === pfoRole);



  return (
    <>
      <PFO elevation={0} sx={{ marginBottom: "20px" }}>
        {pfo && pfo?.length !== 0 ? (
          <>
            <div className="logisticsRmaProfile">
              <Avatar
                alt={pfo?.name}
                src={
                  pfo?.avatar !== null
                    ? `${IMG_BASE_URL}${pfo?.avatar}`
                    : pfo?.avatar
                }
                sx={{ height: "55px", width: "55px", marginTop: "-8px" }}
              />
              <div className="rmaUserName">
                {pfo?.name}
                <div className="profilePositionTitle">PFO</div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="logisticsRmaProfile">
              <div className="rmaUserName">
                Not Assigned
                <div className="profilePositionTitle">PFO</div>
              </div>
            </div>
          </>
        )}
      </PFO>
    </>
  );
};

export default ForexDetailPFO;
