import React, { useEffect } from "react";
import Summary from "../../components/Sourcing/Summary";
import "./paSummary.style.css";
import NavBar from "../../Layout/NavBar";
import { NavLink, useParams } from "react-router-dom";
import {
  useGetPaSummaryMutation,
  useGetPaSummaryQuery,
  useGetProjectItemQuery,
  useGetProjectsQuery,
} from "../../features/SCMApi";
import SourcingIndex from "./SourcingIndex";
import { useState } from "react";
import BcSummary from "../../components/Sourcing/BcSummary";

export default function BudgetComparison() {
  const params = useParams();
  const { data: response } = useGetPaSummaryQuery(params.id);

  const { data: projectdetail } = useGetProjectItemQuery(params.id);

  const { data: project } = useGetProjectsQuery();
  const Projectdata = [];

  project?.data?.map((items) => {
    if (params.id === items.id) {
      Projectdata.push(items);
    }
  });
  const [manufacturerCount, setmanufacturerCount] = useState();

  let supplierCount;
  if (response?.data != null) {
    supplierCount = Object.entries(response?.data).length;

  }


  const [supplier_count, setSupplierCount] = useState();
  return (
    <SourcingIndex name={Projectdata[0]?.project_name} names="BC Summary">
      <div className="page">
        <div className="sm-card-container">
          <div className="sm-card manufacturer">
            <div className="sm-card-content-one">
              <div className="lc-value">
                {manufacturerCount === isNaN ? "0" : manufacturerCount}
              </div>
              <div className="lc-label">Manufacturers</div>
            </div>

            <div className="sm-card-content-one">
              <div className="lc-value">{supplier_count}</div>
              <div className="lc-label">supplier</div>
            </div>
          </div>
          <div className="sm-card total-orders">
            <div className="lc-value">{projectdetail?.data?.order.length}</div>
            <div className="lc-label">Total Orders</div>
            <NavLink
              to={`/sourcing/orders/${params.id}`}
              className="button-pa-view"
            >
              View Orders
            </NavLink>
          </div>
        </div>
        <BcSummary
          response={response?.data}
          manufacturerCount={setmanufacturerCount}
          setSupplierCount={setSupplierCount}
        />
      </div>
    </SourcingIndex>
  );
}
