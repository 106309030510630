import React, { useState } from "react";
import NoRecord from "../../common/NoRecord";
import StockIndex from "../../../pages/Stocks/StockIndex";
import AssetTools from "./AssetTools";
import AssetsInventory from "./AssetsInventory";
export default function Assets() {
  const [Items, setItems] = useState(true);
  const CheckBoxHandelonShore = () => {
    setItems(!Items);
  };

 
  return <AssetTools />;
}
