import React, { useState } from "react";
import { useGetUserInfoQuery } from "../../../features/SCMApi";
import IncomingTools from "./IncomingTools";
import IncomingInventories from "./IncomingInventory";
import StockIndex from "../../../pages/Stocks/StockIndex";
import IncomingAssets from "./IncomingAssets";

export default function Incoming() {
  const { data: userinfo } = useGetUserInfoQuery();
  let PMandSK =
    userinfo?.role?.role_name == "Project Manager" ||
    userinfo?.role?.role_name == "Super Admin";
  let FE = userinfo?.role?.role_name == "Field Engineer";
  let confirmed_by = userinfo?.id;
  const [Items, setItems] = useState("tools");
  const CheckBoxHandelonShore = (item) => {
    setItems(item);
  };

  const [message, setMessages] = useState(null);
  return (
    <StockIndex Message={message}>
      <div className="tool-header-container d-flex gap-3 justify-content-center mb-3">
        <input
          checked={Items === "tools"}
          id="default-radio-1"
          type="radio"
          value="Tool"
          name="default-radio"
          className="cursor-pointer"
          onChange={() => CheckBoxHandelonShore("tools")}
        />
        <label for="default-radio-1" className="prg">
          Tools
        </label>

        <input
          id="default-radio-2"
          type="radio"
          value="Inventory"
          name="default-radio"
          className="cursor-pointer"
          checked={Items === "items"}
          onChange={() => CheckBoxHandelonShore("items")}
        />
        <label for="default-radio-2" className="prg">
          Items
        </label>
        <input
          id="default-radio-2"
          type="radio"
          value="Inventory"
          name="default-radio"
          className="cursor-pointer"
          checked={Items === "assets"}
          onChange={() => CheckBoxHandelonShore("assets")}
        />
        <label for="default-radio-2" className="prg">
          Assets
        </label>
      </div>
      {Items === "tools" ? (
        <IncomingTools setMessages={setMessages} />
      ) : Items === "items" ? (
        <IncomingInventories setMessages={setMessages} />
      ) : (
        <IncomingAssets setMessages={setMessages} />
      )}
    </StockIndex>
  );
}
