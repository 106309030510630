import React, { useEffect, useState } from "react";
import Table from "./Table";
import TableForItemDescription from "./TableForItemDescription";
import { useDownloadRequestedGrnByGrnIdMutation } from "../../../features/SCMApi";
import Table2 from "./Table2";
import { useParams } from "react-router-dom";
import "./templateGRNcss.css"; // Your custom CSS file
import * as htmlToImage from "html-to-image";
// import html2pdf from 'html2pdf.js';

function ScmGrnTemplate() {
  const [downloadRequestedGrnByGrnId] =
    useDownloadRequestedGrnByGrnIdMutation();
  const [data, setData] = useState({});
  const { id } = useParams();
  const { shouldPrintGrn } = useParams();

  function formatNumber(value) {
    if (value == null) {
      return ""; // or any default value you prefer
    }
    const roundedValue = Number(value).toFixed(2);
    return roundedValue;
  }

  useEffect(() => {
    downloadRequestedGrnByGrnId(id)
      .unwrap()
      .then((response) => {
        setData(response?.data);
      })
      .catch((error) => {});
  }, []);

  const handlePrintGrn = () => {
    const targetNode = document.getElementById("downloadedIcon");

    if (targetNode) {
      htmlToImage
        .toPng(targetNode)
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.href = dataUrl;
          link.download = "downloaded-GrnImageFile.png";
          link.click();
        })
        .catch((error) => {
          console.error("Error generating image:", error);
        });
    }
  };
  useEffect(() => {
    if (shouldPrintGrn) {
      handlePrintGrn();
    }
  }, [shouldPrintGrn]);

  return (
    <>
      <div className="container mt-4 ml-20 mr-20">
        <div className="mx-20 font-serif font-monospace fs-6">
          <div className="container mt-4 ml-20 mr-20  fs-5">
            <div
              id="downloadedIcon"
              className=" border border-secondary p-4 bg-white text-black fs-5"
            >
              {/* <div className="row">
                <div className="col-12">
                  <div className="d-flex ">
                    <img
                      src="/scmGrn.png"
                      className="img-fluid w-100 rounded-0"
                      alt="Full Width Image"
                    />
                  </div>
                </div>
              </div> */}
              <div class="">
                <table style={{ border: "2px solid black", width: "100%" }}>
                  <tr>
                    <td
                      style={{
                        width: " 150px",
                        height: "86px",
                        border: "2px solid black",
                      }}
                    >
                      <img
                        src="/IE.jpg"
                        className="img-fluid rounded-0"
                        alt="Full Width Image"
                        style={{ width: "100%", height: "100%" }}
                      />
                    </td>
                    <td colSpan={3} style={{ border: "2px solid black" }}>
                      <span>Company name</span>
                      <br />
                      <span
                        style={{ fontSize: "xx-large", fontWeight: "bold" }}
                      >
                        Ie Network Solutions PLC
                      </span>
                    </td>
                    <td colSpan={2} style={{ border: "2px solid black" }}>
                      Document No :
                      <span style={{ fontsize: "small", color: "blue" }}>
                        IE-PIM-F-004
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4} style={{ border: "2px solid black" }}>
                      <span>Title: </span>
                      <span style={{ fontWeight: "bold" }}>
                        Good Receiving Voucher
                      </span>
                    </td>
                    <td style={{ border: "2px solid black" }}>Issue no: 1</td>
                    <td style={{ border: "2px solid black" }}>Page 1 of 1</td>
                  </tr>
                </table>
              </div>
              <div class="d-flex justify-content-end align-items-end">
                Date : {data?.details?.requested_date}
              </div>
              <div class="d-flex justify-content-end align-items-end">
                No : {data?.recept_no}
              </div>
              <div class="d-flex justify-content-end align-items-end">
                GRN no : {data?.grn_number}
              </div>
              <br />
              <div className="row mt-4">
                <div className="col-md-4">
                  <p className="d-flex">
                    Receiving store Center:{" "}
                    {/* <p className="text-decoration-underline">
                      __________________________
                    </p> */}
                  </p>
                  <p className="d-flex">
                    Department:{" "}
                    <p className="text-decoration-underline">
                      {data?.details?.department_or_project}
                    </p>
                  </p>
                  <p className="d-flex">
                    Delivery Truck Plate No:
                    <p className="text-decoration-underline"></p>
                  </p>
                  <p className="d-flex">
                    Invoice No:
                    <p className="text-decoration-underline">
                      {"________________"}
                    </p>
                  </p>
                  <p className="d-flex">Is No:</p>
                  <p className="d-flex">
                    Purchase/Care Of By:
                    <p className="text-decoration-underline">
                      {data?.details?.requested_by}
                    </p>
                  </p>
                </div>
                <div className="col-md-4">
                  <p className="d-flex">
                    Purpose Of Purchase:
                    <p className="text-decoration-underline">
                      {data?.details?.purpose_of_purchase}
                    </p>
                  </p>
                  <p className="d-flex">
                    Supplier:
                    <p className="text-decoration-underline">
                      {data?.details?.supplier}
                    </p>
                  </p>
                  <p className="d-flex">
                    Bill Of Loading:
                    <p className="text-decoration-underline"></p>
                  </p>
                </div>
                <div className="col-md-4">
                  <p className="d-flex">
                    S.R No: <p className="text-decoration-underline"></p>
                  </p>
                  <p className="d-flex">
                    P.R No:
                    <p className="text-decoration-underline">
                      {data?.details?.PRF_no}
                    </p>
                  </p>
                  <p className="d-flex">
                    PO No:
                    <p className="text-decoration-underline">
                      {data?.details?.PO_no}
                    </p>
                  </p>
                  <p className="d-flex">
                    LC No:<p className="text-decoration-underline"></p>
                  </p>
                </div>
              </div>
              {/* Certification Section */}
              <div className="row mt-4">
                <div className="col-12">
                  <p className="font-weight-bold">
                    I certify that I have received the above items as per the
                    specification on the PR specified in quality and quantity
                  </p>
                </div>
              </div>
              {/* Table Section */}
              <div className="row mt-4 fs-6">
                <div className="col-12">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Item Code</th>
                        <th>Description</th>
                        <th>Part No</th>
                        <th>Unit</th>
                        <th>Qty</th>
                        <th>Unit Price</th>
                        <th>Total Price</th>
                        <th>Store</th>
                        <th>Remark</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Sample Data */}
                      {data?.items?.map((items, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{items?.id}</td>
                          <td>
                            {items?.item_description &&
                            items?.item_description.length > 20
                              ? `${items.item_description.slice(0, 20)} ...`
                              : items?.item_description}
                          </td>
                          {/* <td>{items?.item_part_number}</td> */}
                          <td>
                            {items?.item_part_number &&
                            items?.item_part_number.length > 20
                              ? `${items.item_part_number.slice(0, 20)} ...`
                              : items?.item_part_number}
                          </td>
                          <td>
                            {data?.details?.is_onshore
                              ? items?.uom
                              : items?.ItemType}
                          </td>
                          <td>
                            {data?.details?.is_onshore
                              ? items?.qty
                              : items?.delivered_qty}
                          </td>
                          <td>
                            {data?.details?.is_onshore
                              ? formatNumber(items?.pi_unit_cost)
                              : formatNumber(items?.unit_price)}
                          </td>
                          <td>
                            {data?.details?.is_onshore
                              ? formatNumber(items?.pi_total_cost)
                              : formatNumber(items?.delivered_qty_total_price)}
                          </td>
                          <td>{items?.store}</td>
                          <td>{"________________"}</td>
                        </tr>
                      ))}
                      {/* Add more rows as needed */}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-3">
                  <p>Prepared By: {"____________________"}</p>
                  <p>ID No: {"__________________________"}</p>
                  <p>Signature:{"____________________"}</p>
                </div>
                <div className="col-md-3">
                  <p>Checked By:{"___________________"}</p>
                  <p>ID No:{"____________________"}</p>
                  <p>Date:{"____________________"}</p>
                  <p>Signature:{"___________________"}</p>
                </div>
                <div className="col-md-3">
                  <p>Approved By:{"___________________"}</p>
                  <p>ID No:{"____________________"}</p>
                  <p>Date:{"____________________"}</p>
                  <p>Signature:{"____________________"}</p>
                </div>
                <div className="col-md-3">
                  <p>Received By:{"____________________"}</p>
                  <p>ID No:{"____________________"}</p>
                  <p>Date:{"____________________"}</p>
                  <p>Signature:{"____________________"}</p>
                </div>
              </div>
            </div>
            <div className="row mt-5 mb-20  ">
              <button
                type="button"
                class="btn btn-primary fs-4 p-16 pt-10"
                onClick={handlePrintGrn}
              >
                Print
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ScmGrnTemplate;
