import * as React from "react";
import { alpha } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "../forex.style.css";
import usePagination from "../../../components/Pagination/Pagination";
import { Pagination } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Tooltip } from "@mui/material";

const TCell = styled(TableCell)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "10.5px",
  lineHeight: "12px",
  color: "#353945",
  width: "90px",
  padding: "0 0px 0 0",
  borderRight: "1px #E0E0E0 solid",
  borderLeft: "1px #E0E0E0 solid",
}));
const TCellDesc = styled(TableCell)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "10.5px",
  lineHeight: "12px",
  color: "#353945",
  width: "200px",
  padding: "0 0px 0 0",
  borderRight: "1px #E0E0E0 solid",
  borderLeft: "1px #E0E0E0 solid",
}));
const TCellHeader = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#ececec",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "11px",
  lineHeight: "12px",
  color: "#353945",
}));
const SpecialTcell = styled(TableCell)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12.5px",
  lineHeight: "12px",
  color: "#353945",
  padding: "0 0px 0 0",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  height: "150px",
}));

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  // marginTop: "10px",
}));
const TotalDisplay = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "20px",
  lineHeight: "28px",
  height: "50px",
  borderRadius: "5px",
  marginLeft: "auto",
  marginRight: "40px",
  justifyContent: "center",
  color: "#186569",
  backgroundColor: "#FFFFFF",
  border: "1px solid #186569",
  width: "180px",
  marginTop: "-14%",
}));

export default function EnhancedTable(props) {
  const formatResponseFunc = (response) => {
    function groupBy(arr, key) {
      return arr.reduce((acc, x) => {
        (acc[x[key]] = acc[x[key]] || []).push(x);
        return acc;
      }, {});
    }
    const forex_need = response?.data?.forexNeed || [];
    const forexBalance = response?.data?.forexBalance || [];
    const receivable =
      response?.data?.receivableAmount !== null
        ? Number(response?.data?.receivableAmount)
        : 0;
    const _rows = [];

    const projectsTemp = groupBy(forex_need, "project_name");
    Object.keys(projectsTemp).forEach((key, index) => {
      const data = {};
      data.no = index + 1;
      data.project = {
        id: index + 1,
        project_id: projectsTemp[key][0].project_id,
        name: projectsTemp[key][0].project_name,
      };
      const milestoneTemp = groupBy(projectsTemp[key], "milestone_name");
      data.milestone = [];
      data.receivable = receivable;
      data.balance = forexBalance.length !== 0 ? Number(forexBalance?.amount) : 0;
      Object.keys(milestoneTemp).forEach((key) => {
        data.milestone.push({
          id: milestoneTemp[key][0].milestone_id,
          project_id: milestoneTemp[key][0].project_id,
          milestone_name: milestoneTemp[key][0].milestone_name,
          milestoneData: milestoneTemp[key]
            .flat()
            .sort((a, b) => (a.milestone_id > b.milestone_id ? 1 : -1))
            .map((item, index) => ({
              id: index + 1,
              ...item,
              noOfData: milestoneTemp[key].length,
              gap:
                Math.round(
                  (Number(data?.receivable) +
                    Number(data?.balance) -
                    Number(item?.amount) +
                    Number.EPSILON) *
                    1000
                ) / 1000,
            })),
        });
      });
      data.milestone = data.milestone.sort((a, b) => (a.id > b.id ? 1 : -1));
      data.totalNeed =
        Math.round(
          (response?.data?.forexNeed?.reduce(
            (total, item) => (total += Number(item.amount) || 0),
            0
          ) || 0 + Number.EPSILON) * 1000
        ) / 1000;
      data.totalGap =
        Math.round(
          (Number(data.balance) + Number(data.receivable) - Number(data?.totalNeed) + Number.EPSILON) *
            1000
        ) / 1000;
      data.bank = response?.data?.forexNeed?.map((bank) => bank?.bank_name);
      _rows.push(data);
    });
    return _rows;
  };

  const rows = React.useMemo(
    () => formatResponseFunc(props.gapAnalysisData),
    [props?.gapAnalysisData]
  );
  // const rows = React.useMemo(() => formatResponseFunc(data), [data]);

  const totalNeed = rows?.map((project) => project?.totalNeed)[0];
  const totalGap = rows?.map((project) => project?.totalGap)[0];
  const totalReceivable = rows?.map((project) => project?.receivable)[0];
  const totalBalance = rows?.map((project) => project?.balance)[0];
  const bankName = rows?.map((project) => project?.bank)[0];

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const headCells = [
    {
      id: "no",
      numeric: true,
      disablePadding: false,
      label: "\xa0\xa0\xa0\xa0NO",
    },
    {
      id: "project",
      numeric: true,
      disablePadding: false,
      label: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0PROJECT",
    },
    {
      id: "milestone",
      numeric: true,
      disablePadding: false,
      label: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0MILESTONE",
    },

    {
      id: "supplier",
      numeric: true,
      disablePadding: false,
      label: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0SUPPLIER",
    },
    {
      id: "need",
      numeric: true,
      disablePadding: false,
      label: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0NEED",
    },
    {
      id: "balance",
      numeric: true,
      disablePadding: false,
      label: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0BALANCE",
    },
    {
      id: "receivable",
      numeric: true,
      disablePadding: false,
      label: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0RECEIVABLE",
    },
    {
      id: "gap",
      numeric: true,
      disablePadding: false,
      label: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0GAP",
    },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label:
        "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0DESCRIPTION",
    },
  ];

  function EnhancedTableHead(props) {
    const { order, orderBy } = props;

    return (
      <TableHead
        className="paymentTableHeader"
        sx={{
          backgroundColor: "#ececec",
          color: "#353945",
        }}
      >
        <TableRow>
          {headCells.map((headCell) => (
            <TCellHeader
              key={headCell.id}
              align={headCell.numeric ? "left" : "left"}
              padding="none"
              sortDirection={orderBy === headCell.id ? order : false}
              height="50px"
            >
              {headCell.label}
            </TCellHeader>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        <>
          <TotalDisplay>
            {/* {grandTotal.toLocaleString()} */}
            <span className="gapAnalysis">
              Total Need:{" "}
              <span>
                {totalNeed !== undefined
                  ? `\xa0\xa0${Number(totalNeed)?.toLocaleString()}`
                  : ""}
              </span>
            </span>
            <span className="gapAnalysis">
              Gap:
              <span
                style={{
                  color:
                    totalGap <= -1
                      ? "#FF5252"
                      : Number(totalGap) === 0
                      ? "#FABB06"
                      : "#00DD9B",
                }}
              >
                {totalGap !== undefined
                  ? `\xa0\xa0${Number(totalGap)?.toLocaleString()}`
                  : ""}
              </span>
            </span>
          </TotalDisplay>
        </>
      </Toolbar>
    );
  };

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("no");
  const [selected, setSelected] = React.useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.no);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  let [page, setPage] = React.useState(1);
  const PER_PAGE = 1;
  const count = Math.ceil(rows?.length / PER_PAGE);
  const _DATA = usePagination(rows, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <>
      <EnhancedTableToolbar numSelected={selected.length} />

      <Table
        sx={{ width: "inherent", marginTop: "-7%" }}
        aria-labelledby="tableTitle"
        size="small"
      >
        <EnhancedTableHead
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={rows.length}
        />
        <TableBody>
          {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
          {stableSort(_DATA?.currentData(), getComparator(order, orderBy)).map(
            (row, index) => {
              const isItemSelected = isSelected(row.no);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.no}
                  selected={isItemSelected}
                >
                  <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    align="left"
                    sx={{
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "10.5px",
                      lineHeight: "12px",
                      color: "#353945",
                      width: "1px",
                    }}
                  >
                    {row?.no}
                  </TableCell>
                  <TCell
                    align="center"
                    component="th"
                    id={labelId}
                    scope="row"
                    sx={{
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "10.5px",
                      lineHeight: "12px",
                      color: "#353945",
                    }}
                  >
                    {row.project?.name}
                  </TCell>
                  <TCell>
                    {row.milestone
                      ?.filter(
                        (milestone) =>
                          milestone?.project_id === row?.project?.project_id
                      )
                      .map((milestone) => (
                        <Container>
                          <SpecialTcell align="center">
                            {milestone?.milestone_name}
                          </SpecialTcell>
                        </Container>
                      ))}
                  </TCell>

                  <TCell>
                    {row.milestone
                      ?.filter(
                        (milestone) =>
                          milestone?.project_id === row?.project?.project_id
                      )
                      .map((milestoneData) => milestoneData?.milestoneData)
                      ?.flat()
                      .map((supplier) => (
                        <Container>
                          <Tooltip title={supplier?.distributor_name} arrow>
                            <SpecialTcell
                              align="center"
                              sx={{
                                height:
                                  supplier?.noOfData > 10
                                    ? `calc(1000px / ${supplier?.noOfData})`
                                    : `calc(150px / ${supplier?.noOfData})`,
                              }}
                            >
                              {supplier?.distributor_name?.length >= 11
                                ? supplier?.distributor_name?.slice(0, 10) +
                                  "..."
                                : supplier?.distributor_name}
                            </SpecialTcell>
                          </Tooltip>
                        </Container>
                      ))}
                  </TCell>
                  <TCell>
                    {row.milestone
                      ?.filter(
                        (milestone) =>
                          milestone?.project_id === row?.project?.project_id
                      )
                      .map((milestoneData) => milestoneData?.milestoneData)
                      ?.flat()
                      .map((supplier) => (
                        <Container>
                          <SpecialTcell
                            align="center"
                            sx={{
                              height:
                                supplier?.noOfData > 10
                                  ? `calc(1000px / ${supplier?.noOfData})`
                                  : `calc(150px / ${supplier?.noOfData})`,
                            }}
                          >
                            {Number(supplier?.amount)?.toLocaleString()}
                          </SpecialTcell>
                        </Container>
                      ))}
                  </TCell>
                  <TCell align="center">
                    {Number(row?.balance)?.toLocaleString()}
                  </TCell>
                  <TCell align="center">
                    {Number(row?.receivable)?.toLocaleString()}
                  </TCell>
                  <TCell>
                    {row.milestone
                      ?.filter(
                        (milestone) =>
                          milestone?.project_id === row?.project?.project_id
                      )
                      .map((milestoneData) => milestoneData?.milestoneData)
                      ?.flat()
                      .map((supplier) => (
                        <Container>
                          <SpecialTcell
                            align="center"
                            sx={{
                              height:
                                supplier?.noOfData > 10
                                  ? `calc(1000px / ${supplier?.noOfData})`
                                  : `calc(150px / ${supplier?.noOfData})`,
                              color: supplier?.gap?.toString()?.includes("-")
                                ? "#FF5252"
                                : Number(supplier?.gap) === 0
                                ? "#FABB06"
                                : "#00DD9B",
                            }}
                          >
                            {Number(supplier?.gap)?.toLocaleString()}
                          </SpecialTcell>
                        </Container>
                      ))}
                  </TCell>
                  <TCellDesc>
                    {row.milestone
                      ?.filter(
                        (milestone) =>
                          milestone?.project_id === row?.project?.project_id
                      )
                      ?.map((milestoneData) => milestoneData?.milestoneData)
                      ?.flat()
                      ?.map((supplier) => (
                        <Container>
                          <Tooltip title={supplier?.description} arrow>
                            <TableCell
                              align="left"
                              sx={{
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "12px",
                                lineHeight: "12px",
                                color: "#353945",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                padding: "0 0px 0 10px",
                                height:
                                  supplier?.noOfData > 10
                                    ? `calc(1000px / ${supplier?.noOfData})`
                                    : `calc(150px / ${supplier?.noOfData})`,
                              }}
                            >
                              {supplier?.description?.length >= 21
                                ? supplier?.description?.slice(0, 20) + "..."
                                : supplier?.description}
                            </TableCell>
                          </Tooltip>
                        </Container>
                      ))}
                  </TCellDesc>
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>

      {count > 1 ? (
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            margin: "5px",
            paddingBottom: "15px",
            paddingTop: "15px",
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}
