import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { API_BASE_URL } from "../../api/endPoint";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

// const useStyles = makeStyles((theme) => ({}));

export default function DeleteRepresentativeModal({
  show = false,
  handleDeleteModal,
  refresh,
  repId,
  name,
  solutionName,
}) {


  const [confirmationText, setConfirmationText] = useState(false);

  const handleSubmit = (e) => {
    setConfirmationText(false);
    e.preventDefault();

    axios
      .put(
        `${API_BASE_URL}/scm/settings/clientrepresentatives/delete/${repId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        
        handleDeleteModal(false);
        refresh();
      })
      .then((error) => {
        
      });
  };

  //   const classes = useStyles();

  return (
    <BootstrapDialog
      onClose={handleDeleteModal}
      aria-labelledby="customized-dialog-title"
      open={show}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={handleDeleteModal}
        onClick={(e) => (setConfirmationText(false), 1)}
        sx={{ width: 500, color: "#575757" }}
      ></BootstrapDialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              {confirmationText === true ? (
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "#FF5252",
                    marginBottom: "4%",
                  }}
                >
                  Are you sure ?
                </p>
              ) : (
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "#575757",
                    // marginBottom: "4%",
                    paddingTop: "15px ",
                  }}
                >
                  Do you want to delete{" "}
                  {name === "solution" ? `${solutionName}` : null} ?
                </p>
              )}
              <br />
              <br />
            </Grid>
          </Grid>
          <DialogActions sx={{ display: "inline-block", marginLeft: "29%" }}>
            <Button
              variant="contained"
              type="reset"
              sx={{
                backgroundColor: "#fff",
                color: "#575757",
                borderRadius: "25px",
                "&:hover": {
                  backgroundColor: "#ececec",
                  color: "#575757",
                },
                width: "84.5px",
                marginTop: "-30px",
              }}
              onClick={
                confirmationText === !true
                  ? handleDeleteModal
                  : (e) => (setConfirmationText(false), 1)
              }
            >
              {confirmationText === !true ? "Cancel" : "No"}
            </Button>
            {confirmationText === !true ? (
              <Button
                variant="contained"
                onClick={(e) => (setConfirmationText(true), 1)}
                sx={{
                  borderRadius: "25px",
                  width: "84.5x",
                  backgroundColor: "#FF5252",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#b12323",
                    color: "#fff",
                  },
                  marginTop: "-30px",
                }}
              >
                Delete
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={handleSubmit}
                sx={{
                  borderRadius: "25px",
                  width: "84.5px",
                  backgroundColor: "#FF5252",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#b12323",
                    color: "#fff",
                  },
                  marginTop: "-30px",
                }}
              >
                Yes
              </Button>
            )}
          </DialogActions>
        </DialogContent>
      </form>
    </BootstrapDialog>
  );
}
