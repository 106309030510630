import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  useCreateMissedItemMutation,
  useGetUserInfoQuery,
} from "../../features/SCMApi";
import { Tooltip } from "@mui/material";

export default function CreateMissedItem(props) {
  const [createMissed] = useCreateMissedItemMutation();
  const { data: loggedUser } = useGetUserInfoQuery();
  const { ProjectId, batchId } = useParams();
  const [missedItems, setMissedItems] = useState([]);
  let allMissedItems = props.items
    ?.filter(
      (item) =>
        item?.remaining_quantity !== 0 &&
        item?.missed_item == false &&
        item?.rma == false
    )
    ?.map((item) => item?.id);

  const handleChangeBatch = (e, items) => {
    const { checked } = e.target;
    if (items === "all") {
      if (checked) {
        setMissedItems(allMissedItems);
      } else {
        setMissedItems([]);
      }
    } else {
      if (checked) {
        setMissedItems((prevMissedItems) => [...prevMissedItems, items?.id]);
      } else {
        setMissedItems((prevMissedItems) =>
          prevMissedItems.filter((id) => id !== items?.id)
        );
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const selectedItems = props.items
      .filter((item) => missedItems.includes(item.id))
      .map((item) => ({
        delivery_group: item?.delivery_group,
        hs_code: item?.hs_code,
        id: item?.id,
        item_description: item?.item_description,
        item_part_number: item?.item_part_number,
        missed_item: true,
        po_group: item?.po_group,
        qty: item?.qty,
        rma: item?.rma,
        total_price: item?.total_price,
        unit_price: item?.unit_price,
        uom: item?.uom,
      }));

    const createMissedForm = {
      project_id: ProjectId,
      batch_id: batchId,
      items: selectedItems,
      created_by: loggedUser?.id,
    };
    
    createMissed(createMissedForm)
      .unwrap()
      .then((response) => {
        
        props.ToggleModal(false);
      })
      .catch((error) => {
        
      });
  };

  return (
    <div>
      {allMissedItems?.length !== 0 && (
        <label className="d-flex justify-content-start mb-2 ms-5 gap-2">
          <input
            type="checkbox"
            onChange={(e) => handleChangeBatch(e, "all")}
          />
          <span className="prg-pri">Select All</span>
        </label>
      )}
      <form className="form-container" onSubmit={handleSubmit}>
        <div className="form-card-container">
          <div className="missed-card-information">
            <div className="card-center">
              <div className="ms-5">
                <div className="supplier-input-labels">
                  {props.items?.map(
                    (item) =>
                      item?.remaining_quantity !== 0 &&
                      item?.missed_item == false &&
                      item?.rma == false && (
                        <div className="text-center" key={item.id}>
                          <label className="d-flex gap-2 align-items-center justify-content-start">
                            <input
                              type="checkbox"
                              checked={missedItems?.includes(item?.id)}
                              onChange={(e) => handleChangeBatch(e, item)}
                            />
                            <Tooltip title={item?.item_description}>
                              <div className="prg text-start">
                                {item?.item_description?.length >= 150
                                  ? item?.item_description?.slice(0, 150) +
                                    "..."
                                  : item?.item_description}
                              </div>
                            </Tooltip>
                          </label>
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center gap-3 mt-2">
          <button
            type="button"
            className="form-cancel"
            onClick={() => props.ToggleModal(false)}
          >
            Cancel
          </button>
          <button type="submit" className="form-save">
            Save
          </button>
        </div>
      </form>
    </div>
  );
}
