import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useGetProjectQuery } from "../../features/SCMApi";
import NoRecord from "../common/NoRecord";

export default function BcSummary(props) {
  
  const manufacturer = [];
  const vr = props?.response;
  const newpa = props?.response?.data?.newpa;
  if (props?.response != null) {
    for (const items in vr) {
      manufacturer.push(...vr[items]);
      
    }
  }
  let boq_total_price = 0;
  let final_cost = 0;
  let finalsupplier = [];
  
  newpa?.map((newpas, indexp) => {
    manufacturer?.map((items, index) => {
      if (newpas.manufacturer_id === items[index].manufacturer_id) {
        finalsupplier.push(newpas?.distributor_name);
        
      }
    });
  });
  
  let supplier = "";
  let total_boq_cost = 0;
  let total_final_cost = 0;
  let pi_total_cost = 0;
  let counter = 0;
  let ordername;
  // manufacturer?.map((items) =>
  //   Object.keys(items)?.map((index) =>
  
  //     items[index]?.pa?.map((pa) => {
  //       total_boq_cost += pa.boq_total_price;
  //       total_boq_cost = total_boq_cost++;
  //       total_final_cost += pa.pi_total_price;
  //       total_final_cost = total_boq_cost++;
  //     })
  //   )
  // );

  props?.manufacturerCount(manufacturer?.length);
  const params = useParams();
  const { data: response } = useGetProjectQuery(params.id);
  const project_name = response?.data?.project_name;
  let order_name = "";
  let supplier_count = [];
  manufacturer?.map((items) =>
    supplier_count.push(items?.pa_order?.supplier?.distributor_name)
  );

  manufacturer?.map((items) =>
    Object.keys(items)?.map((index) =>
    
      {
        items[index]?.pa?.map((pa) => {
          pi_total_cost = pa?.pi_total_cost + pa?.pi_total_cost;
        });
      }
    )
  );
  const results = supplier_count.filter((element) => {
    return element !== undefined;
  });
  let uniqueChars = [...new Set(results)];

  props.setSupplierCount(uniqueChars?.length);
  let boq = [];
  let boqs;
  let lasttouch = [];
  manufacturer?.map((items, index) => boq.push(items?.pa));
  for (let i = 0; i <= boq?.length; i++) {
    for (let j = 0; j <= boq?.length; j++) {
      boq[j]?.map((items) => {});
    }
  }
  // for (let i = 0; i <= boq?.length; i++) {
  //   for (let j = 0; j < boq[i]?.length; j++) {
  //     boqs?.push(boq[i][j], "boq[i][j]");
  //   }
  // }

  const paData = [];
  const pasummarycal = [];
  if (props?.response != null) {
    Object?.values(vr)?.map((value, index) => {
      paData.push(value[0]?.pa);
    });
  }

  function combineBySummingKeys(...objects) {
    const mergedPaBoq = {};

    objects.forEach((obj) => {
      Object.keys(obj).forEach((key) => {
        mergedPaBoq[key] = (mergedPaBoq[key] || 0) + obj[key];
      });
    });
   

    pasummarycal.push(mergedPaBoq);
  }
  for (let index = 0; index < paData.length; index++) {
    combineBySummingKeys(...paData[index]);
  }

  
  // let boq_total = {};
  // let ourchance = 0;
  // for (let i = 0; i <= paData?.length; i++) {
  //   for (let j = 0; j <= paData[i]?.length; j++) {
  //     if (paData[i][j] != undefined) {
  //       boq_total = { [i]: paData[i][j]?.boq_total_cost };
  //       for (let k = 0; k <= boq_total?.length; k++) {
  //         ourchance += boq_total[i];
  //       }
  //     }
  //   }
  // }
  manufacturer?.map((items, indexs) =>
    items?.pa?.length !== 0
      ? (total_boq_cost += pasummarycal[indexs]?.boq_total_cost)
      : ""
  );
  manufacturer?.map((items, indexs) =>
    items?.pa?.length !== 0
      ? (pi_total_cost += pasummarycal[indexs]?.pi_total_cost)
      : ""
  );
 
  return (
    <div className="summary-table">
      {manufacturer?.length !== 0 ? (
        <>
          <div className=" summary-header summary-row">
            <div className="no"></div>
            <div className="no">No</div>
            <div> Description</div>
            <div>BoQ Total Cost</div>
            <div>FinalTotal Cost</div>
            <div>Payment Mode</div>
            <div>Batch Name</div>
            <div>Supplier</div>
            <div>Profit/Loss</div>
          </div>
          {manufacturer?.map((items, indexs) =>
            items?.pa?.length !== 0 ? (
              <div className="summary-row">
                <div className="no">
                  <div>
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.3775 16.005L17.16 8.2225L15.895 6.985L9.3775 13.5025L6.0775 10.2025L4.84 11.44L9.3775 16.005ZM11 22C9.49667 22 8.07583 21.7113 6.7375 21.1338C5.39917 20.5562 4.23042 19.7679 3.23125 18.7688C2.23208 17.7696 1.44375 16.6008 0.86625 15.2625C0.28875 13.9242 0 12.5033 0 11C0 9.47833 0.28875 8.04833 0.86625 6.71C1.44375 5.37167 2.23208 4.2075 3.23125 3.2175C4.23042 2.2275 5.39917 1.44375 6.7375 0.86625C8.07583 0.28875 9.49667 0 11 0C12.5217 0 13.9517 0.28875 15.29 0.86625C16.6283 1.44375 17.7925 2.2275 18.7825 3.2175C19.7725 4.2075 20.5562 5.37167 21.1338 6.71C21.7113 8.04833 22 9.47833 22 11C22 12.5033 21.7113 13.9242 21.1338 15.2625C20.5562 16.6008 19.7725 17.7696 18.7825 18.7688C17.7925 19.7679 16.6283 20.5562 15.29 21.1338C13.9517 21.7113 12.5217 22 11 22Z"
                        fill="#52FFAC"
                      />
                    </svg>
                  </div>
                </div>

                <div className="label-container">
                  <div className="pa-label">No</div>
                  <div>{(counter += 1)}</div>
                </div>

                <div className="label-container">
                  <div className="pa-label"> Description</div>
                  <div className="text-start" style={{ width: "110px" }}>
                    {items?.pa_order?.order_name}
                  </div>
                </div>
                <div className="label-container">
                  <div className="pa-label">BoQ Total Cost</div>

                  <div className="prg">
                    {pasummarycal[indexs]?.boq_total_cost === 0
                      ? pasummarycal[indexs]?.boq_total_cost?.toLocaleString()
                      : pasummarycal[indexs]?.boq_total_cost?.toLocaleString()}
                  </div>
                </div>
                <div className="label-container">
                  <div className="pa-label">FinalTotal Cost</div>
                  <div>
                    <div className="prg">
                      {pasummarycal[indexs]?.pi_total_cost === 0
                        ? pasummarycal[indexs]?.pi_total_cost?.toLocaleString()
                        : pasummarycal[indexs]?.pi_total_cost?.toLocaleString()}
                    </div>
                  </div>
                </div>
                <div className="label-container">
                  <div className="pa-label">Payment Mode</div>
                  <div>N/A</div>
                </div>
                <div className="label-container">
                  <div className="pa-label">Batch Name</div>
                  <div>N/A</div>
                </div>
                <div className="label-container">
                  <div className="pa-label">Supplier</div>
                  {Object.keys(items)?.map((index) =>
                   
                    {
                      supplier =
                        items[index]?.pa_order?.supplier?.distributor_name;
                    }
                  )}

                  <div>{items?.pa_order?.supplier?.distributor_name}</div>
                </div>

                <div
                  className={`Profit ${
                    pasummarycal[indexs]?.profit === 0
                      ? "zero"
                      : pasummarycal[indexs]?.profit > 0
                        ? "positive"
                        : pasummarycal[indexs]?.profit < 0
                          ? "negative"
                          : ""
                  }`}
                >
                  {pasummarycal[indexs]?.pi_total_cost === 0
                    ? pasummarycal[indexs]?.profit?.toLocaleString()
                    : pasummarycal[indexs]?.profit?.toLocaleString()}
                </div>
              </div>
            ) : (
              ""
            )
          )}
          <div className="pasummary-total">
            <div className="prg text-center none">Total</div>
            <div className="label-container">
              <div className="pa-label">BoQ Total Cost</div>
              <div className="prg">{total_boq_cost?.toLocaleString()}</div>
            </div>
            <div className="label-container">
              <div className="pa-label">FInal Total Cost</div>
              <div className="prg">{pi_total_cost?.toLocaleString()}</div>
            </div>
            <div className="label-container mb-3">
              <div className="pa-label">Profit</div>

              <div
                className={`Profit ${
                  total_boq_cost - pi_total_cost == 0
                    ? "zero"
                    : total_boq_cost - pi_total_cost > 0
                      ? "positive"
                      : total_boq_cost - pi_total_cost < 0
                        ? "negative"
                        : ""
                }`}
              >
                {` ${(total_boq_cost - pi_total_cost).toLocaleString()}`}
              </div>
            </div>
          </div>
        </>
      ) : (
        <NoRecord />
      )}
    </div>
  );
}
