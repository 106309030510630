import React, { useEffect, useState } from "react";
import ViewPriceComparison from "../../../components/Payment/ForexApproval/PaymentSideBar";
import axios from "axios";
import { Pagination } from "@mui/material";
import usePagination from "../../Pagination/Pagination";
import { FaSearch } from "react-icons/fa";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link, useParams } from "react-router-dom";
import { API_BASE_URL } from "../../../api/endPoint";
import ImageModal from "./ImageModal";

const ViewAttachedPI = () => {
  const params = useParams();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [attachedPI, setAttachedPI] = useState([]);
  const [search, setSearch] = useState("");
  const [modalImage, setModalImage] = useState(null);
  const [deleteItemId, setDeleteItemId] = useState(null);
  let [page, setPage] = useState(1);
  const PER_PAGE = 4;

  // =================> SEARCH SECTION <===============

  const filteredData = () => {
    return attachedPI
      ?.filter((items) => {
        if (search === "") {
          return items;
        } else if (
          items?.priceComparison?.name
            ?.toLowerCase()
            .includes(search.toLowerCase())
        ) {
          return items;
        }
      })
      .map((items) => items);
  };
  const _DATA = usePagination(filteredData(), PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const count = Math.ceil(filteredData().length / PER_PAGE);

  // =================> GET SECTION <===============

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/scm/sourcing/price_comparison/${params.f_id}`)
      .then((response) => {
        setAttachedPI(response?.data);
        
      })
      .catch((error) => {
     
      });
  }, []);

  // =================> DELETE SECTION <===============

  const handleConfirmation = (attachedPI_ID) => {
    setShowConfirmationModal(true);
    setDeleteItemId(attachedPI_ID);
  };
  const handleCloseConfirmation = () => {
    setShowConfirmationModal(false);
  };
  const handleDelete = () => {
    if (!deleteItemId) return;

    axios
      .delete(`${API_BASE_URL}/scm/sourcing/price_comparison/${deleteItemId}`)
      .then((response) => {

        setShowConfirmationModal(false);
        axios
          .get(`${API_BASE_URL}/scm/sourcing/price_comparison/${params.f_id}`)
          .then((response) => {
            setAttachedPI(response?.data);

          })
          .catch((error) => {

          });
      })
      .catch((error) => {
        console.error("Error deleting data", error);
      });
  };

  // =================> ENLARGE IMAGE SECTION <===============

  const handleImageClick = (src) => {
    setModalImage(src);
  };

  const handleCloseModal = () => {
    setModalImage(null);
  };
  return (
    <ViewPriceComparison title={"Attached PI"}>
      <div className="d-flex align-items-center ">
        <Link
          className="download-btn"
          style={{
            borderRadius: "4px",
          }}
          to={`/sourcing/comparedprices/${params.id}/final/${params.f_id}`}
        >
          <div
            className="d-flex justify-content-around align-items-center"
            style={{
              color: "#ffffff",
            }}
          >
            <IoMdArrowRoundBack />
          </div>
        </Link>
        <div className="input-group m-4">
          <div className="form-outline" data-mdb-input-init>
            <div style={{ position: "relative", width: "250px" }}>
              <input
                type="text"
                value={search}
                onChange={(e) => handleSearchChange(e)}
                placeholder="Enter name ..."
                style={{
                  width: "100%",
                }}
              />
            </div>
          </div>
          <button
            onClick={handleSearchChange}
            type="button"
            style={{ width: "30px", background: "#186569", color: "white" }}
            className="btn btn-success"
            data-mdb-ripple-init
          >
            <FaSearch />
          </button>
        </div>
      </div>

      <div className="row">
        {_DATA?.currentData()?.map((pis) => (
          <div key={pis.id} className="col-sm-6">
            <div className="card m-2">
              <div
                className="card-body d-flex"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>
                  <img
                    src={
                      "https://scm-backend.ienetworks.co" +
                      pis?.priceComparison?.image
                    }
                    alt="Price comparison Image"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      borderRadius: "0",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      handleImageClick(
                        "https://scm-backend.ienetworks.co" +
                          pis?.priceComparison?.image
                      )
                    }
                  />
                </div>
                <div
                  style={{
                    flex: 1,
                    paddingLeft: "20px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <p className="prg">
                    <strong>Name:</strong> {pis?.priceComparison?.name}
                  </p>
                  <p className="prg">
                    <strong>Supplier Name:</strong> {pis?.supplier_name}
                  </p>
                  <button
                    type="submit"
                    className="supplier-remove-btn"
                    onClick={() => handleConfirmation(pis?.priceComparison?.id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {count > 1 ? (
        <Pagination
          style={{
            display: "flex",
            justifyContent: "right",
            marginTop: "20px",
          }}
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
      ) : (
        ""
      )}
      {showConfirmationModal && (
        <div className="modal">
          <div className="modal-dialog modal-dialog-centered ">
            <div className="modal-content " style={{ minHeight: "0" }}>
              <div className="modal-header">
                <p>Are you sure you want to delete PI/Picture?</p>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCloseConfirmation}
                ></button>
              </div>
              <div className="d-flex flex-row form-buttons modal-body">
                <button className="form-save" onClick={handleDelete}>
                  Yes
                </button>
                <button
                  className="form-cancel"
                  onClick={handleCloseConfirmation}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {modalImage && (
        <ImageModal
          src={modalImage}
          alt="Enlarged Image"
          onClose={handleCloseModal}
        />
      )}
    </ViewPriceComparison>
  );
};

export default ViewAttachedPI;
