import React, { useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../../api/endPoint";
import "../../../../components/Logistics/logistics.css";
import attachmentIcon from "../../../../assets/SVG/attach.svg";
import { useParams } from "react-router-dom";
import Selector from "../Select";

export default function SwiftAndPermit(props) {
  // const [addClient, response] = useAddClientMutaion();

  function HandleCancel() {
    props.modal(false);
  }

  const forexId = useParams();
  const [swift, setSwift] = useState(null);
  const [bankPermit, setBankPermit] = useState(null);
  const [issuedBankLc, setIssuedBankLc] = useState(null);
  const [issuedBankTf, setIssuedBankTf] = useState(null);
  const [permitNumber, setPermitNumber] = useState(null);
  let params = {
    swift: swift,
    bank_permit: bankPermit,
    forex_id: forexId?.id,
    consignee_bank_id: props?.previousData[1]?.id,
    issue_bank_lc: issuedBankLc,
    issue_bank_tf: issuedBankTf,
    permit_number: permitNumber,
  };


  const [isPdf, setIsPdf] = useState(null);
  const handleChangeSwift = (e) => {
    setSwift(e?.target?.files[0]);
  };
  const handleChangeBankPermit = (e) => {
    setBankPermit(e?.target?.files[0]);
  };

  const handleClick = () => {
    swift?.name?.includes(".pdf")
      ? setIsPdf(swift?.name?.includes(".pdf"))
      : props?.previousData?.length !== 0 && swift === null
        ? setIsPdf(true)
        : setIsPdf(false);
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();

    if (isPdf === true) {
      props?.previousData?.length === 0
        ? axios
            .post(`${API_BASE_URL}/scm/forex/swift_bank_permit`, params, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {

              HandleCancel();
              props?.successMessage(true);
              props?.message(
                "Swift and permit documents attached successfully!!!"
              );
              // props?.refreshe();
            })
            .then((error) => {

            })
        : axios
            .post(
              `${API_BASE_URL}/scm/forex/swift_bank_permit/${props?.previousData[0]?.id}`,
              params,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {

              HandleCancel();
              props?.successMessage(true);
              props?.message("Request letter document updated successfully!!!");
            })
            .then((error) => {

            });
    }

    // addClient(params)
    //   .unwrap()
    //   .then((response) => {
  
    //   })
    //   .then((error) => {

    //   });
  };


  return (
    <>
      <div id="myModal" className="modal" onClick={HandleCancel}>
        <div
          className="mini-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="logisticsRma-create-project-modal-title">
            <div className="swiftAndPermitsTitle">Swift & Bank Permit</div>
            <div onClick={HandleCancel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="42"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>

          <form
            className="form-container rmaMissedItemForm forexApprovalForms"
            onSubmit={HandleSubmit}
          >
            {swift && isPdf === false ? (
              <p className="select-order-list-info-message swift-info-message">
                Swift file can be only pdf !
              </p>
            ) : (
              <></>
            )}
            <div
              className="form-card-container"
              style={{ marginLeft: "30px", marginTop: "30px" }}
            >
              <div className="form-card-information">
                {props?.previousData?.length !== 0 ? (
                  <>
                    <p
                      className="forex-attached-file-warning"
                      style={{ marginTop: "-40" }}
                    >
                      Swift & Permit document already attached !!!
                    </p>
                  </>
                ) : (
                  <></>
                )}
                <div className="card-center">
                  <div className="card-form" style={{ marginTop: "-30px" }}>
                    <div className="rmaFileInputLabelContainer inputLabelsHolder">
                      <div className="rmaFileInputLabel">Swift</div>
                      <div className="rmaFileInputLabel">Bank Permit</div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginLeft: "-10px" }}
                      >
                        Beneficiary Bank LC
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginLeft: "-80px" }}
                      >
                        Issuing Bank LC Reference
                      </div>
                      <div className="rmaFileInputLabel">Permit Number</div>
                    </div>
                    <div className="input-fields">
                      <div
                        className="file-chooser rmaMissedItemFile-choosers"
                        style={{
                          backgroundImage: `url(${attachmentIcon})`,
                        }}
                      >
                        <input
                          required={
                            props?.previousData?.length === 0 ? true : false
                          }
                          type="file"
                          accept="application/pdf"
                          className="file-input"
                          name="boq"
                          onChange={(e) => {
                            handleChangeSwift(e);
                          }}
                        />
                        <div className="rmaFileUploader">
                          {props?.previousData?.length !== 0
                            ? "File exist"
                            : swift
                              ? swift?.name.slice(0, 10) + "...."
                              : " File"}
                        </div>
                      </div>
                      <div
                        className="file-chooser rmaMissedItemFile-choosers"
                        style={{
                          backgroundImage: `url(${attachmentIcon})`,
                        }}
                      >
                        <input
                          required={
                            props?.previousData?.length === 0 ? true : false
                          }
                          type="file"
                          className="file-input"
                          name="boq"
                          onChange={(e) => {
                            handleChangeBankPermit(e);
                          }}
                        />
                        <div className="rmaFileUploader">
                          {props?.previousData?.length !== 0
                            ? "File exist"
                            : bankPermit
                              ? bankPermit?.name.slice(0, 10) + "...."
                              : " File"}
                        </div>
                      </div>
                      <div>
                        <input
                          required={
                            props?.previousData?.length === 0 ? true : false
                          }
                          defaultValue={
                            props?.previousData &&
                            props?.previousData[1]?.issue_bank_lc
                          }
                          className="forex-select-option swift-and-permit-inputs"
                          placeholder="Enter beneficiary Bank LC"
                          onChange={(event) =>
                            setIssuedBankLc(event.target.value)
                          }
                        />
                      </div>
                      <div>
                        <input
                          required={
                            props?.previousData?.length === 0 ? true : false
                          }
                          defaultValue={
                            props?.previousData &&
                            props?.previousData[1]?.issue_bank_tf
                          }
                          className="forex-select-option swift-and-permit-inputs"
                          placeholder="Enter issued bank LC ref..."
                          onChange={(event) =>
                            setIssuedBankTf(event.target.value)
                          }
                        />
                      </div>
                      <div>
                        <input
                          required={
                            props?.previousData?.length === 0 ? true : false
                          }
                          defaultValue={
                            props?.previousData &&
                            props?.previousData[1]?.permit_number
                          }
                          permit_number
                          className="forex-select-option swift-and-permit-inputs"
                          placeholder="Enter permit number"
                          onChange={(event) =>
                            setPermitNumber(event.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-buttons rma-form-submit">
                <button
                  type="cancel"
                  className="form-cancel clientCancel"
                  onClick={HandleCancel}
                  style={{ width: "112px", height: "32px" }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="form-save clientSave"
                  style={{ width: "112px", height: "32px" }}
                  onClick={handleClick}
                >
                  {props?.previousData?.length !== 0 ? "Update" : "Save"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
