import React, { useState } from "react";
import { useAddClientMutaion } from "../../../../features/SCMApi";
import axios from "axios";
import { API_BASE_URL } from "../../../../api/endPoint";
import "../../logistics.css";
import attachmentIcon from "../../../../assets/SVG/attach.svg";

export default function SCModal(props) {
  // const [addClient, response] = useAddClientMutaion();

  const [insaApproval, setInasaApproval] = useState(null);
  const [broadcastApproval, setBroadcastApproval] = useState(null);
  const [ecaApproval, setecaApproval] = useState(null);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const documentData =
    props?.previousData !== undefined ? props?.previousData : [];

  let params = {
    batch_id: props?.batchId,
    missed_item_id: props.missedItemId,
    insa_approval: insaApproval,
    broadcast_approval: broadcastApproval,
    eca_approval: ecaApproval,
  };
  function HandleCancel() {
    props.modal(false);
  }
  const handleInsaApproval = (e) => {
    setInasaApproval(e?.target?.files[0]);
  };
  const handleBroadcastApproval = (e) => {
    setBroadcastApproval(e?.target?.files[0]);
  };
  const handleEcaApproval = (e) => {
    setecaApproval(e?.target?.files[0]);
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    documentData?.length === 0
      ? axios
          .post(`${API_BASE_URL}/scm/missed-item/security-clearance`, params, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            props?.successMessage(true);
            props?.message(
              "Security clearance document attached successfully!!!"
            );
           
            HandleCancel();
          })
          .then((error) => {
           
          })
      : axios
          .post(
            `${API_BASE_URL}/scm/missed-item/security-clearance/${documentData[0]?.id}`,
            params,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            props?.successMessage(true);
            props?.message(
              "Security clearance document updated successfully!!!"
            );
           
            HandleCancel();
          })
          .then((error) => {
            
          });

    // addClient(params)
    //   .unwrap()
    //   .then((response) => {
  
    //   })
    //   .then((error) => {

    //   });
  };

 

  return (
    <>
      <div id="myModal" className="modal" onClick={HandleCancel}>
        <div
          className="mini-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="logisticsRma-create-project-modal-title">
            <div className="securityClearanceTitle">Security Clearance</div>
            <div onClick={HandleCancel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="42"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>

          <form
            className="form-container rmaMissedItemForm"
            onSubmit={HandleSubmit}
          >
            <div className="form-card-container">
              <div className="form-card-information">
                {documentData?.length !== 0 ? (
                  <>
                    <p
                      className="forex-attached-file-warning"
                      style={{
                        marginLeft: "-4px",
                        marginTop: "-40px",
                        marginBottom: "60px",
                      }}
                    >
                      Security clearance documents already attached !!!
                    </p>
                  </>
                ) : (
                  <></>
                )}
                <div className="card-center">
                  <div className="card-form" style={{ marginTop: "-30px" }}>
                    <div className="rmaFileInputLabelContainer inputLabelsHolder">
                      <div className="rmaFileInputLabel">INSA Approval</div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginLeft: "-15px" }}
                      >
                        Broadcast Approval
                      </div>
                      <div className="rmaFileInputLabel">ECA Approval</div>
                    </div>
                    <div className="input-fields">
                      <div
                        className="file-chooser rmaMissedItemFile-choosers"
                        style={{
                          backgroundImage: `url(${attachmentIcon})`,
                        }}
                      >
                        <input
                          required={documentData?.length === 0 ? true : false}
                          type="file"
                          className="file-input"
                          name="boq"
                          onChange={(e) => {
                            handleInsaApproval(e);
                          }}
                        />
                        <div className="rmaFileUploader">
                          {insaApproval
                            ? insaApproval?.name.slice(0, 10) + "...."
                            : documentData?.length !== 0
                            ? "File exist"
                            : " File"}
                        </div>
                      </div>
                      <div
                        className="file-chooser rmaMissedItemFile-choosers"
                        style={{
                          backgroundImage: `url(${attachmentIcon})`,
                        }}
                      >
                        <input
                          required={documentData?.length === 0 ? true : false}
                          type="file"
                          className="file-input"
                          name="boq"
                          onChange={(e) => {
                            handleBroadcastApproval(e);
                          }}
                        />
                        <div className="rmaFileUploader">
                          {broadcastApproval
                            ? broadcastApproval?.name.slice(0, 10) + "...."
                            : documentData?.length !== 0
                            ? "File exist"
                            : " File"}
                        </div>
                      </div>
                      <div
                        className="file-chooser rmaMissedItemFile-choosers"
                        style={{
                          backgroundImage: `url(${attachmentIcon})`,
                        }}
                      >
                        <input
                          required={documentData?.length === 0 ? true : false}
                          type="file"
                          className="file-input"
                          name="boq"
                          onChange={(e) => {
                            handleEcaApproval(e);
                          }}
                        />
                        <div className="rmaFileUploader">
                          {ecaApproval
                            ? ecaApproval?.name.slice(0, 10) + "...."
                            : documentData?.length !== 0
                            ? "File exist"
                            : " File"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-buttons rma-form-submit">
                <button
                  type="cancel"
                  className="form-cancel clientCancel"
                  onClick={HandleCancel}
                  style={{ width: "112px", height: "32px" }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="form-save clientSave"
                  style={{ width: "112px", height: "32px" }}
                >
                  {documentData?.length !== 0 ? "Update" : "Save"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
