import React from "react";
import {
  useGetBankBalanceQuery,
  useGetForexReceivableQuery,
  useGetForexNeedsQuery,
} from "../../features/SCMApi";

export default function ForexNeed() {
  const { data: bankBalance } = useGetBankBalanceQuery();
  const { data: receivableData } = useGetForexReceivableQuery();
  const { data: forexNeedData } = useGetForexNeedsQuery();

  //Grand total calculator logic
  const balanceGrandTotal = [];
  for (const total in bankBalance?.data) {
    balanceGrandTotal.push(Number(bankBalance?.data[total]?.amount));
  }

  let balanceGrandTotals =
    Math.round(
      (balanceGrandTotal
        .filter((e) => typeof e == "number")
        .reduce((prev, acc) => prev + acc, 0) +
        Number.EPSILON) *
        100
    ) / 100;

  const receivableGrandTotal = [];
  for (const total in receivableData?.data) {
    receivableGrandTotal.push(Number(receivableData?.data[total]?.amount));
  }

  let receivableGrandTotals =
    Math.round(
      (receivableGrandTotal
        .filter((e) => typeof e == "number")
        .reduce((prev, acc) => prev + acc, 0) +
        Number.EPSILON) *
        100
    ) / 100;

  const forexNeedGrandTotal = [];
  for (const total in forexNeedData?.data?.forex_need) {
    forexNeedGrandTotal.push(
      Number(forexNeedData?.data?.forex_need[total]?.amount)
    );
  }

  let forexNeedGrandTotals =
    Math.round(
      (forexNeedGrandTotal
        .filter((e) => typeof e == "number")
        .reduce((prev, acc) => prev + acc, 0) +
        Number.EPSILON) *
        100
    ) / 100;

  return (
    <div className="d-grid gap-2 mt-2">
      <div className="forex-card">
        <div className="forex-text">Forex Need</div>
        <div className="forex-number">
          {forexNeedGrandTotals.toLocaleString()}
        </div>
      </div>
      <div className="forex-card">
        <div className="forex-text">Recievables</div>
        <div className="forex-number">
          {receivableGrandTotals?.toLocaleString()}
        </div>
      </div>
      <div className="forex-card">
        <div className="forex-text">Balance</div>
        <div className="forex-number">
          {balanceGrandTotals?.toLocaleString()}
        </div>
      </div>
    </div>
  );
}
