import * as React from "react";
import NavBar from "../../../Layout/NavBar";
import LogisticsBreadCramp from "../../../components/Logistics/LogisticsRmaBreadCramp";
import MissedItemDetailsHeading from "../../../components/Logistics/MissedItem/MissedItemDetailsHeading";
import MissedItemBatchDetail from "../../../components/Logistics/MissedItem/MissedItemBatchDetail";
import { useLocation } from "react-router-dom";
import SuccessMessage from "../../../components/Payment/SuccessMessage";
import { useGetMissedItemsDetailsQuery } from "../../../features/SCMApi";

export default function MissedItemBatchDetails(props) {
  const location = useLocation();
  const { state } = location;
  const [successMessage, setSuccessMessage] = React.useState(false);
  const [message, setMessage] = React.useState("");
  successMessage &&
    setTimeout(() => {
      setSuccessMessage(false);
    }, 3000);

  const { data: missedItemData } = useGetMissedItemsDetailsQuery(state?.data);

  return (
    <>
      <NavBar />
      <LogisticsBreadCramp />
      <div className="page">
        {successMessage === true ? (
          <>
            <SuccessMessage
              style={{
                marginTop: "10px",
                width: "100%",
                marginLeft: "0px",
                backgroundColor: "#52ffab96",
                mb: "-10px",
              }}
              Message={message}
            />
          </>
        ) : (
          <></>
        )}
        <MissedItemDetailsHeading data={missedItemData?.data[0]} />
        <MissedItemBatchDetail
          data={missedItemData?.data[0]}
          successMessage={setSuccessMessage}
          message={setMessage}
        />
      </div>
    </>
  );
}
