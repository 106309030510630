import React, { useState } from "react";
import { IMG_BASE_URL } from "../../../api/endPoint";
import {
  useGetInventoryQuery,
  useGetItemCategorysQuery,
  useGetPurchasedRequestQuery,
  useGetStoreQuery,
  useGetToolsQuery,
  useGetToolsRequestsQuery,
  useGetUserInfoQuery,
  useInStoreItemsRequestMutation,
  useItemsAcceptMutation,
  useToolRequestMutation,
} from "../../../features/SCMApi";
import AddPurchaseRequest from "../StockModal/AddPurchaseRequest";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { IconButton } from "@mui/material";
import StockDialog from "../../common/StockDialog";
import NoRecord from "../../common/NoRecord";
import InStoreItemsTools from "./InStoreItemsTools";
import InStoreItemsInventory from "./InStoreItemsInventory";
import StockIndex from "../../../pages/Stocks/StockIndex";
import ToolRequestMenu from "./ToolRequestMenu";
import ItemsRequestMenu from "./ItemsRequestMenu";
import AssetsRequestMenu from "./AssetsRequestMenu";
import ApprovedItems from "../Approved";

export default function InStoreItems() {
  let notRequest = [];
  const [confirm, setConfirm] = useState(false);
  const [Accept, setAccept] = useState(false);
  const [dialog, setDialog] = useState(false);
  function HandleRequest() {
    setConfirm(true);
  }
  function HandleAccept() {
    setAccept(true);
  }
  const [ItemRequest, src] = useInStoreItemsRequestMutation();
  const [ItemAccept, acce] = useItemsAcceptMutation();

  const { data: userinfo } = useGetUserInfoQuery();
  let PMandSK =
    userinfo?.role?.role_name == "Project Manager" ||
    userinfo?.role?.role_name == "Super Admin";
  let FE = userinfo?.role?.role_name == "Field Engineer";
  let confirmed_by = userinfo?.id;
  const [Items, setItems] = useState("tools");
  const CheckBoxHandelonShore = (e, type) => {
    setItems(type);
  };

  return (
    <StockIndex>
      <div className="tool-header-container d-flex gap-3 justify-content-center my-3">
        <input
          checked={Items == "tools"}
          id="default-radio-1"
          type="radio"
          value="Tool"
          name="default-radio"
          className="cursor-pointer"
          onClick={() => setItems("tools")}
        />
        <label for="default-radio-1" className="prg">
          Tools
        </label>

        <input
          checked={Items == "items"}
          id="default-radio-2"
          type="radio"
          value="Inventory"
          name="default-radio"
          className="cursor-pointer"
          onClick={() => setItems("items")}
        />
        <label for="default-radio-2" className="prg">
          Items
        </label>
        <input
          checked={Items == "assets"}
          id="default-radio-2"
          type="radio"
          value="Inventory"
          name="default-radio"
          className="cursor-pointer"
          onClick={() => setItems("assets")}
        />
        <label for="default-radio-2" className="prg">
          Assets
        </label>
        <input
          checked={Items == "approved"}
          id="default-radio-2"
          type="radio"
          value="Inventory"
          name="default-radio"
          className="cursor-pointer"
          onClick={() => setItems("approved")}
        />
        <label for="default-radio-2" className="prg">
          Approved
        </label>
      </div>
      {/* {Items === true ? <InStoreItemsTools /> : <InStoreItemsInventory />} */}
      {Items === "tools" ? (
        <ToolRequestMenu />
      ) : Items === "items" ? (
        <ItemsRequestMenu />
      ) : Items === "assets" ? (
        <AssetsRequestMenu />
      ) : (
        <ApprovedItems />
      )}
    </StockIndex>
  );
}
