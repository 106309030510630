import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  useGetManufacturersQuery,
  useGetUserInfoQuery,
} from "../../features/SCMApi";
import Supplier from "../../pages/Supplier";
import SupplierManufacturerModal from "./SupplierModal/SupplierManufacturerModal";
import AddIcon from "@mui/icons-material/Add";
import CheckPermission from "../common/checkPermission/checkPermission";
import { Pagination, Skeleton, Tooltip } from "@mui/material";
import NoRecord from "../common/NoRecord";
import usePagination from "../Pagination/Pagination";
import ReactPaginate from "react-paginate";

export default function SupplierManufacturer() {
  const [createSupplierManufatcurerModal, setCreatesupplierManufacturer] =
    useState(false);
  function createSupplierManufacturerHandler() {
    setCreatesupplierManufacturer(true);
  }
  const {
    data: manufacturer,
    isError,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetManufacturersQuery();

  const [search, setSearch] = useState("");
  const [messages, setMessages] = useState(null);
  const handleSearchChange = (e) => {
    setSearch(e.target.value);

  };
  const { data: userinfo } = useGetUserInfoQuery();
  let [page, setPage] = useState(0);
  const PER_PAGE = 12;
  const count = Math.ceil(
    manufacturer?.data?.filter((items) => {
      if (search === "") {
        return items;
      } else if (
        items.manufacturer_name.toLowerCase().includes(search.toLowerCase())
      ) {
        return items;
      }
      return false;
    }).length / PER_PAGE
  );
  const pageVisted = page * PER_PAGE;
  const handleChange = ({ selected }) => {
    setPage(selected);
  };
  return (
    <Supplier
      Message={messages}
      handleSearchChange={handleSearchChange}
      title={"Manufacturers"}
      placeholder={"search Manufacturer..."}
    >
      {isSuccess ? (
        <div className="page">
          {CheckPermission("supplier_add_manufacturer", userinfo) ? (
            <div
              className="create-project-btn"
              onClick={createSupplierManufacturerHandler}
            >
              <AddIcon
                sx={{
                  "&:hover": {
                    backgroundColor: "#fff",
                    color: "#186569",
                  },
                }}
              />
              Add New
            </div>
          ) : (
            ""
          )}
          {manufacturer?.data?.length !== 0 ? (
            <div className="supplier-module-container">
              {manufacturer?.data
                ?.filter((items) => {
                  if (search === "") {
                    return items;
                  } else if (
                    items.manufacturer_name
                      ?.toLowerCase()
                      .includes(search.toLowerCase())
                  ) {
                    return items;
                  }
                })
                .slice(pageVisted, pageVisted + PER_PAGE)
                .map((items) => (
                  <NavLink
                    to={`${items.id}`}
                    className="card-supplier shadow-md p-3"
                  >
                    <div className="d-flex justify-content-between">
                      <div className="">
                        <Tooltip title={items.manufacturer_name}>
                          <p className="h5-supplier">
                            {items.manufacturer_name?.length >= 20
                              ? items?.manufacturer_name?.slice(0, 13) + "..."
                              : items?.manufacturer_name}
                          </p>
                        </Tooltip>

                        <p className="small">Manufacturer</p>
                      </div>
                      <div className="">
                        <p className="h5-supplier text-end">
                          {items?.product_supplier.length}
                        </p>
                        <p className="small">Distributors</p>
                      </div>
                    </div>
                    <div className="text-center">
                      <p className="name-supplier prg">
                        {items.manufacturer_origin?.name}
                      </p>
                    </div>
                  </NavLink>
                ))}
            </div>
          ) : (
            <NoRecord />
          )}
          <div className="mt-3">
            {count > 1 ? (
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={count}
                onPageChange={handleChange}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            ) : (
              ""
            )}
          </div>
          {createSupplierManufatcurerModal ? (
            <SupplierManufacturerModal
              modal={setCreatesupplierManufacturer}
              refresh={useGetManufacturersQuery}
              setMessages={setMessages}
            />
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div className="supplier-module-container">
          <Skeleton height={150}></Skeleton>
          <Skeleton height={150}></Skeleton>
          <Skeleton height={150}></Skeleton>
          <Skeleton height={150}></Skeleton>
          <Skeleton height={150}></Skeleton>
          <Skeleton height={150}></Skeleton>
          <Skeleton height={150}></Skeleton>
          <Skeleton height={150}></Skeleton>
          <Skeleton height={150}></Skeleton>
        </div>
      )}
    </Supplier>
  );
}
