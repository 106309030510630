import React from "react";
import { NavLink } from "react-router-dom";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import NavBar from "../../Layout/NavBar";
export default function Index(props) {
  return (
    <>
      <NavBar
        placeholder={props?.placeholder}
        handleSearchChange={props.handleSearchChange}
      />
      <div className="all-breadcrumb">
        <Breadcrumb className="">
          <Breadcrumb.Item className="prg" href="/product">
            Product
          </Breadcrumb.Item>
          {props.name === undefined ? (
            ""
          ) : (
            <Breadcrumb.Item className="prg-pri" active>
              {props.name}
            </Breadcrumb.Item>
          )}
        </Breadcrumb>
      </div>
      <div className="page">
        <div className="block">
          <div className="row">
            <div className="col-md-1 me-5">
              <div className="side-nav sub-side-menu">
                <div className="sidebar-text">
                  <ul class="nav nav-pills flex-column  justify-content-around">
                    <li class="nav-item">
                      <NavLink
                        className="nav-link text-white"
                        to="/projects"
                        activeClassName="active"
                      >
                        Projects
                      </NavLink>
                    </li>
                    <li class="nav-item">
                      <NavLink
                        className="nav-link text-white"
                        to="/boq"
                        activeClassName="active"
                      >
                        BOQ
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-10">{props.children}</div>
          </div>
        </div>
      </div>
    </>
  );
}
