import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useGetProjectsQuery } from "../../../features/SCMApi";
import NavBar from "../../../Layout/NavBar";
import Button from "@mui/material/Button";
import "./logisticsScm.style.css";
import ViewMoreButton from "../../../components/Logistics/ViewMoreButton";
import Index from "./Index";
import SelectOption from "../../../components/common/SelectOption";

export default function LogisticsIndex() {
  const { data: project } = useGetProjectsQuery();
  // const [projects, setProjects] = useState(project?.data);
  const [select, setSelected] = useState();
  const [selectedProject, setProject] = useState();
  const list = [];
  project?.data?.map((items) => {
    if (items?.isActive == 1)
      list.push({
        value: items?.id,
        label: items?.project_name,
      });
  });
  return (
    <Index>
      <>
        <div className="batch-index-container">
          <div className="sourcing-dashboard-utility justify-content-center">
            <SelectOption
              options={list}
              setters={setProject}
              value={selectedProject}
            />
            {/* <button className="select-btn">Select</button> */}
          </div>
          <div class="h5-pri text-center mb-2">{select}</div>{" "}
          <div className="batch-index-controllers">
            <div className="batch-index-controller">
              <div className="controller-label">Active Batch</div>
              {selectedProject?.value ? (
                <NavLink
                  to={`batches/${selectedProject?.value}`}
                  // className="view-more-po"
                >
                  <ViewMoreButton />
                </NavLink>
              ) : (
                <button
                  className="view-more-order"
                  onClick={(e) =>
                    setSelected("Please select a project to proceed")
                  }
                >
                  View More
                </button>
              )}
            </div>
            <div className="batch-index-controller">
              <div className="controller-label">Missed Item</div>
              {selectedProject?.value ? (
                <NavLink to={`missedItemBatches/${selectedProject?.value}`}>
                  <ViewMoreButton />
                </NavLink>
              ) : (
                <button
                  className="view-more-order"
                  onClick={(e) =>
                    setSelected("Please select a project to proceed")
                  }
                >
                  View More
                </button>
              )}
            </div>
            <div className="batch-index-controller">
              <div className="controller-label">Delivered Batch</div>
              {selectedProject?.value ? (
                <NavLink to={`deliveredBatches/${selectedProject?.value}`}>
                  <ViewMoreButton />
                </NavLink>
              ) : (
                <button
                  className="view-more-order"
                  onClick={(e) =>
                    setSelected("Please select a project to proceed")
                  }
                >
                  View More
                </button>
              )}
            </div>
            <div className="batch-index-controller">
              <div className="controller-label">RMA</div>
              {selectedProject?.value ? (
                <NavLink to={`rmaBatches/${selectedProject?.value}`}>
                  <ViewMoreButton />
                </NavLink>
              ) : (
                <button
                  className="view-more-order"
                  onClick={(e) =>
                    setSelected("Please select a project to proceed")
                  }
                >
                  View More
                </button>
              )}
            </div>
          </div>
        </div>
      </>
    </Index>
  );
}
