import React from "react";
import ForexSideBar from "../../../components/Payment/ForexApproval/PaymentSideBar";
import "./finance.style.css";
import { NavLink } from "react-router-dom";
import Onshore from "../../../assets/SVG/onShore.jpg";
import Offshore from "../../../assets/SVG/offshore.png";
export default function PaymentCollectionHome() {
  return (
    <ForexSideBar>
      <div className="supplier-menu-container">
        <NavLink to="OnshorePaymentCollection" className="payment-menu-card">
          <div className="text-center">
            <img
              src={Onshore}
              style={{ width: 80, height: 80, borderRadius: "0px" }}
              alt="OnShore"
            />
          </div>
          <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
            OnShore
          </span>
        </NavLink>
        <NavLink to="offshorePaymentCollection" className="payment-menu-card">
          <div className="text-center">
            <img
              src={Offshore}
              style={{ width: 80, height: 80, borderRadius: "0px" }}
              alt="OFFShore"
            />
          </div>
          <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
            OffShore
          </span>
        </NavLink>
      </div>
    </ForexSideBar>
  );
}
