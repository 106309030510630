import React, { useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../../api/endPoint";
import "../../../../components/Logistics/logistics.css";
import Select from "../../ForexApproval/Select";
import DatePicker from "../DatePicker";
import MultipleTextInput from "../MultipleTextInput";
import {
  useUpdateForexPlaningReceivableMutation,
  useGetReceivableManufacturersQuery,
  useGetForexOpeningToQuery,
  useGetReceivableBanksQuery,
  useGetSuppliersOrdersQuery,
} from "../../../../features/SCMApi";
import SelectOption from "../../../common/SelectOption";

export default function EditPaymentPlaning(props) {
  const [updateForexTerm] = useUpdateForexPlaningReceivableMutation();
  const { data: vendorData } = useGetReceivableManufacturersQuery();
  const { data: supplierData } = useGetForexOpeningToQuery();
  const { data: bankData } = useGetReceivableBanksQuery();
  const { data: Suppliers } = useGetSuppliersOrdersQuery();

  const vendorId = props?.previousData?.manufacturer_id;
  // const supplierId = props?.previousData?.supplier_id;
  const bankId = props?.previousData?.bank_id;
  const [collection_budget_date, setCollectionBudgetDate] = useState(
    props?.previousData?.collection_budget_date
  );
  const [descriptionText, setDescriptionText] = React.useState(null);
  const [vendorName, setVendorName] = useState(null);
  const [expected_date, setExpectedDate] = useState(
    props?.previousData?.expected_date
  );
  const [productServiceName, setProductService] = useState(
    props?.previousData?.order_type
  );
  const [bankName, setBankName] = useState(null);
  const [supplierName, setSupplierName] = useState(null);
  const [amount, setAmountName] = useState(props?.previousData?.amount);
  const [isSelected, setIsSelected] = React.useState(
    props?.previousData?.payment_way
  );
  const [supplierID, setSupplier] = useState(null);
  const list = [];
  Suppliers?.data?.map((items) => {
    list.push({
      value: items?.id,
      label: items?.distributor_name,
    });
  });

  let params = {
    id: props?.previousData?.id,
    description: descriptionText,
    order_type: productServiceName,
    manufacturer_id:
      vendorName === null
        ? vendorId
        : vendorData?.data
            ?.filter((vendor) => vendor?.manufacturer_name === vendorName)
            .map((id) => id?.id)
            .flat()
            .toString(),
    expected_date: expected_date,
    payment_way: isSelected,
    bank_id:
      bankName !== null
        ? bankData?.data
            ?.filter((bank) => bank?.bank_name === bankName)
            .map((id) => id?.id)
            .flat()
            .toString()
        : bankId,
    supplier_id:
      supplierName !== null
        ? supplierData?.data
            ?.filter((supplier) => supplier?.distributor_name === supplierName)
            .map((id) => id?.id)
            .flat()
            .toString()
        : supplierID?.value,
    amount: amount,
    collection_budget_date,
  };

  function HandleCancel() {
    props.modal(false);
  }

  const HandleSubmit = async (e) => {
    e.preventDefault();
    updateForexTerm(params)
      .unwrap()
      .then((response) => {
        HandleCancel();
        props?.successMessage(true);
        props?.message("Forex receivable updated successfully!!!");
      })
      .then((error) => {});
  };

  const vendors = vendorData?.data?.map((vendor) => vendor?.manufacturer_name);
  const productService = [
    "Product",
    "Service",
    "Training",
    "MDF",
    "Rebate",
    "Credit Note",
  ];
  const wayOfPayment = ["To Bank", "To Supplier"];
  const banks = bankData?.data?.map((bank) => bank?.bank_name);
  const suppliers = supplierData?.data?.map(
    (supplier) => supplier?.distributor_name
  );

  return (
    <>
      <div id="myModal" className="modal" onClick={HandleCancel}>
        <div
          className="mini-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="logisticsRma-create-project-modal-title">
            <div className="editPayment">Edit Payment</div>
            <div onClick={HandleCancel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="42"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>

          <form
            className="form-container paymentForexApprovalinitiateForex"
            onSubmit={HandleSubmit}
          >
            <div className="form-card-container">
              <div className="form-card-information">
                <div className="card-center">
                  <div className="card-form">
                    <div className="input-labels">
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "16px" }}
                      >
                        Vendor
                      </div>

                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "4px" }}
                      >
                        Product/Service
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "10px" }}
                      >
                        Description
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{
                          marginTop: "80px",
                        }}
                      >
                        Amount
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "5px" }}
                      >
                        Way of Payment
                      </div>
                      {isSelected === "To Bank" ? (
                        <div
                          className="rmaFileInputLabel"
                          style={{ marginTop: "5px" }}
                        >
                          Bank
                        </div>
                      ) : (
                        <></>
                      )}
                      {isSelected === "To Supplier" ? (
                        <div
                          className="rmaFileInputLabel"
                          style={{ marginTop: "5px" }}
                        >
                          Supplier
                        </div>
                      ) : (
                        <></>
                      )}

                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "5px" }}
                      >
                        Expected Date
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "5px" }}
                      >
                        Collection Budget Date
                      </div>
                    </div>
                    <div className="forex-input-fields">
                      <div>
                        <Select
                          name={vendors}
                          passedValue={
                            props?.previousData?.forex_receivable_manufacturer
                              ?.manufacturer_name
                          }
                          onChange={setVendorName}
                        />
                      </div>
                      <div>
                        <Select
                          name={productService}
                          passedValue={props?.previousData?.order_type}
                          onChange={setProductService}
                        />
                      </div>
                      <div style={{ width: "110%", marginLeft: "20px" }}>
                        <MultipleTextInput
                          defaultValue={props?.previousData?.description}
                          setText={setDescriptionText}
                        />
                      </div>
                      <div>
                        <input
                          required
                          type="text"
                          className="forex-select-option"
                          placeholder="Amount"
                          defaultValue={props?.previousData?.amount}
                          onChange={(event) =>
                            setAmountName(event.target.value)
                          }
                        />
                      </div>
                      <div>
                        <Select
                          name={wayOfPayment}
                          passedValue={props?.previousData?.payment_way}
                          onChange={setIsSelected}
                        />
                      </div>
                      {isSelected === "To Bank" ? (
                        <div>
                          <Select
                            name={banks}
                            passedValue={
                              props?.previousData?.forex_receivable_bank
                                ?.bank_name
                            }
                            onChange={setBankName}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                      {isSelected === "To Supplier" ? (
                        <div
                          style={{
                            marginLeft: "20px",
                          }}
                        >
                          <SelectOption
                            options={list}
                            setters={setSupplier}
                            value={supplierID}
                            isinput={true}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                      <div style={{ marginLeft: "-4px" }}>
                        <input
                          type="date"
                          defaultValue={expected_date}
                          className="payment-term-and-condition-input"
                          placeholder="Enter expected date"
                          required
                          onChange={(e) => setExpectedDate(e.target.value)}
                        />
                      </div>
                      <div style={{ marginLeft: "-4px" }}>
                        <input
                          type="date"
                          defaultValue={collection_budget_date}
                          className="payment-term-and-condition-input"
                          placeholder="Enter expected date"
                          required
                          onChange={(e) =>
                            setCollectionBudgetDate(e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-buttons clientForm">
                <button
                  type="cancel"
                  className="form-cancel clientCancel"
                  style={{ width: "112px", height: "32px" }}
                  onClick={HandleCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="form-save clientSave"
                  style={{ width: "112px", height: "32px" }}
                  onClick={HandleSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
