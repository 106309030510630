import { IconButton, Pagination, Tooltip } from "@mui/material";
import React from "react";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import AddIcon from "@mui/icons-material/Add";
import CreatePermission from "../SettingModal/CreatePermission";
import { useState } from "react";
import {
  useGetPermissionsQuery,
  useGetRolesQuery,
} from "../../../features/SCMApi";
import usePagination from "../../Pagination/Pagination";
import axios from "axios";
import { API_BASE_URL } from "../../../api/endPoint";
import { Tab, Tabs } from "react-bootstrap";
import AllRoles from "./AllRoles";
import ProjectRoles from "./ProjectRoles";
export default function Role(props) {
  return (
    <div className="card py-3">
      <p className="h5 ms-3">Role</p>
      <div className="permission-divider"></div>
      <Tabs
        defaultActiveKey="roles"
        id="uncontrolled-tab-example"
        className="mb-3 mt-2 d-flex justify-content-center gap-4"
      >
        <Tab eventKey="roles" title="Roles">
          <AllRoles setMessages={props?.setMessages} />
        </Tab>
        <Tab eventKey="projectrole" title="Project Role" className="">
          <ProjectRoles setMessages={props?.setMessages} />
        </Tab>
      </Tabs>
    </div>
  );
}
