import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../../../api/endPoint";
import axios from "axios";
import SoaSUmmaryTopBar from "../../../../components/Payment/ForexApproval/PaymentSideBar";
import usePagination from "../../../Pagination/Pagination";
import { Pagination } from "@mui/material";
import { FaSearch } from "react-icons/fa";
import SOASummaryReport from "./SOASummaryReport";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";

const SOAViewComponent = () => {
  const [soaSummaryData, setSoaSummaryData] = useState([]);
  const [search, setSearch] = useState("");
  let [page, setPage] = useState(1);
  let counter = 0;

  const PER_PAGE = 15;
  const filteredData = () => {
    return soaSummaryData
      ?.filter((items) => {
        if (search === "") {
          return items;
        } else if (
          items.supplier_name?.toLowerCase().includes(search.toLowerCase())
        ) {
          return items;
        }
      })

      .map((items) => items);
  };
  const _DATA = usePagination(filteredData(), PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const count = Math.ceil(filteredData().length / PER_PAGE);
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/scm/payment/soa_summery_view/0`
        );
        
        setSoaSummaryData(response?.data?.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <SoaSUmmaryTopBar title={"SOA Summary"}>
      <div className="payable-container">
        <div className="Payable-Table-Container prg">
          <div className="d-flex gap-3 justify-content-end">
            <Link
              className="download-btn"
              style={{
                borderRadius: "4px",
                width: "133px",
              }}
              to="/payment/supplierpayment"
            >
              <div
                className="d-flex justify-content-around align-items-center"
                style={{
                  color: "#ffffff",
                }}
              >
                <IoMdArrowRoundBack />
                <div className="px-2">Go back</div>
              </div>
            </Link>
            <SOASummaryReport />
          </div>
          <div className="input-group m-4">
            <div className="form-outline" data-mdb-input-init>
              <div style={{ position: "relative", width: "250px" }}>
                <input
                  type="text"
                  value={search}
                  onChange={(e) => handleSearchChange(e)}
                  placeholder="Enter supplier name ..."
                  style={{
                    width: "100%",
                  }}
                />
              </div>
            </div>
            <button
              onClick={handleSearchChange}
              type="button"
              style={{ width: "30px", background: "#186569", color: "white" }}
              className="btn btn-success"
              data-mdb-ripple-init
            >
              <FaSearch />
            </button>
          </div>
          <div class="table-responsive border m-[20px]">
            <table class="table table-bordered">
              <thead>
                <tr style={{ backgroundColor: "rgb(0, 112, 192, 0.2)" }}>
                  <th>No. </th>
                  <th>Supplier Name</th>
                  <th>Total credit</th>
                  <th>Total Debit</th>
                  <th>Closing Balance</th>
                </tr>
              </thead>
              {_DATA?.currentData()?.map((summary, index) => (
                <tbody>
                  <tr>
                    <td>{(counter += 1)}</td>
                    <td>{summary?.supplier_name}</td>
                    <td>{Number(summary?.total_credit).toLocaleString()}</td>
                    <td>{Number(summary?.total_debit).toLocaleString()}</td>
                    <td>{Number(summary?.closing_balance).toLocaleString()}</td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
          {count > 1 ? (
            <Pagination
              style={{
                display: "flex",
                justifyContent: "right",
                marginTop: "20px",
              }}
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </SoaSUmmaryTopBar>
  );
};

export default SOAViewComponent;
