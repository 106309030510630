import React from "react";
import { useState } from "react";
import { useRequestPrfMutation } from "../../../features/SCMApi";
export default function PrfRequestModal(props) {

  const [requestPRF] = useRequestPrfMutation();
  let request = {
    po_id: props?.data?.id,
  };
  const HandleSubmit = async (e) => {
    e.preventDefault();

    requestPRF(request).unwrap().then((response) => {

        props.setMessages("PRF Requested successfully");
        props.modal(false);
      })
      .catch((error) => {

      });
  };
  return (
    <div
      id="Modal"
      className="dialog-modal "
      onClick={(e) => {
        props?.modal(false);
        props?.setters(false);
      }}
    >
      <div
        className="stock-dialog-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="dialog-info">Are you sure to request</div>
        <div className="prg-pri text-center">
          {props?.data?.order?.order_name}
        </div>

        <div className="confirm-dialog-controller">
          <div
            className="dialog-cancel-btn"
            onClick={(e) => {
              props?.modal(false);
            }}
          >
            Cancel
          </div>
          <button
            type={"submit"}
            onClick={(e) => HandleSubmit(e)}
            className={"form-save "}
          >
            Request
          </button>
        </div>
      </div>
    </div>
  );
}
