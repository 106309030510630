import React from "react";
import { useState } from "react";
import { API_BASE_URL } from "../../api/endPoint";
import { useAddNewSubSolutionMutation } from "../../features/SCMApi";
import axios from "axios";

export default function AddNewSubSolution(props) {
  const [solution_name, setSubSolution] = useState("");
  const [addNewSolution] = useAddNewSubSolutionMutation();
  function handleSubmit() {
    addNewSolution({ solution_name, department_id: props?.params?.id })
      .unwrap()
      .then((response) => {

        // window.location.reload();
        props?.ToggleModal(false);
        props?.ToggleParentModal(false);
        props?.setSuccessMessage(true);
        props?.setMessage("sub solution created successfully");
      })
      .then((error) => {

      });
  }
  return (
    <>
      <div
        id="Modal"
        className="edit-mini-modal"
        onClick={(e) => props?.ToggleModal(false)}
      >
        <div
          className="edit-mini-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="edit-dialog-info">Edit Sub Solution</div>
          <div className="edit-modal-divider"></div>
          <div className="row gap-3 centered-content-column">
            <div className="col-12  ">
              <div className="">
                <div className="edit-input-label mb-1">SubSolution</div>
                <input
                  onChange={(e) => setSubSolution(e.target.value)}
                  //   defaultValue={solution_name}
                  type="text"
                  className="select-option"
                />
              </div>
            </div>
            <div className="confirm-dialog-controller">
              <button
                className="form-cancel"
                onClick={(e) => {
                  props?.ToggleModal(false);
                }}
              >
                Cancel
              </button>
              <button
                className="form-save"
                type={"submit"}
                onClick={(e) => {
                  handleSubmit();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
