import axios from "axios";
import React from "react";
import { useState } from "react";
import { API_BASE_URL, IMG_BASE_URL } from "../../api/endPoint";
import { useGetUserInfoQuery } from "../../features/SCMApi";

export default function Signature() {
  const [image, setImage] = useState({ preview: "", data: "" });
  const { data: userinfo } = useGetUserInfoQuery();

  const handleChange = function loadFile(e) {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e?.target?.files[0],
    };
    setImage(img);
  };

  const HandleSubmit = async (e) => {
    let form = new FormData();
    form.append("signature", image?.data);
    e?.preventDefault();
    axios
      .post(`${API_BASE_URL}/change-profile/${userinfo?.id}`, form, {})
      .then((response) => {

        //window.location.reload(true);
      })
      .then((error) => {

      });
    // // createSupplier(params)
    // //   .unwrap()
    // //   .then((response) => {

    // //   })
    // //   .then((error) => {

    // //   });
  };

  return (
    <div className="d-grid justify-content-center gap-3">
      <div id="request--icon">
        <input
          name="Select File"
          type="file"
          onChange={handleChange}
          accept="image/*"
        ></input>
        {image?.preview !== "" ? (
          <img
            className="add-purchase-image"
            src={image?.preview}
            alt="image description"
          />
        ) : userinfo?.signature == "" ? (
          <div className="h5-pri">Add Signature</div>
        ) : (
          <img
            className="add-purchase-image"
            src={`${IMG_BASE_URL}${userinfo?.signature}`}
            alt="image description"
          />
        )}
      </div>
      {image?.preview !== "" ? (
        <button className="form-save mb-3" onClick={HandleSubmit}>
          Change Signature
        </button>
      ) : (
        ""
      )}
    </div>
  );
}
