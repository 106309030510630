import React from "react";
import NavBar from "../../../Layout/NavBar";
import LogisticsBreadCramp from "../../../components/Logistics/LogisticsRmaBreadCramp";
import RmaDetailsHeading from "../../../components/Logistics/RMA/RmaDetailsHeading";
import RmaBatchDetail from "../../../components/Logistics/RMA/RmaBatchDetail";
import { useLocation } from "react-router-dom";
import SuccessMessage from "../../../components/Payment/SuccessMessage";
import { useGetRmaDetailsQuery } from "../../../features/SCMApi";

export default function RMABatchDetails() {
  const location = useLocation();
  const { state } = location;
  const [successMessage, setSuccessMessage] = React.useState(false);
  const [message, setMessage] = React.useState("");
  successMessage &&
    setTimeout(() => {
      setSuccessMessage(false);
    }, 3000);

  const { data: rmaData } = useGetRmaDetailsQuery(state?.data);


  return (
    <>
      <NavBar />
      <LogisticsBreadCramp />
      <div className="page">
        {successMessage === true ? (
          <>
            <SuccessMessage
              style={{
                marginTop: "10px",
                width: "100%",
                marginLeft: "0px",
                backgroundColor: "#52ffab96",
                mb: "-10px",
              }}
              Message={message}
            />
          </>
        ) : (
          <></>
        )}
        <RmaDetailsHeading data={rmaData?.data[0]} />
        <RmaBatchDetail
          data={rmaData?.data[0]}
          successMessage={setSuccessMessage}
          message={setMessage}
        />
      </div>
    </>
  );
}
