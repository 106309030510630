import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  useCloseProjectMutation,
  useGetProjectQuery,
  useGetProjectRolePermissionQuery,
  useGetUserInfoQuery,
} from "../../features/SCMApi";

export default function CloseProject(props) {
  const floating_button = {
    display: "flex",
    justifyContent: "end",
  };
  const params = useParams();
  const { data: project } = useGetProjectQuery(params?.projectId);
  const [modal, setModal] = useState(false);
  function HandleModal() {
    setModal(true);
  }
  
  const { projectId } = useParams();
  const { data: projectmembers } = useGetProjectQuery(projectId);
  const { data: userinfo } = useGetUserInfoQuery();
  const { data: roleRoles } = useGetProjectRolePermissionQuery(projectId);
  const [closeProject] = useCloseProjectMutation(projectId);

  const CloseProjectModal = () => {
    let close = {
      id: projectId,
    };
    function handleClose() {
      closeProject(close)
        .unwrap()
        .then((response) => {
         
          props?.setMessage(
            `Project ${
              project?.data?.isActive == 1 ? "Closed" : "Opened"
            } Successfully`
          );
          props?.setSuccessMessage(true);
          setModal(false);
        })
        .then((error) => {
          
        });
    }
    
    return (
      <div
        id="Modal"
        className="dialog-modal "
        onClick={(e) => {
          setModal(false);
        }}
      >
        <div
          className="dialog-modal-content"
          onClick={(e) => e.stopPropagation()}
          style={{ height: "fit-content" }}
        >
          <div className="dialog-info text-center">
            Are you sure to {project?.data?.isActive == 1 ? "Close" : "Open"}
            <div className="prg-pri text-center mt-2">
              {project?.data?.project_name}
            </div>
          </div>
          <div className="confirm-dialog-controller mb-2">
            <div
              className="dialog-cancel-btn"
              onClick={(e) => {
                setModal(false);
              }}
            >
              Cancel
            </div>
            <button
              type="submit"
              onClick={handleClose}
              className="dialog-delete-btn"
            >
              {project?.data?.isActive == 1 ? "Close" : "Open"}
            </button>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {!modal ? (
        props?.checkPermission(
          "add_member_to_project",
          projectmembers,
          userinfo,
          roleRoles
        ) ? (
          <div style={floating_button}>
            <button className="form-save" onClick={(e) => HandleModal(e)}>
              {project?.data?.isActive == 1 ? "Close Project" : "Open Project"}
            </button>
          </div>
        ) : (
          ""
        )
      ) : (
        ""
      )}
      {modal ? <CloseProjectModal /> : ""}
    </>
  );
}
