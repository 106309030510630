import React, { useState } from "react";
import "./Supplier.css";
import { NavLink } from "react-router-dom";
import Supplier from "../../pages/Supplier";
import SupplierModal from "./SupplierModal/SupplierModal";
import {
  useGetSuppliersQuery,
  useGetUserInfoQuery,
} from "../../features/SCMApi";
import AddIcon from "@mui/icons-material/Add";
import CheckPermission from "../../components/common/checkPermission/checkPermission";
import NoRecord from "../common/NoRecord";
import { Pagination, Tooltip } from "@mui/material";
import usePagination from "../Pagination/Pagination";
import ReactPaginate from "react-paginate";
export default function SupplierContainer() {
  const [createSupplierModal, setCreatesupplier] = useState(false);
  function createSupplierHandler() {
    setCreatesupplier(true);
  }
  const { data: userinfo } = useGetUserInfoQuery();
  const { data: supplier } = useGetSuppliersQuery();

  const [search, setSearch] = useState("");
  const handleSearchChange = (e) => {
    setSearch(e.target.value);

  };
  const [Messages, setMessages] = useState(null);
  let [page, setPage] = useState(0);

  const PER_PAGE = 12;
  const count = Math.ceil(
    supplier?.data?.filter((items) => {
      if (search === "") {
        return items;
      } else if (
        items.distributor_name.toLowerCase().includes(search.toLowerCase())
      ) {
        return items;
      }
      return false;
    }).length / PER_PAGE
  );
  const pageVisted = page * PER_PAGE;
  const handleChange = ({ selected }) => {
    setPage(selected);
  };

  return (
    <Supplier
      title={"Suppliers"}
      Message={Messages}
      handleSearchChange={handleSearchChange}
      placeholder={"search Supplier..."}
    >
      <div className="page">
        {CheckPermission("add_supplier", userinfo) ? (
          <div className="create-project-btn" onClick={createSupplierHandler}>
            <AddIcon
              sx={{
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#186569",
                },
              }}
            />
            Add New
          </div>
        ) : (
          <></>
        )}

        {supplier?.data?.length !== 0 ? (
          <div className="supplier-module-container">
            {supplier?.data
              ?.filter((items) => {
                if (search === "") {
                  return items;
                } else if (
                  items.distributor_name
                    ?.toLowerCase()
                    .includes(search.toLowerCase())
                ) {
                  return items;
                }
              })
              .slice(pageVisted, pageVisted + PER_PAGE)
              .map((items) => (
                <NavLink
                  to={`${items.id}`}
                  className="card-supplier shadow-md p-3"
                >
                  <div className="d-flex justify-content-between">
                    <div className="">
                      <Tooltip title={items.distributor_name}>
                        <p className="h5-supplier">
                          {items.distributor_name?.length >= 20
                            ? items?.distributor_name.slice(0, 18) + "..."
                            : items?.distributor_name}
                        </p>
                      </Tooltip>
                      <p className="small">Distributor</p>
                    </div>

                    <div className="">
                      <p className="h5-supplier text-end">
                        {items?.product.length}
                      </p>

                      <p className="small">Products</p>
                    </div>
                  </div>
                  <div className="text-center">
                    <p className="name-supplier prg">
                      {items.supplier_origin?.name}
                    </p>
                  </div>
                </NavLink>
              ))}
          </div>
        ) : (
          <NoRecord />
        )}
        <div className="mt-3">
          {count > 1 ? (
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={count}
              onPageChange={handleChange}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          ) : (
            ""
          )}
        </div>

        {createSupplierModal ? (
          <SupplierModal
            modal={setCreatesupplier}
            data={useGetSuppliersQuery}
            setMessages={setMessages}
          />
        ) : (
          <></>
        )}
      </div>
    </Supplier>
  );
}
