import React from "react";
import ForexSideBar from "../../../components/Payment/ForexApproval/PaymentSideBar";
import ForexApprovalDetail from "../../../components/Payment/ForexApproval/ForexApprovalDetail";
import "../forexPages.style.css";
import { useGetForexDetailQuery } from "../../../features/SCMApi";
import { useParams } from "react-router-dom";
export default function FADetailIEInitiate() {
  const forexId = useParams();
  const { data: forexDetail } = useGetForexDetailQuery(forexId?.id);

  return (
    <ForexSideBar
      name={forexDetail?.data[0]?.forex_name}
      title={"Forex Approval"}
      navlink={"/payment/forexApproval"}
    >
      <ForexApprovalDetail />
    </ForexSideBar>
  );
}
