import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import { IMG_BASE_URL } from "../../../../api/endPoint";

const RmaProfile = (props) => {
  // const logisticsOfficerRole = props?.data?.rma_project?.project_participants
  //   .map((data) => data?.role)
  //   ?.filter((data) => data?.role_name === "Logistics Officer")
  //   .map((data) => data?.id)
  //   .flat();
  // const logisticsOfficer =
  //   props?.data?.rma_project?.project_participants.filter(
  //     (data) => data?.role_id === logisticsOfficerRole.toString()
  //   );

  let logisticOfficers = [];
  props?.data?.rma_project?.project_participants?.map((item) =>
    item?.role?.role_name == "Logistics Officer"
      ? logisticOfficers.push(item)
      : null
  );
  const Item = styled(Paper)(({ theme }) => ({
    height: "90px",
    color: theme.palette.text.secondary,
    alignItems: "center",
    border: "1px solid #AEB8C9",
    overflowY: logisticOfficers.length > 1 ? "scroll" : "none",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f7f5f5",
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#186569",
      borderRadius: "3px",
      boxShadow: "inset 0 0 5px grey",
    },
  }));
  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={3.5}
        xl={3.5}
        xxl={3.5}
        marginRight={{ xs: "16px", sm: "0px", md: "0px" }}
        marginLeft={{ xs: "16px", sm: "0px", md: "0px" }}
      >
        <Item elevation={0}>
          {props?.data?.rma_project &&
          props?.data?.rma_project?.project_participants?.length !== 0 ? (
            logisticOfficers?.map((officer) => (
              <div className="logisticsRmaProfile">
                <div className="rma_missed_item_avatar">
                  <Avatar
                    alt={officer?.name}
                    src={
                      officer?.avatar !== null
                        ? `${IMG_BASE_URL}${officer?.avatar}`
                        : officer?.avatar
                    }
                    sx={{ height: "55px", width: "55px" }}
                  />
                </div>{" "}
                <div className="rmaUserName">
                  {officer?.name}
                  <div className="profilePositionTitle">Logistics officer</div>
                </div>
              </div>
            ))
          ) : (
            <>
              <div className="logisticsRmaProfile">
                <div className="rmaUserName">
                  Not Assigned
                  <div className="profilePositionTitle">Logistics officer</div>
                </div>
              </div>
            </>
          )}
        </Item>
      </Grid>
    </>
  );
};

export default RmaProfile;
