import React from "react";
import NoRecord from "../../common/NoRecord";

export default function RoleDetailModal(props) {
  
  let role = props.roledetail;
  return (
    <div>
      {" "}
      <>
        <div
          id="myModal"
          className="mini-modal"
          onClick={(e) => props?.ToggleModal(false)}
        >
          <div
            className="mini-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="create-project-modal-title">
              <div> {role?.role_name}</div>
              <div
                className="close-modal-button"
                onClick={(e) => props?.ToggleModal(false)}
              >
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.4612 5.78721L19.8657 7.19175L7.46095 19.5965L6.0564 18.192L18.4612 5.78721ZM7.17507 5.51376L20.3505 18.6891L18.8589 20.1807L5.68351 7.00531L7.17507 5.51376Z"
                    fill="#353945"
                  />
                </svg>
              </div>
            </div>
            <div className="solution-profile">
              <div className="d-grid justify-content-center">
                <p className="text-center prg">Permissions</p>
                {role?.permissions?.length !== 0 ? (
                  role?.permissions?.map((permission) => (
                    <ul>
                      <li className="h5-pri">{permission?.permission_name}</li>
                    </ul>
                  ))
                ) : (
                  <NoRecord />
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
