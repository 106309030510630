import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddUnitofMeasurement from "./SettingModal/AddUnitofMesurenment";
import EditUnitofMeasurement from "./SettingModal/EditUnitofMeasurenment";
import {
  useDeleteUnitOfMeasurementMutation,
  useGetUnitOfMeasurementQuery,
} from "../../features/SCMApi";
import DeleteModal from "../Supplier/SupplierModal/DeleteModal";

export default function UnitofMeasurement() {
  const [addUnit, setUnit] = useState(false);
  const [editUnit, setEditUnit] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [unitToDeleteId, setUnitToDeleteId] = useState(null);
  const [updateUnit, setUpdateUnit] = useState(null);

  const { data: unit } = useGetUnitOfMeasurementQuery();
  const [deleteUnit] = useDeleteUnitOfMeasurementMutation();

  const handleAddUnitModal = () => {
    setUnit(true);
  };

  const handleEditUnitModal = (items) => {
    setEditUnit(true);
    setUpdateUnit(items);
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const handleOpenDeleteModal = (id) => {
    setUnitToDeleteId(id);
    toggleDeleteModal();
  };

  const handleDeleteUnit = () => {
    const deleted = { id: unitToDeleteId };
    deleteUnit(deleted)
      .unwrap()
      .then(() => {
        console.log("Unit of measurement deleted successfully");
      })
      .catch((error) => {
        console.error("Error deleting unit of measurement:", error);
      });
    toggleDeleteModal();
  };

  return (
    <div className="pcard incoterm">
      <section className="pi-header">
        <h3>Unit of Measurement</h3>
      </section>
      {unit?.data?.map((items) => (
        <section className="pi-body" key={items.id}>
          <p>{items?.name}</p>
          <p>{items?.description}</p>
          <div>
            <ModeEditOutlineOutlinedIcon
              onClick={() => handleEditUnitModal(items)}
              sx={{
                color: "#52FFAC",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#52FFAC",
                  border: "1px solid #52FFAC",
                  borderRadius: 25,
                },
              }}
            />
            <DeleteOutlineOutlinedIcon
              onClick={() => handleOpenDeleteModal(items.id)}
              sx={{
                color: "#FF5252",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#FF5252",
                  border: "1px solid #FF5252",
                  borderRadius: 25,
                },
              }}
            />
          </div>
        </section>
      ))}

      <section className="pi-footer">
        <div onClick={handleAddUnitModal} className="pi-footer-icon">
          <AddIcon />
        </div>
      </section>
      {addUnit && <AddUnitofMeasurement modal={setUnit} />}
      {editUnit && (
        <EditUnitofMeasurement modal={setEditUnit} data={updateUnit} />
      )}

      <DeleteModal
        show={showDeleteModal}
        handleDeleteModal={toggleDeleteModal}
        name={"unit of measurement"}
        deletedId={unitToDeleteId}
        navigationto={"smm/unit-of-measurement"}
        handleDelete={handleDeleteUnit}
      />
    </div>
  );
}
