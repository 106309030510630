import React from "react";
import { useState } from "react";

import "./sourcing.style.css";
import NavBar from "../../Layout/NavBar";
import { NavLink } from "react-router-dom";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
export default function SourcingIndex(props) {
  const [createOrderModalToggle, setCreateOrderModalToggle] = useState(false);
  const [open, setOpen] = useState(true);

  function handleCreateOrderModal() {
    setCreateOrderModalToggle(true);
  }
  return (
    <>
      <NavBar
        placeholder={props?.placeholder}
        handleSearchChange={props.handleSearchChange}
      />
      <div className="all-breadcrumb">
        <Breadcrumb className="ms-3">
          <Breadcrumb.Item className="prg" href="/sourcing">
            Sourcing
          </Breadcrumb.Item>
          {props.title == undefined ? (
            ""
          ) : (
            <Breadcrumb.Item className="prg" href={props.navlink}>
              {props.title}
            </Breadcrumb.Item>
          )}
          {props.names == undefined ? (
            ""
          ) : (
            <Breadcrumb.Item className="prg-pri" active>
              {props.names}
            </Breadcrumb.Item>
          )}

          {props.name == undefined ? (
            ""
          ) : (
            <Breadcrumb.Item className="prg-pri" active>
              {props.name}
            </Breadcrumb.Item>
          )}
        </Breadcrumb>
      </div>
      <div className="page">
        {props?.Message !== null && props?.Message !== undefined ? (
          <Box sx={{ width: "100%", color: "#FFF" }}>
            <Collapse in={open}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{
                  mb: 2,
                  backgroundColor: "#52FFAB96",
                  color: "#000",
                  "& .MuiAlert-icon": {
                    fontSize: 25,
                    color: "#186569",
                  },
                }}
              >
                {props?.Message}
              </Alert>
            </Collapse>
          </Box>
        ) : (
          ""
        )}
        <>{props.children}</>
      </div>
    </>
  );
}
