import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { styled } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import SuccessMessage from "../../SuccessMessage";
import {
  useGetCurrenciesQuery,
  useGetProjectsQuery,
  useGetSoaReportMutation,
  useGetUserInfoQuery,
} from "../../../../features/SCMApi";
import CheckPermission from "../../../common/checkPermission/checkPermission";
import Select from "../../ForexApproval/Select";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
// import EditSupplierPaymentButton from "./Modal/EditSupplierPaymentButton";
import DebitHistoryDetailButton from "./DebitHistory";
import DownloadIcon from "@mui/icons-material/Download";
import { IconButton, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import TableContainer from "@mui/material/TableContainer";
import SOAReport from "../DownloadReport/SOAReport";
import NoRecord from "../../../common/NoRecord";
import { API_BASE_URL, IMG_BASE_URL } from "../../../../api/endPoint";
import SOASummaryReport from "./SOASummaryReport";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { Link } from "react-router-dom";

const TCell = styled(TableCell)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "10.5px",
  lineHeight: "12px",
  color: "#353945",
  width: "45px",
  padding: "0 0 0 5px",
  // textAlign: "left",
}));
const DayDisplay = styled("div")(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "12px",
  lineHeight: "12px",
  color: "#353945",
  // width: "50px",
  // padding: "0 0 0 5px",
  // // textAlign: "left",
}));
const TCellForOrder = styled(TableCell)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "10.5px",
  lineHeight: "12px",
  color: "#353945",
  width: "150px",
  padding: "0 5px 0 0",
}));

const TCellHeader = styled(TableCell)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "11px",
  lineHeight: "12px",
  color: "#353945",
  padding: "1px",
}));
const GrandTotal = styled(TableCell)(({ theme }) => ({
  display: "flex",
  justifyContent: "end",
  borderBottom: "#fff",
}));

const Pagination = styled(TablePagination)(({ theme }) => ({
  "& .css-pdct74-MuiTablePagination-selectLabel": {
    display: "none",
  },
  "& .css-levciy-MuiTablePagination-displayedRows": {
    marginTop: "19px",
  },
  height: "55px",
  marginBottom: "-25px",
  ".MuiInputBase-root": {
    marginTop: "4.5px",
    marginleft: "10px",
  },
  "& .MuiTablePagination-actions": {
    marginTop: "2px",
    marginLeft: "8px",
  },
}));

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: "2020-01-05",
        customerId: "11091700",
        amount: 3,
      },
      {
        date: "2020-01-02",
        customerId: "Anonymous",
        amount: 1,
      },
    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);


  function date(dates) {
    const dateStr = dates;
    const date = new Date(dateStr);
    const options = {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);


    return formattedDate;
  }
  const header = {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "10.5px",
    lineHeight: "12px",
    color: "#353945",
  };
  const tdata = {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "10.5px",
    lineHeight: "12px",
    color: "white",
    borderBottom: "1px solid #eff2f3",
  };
  const { data: userinfo } = useGetUserInfoQuery();
  let total_debit = 0;

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {row?.supplier_name === "IE Netwoks LLC" ? (
          <TableCell sx={header} component="th" scope="row">
            {row.description}
          </TableCell>
        ) : (
          <TableCell sx={header} component="th" scope="row">
            {row.order_name}
          </TableCell>
        )}
        <TableCell sx={header} align="left">
          {row?.pi_number == null ? "-" : row?.pi_number}
        </TableCell>
        <TableCell sx={header} align="left">
          {date(row?.created_at)}
        </TableCell>
        <TableCell sx={header} align="left">
          {Number(row.debit)?.toLocaleString()}
        </TableCell>
        <TableCell sx={header} align="left">
          {Number(row.credit)?.toLocaleString()}
        </TableCell>
        <TableCell sx={header} align="left">
          {Number(row.credit - row.debit)?.toLocaleString()}
        </TableCell>
        <TableCell sx={header} align="left">
          {row.currency_name}
        </TableCell>
        <TableCell sx={header} align="left">
          {row.payment_due_date == null ? "-" : date(row?.payment_due_date)}
        </TableCell>
        <TableCell align="left" padding="0px">
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <SOAReport
              data={row?.statement_of_account_history}
              po_number={row?.order_name}
              pi_number={row?.pi_number}
              id={row.id}
            />

            <DebitHistoryDetailButton
              editData={row}
              disabled={
                CheckPermission("view_payment_history", userinfo) ? false : true
              }
            />
          </div>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            background: "cadetblue",
            color: "white",
          }}
          colSpan={12}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Statement
              </Typography>
              {row?.statement_of_account_history?.length != 0 ? (
                <>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={tdata}>Debit</TableCell>
                        <TableCell sx={tdata}>Credit</TableCell>
                        <TableCell sx={tdata} align="left">
                          Closing Balance
                        </TableCell>
                        <TableCell sx={tdata} align="left">
                          Payment Date
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row?.statement_of_account_history?.map((historyRow) => (
                        <TableRow key={historyRow.date}>
                          <TableCell sx={tdata} component="th" scope="row">
                            {Number(
                              historyRow?.debit == null ? 0 : historyRow?.debit
                            )?.toLocaleString()}
                          </TableCell>
                          <TableCell sx={tdata}>
                            {Number(
                              historyRow.credit == null ? 0 : historyRow.credit
                            )?.toLocaleString()}
                          </TableCell>
                          <TableCell sx={tdata} align="left">
                            {Number(
                              historyRow?.credit - historyRow?.debit
                            )?.toLocaleString()}
                          </TableCell>
                          <TableCell sx={tdata} align="left">
                            {date(historyRow?.created_at)}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell sx={tdata} colSpan={12}>
                          <span className="text-black">Total Debit</span>
                          <span className="ms-2">
                            {Number(row?.debit)?.toLocaleString()}
                          </span>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={tdata} colSpan={12}>
                          <span className="text-black">Total Credit</span>
                          <span className="ms-2">
                            {Number(row?.credit)?.toLocaleString()}
                          </span>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={tdata} colSpan={12}>
                          <span className="text-black">Closing Balance</span>
                          <span className="ms-2">
                            {Number(row?.debit - row?.credit)?.toLocaleString()}
                          </span>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={tdata} colSpan={12}>
                          <span className="text-black">Currency</span>
                          <span className="ms-2">{row?.currency_name}</span>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </>
              ) : (
                <NoRecord />
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0, 3.99, 9, 9),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3, 4.99, 9, 9),
  createData("Eclair", 262, 16.0, 24, 6.0, 3.79, 9, 9),
  createData("Cupcake", 305, 3.7, 67, 4.3, 2.5, 9, 9),
  createData("Gingerbread", 356, 16.0, 49, 3.9, 1.5, 9, 9),
];

export default function CollapsibleTable(props) {
  const { row } = props;

  const rowss = props?.trackingSheet !== undefined ? props.trackingSheet : [];

  const header = {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "10.5px",
    lineHeight: "12px",
    color: "#353945",
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [filteredPage, setFilteredPage] = React.useState(0);
  const [filteredRowsPerPage, setFilteredRowsPerPage] = React.useState(5);
  const [searchClicked, setSearchClicked] = React.useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleFilteredChangePage = (event, newPages) => {
    setFilteredPage(newPages);
  };

  const handleFilteredChangeRowsPerPage = (event) => {
    setFilteredRowsPerPage(parseInt(event.target.value, 10));
    setFilteredPage(0);
  };
  const [project_id, setProject] = React.useState("");
  const [currency_id, setCurrency] = React.useState("");
  const [pi, setPI] = React.useState("");

  const { data: projects } = useGetProjectsQuery();
  const { data: currencies } = useGetCurrenciesQuery();
  const [filter, setFilter] = React.useState(false);
  const [filteredData, setFilteredData] = React.useState([]);
  const [endDate, setEndDate] = React.useState("");
  const [startDate, setStartDate] = React.useState("");

  const HandleFilter = () => {
    setFilter(!filter);
    setProject("");
  };

  const handleDateFilter = (startDate, endDate) => {
    axios
      .get(
        `${API_BASE_URL}/scm/payment/soa_filter?start=${startDate}&end=${endDate}&id=${props?.supplier_id}`
      )
      .then((response) => {

        setFilteredData(response?.data?.data);
        setSearchClicked(true);
      })
      .catch((error) => {
        console.error("Error fetching filtered data:", error);
      });
  };

  let form = {
    id: props?.supplier_id,
    project_id,
    currency_id,
  };

  const [report, src] = useGetSoaReportMutation();
  function HandleDownload(e) {
    e.preventDefault();
    report(form)
      .unwrap()
      .then((response) => {

        props.setMessages("Downloaded successfully");
        props.modal(false);

      })
      .catch((error) => {
  
      });
  }

  return (
    <TableContainer sx={{ overflow: "inherit" }}>
      <div className="d-flex gap-3 align-items-center">
        {filter ? (
          <FilterAltIcon
            onClick={() => HandleFilter()}
            style={{ fontSize: "32px", color: "#064b4f" }}
          />
        ) : (
          <FilterAltOffIcon
            onClick={() => HandleFilter()}
            style={{ fontSize: "32px", color: "#064b4f" }}
          />
        )}
        <Tooltip title="Download SoA">
          <a
            type="link"
            href={`${API_BASE_URL}/scm/soasupplierlevel/${props?.supplier_id}/${
              project_id == "" ? 0 : project_id
            }/${currency_id == "" ? 0 : currency_id}`}
          >
            <IconButton
              variant="text"
              size="small"
              disabled={
                props?.trackingSheet[0]?.statement_of_account_history?.length !=
                0
                  ? false
                  : true
              }
              // onClick={HandleDownload}
            >
              <DownloadIcon style={{ fontSize: "32px", color: "#064b4f" }} />
            </IconButton>
          </a>
        </Tooltip>

        {filter ? (
          <div className="d-flex align-items-center gap-3">
            <select
              className="report-filter-selector border"
              onChange={(e) => setProject(e.target.value)}
            >
              <option value="" selected>
                Project
              </option>
              {projects?.data?.map((items) => (
                <option value={items?.id}>{items?.project_name}</option>
              ))}
            </select>

            <select
              className="report-filter-selector border"
              onChange={(e) => setCurrency(e.target.value)}
            >
              <option value="" selected>
                Currency
              </option>
              {currencies?.data?.map((items) => (
                <option value={items?.id}>{items?.name}</option>
              ))}
            </select>
            <input
              type="date"
              className="report-filter-selector border"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <span> - </span>
            <input
              type="date"
              className="report-filter-selector border"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
            <div className="d-flex gap-2">
              <button
                className="download-btn"
                onClick={() => handleDateFilter(startDate, endDate)}
              >
                Search
              </button>
              {searchClicked && (
                <Link
                  to={`${API_BASE_URL}/scm/payment/soa_filter_view?start=${startDate}&end=${endDate}&id=${props?.supplier_id}`}
                  target="_blank"
                >
                  <IconButton variant="text" size="small">
                    <DownloadIcon
                      style={{ fontSize: "32px", color: "#064b4f" }}
                    />
                  </IconButton>
                </Link>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            {rowss?.supplier_name === "IE Netwoks LLC" ? (
              <TableCell sx={header}>DESCRIPTION </TableCell>
            ) : (
              <TableCell sx={header}>PO NUMBER </TableCell>
            )}

            <TableCell sx={header} align="left">
              PI Number
            </TableCell>
            <TableCell sx={header} align="left">
              PO DATE
            </TableCell>
            <TableCell sx={header} align="left">
              DEBIT
            </TableCell>
            <TableCell sx={header} align="left">
              CREDIT
            </TableCell>
            <TableCell sx={header} align="left">
              CLOSING BALANCE
            </TableCell>
            <TableCell sx={header} align="left">
              CURRENCY
            </TableCell>
            <TableCell sx={header} align="left">
              PAYMENT DUE DATE
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {startDate && endDate
            ? filteredData
                ?.slice(
                  filteredPage * filteredRowsPerPage,
                  filteredPage * filteredRowsPerPage + filteredRowsPerPage
                )
                .map((row) => <Row key={row?.name} row={row} />)
            : rowss
                ?.filter((items) => {
                  if (project_id == "") {
                    return items;
                  } else if (items?.project_id == project_id) {
                    return items;
                  }
                })

                ?.filter((items) => {
                  if (currency_id === "") {
                    return rows;
                  } else if (items?.currency_id == currency_id) {
                    return rows;
                  }
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => <Row key={row.name} row={row} />)}
        </TableBody>
      </Table>
      {!startDate && !endDate && (
        <Pagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rowss?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      {startDate && endDate && (
        <Pagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredData?.length}
          rowsPerPage={filteredRowsPerPage}
          page={filteredPage}
          onPageChange={handleFilteredChangePage}
          onRowsPerPageChange={handleFilteredChangeRowsPerPage}
        />
      )}
    </TableContainer>
  );
}
