import React from "react";
import RmaBatchCard from "../../../components/Logistics/RMA/RmaBatchCard";
import NavBar from "../../../Layout/NavBar";
import { NavLink } from "react-router-dom";
import LogisticsBreadCramp from "../../../components/Logistics/LogisticsRmaBreadCramp";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useGetRmaQuery } from "../../../features/SCMApi";
import { useParams } from "react-router-dom";
import usePagination from "../../../components/Pagination/Pagination";
import { Pagination } from "@mui/material";

export default function RMAIndex() {
  const { ProjectId } = useParams();
  const { data: rmaItemList } = useGetRmaQuery(ProjectId);

  let [page, setPage] = React.useState(1);
  const PER_PAGE = 9;
  const count = Math.ceil(rmaItemList?.data?.length / PER_PAGE);
  const _DATA = usePagination(rmaItemList?.data, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <>
      <NavBar />
      <LogisticsBreadCramp />
      <div className="page">
        <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
          <Grid container spacing={{ xs: 2, md: 2 }}>
            {rmaItemList?.data !== {} ? (
              _DATA.currentData()?.map((rmaItems) => (
                <Grid
                  item
                  marginRight={{ xs: "16px", sm: "0px", md: "0px" }}
                  marginLeft={{ xs: "16px", sm: "0px", md: "0px" }}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  xl={4}
                  xxl={4}
                >
                  <NavLink
                    to={`details/${rmaItems?.id}`}
                    state={{ data: rmaItems?.id }}
                  >
                    <RmaBatchCard data={rmaItems} />
                  </NavLink>
                </Grid>
              ))
            ) : (
              <></>
            )}
          </Grid>
          {count > 1 ? (
            <Pagination
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                margin: "5px",
              }}
            />
          ) : (
            ""
          )}
        </Box>
      </div>
    </>
  );
}
