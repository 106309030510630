import React from "react";

import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import ForexSideBar from "../../../components/Payment/ForexApproval/PaymentSideBar";
import { useState } from "react";
import PaymentCollectionModal from "./PaymentCollectionModal";
import {
  useGetPaymentCollectionsOffshoreQuery,
  useGetPaymentCollectionsOnshoreQuery,
  useGetPaymentCollectionsQuery,
  useGetProjectQuery,
} from "../../../features/SCMApi";
import NoRecord from "../../common/NoRecord";
import { useParams } from "react-router-dom";
import UpdatePaymentCollectionModal from "./UpdatePaymentCollections";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Pagination, Tooltip } from "@mui/material";
import usePagination from "../../Pagination/Pagination";
import AllprojectPaymentCollectionReport from "./DownloadReports/AllprojectPaymentCollectionReport";
import PaymentTermModal from "./PaymentTermModal";
import AddIcon from "@mui/icons-material/Add";
import { RiSecurePaymentFill } from "react-icons/ri";
import PaymentTerms from "./PaymentTerms";
import { AiOutlineFileAdd } from "react-icons/ai";
import VarianceCollection from "./VarianceCollection";
import OnShore from "./OnShore";
import OffShore from "./OffShore";
import PaymentCollectionModalOffshore from "./PaymentCollectionOffshore";
export default function PaymentCollection({ type }) {
  const params = useParams();
  const { data: paymentCollection } = useGetPaymentCollectionsOnshoreQuery(
    params?.id
  );
  const { data: forexPaymentCollection } =
    useGetPaymentCollectionsOffshoreQuery(params?.id);
  let count = 0;
  const [add, setAdd] = useState(false);
  const [addTerms, setAddTerms] = useState(false);
  const [viewTerms, setViewTerms] = useState(false);
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState();
 
  function HandleAdd() {
    setAdd(true);
  }
  function HandleAddTerms() {
    setAddTerms(true);
  }

  const { data: project } = useGetProjectQuery(params?.id);
  const [milestone, setMilestone] = useState("");
  const [status, setStatus] = useState("");

  let milestoneamount = project?.data?.milestone_amount;
  let milestonepush = [];
  for (let i = 1; i <= milestoneamount; i++) {
    milestonepush.push({
      id: i,
      name: `milestone ${i}`,
    });
  }
  const filteredData = (milestone, status) => {
    return paymentCollection?.data
      ?.filter((items) => {
        if (milestone === "") {
          return items;
        } else if (items?.milestone == milestone) {
          return items;
        }
      })
      ?.filter((items, index) => {
        if (status === "") {
          return items;
        } else if (
          status == "Favorable" &&
          items?.planned_amount - items?.actual_amount == 0
        ) {
          return items;
        } else if (
          status == "Unfavorable" &&
          items?.planned_amount > items?.actual_amount
        ) {
          return items;
        }
      })
      .map((items) => items);
  };
  let PFO = "";
  let PM = "";

  PFO = project?.data?.users?.map((items) =>
    items?.pivot?.role_id == "0e324e94-6f2c-415c-9a46-a359a96fea7f"
      ? `${items?.name},`
      : ""
  );
  PM = project?.data?.users?.map((items) =>
    items?.pivot?.role_id == "96f8c153-79d5-40d1-b0b7-7fd70921f02e"
      ? `${items?.name},`
      : ""
  );
  const [filter, setFilter] = useState(false);
  function HandleFilter() {
    setFilter(!filter);
    setMilestone("");
    setStatus("");
  }
  let [page, setPage] = useState(1);
  const PER_PAGE = 5;

  const p_count = Math.ceil(filteredData(milestone, status)?.length / PER_PAGE);
  const _DATA = usePagination(filteredData(milestone, status), PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  const f_p_count = Math.ceil(forexPaymentCollection?.data?.length / PER_PAGE);
  const _DATA_f = usePagination(forexPaymentCollection?.data, PER_PAGE);
  const handleChangeForex = (e, p) => {
    setPage(p);
    _DATA_f.jump(p);
  };
  

  const [variance, setVariance] = useState(null);
  const [varianceModal, setVarianceModal] = useState(false);
  function HandleVariance(e, items) {
    setVariance(items);
    setVarianceModal(true);
  }
  const [onshore, setOnShore] = useState(type);


  let sum = 0;
  onshore
    ? filteredData(milestone, status)?.map(
        (items) => (sum += Number(items?.planned_amount))
      )
    : forexPaymentCollection?.data?.map(
        (items) => (sum += Number(items?.ci_value))
      );

  let contractPayment = 0;
  onshore
    ? (contractPayment = project?.data?.contract_payment)
    : (contractPayment = project?.data?.forex_contract_payment);
  const onshoreAdvancePayment = paymentCollection?.data?.filter(
    (items) => items?.isAdvance == true
  );
  const totalPlannedAmount = onshoreAdvancePayment?.reduce((total, item) => {
    return total + item.actual_amount;
  }, 0);

  return (
    <ForexSideBar>
      <div className="payment-collection-container">
        {onshore ? (
          <div className="d-flex gap-4 p-4 align-items-center">
            {project?.data?.contract_payment ? (
              <>
                <div className="d-grid">
                  <Tooltip title={"Create cash collection term"}>
                    <div
                      className="create-project-btn mt-3"
                      onClick={HandleAddTerms}
                    >
                      <AddIcon />
                      Payment Term
                    </div>
                  </Tooltip>
                  <Tooltip
                    title={
                      sum > contractPayment
                        ? "can't Create payment collection"
                        : "Create payment collection"
                    }
                  >
                    <div
                      className="create-project-btn mt-3"
                      onClick={sum > contractPayment ? null : HandleAdd}
                    >
                      <AddIcon />
                      Collection
                    </div>
                  </Tooltip>
                </div>
                <Tooltip title={"Payment Terms"}>
                  <button
                    className="add-client-representative"
                    onClick={() => setViewTerms(true)}
                  >
                    <RiSecurePaymentFill style={{ color: "white" }} />
                  </button>
                </Tooltip>
                {filteredData(milestone, status)?.length != 0 ? (
                  <AllprojectPaymentCollectionReport
                    data={_DATA.currentData()}
                  />
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            <div className="d-grid">
              <div className="d-flex gap-4">
                <div className="h5-pri">{project?.data?.project_name}</div>
                <div className="grid gap-1">
                  <div className="h5-pri">
                    {project?.data?.contract_payment
                      ? Number(
                          project?.data?.contract_payment
                        )?.toLocaleString()
                      : "0"}{" "}
                  </div>
                  <div className="project-label">Contract Payment</div>
                </div>
              </div>

              <div className="d-flex gap-4">
                <div className="d-grid">
                  <div className="project-label">Project Manager</div>
                  <div className="lc-value">
                    {PM == "" ? "Not assigned" : PM}
                  </div>
                </div>
                <div className="d-grid">
                  <div className="project-label">Project Finance Officer</div>
                  <div className="lc-value">
                    {PFO == "" ? "Not assigned" : PFO}
                  </div>
                </div>
                <div className="d-flex gap-4">
                  <div className="d-grid">
                    <div className="lc-value">
                      Total Actual Amount for Advanced Payment
                    </div>
                    <div className="lc-value">
                      {totalPlannedAmount
                        ? totalPlannedAmount.toLocaleString()
                        : "0"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex gap-4 p-4 align-items-center">
            {project?.data?.forex_contract_payment ? (
              <>
                <div className="d-grid">
                  <Tooltip title={"Create cash collection term"}>
                    <div
                      className="create-project-btn mt-3"
                      onClick={HandleAddTerms}
                    >
                      <AddIcon />
                      Payment Term
                    </div>
                  </Tooltip>
                  <Tooltip
                    title={
                      sum > contractPayment
                        ? "can't Create payment collection"
                        : "Create payment collection"
                    }
                  >
                    <div
                      className="create-project-btn mt-3"
                      onClick={sum > contractPayment ? null : HandleAdd}
                    >
                      <AddIcon />
                      Collection
                    </div>
                  </Tooltip>
                </div>
                <Tooltip title={"Payment Terms"}>
                  <button
                    className="add-client-representative"
                    onClick={() => setViewTerms(true)}
                  >
                    <RiSecurePaymentFill style={{ color: "white" }} />
                  </button>
                </Tooltip>
                {filteredData(milestone, status)?.length != 0 ? (
                  <AllprojectPaymentCollectionReport
                    data={_DATA.currentData()}
                  />
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            <div className="d-grid">
              <div className="d-flex gap-4">
                <div className="h5-pri">{project?.data?.project_name}</div>
                <div className="grid gap-1">
                  {onshore ? (
                    <div className="h5-pri">
                      {project?.data?.contract_payment
                        ? Number(
                            project?.data?.contract_payment
                          )?.toLocaleString()
                        : "0"}
                    </div>
                  ) : (
                    <div className="h5-pri">
                      {project?.data?.forex_contract_payment
                        ? Number(
                            project?.data?.forex_contract_payment
                          )?.toLocaleString()
                        : "0"}
                    </div>
                  )}
                  <div className="project-label">Contract Payment</div>
                </div>
              </div>

              <div className="d-flex gap-4">
                <div className="d-grid">
                  <div className="project-label">Project Manager</div>
                  <div className="lc-value">
                    {PM == "" ? "Not assigned" : PM}
                  </div>
                </div>
                <div className="d-grid">
                  <div className="project-label">Project Finance Officer</div>
                  <div className="lc-value">
                    {PFO == "" ? "Not assigned" : PFO}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {paymentCollection?.data?.length > 1 ? (
          <div className="d-flex gap-4 ms-5 align-items-start">
            {filter ? (
              <Tooltip title="Filter On">
                <FilterAltIcon
                  onClick={HandleFilter}
                  style={{ fontSize: "32px", color: "#064b4f" }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Filter Off">
                <FilterAltOffIcon
                  onClick={HandleFilter}
                  style={{ fontSize: "32px", color: "#064b4f" }}
                />
              </Tooltip>
            )}
            {filter ? (
              <>
                <select
                  className="report-filter-selector"
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="">Status</option>
                  <option value="Favorable">Favorable</option>
                  <option value="Unfavorable"> Unfavorable</option>
                </select>
                <select
                  className="report-filter-selector"
                  onChange={(e) => setMilestone(e.target.value)}
                >
                  <option value="" selected>
                    Milestone
                  </option>
                  {milestonepush?.map((items) => (
                    <option value={items?.id}>{items?.name}</option>
                  ))}
                </select>
              </>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        {/* <div
          className="tool-header-container d-flex gap-3 justify-content-center"
          onChange={CheckBoxHandelonShore}
        >
          <input
            checked={onshore}
            id="default-radio-1"
            type="radio"
            value="Tool"
            name="default-radio"
            className="cursor-pointer"
          />
          <label for="default-radio-1" className="prg">
            Onshore
          </label>

          <input
            id="default-radio-2"
            type="radio"
            value="Inventory"
            name="default-radio"
            className="cursor-pointer"
          />
          <label for="default-radio-2" className="prg">
            Offshore
          </label>
        </div> */}
        {type === true ? (
          <OnShore
            _DATA={_DATA}
            p_count={p_count}
            page={page}
            handleChange={handleChange}
          />
        ) : (
          <OffShore
            _DATA={_DATA_f}
            p_count={f_p_count}
            page={page}
            handleChange={handleChangeForex}
          />
        )}
      </div>

      {add ? (
        <>
          {onshore == true ? (
            <PaymentCollectionModal modal={setAdd} />
          ) : (
            <PaymentCollectionModalOffshore modal={setAdd} />
          )}
        </>
      ) : (
        ""
      )}
      {edit ? <UpdatePaymentCollectionModal modal={setEdit} data={data} /> : ""}
      {addTerms ? <PaymentTermModal modal={setAddTerms} /> : ""}
      {viewTerms ? <PaymentTerms modal={setViewTerms} /> : ""}
      {varianceModal ? (
        <VarianceCollection data={variance} modal={setVarianceModal} />
      ) : (
        ""
      )}
    </ForexSideBar>
  );
}
