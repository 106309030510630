import Settingsidebar from "../../pages/Settings/SettingIndex";
import "./Settings.css";
import "./paymentSettings.css";
import AddIcon from "@mui/icons-material/Add";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import PoDragFeature from "./features/PoDragFeature";
import DocumentDrag from "./features/DocumentDrag";
import PoPriceTab from "./features/PoPriceTab";
import { useState } from "react";
import AddDocumentModal from "./SettingModal/AddDocumentModal";
import AddPoModal from "./SettingModal/AddPoModal";
import AddPaModal from "./SettingModal/AddPaModal";
import { NavLink } from "react-router-dom";
import { useGetApprovalModuleQuery } from "../../features/SCMApi";

export default function ApprovalSetting() {
  const [showPa, setShowPa] = useState(false);
  const [showPo, setShowPo] = useState(false);
  const [showDocument, setShowDocument] = useState(false);
  const { data: approval_module } = useGetApprovalModuleQuery();
  

  return (
    <Settingsidebar name={"Approval"}>
      <div className="supplier-menu-container">
        {approval_module?.data?.map((items) => (
          <NavLink
            to={`/settings/approval/${items?.id}`}
            className="payment-menu-card"
          >
            <div className="text-center"></div>
            <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
              {items?.module_name}
            </span>
          </NavLink>
        ))}
      </div>
    </Settingsidebar>
  );
}
