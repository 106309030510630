import React from "react";
import ForexSideBar from "../../../components/Payment/ForexApproval/PaymentSideBar";
import PaymentTrackingNav from "../../../components/Payment/PaymentTracking/PaymentTrackingNav";

const PaymentTracking = () => {
  return (
    <ForexSideBar>
      <div className="forex-page">
        <PaymentTrackingNav />
      </div>
    </ForexSideBar>
  );
};

export default PaymentTracking;
