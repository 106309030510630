import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const GapAnalysis = styled(Paper)(({ theme }) => ({
  elevation: "0px",
  height: "300px",
  width: "362px",
  // marginTop: "20px",
  [theme.breakpoints.between("md", "lg")]: {
    marginLeft: "35px",
    marginRight: "-45px",
  },
  [theme.breakpoints.between("xs", "sm", "md")]: {
    // margin: "auto",
    marginTop: "20px",
  },
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  border: "1px solid #AEB8C9",
  borderRadius: "6px",
}));

const GapAnalysisContainer = (props) => {
  return (
    <>
      <GapAnalysis elevation={0}>
        <div className="gap-analysis-mobile-view-detail-card">
          <ul className="paymentTermAndConditionsLabel">
            <li>
              <span className="mobile-view-label">Project</span>
            </li>
            <li>
              <span className="mobile-view-label">Milestone</span>
            </li>
            <li>
              <span className="mobile-view-label">Suppler</span>
            </li>
            <li>
              <span className="mobile-view-label">Need</span>
            </li>
            <li>
              <span className="mobile-view-label">Balance</span>
            </li>
            <li>
              <span className="mobile-view-label">Receivable</span>
            </li>
            <li>
              <span className="mobile-view-label">Gap</span>
            </li>
          </ul>

          <ul className="paymentTermAndConditionsValue">
            <li>
              <span className="paymentTcValue">
                {props?.gapAnalysisData?.project_name !== null
                  ? props?.gapAnalysisData?.project_name?.length > 18
                    ? props?.gapAnalysisData?.project_name?.slice(0, 18) + "..."
                    : props?.gapAnalysisData?.project_name
                  : "Not available"}
              </span>
            </li>
            <li>
              <span className="paymentTcValue">
                {props?.gapAnalysisData?.milestone_name !== null
                  ? props?.gapAnalysisData?.milestone_name?.length > 18
                    ? props?.gapAnalysisData?.milestone_name?.slice(0, 18) +
                      "..."
                    : props?.gapAnalysisData?.milestone_name
                  : "Not available"}
              </span>
            </li>
            <li>
              <span className="paymentTcValue">
                {props?.gapAnalysisData?.distributor_name !== null
                  ? props?.gapAnalysisData?.distributor_name?.length > 18
                    ? props?.gapAnalysisData?.distributor_name?.slice(0, 18) +
                      "..."
                    : props?.gapAnalysisData?.distributor_name
                  : "Not available"}
              </span>
            </li>
            <li>
              <span className="paymentTcValue">
                {props?.gapAnalysisData?.amount !== null
                  ? props?.gapAnalysisData?.amount
                  : "Not available"}
              </span>
            </li>
            <li>
              <span className="paymentTcValue">
                {props?.gapAnalysisData?.balance
                  ? props?.gapAnalysisData?.balance
                  : "Not available"}
              </span>
            </li>
            <li>
              <span className="paymentTcValue">
                {props?.gapAnalysisData?.receivable !== null
                  ? props?.gapAnalysisData?.receivable
                  : "Not available"}
              </span>
            </li>
            <li>
              <span
                className="paymentTcValue"
                style={{
                  color: (
                    Math.round(
                      (props?.gapAnalysisData?.receivable +
                        props?.gapAnalysisData?.balance -
                        props?.gapAnalysisData?.amount +
                        Number.EPSILON) *
                        1000
                    ) / 1000
                  )
                    .toString()
                    .includes("-")
                    ? "#FF5252"
                    : Number(
                        Math.round(
                          (props?.gapAnalysisData?.receivable +
                            props?.gapAnalysisData?.balance -
                            props?.gapAnalysisData?.amount +
                            Number.EPSILON) *
                            1000
                        ) / 1000
                      ) === 0
                    ? "#FABB06"
                    : "#00DD9B",
                }}
              >
                {Math.round(
                  (props?.gapAnalysisData?.receivable +
                    props?.gapAnalysisData?.balance -
                    props?.gapAnalysisData?.amount +
                    Number.EPSILON) *
                    1000
                ) / 1000}
              </span>
            </li>
          </ul>
        </div>
        <div className="gap-analysis-mobile-view-description-container">
          <span className="gap-analysis-mobile-view-description-title">
            Description
          </span>
          <span className="paymentTcValue">
            {props?.gapAnalysisData?.description !== null
              ? props?.gapAnalysisData?.description
              : "Not available"}
          </span>
        </div>
      </GapAnalysis>
    </>
  );
};

export default GapAnalysisContainer;
