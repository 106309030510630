import React, { useState } from "react";
import attachmentIcon from "../../assets/SVG/attach.svg";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import { API_BASE_URL, DOC_BASE_URL } from "../../api/endPoint";
import axios from "axios";
import { Tooltip } from "@mui/material";
export default function DocumentCollectionModal(props) {
  function HandleClose() {
    props.ToggleModal(false);
  }

  
  // const hasCommercialInvoice = props?.data.find(
  //   (item) => item?.commercial_invoice != ""
  // );
  // const hasPackingList = props?.data.find((item) => item?.packing_list != "");
  // const hasCertificateOrigin = props?.data.find(
  //   (item) => item?.certificate_origin != ""
  // );
  // const hasBillLoading = props?.data.find((item) => item?.bill_Loading != "");
  const [commercial_invoice, setCommercial] = useState(
    props?.data?.commercial_invoice == undefined ? "" : ""
  );
  const [packing_list, setPL] = useState(
    props?.data?.packing_list == undefined ? "" : ""
  );
  const [certificate_of_origin, setCetificateOrigin] = useState(
    props?.data?.certificate_origin == undefined ? "" : ""
  );
  const [loading_bill, setBillLoading] = useState(
    props?.data?.bill_Loading == undefined ? "" : ""
  );
  const { batchId } = useParams();
  function handleSubmit(e) {
    e.preventDefault();
    const form = {
      batch_id: batchId,
      commercial_invoice,
      packing_list,
      certificate_of_origin,
      loading_bill,
    };
    
    // submitBank({
    //   batch_id: batchId,
    //   cover_letter,
    //   commercial_invoice,
    //   packing_list,
    //   certificate_of_origin,
    //   loading_bill,
    //   beneficiary_certificate,
    //   freight_invoice,
    //   vessel_certificate,
    // })
    //   .unwrap()
    //   .then((response) => {
    
    //     // window.location.reload();
    //   })
    //   .then((error) => {
    
    //   });
   
    axios
      .post(`${API_BASE_URL}/scm/logistics/collected-documents`, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        
        props.ToggleModal(false);
        window.location.reload(true);
      })
      .catch(function (error) {
        
      });
  }

  return (
    <div id="myModal" className="mini-modal" onClick={HandleClose}>
      <div className="mini-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="create-project-modal-title d-flex justify-content-between align-items-baseline p-2">
          <div>Document Collection</div>
          <CloseIcon onClick={HandleClose} />
        </div>

        <form
          onSubmit={handleSubmit}
          className="row justify-content-center gap-2"
        >
          <div className="d-flex align-items-baseline  gap-2 justify-content-center ">
            <div className="input-label col-3 px-0">Commercial Invoice</div>
            <div
              className="file-chooser cl-9 px-0"
              style={{ backgroundImage: `url(${attachmentIcon})` }}
            >
              <input
                id="chooser"
                type={"file"}
                class="file-input"
                onChange={(e) => setCommercial(e.target.files[0])}
              />
              <label
                htmlFor="chooser"
                style={{
                  position: "relative",
                  top: "-28px",
                  left: "5px",
                  width: "6rem",
                  // zIndex: "-1",
                }}
              >
                {commercial_invoice
                  ? commercial_invoice?.name.slice(0, 10) + "...."
                  : "Choose File"}
              </label>
            </div>
            {props?.data[0]?.commercial_invoice != undefined ? (
              <Tooltip title="Download">
                <a
                  href={`${DOC_BASE_URL}scm/document/download?file_path=${props?.data[0]?.commercial_invoice}`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#186569"
                    class="bi bi-download"
                    viewBox="0 0 16 16"
                  >
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                  </svg>
                </a>
              </Tooltip>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex align-items-baseline  gap-2 justify-content-center ">
            <div className="input-label col-3  px-0 justify-content-end">
              Packing List
            </div>
            <div
              className="file-chooser cl-9 px-0"
              style={{ backgroundImage: `url(${attachmentIcon})` }}
            >
              <input
                id="chooser"
                type={"file"}
                class="file-input"
                onChange={(e) => setPL(e.target.files[0])}
              />
              <label
                htmlFor="chooser"
                style={{
                  position: "relative",
                  top: "-28px",
                  left: "5px",
                  width: "6rem",
                  // zIndex: "-1",
                }}
              >
                {packing_list
                  ? packing_list?.name.slice(0, 10) + "...."
                  : "Choose File"}
              </label>
            </div>
            {props?.data[0]?.packing_list != undefined ? (
              <Tooltip title="Download">
                <a
                  href={`${DOC_BASE_URL}scm/document/download?file_path=${props?.data[0]?.packing_list}`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#186569"
                    class="bi bi-download"
                    viewBox="0 0 16 16"
                  >
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                  </svg>
                </a>
              </Tooltip>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex align-items-baseline  gap-2 justify-content-center ">
            <div className="input-label col-3 px-0">Certificate of Origin</div>
            <div
              className="file-chooser cl-9 px-0"
              style={{ backgroundImage: `url(${attachmentIcon})` }}
            >
              <input
                id="chooser"
                type={"file"}
                class="file-input"
                onChange={(e) => setCetificateOrigin(e.target.files[0])}
              />
              <label
                htmlFor="chooser"
                style={{
                  position: "relative",
                  top: "-28px",
                  left: "5px",
                  width: "6rem",
                  // zIndex: "-1",
                }}
              >
                {certificate_of_origin
                  ? certificate_of_origin?.name.slice(0, 10) + "...."
                  : "Choose File"}
              </label>
            </div>
            {props?.data[0]?.certificate_of_origin != undefined ? (
              <Tooltip title="Download">
                <a
                  href={`${DOC_BASE_URL}scm/document/download?file_path=${props?.data[0]?.certificate_of_origin}`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#186569"
                    class="bi bi-download"
                    viewBox="0 0 16 16"
                  >
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                  </svg>
                </a>
              </Tooltip>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex align-items-baseline  gap-2 justify-content-center ">
            <div className="input-label col-3 px-0">Bill of Loading / AWB</div>
            <div
              className="file-chooser cl-9 px-0"
              style={{ backgroundImage: `url(${attachmentIcon})` }}
            >
              <input
                id="chooser"
                type={"file"}
                class="file-input"
                onChange={(e) => setBillLoading(e.target.files[0])}
              />
              <label
                htmlFor="chooser"
                style={{
                  position: "relative",
                  top: "-28px",
                  left: "5px",
                  width: "6rem",
                  // zIndex: "-1",
                }}
              >
                {loading_bill
                  ? loading_bill?.name.slice(0, 10) + "...."
                  : "Choose File"}
              </label>
            </div>
            {props?.data[0]?.loading_bill != undefined ? (
              <Tooltip title="Download">
                <a
                  href={`${DOC_BASE_URL}scm/document/download?file_path=${props?.data[0]?.loading_bill}`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#186569"
                    class="bi bi-download"
                    viewBox="0 0 16 16"
                  >
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                  </svg>
                </a>
              </Tooltip>
            ) : (
              ""
            )}
          </div>
          <div className="row mt-4 align-items-center justify-content-center gap-3">
            <button
              type="cancel"
              className="col-6 form-cancel"
              onClick={HandleClose}
            >
              {" "}
              Cancel
            </button>
            <button type="submit" className="col-6 form-save">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
