import React, { useEffect, useState } from "react";
import "../Recievable.css";
import axios from "axios";
import { API_BASE_URL } from "../../../../api/endPoint";
import Pagination from "@mui/material/Pagination";

// Import statements ...

function ReceivableSummaryTable() {
  const [summaryData, setSummaryData] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [currentPage, setCurrentPage] = useState({});

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/scm/summery`)
      .then((response) => {
        setSummaryData(response.data.data);

      })
      .catch((error) => {
        console.error("response from back end fro summaryData err", error);
      });

    axios
      .get(`${API_BASE_URL}/scm/currencies`)
      .then((response) => {
        setCurrency(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching currencies:", error);
      });
  }, []);

  const itemsPerPage = 3; // Adjust the number of items per page as needed

  const handlePageChange = (currencyCode, page) => {
    setCurrentPage({ ...currentPage, [currencyCode]: page });
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ flex: "2%" }}></div>
      <div
        style={{
          flex: "96%",
          overflow: "auto",
        }}
      >
        <table
          style={{
            backgroundColor: "rgba(234, 236, 240, 1)",
          }}
          className="prg recivableTable"
        >
          <thead style={{ backgroundColor: "rgba(86, 184, 183, 0.2)" }}>
            <tr>
              <th className="recivableTh"></th>
              <th className="recivableTh">NO</th>
              <th className="recivableTh">Project</th>
              <th className="recivableTh">Current & upcoming</th>
              <th className="recivableTh">1-30 days overdue</th>
              <th className="recivableTh">31-60 days overdue</th>
              <th className="recivableTh">61-90 days overdue</th>
              <th className="recivableTh">91-120 days overdue</th>
              <th className="recivableTh">121-150 days overdue</th>
              <th className="recivableTh">Above 150 days overdue</th>
              <th className="recivableTh">Total</th>
            </tr>
          </thead>

          {currency.map((currencyItem, currencyIndex) => {
            let totalsum = 0;

            // Filter summaryData for the current currency
            const filteredSummaryData = summaryData.filter(
              (item) => item.Currency === currencyItem.code
            );

            // Check if there are any items to display for the current currency
            if (filteredSummaryData.length === 0) {
              return null; // Skip rendering this currency
            }

            const currentPageNumber = currentPage[currencyItem.code] || 1;
            const startIndex = (currentPageNumber - 1) * itemsPerPage;
            const endIndex = startIndex + itemsPerPage;
            const paginatedData = filteredSummaryData.slice(
              startIndex,
              endIndex
            );

            // Calculate the running total for all items
            filteredSummaryData.forEach((item) => {
              totalsum += item.Total;
            });

            return (
              <tbody className="recivabletbody" key={currencyIndex} style={{}}>
                <tr style={{ backgroundColor: "rgba(0, 162, 253, 0.7)" }}>
                  <th colSpan={1}>{currencyItem.code}</th>
                  <th colSpan={10}></th>
                </tr>

                {paginatedData.map((item, index) => {
                  const Counters = startIndex + index + 1; // Calculate the row number

                  return (
                    <tr className="recivableTr" key={index}>
                      <td className="recivableTd"></td>
                      <td className="recivableTd">{Counters}</td>
                      <td className="recivableTd">{item.project}</td>
                      <td className="recivableTd">
                        {item.currentAndUpcomingAmmount !== null
                          ? item.currentAndUpcomingAmmount.toLocaleString()
                          : ""}
                      </td>
                      <td className="recivableTd">
                        {item.DaysOverDue1_30 !== null
                          ? item.DaysOverDue1_30.toLocaleString()
                          : ""}
                      </td>
                      <td className="recivableTd">
                        {item.DaysOverDue31_60 !== null
                          ? item.DaysOverDue31_60.toLocaleString()
                          : ""}
                      </td>
                      <td className="recivableTd">
                        {item.DaysOverDue61_90 !== null
                          ? item.DaysOverDue61_90.toLocaleString()
                          : ""}
                      </td>
                      <td className="recivableTd">
                        {item.DaysOverDue91_120 !== null
                          ? item.DaysOverDue91_120.toLocaleString()
                          : ""}
                      </td>
                      <td className="recivableTd">
                        {item.DaysOverDue121_150 !== null
                          ? item.DaysOverDue121_150.toLocaleString()
                          : ""}
                      </td>
                      <td className="recivableTd">
                        {item.DaysOverDue151Above !== null
                          ? item.DaysOverDue151Above.toLocaleString()
                          : ""}
                      </td>
                      <td className="recivableTd">
                        {item.Total !== null ? item.Total.toLocaleString() : ""}
                      </td>
                    </tr>
                  );
                })}

                {/* Total row for the current currency */}

                <tr
                  className="recivableTr"
                  style={{ backgroundColor: "rgba(86, 184, 183, 0.2)" }}
                >
                  <td className="recivableTd" colSpan={2}>
                    Total:
                  </td>
                  <td className="recivableTd" colSpan={8}></td>
                  <td className="recivableTd">
                    {totalsum !== null
                      ? totalsum.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : ""}
                  </td>
                </tr>

                {/* Pagination for the current currency */}
                <tr className="recivableTr" style={{ background: "#fff" }}>
                  <td
                    className="recivableTd"
                    colSpan={11}
                    style={{ textAlign: "center" }}
                  >
                    <Pagination
                      style={{ display: "flex", justifyContent: "flex-end" }}
                      count={Math.ceil(
                        filteredSummaryData.length / itemsPerPage
                      )}
                      page={currentPageNumber}
                      onChange={(event, page) =>
                        handlePageChange(currencyItem.code, page)
                      }
                    />
                  </td>
                </tr>
              </tbody>
            );
          })}
        </table>
      </div>
      <div style={{ flex: "2%" }}></div>
    </div>
  );
}

export default ReceivableSummaryTable;
