import React, { useState } from "react";
import { useEffect } from "react";
import DepartmentTap from "../../components/project/DepartmentTap";
import ItemsTracker from "../../components/project/ItemsTracker";
import "./departmentsDetail.style.css";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import UpdateStatus from "../../components/project/UpdateStatus";
import {
  useGetDeliveredItemQuery,
  useGetSolutionDetailsMutation,
} from "../../features/SCMApi";
import { useLocation, useParams } from "react-router-dom";
import Projectindex from "../../pages/Projects";

export default function DepartmentsDetail() {
  const [updateStatusModal, setUpdateStatusModal] = useState(false);
  const { projectId } = useParams();
  const [selectedItemRma, setItemID] = useState();
  const [activeTab, setActive] = useState("all");
  const [failedItemsState, setFailed] = useState(0);
  const [deliveredItemsState, setDelivered] = useState(0);

  
  const { pId } = useParams();
  const { sId } = useParams();
  const [selectedItem, setSelected] = useState();

  let failed = 0;
  let delivered = [];

  const data = {
    project_id: pId,
    solution_id: sId,
  };
  const [res, setRes] = useState([]);
  const { data: DeliveredItems } = useGetDeliveredItemQuery(projectId);
 
  const [getProduct] = useGetSolutionDetailsMutation();
  DeliveredItems?.data?.map((deliveredItems) =>
    delivered.push(...deliveredItems?.items)
  );
  let batchId = DeliveredItems?.data[0]?.id;
  
  function fetch() {
    
    getProduct(data)
      .unwrap()
      .then((response) => {
       
        setRes(response?.data?.boq);

        failed = response?.data?.boq?.filter(
          (itemsFiltered) =>
            itemsFiltered.isDelivered === true && itemsFiltered.isRma === true
        );
        let count = 0;
        setDelivered(delivered.length);
        
        failed?.map((items) => (count = count + items?.failedQty));
        setFailed(count);
      })
      .then((error) => {
        
      });
  }

  useEffect(() => {
    fetch();

    
  }, []);
  const location = useLocation();
  const { title, names } = location.state;
  
  return (
    <Projectindex title={title} name={names} navlink={`/projects/${pId}`}>
      <DepartmentTap setActive={setActive} />
      <div className="department-bottom-grid">
        {/* {res.map((items) => (
            <ItemsTracker list={items} />
          ))} */}
        <ItemsTracker
          setUpdateStatusModal={setUpdateStatusModal}
          list={res}
          batchId={batchId}
          delivered={delivered}
          activeTab={activeTab}
          setItemID={setItemID}
          setSelected={setSelected}
        />

        <div className="department-right-grid">
          <div className="counter-container">
            <div className="items-counter">
              <div className="tracer-display">
                <div className="tracer-display-value">{failedItemsState}</div>
                <div className="tracer-display-label">Total Failed Items</div>
              </div>
            </div>
            <div className="items-counter">
              <div className="tracer-display">
                <div className="tracer-display-value">{delivered?.length}</div>
                <div className="tracer-display-label">
                  Total Delivered Items
                </div>
              </div>
            </div>
          </div>
          <div className="calender-container">
            <Calendar />
          </div>
        </div>
      </div>

      {updateStatusModal ? (
        <UpdateStatus
          setUpdateStatusModal={setUpdateStatusModal}
          activeTab={activeTab}
          selectedItem={selectedItem}
        />
      ) : (
        <></>
      )}
    </Projectindex>
  );
}
