import React from "react";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import {
  useGetAllCurrenciesQuery,
  useGetProjectsQuery,
  useGetStatusQuery,
  useGetStoreQuery,
  useGetUnitOfMeasurementQuery,
  useGetUsersQuery,
} from "../../../../features/SCMApi";

const TransactionReport = ({ data, type }) => {
  let title = "IE NETWORKS SOLUTIONS PLC";
  let title2 = type;
  let title3 = "Report";
  let count = 0;
  const { data: currencies } = useGetAllCurrenciesQuery();
  const { data: users } = useGetUsersQuery();
  const { data: store } = useGetStoreQuery();
  const { data: uomuom } = useGetUnitOfMeasurementQuery();
  const { data: projects } = useGetProjectsQuery();
  const { data: status } = useGetStatusQuery();

  const downloadExcel = async () => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet();
    worksheet.mergeCells("A1:Y1");
    worksheet.mergeCells("A2:Y2");
    worksheet.mergeCells("A3:Y3");
    const titleCell = worksheet.getCell("A1");
    const titleCell2 = worksheet.getCell("A2");
    const titleCell3 = worksheet.getCell("A3");

    titleCell.value = title;
    titleCell.alignment = { horizontal: "center", vertical: "middle" };
    titleCell2.value = title2;
    titleCell2.alignment = { horizontal: "center", vertical: "middle" };
    titleCell3.value = title3;
    titleCell3.alignment = { horizontal: "center", vertical: "middle" };

    titleCell3.font = {
      name: "Times New Roman",
    };
    titleCell3.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ffffff" },
    };
    titleCell2.font = {
      name: "Times New Roman",
    };
    titleCell2.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ffffff" },
    };
    titleCell.font = {
      name: "Times New Roman",
    };

    titleCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ffffff" },
    };

    // Create the table headers
    const headerRow = worksheet.addRow([
      "Number",
      "Upstream Number",
      "Transaction Number",
      "Downstream Number",
      "Stock Type",
      "Task Type",
      "Is Batch",
      "Created On",
      "Inventory Type/Category",
      // "Transaction Types",
      "Part Number",
      "Brand/Model",
      "Material Name",
      "Serial No",
      "Barcode/Tag Number",
      "Status",
      "Purchase Date",
      "UOM",
      "Qty",
      "Currency",
      "Unit Price",
      "Total Price",
      "Source Name",
      "Destination",
      "Created/Modified By",
      "Approved By",
      "Remark",
    ]);
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "4dffb8" },
      };
      cell.font = {
        color: { argb: "000000" },
        bold: true,
        size: 12,
        name: "Times New Roman",
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });

    // Set column widths
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 25;
    worksheet.getColumn(3).width = 25;
    worksheet.getColumn(4).width = 25;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 15;
    worksheet.getColumn(9).width = 15;
    worksheet.getColumn(10).width = 15;
    worksheet.getColumn(11).width = 15;
    worksheet.getColumn(12).width = 15;
    worksheet.getColumn(13).width = 15;
    worksheet.getColumn(14).width = 15;
    worksheet.getColumn(15).width = 15;
    worksheet.getColumn(16).width = 15;
    worksheet.getColumn(17).width = 15;
    worksheet.getColumn(18).width = 15;
    worksheet.getColumn(19).width = 20;
    worksheet.getColumn(20).width = 20;
    worksheet.getColumn(21).width = 20;
    worksheet.getColumn(22).width = 20;
    worksheet.getColumn(23).width = 20;
    worksheet.getColumn(24).width = 20;
    worksheet.getColumn(25).width = 20;
    worksheet.getColumn(26).width = 20;
    // Set the header row to bold
    worksheet.getRow(2).font = { bold: true };
    worksheet.getRow(4);

    data?.map((items) => {
      const dataRow = worksheet?.addRow([
        (count += 1),
        items?.second_id
          ? `IE-${items?.transaction_type == "register" ? "PO" : items?.transaction_type == "return" ? "PO" : items?.transaction_type == "request" ? "RQ" : items?.transaction_type == "transfer" ? (items?.task_type == "Allocation Out" ? "RQ" : items?.task_type == "Allocation In" ? "PO" : "-") : "_"}-${items?.created_at?.split("T")[0]}-${String(items?.second_id).padStart(4, "0")}`
          : "-",
        items?.second_id
          ? `IE-${items?.transaction_type == "register" ? "SI" : items?.transaction_type == "return" ? "SI" : items?.transaction_type == "request" ? "SO" : items?.transaction_type == "transfer" ? (items?.task_type == "Allocation Out" ? "SO" : items?.task_type == "Allocation In" ? "SI" : "-") : "_"}-${items?.created_at?.split("T")[0]}-${String(items?.second_id).padStart(4, "0")}`
          : "-",
        items?.second_id
          ? `IE-${items?.transaction_type == "register" ? "GR" : items?.transaction_type == "return" ? "GR" : items?.transaction_type == "request" ? "DR" : items?.transaction_type == "transfer" ? (items?.task_type == "Allocation Out" ? "DR" : items?.task_type == "Allocation In" ? "GR" : "-") : "_"}-${items?.created_at?.split("T")[0]}-${String(items?.second_id).padStart(4, "0")}`
          : "-",
        items?.transaction_type == "register"
          ? "Stock In"
          : items?.transaction_type == "return"
            ? "Stock In"
            : items?.transaction_type == "request"
              ? "Stock Out"
              : items?.transaction_type == "transfer"
                ? items?.task_type == "Allocation Out"
                  ? "Stock Out"
                  : items?.task_type == "Allocation In"
                    ? "Stock In"
                    : "*"
                : "#",
        items?.task_type ? items.task_type : "-",
        items?.is_batch ? "Batch Request" : "-",
        items?.created_at != null ? items.created_at?.split("T")[0] : "-",
        items?.inventory_type ? items.inventory_type : "-",

        "-",
        items?.model == null ? "-" : items?.model,
        items?.item_name ? items?.item_name : "-",
        items?.serial_number == null ? "-" : items?.serial_number,
        "-",
        items?.status
          ? status?.data
              ?.filter((stat) => stat?.id == items?.status)
              ?.map((stat) => stat?.status_name)[0] || items?.status
          : items?.status,
        items?.purchased_date ? items.purchased_date : "-",
        items?.uom
          ? uomuom?.data?.filter((uom) => uom?.id == items?.uom)[0]?.name || "?"
          : "-",

        items?.qty ? items?.qty : 0,

        items?.currencies
          ? currencies?.data?.filter(
              (currencies) => currencies?.currency_id == items?.currencies
            )[0]?.code || "?"
          : "-",

        items?.price != null ? items.price : "-",

        items?.price == null
          ? "-"
          : items?.qty
            ? items?.price * parseFloat(items?.qty)
            : "-",

        items?.source
          ? store?.data?.filter((store) => store?.id == items?.source)[0]
              ?.store_name ||
            projects?.data?.filter((project) => project?.id == items?.source)[0]
              ?.project_name ||
            items?.source
          : "-",
        // items?.project_id
        //   ? projects?.data?.filter(
        //       (project) => project?.id == items?.project_id
        //     )[0]?.project_name || "?"
        //   : "-",
        // items?.transaction_type == "transfer"
        //   ? store?.data?.filter((store) => store?.id == items?.destination)[0]
        //       ?.store_name || "-"
        //   :
        items?.destination
          ? projects?.data?.filter(
              (project) => project?.id == items?.destination
            )[0]?.project_name ||
            store?.data?.filter((store) => store?.id == items?.destination)[0]
              ?.store_name ||
            items?.destination
          : "-",
        items?.requested_by
          ? users?.data?.filter((item) => item?.id === items?.requested_by)[0]
              ?.name || "-"
          : "-",
        items?.approved_by
          ? users?.data?.filter((item) => item?.id === items?.approved_by)[0]
              ?.name || "-"
          : "-",
        items?.remark != null ? items.remark : "-",
      ]);
      dataRow.eachCell((cell) => {
        cell.border = {
          top: { style: "thin", color: { argb: "000000" } },
          left: { style: "thin", color: { argb: "000000" } },
          bottom: { style: "thin", color: { argb: "000000" } },
          right: { style: "thin", color: { argb: "000000" } },
        };
      });
    });

    // const FooterRow = worksheet.addRow([
    //   " ",
    //   "Total",
    //   PlannedTotalCost,
    //   " ",
    //   " ",
    //   ActualTotalCost,
    //   " ",
    //   " ",
    //   " ",
    //   " ",
    //   ]);
    //   FooterRow.eachCell((cell) => {
    //     cell.fill = {
    //       type: "pattern",
    //       pattern: "solid",
    //       fgColor: { argb: "BFBFBF" },
    //     };
    //     cell.font = {
    //       color: { argb: "000000" },
    //       bold: true,
    //       size: 12,
    //       name: "Times New Roman",
    //     };
    //     cell.border = {
    //       top: { style: "thin", color: { argb: "000000" } },
    //       left: { style: "thin", color: { argb: "000000" } },
    //       bottom: { style: "thin", color: { argb: "000000" } },
    //       right: { style: "thin", color: { argb: "000000" } },
    //     };
    //     cell.alignment = {
    //       vertical: "middle",
    //       horizontal: "right",
    //       wrapText: true,
    //     };
    //   });

    // Save the workbook and download the Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, type);
  };

  return (
    <button onClick={downloadExcel} className="add-client-representative">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14V11H2V14H14V11H16V14C16 14.55 15.8043 15.021 15.413 15.413C15.021 15.8043 14.55 16 14 16H2ZM8 12L3 7L4.4 5.55L7 8.15V0H9V8.15L11.6 5.55L13 7L8 12Z"
          fill="white"
        />
      </svg>
    </button>
  );
};

export default TransactionReport;
