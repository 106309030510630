import { useState } from "react";
import {
  useDispatchedToolsMutation,
  useReturnedToolsMutation,
} from "../../../features/SCMApi";
import InventoryReport from "./InventoryReport";
import NoRecord from "../../common/NoRecord";

export default function ReturnedModal(props) {
  const [Dispatched] = useDispatchedToolsMutation();
  const [Returned] = useReturnedToolsMutation();
  const [start_date, setStartDate] = useState();
  const [end_date, setEndDate] = useState();
  const [data, setData] = useState(null);
  let form = { start_date, end_date };
  function handleSubmit(e) {
    e.preventDefault();
    Returned(form)
      .unwrap()
      .then((response) => {
        setData(response?.data);

      })
      .then((error) => {

      });
  }

  return (
    <div
      id="Modal"
      className="dialog-modal "
      onClick={(e) => {
        props?.setDialog(false);
      }}
    >
      <div
        className="stock-dialog-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="dialog-info">Please Select start date and end date</div>

        <div className="d-flex gap-2 align-items-baseline">
          <div className="supplier-input-label"> Start Date</div>
          <input
            onChange={(e) => setStartDate(e.target.value)}
            type="date"
            className={`stock-text-input`}
            required
          />
        </div>
        <div className="d-flex gap-2 align-items-baseline">
          <div className="supplier-input-label">End Date</div>
          <input
            onChange={(e) => setEndDate(e.target.value)}
            type="date"
            className={`stock-text-input`}
            required
          />
        </div>
        {data != null ? (
          data?.length != 0 ? (
            <div className="d-flex align-items-center">
              <InventoryReport
                data={data}
                type="Return Tools List"
                is_tool={true}
                is_return={props?.return}
              />
              <span className="prg-pri">Download Returned Items</span>
            </div>
          ) : (
            <NoRecord />
          )
        ) : (
          ""
        )}

        <div className="confirm-dialog-controller">
          <div
            className="dialog-cancel-btn"
            onClick={(e) => {
              props?.setDialog(false);
            }}
          >
            No
          </div>
          <button
            type={"submit"}
            onClick={(e) => {
              handleSubmit(e);
            }}
            className={"form-save"}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
}
