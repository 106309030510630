import React from "react";
import { CgSearch } from "react-icons/cg";
function SearchAndTabHeader(props) {
  return (
    <>
      <div class="d-flex  p-2 mt-10 justify-content-center align-items-center rounded fs-4 gap-4">
        <div className="d-flex gap-2 font-bold  task-order-name">
          <input
            checked={props?.prfType === "sourcing"}
            onChange={() => props?.setPrfType("sourcing")}
            className="w-20"
            type="radio"
            name="prfType"
            id="sourcing"
            value="sourcing"
          />
          <label className="">Sourcing</label>
        </div>
        <div className="d-flex gap-2 font-bold task-order-name">
          <input
            checked={props?.prfType === "logistics"}
            onChange={() => props?.setPrfType("logistics")}
            className="w-20"
            type="radio"
            name="prfType"
            id="logistics"
            value="logistics"
          />
          <label className="">Logistics</label>
        </div>
        <div className="d-flex gap-2 font-bold task-order-name">
          <input
            checked={props?.prfType === "LCByClient"}
            onChange={() => {
              props?.setPrfType("LCByClient");
              props?.setShowLCItemDetail(false);
            }}
            className="w-20"
            type="radio"
            name="prfType"
            id="LCByClient"
            value="LCByClient"
          />
          <label className="">LC By Client</label>
        </div>
      </div>
      <div className="d-flex justify-content-center text-dark items-center px-16 py-4 rounded gap-2">
        <label className="fs-4 text-dark">
          <CgSearch />
        </label>
        <input
          className=" rounded text-dark --bs-dark-text-emphasis"
          style={{ color: "black", width: "300px", height: "40px" }}
          type="text"
          value={props?.searchQuery}
          onChange={(e) => props?.setSearchQuery(e.target.value)}
        />
      </div>
    </>
  );
}

export default SearchAndTabHeader;
