import { Tooltip } from "@mui/material";
import React from "react";
import { DOC_BASE_URL } from "../../api/endPoint";
import NoRecord from "../common/NoRecord";

export default function ChamberizedDocument(props) {
  
  return (
    <>
      {props?.data?.data?.length !== 0 ? (
        <>
          {props?.data?.data?.map((items) =>
            items.cover_letter !== "" ? (
              <>
                <div style={{ margin: "10px 30px" }}>
                  <span
                    style={{
                      color: "#353945",
                      fontWeight: "500",
                      fontSize: "16px",
                      marginLeft: "10px",
                    }}
                  >
                    Cover Letter Document
                  </span>
                </div>
                <div
                  style={{
                    border: "2px #ECECEC solid",
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "5px auto",
                    padding: "3px",
                    alignSelf: "center",
                    width: "90%",
                  }}
                >
                  <span
                    style={{
                      marginLeft: "4%",
                      marginTop: "5px",
                      color: "#353945",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    {props?.batchName}-Cover Letter Document
                  </span>
                  <Tooltip title="Download">
                    <a
                      href={`${DOC_BASE_URL}scm/document/download?file_path=${items.cover_letter}`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#186569"
                        class="bi bi-download"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                      </svg>
                    </a>
                  </Tooltip>
                </div>
              </>
            ) : (
              ""
            )
          )}

          {props?.data?.data?.map((items) =>
            items.commercial_invoice !== "" ? (
              <>
                <div style={{ margin: "10px 30px" }}>
                  <span
                    style={{
                      color: "#353945",
                      fontWeight: "500",
                      fontSize: "16px",
                      marginLeft: "10px",
                    }}
                  >
                    Commercial Invoice Document
                  </span>
                </div>
                <div
                  style={{
                    border: "2px #ECECEC solid",
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "5px auto",
                    padding: "3px",
                    alignSelf: "center",
                    width: "90%",
                  }}
                >
                  <span
                    style={{
                      marginLeft: "4%",
                      marginTop: "5px",
                      color: "#353945",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    {props?.batchName}-Commercial Invoice Document
                  </span>
                  <Tooltip title="Download">
                    <a
                      href={`${DOC_BASE_URL}scm/document/download?file_path=${items.commercial_invoice}`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#186569"
                        class="bi bi-download"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                      </svg>
                    </a>
                  </Tooltip>
                </div>
                <div
                  style={{
                    border: "2px #ECECEC solid",
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "5px auto",
                    padding: "3px",
                    alignSelf: "center",
                    width: "90%",
                  }}
                >
                  <span
                    style={{
                      marginLeft: "4%",
                      marginTop: "5px",
                      color: "#353945",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    {props?.batchName}-Commercial Invoice Document
                  </span>
                  <Tooltip title="Download">
                    <a
                      href={`${DOC_BASE_URL}scm/document/download?file_path=${items.commercial_invoice}`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#186569"
                        class="bi bi-download"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                      </svg>
                    </a>
                  </Tooltip>
                </div>
              </>
            ) : (
              ""
            )
          )}

          {props?.data?.data?.map((items) =>
            items.packing_list !== "" ? (
              <>
                <div style={{ margin: "10px 30px" }}>
                  <span
                    style={{
                      color: "#353945",
                      fontWeight: "500",
                      fontSize: "16px",
                      marginLeft: "10px",
                    }}
                  >
                    Packing List
                  </span>
                </div>
                <div
                  style={{
                    border: "2px #ECECEC solid",
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "5px auto",
                    padding: "3px",
                    alignSelf: "center",
                    width: "90%",
                  }}
                >
                  <span
                    style={{
                      marginLeft: "4%",
                      marginTop: "5px",
                      color: "#353945",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    {props?.batchName}- Packing List
                  </span>
                  <Tooltip title="Download">
                    <a
                      href={`${DOC_BASE_URL}scm/document/download?file_path=${items.packing_list}`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#186569"
                        class="bi bi-download"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                      </svg>
                    </a>
                  </Tooltip>
                </div>
              </>
            ) : (
              ""
            )
          )}

          {props?.data?.data?.map((items) =>
            items.certificate_of_origin !== "" ? (
              <>
                <div style={{ margin: "10px 30px" }}>
                  <span
                    style={{
                      color: "#353945",
                      fontWeight: "500",
                      fontSize: "16px",
                      marginLeft: "10px",
                    }}
                  >
                    Certificate of Origin
                  </span>
                </div>
                <div
                  style={{
                    border: "2px #ECECEC solid",
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "5px auto",
                    padding: "3px",
                    alignSelf: "center",
                    width: "90%",
                  }}
                >
                  <span
                    style={{
                      marginLeft: "4%",
                      marginTop: "5px",
                      color: "#353945",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    {props?.batchName}- Certificate of Origin
                  </span>
                  <Tooltip title="Download">
                    <a
                      href={`${DOC_BASE_URL}scm/document/download?file_path=${items.certificate_of_origin}`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#186569"
                        class="bi bi-download"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                      </svg>
                    </a>
                  </Tooltip>
                </div>
              </>
            ) : (
              ""
            )
          )}

          {props?.data?.data?.map((items) =>
            items.loading_bill !== "" ? (
              <>
                <div style={{ margin: "10px 30px" }}>
                  <span
                    style={{
                      color: "#353945",
                      fontWeight: "500",
                      fontSize: "16px",
                      marginLeft: "10px",
                    }}
                  >
                    Loading Bill
                  </span>
                </div>
                <div
                  style={{
                    border: "2px #ECECEC solid",
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "5px auto",
                    padding: "3px",
                    alignSelf: "center",
                    width: "90%",
                  }}
                >
                  <span
                    style={{
                      marginLeft: "4%",
                      marginTop: "5px",
                      color: "#353945",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    {props?.batchName}- Loading Bill
                  </span>
                  <Tooltip title="Download">
                    <a
                      href={`${DOC_BASE_URL}scm/document/download?file_path=${items.loading_bill}`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#186569"
                        class="bi bi-download"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                      </svg>
                    </a>
                  </Tooltip>
                </div>
              </>
            ) : (
              ""
            )
          )}

          {props?.data?.data?.map((items) =>
            items.beneficiary_certificate !== "" ? (
              <>
                <div style={{ margin: "10px 30px" }}>
                  <span
                    style={{
                      color: "#353945",
                      fontWeight: "500",
                      fontSize: "16px",
                      marginLeft: "10px",
                    }}
                  >
                    Beneficiary Certificate
                  </span>
                </div>
                <div
                  style={{
                    border: "2px #ECECEC solid",
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "5px auto",
                    padding: "3px",
                    alignSelf: "center",
                    width: "90%",
                  }}
                >
                  <span
                    style={{
                      marginLeft: "4%",
                      marginTop: "5px",
                      color: "#353945",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    {props?.batchName}- Beneficiary Certificate
                  </span>
                  <Tooltip title="Download">
                    <a
                      href={`${DOC_BASE_URL}scm/document/download?file_path=${items.beneficiary_certificate}`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#186569"
                        class="bi bi-download"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                      </svg>
                    </a>
                  </Tooltip>
                </div>
              </>
            ) : (
              ""
            )
          )}

          {props?.data?.data?.map((items) =>
            items.freight_invoice !== "" ? (
              <>
                <div style={{ margin: "10px 30px" }}>
                  <span
                    style={{
                      color: "#353945",
                      fontWeight: "500",
                      fontSize: "16px",
                      marginLeft: "10px",
                    }}
                  >
                    Freight Invoice
                  </span>
                </div>
                <div
                  style={{
                    border: "2px #ECECEC solid",
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "5px auto",
                    padding: "3px",
                    alignSelf: "center",
                    width: "90%",
                  }}
                >
                  <span
                    style={{
                      marginLeft: "4%",
                      marginTop: "5px",
                      color: "#353945",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    {props?.batchName}- Freight Invoice
                  </span>
                  <Tooltip title="Download">
                    <a
                      href={`${DOC_BASE_URL}scm/document/download?file_path=${items.freight_invoice}`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#186569"
                        class="bi bi-download"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                      </svg>
                    </a>
                  </Tooltip>
                </div>
              </>
            ) : (
              ""
            )
          )}

          {props?.data?.data?.map((items) =>
            items.vessel_certificate !== "" ? (
              <>
                <div style={{ margin: "10px 30px" }}>
                  <span
                    style={{
                      color: "#353945",
                      fontWeight: "500",
                      fontSize: "16px",
                      marginLeft: "10px",
                    }}
                  >
                    Vessel Certificate
                  </span>
                </div>
                <div
                  style={{
                    border: "2px #ECECEC solid",
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "5px auto",
                    padding: "3px",
                    alignSelf: "center",
                    width: "90%",
                  }}
                >
                  <span
                    style={{
                      marginLeft: "4%",
                      marginTop: "5px",
                      color: "#353945",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    {props?.batchName}- Vessel Certificate
                  </span>
                  <Tooltip title="Download">
                    <a
                      href={`${DOC_BASE_URL}scm/document/download?file_path=${items.vessel_certificate}`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#186569"
                        class="bi bi-download"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                      </svg>
                    </a>
                  </Tooltip>
                </div>
              </>
            ) : (
              ""
            )
          )}
        </>
      ) : (
        <NoRecord />
      )}
    </>
  );
}
