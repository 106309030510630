import * as React from "react";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
const DatePicker = (props) => {
  const date = new Date();

  const [month, day, year] = [
    date.getMonth(),
    date.getDate(),
    date.getFullYear(),
  ];

  const today = `${month + 1}-${day}-${year}`;
  const [value, setValue] = React.useState(dayjs(props?.passedValue));

  const handleChange = (newValue) => {
    setValue(newValue);
    props?.onChange(newValue);
  };

  const popperSx = {
    "& .MuiPaper-root": {
      backgroundColor: "#ececec",
    },
    //   "& .PrivatePickersSlideTransition-root": {},
    "& .MuiPickersDay-dayWithMargin": {
      color: "#aeb8c9",
      backgroundColor: "#ececec",
    },
    "& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected": {
      backgroundColor: "#186569",
      color: "#ececec",
    },
    "& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected .hover": {
      backgroundColor: "#186569",
    },
    "& .css-1yy0aak-MuiTypography-root": {
      backgroundColor: "#186569",
    },
  };
  const inputProps = {
    backgroundColor: "#ececec",
    width: "124%",
    marginTop: "7px",
    marginBottom: "7px",
    marginLeft: "-20px",
    height: "35px",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "11px",
    lineHeight: "14px",
    letterSpacing: "0.105em",
    color: "#353945",
    "& .css-1gctnaj-MuiInputBase-input-MuiFilledInput-input": {
      marginBottom: "13px",
    },

    "& .MuiSvgIcon-root": {
      color: "#186569",
    },
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          inputFormat="MM/DD/YYYY"
          value={value}
          onChange={handleChange}
          // views={["day", "month", "year"]}
          showDaysOutsideCurrentMonth
          components={{
            OpenPickerIcon: CalendarMonthIcon,
            LeftArrowIcon: ArrowLeftIcon,
            RightArrowIcon: ArrowRightIcon,
          }}
          renderInput={(params) => <TextField variant="filled" {...params} />}
          InputProps={{ sx: inputProps, disableUnderline: true }}
          PopperProps={{ sx: popperSx }}
        />
      </LocalizationProvider>
    </>
  );
};

export default DatePicker;
