import React, { useEffect, useState } from "react";
import "./aging.css";
import axios from "axios";
import { API_BASE_URL } from "../../../api/endPoint";
import PayableSummarizedTable from "./PayableSummarizedTable";
import PayableFilter from "./PayableFilter";
import PayableExportButton from "./PayableExportButton";
import PayableFilteredHistoryTable from "./PayableFilteredHistoryTable";
import { IoMdArrowRoundBack } from "react-icons/io";

const PayableFilteredTable = () => {
  const agings = [
    { type: "current_upcoming" },
    { type: "1_30" },
    { type: "31_60" },
    { type: "61_90" },
    { type: "91_120" },
    { type: "121_150" },
    { type: "150_and_above" },
  ];

  const [searchCurrency, setSearchCurrency] = useState("");
  const [searchProject, setSearchProject] = useState("");
  const [searchSupplier, setSearchSupplier] = useState("");
  const [searchDate, setSearchDate] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [payableFilteredData, setPayableFilteredData] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [isHistoryShown, setIsHistoryShown] = useState(true);

  const toggleHistory = () => {
    setShowHistory(!showHistory);
    setIsHistoryShown(!isHistoryShown);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setCurrentPage(0);
  };
  let counter = 0;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/scm/sourcing/supplier/payable-aging_supplier`
        );
        setPayableFilteredData(response?.data?.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  const flattenedArray = payableFilteredData?.flatMap((subArray) => subArray);

  return (
    <div className="Payable-Table-Container prg">
      <div className="d-flex justify-content-end">
        <button className="prg-payableHistory" onClick={toggleHistory}>
          {isHistoryShown ? (
            "Payable History"
          ) : (
            <div className="d-flex justify-content-around align-items-center">
              <IoMdArrowRoundBack />
              <div className="px-2">Go back</div>
            </div>
          )}
        </button>
      </div>
      {!showHistory ? (
        <>
          <div className="payable-selector">
            <PayableFilter
              searchCurrency={searchCurrency}
              searchProject={searchProject}
              searchSupplier={searchSupplier}
              searchDate={searchDate}
              handleSearchSubmit={handleSearchSubmit}
              setSearchCurrency={setSearchCurrency}
              setSearchProject={setSearchProject}
              setSearchSupplier={setSearchSupplier}
              setSearchDate={setSearchDate}
            />
          </div>
          <PayableExportButton />
          {searchCurrency || searchProject || searchDate || searchSupplier ? (
            <div class="table-responsive border m-[20px]">
              <table class="table">
                <thead>
                  <tr style={{ backgroundColor: "rgba(86, 184, 183, 0.2)" }}>
                    <th>S.No.</th>
                    <th>Invoice/Transaction Date</th>
                    <th>Reference/Invoice Number</th>
                    <th>Supplier/Creditor Name</th>
                    <th>Project Name</th>
                    <th>Amount</th>
                    <th>Currency</th>
                    <th>Due Date</th>
                    <th>Report Date</th>
                    <th>Age of Payable</th>
                    <th>Reason</th>
                    <th>Status</th>
                  </tr>
                </thead>
                {agings?.map((itemss) => (
                  <tbody>
                    <tr>
                      <th
                        colSpan={1}
                        style={{
                          backgroundColor: "rgba(160, 205, 99, 0.5)",
                        }}
                      >
                        {}
                      </th>
                      <th
                        colSpan={11}
                        style={{
                          textAlign: "left",
                          color: "rgba(48, 69, 105, 1)",
                          backgroundColor: "rgba(160, 205, 99, 0.5)",
                        }}
                      >
                        {itemss.type}
                      </th>
                    </tr>
                    {flattenedArray?.map((item, index) => {
                      if (
                        item?.AgingGroup === itemss.type &&
                        item?.currency_name &&
                        item?.currency_name
                          .toLowerCase()
                          .includes(searchCurrency.toLowerCase()) &&
                        (searchProject === "" ||
                          item.project_id === searchProject) &&
                        (searchSupplier === "" ||
                          item.supplier_name === searchSupplier) &&
                        (searchDate === "" ||
                          new Date(item.payableDate) >= new Date(searchDate))
                      ) {
                        counter++;
                        return (
                          <tr
                            key={index}
                            style={{
                              backgroundColor:
                                itemss.type === "current_upcoming"
                                  ? "rgba(75, 181, 67, 0.8)"
                                  : itemss.type === "1_30"
                                    ? "rgba(0, 162, 253, 0.7)"
                                    : itemss.type === "31_60"
                                      ? " rgb(255, 255, 0, 0.7)"
                                      : itemss.type === "61_90"
                                        ? "rgb(255, 213, 128, 0.7)"
                                        : itemss.type === "91_120"
                                          ? "rgba(255, 0, 0, 0.7)"
                                          : itemss.type === "121_150"
                                            ? "rgba(255, 0, 0, 0.7)"
                                            : itemss.type === "150_and_above"
                                              ? "rgba(255, 0, 0, 0.7)"
                                              : "transparent",
                            }}
                          >
                            <td
                              style={{
                                backgroundColor: "rgba(234, 236, 240, 1)",
                              }}
                            >
                              {counter}
                            </td>
                            <td>{item.transaction_date}</td>
                            <td>{item.Reference_number || "-"}</td>
                            <td>{item.supplier_name}</td>
                            <td>{item.project_name}</td>
                            <td>
                              {item.amount !== null
                                ? item.amount.toLocaleString()
                                : ""}
                            </td>
                            <td>{item.currency_name}</td>
                            <td>{item.payableDate}</td>
                            <td>{item.reportDate}</td>
                            <td>{item.aging}</td>
                            <td>{item.reason}</td>
                            <td>{item.status}</td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                ))}
              </table>
            </div>
          ) : (
            <PayableSummarizedTable />
          )}
        </>
      ) : (
        <PayableFilteredHistoryTable />
      )}
    </div>
  );
};

export default PayableFilteredTable;
