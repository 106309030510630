import React from "react";
import { useState } from "react";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { IconButton } from "@mui/material";
import {
  useGetBatchToolRequestQuery,
  useGetStoreQuery,
  useBatchToolQuery,
  useGetUserInfoQuery,
  useToolRequestMutation,
  useToolRequestRequestMutation,
  useApprovalApproveMutation,
  useApprovalRejectMutation,
  useGetApproverQuery,
  useInStoreItemsRequestMutation,
  useItemsAcceptMutation,
  useGetBatchInventoryRequestQuery,
  useGetInventoryRequestedQuery,
  useItemsInventoryConfirmMutation,
} from "../../../../features/SCMApi";

import AddIcon from "@mui/icons-material/Add";
import RegisterTool from "../../StockModal/RegisterTool";
import ConfirmDialog from "../../../common/ConfirmDialog";
import axios from "axios";
import { Toll } from "@mui/icons-material";
import NoRecord from "../../../common/NoRecord";
import { IMG_BASE_URL } from "../../../../api/endPoint";
import StockDialog from "../../../common/StockDialog";
import StockIndex from "../../../../pages/Stocks/index";
import NavBar from "../../../../Layout/NavBar";
import { NavLink, useParams } from "react-router-dom";
import InventoryApprovalModal from "../../../common/InventoryApprovalModal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect } from "react";
import BatchAccept from "../../StockModal/BatchAccept";
import InventoryApprovalRejectModal from "../../../common/InventoryApprovalRejectModal";
import { $CombinedState } from "@reduxjs/toolkit";

export default function RequestBatchInventory(props) {
  function HandleRequest() {
    setConfirm(true);
  }
  const [search, setSearch] = useState("");
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const HandleFragment = (tool) => {
    props?.setFragment("detail");
    props?.setData(tool);
  };
  const params = useParams();
  const { data: BatchInventory } = useGetBatchInventoryRequestQuery(params?.id);

  const [registerTool, setRegisterTool] = useState(false);

  const [confirm, setConfirm] = useState(false);
  const [dialog, setDialog] = useState(false);

  const [toolRequest, src] = useToolRequestMutation();
  const [requested_quantity, setQuantity] = useState(null);
  const [requestedProject, setRequestedProject] = useState("");
  const [approveConfirm, setApproveConfirm] = useState(false);
  const [approvalRejected, setApprovalRejected] = useState(false);
  const [hideApprovalButton, setHideApprovalButton] = useState(false);
  const [feedBack, setFeedBack] = useState(null);
  const { data: userinfo } = useGetUserInfoQuery();

  const { data: store } = useGetStoreQuery();
  let SK =
    userinfo?.role?.role_name == "Store Keeper" ||
    userinfo?.role?.role_name == "Super Admin";
  let FE = userinfo?.role?.role_name == "Field Engineer";
  let PM = userinfo?.role?.role_name == "Project Manager"; // userinfo?.role?.role_name == "Super Admin" ||
  // userinfo?.role?.role_name == "Field Engineer";
  let create_by = userinfo?.id;

  const [updateTool, setupdateTool] = useState(null);
  const [error, setError] = useState(null);

  const HandleEditTool = () => {
    setRegisterTool(true);
  };
  const [Accept, setAccept] = useState(false);
  const [remark, setRemark] = useState(null);
  function HandleRequest() {
    setConfirm(true);
  }
  function HandleAccept() {
    setAccept(true);
  }
  const [ItemConfirm, srcs] = useItemsInventoryConfirmMutation();
  const [ItemAccept, acce] = useItemsAcceptMutation();

  const [amount, setAmount] = useState();
  const [confirmRequest, setConfirmRequest] = useState(false);
  const [confirmRequestItems, setConfirmRequestItems] = useState([]);
  function HandleConfirmRequest() {
    setConfirmRequest(true);
  }
  function handleBatchRequest(e, items, i) {
    e.preventDefault();
    const value = e.target.value;
    const tools = [...confirmRequestItems];
    tools[i] = { ...items, requested_amount: value };
    //  setAmount(tools);
    setConfirmRequestItems(tools);
  }
  let data;
  data = confirmRequestItems.filter(function (element) {
    return element !== undefined;
  });
  let items =
    BatchInventory?.data == undefined
      ? "[]"
      : BatchInventory?.data?.requested_items;
  let BatchItems = JSON.parse(items);

  const { data: getApprover } = useGetApproverQuery({
    module_name: "Inventory",
    id: params?.id,
  });

  const { data: loggedUser } = useGetUserInfoQuery();
  const [approvalApprove] = useApprovalApproveMutation();
  const [approvalReject] = useApprovalRejectMutation();

  const handleApprovals = (id) => {
    let dataForApproval = {
      module_name: "Inventory",
      module_id: id,
      user_id: loggedUser?.id,
      feedback: feedBack,
    };
    approvalApprove(dataForApproval)
      .unwrap()
      .then((response) => {
        setApproveConfirm(false);
        setHideApprovalButton(true);
      })
      .then((error) => {});
  };

  const handleDecline = (id) => {
    let dataForApproval = {
      module_name: "Inventory",
      module_id: id,
      user_id: loggedUser?.id,
      feedback: feedBack,
    };
    approvalReject(dataForApproval)
      .unwrap()
      .then((response) => {
        setApprovalRejected(false);
        setHideApprovalButton(true);
      })
      .then((error) => {});
  };
  function HandleToolAccept(e, items) {
    let requested = {
      returned_quantity: items?.returned_quantity,
      id: items?.id,
      tool_id: items?.tool_id,
      remark: remark,
    };
    ItemAccept(requested)
      .unwrap()
      .then((response) => {
        setAccept(false);
      })
      .then((error) => {});
  }
  let request_items =
    getApprover?.data.current[0]?.requested_items == undefined
      ? "[]"
      : JSON.parse(getApprover?.data.current[0]?.requested_items);

  const { data: InventoryDetail } = useGetInventoryRequestedQuery(params?.id);

  const [success, setSuccess] = useState(false);
  function HandleinventoryRequest(e, items) {
    let requested = {
      id: items,
      confirmed_by: loggedUser?.id,
      is_batch: true,
    };
    ItemConfirm(requested)
      .unwrap()
      .then((response) => {
        setConfirm(false);
        setSuccess(true);
      })
      .catch((error) => {
        setError(error?.data?.message);
        console.log(error, "errors");
      });
  }

  success &&
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  const filterdStore = store?.data?.filter(
    (store) => store?.id == BatchItems[0]?.store_id
  );
  return (
    <>
      <NavBar
        placeholder={props?.placeholder}
        handleSearchChange={props?.handleSearchChange}
      />
      <div className="page">
        {" "}
        <div className="d-flex justify-content-start my-2">
          <NavLink
            to={`/stocks/requests/requested/items`}
            className="price-comparison"
          >
            <span className="d-flex justify-content-center gap-4 px-3 py-3 align-items-center">
              <ArrowBackIcon />
              Go Back
            </span>
          </NavLink>
        </div>
        {BatchItems?.length !== 0 ? (
          <>
            <div className="inventory-header-container mt-4">
              <div className="tool-item-status">
                {getApprover?.data?.current?.length == 0
                  ? "No need for approval"
                  : getApprover?.data?.current[0]?.is_approved == 0
                    ? `${getApprover?.data?.current[0]?.role_name} approval pending`
                    : getApprover?.data?.current[0]?.is_approved == 2
                      ? "Rejected"
                      : "Approved"}
              </div>
              {success ? (
                <div className="h5-pri">Confirmed Successfully</div>
              ) : (
                ""
              )}
              <div className="items-counter">
                <div className="text-center">{BatchItems?.length}</div>
                <div>Items Count</div>
              </div>
            </div>
            <table style={{ width: "100%" }}>
              <thead className="stock-table-header">
                <tr>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "10%" }}
                  ></th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "17%" }}
                  >
                    Item Name
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "14%" }}
                  >
                    Model
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "10%" }}
                  >
                    Type
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "15%" }}
                  >
                    Store
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "10%" }}
                  >
                    Amount
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "11%" }}
                  >
                    Status
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "20%" }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {BatchItems?.map((tool, index) => (
                  <tr className="stock-table-row">
                    <td
                      className=""
                      onClick={() => {
                        HandleFragment(tool);
                        setupdateTool(null);
                      }}
                    >
                      <img
                        className="stock-img"
                        src={`${IMG_BASE_URL}${tool?.image}`}
                        alt=""
                      />
                    </td>
                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {tool?.item_name}
                    </td>
                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {tool?.model === null ? "-" : tool?.model}
                    </td>
                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {tool?.tool_type_id === null
                        ? "-"
                        : tool?.tool_type?.tool_type}
                    </td>
                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {" "}
                      {tool?.store_id === null ? "-" : tool?.store?.store_name}
                    </td>
                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {`${tool?.requested_amount === null ? "-" : tool?.requested_amount} ${
                        tool?.uom_id == null ? "-" : tool?.uom?.name
                      }`}
                    </td>
                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {tool?.status_id == null
                        ? "-"
                        : tool?.status?.status_name}
                    </td>
                    <td className="text-start">
                      {FE ? (
                        <div className="d-grid gap-3">
                          <button
                            className="form-save"
                            onClick={() => {
                              HandleRequest();
                              setDialog(tool);
                            }}
                          >
                            Request
                          </button>
                        </div>
                      ) : (
                        ""
                      )}

                      {PM ? (
                        tool?.is_group_item == 1 ? (
                          <div className="d-grid gap-3">
                            <button
                              className="form-save"
                              onClick={() => {
                                HandleRequest();
                                setDialog(tool);
                              }}
                            >
                              Request
                            </button>
                          </div>
                        ) : (
                          ""
                        )
                      ) : SK ? (
                        tool?.created_by == userinfo?.id ? (
                          <div className="d-flex justify-content-center gap-2 align-items-center">
                            <ModeEditOutlineOutlinedIcon
                              onClick={() => {
                                HandleEditTool(true);
                                setupdateTool(tool);
                              }}
                              sx={{
                                color: "#52FFAC",
                                "&:hover": {
                                  backgroundColor: "#fff",
                                  color: "#52FFAC",
                                  border: "1px solid #52FFAC",
                                  borderRadius: 25,
                                },
                              }}
                            />
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div>{error ? <div className="danger">{error}</div> : <></>}</div>
            {props?.data?.request_canceled ? (
              <></>
            ) : (
              <div
                className="batch-header-container text-start mt-2"
                style={{ display: "flex" }}
              >
                {getApprover?.data.current[0]?.is_approved == 1 ||
                getApprover?.data?.current?.length == 0 ? (
                  true ? (
                    getApprover?.data.current[0]?.is_returned == 0 &&
                    getApprover?.data.current[0]?.is_confirmed == 0 &&
                    loggedUser?.id == filterdStore[0]?.store_keeper_id ? (
                      <button
                        className="form-save"
                        onClick={() => {
                          HandleRequest();
                          setDialog(getApprover?.data.current[0]);
                        }}
                      >
                        Confirm
                      </button>
                    ) : getApprover?.data.current[0]?.is_returned == 1 &&
                      getApprover?.data.current[0]?.is_confirmed == 1 &&
                      loggedUser?.id ==
                        BatchItems[0]?.store?.store_keeper_id ? (
                      // loggedUser?.id ==
                      //   getApprover?.data.current[0]?.store_keeper_id
                      <button
                        className="form-save"
                        onClick={() => {
                          HandleAccept();
                          setDialog(getApprover?.data.current[0]);
                        }}
                      >
                        Accept
                      </button>
                    ) : (
                      ""
                    )
                  ) : FE ? (
                    <div className="d-flex justify-content-start gap-2 align-items-center">
                      {getApprover?.data.current[0].is_returned == 0 ? (
                        <ModeEditOutlineOutlinedIcon
                          // onClick={() => {
                          //   HandleEditToolType(true);
                          //   setupdateToolType(items);
                          // }}
                          sx={{
                            color: "#52FFAC",
                            "&:hover": {
                              backgroundColor: "#fff",
                              color: "#52FFAC",
                              border: "1px solid #52FFAC",
                              borderRadius: 25,
                            },
                          }}
                        />
                      ) : (
                        "Waiting for Acceptance"
                      )}
                    </div>
                  ) : (
                    ""
                  )
                ) : !hideApprovalButton &&
                  getApprover?.data?.current[0]?.is_approved == 0 &&
                  getApprover?.data?.current?.length &&
                  getApprover?.data?.current?.find(
                    (user) => user?.user_id === loggedUser?.id
                  ) ? (
                  <>
                    <IconButton
                      onClick={() => {
                        setApproveConfirm(true);
                        setDialog(getApprover?.data.current[0]);
                      }}
                    >
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.3775 16.005L17.16 8.2225L15.895 6.985L9.3775 13.5025L6.0775 10.2025L4.84 11.44L9.3775 16.005ZM11 22C9.49667 22 8.07583 21.7113 6.7375 21.1338C5.39917 20.5562 4.23042 19.7679 3.23125 18.7688C2.23208 17.7696 1.44375 16.6008 0.86625 15.2625C0.28875 13.9242 0 12.5033 0 11C0 9.47833 0.28875 8.04833 0.86625 6.71C1.44375 5.37167 2.23208 4.2075 3.23125 3.2175C4.23042 2.2275 5.39917 1.44375 6.7375 0.86625C8.07583 0.28875 9.49667 0 11 0C12.5217 0 13.9517 0.28875 15.29 0.86625C16.6283 1.44375 17.7925 2.2275 18.7825 3.2175C19.7725 4.2075 20.5562 5.37167 21.1338 6.71C21.7113 8.04833 22 9.47833 22 11C22 12.5033 21.7113 13.9242 21.1338 15.2625C20.5562 16.6008 19.7725 17.7696 18.7825 18.7688C17.7925 19.7679 16.6283 20.5562 15.29 21.1338C13.9517 21.7113 12.5217 22 11 22ZM11 20.35C13.6033 20.35 15.8125 19.4379 17.6275 17.6138C19.4425 15.7896 20.35 13.585 20.35 11C20.35 8.39667 19.4425 6.1875 17.6275 4.3725C15.8125 2.5575 13.6033 1.65 11 1.65C8.415 1.65 6.21042 2.5575 4.38625 4.3725C2.56208 6.1875 1.65 8.39667 1.65 11C1.65 13.585 2.56208 15.7896 4.38625 17.6138C6.21042 19.4379 8.415 20.35 11 20.35Z"
                          fill="#52FFAC"
                        />
                      </svg>
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setApprovalRejected(true);
                        setDialog(props?.id);
                      }}
                    >
                      <svg
                        width="23"
                        height="22"
                        viewBox="0 0 23 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.54169 16.28L11.6667 12.155L15.7917 16.28L16.9467 15.125L12.8217 11L16.9467 6.875L15.7917 5.72L11.6667 9.845L7.54169 5.72L6.38669 6.875L10.5117 11L6.38669 15.125L7.54169 16.28ZM11.6667 22C10.1634 22 8.74252 21.7113 7.40419 21.1338C6.06585 20.5562 4.8971 19.7679 3.89794 18.7688C2.89877 17.7696 2.11044 16.6008 1.53294 15.2625C0.955437 13.9242 0.666687 12.5033 0.666687 11C0.666687 9.47833 0.955437 8.04833 1.53294 6.71C2.11044 5.37167 2.89877 4.2075 3.89794 3.2175C4.8971 2.2275 6.06585 1.44375 7.40419 0.86625C8.74252 0.28875 10.1634 0 11.6667 0C13.1884 0 14.6184 0.28875 15.9567 0.86625C17.295 1.44375 18.4592 2.2275 19.4492 3.2175C20.4392 4.2075 21.2229 5.37167 21.8004 6.71C22.3779 8.04833 22.6667 9.47833 22.6667 11C22.6667 12.5033 22.3779 13.9242 21.8004 15.2625C21.2229 16.6008 20.4392 17.7696 19.4492 18.7688C18.4592 19.7679 17.295 20.5562 15.9567 21.1338C14.6184 21.7113 13.1884 22 11.6667 22ZM11.6667 20.35C14.27 20.35 16.4792 19.4379 18.2942 17.6138C20.1092 15.7896 21.0167 13.585 21.0167 11C21.0167 8.39667 20.1092 6.1875 18.2942 4.3725C16.4792 2.5575 14.27 1.65 11.6667 1.65C9.08169 1.65 6.8771 2.5575 5.05294 4.3725C3.22877 6.1875 2.31669 8.39667 2.31669 11C2.31669 13.585 3.22877 15.7896 5.05294 17.6138C6.8771 19.4379 9.08169 20.35 11.6667 20.35Z"
                          fill="#FF5252"
                        />
                        <path
                          d="M7.54169 16.28L11.6667 12.155L15.7917 16.28L16.9467 15.125L12.8217 11L16.9467 6.875L15.7917 5.72L11.6667 9.845L7.54169 5.72L6.38669 6.875L10.5117 11L6.38669 15.125L7.54169 16.28ZM11.6667 22C10.1634 22 8.74252 21.7113 7.40419 21.1338C6.06585 20.5562 4.8971 19.7679 3.89794 18.7688C2.89877 17.7696 2.11044 16.6008 1.53294 15.2625C0.955437 13.9242 0.666687 12.5033 0.666687 11C0.666687 9.47833 0.955437 8.04833 1.53294 6.71C2.11044 5.37167 2.89877 4.2075 3.89794 3.2175C4.8971 2.2275 6.06585 1.44375 7.40419 0.86625C8.74252 0.28875 10.1634 0 11.6667 0C13.1884 0 14.6184 0.28875 15.9567 0.86625C17.295 1.44375 18.4592 2.2275 19.4492 3.2175C20.4392 4.2075 21.2229 5.37167 21.8004 6.71C22.3779 8.04833 22.6667 9.47833 22.6667 11C22.6667 12.5033 22.3779 13.9242 21.8004 15.2625C21.2229 16.6008 20.4392 17.7696 19.4492 18.7688C18.4592 19.7679 17.295 20.5562 15.9567 21.1338C14.6184 21.7113 13.1884 22 11.6667 22ZM11.6667 20.35C14.27 20.35 16.4792 19.4379 18.2942 17.6138C20.1092 15.7896 21.0167 13.585 21.0167 11C21.0167 8.39667 20.1092 6.1875 18.2942 4.3725C16.4792 2.5575 14.27 1.65 11.6667 1.65C9.08169 1.65 6.8771 2.5575 5.05294 4.3725C3.22877 6.1875 2.31669 8.39667 2.31669 11C2.31669 13.585 3.22877 15.7896 5.05294 17.6138C6.8771 19.4379 9.08169 20.35 11.6667 20.35Z"
                          fill="#FF5252"
                        />
                      </svg>
                    </IconButton>
                  </>
                ) : (
                  ""
                )}
              </div>
            )}
          </>
        ) : (
          <NoRecord />
        )}
      </div>
      {confirm ? (
        <StockDialog
          project_id={1}
          setDialog={setConfirm}
          dialog={`${BatchItems?.length} Batch Items `}
          data={dialog}
          receive_title={"ho"}
          name="confirm"
          action={(e) => HandleinventoryRequest(e, params?.id)}
          setQuantity={setQuantity}
        />
      ) : (
        ""
      )}

      {Accept ? (
        // <StockDialog
        //   setDialog={setAccept}
        //   dialog={`${dialog?.item_name} `}
        //   data={dialog}
        //   name="confirm"
        //   receive_title={"User Received"}
        //   setRemark={setRemark}
        //   action={(e) => HandleToolAccept(e, dialog)}
        // />
        <BatchAccept modal={setAccept} data={BatchItems} is_item={true} />
      ) : (
        ""
      )}
      {approvalRejected ? (
        <InventoryApprovalRejectModal
          setDialog={setApprovalRejected}
          dialog={`${dialog?.item_name} `}
          data={dialog}
          setComment={setFeedBack}
          action={(e) => handleDecline(params?.id)}
        />
      ) : (
        <></>
      )}
      {approveConfirm ? (
        <InventoryApprovalModal
          setDialog={setApproveConfirm}
          dialog={dialog?.item_name}
          length={BatchItems?.length}
          data={dialog}
          action={(e) => handleApprovals(params?.id)}
        />
      ) : (
        <></>
      )}
    </>
  );
}
