import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import InitiateForexButton from "../ForexApproval/InitiateForexButton";
import "../forex.style.css";
import usePagination from "../../../components/Pagination/Pagination";
import { Pagination } from "@mui/material";
import { styled } from "@mui/material/styles";
import EditPaymentButton from "./EditPaymentButton";
import DeletePaymentButton from "./DeletePaymentButton";
import SuccessMessage from "../SuccessMessage";
import { useGetUserInfoQuery } from "../../../features/SCMApi";
import CheckPermission from "../../common/checkPermission/checkPermission";

const TCell = styled(TableCell)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "10.5px",
  lineHeight: "12px",
  color: "#353945",
  width: "100px",
  padding: "0 5px 0 0",
}));
const TCellHeader = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#ececec",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "11px",
  lineHeight: "12px",
  color: "#353945",
  padding: "1px",
}));
const TotalDisplay = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "20px",
  lineHeight: "28px",
  color: "#FFFFFF",
  backgroundColor: "#186569",
  width: "250px",
  height: "50px",
  borderRadius: "5px",
  marginLeft: "auto",
  marginRight: "40px",
}));

export default function EnhancedTable(props) {
  const [successMessage, setSuccessMessage] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const { data: userinfo } = useGetUserInfoQuery();

  successMessage &&
    setTimeout(() => {
      setSuccessMessage(false);
    }, 3000);

  let counter = 0;
  const grandTotals = [];
  for (const total in props?.data) {
    grandTotals.push(Number(props?.data[total]?.amount));
  }

  let grandTotal =
    Math.round(
      (grandTotals
        .filter((e) => typeof e == "number")
        .reduce((prev, acc) => prev + acc, 0) +
        Number.EPSILON) *
        100
    ) / 100;
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  const headCells = [
    {
      id: "no",
      numeric: true,
      disablePadding: true,
      label: "\xa0\xa0\xa0\xa0\xa0\xa0\xa0NO",
    },
    {
      id: "vendor",
      numeric: true,
      disablePadding: false,
      label: "VENDOR\xa0\xa0\xa0\xa0\xa0\xa0",
    },
    {
      id: "product",
      numeric: true,
      disablePadding: false,
      label: "PRODUCT/SERVICE",
    },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label:
        "DESCRIPTION\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0",
    },
    {
      id: "expectedDate",
      numeric: true,
      disablePadding: false,
      label: "EXPECTED DATE",
    },
    {
      id: "amount",
      numeric: true,
      disablePadding: false,
      label: "AMOUNT\xa0\xa0\xa0\xa0",
    },
    {
      id: "wayOfPayment",
      numeric: true,
      disablePadding: false,
      label: "WAY OF PAYMENT",
    },
  ];

  function EnhancedTableHead(props) {
    const {
      // onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead
        className="paymentTableHeader"
        sx={{
          backgroundColor: "#ececec",
          color: "#353945",
        }}
      >
        <TableRow>
          {headCells.map((headCell) => (
            <TCellHeader
              key={headCell.id}
              align={headCell.numeric ? "left" : "left"}
              padding="none"
              sortDirection={orderBy === headCell.id ? order : false}
              height="50px"
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TCellHeader>
          ))}
          <TCellHeader></TCellHeader>
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    // onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const [deleteData, setDeleteData] = React.useState(null);
  const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <>
            {successMessage === true ? (
              <>
                <SuccessMessage
                  style={{ marginTop: "10px", backgroundColor: "#52ffab96" }}
                  Message={message}
                />
              </>
            ) : (
              <>
                {CheckPermission("add_receivable", userinfo) ? (
                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <InitiateForexButton
                      title="Add New"
                      name="addNew"
                      message={setMessage}
                      successMessage={setSuccessMessage}
                    />
                  </div>
                ) : (
                  <></>
                )}
                <TotalDisplay>
                  {grandTotal.toLocaleString()}
                  <span className="projectTotal">Grand Total</span>
                </TotalDisplay>
              </>
            )}
          </>
        )}

        {numSelected > 0 ? (
          <DeletePaymentButton deleteData={deleteData} />
        ) : (
          <></>
        )}
      </Toolbar>
    );
  };

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("no");
  const [selected, setSelected] = React.useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelected = props?.data?.map((n) => n.id);
  //     setSelected(newSelected);
  //     return;
  //   }
  //   setSelected([]);
  // };

  const handleClick = (event, no) => {
    const selectedIndex = selected.indexOf(no);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, no);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  let [page, setPage] = React.useState(1);
  const PER_PAGE = 5;
  const count = Math.ceil(props?.data?.length / PER_PAGE);
  const _DATA = usePagination(props?.data, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  //Delete Selected Row data
  const handleOnChange = (value) => {
    setDeleteData(value);
  };


  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{ width: "inherit" }}
            aria-labelledby="tableTitle"
            size="small"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={props?.data?.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(
                _DATA?.currentData(),
                getComparator(order, orderBy)
              )?.map((row, index) => {
                const isItemSelected = isSelected(row?.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row?.id}
                    selected={isItemSelected}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      align="center"
                      sx={{
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "10.5px",
                        lineHeight: "12px",
                        color: "#353945",
                      }}
                    >
                      {(counter += 1)}
                    </TableCell>
                    <TCell>
                      {row.forex_receivable_manufacturer?.manufacturer_name}
                    </TCell>
                    <TCell>{row.order_type}</TCell>
                    <TableCell
                      // align="left"
                      sx={{
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "10.5px",
                        lineHeight: "12px",
                        color: "#353945",
                        padding: "15px 0px 15px 1px",
                      }}
                    >
                      {row.description}
                    </TableCell>
                    {/* <TCell>{row.bank}</TCell> */}
                    <TCell>{row?.expected_date}</TCell>
                    <TCell>{Number(row?.amount)?.toLocaleString()}</TCell>
                    <TCell>
                      {row?.payment_way.includes("To Bank")
                        ? `Transfer to ${row?.forex_receivable_bank?.bank_name}`
                        : row.payment_way.includes("To Supplier")
                          ? `Transfer to ${row?.forex_receivable_supplier?.distributor_name}`
                          : ""}
                    </TCell>
                    <TableCell align="right" padding="0px">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "60px",
                        }}
                      >
                        <EditPaymentButton
                          backgroundColor="#fff"
                          editData={row}
                          message={setMessage}
                          successMessage={setSuccessMessage}
                          disabled={
                            CheckPermission("edit_receivable", userinfo)
                              ? false
                              : true
                          }
                        />
                        <DeletePaymentButton
                          disabled={
                            CheckPermission("receive_receivable", userinfo)
                              ? false
                              : true
                          }
                          receivable={row}
                          deleteData={row?.id}
                          message={setMessage}
                          successMessage={setSuccessMessage}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {count > 1 ? (
          <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              margin: "5px",
              paddingBottom: "15px",
              paddingTop: "15px",
            }}
          />
        ) : (
          ""
        )}
      </Paper>
    </Box>
  );
}
