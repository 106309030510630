import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import PerformanceGuarantee from "./PerformanceGuarantee";
import AdvancePaymentGuarantee from "./Summary";
import CreateGuarantee from "./CreateGuarantee";
import {
  useGetAllClearedGuaranteeQuery,
  useGetAllGuaranteeQuery,
} from "../../../features/SCMApi";
import NoRecord from "../../common/NoRecord";

export default function ClearedGuaranteeIndex(props) {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [createGuarantee, setCreateGuarantee] = React.useState(false);
  function handleCreateGuarantee() {
    setCreateGuarantee(true);
  }
  const { data: guarantee } = useGetAllClearedGuaranteeQuery();

  return (
    <div className="guarantee-container p-3">
      {guarantee?.data.length != 0 ? (
        <>
          {Object.keys(guarantee == undefined ? [] : guarantee?.data).map(
            (key, index) => (
              <Accordion
                expanded={expanded === index}
                onChange={handleChange(index)}
                sx={{ background: "#EFF2F3" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ width: "33%", flexShrink: 0 }}>
                    {key}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <PerformanceGuarantee
                    data={guarantee?.data[key]}
                    cleared={true}
                  />
                </AccordionDetails>
              </Accordion>
            )
          )}
        </>
      ) : (
        <NoRecord />
      )}

      {createGuarantee ? (
        <CreateGuarantee modal={setCreateGuarantee} all={true} />
      ) : (
        ""
      )}
    </div>
  );
}
