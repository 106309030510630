import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  useGetApproverQuery,
  useGetOrderPoItemQuery,
  useGetPRFQuery,
  useGetCurrenciesQuery,
  useGetProjectPRFQuery,
  useGetProjectQuery,
  useGetProjectRolePermissionQuery,
  useGetUserInfoQuery,
  useGetUsersQuery,
  useShowPRFQuery,
} from "../../../features/SCMApi";
import { useState } from "react";
import { UpgradeOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import GeneratePRF from "../GeneratePRF";
import UpdatePRF from "../UpdatePRF";
import axios from "axios";
import { API_BASE_URL } from "../../../api/endPoint";
import CheckProjectPermission from "../../common/checkPermission/CheckProjectPermission";
import "../../../pages/Payment/Payable/aging.css";
import { ClipLoader } from "react-spinners";

export default function PrfDataContainer({ prf_id }) {
  const params = useParams();
  const prfID = useParams().prf_id;

  const po_id = useParams().id;

  const [is_paid, setPaid] = useState(false);
  const [cancelResponse, setResponse] = useState([]);
  const [creditNote_confirmed, setCreditNoteConfirmed] = useState(false);
  const [creditNote_declined, setCreditNoteDeclined] = useState(false);
  const [is_returned, setReturned] = useState(false);
  const [is_creditNote, setCreditNote] = useState(false);
  const [service_received, setServiceReceived] = useState(false);
  const [showPaidModal, setShowPaidModal] = useState(false);
  const [showServiceReceivedModal, setShowServiceReceivedModal] =
    useState(false);
  const [showReturnedModal, setShowReturnedModal] = useState(false);
  const [showCreditNoteModal, setShowCreditNoteModal] = useState(false);
  const [showCreditNoteConfirmation, setShowCreditNoteConfirmation] =
    useState(false);
  const [confirmModalType, setConfirmModalType] = useState(null);
  const [reason, setReason] = useState();
  const [creditNoteIssuer, setCreditNoteIssuer] = useState();
  const [prfLoading, setPrfLoading] = useState(true);

  const { data: orderpo } = useGetOrderPoItemQuery(params.id);
  const {
    data: Prf,
    isLoading,
    isError,
  } = useShowPRFQuery(prf_id ? prf_id : params?.prf_id, {
    onSuccess: () => {
      setPrfLoading(false);
    },
    onError: () => {
      setPrfLoading(false);
    },
  });

  useEffect(() => {
    if (isLoading || isError) {
      setPrfLoading(true);
    } else {
      setPrfLoading(false);
    }
  }, [isLoading, isError]);

  const [generatePrf, setGeneratePRF] = useState(false);
  const [messages, setMessages] = useState(null);
  const [creditNoteResponse, setCreditNoteResponse] = useState();
  const [supplier_id, setSupplierId] = useState(Prf?.data?.order?.supplier?.id);
  const [creditNoteAmount, setCreditNoteAmount] = useState(
    Prf?.data?.total_payment
  );

  const currenciesList = [];
  const { data: currencies } = useGetCurrenciesQuery();
  currencies?.data?.map((items) =>
    currenciesList.push({
      value: items?.id,
      label: items?.code,
    })
  );
  useEffect(() => {
    setSupplierId(Prf?.data?.order?.supplier?.id);
    setCreditNoteAmount(Prf?.data?.total_payment);
  }, [Prf?.data?.order?.supplier?.id, Prf?.data?.total_payment]);

  const { data: poprf } = useGetProjectPRFQuery(params?.project_id);



  const { data: users } = useGetUsersQuery();
  const creditNote = {
    po_id: po_id,
    prf_id: prfID,
    supplier_id: supplier_id,
    creditNoteAmount: creditNoteAmount,
    reason: reason,
    creditNoteIssuer: creditNoteIssuer,
  };
  const cancelCreditNoteData = {
    supplier_id: supplier_id,
    po_id: po_id,
    creditNoteAmount: creditNoteAmount,
    creditNote_declined: creditNote_declined,
    creditNote_confirmed: creditNote_confirmed,
  };

  useEffect(() => {}, [
    is_paid,
    creditNote_confirmed,
    creditNote_declined,
    is_returned,
    is_creditNote,
    service_received,
  ]);

  const buttonData = {
    po_id: po_id,
    creditNote_declined: creditNote_declined,
    creditNote_confirmed: creditNote_confirmed,
    amount: creditNoteAmount,
    is_paid: is_paid,
    is_returned: is_returned,
    is_creditNote: is_creditNote,
    service_received: service_received,
    supplier_id: supplier_id,
  };

  function GeneratePRFModal() {
    setGeneratePRF(true);
  }
  const { data: getApprover } = useGetApproverQuery({
    module_name: "PRF",
    id: params?.prf_id,
  });

  const [showDownloadButton, setShowDownloadButton] = useState(
    Prf && Prf?.data?.is_paid === 1
      ? true
      : false || (Prf && Prf?.data?.is_returned === 1)
        ? true
        : false || (Prf && Prf?.data?.service_received === 1)
          ? true
          : false ||
              (Prf &&
                Prf?.data?.is_advanced === 1 &&
                Prf?.data?.is_creditNote === 1)
            ? true
            : false || (Prf && Prf?.data?.creditNote_declined === 1)
              ? true
              : false || (Prf && Prf?.data?.creditNote_confirmed === 1)
                ? true
                : false
  );

  const sendPatchRequest = async (prfID, updatedButtonData) => {

    try {
      await axios
        .post(`${API_BASE_URL}/scm/prf_paid/${prfID}`, updatedButtonData)
        .then((response) => {

        });
      setShowDownloadButton(true);
    } catch (error) {
      throw error;
    }
  };
  const handleCreditNote = async (prfID) => {
    try {
      await axios
        .post(`${API_BASE_URL}/scm/payment/prf/credit_note`, creditNote)
        .then((response) => {
          setCreditNoteResponse(response?.data);
        });
      setShowDownloadButton(true);
    } catch (error) {
      throw error;
    }
  };

  const handleCancelCN = async (prfID, updatedCreditNoteData) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/scm/prf_creditnote/${prfID}`,
        updatedCreditNoteData
      );


      setShowDownloadButton(true);
      setResponse(response?.data?.data);
    } catch (error) {
      console.error("Error in cancelCreditNote API:", error);
    }
  };

  const isCreditNote = Prf?.data?.is_creditNote === 1;

  useEffect(() => {
    if (
      Prf?.data?.is_paid ||
      Prf?.data?.creditNote_declined ||
      (Prf?.data?.is_advanced === 1 && Prf?.data?.is_creditNote === 1) ||
      Prf?.data?.creditNote_confirmed ||
      Prf?.data?.is_returned ||
      Prf?.data?.service_received === 1
    ) {
      setShowDownloadButton(true);
    } else {
      setShowDownloadButton(false);
    }
  }, [Prf]);

  const { data: projectmembers } = useGetProjectQuery(params?.project_id);
  const { data: roleRoles } = useGetProjectRolePermissionQuery(
    params?.project_id
  );
  const { data: userinfo } = useGetUserInfoQuery();
  const [showCancelCreditNoteModal, setShowCancelCreditNoteModal] =
    useState(false);
  const [showCreditNoteModall, setShowCreditNoteModall] = useState(false);

  const handleConfirm = (modalType) => {
    setConfirmModalType(modalType);

    if (modalType === "paid") {
      setShowPaidModal(true);
    } else if (modalType === "serviceReceived") {
      setShowServiceReceivedModal(true);
    } else if (modalType === "returned") {
      setShowReturnedModal(true);
    } else if (modalType === "creditNote") {
      setShowCreditNoteModal(true);
    } else if (modalType === "cancelCreditNote") {
      setShowCancelCreditNoteModal(true);
    } else if (modalType === "confirmCreditNote") {
      setShowCreditNoteModall(true);
    }
  };

  const handleConfirmCreditNote = () => {
    handleCreditNote(prfID);
    setShowCreditNoteConfirmation(false);
  };

  const handleCancelCreditNote = (prfID) => {
    let updatedCreditNoteData = { ...cancelCreditNoteData };

    if (confirmModalType === "cancelCreditNote") {
      updatedCreditNoteData.creditNote_declined = true;
    } else if (confirmModalType === "confirmCreditNote") {
      updatedCreditNoteData.creditNote_confirmed = true;
    }

    handleCancelCN(prfID, updatedCreditNoteData);
    setConfirmModalType(null);

    setShowCreditNoteConfirmation(false);
  };

  const handleConfirmation = (prfID) => {
    let updatedButtonData = { ...buttonData };

    if (confirmModalType === "paid") {
      setPaid(true);
      updatedButtonData.is_paid = true;
    } else if (confirmModalType === "serviceReceived") {
      setServiceReceived(true);
      updatedButtonData.service_received = true;
    } else if (confirmModalType === "returned") {
      setReturned(true);
      updatedButtonData.is_returned = true;
    } else if (confirmModalType === "creditNote") {
      setCreditNote(true);
      updatedButtonData.is_creditNote = true;
    }

    sendPatchRequest(prfID, updatedButtonData);

    setConfirmModalType(null);
    setShowServiceReceivedModal(false);
    setShowPaidModal(false);
    setShowReturnedModal(false);
    setShowCreditNoteModal(false);
  };

  const handleCancel = () => {
    setShowServiceReceivedModal(false);
    setShowPaidModal(false);
    setShowReturnedModal(false);
    setShowCreditNoteModal(false);
    setShowCreditNoteModall(false);
    setShowCancelCreditNoteModal(false);
  };

  const handleCreditNoteConfirmation = () => {
    setShowCreditNoteConfirmation(true);
  };
  const handleCreditNoteCancel = () => {
    setShowCreditNoteConfirmation(false);
  };

  const isAdvanced = Prf?.data?.is_advanced === 1;
  const isLLC = Prf?.data?.isLLC === 1;


  

  return (
    <div>
      <>
        {getApprover?.data?.current[0]?.prf_approval == 0 ? (
          <button
            type="submit"
            className="sourcing-postage-big-btn"
            onClick={GeneratePRFModal}
          >
            <UpgradeOutlined />
            Update PRF Information
          </button>
        ) : (
          ""
        )}
      </>

      <div className="card mt-3">
        <div className="text-center h5 mt-2 mb-0">PRF Information</div>
        <div className="">
          <div className={!prf_id ? "d-flex ms-5 gap-3" : "d-flex ms-1 gap-1"}>
            <div className="payment-text mt-2 mb-0">PRF Number</div>
            <div className="payment-text-bold mt-2 mb-0">
              {Prf?.data != null && Prf?.data?.prf_number != null
                ? "IE-PRF-" + (Prf && Prf.data && Prf.data.prf_number)
                : " Not Avaliable"}
            </div>
          </div>
          <div className={!prf_id ? "d-flex ms-5 gap-3" : "d-flex ms-1 gap-1"}>
            <div className="payment-text mt-2 mb-0">Mode of Payment</div>
            <div className="payment-text-bold mt-2 mb-0">
              {Prf?.data != null && Prf?.data?.payment_mode != null
                ? Prf?.data?.payment_mode?.payment_mode
                : " Not Avaliable"}
            </div>
          </div>
          <div className={!prf_id ? "d-flex ms-5 gap-3" : "d-flex ms-1 gap-1"}>
            <div className="payment-text mt-2 mb-0">Payable to</div>

            <Tooltip title={Prf?.data?.order?.supplier?.distributor_name}>
              <p className="payment-text-bold mt-2 mb-0">
                {Prf?.data != null && Prf?.data?.bill_to != null
                  ? Prf?.data?.order?.supplier?.distributor_name?.length >= 15
                    ? Prf?.data?.order?.supplier?.distributor_name.slice(
                        0,
                        15
                      ) + "..."
                    : Prf?.data?.order?.supplier?.distributor_name
                  : " Not Avaliable"}
              </p>
            </Tooltip>
          </div>
          <div className={!prf_id ? "d-flex ms-5 gap-3" : "d-flex ms-1 gap-1"}>
            <div className="payment-text mt-2 mb-0">Reason For</div>

            <Tooltip title={Prf?.data?.order?.supplier?.distributor_name}>
              <p className="payment-text-bold mt-2 mb-0">
                {Prf?.data != null && Prf?.data?.reason_for != null
                  ? Prf?.data?.reason_for?.length >= 15
                    ? Prf?.data?.reason_for.slice(0, 15) + "..."
                    : Prf?.data?.reason_for
                  : " Not Avaliable"}
              </p>
            </Tooltip>
          </div>
          <div className="divider"></div>
          <div className={!prf_id ? "d-flex ms-5 gap-3" : "d-flex ms-1 gap-1"}>
            <div className="payment-text mt-2 mb-0">Requested By</div>
            <div className="payment-text-bold mt-2 mb-0">
              {Prf?.data != null && Prf?.data?.requested_by != null
                ? Prf?.data?.requested_by?.name
                : " Not Avaliable"}
            </div>
          </div>
          <div>
            <div
              className={!prf_id ? "d-flex ms-5 gap-3" : "d-flex ms-1 gap-1"}
            >
              <div className="payment-text mt-2 mb-0">Prepared By</div>
              <div className="payment-text-bold mt-2 mb-0">
                {Prf?.data != null && Prf?.data?.prepared_by != null
                  ? Prf?.data?.prepared_by?.name
                  : " Not Avaliable"}
              </div>
            </div>
            <div
              className={!prf_id ? "d-flex ms-5 gap-3" : "d-flex ms-1 gap-1"}
            >
              <div className="payment-text mt-2 mb-0">Approved By</div>
              <div className="payment-text-bold mt-2 mb-0">
                {Prf?.data != null && Prf?.data?.approved_by != null
                  ? Prf?.data?.approved_by?.name
                  : " Not Avaliable"}
              </div>
            </div>
            <div
              className={!prf_id ? "d-flex ms-5 gap-3" : "d-flex ms-1 gap-1"}
            >
              <div className="payment-text mt-2 mb-0">Paid By</div>
              <div className="payment-text-bold mt-2 mb-0">
                {Prf?.data != null && Prf?.data?.paid_by != null
                  ? Prf?.data?.paid_by?.name
                  : " Not Avaliable"}
              </div>
            </div>{" "}
            {getApprover?.data?.current[0]?.prf_approval == 1 ? (
              <div class="d-flex justify-content-center mb-2">
                {prfLoading ? (
                  <div className="justify-content-center ">
                    <ClipLoader size={"10px"} />
                    <div className="prg">Please Wait</div>
                  </div>
                ) : (
                  <>
                    {showDownloadButton === false &&
                    CheckProjectPermission(
                      "create_order",
                      projectmembers,
                      userinfo,
                      roleRoles
                    ) &&
                    !isAdvanced &&
                    !isCreditNote &&
                    !isLLC ? (
                      <div>
                        <button
                          className="form-save d-flex justify-content-center align-items-center mt-3"
                          onClick={() => handleConfirm("paid")}
                        >
                          Mark as Paid
                        </button>
                        {showPaidModal && (
                          <div className="modal-container">
                            <div
                              className="modal-dialog modal-dialog-centered"
                              role="document"
                            >
                              <div
                                className="modal-content "
                                style={{ minHeight: "0", width: "72%" }}
                              >
                                <div class="modal-header">
                                  <h5 className="modal-title">Confirm paid?</h5>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    onClick={handleCancel}
                                  ></button>
                                </div>

                                <div className="modal-body">
                                  <p>
                                    Are you sure you want to mark this as paid?
                                  </p>
                                </div>

                                <div className="d-flex flex-row form-buttons modal-footer">
                                  <button
                                    className="form-save"
                                    onClick={() => handleConfirmation(prfID)}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    className="form-cancel"
                                    onClick={handleCancel}
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
                {showDownloadButton == false &&
                CheckProjectPermission(
                  "create_order",
                  projectmembers,
                  userinfo,
                  roleRoles
                ) &&
                isAdvanced ? (
                  <div className="d-flex gap-2">
                    <div>
                      <button
                        className="form-save d-flex justify-content-center align-items-center mt-3"
                        onClick={() => handleConfirm("serviceReceived")}
                      >
                        Service received
                      </button>
                      {showServiceReceivedModal && (
                        <div className="modal-container">
                          <div
                            className="modal-dialog modal-dialog-centered"
                            role="document"
                          >
                            <div
                              className="modal-content "
                              style={{ minHeight: "0", width: "72%" }}
                            >
                              <div class="modal-header">
                                <h5 className="modal-title">
                                  Confirm service received?
                                </h5>
                                <button
                                  type="button"
                                  className="btn-close"
                                  onClick={handleCancel}
                                ></button>
                              </div>
                              <div className="modal-body">
                                <p>
                                  Are you sure you want to mark this as service
                                  received?
                                </p>
                              </div>

                              <div className="d-flex flex-row form-buttons modal-footer">
                                <button
                                  className="form-save"
                                  onClick={() => handleConfirmation(prfID)}
                                >
                                  Yes
                                </button>
                                <button
                                  className="form-cancel"
                                  onClick={handleCancel}
                                >
                                  No
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div>
                      <button
                        className="form-save d-flex justify-content-center align-items-center mt-3"
                        onClick={() => handleConfirm("returned")}
                      >
                        Returned
                      </button>
                      {showReturnedModal && (
                        <div className="modal-container">
                          <div
                            className="modal-dialog modal-dialog-centered"
                            role="document"
                          >
                            <div
                              className="modal-content "
                              style={{ minHeight: "0", width: "72%" }}
                            >
                              <div class="modal-header">
                                <h5 className="modal-title">
                                  Confirm returned?
                                </h5>
                                <button
                                  type="button"
                                  className="btn-close"
                                  onClick={handleCancel}
                                ></button>
                              </div>

                              <div className="modal-body">
                                <p>
                                  Are you sure you want to mark this as
                                  returned?
                                </p>
                              </div>

                              <div className="d-flex flex-row form-buttons modal-footer">
                                <button
                                  className="form-save"
                                  onClick={() => handleConfirmation(prfID)}
                                >
                                  Yes
                                </button>
                                <button
                                  className="form-cancel"
                                  onClick={handleCancel}
                                >
                                  No
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div>
                      <button
                        className="form-save d-flex justify-content-center align-items-center mt-3"
                        onClick={() => handleConfirm("creditNote")}
                      >
                        Credit Note
                      </button>
                      {showCreditNoteModal && (
                        <div className="modal-container">
                          <div
                            className="modal-dialog modal-dialog-centered"
                            role="document"
                          >
                            <div
                              className="modal-content "
                              style={{ minHeight: "0", width: "72%" }}
                            >
                              <div class="modal-header">
                                <h5 className="modal-title">
                                  Confirm credit note?
                                </h5>
                                <button
                                  type="button"
                                  className="btn-close"
                                  onClick={handleCancel}
                                ></button>
                              </div>
                              <div className="modal-body">
                                <div
                                  style={{ marginBottom: "5px" }}
                                  className="d-flex gap-2 align-items-baseline prg"
                                >
                                  <div
                                    className="supplier-input-label"
                                    style={{ minWidth: "110px" }}
                                  >
                                    Supplier Name:
                                  </div>
                                  {Prf?.data?.order?.supplier?.distributor_name}
                                </div>
                                <div
                                  style={{ marginBottom: "5px" }}
                                  className="d-flex gap-2 align-items-baseline prg"
                                >
                                  <div
                                    className="supplier-input-label"
                                    style={{ minWidth: "110px" }}
                                  >
                                    Amount:
                                  </div>
                                  {Prf?.data?.total_payment.toLocaleString()}
                                </div>
                                {/* <div
                                style={{ marginBottom: "5px" }}
                                className="d-flex gap-2 align-items-baseline"
                              >
                                <div
                                  className="supplier-input-label"
                                  style={{ minWidth: "110px" }}
                                >
                                  Currency:
                                </div>
                              </div> */}

                                <div
                                  style={{ marginBottom: "5px" }}
                                  className="d-flex gap-2 align-items-baseline prg"
                                >
                                  <div
                                    className="supplier-input-label"
                                    style={{ minWidth: "110px" }}
                                  >
                                    Credit Note Issuer
                                  </div>
                                  {/* <select
                                  value={creditNoteIssuer}
                                  name=""
                                  id=""
                                  className="supplier-select-option"
                                  required
                                  onChange={(e) =>
                                    setCreditNoteIssuer(e.target.value)
                                  }
                                >
                                  <option value="" disabled selected hidden>
                                    Choose issuer
                                  </option>
                                  {users?.data?.map((items) => (
                                    <option value={items.id}>
                                      {items.name}
                                    </option>
                                  ))}
                                </select> */}
                                  {Prf?.data?.requested_by?.name}
                                </div>

                                <div
                                  style={{ marginBottom: "5px" }}
                                  className="d-flex gap-2 align-items-baseline"
                                >
                                  <div
                                    className="supplier-input-label"
                                    style={{ minWidth: "110px" }}
                                  >
                                    Reason
                                  </div>
                                  <input
                                    style={{ minWidth: "110px" }}
                                    id="reason"
                                    name="reason"
                                    type="text"
                                    className={`supplier-text-input`}
                                    value={reason}
                                    onChange={(e) => setReason(e.target.value)}
                                  />
                                </div>
                              </div>

                              <div className="d-flex flex-row form-buttons modal-footer">
                                <button
                                  className="form-save"
                                  onClick={() => handleCreditNoteConfirmation()}
                                >
                                  Yes
                                </button>
                                <button
                                  className="form-cancel"
                                  onClick={handleCancel}
                                >
                                  No
                                </button>
                              </div>
                              {showCreditNoteConfirmation && (
                                <div className="modal-container">
                                  <div
                                    className="modal-dialog modal-dialog-centered"
                                    role="document"
                                  >
                                    <div
                                      className="modal-content "
                                      style={{ minHeight: "0", width: "72%" }}
                                    >
                                      <div class="modal-header">
                                        <h5 className="modal-title">
                                          Confirm credit note?
                                        </h5>
                                        <button
                                          type="button"
                                          className="btn-close"
                                          onClick={handleCancel}
                                        ></button>
                                      </div>
                                      <div className="modal-body">
                                        <p>
                                          Are you sure you want to mark this as
                                          credit note?
                                        </p>
                                      </div>

                                      <div className="d-flex flex-row form-buttons modal-footer">
                                        <button
                                          className="form-save"
                                          onClick={handleConfirmCreditNote}
                                        >
                                          Yes
                                        </button>

                                        <button
                                          className="form-cancel"
                                          onClick={handleCreditNoteCancel}
                                        >
                                          No
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {showDownloadButton === false &&
                CheckProjectPermission(
                  "create_order",
                  projectmembers,
                  userinfo,
                  roleRoles
                ) &&
                !isAdvanced &&
                isCreditNote ? (
                  <div className="d-flex gap-2">
                    <div>
                      <button
                        className="form-save d-flex justify-content-center align-items-center mt-3"
                        onClick={() => handleConfirm("confirmCreditNote")}
                      >
                        Confirm
                      </button>
                      {showCreditNoteModall && (
                        <div className="modal-container">
                          <div
                            className="modal-dialog modal-dialog-centered"
                            role="document"
                          >
                            <div
                              className="modal-content "
                              style={{ minHeight: "0", width: "72%" }}
                            >
                              <div class="modal-header">
                                <h5 className="modal-title">
                                  Confirm Credit Note?
                                </h5>
                                <button
                                  type="button"
                                  className="btn-close"
                                  onClick={handleCancel}
                                ></button>
                              </div>
                              <div className="modal-body">
                                <p>
                                  Are you sure you want to mark this as Credit
                                  Note?
                                </p>
                              </div>

                              <div className="d-flex flex-row form-buttons modal-footer">
                                <button
                                  className="form-save"
                                  onClick={() => handleCancelCreditNote(prfID)}
                                >
                                  Yes
                                </button>
                                <button
                                  className="form-cancel"
                                  onClick={handleCancel}
                                >
                                  No
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div>
                      <button
                        className="form-save d-flex justify-content-center align-items-center mt-3"
                        onClick={() => handleConfirm("cancelCreditNote")}
                      >
                        Cancel
                      </button>
                      {showCancelCreditNoteModal && (
                        <div className="modal-container">
                          <div
                            className="modal-dialog modal-dialog-centered"
                            role="document"
                          >
                            <div
                              className="modal-content "
                              style={{ minHeight: "0", width: "72%" }}
                            >
                              <div class="modal-header">
                                <h5 className="modal-title">
                                  Cancel Credit Note?
                                </h5>
                                <button
                                  type="button"
                                  className="btn-close"
                                  onClick={handleCancel}
                                ></button>
                              </div>
                              <div className="modal-body">
                                <p>
                                  Are you sure you want to cancel this as Credit
                                  Note?
                                </p>
                              </div>

                              <div className="d-flex flex-row form-buttons modal-footer">
                                <button
                                  className="form-save"
                                  onClick={() => handleCancelCreditNote(prfID)}
                                >
                                  Yes
                                </button>
                                <button
                                  className="form-cancel"
                                  onClick={handleCancel}
                                >
                                  No
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {/* showDownloadButton === false && isLLC &&
                CheckProjectPermission("create_order", projectmembers, userinfo,
                roleRoles) && !isAdvanced && !isCreditNote */}
                {showDownloadButton === false &&
                  isLLC &&
                  // CheckProjectPermission(
                  //   "create_order",
                  //   projectmembers,
                  //   userinfo,
                  //   roleRoles
                  // ) &&
                  !isAdvanced &&
                  !isCreditNote && <GeneratePRF data={Prf?.data} />}
                {showDownloadButton ? <GeneratePRF data={Prf?.data} /> : ""}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {generatePrf ? (
        <UpdatePRF
          order_id={params?.id}
          modal={setGeneratePRF}
          setMessages={setMessages}
          data={Prf?.data}
          isApproved={orderpo?.data[0]?.po_approval == 1}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
