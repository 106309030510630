import React from "react";
import AssetsHistory from "./AssetsHistory";
import ToolHistory from "./AssetsHistory";
import AssetsItemDescription from "./AssetsItemDescription";
import ToolItemDescription from "./AssetsItemDescription";

export default function AssetDetail(props) {
  
  return (
    <div>
      <AssetsItemDescription
        isproject={props.isproject}
        setFragment={props?.setFragment}
        data={props?.data}
      />
      <AssetsHistory data={props?.data} />
    </div>
  );
}
