import React from "react";

export default function EditPaymentModal(props) {
  return (
    <>
      <div className="mini-modal" onClick={(e) => props.ToggleModal(false)}>
        <div
          className="mini-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="create-project-modal-title">
            <div>Edit Payment Information</div>
            <div
              className="close-modal-button"
              onClick={(e) => props?.ToggleModal(false)}
            >
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.4612 5.78721L19.8657 7.19175L7.46095 19.5965L6.0564 18.192L18.4612 5.78721ZM7.17507 5.51376L20.3505 18.6891L18.8589 20.1807L5.68351 7.00531L7.17507 5.51376Z"
                  fill="#353945"
                />
              </svg>
            </div>
          </div>
          <div className="form-card-container mb-4">
            <div className="input-group-fine">
              <div className="d-flex gap-2 align-items-baseline">
                <div className="supplier-input-label">Mode of Payment</div>
                <select name="" id="" className="select-option"></select>
              </div>

              <div className="d-flex gap-2 align-items-baseline">
                <div className="supplier-input-label">Terms of Payment</div>
                <select name="" id="" className="select-option"></select>{" "}
              </div>

              <div className="d-flex gap-2 align-items-baseline">
                <div className="supplier-input-label">Incoterm</div>
                <select name="" id="" className="select-option"></select>{" "}
              </div>
            </div>
            <div className="form-buttons">
              <button
                type="cancel"
                className="form-cancel"
                onClick={(e) => {
                  props.ToggleModal(false);
                }}
              >
                {" "}
                Cancel
              </button>
              <button
                type="submit"
                className="form-save"
                // onClick={(event) => handleSubmit(event)}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
