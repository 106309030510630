import { Tooltip } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  useRequestForGrnMutation,
  useApprovalPoMutation,
  useGetOrderPoItemQuery,
  useGetProjectQuery,
  useGetProjectRolePermissionQuery,
  useGetUserInfoQuery,
  useGetApproverQuery,
  useSendForApproverMutation,
  useApprovalApproveMutation,
  useApprovalRejectMutation,
  useApprovalRetractMutation,
  useGetPRFByOrderIdMutation,
} from "../../features/SCMApi";
// import CheckPermission from "../common/checkPermission/checkPermission";
import CheckProjectPermission from "../common/checkPermission/CheckProjectPermission";
import NoRecord from "../common/NoRecord";
import { API_BASE_URL } from "../../api/endPoint";
import axios from "axios";
import SendToInventoryModal from "../Logistics/DeliveredItem/DeliveredItemModals/SendToInventoryModal";
import IsOnShoreModal from "./SourcingModal/IsOnShoreModal";
import RequestGrnModal from "../../pages/GRN/RequestGrnModal";
import AutoClosePopup from "../../pages/GRN/SuccessMessage/AutoClosePopup ";

export default function EtaPoDetail(props) {
  const params = useParams();
  const [listOfPrf, setListOfPrf] = useState([]);
  const { data: orderpo } = useGetOrderPoItemQuery(
    props?.order_id ? props?.order_id : params.id
  );
  const payment_information_id = orderpo?.data[0]?.payment_information_id;
  let count = 1;
  const [show, setShow] = useState();
  let total_line_price = 0;
  const orderpos = orderpo?.data?.map((items) => items.po);

  const projectid = orderpo?.data[0]?.project_id;
  const po_id = orderpo?.data[0]?.id;
  const po_number = orderpo?.data[0]?.po_number;

  const [getPRFByOrderId] = useGetPRFByOrderIdMutation();
  const [requestForGrn] = useRequestForGrnMutation();
  const [sendForApprover] = useSendForApproverMutation();
  const [approvalApprove] = useApprovalApproveMutation();
  const [approvalReject] = useApprovalRejectMutation();
  const [approvalRetract] = useApprovalRetractMutation();
  const { data: projectmembers, isSuccess } = useGetProjectQuery(projectid);
  const { data: roleRoles } = useGetProjectRolePermissionQuery(projectid);
  const { data: userinfo } = useGetUserInfoQuery();
  const [viewListOfPrf, setViewListOfPrf] = useState(false);
  const [requestPrfId, setRequestPrfId] = useState(null);

  const [message, setMessage] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(false);
  const [type, setType] = React.useState("success");

  let approvals = {
    po_id: po_id,
  };

  useEffect(() => {
    getPRFByOrderId(params.id)
      .unwrap()
      .then((response) => {
        setListOfPrf(response?.data);
      })
      .then((error) => {});
  }, []);

  const isPO = orderpo?.data[0]?.status == 0 ? "PO" : "";

  const [approval, rep] = useApprovalPoMutation();
  // const HandleApproval = () => {
  //   approval(approvals)
  //     .unwrap()
  //     .then((response) => {

  //       props.setMessages("PO Approved Successfully");
  //       setConfirmation(false);
  //     })
  //     .then((error) => {

  //     });
  // };

  const [confirmation, setConfirmation] = useState(false);
  const [retractConfirmation, setRetractConfirmation] = useState(false);
  function confirmationHandler() {
    setConfirmation(true);
  }
  function retractConfirmationHandler() {
    setRetractConfirmation(true);
  }
  // function sendRequestToInventory(id){

  //        requestForGrn(id);
  // }
  //Approval detail implementation
  const { data: loggedUser } = useGetUserInfoQuery();
  const poId = props?.poData && props?.poData;
  const { data: getApprover } = useGetApproverQuery({
    module_name: props?.isPO,
    id: poId,
  });
  let currentApproverUser =
    loggedUser &&
    getApprover &&
    getApprover?.data?.current.find((user) => user?.user_id === loggedUser?.id);
  let isPaApproved =
    getApprover?.data?.current &&
    getApprover?.data?.current.find((paData) => paData?.po_approval == 1)
      ?.po_approval == 1
      ? true
      : getApprover?.data?.current.find((paData) => paData?.po_approval == 0)
            ?.po_approval == 0
        ? false
        : getApprover?.data?.current.find((paData) => paData?.po_approval == 2)
              ?.po_approval == 2
          ? null
          : "";

  let feedBack =
    getApprover &&
    getApprover?.data?.history.find((user) => user?.feedback)?.feedback
      ? getApprover?.data?.history.find((user) => user?.feedback)?.feedback
      : false;

  let isApprover = currentApproverUser !== undefined ? true : false;
  // setTimeout(() => {
  //   props?.setApprovalStatus(paApprovalStatus);
  // }, 3000);

  const [feedback, setFeedBack] = useState("");

  let dataForApproval = {
    module_name: props?.isPO,
    module_id: poId,
    user_id: loggedUser?.id,
    feedback,
  };

  const [buttonDisable, setButtonDisable] = useState(false);
  const handleApprovals = () => {
    setButtonDisable(true);
    approvalApprove(dataForApproval)
      .unwrap()
      .then((response) => {
        setConfirmation(false);
        setButtonDisable(false);
      })
      .then((error) => {
        setButtonDisable(false);
      });
    // axios
    //   .post(`${API_BASE_URL}/scm/approval/approve`, dataForApproval)
    //   .then((response) => {
    //     setConfirmation(false);

    //   })
    //   .catch((err) =>

    //   );
  };
  const handleDecline = () => {
    setButtonDisable(true);

    approvalReject(dataForApproval)
      .unwrap()
      .then((response) => {
        setConfirmation(false);
        setButtonDisable(false);

        setShow(false);
      })
      .then((error) => {
        setButtonDisable(false);
      });
    // axios
    //   .post(`${API_BASE_URL}/scm/approval/reject`, dataForApproval)
    //   .then((response) => {
    //     setConfirmation(false);

    //   })
    //   .catch((err) =>

    //   );
  };

  let finalApprovedd =
    isPaApproved == 1 ? true : isPaApproved == 0 ? false : null;

  //Retract approval functionality
  let currentLevel = getApprover?.data?.current[0]?.level;

  let approvedHistoryForCurrentApprover = getApprover?.data?.history?.find(
    (user) => user?.approval_type == 0 && user?.user_id == loggedUser?.id
  );

  let canRetract =
    approvedHistoryForCurrentApprover?.level + 1 === currentLevel
      ? true
      : false;

  const handleRetract = () => {
    setButtonDisable(true);

    approvalRetract(dataForApproval)
      .unwrap()
      .then((response) => {
        // props.setMessages("Approved Successfully");
        setRetractConfirmation(false);
        setButtonDisable(false);
      })
      .then((error) => {
        setButtonDisable(false);
      });
    // axios
    //   .post(`${API_BASE_URL}/scm/approval/retract`, dataForApproval)
    //   .then((response) => {
    //     setRetractConfirmation(false);

    //   })
    //   .catch((err) =>

    //   );
  };

  let approvalstage = getApprover && getApprover?.data?.current.length === 0;

  let PoApproved = {
    pa_id: poId,
    // profit: props?.profit,
  };

  const [sendToInventory, setSendToInventory] = React.useState(false);

  const [checkIsOffShore, setCheckIsOffShore] = React.useState(false);
  const IsOffShoreModalHandler = (e) => {
    setCheckIsOffShore(!checkIsOffShore);
  };

  function SendToInventoryModalHandler() {
    setSendToInventory(true);
  }

  const isOffShore = orderpo?.data[0]?.order?.is_offshore;

  const HandleSingleGrn = (prfId) => {
    requestForGrn(prfId)
      .unwrap()
      .then((response) => {
        setType("success");
        setMessage("successfully requested");
        setIsOpen(true);
      })
      .catch((error) => {
        setType("danger");
        setMessage(error?.data?.message);
        setIsOpen(true);
      });
  };
  return (
    <>
      <AutoClosePopup
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        type={type}
        message={message}
      />
      <div className={props?.order_id ? "p-3 bg-white" : ""}>
        {orderpo?.data?.length !== 0 ? (
          <>
            {orderpo?.data[0]?.order?.supplier_id != null ? (
              <div className=" summarymanufacturer-header poStage-row">
                <div className="no">No</div>
                <div>Part Number</div>
                <div className="no">Item Description</div>
                <div>qty</div>
                <div>Unit Price</div>
                <div>Total price</div>
              </div>
            ) : (
              <div className="supplier-summarymanufacturer-header supplier-poStage-row">
                <div className="no">No</div>
                <div>Part Number</div>
                <div className="no">Item Description</div>
                <div className="no">Supplier</div>
                <div>qty</div>
                <div>Unit Price</div>
                <div>Total price</div>
              </div>
            )}
            {orderpo?.data?.map((items) =>
              items?.po?.map((poitems) => (
                <div
                  className={
                    orderpo?.data[0]?.order?.supplier_id != null
                      ? "poStage-row mb-2"
                      : "supplier-poStage-row mb-2"
                  }
                >
                  <div className="label-container">
                    <div className="pa-label">NO</div>
                    <div>{count++}</div>
                  </div>
                  <div className="label-container">
                    <div className="pa-label">Part Number</div>
                    <div>
                      {poitems?.item_part_number
                        ? poitems?.item_part_number
                        : poitems?.course}
                    </div>
                  </div>
                  <div className="label-container">
                    <div className="pa-label">Item Description</div>

                    <Tooltip
                      title={
                        poitems?.item_description
                          ? poitems?.item_description
                          : poitems?.course_description
                      }
                    >
                      <div className="text-start">
                        {poitems?.item_description
                          ? poitems?.item_description?.slice(0, 50) + "..."
                          : poitems?.course_description?.slice(0, 50) + "..."}
                      </div>
                    </Tooltip>
                  </div>
                  {orderpo?.data[0]?.order?.supplier_id == null ? (
                    <div className="label-container">
                      <div className="pa-label">Supplier</div>

                      <Tooltip title={poitems?.supplier_name}>
                        <div className="text-start">
                          {poitems?.supplier_name !== null
                            ? poitems?.supplier_name?.slice(0, 70) + "..."
                            : "Unknown"}
                        </div>
                      </Tooltip>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="label-container">
                    <div className="pa-label">qty</div>
                    <div>{poitems?.qty}</div>
                  </div>
                  <div className="label-container">
                    <div className="pa-label">Unit Price</div>
                    <div>
                      {poitems?.pi_unit_cost === 0
                        ? poitems?.pi_unit_cost?.toLocaleString()
                        : poitems?.pi_unit_cost?.toLocaleString()}
                    </div>
                  </div>
                  <div className="label-container">
                    <div className="pa-label">Total price</div>
                    <div>
                      {" "}
                      {
                        ((total_line_price += poitems.pi_total_cost),
                        poitems.pi_total_cost === 0
                          ? poitems.pi_total_cost?.toLocaleString()
                          : poitems.pi_total_cost?.toLocaleString())
                      }
                    </div>
                  </div>
                </div>
              ))
            )}
            <div className="d-flex justify-content-end gap-3">
              <div className="payment-text-bold mt-2 mb-0">Sub Total</div>
              <div
                className={`payment-text-bold mt-2 mb-0 ${
                  total_line_price === 0
                    ? "text-warning"
                    : total_line_price < 0
                      ? "text-danger"
                      : total_line_price > 0
                        ? "text-success"
                        : ""
                }`}
              >
                {total_line_price === 0
                  ? total_line_price?.toLocaleString()
                  : total_line_price?.toLocaleString()}
              </div>
            </div>
          </>
        ) : (
          <NoRecord />
        )}

        {isPaApproved !== true &&
        isApprover === true &&
        getApprover?.data?.current[0]?.po_approval !== 2 &&
        props?.is_payment ? (
          <div className="d-flex justify-content-center gap-3 mt-3">
            <button
              type="button"
              class="decline-btn mb-2"
              onClick={() => setShow((prev) => !prev)}
            >
              Decline
            </button>
            <button
              type="button"
              class="approval-btn"
              onClick={confirmationHandler}
            >
              Approve
            </button>
          </div>
        ) : (
          ""
        )}
        {isPaApproved !== true && canRetract === true ? (
          <div className="d-flex justify-content-center gap-3 mt-3">
            <button
              type="button"
              class="approval-btn"
              onClick={retractConfirmationHandler}
              style={{ backgroundColor: "#FF5252" }}
            >
              Retract Approval
            </button>
          </div>
        ) : (
          <></>
        )}
        {CheckProjectPermission(
          "po_approval",
          projectmembers,
          userinfo,
          roleRoles
        ) ? (
          // isPaApproved === true && canRetract === false ? (
          //   <div className=" d-flex justify-content-center my-3">
          //     <button
          //       className={"send-for-approval-btn"}
          //       onClick={
          //         isOffShore == null
          //           ? IsOffShoreModalHandler
          //           : SendToInventoryModalHandler
          //       }
          //     >
          //    Send to Inventory
          //     </button>
          //   </div>
          // isPaApproved === true &&  canRetract === false && listOfPrf?.length ? (

          // isPaApproved === true && 
          listOfPrf?.length ? (
            <div className=" d-flex justify-content-center my-3">
              <button
                className={"send-for-approval-btn"}
                onClick={
                  listOfPrf?.length >= 1
                    ? () => setViewListOfPrf(!viewListOfPrf)
                    : null
                  // : listOfPrf?.length == 1
                  //   ? (e) => setViewListOfPrf(!viewListOfPrf)
                  // HandleSingleGrn(listOfPrf[0]?.id)
                }
              >
                Request GRN
              </button>
              {/* {viewListOfPrf && <div>{listOfPrf?.map((prfItem)=>(
                         <div className="d-flex gap-4">
                            <input type="checkbox" name="prf" value={`${prfItem}`} className="ml-4fs-5"/> 
                            <span className="ml-2">{prfItem?.reason_for}</span>
                        </div>
                 ))}
             </div>} */}
            </div>
          ) : (
            <label
              className="d-flex"
              style={{
                backgroundColor: "yellow",
                color: "black",
                padding: "2px",
                borderRadius: "2px",
                fontSize: "14px",
              }}
            >
              {" "}
              PA Not Approved or 0 list of prf{" "}
            </label>
          )
        ) : (
          ""
        )}
        {show && (
          <div class="pa-comment-container">
            <div className="prg p-2">Comment</div>
            <textarea
              className="po-comment-section mx-3"
              onChange={(e) => setFeedBack(e.target.value)}
            ></textarea>
            <button
              type="button"
              class="comment-decline-btn mx-3 my-1"
              onClick={handleDecline}
              disabled={buttonDisable}
            >
              Decline
            </button>
            <button
              type="button"
              class="comment-decline-btn mx-3 my-1"
              style={{
                backgroundColor: "#fff",
                color: "#606060",
                border: "1px solid #606060",
              }}
              onClick={() => setShow((prev) => !prev)}
            >
              Cancel
            </button>
          </div>
        )}
        {confirmation ? (
          <div
            id="Modal"
            className="dialog-modal "
            onClick={(e) => {
              setConfirmation(false);
            }}
          >
            <div
              className="dialog-modal-content"
              onClick={(e) => e.stopPropagation()}
              style={{ height: "30%" }}
            >
              <div className="dialog-info text-center">
                Are you sure to approve
                <div className="prg text-center mt-2">{po_number}</div>
              </div>
              <div className="confirm-dialog-controller">
                <div
                  className="dialog-cancel-btn"
                  onClick={(e) => {
                    setConfirmation(false);
                  }}
                >
                  Cancel
                </div>
                <button
                  type="submit"
                  onClick={handleApprovals}
                  disabled={buttonDisable}
                  className="dialog-delete-btn"
                >
                  Approve
                </button>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {retractConfirmation ? (
          <div
            id="Modal"
            className="dialog-modal "
            onClick={(e) => {
              setConfirmation(false);
            }}
          >
            <div
              className="dialog-modal-content"
              onClick={(e) => e.stopPropagation()}
              style={{ height: "30%" }}
            >
              <div className="dialog-info text-center">
                Are you sure to retract
                <div className="prg text-center mt-2">{po_number}</div>
              </div>
              <div className="confirm-dialog-controller">
                <div
                  className="dialog-cancel-btn"
                  onClick={(e) => {
                    setRetractConfirmation(false);
                  }}
                >
                  No
                </div>
                <button
                  type={"submit"}
                  onClick={handleRetract}
                  className="dialog-delete-btn"
                  disabled={buttonDisable}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {isOffShore != null && sendToInventory ? (
          <SendToInventoryModal
            modal={setSendToInventory}
            batchId={orderpo?.data[0]?.id}
            projectId={"sdsa79d8as"}
            successMessage={"Success"}
            message={"Message"}
            items={orderpo?.data[0]?.po}
            name={"po"}
          />
        ) : (
          ""
        )}
        {isOffShore == null && checkIsOffShore ? (
          <IsOnShoreModal
            show={checkIsOffShore}
            handleUpdateEtdModal={IsOffShoreModalHandler}
            orderId={orderpo?.data[0]?.order?.id}
          />
        ) : (
          ""
        )}
        {viewListOfPrf && (
          <RequestGrnModal
            setMessage={setMessage}
            setType={setType}
            setIsOpen={setIsOpen}
            requestGrn={true}
            data={listOfPrf}
            setRequestPrfId={setRequestPrfId}
            setMessages={props?.setMessages}
            modal={setViewListOfPrf}
          />
        )}
      </div>
    </>
  );
}
