import React, { useState } from "react";
import { NavLink } from "react-router-dom";

export default function BatchCard(props) {
  let initializer = 0;
  const [orderCount, setCount] = useState(0);

  let items = [];
  
  items = Object?.values(
    props?.batch?.items == null ? "[]" : props?.batch?.items
  );
  // const propertyValues = Object.values(items);

  return (
    <NavLink
      to={`batchDetail/${props?.batch?.id}`}
      className=" batch-detail-card"
    >
      
      <div className="batch-detail-card-sub">
        <div className="batch-card-left-grid">
          <div className="batch-container">
            <div className="logistics-batch-name">
              {props?.batch?.batch_name}
            </div>
            {/* <div className="batch-date">June 22 2021</div> */}
          </div>
          <div className="origin-group">
            <div className="origin-value">
              {props?.batch?.batch_origin?.name}
            </div>
            <div className="origin-label">Origin</div>
          </div>
        </div>

        <div className="logistics-batch-items-container">
          <div className="logistics-batch-item">
            <div className="logistics-batch-value">
              {items?.map((order) => {
                let count = 0;
                if (order?.order_id !== initializer) {
                  count = count + 1;
                  // setCount(orderCount + 1);
                  initializer = order?.order_id;
                  count != orderCount ? setCount(count) : <></>;
                }
              })}
              {orderCount}
            </div>
            <div className="logistics-batch-label">Supplier</div>
          </div>
          <div className="logistics-batch-item">
            <div className="logistics-batch-value">
              {props?.batch?.items?.length}
            </div>
            <div className="logistics-batch-label">Items</div>
          </div>
        </div>
      </div>
      <div className="status">Active</div>
    </NavLink>
  );
}
