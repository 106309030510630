import React, { useState } from "react";
import Settingsidebar from "../../../pages/Settings/SettingIndex";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import CreatePermission from "../SettingModal/CreatePermission";
import CheckIcon from "@mui/icons-material/Check";
import {
  useDeactivateRoleMutation,
  useGetPermissionsQuery,
  useGetProjectRolesQuery,
  useGetRolesQuery,
} from "../../../features/SCMApi";
import { Pagination, Tooltip } from "@mui/material";
import axios from "axios";
import RoleDetailModal from "../SettingModal/RoleDetailModal";
import usePagination from "../../Pagination/Pagination";
import { API_BASE_URL } from "../../../api/endPoint";
export default function ProjectRoles(props) {
  const [createPermission, setCreatePermission] = useState(false);
  function CreatepermissionHandler() {
    setCreatePermission(true);
  }
  const [update, setUpdate] = useState(false);
  const [role, setRole] = useState(null);
  const [roleDetail, setRoleDetail] = useState();
  const [ViewRoleModal, setViewRoleModal] = useState(false);
  const [permissions, setPermission] = useState([]);
  const { data: roles } = useGetProjectRolesQuery();
  let [page, setPage] = useState(1);
  const PER_PAGE = 10;
  const count = Math.ceil(roles?.data?.length / PER_PAGE);
  const _DATA = usePagination(roles?.data, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA?.jump(p);
  };
  

  function handleViewRoleModal() {
    setViewRoleModal(true);
  }
  const [deleteRole, ress] = useDeactivateRoleMutation();
  function DeleteRole(id) {
    deleteRole({ id })
      .unwrap()
      .then((response) => {
        
        props.setMessages(`Role Deactivated successfully`);
      })
      .then((error) => {
        
      });
  }
  return (
    <div className="role-card-container">
      {_DATA?.currentData()?.map((roles) => (
        <Tooltip title="Click for more information">
          <div
            className="role-card d-flex justify-content-between align-items-center px-3"
            role="button"
          >
            <div
              onClick={() => {
                setRoleDetail(roles);
                handleViewRoleModal();
              }}
              className="prg"
            >
              {roles?.role_name}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <IconButton
                variant="text"
                size="small"
                sx={{
                  zIndex: 1,
                  backgroundColor: "#fff",
                  color: "#52FFAC",
                  "&:hover": {
                    backgroundColor: "#52FFAC",
                    color: "#fff",
                  },
                  marginLeft: "-30px",
                  marginBottom: "3px",
                }}
                onClick={() => {
                  CreatepermissionHandler();
                  setUpdate(true);
                  setRole(roles);
                }}
              >
                <ModeEditOutlinedIcon />
              </IconButton>

              <IconButton
                variant="text"
                size="small"
                sx={{
                  backgroundColor: "#fff",
                  color: "#FF5252",
                  "&:hover": {
                    backgroundColor: "#FF5252",
                    color: "#fff",
                  },
                  // marginLeft: "-20px",
                  marginBottom: "3px",
                }}
                onClick={(e) => DeleteRole(roles?.id)}
              >
                <DeleteOutlinedIcon />
              </IconButton>
            </div>
          </div>
        </Tooltip>
      ))}

      <div className="d-flex justify-content-end mt-2">
        {count > 1 ? (
          <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
          />
        ) : (
          ""
        )}
        <IconButton
          sx={{
            width: "40px",
            height: "40px",
            marginRight: "4rem",
            backgroundColor: "#064b4f",
            color: "#ececec",
            "&:hover": {
              backgroundColor: "#186569",
              color: "#ececec",
            },
          }}
          onClick={() => {
            CreatepermissionHandler();
            setUpdate(false);
          }}
        >
          <AddIcon style={{ fontSize: 25 }} />
        </IconButton>
      </div>

      {createPermission ? (
        <CreatePermission
          modal={setCreatePermission}
          setMessages={props?.setMessages}
          isproject={true}
          update={update}
          role={role}
        />
      ) : (
        <></>
      )}
      {ViewRoleModal ? (
        <RoleDetailModal
          ToggleModal={setViewRoleModal}
          setMessages={props?.setMessages}
          roledetail={roleDetail}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
