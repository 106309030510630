import React, { useState } from "react";
import NavBar from "../../Layout/NavBar";
import EditPaymentModal from "./SourcingModal/EditPaymentModal";
import EditShipmentModal from "./SourcingModal/EditShipmentModal";
import LaunchIcon from "@mui/icons-material/Launch";
import { IconButton, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import {
  useGetOrderPoItemQuery,
  useGetProjectQuery,
  useGetProjectRolePermissionQuery,
  useGetProjectRolesQuery,
  useGetProjectsQuery,
  useGetUserInfoQuery,
  useGetApproverQuery,
  useGetPRFQuery,
} from "../../features/SCMApi";
import { NavLink, useLocation, useParams } from "react-router-dom";
import GroupItemsModal from "./SourcingModal/GroupItemsModal";
import EtaPoDetail from "./EtaPoDetail";
import SourcingIndex from "../../pages/Sourcing/SourcingIndex";
import AddPaymentModal from "./SourcingModal/AddpaymentModal";
import AddShipmentModal from "./SourcingModal/AddShipmentModal";
import { IMG_BASE_URL } from "../../api/endPoint";
import CheckProjectPermission from "../common/checkPermission/CheckProjectPermission";

//Approval icons
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CreatePrf from "./CreatePrf";
import { Download, UpgradeOutlined } from "@mui/icons-material";
import GeneratePRF from "./GeneratePRF";
import ApprovalHistory from "../common/ApprovalHistory";
import PrfRequestModal from "./SourcingModal/PrfRequestModal";
import CopyLink from "../common/CopyLink";
import { useRequestForGrnMutation } from "../../features/SCMApi";
import PrfRequestComponent from "./SourcingModal/PrfRequestComponent";
import { Button } from "react-bootstrap";

export default function PoStageDetail(props) {
  const params = useParams();
  const [requestForGrn] = useRequestForGrnMutation();
  const [EditPaymentDetail, setEditPaymentDetail] = useState(false);
  function EditPaymentDetailHandler() {
    setEditPaymentDetail(true);
  }
  const [AddPaymentDetail, setAddPaymentDetail] = useState(false);
  function AddPaymentDetailHandler() {
    setAddPaymentDetail(true);
  }
  const handleRequestGrn = () => {
    requestForGrn();
  };
  const [AddShipment, setAddShipment] = useState(false);
  function AddShipmentDetailHandler() {
    setAddShipment(true);
  }
  const [EditShipment, setEditShipment] = useState(false);
  function EditShipmentDetailHandler() {
    setEditShipment(true);
  }
  const [groupItems, setGroupItems] = useState(false);
  const [generatePrf, setGeneratePRF] = useState(false);
  function GroupItemsHandler() {
    setGroupItems(true);
  }
  function GeneratePRFModal() {
    setGeneratePRF(true);
  }
  const [messages, setMessages] = useState(null);
  const { data: orderpo } = useGetOrderPoItemQuery(params.id);
  const { data: response } = useGetProjectsQuery();
  const Projectdata = [];

  response?.data?.map((items) => {
    if (params.id === items.id) {
      Projectdata.push(items);
    }
  });
  const location = useLocation();

  let paymentInfo = [];
  if (orderpo?.data !== undefined)
    Object.values(orderpo?.data).map((value, index) =>
      paymentInfo.push(value?.payment_information_po)
    );
  let avatar = [];

  let pay = [];
  pay.push(paymentInfo[0]);
  let ordername = orderpo?.data[0]?.order?.supplier?.distributor_name;

  const projectid = orderpo?.data[0]?.project_id;

  let count = 0;
  const { data: projectmembers, isSuccess } = useGetProjectQuery(projectid);
  const { data: roleRoles } = useGetProjectRolePermissionQuery(projectid);
  const { data: userinfo } = useGetUserInfoQuery();
  let navlink = `/sourcing/poStage/${projectid}`;
  const { data: roles } = useGetProjectRolesQuery();

  const poData = orderpo && orderpo?.data[0]?.id;

  const { data: getApprover } = useGetApproverQuery({
    module_name: "PO",
    id: poData,
  });

  let feedBack =
    getApprover &&
    getApprover?.data?.history.find((user) => user?.feedback)?.feedback
      ? getApprover?.data?.history.find((user) => user?.feedback)?.feedback
      : false;

  let feedBackUser =
    getApprover &&
    getApprover?.data?.history.find((user) => user?.feedback)?.name
      ? getApprover?.data?.history.find((user) => user?.feedback)?.name
      : false;

  let roleName =
    getApprover &&
    getApprover?.data?.current.find((paData) => paData?.level)?.role_name;

  let isPaApproved =
    getApprover?.data?.current &&
    getApprover?.data?.current.find((paData) => paData?.po_approval == 1)
      ?.po_approval == 1
      ? true
      : getApprover?.data?.current.find((paData) => paData?.po_approval == 0)
            ?.po_approval == 0
        ? false
        : getApprover?.data?.current.find((paData) => paData?.po_approval == 2)
              ?.po_approval == 2
          ? null
          : "";

  let level = getApprover?.data?.current.find((paData) => paData?.level)?.level;

  const approvalStatus = {
    level,
    finalApproved: isPaApproved == 1 ? true : isPaApproved == 0 ? false : null,
    feedBack,
    feedBackUser,
  };
  let ordertype =
    (orderpo && orderpo?.data[0]?.order?.order_type == "Product") ||
    (orderpo && orderpo?.data[0]?.order?.order_type == "Training");
  let is_offshore = orderpo && orderpo?.data[0]?.order?.is_offshore;
  const { data: Prf } = useGetPRFQuery(orderpo?.data[0]?.order_id);

  const [approvalHistory, setApprovalHistory] = useState(false);

  function HandleHistory() {
    setApprovalHistory(true);
  }
  let pass = "";
  if (is_offshore === 1 && orderpo?.data[0]?.payment_information_id !== null) {
    pass = true;
  } else if (
    is_offshore === 1 &&
    orderpo?.data[0]?.payment_information_id === null &&
    orderpo?.data[0]?.order?.order_type == "Training"
  ) {
    pass = true;
  } else if (
    is_offshore === 0 &&
    orderpo?.data[0]?.payment_information_id === null
  ) {
    pass = true;
  } else {
    pass = false;
  }

  return (
    <SourcingIndex
      title={"PO Stage"}
      navlink={navlink}
      name={orderpo?.data[0]?.po_number}
      Message={messages}
    >
      <div className="sourcing-postage-cards">
        <div
          className="smManufacturer-card"
          style={{ marginTop: "8px", borderRadius: "0px", height: "90%" }}
        >
          <div className="Lc-card" onClick={HandleHistory}>
            {pass ? (
              <>
                <div className="lc-value">
                  {approvalStatus?.finalApproved === false &&
                  getApprover &&
                  getApprover?.data?.current?.length !== 0 ? (
                    <div
                      style={{
                        color: "#fcce37",
                        textAlign: "center",
                      }}
                    >
                      Level {approvalStatus?.level} (
                      {roleName?.length > 21
                        ? roleName?.slice(0, 20) + "..."
                        : roleName}
                      ) approval pending
                    </div>
                  ) : approvalStatus?.finalApproved === true ? (
                    <span
                      style={{
                        color: "#064B4F",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Approved
                      <CheckCircleOutlineIcon />
                    </span>
                  ) : approvalStatus?.finalApproved === null ? (
                    <Tooltip
                      title={`${approvalStatus?.feedBackUser} reject PO with comment ${approvalStatus?.feedBack}`}
                    >
                      <span
                        style={{
                          color: "#FF5252",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        PO rejected
                      </span>
                    </Tooltip>
                  ) : (
                    "Pending"
                  )}
                </div>
                <div className="lc-label">Status</div>
              </>
            ) : (
              <div className="lc-value text-center text-danger">
                Please provide the <strong>Payment Information </strong>to
                proceed
              </div>
            )}
          </div>
        </div>
        <div className="po-smManufacture-card mt-2">
          <div className="Lc-card">
            <Tooltip title={ordername}>
              {orderpo?.data[0]?.order?.supplier_id != null ? (
                <p className="h4-pri">
                  {ordername?.length >= 10
                    ? ordername.slice(0, 10) + "..."
                    : ordername}
                </p>
              ) : (
                <p className="h4-pri">
                  {orderpo?.data[0]?.order?.bill_to?.distributor_name?.length >=
                  10
                    ? orderpo?.data[0]?.order?.bill_to?.distributor_name.slice(
                        0,
                        10
                      ) + "..."
                    : orderpo?.data[0]?.order?.bill_to?.distributor_name}
                </p>
              )}
            </Tooltip>

            <div className="lc-label">
              {orderpo?.data[0]?.order?.supplier_id != null
                ? "Supplier"
                : "Bill to"}
            </div>
          </div>
        </div>
        <div className="po-smManufacture-card mt-2 overflow-auto">
          <div className="Lc-card ">
            {projectmembers?.data?.users.map((items) =>
              roles?.data?.map((role) =>
                role.id == items?.pivot?.role_id ? (
                  role?.role_name == "Sourcing Officer" ? (
                    <div className="d-flex align-items-center gap-3">
                      <div className="participantManufacturer-img">
                        {items.avatar != null ? (
                          <img src={`${IMG_BASE_URL}${items.avatar}`} alt="" />
                        ) : (
                          <div className="default-avatar">
                            <div style={{ display: "none" }}>
                              {(avatar = items?.name?.split(" "))}
                            </div>
                            {avatar[0]?.charAt(0)}
                            {avatar[1]?.charAt(0)}
                          </div>
                        )}
                      </div>
                      <div className="">
                        <div className="lc-value">{items?.name}</div>
                        <div className="lc-label text-center">
                          {items?.role?.role_name == "Project Finance Officer"
                            ? "PFO"
                            : role?.role_name}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )
              )
            )}
          </div>
        </div>
        <div className="po-smManufacture-card mt-2 overflow-auto">
          <div className="Lc-card">
            {projectmembers?.data?.users.map((items) =>
              roles?.data?.map((role) =>
                role.id === items?.pivot?.role_id ? (
                  role?.role_name === "Logistics Officer" ? (
                    <div className="d-flex align-items-center gap-3">
                      <div className="participantManufacturer-img">
                        {items.avatar !== null ? (
                          <img src={`${IMG_BASE_URL}${items.avatar}`} alt="" />
                        ) : (
                          <div className="default-avatar">
                            <div style={{ display: "none" }}>
                              {(avatar = items?.name?.split(" "))}
                            </div>
                            {avatar[0]?.charAt(0)}
                            {avatar[1]?.charAt(0)}
                          </div>
                        )}
                      </div>
                      <div className="">
                        <div className="lc-value">{items?.name}</div>
                        <div className="lc-label text-center">
                          {items?.role?.role_name === "Project Finance Officer"
                            ? "PFO"
                            : role?.role_name}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )
              )
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className={"po-summary-table col-md-8 mt-4 "}>
          <EtaPoDetail
            is_payment={pass}
            setMessages={setMessages}
            poData={poData}
            isPO={"PO"}
          />
        </div>
        {is_offshore == 1 ? (
          <div className="col-md-4">
            <div className="d-flex gap-2">
              {CheckProjectPermission(
                "po_group",
                projectmembers,
                userinfo,
                roleRoles
              ) ? (
                <button
                  type="submit"
                  className="sourcing-postage-big-btn"
                  onClick={GroupItemsHandler}
                >
                  <LaunchIcon />
                  Group items
                </button>
              ) : (
                ""
              )}

              {orderpo?.data[0]?.po_approval == 1 ? (
                orderpo?.data[0]?.prf_requested == 0 ? (
                  <button
                    // to={`/prf/${params?.id}`}
                    className="sourcing-postage-big-btn"
                    onClick={
                      orderpo?.data[0]?.prf_requested == 0
                        ? GeneratePRFModal
                        : ""
                    }
                  >
                    <div className="px-1 py-3">
                      {orderpo?.data[0]?.prf_requested == 1 ? (
                        <CheckCircleOutlineIcon />
                      ) : (
                        <LaunchIcon />
                      )}
                      {orderpo?.data[0]?.prf_requested == 1
                        ? "PRF Requested"
                        : "Request PRF"}
                    </div>
                  </button>
                ) : (
                  <div>
                    <Button
                      style={{ backgroundColor: "blue" }}
                      onClick={() => {
                        handleRequestGrn();
                      }}
                    >
                      GRN request
                    </Button>
                    <CopyLink
                      linkToCopy={`https://scm.ienetworks.co/payment/prf/${orderpo?.data[0]?.project_id}/prfs/${orderpo?.data[0]?.id}`}
                    />
                  </div>
                )
              ) : (
                ""
              )}
            </div>

            {orderpo?.data.length !== 0 &&
            orderpo?.data[0]?.payment_information_id ? (
              pay.map((items) => (
                <div className="card mt-4">
                  <div className="text-center h5 mt-2 mb-0">
                    Payment Information
                  </div>
                  <div className="">
                    <div className="d-flex ms-5 gap-3">
                      <div className="payment-text mt-2 mb-0">
                        Mode of Payment
                      </div>
                      <div className="payment-text-bold mt-2 mb-0">
                        {items?.payment_mode?.payment_mode === null
                          ? "Not Avaliable"
                          : items?.payment_mode?.payment_mode}
                      </div>
                    </div>
                    <div className="d-flex ms-5 gap-3">
                      <div className="payment-text mt-2 mb-0">
                        Terms of Payment
                      </div>
                      <Tooltip title={items?.payment_term?.payment_term}>
                        <div className="payment-text-bold mt-2 mb-0">
                          {items?.payment_term?.payment_term === null
                            ? "Not Avaliable"
                            : items?.payment_term?.payment_term?.slice(0, 20) +
                              "..."}
                        </div>
                      </Tooltip>
                    </div>
                    <div className="d-flex ms-5 gap-3">
                      <div className="payment-text mt-2 mb-0">Incoterm</div>
                      <div className="payment-text-bold mt-2 mb-0">
                        {/* {Object.keys(items)?.map((index) => {
                          items[index]?.incoterm?.incoterm_name;
                        })} */}
                        {items?.incoterm === null
                          ? "Not Avaliable"
                          : items?.incoterm.incoterm_name}
                      </div>
                    </div>
                    <div className="d-flex ms-5 gap-3">
                      <div className="payment-text mt-2 mb-0">
                        Advance Percentage
                      </div>
                      <div className="payment-text-bold mt-2 mb-0">
                        {items?.advance_percentage === null
                          ? "Not Avaliable"
                          : `${items?.advance_percentage}%`}
                      </div>
                    </div>
                    <div className="divider"></div>
                    <div className="text-center h5 mt-2 mb-0">
                      Shipment Information
                    </div>
                    <div>
                      <div className="d-flex ms-5 gap-3">
                        <div className="payment-text mt-2 mb-0">
                          Mode of Shipment
                        </div>

                        <div className="payment-text-bold mt-2 mb-0">
                          {items?.shipment_mode === null
                            ? "Not Avaliable"
                            : items?.shipment_mode?.shipment_mode}
                        </div>
                      </div>
                      <div className="d-flex ms-5 gap-3">
                        <div className="payment-text mt-2 mb-0">
                          Loading Origin
                        </div>
                        {items?.loading_port !== null ? (
                          <div className="payment-text-bold mt-2 mb-0">
                            {items?.origin?.name === null
                              ? "Not Avaliable"
                              : items?.origin?.name}
                          </div>
                        ) : (
                          <div className="payment-text-bold mt-2 mb-0">
                            {items?.origin?.name === null
                              ? "Not Avaliable"
                              : items?.origin?.name}
                          </div>
                        )}
                      </div>
                      <div className="d-flex ms-5 gap-3">
                        <div className="payment-text mt-2 mb-0">
                          Port of Loading
                        </div>
                        {items?.loading_port !== null ? (
                          <div className="payment-text-bold mt-2 mb-0">
                            {items?.loading_port === null
                              ? "Not Avaliable"
                              : items?.loading_port?.port_name}
                          </div>
                        ) : (
                          <div className="payment-text-bold mt-2 mb-0">
                            {items?.sea_loading_port === null
                              ? `Any port in ${items?.origin?.code}`
                              : items?.sea_loading_port?.port_name}
                          </div>
                        )}
                      </div>
                      <div className="d-flex ms-5 gap-3">
                        <div className="payment-text mt-2 mb-0">
                          Port of Destination
                        </div>
                        <div className="payment-text-bold mt-2 mb-0">
                          {items?.shipment_destination === null
                            ? "Not Avaliable"
                            : items?.shipment_destination?.port_name}
                        </div>
                      </div>
                      <div className="d-flex ms-5 gap-3">
                        <div className="payment-text mt-2 mb-0">
                          Partial Shipment
                        </div>
                        <div className="payment-text-bold mt-2 mb-0">
                          {items?.partial_shipment === null
                            ? "Not Avaliable"
                            : items?.partial_shipment}
                        </div>
                      </div>
                      <div className="d-flex ms-5 gap-3">
                        <div className="payment-text mt-2 mb-0">
                          Transshipment
                        </div>
                        <div className="payment-text-bold mt-2 mb-0">
                          {items?.trans_shipment === null
                            ? "Not Avaliable"
                            : items?.trans_shipment}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end p-2">
                      {CheckProjectPermission(
                        "edit_payment_information",
                        projectmembers,
                        userinfo,
                        roleRoles
                      ) ? (
                        <IconButton
                          variant="text"
                          size="medium"
                          className="float-end"
                          sx={{
                            backgroundColor: "#186569",
                            color: "#fff",
                            border: "1px solid #186569",
                            "&:hover": {
                              backgroundColor: "#fff",
                              color: "#186569",
                              border: "1px solid #186569",
                            },
                            marginTop: "3%",
                          }}
                          onClick={EditPaymentDetailHandler}
                        >
                          <ModeEditOutlinedIcon />
                        </IconButton>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="card mt-4">
                <div className="text-center h5 mt-2 mb-0">
                  Payment Information
                </div>
                <div className="">
                  <div className="d-flex ms-5 gap-3">
                    <div className="payment-text mt-2 mb-0">
                      Mode of Payment
                    </div>
                    <div className="payment-text-bold mt-2 mb-0">
                      Not Avaliable
                    </div>
                  </div>
                  <div className="d-flex ms-5 gap-3">
                    <div className="payment-text mt-2 mb-0">
                      Terms of Payment
                    </div>
                    <div className="payment-text-bold mt-2 mb-0">
                      Not Avaliable
                    </div>
                  </div>
                  <div className="d-flex ms-5 gap-3">
                    <div className="payment-text mt-2 mb-0">Incoterm</div>
                    <div className="payment-text-bold mt-2 mb-0">
                      Not Avaliable
                    </div>
                  </div>
                  <div className="d-flex ms-5 gap-3">
                    <div className="payment-text mt-2 mb-0">
                      Advance Percentage
                    </div>
                    <div className="payment-text-bold mt-2 mb-0">
                      Not Avaliable
                    </div>
                  </div>
                  <div className="divider"></div>
                  <div className="text-center h5 mt-2">
                    Shipment Information
                  </div>
                  <div>
                    <div className="d-flex ms-5 gap-3">
                      <div className="payment-text mt-2 mb-0">
                        Mode of Shipment
                      </div>
                      <div className="payment-text-bold mt-2 mb-0">
                        Not Avaliable
                      </div>
                    </div>
                    <div className="d-flex ms-5 gap-3">
                      <div className="payment-text mt-2 mb-0">
                        Loading Origin
                      </div>
                      <div className="payment-text-bold mt-2 mb-0">
                        Not Avaliable
                      </div>
                    </div>
                    <div className="d-flex ms-5 gap-3">
                      <div className="payment-text mt-2 mb-0">
                        Port of Loading
                      </div>
                      <div className="payment-text-bold mt-2 mb-0">
                        Not Avaliable
                      </div>
                    </div>
                    <div className="d-flex ms-5 gap-3">
                      <div className="payment-text mt-2 mb-0">
                        Port of Destination
                      </div>
                      <div className="payment-text-bold mt-2 mb-0">
                        Not Avaliable
                      </div>
                    </div>
                    <div className="d-flex ms-5 gap-3">
                      <div className="payment-text mt-2 mb-0">
                        Port of Destination
                      </div>
                      <div className="payment-text-bold mt-2 mb-0">
                        Not Avaliable
                      </div>
                    </div>
                    <div className="d-flex ms-5 gap-3">
                      <div className="payment-text mt-2 mb-0">
                        Transshipment
                      </div>
                      <div className="payment-text-bold mt-2 mb-0">
                        Not Avaliable
                      </div>
                    </div>
                  </div>
                  {orderpo?.data[0]?.id !== undefined ? (
                    <div className="d-flex justify-content-end p-2">
                      {CheckProjectPermission(
                        "add_payment_information",
                        projectmembers,
                        userinfo,
                        roleRoles
                      ) ? (
                        <IconButton
                          variant="text"
                          size="medium"
                          className="float-end"
                          sx={{
                            backgroundColor: "#186569",
                            color: "#fff",
                            border: "1px solid #186569",
                            "&:hover": {
                              backgroundColor: "#fff",
                              color: "#186569",
                              border: "1px solid #186569",
                            },
                            marginTop: "3%",
                          }}
                          onClick={AddPaymentDetailHandler}
                        >
                          <AddIcon />
                        </IconButton>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="col-md-4">
            {orderpo?.data[0]?.po_approval == 1 ? (
              orderpo?.data[0]?.prf_requested == 0 ? (
                <button
                  // to={`/prf/${params?.id}`}
                  className="sourcing-postage-big-btn"
                  onClick={
                    orderpo?.data[0]?.prf_requested == 0 ? GeneratePRFModal : ""
                  }
                >
                  <div className="px-1 py-3">
                    {orderpo?.data[0]?.prf_requested == 1 ? (
                      <CheckCircleOutlineIcon />
                    ) : (
                      <LaunchIcon />
                    )}
                    {orderpo?.data[0]?.prf_requested == 1
                      ? "PRF Requested"
                      : "Request PRF"}
                  </div>
                </button>
              ) : (
                <CopyLink
                  linkToCopy={`https://scm.ienetworks.co/payment/prf/${orderpo?.data[0]?.project_id}/prfs/${orderpo?.data[0]?.id}`}
                />
              )
            ) : (
              ""
            )}
            <div className="card mt-3">
              <div className="text-center h5 mt-2 mb-0">PRF Information</div>
              <div className="">
                <div className="d-flex ms-5 gap-3">
                  <div className="payment-text mt-2 mb-0">PRF Number</div>
                  <div className="payment-text-bold mt-2 mb-0">
                    {Prf?.data != null && Prf?.data?.prf_number != null
                      ? Prf?.data?.prf_number
                      : " Not Avaliable"}
                  </div>
                </div>
                <div className="d-flex ms-5 gap-3">
                  <div className="payment-text mt-2 mb-0">Mode of Payment</div>
                  <div className="payment-text-bold mt-2 mb-0">
                    {Prf?.data != null && Prf?.data?.payment_mode != null
                      ? Prf?.data?.payment_mode?.payment_mode
                      : " Not Avaliable"}
                  </div>
                </div>
                <div className="d-flex ms-5 gap-3">
                  <div className="payment-text mt-2 mb-0">Payablerrrr to</div>

                  <Tooltip title={Prf?.data?.order?.supplier?.distributor_name}>
                    <p className="payment-text-bold mt-2 mb-0">
                      {Prf?.data != null && Prf?.data?.bill_to != null
                        ? Prf?.data?.order?.supplier?.distributor_name
                            ?.length >= 15
                          ? Prf?.data?.order?.supplier?.distributor_name.slice(
                              0,
                              15
                            ) + "..."
                          : Prf?.data?.order?.supplier?.distributor_name
                        : " Not Avaliable"}
                    </p>
                  </Tooltip>
                </div>
                <div className="d-flex ms-5 gap-3">
                  <div className="payment-text mt-2 mb-0">Reason For</div>

                  <Tooltip title={Prf?.data?.order?.supplier?.distributor_name}>
                    <p className="payment-text-bold mt-2 mb-0">
                      {Prf?.data != null && Prf?.data?.reason_for != null
                        ? Prf?.data?.reason_for?.length >= 15
                          ? Prf?.data?.reason_for.slice(0, 15) + "..."
                          : Prf?.data?.reason_for
                        : " Not Avaliable"}
                    </p>
                  </Tooltip>
                </div>
                <div className="divider"></div>
                <div className="d-flex ms-5 gap-3">
                  <div className="payment-text mt-2 mb-0">Requested By</div>
                  <div className="payment-text-bold mt-2 mb-0">
                    {Prf?.data != null && Prf?.data?.requested_by != null
                      ? Prf?.data?.requested_by?.name
                      : " Not Avaliable"}
                  </div>
                </div>
                <div>
                  <div className="d-flex ms-5 gap-3">
                    <div className="payment-text mt-2 mb-0">Prepared By</div>
                    <div className="payment-text-bold mt-2 mb-0">
                      {Prf?.data != null && Prf?.data?.prepared_by != null
                        ? Prf?.data?.prepared_by?.name
                        : " Not Avaliable"}
                    </div>
                  </div>
                  <div className="d-flex ms-5 gap-3">
                    <div className="payment-text mt-2 mb-0">Approved By</div>
                    <div className="payment-text-bold mt-2 mb-0">
                      {Prf?.data != null && Prf?.data?.approved_by != null
                        ? Prf?.data?.approved_by?.name
                        : " Not Avaliable"}
                    </div>
                  </div>
                  <div className="d-flex ms-5 gap-3 mb-2">
                    <div className="payment-text mt-2 mb-0">Paid By</div>
                    <div className="payment-text-bold mt-2 mb-0">
                      {Prf?.data != null && Prf?.data?.paid_by != null
                        ? Prf?.data?.paid_by?.name
                        : " Not Avaliable"}
                    </div>
                  </div>
                  {Prf?.data != null ? <GeneratePRF data={Prf?.data} /> : ""}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {EditPaymentDetail ? (
        <EditPaymentModal
          modal={setEditPaymentDetail}
          poid={orderpo?.data[0]?.id}
          value={orderpo}
          setMessages={setMessages}
        />
      ) : (
        <></>
      )}
      {AddPaymentDetail ? (
        <AddPaymentModal
          modal={setAddPaymentDetail}
          poid={orderpo?.data[0]?.id}
          setMessages={setMessages}
        />
      ) : (
        <></>
      )}

      {EditShipment ? (
        <EditShipmentModal modal={setEditShipment} setMessages={setMessages} />
      ) : (
        <></>
      )}
      {groupItems ? (
        <GroupItemsModal
          po_id={orderpo?.data[0]?.id}
          modal={setGroupItems}
          setMessages={setMessages}
        />
      ) : (
        <></>
      )}
      {generatePrf ? (
        // <CreatePrf
        //   order_id={orderpo?.data[0]?.order_id}
        //   modal={setGeneratePRF}
        //   setMessages={setMessages}
        //   data={Prf?.data}
        // />
        <PrfRequestModal
          modal={setGeneratePRF}
          setMessages={setMessages}
          data={orderpo?.data[0]}
        />
      ) : (
        // <PrfRequestComponent
        //    isOpen={generatePrf}
        //    onClose={!generatePrf}
        //    orders={orderpo}/>

        <></>
      )}
      {approvalHistory ? (
        <ApprovalHistory
          open={approvalHistory}
          data={getApprover?.data?.history}
          handleClose={() => setApprovalHistory(false)}
        />
      ) : (
        ""
      )}
    </SourcingIndex>
  );
}
