import React, { useEffect, useState } from "react";
import EmployeeRecievableFilter from "./EmployeeReceivableFilter";
import axios from "axios";
import { API_BASE_URL } from "../../../../api/endPoint";
import Pagination from "@mui/material/Pagination";
import Button from "@mui/material/Button";
import UploadIcon from "@mui/icons-material/Upload";
import EmployeeReceivableReport from "./EmployeeReceivableReport";

function EmployeeReceivable() {
  const [productData, setProductData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [file, setFile] = useState(null);

  const itemsPerPage = 20;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/scm/settings/payment/employeereceivable`,
          {
            params: {
              searchQuery,
              startDate,
              endDate,
            },
          }
        );
        setProductData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [searchQuery, startDate, endDate]);

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      console.log("File uploaded:", selectedFile.name);
      await handleImport(selectedFile);
    }
  };

  const handleImport = async (selectedFile) => {
    if (!selectedFile) {
      alert("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      await axios.post(
        `${API_BASE_URL}/scm/settings/payment/employeereceivable`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("File uploaded successfully");

      const response = await axios.get(
        `${API_BASE_URL}/scm/settings/payment/employeereceivable`,
        {
          params: {
            searchQuery,
            startDate,
            endDate,
          },
        }
      );
      setProductData(response.data);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const filteredData = productData.filter((item) => {
    const matchesQuery = item.employee_name
      ? item.employee_name.toLowerCase().includes(searchQuery.toLowerCase())
      : false;
    const matchesStartDate =
      !startDate || new Date(item.created_at) >= new Date(startDate);
    const matchesEndDate =
      !endDate || new Date(item.created_at) <= new Date(endDate);

    return matchesQuery && matchesStartDate && matchesEndDate;
  });

  const numberOfPages = Math.ceil(filteredData.length / itemsPerPage);
  const paginatedData = filteredData.slice(startIndex, endIndex);
  const shouldDisplayPagination = numberOfPages > 1;

  const totalSum = filteredData.reduce((sum, item) => {
    const totalValue = parseFloat(item.total) || 0;
    return sum + totalValue;
  }, 0);

  return (
    <div className="mb-3 bg-white rounded border flex items-center justify-between">
      <div>
        <EmployeeRecievableFilter
          searchQuery={searchQuery}
          startDate={startDate}
          endDate={endDate}
          handleSearchSubmit={handleSearchSubmit}
          setSearchQuery={setSearchQuery}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />

        <div
          style={{ display: "flex", alignItems: "center", marginTop: "10px" }}
        >
          <EmployeeReceivableReport data={filteredData} />
          <div
            className="items-counter"
            style={{ textAlign: "center", marginLeft: "10px" }}
          >
            <div>Total Count</div>
            <div>{filteredData.length}</div>
          </div>
          <div
            className="items-counter"
            style={{ textAlign: "center", marginLeft: "10px" }}
          >
            <div>Total Sum</div>
            <div>{totalSum.toLocaleString()} Birr</div>
          </div>
        </div>
      </div>

      <div style={{ display: "flex" }}>
        <div style={{ flex: "2%" }}></div>
        <div style={{ flex: "96%", overflow: "auto" }}>
          <table className="table" style={{ marginTop: "20px" }}>
            <thead>
              <tr className="table-info">
                <th className="px-4 py-2">NO</th>
                <th className="px-4 py-2">Employee Name</th>
                <th className="px-4 py-2">Car</th>
                <th className="px-4 py-2">PMA</th>
                <th className="px-4 py-2">Total</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((item, index) => (
                <tr
                  key={item.id}
                  className={
                    index % 2 === 0 ? "table-light" : "table-secondary"
                  }
                >
                  <td>{startIndex + index + 1}</td>
                  <td>{item.employee_name}</td>
                  <td>{item.car_maintenance}</td>
                  <td>{item.pma}</td>
                  <td>{item.total}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div style={{ flex: "2%" }}></div>
      </div>

      {shouldDisplayPagination && (
        <Pagination
          count={numberOfPages}
          page={currentPage}
          onChange={(e, page) => handlePageChange(page)}
          variant="outlined"
          shape="rounded"
        />
      )}
    </div>
  );
}

export default EmployeeReceivable;
