import React from "react";
import { Workbook } from "exceljs";
import saveAs from "file-saver";

const AllprojectPaymentCollectionReport = ({ data }) => {
  const collectedPayment = data?.filter((Payment) => Payment.is_collected == 1);
  const unCollectedPayment = data?.filter(
    (Payment) => Payment.is_collected == 0
  );
  const calculateDateDifference = (endDate, currentDate) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const endDateTime = new Date(endDate).setHours(0, 0, 0, 0);
    const currentDateTime = new Date(currentDate).setHours(0, 0, 0, 0);
    return Math.ceil((endDateTime - currentDateTime) / oneDay);
  };

  const downloadExcel = async () => {
    const workbook = new Workbook();

    // Add worksheets to the workbook
    const worksheetAll = workbook.addWorksheet("All");
    const worksheetColl = workbook.addWorksheet("Collected");
    const worksheetUnColl = workbook.addWorksheet("Un Collected");

    const titleCellAll = worksheetAll.getCell("A1");
    const titleCellColl = worksheetColl.getCell("A1");
    const titleCellUncoll = worksheetUnColl.getCell("A1");

    titleCellAll.value = `Payment Collection Report (All)`;
    titleCellColl.value = `Payment Collection Report (Coll)`;
    titleCellUncoll.value = `Payment Collection Report (Uncoll)`;

    // Title
    const title = ["Payment Collection Report"];

    title.forEach((title, index) => {
      worksheetAll.getCell(1, index + 1).value = title;
      worksheetColl.getCell(1, index + 1).value = title;
      worksheetUnColl.getCell(1, index + 1).value = title;
    });

    const styleTitleCell = (cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "00B0F0" },
      };
      cell.font = {
        size: 12,
        name: "Times New Roman",
        bold: true,
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    };

    title.forEach((_, index) => {
      styleTitleCell(worksheetAll.getCell(1, index + 1));
      styleTitleCell(worksheetColl.getCell(1, index + 1));
      styleTitleCell(worksheetUnColl.getCell(1, index + 1));
    });

    worksheetAll.mergeCells("A1:K1");
    worksheetColl.mergeCells("A1:K1");
    worksheetUnColl.mergeCells("A1:K1");

    // Headers
    const headers = [
      "Project Name",
      "Delivered Milestone",
      "Expected Payment Amount",
      "Actual Amount",
      "UnCollected Balance",
      "Expected date to be collected",
      "Actual collected date",
      "Variance",
      "Responsible Person",
      "Today's progress",
      "Next action",
    ];

    headers.forEach((header, index) => {
      worksheetAll.getCell(2, index + 1).value = header;
      worksheetColl.getCell(2, index + 1).value = header;
      worksheetUnColl.getCell(2, index + 1).value = header;
    });

    const styleHeaderCell = (cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B8CCE4" },
      };
      cell.font = {
        color: { argb: "000000" },
        bold: true,
        size: 9,
        name: "Times New Roman",
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    };

    headers.forEach((_, index) => {
      styleHeaderCell(worksheetAll.getCell(2, index + 1));
      styleHeaderCell(worksheetColl.getCell(2, index + 1));
      styleHeaderCell(worksheetUnColl.getCell(2, index + 1));
    });

    // Set column widths
    worksheetAll.getColumn(1).width = 20;
    worksheetAll.getColumn(2).width = 15;
    worksheetAll.getColumn(3).width = 15;
    worksheetAll.getColumn(4).width = 15;
    worksheetAll.getColumn(5).width = 15;
    worksheetAll.getColumn(6).width = 20;
    worksheetAll.getColumn(7).width = 20;
    worksheetAll.getColumn(8).width = 20;
    worksheetAll.getColumn(9).width = 20;
    worksheetAll.getColumn(10).width = 20;
    worksheetAll.getColumn(11).width = 20;
    worksheetAll.getColumn(12).width = 25;
    // Set the header row to bold
    worksheetAll.getRow(1).font = { bold: true };
    worksheetColl.getColumn(1).width = 20;
    worksheetColl.getColumn(2).width = 15;
    worksheetColl.getColumn(3).width = 15;
    worksheetColl.getColumn(4).width = 15;
    worksheetColl.getColumn(5).width = 15;
    worksheetColl.getColumn(6).width = 20;
    worksheetColl.getColumn(7).width = 20;
    worksheetColl.getColumn(8).width = 20;
    worksheetColl.getColumn(9).width = 20;
    worksheetColl.getColumn(10).width = 20;
    worksheetColl.getColumn(11).width = 20;
    worksheetColl.getColumn(12).width = 25;
    // Set the header row to bold
    worksheetColl.getRow(1).font = { bold: true };
    worksheetUnColl.getColumn(1).width = 20;
    worksheetUnColl.getColumn(2).width = 15;
    worksheetUnColl.getColumn(3).width = 15;
    worksheetUnColl.getColumn(4).width = 15;
    worksheetUnColl.getColumn(5).width = 15;
    worksheetUnColl.getColumn(6).width = 20;
    worksheetUnColl.getColumn(7).width = 20;
    worksheetUnColl.getColumn(8).width = 20;
    worksheetUnColl.getColumn(9).width = 20;
    worksheetUnColl.getColumn(10).width = 20;
    worksheetUnColl.getColumn(11).width = 20;
    worksheetUnColl.getColumn(12).width = 25;
    // Set the header row to bold
    worksheetUnColl.getRow(1).font = { bold: true };
    function formatDate(dateStr) {
      const date = new Date(dateStr);
      const options = {
        weekday: "long",
        month: "long",
        day: "numeric",
        year: "numeric",
      };
      return date.toLocaleDateString("en-US", options);
    }
    // Populate data for All sheet
    data.forEach((item) => {
      const expectedDate = item.expected_date || item.planed_collection_date;
      const actualDate = item.actual_collection_date || "-";
      const variance = expectedDate
        ? item.actual_collection_date
          ? calculateDateDifference(item.actual_collection_date, expectedDate)
          : calculateDateDifference(
              new Date().toLocaleDateString(),
              expectedDate
            )
        : item.planed_collection_date
          ? item.actual_collection_date
            ? calculateDateDifference(
                item.actual_collection_date,
                item.planed_collection_date
              )
            : calculateDateDifference(
                new Date().toLocaleDateString(),
                item.planed_collection_date
              )
          : "-";
      const todaysProgress = item.todays_progress || "-";
      const nextAction = item.next_action || "-";
      const rowData = [
        item.collection_project[0].project_name,
        item.name,
        Number(item.planned_amount).toLocaleString(),
        Number(item.actual_amount).toLocaleString(),
        Number(item.planned_amount - item.actual_amount).toLocaleString(),
        formatDate(expectedDate),
        formatDate(actualDate),
        variance,
        item.collection_project[0].users.find(
          (user) =>
            user.pivot.role_id === "0e324e94-6f2c-415c-9a46-a359a96fea7f"
        )?.name || "-",
        todaysProgress,
        nextAction,
      ];

      worksheetAll.addRow(rowData).eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFFFF" },
        };
        cell.font = {
          color: { argb: "000000" },
          bold: true,
          size: 9,
          name: "Times New Roman",
        };
        cell.border = {
          top: { style: "thin", color: { argb: "000000" } },
          left: { style: "thin", color: { argb: "000000" } },
          bottom: { style: "thin", color: { argb: "000000" } },
          right: { style: "thin", color: { argb: "000000" } },
        };
        cell.alignment = { vertical: "middle", horizontal: "right" };
      });
    });

    // Populate data for Collected Payment sheet
    collectedPayment.forEach((item) => {
      const expectedDate = item.expected_date || item.planed_collection_date;
      const actualDate = item.actual_collection_date || "-";
      const variance = expectedDate
        ? item.actual_collection_date
          ? calculateDateDifference(item.actual_collection_date, expectedDate)
          : calculateDateDifference(
              new Date().toLocaleDateString(),
              expectedDate
            )
        : item.planed_collection_date
          ? item.actual_collection_date
            ? calculateDateDifference(
                item.actual_collection_date,
                item.planed_collection_date
              )
            : calculateDateDifference(
                new Date().toLocaleDateString(),
                item.planed_collection_date
              )
          : "-";
      const todaysProgress = item.todays_progress || "-";
      const nextAction = item.next_action || "-";
      const rowData = [
        item.collection_project[0].project_name,
        item.name,
        Number(item.planned_amount).toLocaleString(),
        Number(item.actual_amount).toLocaleString(),
        Number(item.planned_amount - item.actual_amount).toLocaleString(),
        formatDate(expectedDate),
        formatDate(actualDate),
        variance,
        item.collection_project[0].users.find(
          (user) =>
            user.pivot.role_id === "0e324e94-6f2c-415c-9a46-a359a96fea7f"
        )?.name || "-",
        todaysProgress,
        nextAction,
      ];

      worksheetColl.addRow(rowData).eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFFFF" },
        };
        cell.font = {
          color: { argb: "000000" },
          bold: true,
          size: 9,
          name: "Times New Roman",
        };
        cell.border = {
          top: { style: "thin", color: { argb: "000000" } },
          left: { style: "thin", color: { argb: "000000" } },
          bottom: { style: "thin", color: { argb: "000000" } },
          right: { style: "thin", color: { argb: "000000" } },
        };
        cell.alignment = { vertical: "middle", horizontal: "right" };
      });
    });

    // Populate data for UnCollected Payment sheet
    unCollectedPayment.forEach((item) => {
      const expectedDate = item.expected_date || item.planed_collection_date;
      const actualDate = item.actual_collection_date || "-";
      const variance = expectedDate
        ? item.actual_collection_date
          ? calculateDateDifference(item.actual_collection_date, expectedDate)
          : calculateDateDifference(
              new Date().toLocaleDateString(),
              expectedDate
            )
        : item.planed_collection_date
          ? item.actual_collection_date
            ? calculateDateDifference(
                item.actual_collection_date,
                item.planed_collection_date
              )
            : calculateDateDifference(
                new Date().toLocaleDateString(),
                item.planed_collection_date
              )
          : "-";
      const todaysProgress = item.todays_progress || "-";
      const nextAction = item.next_action || "-";
      const rowData = [
        item.collection_project[0].project_name,
        item.name,
        Number(item.planned_amount).toLocaleString(),
        Number(item.actual_amount).toLocaleString(),
        Number(item.planned_amount - item.actual_amount).toLocaleString(),
        formatDate(expectedDate),
        formatDate(actualDate),
        variance,
        item.collection_project[0].users.find(
          (user) =>
            user.pivot.role_id === "0e324e94-6f2c-415c-9a46-a359a96fea7f"
        )?.name || "-",
        todaysProgress,
        nextAction,
      ];

      worksheetUnColl.addRow(rowData).eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFFFF" },
        };
        cell.font = {
          color: { argb: "000000" },
          bold: true,
          size: 9,
          name: "Times New Roman",
        };
        cell.border = {
          top: { style: "thin", color: { argb: "000000" } },
          left: { style: "thin", color: { argb: "000000" } },
          bottom: { style: "thin", color: { argb: "000000" } },
          right: { style: "thin", color: { argb: "000000" } },
        };
        cell.alignment = { vertical: "middle", horizontal: "right" };
      });
    });

    const totalPlannedAmountAll = data.reduce(
      (acc, item) => acc + Number(item.planned_amount),
      0
    );
    const totalActualAmountAll = data.reduce(
      (acc, item) => acc + Number(item.actual_amount),
      0
    );
    const totalUncollectedAmountAll = data.reduce(
      (acc, item) => acc + Number(item.planned_amount - item.actual_amount),
      0
    );
    const totalPlannedAmountColl = collectedPayment.reduce(
      (acc, item) => acc + Number(item.planned_amount),
      0
    );
    const totalActualAmountColl = collectedPayment.reduce(
      (acc, item) => acc + Number(item.actual_amount),
      0
    );
    const totalUncollectedAmountColl = collectedPayment.reduce(
      (acc, item) => acc + Number(item.planned_amount - item.actual_amount),
      0
    );
    const totalPlannedAmountUncoll = unCollectedPayment.reduce(
      (acc, item) => acc + Number(item.planned_amount),
      0
    );
    const totalActualAmountUncoll = unCollectedPayment.reduce(
      (acc, item) => acc + Number(item.actual_amount),
      0
    );
    const totalUncollectedAmountUncoll = unCollectedPayment.reduce(
      (acc, item) => acc + Number(item.planned_amount - item.actual_amount),
      0
    );

    const footerDataAll = [
      "Total",
      "",
      totalPlannedAmountAll.toLocaleString(),
      totalActualAmountAll.toLocaleString(),
      totalUncollectedAmountAll.toLocaleString(),
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ];
    const footerDataColl = [
      "Total",
      "",
      totalPlannedAmountColl.toLocaleString(),
      totalActualAmountColl.toLocaleString(),
      totalUncollectedAmountColl.toLocaleString(),
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ];
    const footerDataUncoll = [
      "Total",
      "",
      totalPlannedAmountUncoll.toLocaleString(),
      totalActualAmountUncoll.toLocaleString(),
      totalUncollectedAmountUncoll.toLocaleString(),
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ];

    const footerRow1 = worksheetAll.addRow(footerDataAll);
    headers.forEach((_, index) =>
      styleHeaderCell(footerRow1.getCell(index + 1))
    );
    const footerRow2 = worksheetColl.addRow(footerDataColl);
    headers.forEach((_, index) =>
      styleHeaderCell(footerRow2.getCell(index + 1))
    );
    const footerRow3 = worksheetUnColl.addRow(footerDataUncoll);
    headers.forEach((_, index) =>
      styleHeaderCell(footerRow3.getCell(index + 1))
    );

    // Save the workbook and download the Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "Payment Collection.xlsx");
  };

  return (
    <button onClick={downloadExcel} className="add-client-representative">
      {" "}
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14V11H2V14H14V11H16V14C16 14.55 15.8043 15.021 15.413 15.413C15.021 15.8043 14.55 16 14 16H2ZM8 12L3 7L4.4 5.55L7 8.15V0H9V8.15L11.6 5.55L13 7L8 12Z"
          fill="white"
        />
      </svg>
    </button>
  );
};

export default AllprojectPaymentCollectionReport;
