import { display } from "@mui/system";
import { Core } from "@pdftron/webviewer";
import React, { useState } from "react";
import { useEffect } from "react";

export default function Comparison(props) {
  const [editable, setEditable] = useState(false);
  const field = document.getElementById(props?.id);
  if (editable === true) {
    field?.removeAttribute("disabled");
  } else if (editable === false) {
    field?.setAttribute("disabled", "");
  }
  const [textareaValue, setValue] = useState(props?.text);
  let btn = null;
  function copied(classname) {
    btn = classname;
    const svg = document.getElementById(classname);
    svg.style.display = "none";
    document.getElementById("tl-tip" + props?.id).style.display = "flex";
    setTimeout(displayNone, 3000);
  }
  function displayNone() {
    document.getElementById("tl-tip" + props?.id).style.display = "none";
    document.getElementById(btn).style.display = "flex";
  }

  useEffect(() => {
    // generateDocument
  });
  return (
    <div className="comparison">
      <div className="terms">
        <div className="term">
          <div>{props?.Title}</div>

          <button
            className="copy-btn"
            id={"copy-svg" + props?.id}
            onClick={(event) => {
              props.toClipboard(props?.Title);
              copied(event.target.id);
            }}
          >
            Copy
          </button>
          <div id={"tl-tip" + props?.id} style={{ display: "none" }}>
            Copied
          </div>
        </div>
        <textarea
          rows={props?.big ? 4 : 3}
          type={"text"}
          className="term-values "
          disabled
          id={props?.id}
          onChange={(e) => props?.setters(e.target.value)}
        >
          {textareaValue}
        </textarea>
      </div>
      <div className="controllers">
        <input type="checkbox" name="" id="" className="check-box-ci" />
        <button className="edit-term" onClick={(e) => setEditable(true)}>
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.375 15.4118H2.38333L12.5354 5.2597L11.5271 4.25137L1.375 14.4035V15.4118ZM15.4458 4.27429L12.5125 1.34095L13.475 0.378452C13.7347 0.11873 14.0594 -0.00731151 14.449 0.000327381C14.8385 0.00796627 15.1632 0.141647 15.4229 0.401369L16.4083 1.38679C16.6681 1.64651 16.7979 1.96734 16.7979 2.34929C16.7979 2.73123 16.6681 3.05206 16.4083 3.31179L15.4458 4.27429ZM0.6875 16.7868C0.488889 16.7868 0.324653 16.7219 0.194792 16.592C0.0649306 16.4621 0 16.2979 0 16.0993V14.1285C0 14.0368 0.0152777 13.9528 0.0458333 13.8764C0.0763888 13.8 0.129861 13.7236 0.20625 13.6472L11.55 2.30345L14.4833 5.23679L3.13958 16.5805C3.06319 16.6569 2.98681 16.7104 2.91042 16.741C2.83403 16.7715 2.75 16.7868 2.65833 16.7868H0.6875ZM12.0312 4.75554L11.5271 4.25137L12.5354 5.2597L12.0312 4.75554Z"
              fill="#FFD752"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}
