import React, { useState } from "react";
import { IMG_BASE_URL } from "../../api/endPoint";
import {
  useDeleteSolutionMutation,
  useGetProductsQuery,
  useGetUserInfoQuery,
} from "../../features/SCMApi";
import ConfirmDelete from "../common/ConfirmDelete";
import AddNewSolution from "./AddNewSolution";
import "./products.style.css";
import ViewSolution from "./ViewSolution";
import AddIcon from "@mui/icons-material/Add";
import Productsidebar from "../../pages/products/Index";
import CheckPermission from "../common/checkPermission/checkPermission";
import NoRecord from "../common/NoRecord";
import usePagination from "../Pagination/Pagination";
import { Pagination } from "@mui/material";
import NavBar from "../../Layout/NavBar";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import SuccessMessage from "../Payment/SuccessMessage";

export default function Products(props) {
  let avatar = [];
  const [department, setDepartment] = useState();

  const [addSolutionModalToggle, setAddSolutionModal] = useState(false);
  const [viewSolutionModalToggle, setViewSolutionModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [count, setCount] = useState(0);
  const [selectedComponent, setComponent] = useState("");

  function handleAddSolutionModal() {
    setViewSolutionModal(false);
    setAddSolutionModal(true);
  }
  function handleViewSolutionModal() {
    setAddSolutionModal(false);
    setViewSolutionModal(true);
  }

  const {
    data: Departments,
    isError,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetProductsQuery();
  // function handleClick(e) {

  //   if (count == 0) {
  //     setComponent(e.target.id);
  //     setModal(true);
  //     setCount(1);
  //   }
  //   if (count == 1) {
  //     if (selectedComponent == e.target.id) {
  //       setComponent("");
  //       setCount(0);
  //     } else {
  //       setComponent(e.target.id);
  //       setModal(true);
  //       setCount(1);
  //     }
  //   }
  // }


  // axios.post(`${API_BASE_URL}/scm/project/projects/remove-solutions`, form, {
  //   headers: {
  //     "Content-Type": "multipart/form-data",
  //   },
  // });
  // };
  const [search, setSearch] = useState("");
  successMessage &&
    setTimeout(() => {
      setSuccessMessage(false);
    }, 3000);
  const handleSearchChange = (e) => {
    setSearch(e.target.value);

  };
  const { data: userinfo } = useGetUserInfoQuery();
  let [page, setPage] = React.useState(1);
  const PER_PAGE = 6;
  const countPage = Math.ceil(Departments?.data?.length / PER_PAGE);
  const _DATA = usePagination(Departments?.data, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    // <Productsidebar
    //   name={"Solution"}
    //   placeholder={"search product..."}
    //   handleSearchChange={handleSearchChange}
    // >
    <>
      <NavBar
        placeholder={props?.placeholder}
        handleSearchChange={props.handleSearchChange}
      />
      <div className="all-breadcrumb ms-0">
        <Breadcrumb className="ms-0">
          <Breadcrumb.Item className="prg" href="/product">
            Solutions
          </Breadcrumb.Item>
          {props.name === undefined ? (
            ""
          ) : (
            <Breadcrumb.Item className="prg-pri" active>
              {props.name}
            </Breadcrumb.Item>
          )}
        </Breadcrumb>
      </div>
      <div className="page">
        <div className="block">
          <>
            <div>
              {CheckPermission("product_add_solution", userinfo) ? (
                <div
                  className="create-project-btn mt-4"
                  onClick={handleAddSolutionModal}
                >
                  <AddIcon />
                  Add New
                </div>
              ) : (
                ""
              )}
              {successMessage ? (
                <SuccessMessage
                  style={{ marginTop: "10px", backgroundColor: "#52ffab96" }}
                  Message={message}
                />
              ) : (
                <></>
              )}
              {Departments?.data.length !== 0 ? (
                <div className="product-container">
                  {_DATA
                    ?.currentData()
                    ?.filter((items) => {
                      if (search === "") {
                        return items;
                      } else if (
                        items.abbrivation?.toLowerCase().includes(search)
                      ) {
                        return items;
                      }
                    })
                    ?.map((items) => (
                      <div className="product-card">
                        <div
                          className="product-card-content"
                          onClick={() => {
                            setDepartment(items);
                            handleViewSolutionModal();
                          }}
                        >
                          <div className="product-card-detail">
                            <div className="product-team">
                              {items?.abbrivation}
                            </div>
                            <div>
                              <div className="product-quantity">
                                {items.solution.length}
                              </div>
                              <div className="product-type">sub solutions</div>
                            </div>
                          </div>
                          <div className="product-divider"></div>
                          <div className="product-card-pmr">
                            <div className="pmr-img">
                              {items?.pmr?.avatar != null ? (
                                <img
                                  src={`${IMG_BASE_URL}${items?.pmr?.avatar}`}
                                  alt=""
                                />
                              ) : (
                                <div className="default-avatar">
                                  <div style={{ display: "none" }}>
                                    {(avatar = items?.pmr?.name?.split(" "))}
                                  </div>
                                  {
                                    (avatar =
                                      items?.pmr == null
                                        ? ""
                                        : avatar[0]?.charAt(0))
                                  }
                                  {
                                    (avatar =
                                      items?.pmr == null
                                        ? ""
                                        : avatar[1]?.charAt(0))
                                  }
                                </div>
                              )}
                            </div>
                            <div className="pmr-name">{items?.pmr?.name}</div>
                            <div className="designation"></div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              ) : (
                <NoRecord />
              )}
              <div className="faq-pagination-container">
                {countPage > 1 ? (
                  <Pagination
                    count={countPage}
                    size="large"
                    page={page}
                    variant="outlined"
                    shape="rounded"
                    onChange={handleChange}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      margin: "5px",
                    }}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            {viewSolutionModalToggle ? (
              <ViewSolution
                solutions={department}
                ToggleModal={setViewSolutionModal}
                setSuccessMessage={setSuccessMessage}
                setMessage={setMessage}
              />
            ) : (
              <> </>
            )}
            {addSolutionModalToggle ? (
              <AddNewSolution
                closeModal={setAddSolutionModal}
                successComponent={setSuccessMessage}
                setMessage={setMessage}
              />
            ) : (
              <> </>
            )}
          </>
        </div>
      </div>
    </>
    // </Productsidebar>
  );
}
