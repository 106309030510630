import React, { useState } from "react";
import { useEditClientMutation } from "../../features/SCMApi";
import axios from "axios";
import { API_BASE_URL } from "../../api/endPoint";

export default function EditClient(props) {
  const [client_name, setClient_name] = useState(props?.clientName);
  const [postal_code, setPostal_code] = useState(props?.clientPost);
  const [address, setAddress] = useState(props?.clientAddrese);
  const [telephone, setTelephone] = useState(props?.clientTelephone);

  const [id, setID] = useState(props?.clientId);

  const [updateClient, response] = useEditClientMutation();
  const form = { client_name, postal_code, address, telephone, id };
  const HandleCancel = () => {
    props?.modalToggle(false);
  };
  const HandleSubmit = (e) => {
    e.preventDefault();
    
    updateClient(form)
      .unwrap()
      .then((response) => {
        HandleCancel();
        
      })
      .then((error) => {
       
      });
  };

  return (
    <div>
      <div id="myModal" className="modal">
        <div
          className="setting-projectModal"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="setting-create-project-modal-title clientRepModal">
            <div className="addClientTitle">Edit Client</div>
            <div onClick={HandleCancel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="42"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>
          <form className="form-container" onSubmit={HandleSubmit}>
            <div className="form-card-container">
              <div className="form-card-information">
                <div className="">
                  <div className="card-center">
                    <div className="card-form">
                      <div className="setting-projectModal-input-labels">
                        <div className="input-label project-inputs">
                          Client Name
                        </div>
                        <div className="input-label project-inputs">
                          Address
                        </div>
                        <div className="input-label project-inputs">
                          P.O Box
                        </div>
                        <div className="input-label project-inputs">
                          Telephone
                        </div>
                      </div>
                      <div className="input-fields">
                        <input
                          type="text"
                          className="setting-projectModal-input"
                          defaultValue={client_name}
                          onChange={(e) => setClient_name(e.target.value)}
                        />
                        <input
                          type="text"
                          className="setting-projectModal-input"
                          defaultValue={address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                        <input
                          type="text"
                          className="setting-projectModal-input"
                          defaultValue={postal_code}
                          onChange={(e) => setPostal_code(e.target.value)}
                        />
                        <input
                          type="text"
                          className="setting-projectModal-input"
                          defaultValue={telephone}
                          onChange={(e) => setTelephone(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-buttons clientForm">
                <button
                  type="cancel"
                  className="form-cancel clientCancel"
                  onClick={HandleCancel}
                >
                  {" "}
                  Cancel
                </button>
                <button
                  type="submit"
                  className="form-save clientSave"
                  onClick={HandleSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
