import React from "react";
import Grid from "@mui/material/Grid";

const DeliveredItemDetailStagesContainer = (props) => {
  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={3.5}
        xl={3.5}
        xxl={3.5}
        marginTop={{
          xs: "20px",
          sm: "20px",
          md: "20px",
          lg: "0px",
          xl: "0px",
          xxl: "0px",
        }}
        marginRight={{ xs: "16px", sm: "0px", md: "0px" }}
        marginLeft={{ xs: "16px", sm: "0px", md: "0px" }}
      >
        {props?.children}
      </Grid>
    </>
  );
};

export default DeliveredItemDetailStagesContainer;
