import React, { useState } from "react";
import {
  useGetCurrenciesQuery,
  useGetProjectsQuery,
  useGetSuppliersOrdersQuery,
} from "../../../features/SCMApi";
import "./aging.css";

export default function PayableFilter({
  searchCurrency,
  searchSupplier,
  searchProject,
  searchDate,
  handleSearchSubmit,
  setSearchCurrency,
  setSearchProject,
  setSearchSupplier,
  setSearchDate,
}) {
  const [handleClearSearch, setHandleClearSearch] = useState("");
  const handleClearSelection = () => {
    setHandleClearSearch("");
  };
  //==========> Project Filter Data <=============
  const { data: project } = useGetProjectsQuery();
  const [selectedProject, setProject] = useState();
  const list = [];
  project?.data?.map((items) => {
    if (items?.isActive == 1)
      list.push({
        value: items?.id,
        label: items?.project_name,
      });
  });


  //==========> Supplier Filter Data <=============

  const [supplier_id, setSupplier] = useState(null);
  const suppliersList = [];
  const { data: Suppliers } = useGetSuppliersOrdersQuery();
  Suppliers?.data?.map((items) =>
    suppliersList.push({
      value: items?.id,
      label: items?.distributor_name,
    })
  );

  //==========> Currency Filter Data <=============

  const [currency_id, setCurrency] = useState();
  const currenciesList = [];
  const { data: currencies } = useGetCurrenciesQuery();
  currencies?.data?.map((items) =>
    currenciesList.push({
      value: items?.id,
      label: items?.code,
    })
  );

  //=========> Date Filter <============

  return (
    <div>
      <form className="payable-selector" onSubmit={handleSearchSubmit}>
        {/* Project Selection */}
        <div>
          <select
            className="custom-select prg"
            name="project"
            id="project"
            placeholder="project"
            value={searchProject}
            onChange={(e) => setSearchProject(e.target.value)}
          >
            <option value="">Select a project</option>
            {list.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
            {searchProject && (
              <button
                type="button"
                className="btn-close"
                onClick={handleClearSelection}
              ></button>
            )}
          </select>
        </div>

        {/* Currency Selection */}
        <div>
          <select
            className="custom-select prg"
            name="currency"
            id="currency"
            placeholder="currency"
            value={searchCurrency}
            onChange={(e) => setSearchCurrency(e.target.value)}
          >
            <option value="">Select currency</option>
            {currenciesList.map((item) => (
              <option key={item.value} value={item.label}>
                {item.label}
              </option>
            ))}
          </select>
        </div>

        {/* Supplier Selection */}

        <div>
          <select
            className="custom-select prg"
            name="supplier"
            id="supplier"
            placeholder="supplier"
            value={searchSupplier}
            onChange={(e) => setSearchSupplier(e.target.value)}
          >
            <option value="">Select supplier</option>
            {suppliersList.map((item) => (
              <option key={item.value} value={item.label}>
                {item.label}
              </option>
            ))}
          </select>
        </div>

        {/* Date Selection */}

        <div>
          <input
            className="custom-select prg "
            type="date"
            placeholder="Select Date"
            value={searchDate}
            onChange={(e) => setSearchDate(e.target.value)}
          />
        </div>
      </form>
    </div>
  );
}
