import * as React from "react";
import Grid from "@mui/material/Grid";
import "../logistics.css";
import RmaDetailOrderList from "./rmaDetailComponent/RmaDetailOrderList";
import RmaDetailExport from "./rmaDetailComponent/RmaDetailExport";
import RmaDetailImport from "./rmaDetailComponent/RmaDetailImport";
import RmaDetailExImContainer from "./rmaDetailComponent/RmaDetailExImContainer";
// import useMediaQuery from "@mui/material/useMediaQuery";

export default function RmaBatchInfo(props) {
  // const matches = useMediaQuery("(min-width:600px)");
  return (
    <>
      <Grid container spacing={2}>
        <RmaDetailOrderList data={props?.data?.items} />
        <RmaDetailExImContainer>
          <RmaDetailExport
            rmaId={props?.data?.id}
            exportData={props?.data}
            batchId={props?.data?.batch?.id}
            successMessage={props.successMessage}
            message={props.message}
          />
          <RmaDetailImport
            rmaId={props?.data?.id}
            importData={props?.data}
            batchId={props?.data?.batch?.id}
            successMessage={props.successMessage}
            message={props.message}
          />
        </RmaDetailExImContainer>
      </Grid>
    </>
  );
}
