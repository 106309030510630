import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { SCMApi } from "./SCMApi";
// import projectReducer from "./../features/ProjectSlice";
export const store = configureStore({
  reducer: {
    [SCMApi.reducerPath]: SCMApi.reducer,
    //  projects: projectReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(SCMApi.middleware),
});
