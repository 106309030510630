import * as React from "react";
import Grid from "@mui/material/Grid";
import "../logistics.css";
import { Box } from "@mui/material";
import RmaETD from "./rmaHeadingComponent/RmaETD";
import RmaProfile from "./rmaHeadingComponent/RmaProfile";
import RmaOrigin from "./rmaHeadingComponent/RamOrigin";
import RmaStatus from "./rmaHeadingComponent/RmaStatus";
import SuccessMessage from "../../Payment/SuccessMessage";

export default function RmaDetailsHeading(props) {
  const [successMessage, setSuccessMessage] = React.useState(false);
  const [message, setMessage] = React.useState("");
  successMessage &&
    setTimeout(() => {
      setSuccessMessage(false);
    }, 3000);
  return (
    <Box marginTop={"20px"}>
      <Grid container spacing={{ xs: 2, md: 2 }}>
        {successMessage === true ? (
          <>
            <SuccessMessage
              style={{
                marginTop: "10px",
                marginBottom: "10px",
                width: "98.5%",
                marginLeft: "17px",
                backgroundColor: "#52ffab96",
                mb: "-10px",
              }}
              Message={message}
            />
          </>
        ) : (
          <></>
        )}
        <RmaETD
          data={props?.data}
          setSuccessMessage={setSuccessMessage}
          setMessage={setMessage}
        />
        <RmaProfile data={props?.data} />
        <RmaOrigin data={props?.data} />
        <RmaStatus
          data={props?.data}
          setSuccessMessage={setSuccessMessage}
          setMessage={setMessage}
        />
      </Grid>
    </Box>
  );
}
