import { useState } from "react";
import { useCreateLessonLearnedMutation } from "../../../features/SCMApi";

export default function LessonLearnedModal(props) {
  const [description, setLessonLearned] = useState(null);
  const [recomendation, setRecommendation] = useState(null);
  const [lessonLearned] = useCreateLessonLearnedMutation();
  let form = { id: props?.data?.id, description, recomendation };
  function handleSubmit(e) {
    e.preventDefault();

    lessonLearned(form)
      .unwrap()
      .then((response) => {
       
        props?.modal(false);
      })
      .catch((error) => {
       
      });
  }
  return (
    <div
      id="Modal"
      className="dialog-modal "
      onClick={(e) => {
        props?.modal(false);
      }}
    >
      <div
        className="stock-dialog-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="dialog-info">Create Lesson Learned</div>

        <div className="d-grid gap-2 align-items-baseline">
          <div className="supplier-input-label justify-content-start">
            Lesson Learned
          </div>
          <textarea
            cols="30"
            rows="5"
            onChange={(e) => setLessonLearned(e.target.value)}
            className="finance-input-field m-0"
          ></textarea>
          <div className="supplier-input-label justify-content-start">
            Recommendation
          </div>
          <textarea
            cols="30"
            rows="5"
            onChange={(e) => setRecommendation(e.target.value)}
            className="finance-input-field m-0"
          ></textarea>
        </div>

        <div className="confirm-dialog-controller">
          <div
            className="dialog-cancel-btn"
            onClick={(e) => {
              props?.modal(false);
            }}
          >
            No
          </div>
          <button
            type={"submit"}
            onClick={(e) => {
              handleSubmit(e);
            }}
            className={"form-save"}
          >
            Create
          </button>
        </div>
      </div>
    </div>
  );
}
