import React from "react";
import NoRecord from "../../common/NoRecord";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { useAccordionButton } from "react-bootstrap";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useState } from "react";
import AllPrice from "./AllPrice";
export default function PriceComparisonAccordion({ datas, compared_data }) {
  let count = 0;
  const [up, setUp] = useState(true);
  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => setUp(!up));
    return (
      <p className="setting-accordion-p mb-0" onClick={decoratedOnClick}>
        {children}
      </p>
    );
  }
  const data = datas == undefined ? [] : datas;
  const comparedData = compared_data == undefined ? [] : compared_data;

  

  return (
    <div className="p-3">
      <div className="">
        <Accordion>
          <Card className="mb-2 mt-0 stockAccordionCardAccordionCard ">
            <div
              className={`card-header incomingTitle ${
                data?.length >= 2 ? "bg-success" : ""
              }`}
            >
              <CustomToggle eventKey="0">
                <div className="d-flex align-items-center">
                  <div className="paymentTrackingProjectName text-white">
                    Final Price
                  </div>
                  {up ? (
                    <KeyboardArrowDownIcon sx={{ color: "white" }} />
                  ) : (
                    <KeyboardArrowUpIcon sx={{ color: "white" }} />
                  )}
                </div>
              </CustomToggle>
            </div>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <div>
                  {data?.final_list_price?.length !== 0 ? (
                    <>
                      <table style={{ width: "100%" }}>
                        <thead className="stock-table-header">
                          <tr>
                            <th
                              className="stock-table-header-text text-center"
                              style={{ width: "3%" }}
                            >
                              No
                            </th>

                            <th
                              className="stock-table-header-text text-center"
                              style={{ width: "10%" }}
                            >
                              Supplier
                            </th>
                            <th
                              className="stock-table-header-text text-center"
                              style={{ width: "10%" }}
                            >
                              {data?.[0]?.item_part_number
                                ? "Item"
                                : data?.[0]?.course
                                  ? "Course"
                                  : ""}
                            </th>
                            <th
                              className="stock-table-header-text text-center"
                              style={{ width: "20%" }}
                            >
                              {data?.[0]?.item_part_number
                                ? "Item Description"
                                : data?.[0]?.course
                                  ? "Course Description"
                                  : ""}
                            </th>

                            <th
                              className="stock-table-header-text text-center"
                              style={{ width: "10%" }}
                            >
                              UoM
                            </th>
                            <th
                              className="stock-table-header-text text-center"
                              style={{ width: "10%" }}
                            >
                              Qty
                            </th>
                            <th
                              className="stock-table-header-text text-center"
                              style={{ width: "10%" }}
                            >
                              Unit Price
                            </th>
                            <th
                              className="stock-table-header-text text-center"
                              style={{ width: "10%" }}
                            >
                              Total Price
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.map((items, orderindex) => (
                            <tr className="stock-table-row">
                              <td className="text-center">{(count += 1)}</td>
                              <td className="text-start">
                                {items?.SupplierName}
                              </td>
                              <td className="text-start">
                                {items?.item_part_number
                                  ? items?.item_part_number
                                  : items?.course
                                    ? items?.course
                                    : ""}
                              </td>

                              <td className="text-center">
                                {items?.item_description
                                  ? items?.item_description
                                  : items?.course_description
                                    ? items?.course_description
                                    : ""}

                                {items?.item_description}
                              </td>
                              <td className="text-center">{items?.uom}</td>
                              <td className="text-center">{items?.qty}</td>
                              <td className="text-center">
                                {Number(items?.unit_cost)?.toLocaleString()}
                              </td>
                              <td className={`text-center`}>
                                {Number(items?.total_cost)?.toLocaleString()}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    <NoRecord />
                  )}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>

      <AllPrice comparedData={comparedData} />
    </div>
  );
}
