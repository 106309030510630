import React, { useEffect, useState } from "react";
import { FaArrowDown } from "react-icons/fa6";
import Pagination from "@mui/material/Pagination";
import { API_BASE_URL } from "../../../api/endPoint";
import axios from "axios";
import "./aging.css";

const PayableSummarizedTable = () => {
  const [payableSummary, setPayableSummary] = useState([]);
  const [currencyPages, setCurrencyPages] = useState({});

  const currencies = [
    { type: "ETB" },
    { type: "USD" },
    { type: "AED" },
    { type: "EUR" },
    { type: "CAD" },
    { type: "Yuan" },
    { type: "GBR" },
    { type: "ZAR" },
    { type: "other" },
  ];

  const itemsPerPage = 3;

  useEffect(() => {
    const initialPages = currencies.reduce((acc, currency) => {
      acc[currency.type] = 1;
      return acc;
    }, {});
    setCurrencyPages(initialPages);
  }, []);

  const handlePageChange = (currencyType, event, page) => {
    setCurrencyPages((prevPages) => ({
      ...prevPages,
      [currencyType]: page,
    }));
  };

  const getPaginatedDataForCurrency = (currencyType) => {
    const filteredData = payableSummary?.filter(
      (payables) => payables?.Currency === currencyType
    );
    const currentPage = currencyPages[currencyType];
    return filteredData?.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/scm/sourcing/supplier/payable-aging_summary`
        );
        setPayableSummary(response?.data?.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  const computeSumByRange = (rangeKey) => {
    return payableSummary.reduce((acc, supplier) => {
      const currency = supplier.Currency || "Unknown";
      const overdueAmount = supplier[rangeKey];

      acc[currency] = (acc[currency] || 0) + overdueAmount;
      return acc;
    }, {});
  };

  const sumCurrent_upcoming = computeSumByRange("current_upcoming");
  const sum1_30 = computeSumByRange("DaysOverDue1_30");
  const sum31_60 = computeSumByRange("DaysOverDue31_60");
  const sum61_90 = computeSumByRange("DaysOverDue61_90");
  const sum91_120 = computeSumByRange("DaysOverDue91_120");
  const sum121_150 = computeSumByRange("DaysOverDue121_150");
  const sum151Above = computeSumByRange("DaysOverDue151Above");


  return (
    <div className="table-responsive border prg">
      <table className="table">
        <thead>
          <tr
            style={{
              backgroundColor: "rgba(86, 184, 183, 0.2)",
              color: "#304569",
            }}
          >
            <th>No.</th>
            <th>Supplier</th>
            <th>Current & Upcoming</th>
            <th>1-30 Days Due</th>
            <th>31-60 Days Due</th>
            <th>61-90 Days Due</th>
            <th>91-120 Days Due</th>
            <th>121-150 Days Due</th>
            <th>150 Days & above</th>
            <th>Total</th>
          </tr>
        </thead>

        {currencies?.map((currency) => {
          const filteredPayableSummary = payableSummary?.filter(
            (payables) => payables?.Currency === currency.type
          );
          if (filteredPayableSummary.length === 0) {
            return null;
          }
          const paginatedData = getPaginatedDataForCurrency(currency.type);
          const startCounter =
            (currencyPages[currency.type] - 1) * itemsPerPage + 1;

          const grandTotalForCurrency =
            (sumCurrent_upcoming[currency.type] || 0) +
            (sum1_30[currency.type] || 0) +
            (sum31_60[currency.type] || 0) +
            (sum61_90[currency.type] || 0) +
            (sum91_120[currency.type] || 0) +
            (sum121_150[currency.type] || 0) +
            (sum151Above[currency.type] || 0);
          return (
            <tbody key={currency.type}>
              <tr className="text-start">
                <th
                  colSpan={1}
                  style={{ backgroundColor: "rgba(0, 162, 253, 0.7)" }}
                ></th>
                <th
                  colSpan={9}
                  style={{ backgroundColor: "rgba(0, 162, 253, 0.7)" }}
                >
                  {currency.type}
                </th>
              </tr>
              {paginatedData?.map((payables, index) => {
                const counter = startCounter + index; // Adjusted counter based on startCounter
                return (
                  <>
                    <tr key={index} style={{ backgroundColor: "#eef8f8" }}>
                      <td>{counter}</td>
                      <td>{payables?.supplier_name}</td>
                      <td>{payables?.current_upcoming.toLocaleString()}</td>
                      <td>{payables?.DaysOverDue1_30.toLocaleString()}</td>
                      <td>{payables?.DaysOverDue31_60.toLocaleString()}</td>
                      <td>{payables?.DaysOverDue61_90.toLocaleString()}</td>
                      <td>{payables?.DaysOverDue91_120.toLocaleString()}</td>
                      <td>{payables?.DaysOverDue121_150.toLocaleString()}</td>
                      <td>{payables?.DaysOverDue151Above.toLocaleString()}</td>
                      <td>{payables?.Total.toLocaleString()}</td>
                    </tr>
                  </>
                );
              })}

              <tr style={{ backgroundColor: "#eef8f8", fontWeight: "500" }}>
                <td
                  colSpan={2}
                  style={{ textAlign: "right", fontWeight: "600" }}
                  className="prg"
                >
                  Total
                </td>
                <td>
                  {(sumCurrent_upcoming[currency.type] || 0).toLocaleString()}
                </td>
                <td>{(sum1_30[currency.type] || 0).toLocaleString()}</td>
                <td>{(sum31_60[currency.type] || 0).toLocaleString()}</td>
                <td>{(sum61_90[currency.type] || 0).toLocaleString()}</td>
                <td>{(sum91_120[currency.type] || 0).toLocaleString()}</td>
                <td>{(sum121_150[currency.type] || 0).toLocaleString()}</td>
                <td>{(sum151Above[currency.type] || 0).toLocaleString()}</td>
                <td>{grandTotalForCurrency.toLocaleString()}</td>
              </tr>
              <tr>
                <td colSpan={10} style={{ textAlign: "center" }}>
                  <Pagination
                    style={{ display: "flex", justifyContent: "right" }}
                    count={Math.ceil(
                      filteredPayableSummary.length / itemsPerPage
                    )}
                    page={currencyPages[currency.type] || 1}
                    onChange={(event, page) =>
                      handlePageChange(currency.type, event, page)
                    }
                  />
                </td>
              </tr>
            </tbody>
          );
        })}
      </table>
    </div>
  );
};

export default PayableSummarizedTable;
