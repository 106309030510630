import React, { useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../../api/endPoint";
import "../../../../components/Logistics/logistics.css";
import { useViewPaymentTrackingHistoryQuery } from "../../../../features/SCMApi";
import "../../forex.style.css";
import NoRecord from "../../../common/NoRecord";

export default function PaymentTrackingDetail(props) {
  const poId = props?.previousData?.po_id;
  const { data: paymentHistory } = useViewPaymentTrackingHistoryQuery(poId);

  function HandleCancel() {
    props.modal(false);
  }

  return (
    <>
      <div id="myModal" className="modal" onClick={HandleCancel}>
        <div
          className="mini-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="logisticsRma-create-project-modal-title">
            <div className="updatePayment">Payment History</div>
            <div onClick={HandleCancel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="42"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>
          {paymentHistory?.data?.length !== 0 ? (
            <form className="paymentTrackingForm" onSubmit={""}>
              <div className="paymentTrackingFormCardContainer">
                <div className="paymentDetailOrderSupplier">
                  {props?.previousData?.order_name}
                </div>
                <div className="paymentDetailOrderSupplierName">
                  {props?.previousData?.distributor_name}
                </div>
                <div className="paymentTrackingFormCardInformation">
                  <div className="paymentTrackingCardLeft">
                    <div className="paymentTrackingCardForm">
                      <div className="paymentTrackingViewDetailDateInputLabels">
                        {paymentHistory?.data !== undefined || null
                          ? paymentHistory?.data?.map((paymentHistory) => (
                              <>
                                <div className="paymentDetailInputLabel">
                                  <div>{paymentHistory?.payment_date}</div>
                                </div>
                              </>
                            ))
                          : ""}
                      </div>
                      <div className="paymentTrackingViewDetailBalanceInputLabels">
                        {paymentHistory?.data !== undefined || null
                          ? paymentHistory?.data?.map((paymentHistory) => (
                              <div className="payment-tracking-detail-origin-group">
                                <>
                                  <div className="origin-value">
                                    USD {paymentHistory?.payment_amount}
                                  </div>
                                  <div className="origin-label">
                                    {paymentHistory?.payment_mechanism}
                                  </div>
                                </>
                                <div className="statusHistoryDivider"></div>
                              </div>
                            ))
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          ) : (
            <NoRecord />
          )}
        </div>
      </div>
    </>
  );
}
