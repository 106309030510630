import React, { useState } from "react";
import { useAddClientMutaion } from "../../../../features/SCMApi";
import axios from "axios";
import { API_BASE_URL } from "../../../../api/endPoint";
import "../../../../components/Logistics/logistics.css";
import attachmentIcon from "../../../../assets/SVG/attach.svg";
import { useParams } from "react-router-dom";

export default function RequestLetter(props) {
  function HandleCancel() {
    props.modal(false);
  }

  const forexId = useParams();
  const [requestLetter, setRequestLetter] = useState(null);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  let params = { request_letter: requestLetter, forex_id: forexId?.id };

  const handleChange = (e) => {
    setRequestLetter(e?.target?.files[0]);
    setIsFileSelected(true);
  };

  const handleClick = () => {
    isFileSelected === true ? setErrorMessage(false) : setErrorMessage(true);
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();

    isFileSelected ? (
      props?.previousData?.length === 0 ? (
        axios
          .post(`${API_BASE_URL}/scm/forex/request_letter`, params, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {

            HandleCancel();
            props?.successMessage(true);
            props?.message("Request letter document attached successfully!!!");
          })
          .then((error) => {

          })
      ) : (
        axios
          .post(
            `${API_BASE_URL}/scm/forex/request_letter/${props?.previousData[0]?.id}`,
            params,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {

            HandleCancel();
            props?.successMessage(true);
            props?.message("Request letter document updated successfully!!!");
          })
          .then((error) => {

          })
      )
    ) : (
      <></>
    );

    // addClient(params)
    //   .unwrap()
    //   .then((response) => {
    //   })
    //   .then((error) => {

    //   });
  };

  return (
    <>
      <div id="myModal" className="modal" onClick={HandleCancel}>
        <div
          className="mini-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="logisticsRma-create-project-modal-title">
            <div className="requestLetterTitle">Request Letter</div>
            <div onClick={HandleCancel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="42"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>

          <form
            className="form-container rmaMissedItemForm forexApprovalForms"
            onSubmit={HandleSubmit}
          >
            <div className="form-card-container">
              <div className="form-card-information">
                {props?.previousData?.length !== 0 ? (
                  errorMessage === true ? (
                    <>
                      <p
                        className="forex-attached-file-warning"
                        style={{ marginTop: "-30px" }}
                      >
                        Please select a file !!!
                      </p>
                    </>
                  ) : props?.previousData?.length !== 0 ? (
                    <>
                      <p
                        className="forex-attached-file-warning"
                        style={{ marginTop: "-30px" }}
                      >
                        Request Letter document already attached !!!
                      </p>
                    </>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
                <div className="card-center">
                  <div
                    className="card-form"
                    style={
                      props?.previousData?.length === 0
                        ? { marginTop: "-30px" }
                        : { marginTop: "-50px" }
                    }
                  >
                    <div className="rmaFileInputLabelContainer inputLabelsHolder">
                      <div className="rmaFileInputLabel">Request Letter</div>
                    </div>
                    <div className="input-fields">
                      <div
                        className="file-chooser rmaMissedItemFile-choosers"
                        style={{
                          backgroundImage: `url(${attachmentIcon})`,
                        }}
                      >
                        <input
                          required={
                            props?.previousData?.length === 0 ? true : false
                          }
                          type="file"
                          className="file-input"
                          name="boq"
                          onChange={(e) => handleChange(e)}
                        />
                        <div className="rmaFileUploader">
                          {requestLetter
                            ? requestLetter?.name.slice(0, 10) + "...."
                            : props?.previousData?.length !== 0
                            ? "File exist"
                            : " File"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-buttons rma-form-submit">
                <button
                  type="cancel"
                  className="form-cancel clientCancel"
                  onClick={HandleCancel}
                  style={{ width: "112px", height: "32px" }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="form-save clientSave"
                  style={{ width: "112px", height: "32px" }}
                  onClick={handleClick}
                >
                  {props?.previousData?.length !== 0 ? "Update" : "Save"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
