import { Tooltip } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import {
  useGetOrderItemQuery,
  useGetProjectQuery,
  useGetProjectRolePermissionQuery,
  useGetUserInfoQuery,
} from "../../features/SCMApi";
import CheckPermission from "../common/checkPermission/checkPermission";
import CheckProjectPermission from "../common/checkPermission/CheckProjectPermission";
import NoRecord from "../common/NoRecord";
import Approve from "./Approve";

export default function PaStageManufacturer(props) {
  const params = useParams();
  let new_unit_cost = 0;
  let new_total_cost = 0;
  let gpl_unit_cost = 0;
  let count = 0;
  let gpl_total_cost = 0;
  let profit = 0;
  const { data: orderDetail } = useGetOrderItemQuery(params.id);
  const manufacturers = [];
  const vr = orderDetail?.data?.pa;
  if (orderDetail?.data?.pa) {
    for (const items in vr) {
      if (props.manufacturer_id === undefined) {
        manufacturers.push(vr[items]);

        manufacturers?.map((items, manuindex) =>
          Object.keys(items)?.map((index) =>
            items[index]?.pa?.map(
              (pa, paindex) => (
                (gpl_total_cost += +pa.boq_total_cost),
                (new_total_cost += +pa.pi_total_cost),
                (profit += pa.profit)
              )
            )
          )
        );
      } else {
        const manus = vr[props.manufacturer_id];
        manufacturers[0] = manus;
        manufacturers?.map((items) =>
          Object.keys(items)?.map((index) =>
            items[index]?.pa?.map(
              (pa, paindex) => (
                (gpl_total_cost += +pa.boq_total_cost),
                (new_total_cost += +pa.pi_total_cost),
                (profit += pa.profit)
              )
            )
          )
        );
      }
    }
  }
  const projectid = orderDetail?.data?.orders?.project_id;
  const { data: projectmembers, isSuccess } = useGetProjectQuery(projectid);
  const { data: roleRoles } = useGetProjectRolePermissionQuery(projectid);
  const { data: userinfo } = useGetUserInfoQuery();

  const paData = orderDetail && Object.values(orderDetail?.data?.pa);
  

  return (
    <div className="summary-table">
      {manufacturers?.length !== 0 ? (
        <>
          {orderDetail?.data?.orders?.supplier_id != null ? (
            <div className="summarymanufacturer-header ">
              <div className="no">No</div>
              <div className="no">Item Description</div>
              <div>qty</div>
              <div>BoQ Unit Cost</div>
              <div>BoQ Total Cost</div>
              <div>New Unit Cost</div>
              <div>New Total Cost</div>
              <div>Manufacturer</div>
              <div>Profit</div>
            </div>
          ) : (
            <div className="supplier-summarymanufacturer-header ">
              <div className="no">No</div>
              <div className="no">Supplier</div>
              <div className="no">Item Description</div>
              <div>qty</div>
              <div>BoQ Unit Cost</div>
              <div>BoQ Total Cost</div>
              <div>New Unit Cost</div>
              <div>New Total Cost</div>
              <div>Manufacturer</div>
              <div>Profit</div>
            </div>
          )}
          {manufacturers?.map((items, manuindex) =>
            Object.keys(items)?.map((index) =>
              items[index]?.pa?.map((pa, paindex) =>
                orderDetail?.data?.orders?.supplier_id != null ? (
                  <div className="summaryManufacturer-row p-2">
                    <div className="label-container">
                      <div className="pa-label">No</div>
                      <div>{(count += 1)}</div>
                    </div>
                    <div className="label-container">
                      <div className="pa-label">Item Description</div>
                      <Tooltip title={pa.item_description}>
                        <p className="text-start">
                          {pa?.item_description
                            ? pa?.item_description?.length >= 20
                              ? pa?.item_description?.slice(0, 20) + "..."
                              : pa?.item_description
                            : pa?.course_description
                              ? pa?.course_description?.length >= 20
                                ? pa?.course_description?.slice(0, 20) + "..."
                                : pa?.course_description
                              : "-"}
                        </p>
                      </Tooltip>
                    </div>
                    <div className="label-container">
                      <div className="pa-label">Quantity</div>
                      <div>{pa.qty}</div>
                    </div>
                    <div className="label-container">
                      <div className="pa-label">BOQ Unit Cost</div>
                      <div>
                        {gpl_unit_cost === 0
                          ? (gpl_unit_cost = pa.boq_unit_cost)?.toLocaleString()
                          : (gpl_unit_cost =
                              pa.boq_unit_cost)?.toLocaleString()}
                      </div>
                    </div>
                    <div className="label-container">
                      <div className="pa-label">BoQ Total Cost</div>
                      <div>
                        {pa.boq_total_cost === 0
                          ? pa.boq_total_cost?.toLocaleString()
                          : pa.boq_total_cost?.toLocaleString()}
                      </div>
                    </div>
                    <div className="label-container">
                      <div className="pa-label">New Unit Cost</div>
                      <div>
                        {new_unit_cost === 0
                          ? (new_unit_cost = pa.pi_unit_cost)?.toLocaleString()
                          : (new_unit_cost = pa.pi_unit_cost)?.toLocaleString()}
                      </div>
                    </div>
                    <div className="label-container">
                      <div className="pa-label">New Total Cost</div>
                      <div>
                        {pa.pi_total_price === 0
                          ? pa.pi_total_cost?.toLocaleString()
                          : pa.pi_total_cost?.toLocaleString()}
                      </div>
                    </div>
                    <div className="label-container">
                      <div className="pa-label">Manufacturer</div>
                      <div>
                        {items[index]?.manufacturer?.manufacturer_name == null
                          ? "-"
                          : items[index]?.manufacturer?.manufacturer_name}
                      </div>
                    </div>
                    <div className="label-container">
                      <div className="pa-label">Profit</div>
                      <div
                        className={`Profit ${
                          pa.profit === 0
                            ? "zero"
                            : pa.profit > 0
                              ? "positive"
                              : pa.profit < 0
                                ? "negative"
                                : ""
                        }`}
                      >
                        {pa.profit === 0
                          ? pa.profit?.toLocaleString()
                          : pa.profit?.toLocaleString()}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="supplier-summaryManufacturer-row p-2">
                    <div className="label-container">
                      <div className="pa-label">No</div>
                      <div>{(count += 1)}</div>
                    </div>
                    <div className="label-container">
                      <div className="pa-label">Supplier</div>
                      <Tooltip title={pa?.supplier_name}>
                        <p className="text-start">
                          {pa?.supplier_name?.length >= 15
                            ? pa?.supplier_name?.slice(0, 15) + "..."
                            : pa?.supplier_name}
                        </p>
                      </Tooltip>
                    </div>
                    <div className="label-container">
                      <div className="pa-label">Item Description</div>
                      <Tooltip title={pa?.item_description}>
                        <p className="text-start">
                          {pa?.item_description?.length >= 20
                            ? pa?.item_description?.slice(0, 20) + "..."
                            : pa?.item_description}
                        </p>
                      </Tooltip>
                    </div>
                    <div className="label-container">
                      <div className="pa-label">Quantity</div>
                      <div>{pa.qty}</div>
                    </div>
                    <div className="label-container">
                      <div className="pa-label">BOQ Unit Cost</div>
                      <div>
                        {gpl_unit_cost === 0
                          ? (gpl_unit_cost = pa.boq_unit_cost)?.toLocaleString()
                          : (gpl_unit_cost =
                              pa.boq_unit_cost)?.toLocaleString()}
                      </div>
                    </div>
                    <div className="label-container">
                      <div className="pa-label">BoQ Total Cost</div>
                      <div>
                        {pa.boq_total_cost === 0
                          ? pa.boq_total_cost?.toLocaleString()
                          : pa.boq_total_cost?.toLocaleString()}
                      </div>
                    </div>
                    <div className="label-container">
                      <div className="pa-label">New Unit Cost</div>
                      <div>
                        {new_unit_cost === 0
                          ? (new_unit_cost = pa.pi_unit_cost)?.toLocaleString()
                          : (new_unit_cost = pa.pi_unit_cost)?.toLocaleString()}
                      </div>
                    </div>
                    <div className="label-container">
                      <div className="pa-label">New Total Cost</div>
                      <div>
                        {pa.pi_total_price === 0
                          ? pa.pi_total_cost?.toLocaleString()
                          : pa.pi_total_cost?.toLocaleString()}
                      </div>
                    </div>
                    <div className="label-container">
                      <div className="pa-label">Manufacturer</div>
                      <div>
                        {items[index]?.manufacturer?.manufacturer_name == null
                          ? "-"
                          : items[index]?.manufacturer?.manufacturer_name}
                      </div>
                    </div>
                    <div className="label-container">
                      <div className="pa-label">Profit</div>
                      <div
                        className={`Profit ${
                          pa.profit === 0
                            ? "zero"
                            : pa.profit > 0
                              ? "positive"
                              : pa.profit < 0
                                ? "negative"
                                : ""
                        }`}
                      >
                        {pa.profit === 0
                          ? pa.profit?.toLocaleString()
                          : pa.profit?.toLocaleString()}
                      </div>
                    </div>
                  </div>
                )
              )
            )
          )}
          {CheckPermission("pa_approval", userinfo) ? (
            <>
              <Approve
                type={"PaStage"}
                gplTotal={gpl_total_cost}
                newTotal={new_total_cost}
                profit={profit}
                orderid={params.id}
                ordername={orderDetail?.data?.orders?.order_name}
                setMessages={props?.setMessages}
                paData={paData}
                setApprovalStatus={props?.setApprovalStatus}
                isPA={"PA"}
                projectid={projectid}
              />
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        <NoRecord />
      )}
    </div>
  );
}
