import React, { useState } from "react";

export default function DepartmentTap(props) {
  const [all, setAll] = useState("active-tab-dep");
  const [delivered, setDelivered] = useState("");
  const [rma, setRma] = useState("");

  function handleActive(e) {
    if (e == "all") {
      setAll("active-tab-dep");
      setDelivered("");
      setRma("");
      props.setActive("all");
    } else if (e == "delivered") {
      setAll("");
      setDelivered("active-tab-dep");
      setRma("");
      props.setActive("delivered");
    } else if (e == "rma") {
      setAll("");
      setDelivered("");
      setRma("active-tab-dep");
      props.setActive("rma");
    }
  }

  return (
    <div className="items-tab mt-4">
      <div className="tab-list">
        <div
          className={`rma-tab  ${all}`}
          id="all"
          onClick={(e) => handleActive(e.target.id)}
        >
          All
        </div>

        <div
          className={`rma-tab ${delivered}`}
          id="delivered"
          onClick={(e) => handleActive(e.target.id)}
        >
          Delivered
        </div>

        <div
          className={`rma-tab ${rma}`}
          id="rma"
          onClick={(e) => handleActive(e.target.id)}
        >
          RMA
        </div>
      </div>
    </div>
  );
}
