import axios from "axios";
import React, { useState } from "react";
import { API_BASE_URL } from "../../../api/endPoint";
import { useAddSettingBankinformationMutation } from "../../../features/SCMApi";

export default function AddBankinformation(props) {
  const [bank_name, setbank_name] = useState();
  const [swift_code, setSwiftcode] = useState();
  const [address, setAddress] = useState();
  const [postal_code, setPostalCode] = useState();
  const [phone, setPhone_number] = useState();
  const [fax, setfax] = useState();
  const [abbreviation, setabbreviation] = useState();
  let information = {
    bank_name,
    swift_code,
    address,
    phone,
    postal_code,
    fax,
    abbreviation,
  };

  function HandleCancel() {
    props.modal(false);
  }
  const [createBank, res] = useAddSettingBankinformationMutation();
  const HandleSubmit = async (e) => {
    e.preventDefault();
    // axios
    //   .post(`${API_BASE_URL}/scm/settings/bank-details`, information, {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   })
    //   .then((response) => {
    
    //   })
    //   .then((error) => {
    

    //   });
    createBank(information)
      .unwrap()
      .then((response) => {
        
        props.modal(false);
        props.setMessages("Bank Added Successfully");
      })
      .then((error) => {
        
      });
  };
  return (
    <div>
      <div id="myModal" className="modal">
        <div className="modal-content-md">
          <div className="create-project-modal-title clientRepModal">
            <div className="">Add Bank Information</div>
            <div onClick={HandleCancel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="42"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>

          <form className="form-container" onSubmit={HandleSubmit}>
            <div className="form-card-container">
              <div className="form-card-information">
                <div className="">
                  <div className="card-center">
                    <div className="card-form">
                      <div className="input-labels">
                        <div className="input-label"> Name</div>
                        <div className="input-label"> Abbreviation</div>
                        <div className="input-label">Address</div>
                        <div className="input-label">Swift Code</div>
                        <div className="input-label">P.O.BOX</div>
                        <div className="input-label">Fax</div>
                        <div className="input-label">Telephone</div>
                      </div>
                      <div className="input-fields">
                        <input
                          type="text"
                          className="text-input"
                          onChange={(e) => setbank_name(e.target.value)}
                        />
                        <input
                          type="text"
                          className="text-input"
                          onChange={(e) => setabbreviation(e.target.value)}
                        />
                        <input
                          type="text"
                          className="text-input"
                          onChange={(e) => setAddress(e.target.value)}
                        />
                        <input
                          type="text"
                          className="text-input"
                          onChange={(e) => setSwiftcode(e.target.value)}
                        />
                        <input
                          type="text"
                          className="text-input"
                          onChange={(e) => setPostalCode(e.target.value)}
                        />
                        <input
                          type="text"
                          className="text-input"
                          onChange={(e) => setfax(e.target.value)}
                        />
                        <input
                          type="text"
                          className="text-input"
                          onChange={(e) => setPhone_number(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-buttons repreForm">
                <button
                  type="cancel"
                  className="form-cancel repreCanel"
                  onClick={HandleCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="form-save repreSave"
                  onClick={HandleSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
