import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../api/endPoint";
const BearerToken = localStorage.getItem("accessToken");

export const SCMApi = createApi({
  reducerPAth: "SCM",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_BASE_URL}`,
  }),
  tagTypes: [
    "project",
    "product",
    "boq",
    "aProject",
    "forexApproval",
    "forexApprovalIndex",
    "ForexNeeds",
    "batch",
    "ForexReceivable",
    "paymentTracking",
    "supplier",
    "order",
    "consigneeInformation",
    "MandatoryDocument",
    "GetBatch",
    "manufacturers",
    "freightforwarders",
    "BatchDetail",
    "bank",
    "batchStatus",
    "paymentterm",
    "projectInput",
    "MissedItemAndRma",
    "getApprover",
    "BankBalance",
    "notification",
    "creditNote",
    "store",
    "purchaserequest",
    "ForexNeedsList",
    "paymentcollection",
  ],
  endpoints: (builder) => ({
    getBoqtypes: builder.query({
      query: () => "/view/Boqtypes",
    }),
    getProjects: builder.query({
      query: () => "/scm/project/projects",
      providesTags: ["project"],
    }),
    getProject: builder.query({
      query: (id) => `/scm/project/projects/${id}`,
      providesTags: ["aProject"],
    }),

    getProjectInputParams: builder.query({
      query: () => `scm/project/involved-personnel`,
      providesTags: ["projectInput"],
    }),
    getProducts: builder.query({
      query: () => "/scm/product/solutions",
      providesTags: ["product"],
    }),
    getProduct: builder.query({
      query: (id) => `/scm/product/solutions/${id}`,
    }),
    getSuppliers: builder.query({
      query: () => `/scm/supplier/suppliers`,
      providesTags: ["suppliers"],
    }),
    getSuppliersOrders: builder.query({
      query: () => `scm/supplier/supplier-order`,
      providesTags: ["suppliers"],
    }),
    getCreditNoteSupplier: builder.query({
      query: () => `/scm/payment/prf/credit_note`,
      providesTags: ["suppliers"],
    }),
    addSupplier: builder.mutation({
      query: (payload) => ({
        url: "/scm/supplier/suppliers",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["suppliers"],
    }),
    addSupplierProduct: builder.mutation({
      query: (payload) => ({
        url: "/scm/supplier/supplier-products",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["suppliers"],
    }),
    addSolution: builder.mutation({
      query: (payload) => ({
        url: "/scm/product/solutions",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["product"],
    }),
    getManufacturers: builder.query({
      query: () => `/scm/supplier/manufacturers`,
      providesTags: ["manufacturers"],
    }),
    getMillstones: builder.query({
      query: () => `/scm/settings/milestones`,
    }),
    getSettingBank: builder.query({
      query: () => `/scm/settings/bank-details`,
      providesTags: ["bank"],
    }),
    createProject: builder.mutation({
      query: (payload) => ({
        url: "/scm/project/projects",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
        mode: "no-cors",
      }),
      invalidatesTags: ["project"],
    }),

    getBoq: builder.query({
      query: () => "/scm/product/boq",

      providesTags: ["boq"],
    }),
    addBoq: builder.mutation({
      query: (payload) => ({
        url: "scm/product/boq",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "multipart/form-data",
        },
        responseHandler: (response) => response.text(),
      }),
      invalidatesTags: ["project"],
    }),
    getOrigin: builder.query({
      query: (payload) => ({
        url: "scm/discrepancy/origins",
      }),
    }),
    getShipmentMode: builder.query({
      query: (payload) => ({
        url: "scm/discrepancy/shipmentmodes",
      }),
    }),
    getAirPortLoading: builder.query({
      query: (payload) => ({
        url: "scm/discrepancy/airloadings",
      }),
    }),
    getSeaPortLoading: builder.query({
      query: (payload) => ({
        url: "scm/discrepancy/sealoadings",
      }),
    }),
    getDischarge: builder.query({
      query: (payload) => ({
        url: "scm/discrepancy/airdischarges",
      }),
    }),
    addLc: builder.mutation({
      query: (payload) => ({
        url: "scm/discrepancy/items",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "multipart/form-data",
        },
      }),
    }),

    getManufacturer: builder.query({
      query: (id) => `/scm/supplier/manufacturers/${id}`,
      providesTags: ["manufacturers"],
    }),
    createSupplier: builder.mutation({
      query: (payload) => ({
        url: "/scm/supplier/suppliers",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["project"],
    }),
    getSupplier: builder.query({
      query: (id) => `/scm/supplier/suppliers/${id}`,
      providesTags: ["suppliers"],
    }),
    getFrightForwarders: builder.query({
      query: () => `/scm/supplier/fright-forwarders`,
      providesTags: ["freightforwarders"],
    }),
    getFrightForwarder: builder.query({
      query: (id) => `/scm/supplier/fright-forwarders/${id}`,
      providesTags: ["freightforwarders"],
    }),
    getMappings: builder.query({
      query: () => `/send/SupplierMapping`,
    }),
    createCi: builder.mutation({
      query: (payload) => ({
        url: "/scm/discrepancy/items",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["project"],
    }),
    getShipmentModes: builder.query({
      query: () => `/scm/discrepancy/shipmentmodes`,
    }),
    getShipmentDestinations: builder.query({
      query: () => `scm/settings/shipment/destinations`,
    }),

    getOrigins: builder.query({
      query: () => `/scm/discrepancy/origins`,
    }),
    getAirPorts: builder.query({
      query: () => `/scm/discrepancy/airloadings`,
    }),
    getSeaPorts: builder.query({
      query: () => `/scm/discrepancy/sealoadings`,
    }),
    getDischarges: builder.query({
      query: () => `/scm/discrepancy/airdischarges`,
    }),
    getItems: builder.query({
      query: () => "/scm/discrepancy/items",
    }),
    getSorcingStage: builder.query({
      query: () => "/scm/stages/sourcing",
    }),
    getOrderStage: builder.query({
      query: () => "/scm/stages/logistics",
    }),

    getItem: builder.query({
      query: (id) => `/scm/discrepancy/items/${id}`,
    }),
    getSupplierOrigins: builder.query({
      query: () => `/scm/settings/origins`,
    }),

    getSolutionDetails: builder.mutation({
      query: (payload) => ({
        url: "scm/project/project-products",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
    }),
    getPaSummary: builder.query({
      query: (id) => `/scm/sourcing/pa-summaries/${id}`,
    }),

    createOrder: builder.mutation({
      query: (payload) => ({
        url: "scm/sourcing/orders",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["order"],
    }),
    updateOrder: builder.mutation({
      query: (payload) => ({
        url: `scm/sourcing/orders-updatePi/${payload.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["order"],
    }),
    deleteOrder: builder.mutation({
      query: (payload) => ({
        url: `scm/sourcing/order/${payload?.id}`,
        method: "DELETE",
        body: payload,
      }),
      invalidatesTags: ["order"],
    }),

    getCurrencies: builder.query({
      query: () => `/scm/supplier/bank/currencies`,
    }),
    getProjectItem: builder.query({
      query: (id) => `/scm/sourcing/home/${id}`,
      providesTags: ["order"],
    }),
    GetSourcingProjectItem: builder.query({
      query: (id) => `/scm/sourcing/orders/${id}`,
      providesTags: ["order"],
    }),
    getSupplierTypes: builder.query({
      query: () => `/scm/supplier/partner-types`,
    }),
    getOrderItem: builder.query({
      query: (id) => `/scm/sourcing/orders/${id}`,
      providesTags: ["order"],
    }),
    getOrdercomparision: builder.query({
      query: (id) => `/scm/sourcing/budgetComparison/${id}`,
      providesTags: ["order"],
    }),
    getOrderPoItem: builder.query({
      query: (id) => `/scm/sourcing/po/${id}`,
      providesTags: ["order"],
    }),
    updateOrderSource: builder.mutation({
      query: (payload) => ({
        url: `scm/sourcing/offshore/${payload.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["paymentterm", "order"],
    }),

    getPaymentsMode: builder.query({
      query: () => `/scm/settings/payment/modes`,
      providesTags: ["paymentterm"],
    }),
    getPaymentTerms: builder.query({
      query: () => `/scm/settings/payment/terms`,
      providesTags: ["paymentterm"],
    }),
    addPaymentTerm: builder.mutation({
      query: (payload) => ({
        url: `scm/settings/payment/terms`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["paymentterm"],
    }),
    editPaymentTerm: builder.mutation({
      query: (payload) => ({
        url: `scm/settings/payment/terms/${payload.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["paymentterm"],
    }),
    deletePaymentTerm: builder.mutation({
      query: (payload) => ({
        url: `scm/settings/payment/terms/delete/${payload.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["paymentterm"],
    }),
    addPaymentMode: builder.mutation({
      query: (payload) => ({
        url: `scm/settings/payment/modes`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["paymentterm"],
    }),
    editPaymentMode: builder.mutation({
      query: (payload) => ({
        url: `scm/settings/payment/modes/${payload.id}`,
        method: "PUT",
        body: payload,
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["paymentterm"],
    }),
    deletePaymentMode: builder.mutation({
      query: (payload) => ({
        url: `scm/settings/payment/modes/delete/${payload.id}`,
        method: "PUT",
        body: payload,
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["paymentterm"],
    }),

    getShipmentsMode: builder.query({
      query: () => `/scm/settings/shipment/modes`,
    }),
    getEtas: builder.query({
      query: (id) => `/scm/sourcing/eta/${id}`,
    }),
    getEtaDetail: builder.query({
      query: (id) => `/scm/sourcing/eta-detail/${id}`,
      providesTags: ["order"],
    }),
    getBatch: builder.query({
      query: (id) => `/scm/sourcing/batches/${id}`,
      providesTags: ["order"],
    }),
    getAllCurrencies: builder.query({
      query: () => `scm/currencies`,
    }),
    //=========> HERE <==========
    getSupplierCategories: builder.query({
      query: () => `/scm/supplier/supplier_category`,
    }),
    getSettingClient: builder.query({
      query: () => `/scm/settings/clients`,
    }),
    getIncoterms: builder.query({
      query: () => `/scm/settings/payment/incoterms`,
      headers: {
        "Content-type": "application/json",
      },
    }),
    getLoadingPortals: builder.query({
      query: () => `scm/settings/shipment/loadingportals`,
    }),
    getAirLoadingPorts: builder.query({
      query: () => `scm/settings/shipment/air-loading-ports`,
    }),
    getSeaLoadingPorts: builder.query({
      query: () => `scm/settings/shipment/sea-loading-ports`,
    }),
    getShipmentModesAdd: builder.query({
      query: () => `scm/settings/shipment/modes`,
    }),

    getSettingClientRepresentative: builder.query({
      query: () => `/scm/settings/clientrepresentatives`,
    }),
    addClient: builder.mutation({
      query: (payload) => ({
        url: "/scm/settings/clients",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
        mode: "no-cors",
      }),
      invalidatesTags: ["client"],
    }),
    editClientRepresentative: builder.mutation({
      query: (payload) => ({
        url: `scm/settings/clientrepresentatives/${payload?.id}`,
        method: "PUT",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["aProject"],
    }),
    addClientRepresentative: builder.mutation({
      query: (payload) => ({
        url: `scm/settings/clientrepresentatives`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["aProject"],
    }),
    editClient: builder.mutation({
      query: (payload) => ({
        url: `scm/settings/clients/${payload?.id}`,
        method: "PUT",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
    }),
    removeMember: builder.mutation({
      query: (payload) => ({
        url: `scm/project/projects/remove-members`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["aProject"],
    }),
    getSubSolutions: builder.query({
      query: () => "scm/product/subsolutions",
    }),
    getPo: builder.query({
      query: (origin_id) => `scm/sourcing/batches/orders/${origin_id}`,
    }),
    getBatches: builder.query({
      query: (ProjectId) => `scm/sourcing/scm-batches/${ProjectId}`,
      providesTags: ["batch"],
    }),
    getCostBuildUpBatches: builder.query({
      query: (ProjectId) => `scm/cost-build-up/showBatchCostBuildUp`,
      providesTags: ["batch"],
    }),
    getCostBuildUpIndex: builder.query({
      query: (ProjectId) => `scm/cost-build-up/index`,
      providesTags: ["batch"],
    }),
    getCostBuildUpDetail: builder.query({
      query: (id) => `scm/cost-build-up/showBatchCostBuildUp/${id}`,
      providesTags: ["batch"],
    }),
    getCostBuildUpDetailFinance: builder.query({
      query: (id) => `scm/cost-build-up/showBatchCostBuild/${id}`,
      providesTags: ["batch"],
    }),
    CreateCostBuildUp: builder.mutation({
      query: (payload) => ({
        url: "scm/cost-build-up/create",
        method: "POST",
        body: payload,
        header: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["BatchDetail"],
    }),
    updateCostBuildUp: builder.mutation({
      query: (payload) => ({
        url: `scm/cost-build-up/update/${payload?.id}`,
        method: "POST",
        body: payload,
        header: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["BatchDetail"],
    }),
    updateCostBuildUpStatus: builder.mutation({
      query: (payload) => ({
        url: `scm/cost-build-up/updateStatus/${payload?.id}`,
        method: "POST",
        body: payload,
        header: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["BatchDetail", "forexApproval"],
    }),
    getBatchDetail: builder.query({
      query: (id) => `scm/sourcing/batch-detail/${id}`,
      providesTags: ["BatchDetail", "forexApproval"],
    }),
    getProjectMembers: builder.query({
      query: (id) => ({
        url: `scm/project/projects/get-members/${id}`,
      }),
    }),
    getSubSolutionsPerProject: builder.mutation({
      query: (payload) => ({
        url: "scm/project/projects/department",
        method: "POST",
        body: payload,
        header: {
          "Content-type": "application/json",
        },
      }),
    }),
    closeProject: builder.mutation({
      query: (payload) => ({
        url: `scm/project/close/${payload?.id}`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["aProject"],
    }),
    assignPersonnel: builder.mutation({
      query: (payload) => ({
        url: "scm/project/projects/add-members",
        method: "POST",
        body: payload,
        header: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["aProject"],
    }),
    assignSolution: builder.mutation({
      query: (payload) => ({
        url: "/scm/project/projects/add-solutions",
        method: "POST",
        body: payload,
        header: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["aProject"],
    }),
    removeSolution: builder.mutation({
      query: (payload) => ({
        url: "scm/project/projects/remove-solutions",
        method: "POST",
        body: payload,
        header: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["aProject"],
    }),
    deleteSolution: builder.mutation({
      query: (solutionId) => ({
        url: `scm/product/solutions/delete/${solutionId}`,
        method: "PUT",
        body: solutionId,
        header: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["product"],
    }),
    deleteBatch: builder.mutation({
      query: (payload) => ({
        url: `scm/sourcing/batches-delete/${payload?.id}`,
        method: "DELETE",
        body: payload,
      }),
      invalidatesTags: ["order"],
    }),
    deleteSubSolution: builder.mutation({
      query: (subSolutionId) => ({
        url: `scm/product/subsolutions/delete/${subSolutionId}`,
        method: "PUT",
        body: subSolutionId,
        header: {
          "Content-type": "application/json",
        },
      }),
    }),
    editSubSolution: builder.mutation({
      query: (payload) => ({
        url: `scm/product/subsolutions/${payload?.id}`,
        method: "PUT",
        body: payload,
        header: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["product"],
    }),
    editSolution: builder.mutation({
      query: (payload) => ({
        url: `scm/product/solutions/${payload?.id}`,
        method: "PATCH",
        body: payload,
        header: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["product"],
    }),
    addNewSubSolution: builder.mutation({
      query: (payload) => ({
        url: "/scm/product/subsolutions",
        method: "POST",
        body: payload,
        header: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["product"],
    }),
    changePmr: builder.mutation({
      query: (payload) => ({
        url: "scm/product/solutions/change-pmr",
        method: "POST",
        body: payload,
        headers: {
          "content-type": "application/json",
        },
      }),
      invalidatesTags: ["supplier"],
    }),
    attachDocument: builder.mutation({
      query: (payload) => ({
        url: `scm/project/projects/attach-documents/029dd97d-1e7f-43ca-9575-92b769e96ca7`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          // "Content-Type": "multipart/form-data",
        },
      }),
    }),
    getProjectDocuments: builder.query({
      query: (payload) => ({
        url: "scm/project/project-doc",
      }),
    }),
    getAllBatches: builder.query({
      query: (payload) => ({
        url: "scm/sourcing/scm-batches",
      }),
    }),
    getProjectDocument: builder.query({
      query: (id) => ({
        url: `scm/project/project-document/${id}`,
      }),
    }),
    getProjectBatch: builder.query({
      query: (id) => ({
        url: `scm/sourcing/scm-batches/${id}`,
      }),
    }),
    getPoGroup: builder.query({
      query: (id) => ({
        url: `scm/sourcing/po-group/${id}`,
      }),
    }),
    getRMADocument: builder.query({
      query: (id) => ({
        url: `scm/documents/rma-batch-document/${id}`,
      }),
    }),
    getChamberuizedDocument: builder.query({
      query: (id) => ({
        url: `scm/logistics/collected-documents/${id}`,
      }),
    }),
    // getCiDocument: builder.query({
    //   query: (id) => ({
    //     url: `scm/logistics/document-preparation/ci/${id}`,
    //   }),
    // }),
    getPiDocument: builder.query({
      query: (id) => ({
        url: `scm/logistics/document-preparation/pi/${id}`,
      }),
    }),
    getPermitDocument: builder.query({
      query: (id) => ({
        url: `scm/documents/forex-permit-document/${id}`,
      }),
    }),
    getOrderPoDocument: builder.query({
      query: (id) => ({
        url: `/scm/sourcing/document-preparation/po/${id}`,
      }),
    }),
    getPoGroupDocument: builder.query({
      query: (id) => ({
        url: `scm/sourcing/order_po_group/${id}`,
      }),
    }),
    getSampleDocument: builder.query({
      query: (id) => ({
        url: `scm/documents/all-sample-documents`,
      }),
    }),
    submitBank: builder.mutation({
      query: (payload) => ({
        url: `scm/logistics/bank-submition-documents`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          // "Content-Type": "multipart/form-data",
        },
      }),
    }),
    createBatch: builder.mutation({
      query: (payload) => ({
        url: `scm/sourcing/batches`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",

          // "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["batch"],
    }),

    //Payment Module apis
    getForex: builder.query({
      query: (payload) => ({
        url: "scm/payment/forex",
      }),
      providesTags: ["forexApprovalIndex"],
    }),

    approveForex: builder.mutation({
      query: (payload) => ({
        url: `scm/payment/forex-Approved/${payload?.id}`,
        method: "POST",
        body: payload,
        header: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["forexApproval"],
    }),
    clearForex: builder.mutation({
      query: (payload) => ({
        url: `scm/payment/deliquency-cleared/${payload?.id}`,
        method: "POST",
        body: payload,
        header: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["forexApproval"],
    }),
    createStatusForex: builder.mutation({
      query: (payload) => ({
        url: `scm/payment/forex-status`,
        method: "POST",
        body: payload,
        header: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["forexApproval"],
    }),

    createStatusForexAchieved: builder.mutation({
      query: (payload) => ({
        url: `scm/payment/forexExpiration-status/expiration/${payload?.id}`,
        method: "POST",
        body: payload,
        header: {
          "Content-type": "application/json",
        },
        // mode: "no-cors",
      }),
      invalidatesTags: ["forexApproval"],
    }),
    createForexExpirationRemark: builder.mutation({
      query: (payload) => ({
        url: `scm/payment/forexExpiration-status`,
        method: "POST",
        body: payload,
        header: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["forexApproval"],
    }),
    getStatusForex: builder.query({
      query: (id) => ({
        url: `scm/payment/forex-status/${id}`,
      }),
      providesTags: ["forexApprovalIndex"],
    }),
    getForexExpirationRemark: builder.query({
      query: (id) => ({
        url: `scm/payment/forexExpiration-status/${id}`,
      }),
      providesTags: ["forexApprovalIndex"],
    }),

    inititateForexIE: builder.mutation({
      query: (payload) => ({
        url: "scm/payment/forex",
        method: "POST",
        body: payload,
        header: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["forexApprovalIndex"],
    }),

    inititateForexClient: builder.mutation({
      query: (payload) => ({
        url: "scm/payment/forex-client",
        method: "POST",
        body: payload,
        header: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["forexApprovalIndex"],
    }),
    getForexDetail: builder.query({
      query: (id) => ({
        url: `scm/payment/forex/${id}`,
      }),
      providesTags: ["forexApproval"],
    }),

    updateForexBank: builder.mutation({
      query: (payload) => ({
        url: `scm/payment/forex-bank-update/${payload?.id}`,
        method: "POST",
        body: payload,
        header: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["forexApproval"],
    }),
    getForexPaymentMode: builder.query({
      query: (payload) => ({
        url: "scm/settings/payment/modes",
      }),
    }),
    getForexShipmentMode: builder.query({
      query: (payload) => ({
        url: "scm/settings/shipment/modes",
      }),
    }),
    getForexLoadingOrigin: builder.query({
      query: (payload) => ({
        url: "scm/settings/origins",
      }),
    }),
    getForexDischargePort: builder.query({
      query: (payload) => ({
        url: "scm/settings/shipment/destinations",
      }),
    }),
    getForexAirLoadingPort: builder.query({
      query: (payload) => ({
        url: "scm/settings/shipment/air-loading-ports",
      }),
    }),
    getSeaLoadingPort: builder.query({
      query: (payload) => ({
        url: "scm/settings/shipment/sea-loading-ports",
      }),
    }),
    getForexOpeningTo: builder.query({
      query: (payload) => ({
        url: "scm/supplier/suppliers",
      }),
    }),
    getForexIncoterms: builder.query({
      query: (payload) => ({
        url: "scm/settings/payment/incoterms",
      }),
    }),
    addForexTermCondition: builder.mutation({
      query: (payload) => ({
        url: "scm/payment/forex-term",
        method: "POST",
        body: payload,
        header: {
          "Content-Type": "application/json",
        },
        // mode: "no-cors",
      }),
      invalidatesTags: ["forexApproval"],
    }),
    addForexPlanningReceivable: builder.mutation({
      query: (payload) => ({
        url: "scm/payment/forex-receivable",
        method: "POST",
        body: payload,
        header: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["BankBalance", "ForexReceivable"],
    }),
    checkedForexPlanningReceivable: builder.mutation({
      query: (payload) => ({
        url: `/scm/payment/forex-receivable-received/${payload?.id}`,
        method: "POST",
        body: payload,
        header: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["ForexReceivable"],
    }),
    updateForexTermCondition: builder.mutation({
      query: (payload) => ({
        url: `scm/payment/forex-term-payment`,
        method: "POST",
        body: payload,
        header: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["forexApproval"],
    }),
    updateForexPlaningReceivable: builder.mutation({
      query: (payload) => ({
        url: `scm/payment/forex-receivable/${payload?.id}`,
        method: "POST",
        body: payload,
        header: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["ForexReceivable"],
    }),
    getForexProject: builder.query({
      query: (payload) => ({
        url: "scm/payment/client-initiated-project",
      }),
    }),
    getForexProjectIeInitiated: builder.query({
      query: (payload) => ({
        url: "scm/payment/ie-initiated-project",
      }),
    }),
    getOrderList: builder.query({
      query: (id) => ({
        url: `scm/sourcing/project-orders/${id}`,
      }),
    }),
    getBankBalance: builder.query({
      query: (payload) => ({
        url: "scm/payment/bank-balance",
      }),
      providesTags: [
        "BankBalance",
        "ForexReceivable",
        "ForexNeeds",
        "ForexNeedsList",
      ],
    }),

    getForexReceivable: builder.query({
      query: (payload) => ({
        url: "scm/payment/forex-receivable",
      }),
      providesTags: ["ForexReceivable"],
    }),

    getReceivableManufacturers: builder.query({
      query: () => `scm/supplier/manufacturers`,
    }),
    getReceivableBanks: builder.query({
      query: () => `scm/settings/banks`,
      providesTags: ["ForexNeeds"],
    }),
    getForexNeeds: builder.query({
      query: () => `scm/payment/forex-need`,
      providesTags: ["ForexNeedsList"],
    }),

    updateForexNeedStatus: builder.mutation({
      query: (payload) => ({
        url: `scm/payment/update-forex-need-status/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      invalidatesTags: ["ForexNeeds", "ForexNeedsList", "BankBalance"],
    }),

    selectForexNeedBank: builder.mutation({
      query: (payload) => ({
        url: `scm/payment/forex-need-bank/${payload?.id}`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["ForexNeeds"],
    }),
    selectSupplierPaymentBank: builder.mutation({
      query: (payload) => ({
        url: `scm/supplier-payment/update/${payload?.id}`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["paymentTracking"],
    }),
    selectSupplierPaymentCurrency: builder.mutation({
      query: (payload) => ({
        url: `scm/supplier-payment/update-currency/${payload?.id}`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["paymentTracking"],
    }),
    updateSupplierPaymentPI: builder.mutation({
      query: (payload) => ({
        url: `scm/supplier-payment/update-pinumber/${payload?.id}`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["paymentTracking"],
    }),
    getForexPaymentTrackingProjects: builder.query({
      query: () => `scm/payment/projects`,
      providesTags: ["PaymentTracking"],
    }),
    getForexPaymentTrackingVendor: builder.query({
      query: () => `scm/payment/vendors`,
    }),
    getPaymentTrackingForProject: builder.query({
      query: (id) => ({
        url: `scm/payment/project-payment/{id}`,
      }),
      providesTags: ["paymentTracking"],
    }),
    getPaymentTrackingForProjectReport: builder.query({
      query: (id) => ({
        url: `scm/payment/allproject-payment`,
      }),
      providesTags: ["paymentTracking"],
    }),
    getPaymentTrackingForVendor: builder.query({
      query: (id) => ({
        url: `scm/payment/vendor-payment/{id}`,
      }),
      providesTags: ["paymentTracking"],
    }),
    getStatementOfAccount: builder.query({
      query: (id) => ({
        url: `scm/payment/soa/${id}`,
      }),
      providesTags: ["paymentTracking"],
    }),
    getSoaReport: builder.mutation({
      query: (payload) => ({
        url: `scm/payment/soa/${payload?.id}/${payload?.project_id}/${payload?.currency_id}`,
        method: "GET",
      }),
      invalidatesTags: ["paymentTracking"],
    }),

    getSupplierCredit: builder.query({
      query: (id) => ({
        url: `scm/payment/supplier-credit/${id}`,
      }),
      providesTags: ["paymentTracking"],
    }),
    getSupplierPayment: builder.query({
      query: (id) => ({
        url: `scm/supplier-payment/${id}`,
      }),
      providesTags: ["paymentTracking"],
    }),
    viewPaymentTrackingHistory: builder.query({
      query: (id) => ({
        url: `scm/payment/payment_history/${id}`,
      }),
    }),
    viewSupplierPaymentHistory: builder.query({
      query: (id) => ({
        url: `scm/payment/supplier-payment-history/${id}`,
      }),
    }),
    viewSupplierCreditHistory: builder.query({
      query: (id) => ({
        url: `scm/payment/supplier-credit-history/${id}`,
      }),
    }),
    updatePaymentOnPaymentTracking: builder.mutation({
      query: (payload) => ({
        url: "scm/payment/payment-update",
        method: "POST",
        body: payload,
        header: {
          "Content-type": "application/json",
        },
        // mode: "no-cors",
      }),
      invalidatesTags: ["paymentTracking"],
    }),
    updateSupplierPaymentOnSupplierPayment: builder.mutation({
      query: (payload) => ({
        url: `scm/payment/supplier-payment-update/${payload?.id}`,
        method: "POST",
        body: payload,
        header: {
          "Content-type": "application/json",
        },
        // mode: "no-cors",
      }),
      invalidatesTags: ["paymentTracking"],
    }),
    addSupplierCredit: builder.mutation({
      query: (payload) => ({
        url: "scm/payment/supplier-credit",
        method: "POST",
        body: payload,
        header: {
          "Content-type": "application/json",
        },
        // mode: "no-cors",
      }),
      invalidatesTags: ["paymentTracking"],
    }),
    UpdateSupplierCredit: builder.mutation({
      query: (payload) => ({
        url: `scm/payment/update-supplier-credit/${payload?.id}`,
        method: "POST",
        body: payload,
        header: {
          "Content-type": "application/json",
        },
        // mode: "no-cors",
      }),
      invalidatesTags: ["paymentTracking"],
    }),
    addPaySupplierCredit: builder.mutation({
      query: (payload) => ({
        url: "scm/payment/pay-supplier-credit",
        method: "POST",
        body: payload,
        header: {
          "Content-type": "application/json",
        },
        // mode: "no-cors",
      }),
      invalidatesTags: ["paymentTracking"],
    }),

    //Gap Analysis
    getBankListForGapAnalysis: builder.query({
      query: (id) => ({
        url: `scm/payment/forex-need-banks`,
      }),
      providesTags: ["paymentTracking"],
    }),
    getGapAnalysisForBank: builder.query({
      query: (id) => ({
        url: `scm/payment/gap-analysis/${id}`,
      }),
    }),
    getForexSummarizedPi: builder.query({
      query: (id) => ({
        url: `scm/payment/summarized-pi/${id}`,
      }),
    }),

    //<----Credit Note API's---->//
    getCreditNotes: builder.query({
      query: (id) => ({
        url: `scm/payment/forex-credit_note`,
      }),
      providesTags: ["creditNote", "ForexReceivable"],
    }),

    updateCreditNote: builder.mutation({
      query: (payload) => ({
        url: `scm/payment/forex-credit_note/${payload?.id}`,
        method: "POST",
        body: payload,
        header: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["creditNote", "ForexReceivable"],
    }),

    getReceivableHistory: builder.query({
      query: (id) => ({
        url: `scm/payment/forex-receivable_history`,
      }),
      providesTags: ["creditNote", "ForexReceivable"],
    }),

    //RMA Query
    rmaExportPreviousDocument: builder.mutation({
      query: (payload) => ({
        url: "scm/rma/export/previous-document",
        method: "POST",
        body: payload,
        header: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }),
      // invalidatesTags: ["ForexReceivable"],
    }),
    rmaExportSecurityClearance: builder.mutation({
      query: (payload) => ({
        url: "scm/rma/export/security-clearance",
        method: "POST",
        body: payload,
        header: {
          "Content-Type": "multipart/form-data",
        },
      }),
      // invalidatesTags: ["ForexReceivable"],useGetSupplier
    }),
    rmaExportAttachAWB: builder.mutation({
      query: (payload) => ({
        url: "scm/rma/export/billing-document",
        method: "POST",
        body: payload,
        header: {
          "Content-Type": "multipart/form-data",
        },
      }),
      // invalidatesTags: ["ForexReceivable"],
    }),
    rmaExportCustomClearance: builder.mutation({
      query: (payload) => ({
        url: "scm/rma/export/custom-clearance",
        method: "POST",
        body: payload,
        header: {
          "Content-Type": "multipart/form-data",
        },
      }),
      // invalidatesTags: ["ForexReceivable"],
    }),

    //RMA and MissedItem documents
    getMissedItems: builder.query({
      query: (id) => ({
        url: `scm/missed-item/missed-items/${id}`,
      }),
      providesTags: ["MissedItemAndRma"],
    }),
    getDeliveredItems: builder.query({
      query: (id) => ({
        url: `scm/sourcing/batches/delivered/${id}`,
      }),
      providesTags: ["MissedItemAndRma"],
    }),
    getDeliveredItemDetail: builder.query({
      query: (id) => ({
        url: `scm/sourcing/batches/delivered/detail/${id}`,
      }),
      providesTags: ["MissedItemAndRma"],
    }),
    getMissedItemsDetails: builder.query({
      query: (id) => ({
        url: `scm/missed-item/missed-item/${id}`,
      }),
      providesTags: ["MissedItemAndRma"],
    }),
    getRmaDetails: builder.query({
      query: (id) => ({
        url: `scm/rma-information/${id}`,
      }),
      providesTags: ["MissedItemAndRma"],
    }),

    getRma: builder.query({
      query: (id) => ({
        url: `scm/rma/${id}`,
      }),
      providesTags: ["MissedItemAndRma"],
    }),
    getRmaMissedItemDocuments: builder.query({
      query: (id) => ({
        url: "scm/documents/all-sample-documents",
      }),
      providesTags: ["MissedItemAndRma"],
    }),
    getMissedItemImportDeclarationDocuments: builder.query({
      query: (id) => ({
        url: `scm/missed-item/previous-document/import_declaration/${id}`,
      }),
      providesTags: ["MissedItemAndRma"],
    }),
    getRmaMissedItemFrightForwarderList: builder.query({
      query: (id) => ({
        url: "scm/supplier/fright-forwarders",
      }),
    }),
    getRmaMissedItemVendorList: builder.query({
      query: (id) => ({
        url: "scm/supplier/suppliers",
      }),
    }),
    rmaShipmentArrangement: builder.mutation({
      query: (payload) => ({
        // url: `scm/rma/import/shipment-arrangement${payload?.id}`,
        url: "scm/rma/import/shipment-arrangement",
        method: "POST",
        body: payload,
        header: {
          "Content-type": "application/json",
          accept: "application/json",
        },
      }),
      invalidatesTags: ["MissedItemAndRma"],
    }),
    missedItemShipmentArrangement: builder.mutation({
      query: (payload) => ({
        // url: `scm/rma/import/shipment-arrangement${payload?.id}`,
        url: "scm/missed-item/shipment-arrangement",
        method: "POST",
        body: payload,
        header: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["MissedItemAndRma"],
    }),
    updateEtdMissedItem: builder.mutation({
      query: (payload) => ({
        url: `scm/missed-item/missed-items/update/${payload.id}`,
        method: "PUT",
        body: payload,
        header: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["MissedItemAndRma"],
    }),
    updateEtdRma: builder.mutation({
      query: (payload) => ({
        url: `scm/rma/update/${payload.id}`,
        method: "PUT",
        body: payload,
        header: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["MissedItemAndRma"],
    }),
    updateMissedItemStatus: builder.mutation({
      query: (payload) => ({
        url: `scm/missed-item/missed-items/update/status/${payload.id}`,
        method: "PUT",
        body: payload,
        header: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["MissedItemAndRma"],
    }),
    updateRmaStatus: builder.mutation({
      query: (payload) => ({
        url: `scm/rma/update/status/${payload.id}`,
        method: "PUT",
        body: payload,
        header: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["MissedItemAndRma"],
    }),

    getRmaStatusTracking: builder.query({
      query: (id) => ({
        url: `scm/rma/status-trucking/${id}`,
      }),
      providesTags: ["MissedItemAndRma"],
    }),
    getMissedItemStatusTracking: builder.query({
      query: (id) => ({
        url: `scm/missed-item/status-trucking/${id}`,
      }),
      providesTags: ["MissedItemAndRma"],
    }),

    //FAQ Query
    getFaqVideos: builder.query({
      query: () => `/scm/faq/videos`,
    }),
    getFaqAnswers: builder.query({
      query: () => "/scm/faq/answers",
    }),
    getFaqQuestions: builder.query({
      query: () => "/scm/faq/questions",
    }),
    getCi: builder.query({
      query: (batchID) => `scm/logistics/document-preparation/ci/${batchID}`,
    }),
    getPl: builder.query({
      query: (batchID) => `scm/logistics/document-preparation/pl/${batchID}`,
    }),
    getConsigneeInformation: builder.query({
      query: (batchID) =>
        `scm/logistics/document-preparation/consignee/${batchID}`,
      providesTags: ["consigneeInformation"],
    }),
    updateConsigneeInformation: builder.mutation({
      query: (payload) => ({
        url: `scm/logistics/document-preparation/consignee/${payload?.batch_id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          // "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["consigneeInformation"],
    }),
    getShipmentInformation: builder.query({
      query: (batchID) =>
        `scm/logistics/document-preparation/shipment-owner/${batchID}`,
    }),
    updateShipmentInformation: builder.mutation({
      query: (payload) => ({
        url: `scm/logistics/document-preparation/shipment-owner/${payload?.batch_id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          // "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["MandatoryDocument"],
    }),
    getMandatoryDocuments: builder.query({
      query: (batchID) =>
        `scm/logistics/document-preparation/mandatory-document/${batchID}`,
      providesTags: ["MandatoryDocument"],
    }),
    updateMandatoryDocument: builder.mutation({
      query: (payload) => ({
        url: `scm/logistics/document-preparation/mandatory-document/${payload?.batch_id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          // "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["MandatoryDocument"],
    }),
    getTerms: builder.query({
      query: (batchID) => `scm/logistics/document-preparation/terms/${batchID}`,
    }),
    updateTerms: builder.mutation({
      query: (payload) => ({
        url: `scm/logistics/document-preparation/terms/${payload?.batch_id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          // "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["MandatoryDocument"],
    }),
    getBanks: builder.query({
      query: () => "scm/settings/banks",
    }),
    getBatchGroup: builder.query({
      query: (id) => `scm/item-delivery/${id}`,
      providesTags: ["GetBatch"],
    }),
    createBatchGroup: builder.mutation({
      query: (payload) => ({
        url: `scm/item-delivery`,
        method: "POST",
        body: payload,

        mode: "no-cors",
      }),
      invalidatesTags: ["BatchDetail"],
    }),
    reportRma: builder.mutation({
      query: (payload) => ({
        url: `scm/rma/report`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          // "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["BatchDetail"],
    }),
    attachDeliveryNote: builder.mutation({
      query: (payload) => ({
        url: `scm/item-delivery/delivery-note/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "multipart/form-data",
          // "Content-Type": "multipart/form-data",
        },
      }),
    }),
    createMissedItem: builder.mutation({
      query: (payload) => ({
        url: `scm/missed-item/missed-items`,
        method: "POST",
        body: payload,
        headers: {
          // "Content-type": "multipart/form-data",
          // "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["BatchDetail"],
    }),
    assignShipment: builder.mutation({
      query: (payload) => ({
        url: `scm/logistics/shipmentArrangement`,
        method: "POST",
        body: payload,
        headers: {
          // "Content-type": "multipart/form-data",
          // "Content-Type": "multipart/form-data",
        },
      }),
    }),
    editETD: builder.mutation({
      query: (payload) => ({
        url: `scm/logistics/batch-etd/${payload.batchId}`,
        method: "POST",
        body: payload,
        headers: {
          // "Content-type": "multipart/form-data",
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["BatchDetail"],
    }),
    getRoles: builder.query({
      query: () => "/view/roles",
      providesTags: ["roles"],
    }),
    getPermissions: builder.query({
      query: () => "/view/Permissions",
    }),
    getProjectRoles: builder.query({
      query: () => "/view/project-roles",
      providesTags: ["roles"],
    }),
    createRole: builder.mutation({
      query: (payload) => ({
        url: "/create/Role",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["roles"],
    }),
    updateRole: builder.mutation({
      query: (payload) => ({
        url: `/update/Role/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["roles"],
    }),
    deactivateRole: builder.mutation({
      query: (payload) => ({
        url: `/deactivate/Role/${payload?.id}`,
        method: "PUT",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["roles"],
    }),
    getRoleDetail: builder.query({
      query: (id) => `/view/Role/${id}`,
    }),
    getUsers: builder.query({
      query: () => `/view/users`,
    }),
    //Supplier mutation start
    DeleteSupplierProduct: builder.mutation({
      query: (payload) => ({
        url: "/scm/supplier/suppliers/delete-products",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["suppliers"],
    }),

    AddManufacturer: builder.mutation({
      query: (payload) => ({
        url: "/scm/supplier/manufacturers",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["manufacturers"],
    }),
    AddFreightForwarders: builder.mutation({
      query: (payload) => ({
        url: "/scm/supplier/fright-forwarders",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["freightforwarders"],
    }),
    UpdateSupplierAddress: builder.mutation({
      query: (payload) => ({
        url: `/scm/supplier/suppliers/${payload.id}`,
        method: "PUT",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          //"Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["suppliers"],
    }),
    editSupplierRepresentative: builder.mutation({
      query: (payload) => ({
        url: `scm/supplier/representatives/${payload?.id}`,
        method: "PUT",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["suppliers", "manufacturers", "freightforwarders"],
    }),
    UpdateManufacturerAddress: builder.mutation({
      query: (payload) => ({
        url: `/scm/supplier/manufacturers/${payload.id}`,
        method: "PUT",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          //"Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["manufacturers"],
    }),
    UpdateFreightForwarderAddress: builder.mutation({
      query: (payload) => ({
        url: `/scm/supplier/fright-forwarders/${payload.id}`,
        method: "PUT",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          //"Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["freightforwarders"],
    }),
    updateSupplierRepresentative: builder.mutation({
      query: (payload) => ({
        url: `/scm/supplier/representatives/${payload.id}`,
        method: "PUT",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          //"Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["suppliers", "manufacturers", "freightforwarders"],
    }),
    addSupplierRepresentative: builder.mutation({
      query: (payload) => ({
        url: `/scm/supplier/representatives`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          //"Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["suppliers"],
    }),
    addManufacturerRepresentative: builder.mutation({
      query: (payload) => ({
        url: `/scm/supplier/representatives`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          //"Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["manufacturers"],
    }),
    addFrieghtForwarderRepresentative: builder.mutation({
      query: (payload) => ({
        url: `/scm/supplier/representatives`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          //"Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["freightforwarders"],
    }),
    addBankInformation: builder.mutation({
      query: (payload) => ({
        url: `/scm/supplier/bankdetails`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          //"Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["suppliers", "manufacturers", "freightforwarders"],
    }),
    editSupplierBankInformation: builder.mutation({
      query: (payload) => ({
        url: `/scm/supplier/bankdetails/${payload.id}`,
        method: "PUT",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          //"Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["suppliers", "manufacturers", "freightforwarders"],
    }),
    createPoGroup: builder.mutation({
      query: (payload) => ({
        url: `/scm/sourcing/po-group`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          //"Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["order"],
    }),
    //supplier mutation end
    // setting start
    getpaymentMode: builder.query({
      query: () => `scm/settings/payment/modes`,
    }),
    getpaymentIncoterm: builder.query({
      query: () => `scm/settings/payment/incoterms`,
    }),
    getSettingShipmentMode: builder.query({
      query: () => `scm/settings/shipment/modes`,
    }),
    getSettingShipmentDestinations: builder.query({
      query: () => `scm/settings/shipment/destinations`,
    }),
    getSupplierManufacter: builder.query({
      query: (id) => `scm/supplier/supplier-manufacturer/${id}`,
    }),
    getOnlyManufacters: builder.query({
      query: (id) => `scm/supplier/supplier-manufacturer`,
    }),

    //setting end
    approvalPitoPo: builder.mutation({
      query: (payload) => ({
        url: `/scm/product/pi-to-po`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          //"Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["order"],
    }),
    approvalPo: builder.mutation({
      query: (payload) => ({
        url: `/scm/sourcing/po-status/${payload.po_id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          //"Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["order"],
    }),
    addPaymentInformation: builder.mutation({
      query: (payload) => ({
        url: `/scm/settings/payment/informations`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          //"Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["order"],
    }),
    editPaymentInformation: builder.mutation({
      query: (payload) => ({
        url: `/scm/settings/payment/informations/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          //"Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["order"],
    }),
    addEtadate: builder.mutation({
      query: (payload) => ({
        url: `/scm/sourcing/eta-update/${payload.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          //"Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["order"],
    }),
    editEtadate: builder.mutation({
      query: (payload) => ({
        url: `/scm/sourcing/eta-update/${payload.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          //"Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["order"],
    }),
    finalEta: builder.mutation({
      query: (payload) => ({
        url: `/scm/sourcing/eta-final/${payload.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          //"Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["order"],
    }),
    // email subject and handover date
    addEmailHandover: builder.mutation({
      query: (payload) => ({
        url: `scm/sourcing/email-handover/${payload.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          //"Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["order"],
    }),
    addEtdBatch: builder.mutation({
      query: (payload) => ({
        url: `scm/sourcing/etd-batch/${payload.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          //"Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["order"],
    }),
    updateDescription: builder.mutation({
      query: (payload) => ({
        url: `scm/sourcing/description/${payload.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          //"Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["order"],
    }),
    updateCurrentStatus: builder.mutation({
      query: (payload) => ({
        url: `scm/sourcing/currentstatus/${payload.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          //"Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["order"],
    }),
    addSettingBankinformation: builder.mutation({
      query: (payload) => ({
        url: `/scm/settings/bank-details`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          //"Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["bank"],
    }),
    editSettingBankinformation: builder.mutation({
      query: (payload) => ({
        url: `/scm/settings/bank-details/${payload.id}`,
        method: "POST",
        body: payload,
        headers: {
          "content-Type": "application/json",
        },
      }),
      invalidatesTags: ["bank"],
    }),
    deleteSettingBankinformation: builder.mutation({
      query: (payload) => ({
        url: `/scm/settings/bank-delete/${payload.id}`,
        method: "POST",
        body: payload,
        headers: {
          "content-Type": "application/json",
        },
      }),
      invalidatesTags: ["bank"],
    }),
    updateTermsAndConditions: builder.mutation({
      query: (payload) => ({
        url: `scm/payment/forex-term/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          // accept: "application/json",
          // Authorization: "Bearer " + BearerToken,
        },
      }),
      invalidatesTags: ["forexApproval"],
    }),
    getUserInfo: builder.query({
      query: () => ({
        url: `/logdUserData`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
    }),
    getProjectRolePermission: builder.query({
      query: (id) => `scm/project/projects-permissions/${id}`,
    }),
    getProjectRole: builder.query({
      query: () => `view/project-roles`,
    }),
    editBatchStatus: builder.mutation({
      query: (payload) => ({
        url: `scm/sourcing/batches/status/${payload?.id}`,
        method: "Put",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          // accept: "application/json",
          // Authorization: "Bearer " + BearerToken,
        },
      }),
      invalidatesTags: ["BatchDetail", "batchStatus"],
    }),
    getBatchStatusHistory: builder.query({
      query: (id) => `scm/sourcing/batches/status-trucking/${id}`,
      providesTags: ["batchStatus"],
    }),
    updateBatchDelivery: builder.mutation({
      query: (id) => ({
        url: `scm/sourcing/batches/close-batch/${id}`,
        method: "POST",
        body: id,
        headers: {
          "Content-Type": "application/json",
          // accept: "application/json",
          // Authorization: "Bearer " + BearerToken,
        },
      }),
      invalidatesTags: ["BatchDetail"],
    }),
    uploadFaqVideo: builder.mutation({
      query: (payload) => ({
        url: `scm/faq/videos`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "multipart/form-data",
          // accept: "application/json",
          // Authorization: "Bearer " + BearerToken,
        },
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
    }),
    answerFaq: builder.mutation({
      query: (payload) => ({
        url: `scm/faq/answers`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    editProject: builder.mutation({
      query: (payload) => ({
        url: `scm/project/projects-update/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
        },
        mode: "no-cors",
      }),

      invalidatesTags: ["aProject"],
    }),
    changeClient: builder.mutation({
      query: (payload) => ({
        url: `scm/project/change-client/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["aProject"],
    }),

    askQuestion: builder.mutation({
      query: (payload) => ({
        url: `/scm/faq/questions`,
        method: "POST",
        body: payload,
        header: {
          "Content-Type": "application/json",
        },
      }),
    }),
    createClient: builder.mutation({
      query: (payload) => ({
        url: `scm/settings/clients`,
        method: "POST",
        body: payload,
        header: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["projectInput"],
    }),
    getDeliveredItem: builder.query({
      query: (projectId) => `scm/sourcing/batches/delivered/${projectId}`,
    }),

    getApprovedForex: builder.query({
      query: (payload) => `scm/payment/approved-forex`,
    }),
    deleteBoq: builder.mutation({
      query: (ProjectId) => ({
        url: `scm/product/${ProjectId}`,
        method: `DELETE`,
        body: ProjectId,
        header: {
          "Content-Type": "application/json",
        },
      }),
    }),
    getForexOption: builder.query({
      query: (id) => `scm/payment/batch-forex/${id}`,
      providesTags: ["BatchDetail"],
    }),
    getRequestLetterDoc: builder.query({
      query: (id) => `scm/documents/forex-request-letter-document/${id}`,
    }),
    getUnderTakingDoc: builder.query({
      query: (id) => `scm/documents/forex-undertaking-form-document/${id}`,
    }),
    getBankSubmissionDoc: builder.query({
      query: (id) => `scm/documents/forex-bank-submission-document/${id}`,
    }),
    getSwiftPermitDoc: builder.query({
      query: (id) => `scm/documents/forex-swift-document/${id}`,
    }),
    hasBoq: builder.query({
      query: (id) => `scm/product/boq-check/${id}`,
    }),
    deletesupplierRepersentative: builder.mutation({
      query: (payload) => ({
        url: `scm/supplier/representatives/delete/${payload.id}`,
        method: "POST",
        body: payload,
        headers: {
          "content-Type": "application/json",
        },
      }),
      invalidatesTags: ["suppliers", "manufacturers", "freightforwarders"],
    }),
    deletesupplierBank: builder.mutation({
      query: (payload) => ({
        url: `scm/supplier/bankdetails/delete/${payload.id}`,
        method: "POST",
        body: payload,
        headers: {
          "content-Type": "application/json",
        },
      }),
      invalidatesTags: ["suppliers", "manufacturers", "freightforwarders"],
    }),

    //Approval api
    getApprover: builder.query({
      query: ({ module_name, id }) => `scm/view/${module_name}/approval/${id}`,
      providesTags: ["getApprover"],
    }),
    getSupplierMapping: builder.query({
      query: (id) => `scm/supplier/mapping/${id}`,
    }),
    approvalApprove: builder.mutation({
      query: (payload) => ({
        url: `scm/approval/approve`,
        method: "POST",
        body: payload,
        headers: {
          "content-Type": "application/json",
        },
      }),
      invalidatesTags: [
        "getApprover",
        "BatchDetail",
        "Getbatch",
        "batch",
        "order",
        "purchaserequest",
      ],
    }),
    approvalReject: builder.mutation({
      query: (payload) => ({
        url: `scm/approval/reject`,
        method: "POST",
        body: payload,
        headers: {
          "content-Type": "application/json",
        },
      }),
      invalidatesTags: [
        "getApprover",
        "BatchDetail",
        "Getbatch",
        "batch",
        "order",
        "purchaserequest",
      ],
    }),
    approvalRetract: builder.mutation({
      query: (payload) => ({
        url: `scm/approval/retract`,
        method: "POST",
        body: payload,
        headers: {
          "content-Type": "application/json",
        },
      }),
      invalidatesTags: [
        "getApprover",
        "BatchDetail",
        "Getbatch",
        "batch",
        "order",
        "purchaserequest",
      ],
    }),
    sendForApprover: builder.mutation({
      query: (payload) => ({
        url: `scm/sourcing/batches/approval/send`,
        method: "POST",
        body: payload,
        headers: {
          "content-Type": "application/json",
        },
      }),
      invalidatesTags: [
        "getApprover",
        "BatchDetail",
        "Getbatch",
        "batch",
        "order",
        "purchaserequest",
      ],
    }),
    sendForApproverPA: builder.mutation({
      query: (payload) => ({
        url: `scm/sourcing/orders/send_approval`,
        method: "POST",
        body: payload,
        headers: {
          "content-Type": "application/json",
        },
      }),
      invalidatesTags: [
        "getApprover",
        "BatchDetail",
        "Getbatch",
        "batch",
        "order",
        "purchaserequest",
      ],
    }),
    sendForApproverPRF: builder.mutation({
      query: (payload) => ({
        url: `scm/prf/approval/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      invalidatesTags: [
        "getApprover",
        "BatchDetail",
        "Getbatch",
        "batch",
        "order",
        "purchaserequest",
      ],
    }),

    sendForApproverPC: builder.mutation({
      query: (payload) => ({
        url: `scm/pc/approval/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      invalidatesTags: [
        "getApprover",
        "BatchDetail",
        "Getbatch",
        "batch",
        "order",
        "purchaserequest",
      ],
    }),
    addBillTo: builder.mutation({
      query: (payload) => ({
        url: `scm/sourcing/orders-updateBillTo/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "content-Type": "application/json",
        },
      }),
      invalidatesTags: ["order"],
    }),
    editStage: builder.mutation({
      query: (payload) => ({
        url: `scm/sourcing/setStage/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "content-Type": "application/json",
        },
      }),
      invalidatesTags: ["order"],
    }),
    editBatch: builder.mutation({
      query: (payload) => ({
        url: `scm/sourcing/setBatch/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "content-Type": "application/json",
        },
      }),
      invalidatesTags: ["order"],
    }),
    editLogsticStage: builder.mutation({
      query: (payload) => ({
        url: `scm/logistics/setStage/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "content-Type": "application/json",
        },
      }),
      invalidatesTags: ["BatchDetail"],
    }),
    editPiNumber: builder.mutation({
      query: (payload) => ({
        url: `scm/sourcing/pinumber/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "content-Type": "application/json",
        },
      }),
      invalidatesTags: ["order"],
    }),
    getSupplierMapping: builder.query({
      query: (id) => `scm/supplier/mapping/${id}`,
    }),
    getNotification: builder.query({
      query: () => ({
        url: `scm/notifications`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      providesTags: ["notification"],
    }),
    readNotification: builder.mutation({
      query: (payload) => ({
        url: `scm/markNotificationasRead`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      invalidatesTags: ["notification"],
    }),
    getStore: builder.query({
      query: () => `/scm/stock/setting/stores`,
      providesTags: ["store"],
    }),

    getItemType: builder.query({
      query: () => `scm/stock/setting/inventory-type`,
      providesTags: ["store"],
    }),
    getToolType: builder.query({
      query: () => `scm/stock/setting/tool-type`,
      providesTags: ["store"],
    }),
    addStoreSetting: builder.mutation({
      query: (payload) => ({
        url: "scm/stock/setting/stores/create",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["store"],
    }),
    addToStoreTool: builder.mutation({
      query: (payload) => ({
        url: "scm/stock/tools/add-to-store",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["store"],
    }),
    addToStoreAssets: builder.mutation({
      query: (payload) => ({
        url: "scm/stock/assets/add-to-store",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["store"],
    }),
    addToStoreToInventory: builder.mutation({
      query: (payload) => ({
        url: "scm/stock/inventories/addToInventory",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["store"],
    }),
    addPoToStoreTool: builder.mutation({
      query: (payload) => ({
        url: "scm/stock/tools/add-po-to-store",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["store"],
    }),
    addPoToStoreAsset: builder.mutation({
      query: (payload) => ({
        url: "scm/stock/assets/add-po-to-store",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["store"],
    }),
    addPoToStoreToInventory: builder.mutation({
      query: (payload) => ({
        url: "scm/stock/inventories/addPoToInventory",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["store"],
    }),
    addToolType: builder.mutation({
      query: (payload) => ({
        url: "scm/stock/setting/tool-type/create",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["store"],
    }),

    editStoreSetting: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/setting/stores/update/${payload?.id}`,
        method: "PUT",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["store"],
    }),
    editToolType: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/setting/tool-type/update/${payload?.id}`,
        method: "PUT",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["store"],
    }),
    editInventoryType: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/setting/inventory-type/update/${payload?.id}`,
        method: "PUT",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["store"],
    }),
    editStatusSetting: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/setting/item-status/update/${payload?.id}`,
        method: "PUT",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["store"],
    }),
    DeleteStoreSetting: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/setting/stores/delete/${payload?.id}`,
        method: "PUT",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["store"],
    }),
    DeleteToolType: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/setting/tool-type/delete/${payload?.id}`,
        method: "PUT",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["store"],
    }),
    DeleteInventoryType: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/setting/inventory-type/delete/${payload?.id}`,
        method: "PUT",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["store"],
    }),
    DeleteStatusSetting: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/setting/item-status/delete/${payload?.id}`,
        method: "PUT",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["store"],
    }),
    getStatus: builder.query({
      query: () => `/scm/stock/setting/item-status`,
      providesTags: ["store"],
    }),

    addStatusSetting: builder.mutation({
      query: (payload) => ({
        url: "scm/stock/setting/item-status/create",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["store"],
    }),
    addInventoryTypeSetting: builder.mutation({
      query: (payload) => ({
        url: "scm/stock/setting/inventory-type/create",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["store"],
    }),
    getItemCategorys: builder.query({
      query: () => `scm/stock/setting/category`,
      providesTags: ["store"],
    }),
    addItemCategory: builder.mutation({
      query: (payload) => ({
        url: "scm/stock/setting/category/create",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["store"],
    }),
    DeleteItemCategory: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/setting/category/delete/${payload?.id}`,
        method: "PUT",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["store"],
    }),
    editItemCategory: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/setting/category/update/${payload?.id}`,
        method: "PUT",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["store"],
    }),
    // Stock Purchased
    getPurchasedRequest: builder.query({
      query: () => `scm/stock/purchase-request`,
      providesTags: ["purchaserequest"],
    }),

    getTools: builder.query({
      query: () => `scm/stock/tools`,
      providesTags: ["purchaserequest"],
    }),
    getGeneralTools: builder.query({
      query: () => `scm/stock/tools/general`,
      providesTags: ["purchaserequest"],
    }),
    getApprovedItems: builder.query({
      query: () => `scm/stock/approved
      `,
      providesTags: ["purchaserequest"],
    }),
    getRequestItems: builder.query({
      query: () => `scm/stock/transfered-tools`,
      // providesTags: ["purchaserequest"],
    }),
    dispatchedTools: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/weekly-dispatched-tools`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["store"],
    }),
    dispatchedItems: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/weekly-dispatched-inventories`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["store"],
    }),
    returnedTools: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/weekly-returned-tools`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["store"],
    }),
    transferredTools: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/transfer-tools`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["store"],
    }),
    transferredItems: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/transfer-inventories`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["store"],
    }),
    transferredAssets: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/transfer-assets`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["store"],
    }),

    getToolsRequests: builder.query({
      query: () => `scm/stock/tools/requested`,
      providesTags: ["purchaserequest"],
    }),
    getToolsTransaction: builder.query({
      query: () => `scm/stock/inventorytransactions/tool`,
      providesTags: ["purchaserequest"],
    }),

    getInventoryTransaction: builder.query({
      query: () => `scm/stock/inventorytransactions/item`,
      providesTags: ["purchaserequest"],
    }),

    getAssetsTransaction: builder.query({
      query: () => `scm/stock/inventorytransactions/asset`,
      providesTags: ["purchaserequest"],
    }),

    getTransaction: builder.query({
      query: () => `scm/stock/inventorytransactions`,
      providesTags: ["purchaserequest"],
    }),

    getToolRequested: builder.query({
      query: (id) => `scm/stock/tool/${id}`,
      providesTags: ["purchaserequest"],
    }),
    getInventoryRequested: builder.query({
      query: (id) => `scm/stock/inventory/${id}`,
      providesTags: ["purchaserequest"],
    }),
    getAssetsRequested: builder.query({
      query: (id) => `scm/stock/assets/detail/${id}`,
      providesTags: ["purchaserequest"],
    }),
    getInventorysRequests: builder.query({
      query: () => ({
        url: `scm/stock/project/inventories`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      providesTags: ["purchaserequest"],
    }),
    getBatchToolsRequests: builder.query({
      query: () => ({
        url: `scm/stock/tools/allCustomRequests`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      providesTags: ["purchaserequest"],
    }),
    getInventorysBatchReturns: builder.query({
      query: () => ({
        url: `scm/stock/inventory/allCustomRequests/new`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      providesTags: ["purchaserequest"],
    }),
    getInventorysBatchConsum: builder.query({
      query: () => ({
        url: `scm/stock/inventory/allCustomConsums/new`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      providesTags: ["purchaserequest"],
    }),
    getToolsBatchReturns: builder.query({
      query: () => ({
        url: `scm/stock/tools/allCustomRequests`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      providesTags: ["purchaserequest"],
    }),
    getAssetsRequestsBatch: builder.query({
      query: () => ({
        url: `scm/stock/assets/allCustomRequests`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      providesTags: ["purchaserequest"],
    }),
    getAssetsRequests: builder.query({
      query: () => ({
        url: `scm/stock/assets/requested`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      providesTags: ["purchaserequest"],
    }),
    addTool: builder.mutation({
      query: (payload) => ({
        url: "scm/stock/tools/create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    getInventory: builder.query({
      query: () => `scm/stock/inventory/index`,
      providesTags: ["purchaserequest"],
    }),
    getGeneralInventory: builder.query({
      query: () => `scm/stock/inventory/general`,
      providesTags: ["purchaserequest"],
    }),
    getFixedAssets: builder.query({
      query: () => `scm/stock/fixed-assets`,
      providesTags: ["purchaserequest"],
    }),
    getGeneralFixedAssets: builder.query({
      query: () => `scm/stock/fixed-assets/general`,
      providesTags: ["purchaserequest"],
    }),
    getFixedInventoryAssets: builder.query({
      query: () => `scm/stock/fixed-assets-inventory`,
      providesTags: ["purchaserequest"],
    }),

    addUnitOfMeasurement: builder.mutation({
      query: (payload) => ({
        url: "scm/stock/setting/unit-of-measurement/create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    AddPurchasedRequest: builder.mutation({
      query: (payload) => ({
        url: "scm/stock/purchase-request/create",
        method: "POST",
        body: payload,
      }),
      headers: {
        "Content-Type": "multipart/form-data",
      },
      invalidatesTags: ["purchaserequest"],
    }),
    editUnitOfMeasurement: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/setting/unit-of-measurement/update/${payload?.id}`,
        method: "PUT",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    getUnitOfMeasurement: builder.query({
      query: () => `scm/stock/setting/unit-of-measurement`,
      providesTags: ["purchaserequest"],
    }),
    getAssetCatagories: builder.query({
      query: () => `scm/stock/fixed-asset-category`,
      providesTags: ["purchaserequest"],
    }),
    addAssetCatagories: builder.mutation({
      query: (payload) => ({
        url: "scm/stock/fixed-asset-category",
        method: "POST",
        body: payload,
      }),
      headers: {
        "Content-Type": "multipart/form-data",
      },
      invalidatesTags: ["purchaserequest"],
    }),
    editAssetCatagories: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/fixed-asset-category/${payload?.id}`,
        method: "PUT",
        body: payload,
      }),
      headers: {
        "Content-Type": "multipart/form-data",
      },
      invalidatesTags: ["purchaserequest"],
    }),
    deleteAssetCatagories: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/fixed-asset-category/${payload?.id}`,
        method: "DELETE",
        body: payload,
      }),
      headers: {
        "Content-Type": "multipart/form-data",
      },
      invalidatesTags: ["purchaserequest"],
    }),

    DeleteUnitOfMeasurement: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/setting/unit-of-measurement/delete/${payload?.id}`,
        method: "PUT",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    ToolRequest: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/tools/request/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    AssetRequest: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/assets/request/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    InventoryRequest: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/inventory/request/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),

    InStoreItemsRequest: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/tools/confirm/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),

    ItemsRequest: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/tools/receive/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    ToolCancel: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/tools/cancel`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    ItemsCancel: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/inventories/cancel`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    AssetsCancel: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/assets/cancel`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    AssetsReceive: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/assets/receive/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    ToolsReturn: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/tools/customReturn/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    // ToolsReturn: builder.mutation({
    //   query: (payload) => ({
    //     url: `scm/stock/tools/batch-return/${payload?.id}`,
    //     method: "POST",
    //     body: payload,
    //     headers: {
    //       "Content-type": "application/json",
    //     },
    //   }),
    //   invalidatesTags: ["purchaserequest"],
    // }),

    ItemsReturnBatch: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/inventory/customReturn/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          // "Content-type": "multipart/form-data",
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    // ItemsReturnBatch: builder.mutation({
    //   query: (payload) => ({
    //     url: `scm/stock/inventory/batch-return/${payload?.id}`,
    //     method: "POST",
    //     body: payload,
    //     headers: {
    //       "Content-type": "application/json",
    //     },
    //   }),
    //   invalidatesTags: ["purchaserequest"],
    // }),
    AssetsReturnBatch: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/assets/customReturn/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    AssetsConsumeBatch: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/inventory/customConsume/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    // AssetsReturnBatch: builder.mutation({
    //   query: (payload) => ({
    //     url: `scm/stock/assets/batch-return/${payload?.id}`,
    //     method: "POST",
    //     body: payload,
    //     headers: {
    //       "Content-type": "application/json",
    //     },
    //   }),
    //   invalidatesTags: ["purchaserequest"],
    // }),
    inventorysAcceptsBatch: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/inventory/customAccept/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    toolsAcceptsBatch: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/tools/customAccept/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    inventoryRejectBatch: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/inventory/customReject/${payload?.id}`,
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    assetsRejectBatch: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/assets/customReject/${payload?.id}`,
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    toolsRejectBatch: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/tools/customReject/${payload?.id}`,
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    assetsAcceptsBatch: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/assets/customAccept/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    consumeAproveBatch: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/inventory/customConsumAccept/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    inventoryAcceptsBatch: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/inventory/batch-accept/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    ItemsReturn: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/tools/return/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    AssetReturn: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/assets/return/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),

    ItemsAccept: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/tools/accept/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    ItemsInventoryConfirm: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/inventory/storeKeeperConfirmation/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    itemsConsumeConfirm: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/inventory/consumedApproved/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    ItemsAssetsConfirm: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/assets/confirm/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    ItemsInventoryReceive: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/inventory/receive/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    ItemsInventoryReturn: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/inventory/return/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    ItemsInventoryConsume: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/inventory/consumed/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    ItemsInventoryAccept: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/inventory/storekeeper/receive/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),

    getProjectStore: builder.query({
      query: () => `scm/stock/setting/project-stores`,
      providesTags: ["purchaserequest"],
    }),
    getProjectStoreItems: builder.query({
      query: (id) => `scm/stock/tools/project-tools/${id}`,
      providesTags: ["purchaserequest"],
    }),
    getProjectStoreAssets: builder.query({
      query: (id) => `scm/stock/assets/project-assets/${id}`,
      providesTags: ["purchaserequest"],
    }),
    getProjectStoreItemsInventory: builder.query({
      query: (id) => `scm/stock/project/inventories/${id}`,
      providesTags: ["purchaserequest"],
    }),
    getToolHistory: builder.query({
      query: (id) => `scm/stock/tools/history/${id}`,
      providesTags: ["purchaserequest"],
    }),
    getInventoryHistory: builder.query({
      query: (id) => `scm/stock/inventories/history/${id}`,
      providesTags: ["purchaserequest"],
    }),
    deletePurchasedRequest: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/purchase-request/delete/${payload.id}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    getRecordTools: builder.query({
      query: () => `scm/stock/tools/records`,
      providesTags: ["purchaserequest"],
    }),
    getInventoryDashboard: builder.query({
      query: () => `scm/stock/inventory/dashboard/inventory-count`,
      providesTags: ["purchaserequest"],
    }),
    getToolsDashboard: builder.query({
      query: () => `scm/stock/tools/dashboard/status-count`,
      providesTags: ["purchaserequest"],
    }),
    getTopSuppliers: builder.query({
      query: () => `scm/sourcing/topSuppliers`,
      providesTags: ["purchaserequest"],
    }),
    BatchRequestTools: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/tools/batch-request`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    BatchRequestAssets: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/assets/batch-request`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    BatchRequestInventory: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/inventory/batch-request`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    InventoryAccept: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/inventories/approve-inventories-to-accept`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    ToolsAccept: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/tools/approve-tools-to-accept`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    AssetAccept: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/assets/accept/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    AssetsAccept: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/assets/approve-assets-to-accept`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    InventoryDeline: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/inventories/reject-inventories-to-accept`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
        mode: "no-cors",
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    ToolsDeline: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/tools/reject-tools-to-accept`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
        mode: "no-cors",
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    AssetsDeline: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/assets/reject-assets-to-accept`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
        mode: "no-cors",
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    getBatchToolRequest: builder.query({
      query: (id) => `scm/stock/tools/batch-requested/${id}`,
      providesTags: ["purchaserequest"],
    }),
    getBatchAssetRequest: builder.query({
      query: (id) => `scm/stock/assets/batch-requested/${id}`,
      providesTags: ["purchaserequest"],
    }),
    getBatchInventoryReturn: builder.query({
      query: (id) => `scm/stock/inventory/customRequest/${id}`,
      providesTags: ["purchaserequest"],
    }),
    getBatchAssetsReturn: builder.query({
      query: (id) => `scm/stock/assets/customRequest/${id}`,
      providesTags: ["purchaserequest"],
    }),
    getBatchToolReturn: builder.query({
      query: (id) => `scm/stock/tools/customRequest/${id}`,
      providesTags: ["purchaserequest"],
    }),
    getBatchInventoryRequest: builder.query({
      query: (id) => `scm/stock/project/inventories/requested/${id}`,
      providesTags: ["purchaserequest"],
    }),
    getInventoryDetail: builder.query({
      query: (id) => `scm/stock/inventories/detail/${id}`,
    }),
    getIncomingItems: builder.query({
      query: (id) => `scm/stock/inventories/inventories-to-accept`,
      providesTags: ["purchaserequest"],
    }),
    getIncomingTools: builder.query({
      query: (id) => `scm/stock/tools/tools-to-accept`,
      providesTags: ["purchaserequest"],
    }),
    getIncomingAssets: builder.query({
      query: (id) => `scm/stock/assets/assets-to-accept`,
      providesTags: ["purchaserequest"],
    }),
    getAllOrders: builder.query({
      query: (id) => `scm/sourcing/po`,
      providesTags: ["purchaserequest"],
    }),
    getNoBoq: builder.query({
      query: (id) => `scm/sourcing/po/nonboq`,
      providesTags: ["purchaserequest"],
    }),
    InventoryUpdate: builder.mutation({
      query: (payload) => ({
        url: `scm/stock/inventory/update/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "multipart/form-data",
        },
        mode: "no-cors",
      }),
      invalidatesTags: ["purchaserequest"],
    }),
    //  price comparison

    priceComparision: builder.mutation({
      query: (payload) => ({
        url: "scm/sourcing/compare-price",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["order"],
    }),
    priceComparisionAttachement: builder.mutation({
      query: (payload) => ({
        url: "scm/sourcing/price_comparison",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["order"],
    }),
    getPriceComparisionAttachement: builder.query({
      query: (id) => `scm/sourcing/price_comparison`,
      providesTags: ["order"],
    }),
    traningPriceComparision: builder.mutation({
      query: (payload) => ({
        url: "scm/sourcing/training/compare-price",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["order"],
    }),

    finalPriceComparision: builder.mutation({
      query: (payload) => ({
        url: `scm/sourcing/final-compare-price/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      invalidatesTags: ["paymentcollection"],
    }),
    getFinalPriceByProject: builder.query({
      query: (id) => `scm/sourcing/pc/${id}`,
      providesTags: ["purchaserequest"],
    }),
    createOrderPriceComparison: builder.mutation({
      query: (payload) => ({
        url: `scm/sourcing/pc-create-order/${payload?.id}`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["order"],
    }),
    // payment collection
    createPaymentTerm: builder.mutation({
      query: (payload) => ({
        url: `scm/project-payment-term`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      invalidatesTags: ["ForexNeeds", "ForexNeedsList", "BankBalance"],
    }),
    createLessonLearned: builder.mutation({
      query: (payload) => ({
        url: `scm/project/payment-collection-lesson/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      invalidatesTags: ["ForexNeeds", "ForexNeedsList", "BankBalance"],
    }),
    uploadSupplierPi: builder.mutation({
      query: (payload) => ({
        url: `scm/supplier-payment/attach-pi/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      invalidatesTags: ["ForexNeeds", "ForexNeedsList", "BankBalance"],
    }),
    getProjectPaymentTerm: builder.query({
      query: (id) => ({
        url: `scm/project-payment-terms/${id}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      providesTags: ["paymentcollection"],
    }),
    getPaymentCollections: builder.query({
      query: (id) => `scm/finance/payment-collection/${id}`,
      providesTags: ["paymentcollection"],
    }),
    getPaymentCollectionsOnshore: builder.query({
      query: (id) => ({
        url: `scm/project/payment-collection/${id}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      providesTags: ["paymentcollection"],
    }),
    getPaymentCollectionsOffshore: builder.query({
      query: (id) => ({
        url: `scm/project/forex-payment-collection/${id}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      providesTags: ["paymentcollection"],
    }),
    getAllPaymentCollections: builder.query({
      query: (id) => ({
        url: `scm/project/payment-collection-all`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      providesTags: ["paymentcollection"],
    }),
    getAllForexPaymentCollections: builder.query({
      query: (id) => ({
        url: `scm/project/forex-payment-collection-all`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      providesTags: ["paymentcollection"],
    }),
    createPaymentCollection: builder.mutation({
      query: (payload) => ({
        url: `scm/project/payment-collection`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      invalidatesTags: ["paymentcollection"],
    }),
    deletePaymentCollection: builder.mutation({
      query: (payload) => ({
        url: `scm/project/payment-collection/${payload?.id}`,
        method: "DELETE",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      invalidatesTags: ["paymentcollection"],
    }),
    deleteForexPaymentCollection: builder.mutation({
      query: (payload) => ({
        url: `scm/project/forex-payment-collection/${payload?.id}`,
        method: "DELETE",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      invalidatesTags: ["paymentcollection"],
    }),
    createPaymentCollectionOffshore: builder.mutation({
      query: (payload) => ({
        url: `scm/project/forex-payment-collection`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      invalidatesTags: ["paymentcollection"],
    }),
    updatePaymentCollectionOffshore: builder.mutation({
      query: (payload) => ({
        url: `scm/project/forex-payment-collection/${payload?.id}`,
        method: "PATCH",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      invalidatesTags: ["paymentcollection"],
    }),
    createRevenue: builder.mutation({
      query: (payload) => ({
        url: `scm/project/payment-revenue/${payload?.id}`,
        method: "PUT",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      invalidatesTags: ["paymentcollection"],
    }),
    getProjectRevenue: builder.query({
      query: (id) => ({
        url: `/scm/project/revenue`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      providesTags: ["paymentcollection"],
    }),
    getProjectRevenueShow: builder.query({
      query: (id) => ({
        url: `/scm/project/project-revenue/${id}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      providesTags: ["paymentcollection"],
    }),
    getGrossProfit: builder.query({
      query: (id) => ({
        url: `/scm/gross-profitability`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      providesTags: ["paymentcollection"],
    }),
    getGrossProfitBirr: builder.query({
      query: (payload) => ({
        url: `/scm/gross-profitability-filter?start=${payload?.start}&end=${payload?.end}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      providesTags: ["paymentcollection"],
    }),
    updatePaymentCollection: builder.mutation({
      query: (payload) => ({
        url: `scm/project/payment-collection/${payload?.id}`,
        method: "Post",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      invalidatesTags: ["paymentcollection"],
    }),
    // Variance Analysis
    getVarianceAnalysis: builder.query({
      query: (id) => `scm/finance/variance-analysis/${id}`,
      providesTags: ["paymentcollection"],
    }),
    getVarianceAnalysisAll: builder.query({
      query: (id) => `scm/finance/variance-analysis`,
      providesTags: ["paymentcollection"],
    }),
    getBudgetVariance: builder.mutation({
      query: (payload) => ({
        url: `scm/get/usedAmount`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["paymentcollection"],
    }),
    createVarianceAnalysis: builder.mutation({
      query: (payload) => ({
        url: `scm/finance/variance-analysis`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["paymentcollection"],
    }),
    updateVarianceAnalysis: builder.mutation({
      query: (payload) => ({
        url: `scm/finance/variance-analysis/${payload?.id}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["paymentcollection"],
    }),
    generatePrf: builder.mutation({
      query: (payload) => ({
        url: `/scm/sourcing/prf/${payload?.order_id}`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["paymentcollection"],
    }),
    createPrf: builder.mutation({
      query: (payload) => ({
        url: `/scm/prf/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      invalidatesTags: ["paymentcollection"],
    }),
    updatePrf: builder.mutation({
      query: (payload) => ({
        url: `/scm/prf/${payload?.id}`,
        method: "PATCH",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      invalidatesTags: ["paymentcollection"],
    }),
    getPRF: builder.query({
      query: (id) => `scm/sourcing/prf/${id}`,
      providesTags: ["paymentcollection"],
    }),
    getProjectPRF: builder.query({
      query: (id) => ({
        url: `/scm/prf/request/${id}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      providesTags: ["paymentcollection"],
    }),
    getPoPRF: builder.query({
      query: (id) => ({
        url: `scm/prf/${id}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      providesTags: ["paymentcollection"],
    }),
    showPRF: builder.query({
      query: (id) => ({
        url: `scm/prfs/${id}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      providesTags: ["paymentcollection"],
    }),

    requestPrf: builder.mutation({
      query: (payload) => ({
        url: `/scm/prf/request/${payload?.po_id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      invalidatesTags: ["paymentcollection"],
    }),
    zero: builder.query({
      query: (id) => `scm/sourcing/pa-zero-total-cost`,
      providesTags: ["paymentcollection"],
    }),

    getAllGuarantee: builder.query({
      query: () => ({
        url: `/scm/guarantee`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      providesTags: ["paymentcollection"],
    }),
    getAllClearedGuarantee: builder.query({
      query: () => ({
        url: `/scm/cleared-guarantee`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      providesTags: ["paymentcollection"],
    }),
    getAllExpiredGuarantee: builder.query({
      query: () => ({
        url: `/scm/filter-guarantee`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      providesTags: ["paymentcollection"],
    }),
    getExpiredGuarantee: builder.query({
      query: () => ({
        url: `/scm/expired-guarantee`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      providesTags: ["paymentcollection"],
    }),
    getSummaryGuarantee: builder.query({
      query: (id) => ({
        url: `scm/balance/guarantee_balance/${id}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      providesTags: ["paymentcollection"],
    }),
    getProjectGuarantee: builder.query({
      query: (id) => ({
        url: `/scm/project/guarantee/${id}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      providesTags: ["paymentcollection"],
    }),
    getAllGuaranteeType: builder.query({
      query: () => ({
        url: `/scm/guarantee-type`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      providesTags: ["paymentcollection"],
    }),
    getAllGuaranteeBalance: builder.query({
      query: () => ({
        url: `/scm/guarantee_balance`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      providesTags: ["paymentcollection"],
    }),
    createGuarantee: builder.mutation({
      query: (payload) => ({
        url: `/scm/guarantee`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      invalidatesTags: ["paymentcollection"],
    }),
    clearGuarantee: builder.mutation({
      query: (payload) => ({
        url: `/scm/clear-guarantee/${payload?.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      invalidatesTags: ["paymentcollection"],
    }),
    editGuarantee: builder.mutation({
      query: (payload) => ({
        url: `/scm/guarantee/${payload?.id}`,
        method: "PATCH",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      invalidatesTags: ["paymentcollection"],
    }),
    EditGuaranteeBalance: builder.mutation({
      query: (payload) => ({
        url: `/scm/guarantee_balance/${payload?.id}`,
        method: "PATCH",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      invalidatesTags: ["paymentcollection"],
    }),
    CreateGuaranteeBalance: builder.mutation({
      query: (payload) => ({
        url: `/scm/guarantee_balance`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
      invalidatesTags: ["paymentcollection"],
    }),
    // approval module

    GetApprovalModule: builder.query({
      query: (id) => ({
        url: `scm/approval_modules`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }),
      providesTags: ["paymentcollection"],
    }),
    GetSingleApprovalModule: builder.query({
      query: (id) => ({
        url: `scm/approval_module/${id}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }),
      providesTags: ["paymentcollection"],
    }),
    UpdateApprovalModule: builder.mutation({
      query: (payload) => ({
        url: `scm/approval_modules/${payload?.approval_module}/${payload?.id}`,
        method: "POST",
        body: payload?.data,
        headers: {
          "Content-type": "application/json",
        },
        mode: "no-cors",
      }),
      invalidatesTags: ["roles"],
    }),
    //Grn//
    downloadRequestedGrnByGrnId: builder.mutation({
      query: (id) => ({
        url: `scm/grn/getGrn/${id}`,
        method: "GET",
        headers: {
          "Content-type": "application/json",
        },
      }),
    }),

    generateGrnForPrf: builder.mutation({
      query: (newArrayData) => ({
        url: `scm/grn/generateGrn/${newArrayData.id}`,
        method: "POST",
        body: newArrayData,
        headers: {
          "Content-type": "application/json",
        },
      }),
    }),
    getListOfUnitOfMeasurment: builder.mutation({
      query: (newArrayData) => ({
        url: `scm/stock/setting/unit-of-measurement`,
        method: "get",
        body: newArrayData,
        headers: {
          "Content-type": "application/json",
        },
      }),
    }),

    getGrnRequest: builder.mutation({
      query: () => ({
        url: "scm/grn/getRequests",
        method: "GET",
        headers: {
          "Content-type": "application/json",
        },
      }),
    }),

    LCitemTrackng: builder.mutation({
      query: () => ({
        url: `scm/grn/itemTrackng`,
        method: "GET",
        headers: {
          "Content-type": "application/json",
        },
      }),
    }),

    LCItemTrackingDetail: builder.mutation({
      query: (id) => ({
        url: `/scm/grn/itemTracking/detail/${id}`,
        method: "GET",
        headers: {
          "Content-type": "application/json",
        },
      }),
    }),

    requestForGrn: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        formData.append("purpose_of_purchase", data?.reason);

        if (data?.receipt) {
          formData.append("receipt", data?.receipt);
        }

        return {
          url: `/scm/grn/requestGrn/${data?.requestPrfId}`,
          method: "POST",
          body: formData,
        };
      },
    }),

    getDeliveredItemListByGroupId: builder.mutation({
      query: (id) => ({
        url: `scm/item-delivery/getGroup/${id}`,
        method: "GET",
      }),
    }),

    requestGrnForGroupItemsByGroupId: builder.mutation({
      query: (groupId, userId) => ({
        url: `scm/grn/offshoreRequest/${groupId}`,
        method: "POST",
      }),
    }),

    generateGrnForOffshoreItemsByRequestId: builder.mutation({
      query: (payload) => ({
        url: `scm/grn/generateOffshoreGrn/${payload.id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
        },
      }),
    }),

    getDeliveredGroupByBatchId: builder.mutation({
      query: (id) => ({
        url: `scm/item-delivery/${id}`,
        method: "GET",
      }),
    }),

    getPRFByOrderId: builder.mutation({
      query: (order_id) => ({
        url: `/scm/grn/getPrf/${order_id}`,
        method: "GET",
      }),
    }),
    //Grn//
    // service
    getServiceType: builder.query({
      query: () => `/scm/sourcing/service-category`,
      providesTags: ["order"],
    }),
    createOrderTraningService: builder.mutation({
      query: (payload) => ({
        url: "scm/sourcing/service/training",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["order"],
    }),
    CreateOrderMaterialService: builder.mutation({
      query: (payload) => ({
        url: "scm/sourcing/service/orders",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["order"],
    }),

    createTraningOrder: builder.mutation({
      query: (payload) => ({
        url: "scm/sourcing/training",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["order"],
    }),
    ApprovePrfBudget: builder.mutation({
      query: (payload) => ({
        url: "scm/budget/company-budget",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["order"],
    }),
    BudgetApproval: builder.mutation({
      query: (payload) => ({
        url: "scm/budget/company-budget/approval",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["order"],
    }),
    getPrfBudget: builder.query({
      query: (id) => ({
        url: `scm/budget/prf`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
    }),
    getBudgetApproved: builder.query({
      query: (id) => ({
        url: `scm/budget/company-budget/approval`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
    }),
    ApprovedMonthlyBudget: builder.query({
      query: (id) => ({
        url: `scm/budget/company-budget/approved`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
    }),
    getPrfBudgetOffice: builder.query({
      query: (id) => ({
        url: `scm/budget/office`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + BearerToken,
        },
      }),
    }),
  }),
});

export const {
  //PRF Budget
  useGetPrfBudgetQuery,
  useApprovePrfBudgetMutation,
  useApprovedMonthlyBudgetQuery,
  useGetBudgetApprovedQuery,
  useBudgetApprovalMutation,
  useGetPrfBudgetOfficeQuery,
  // GRN
  useDownloadRequestedGrnByGrnIdMutation,
  useGenerateGrnForPrfMutation,
  useGetListOfUnitOfMeasurmentMutation,
  useGetGrnRequestMutation,
  useLCitemTrackngMutation,
  useLCItemTrackingDetailMutation,
  useRequestForGrnMutation,
  useGetDeliveredItemListByGroupIdMutation,
  useRequestGrnForGroupItemsByGroupIdMutation,
  useGenerateGrnForOffshoreItemsByRequestIdMutation,
  useGetDeliveredGroupByBatchIdMutation,
  useGetPRFByOrderIdMutation,

  //prf
  useGeneratePrfMutation,
  useCreatePrfMutation,
  useUpdatePrfMutation,
  useGetPRFQuery,
  useGetProjectPRFQuery,
  useGetPoPRFQuery,
  useShowPRFQuery,
  useRequestPrfMutation,
  useZeroQuery,
  useGetServiceTypeQuery,
  useCreateOrderTraningServiceMutation,
  useCreateOrderMaterialServiceMutation,

  useCreateTraningOrderMutation,

  //payment collection
  useGetPaymentCollectionsQuery,
  useGetPaymentCollectionsOnshoreQuery,
  useGetPaymentCollectionsOffshoreQuery,
  useGetProjectPaymentTermQuery,
  useCreatePaymentTermMutation,
  useCreateLessonLearnedMutation,
  useUploadSupplierPiMutation,
  useGetAllPaymentCollectionsQuery,
  useGetAllForexPaymentCollectionsQuery,
  useCreatePaymentCollectionMutation,
  useDeletePaymentCollectionMutation,
  useDeleteForexPaymentCollectionMutation,
  useCreatePaymentCollectionOffshoreMutation,
  useUpdatePaymentCollectionOffshoreMutation,
  useCreateRevenueMutation,
  useGetProjectRevenueQuery,
  useGetProjectRevenueShowQuery,
  useGetGrossProfitQuery,
  useGetGrossProfitBirrQuery,
  useUpdatePaymentCollectionMutation,
  //Variance Analysis
  useGetVarianceAnalysisQuery,
  useGetVarianceAnalysisAllQuery,
  useGetBudgetVarianceQuery,
  useCreateVarianceAnalysisMutation,
  useUpdateVarianceAnalysisMutation,

  //
  useCreateProjectMutation,
  useCloseProjectMutation,
  useAddSolutionMutation,
  useGetProjectsQuery,
  useGetProjectQuery,
  useGetProjectInputParamsQuery,
  useGetProductsQuery,
  useGetProductQuery,
  useChangeClientMutation,
  useEditProjectMutation,
  useGetSupplierOriginsQuery,
  useGetBoqtypesQuery,
  useHasBoqQuery,
  useAddBoqMutation,
  useGetOriginQuery,
  useGetAirPortLoadingQuery,
  useGetSeaPortLoadingQuery,
  useGetDischargeQuery,
  useGetShipmentModeQuery,
  useAddLcMutation,
  useGetSuppliersQuery,
  useGetSuppliersOrdersQuery,
  useGetCreditNoteSupplierQuery,
  useAddSupplierMutation,
  useAddBillToMutation,
  useEditStageMutation,
  useEditBatchMutation,
  useEditLogsticStageMutation,
  useEditPiNumberMutation,
  useAddSupplierProductMutation,

  useGetSupplierQuery,
  useGetManufacturersQuery,
  useGetManufacturerQuery,
  useGetSupplierManufacterQuery,
  useGetFrightForwardersQuery,
  useGetFrightForwarderQuery,
  useGetMappingsQuery,
  useGetMillstonesQuery,
  useGetSettingBankQuery,
  useCreateClientMutation,
  useGetOnlyManufactersQuery,

  //supplier mutation start
  useCreateSupplierMutation,
  useDeleteSupplierProductMutation,
  useAddManufacturerMutation,
  useAddFreightForwardersMutation,
  useUpdateSupplierAddressMutation,
  useUpdateManufacturerAddressMutation,
  useUpdateFreightForwarderAddressMutation,
  useUpdateSupplierRepresentativeMutation,
  useAddSupplierRepresentativeMutation,
  useAddManufacturerRepresentativeMutation,
  useAddFrieghtForwarderRepresentativeMutation,
  useAddBankInformationMutation,
  useEditSupplierRepresentativeMutation,
  useEditSupplierBankInformationMutation,
  useApprovalPitoPoMutation,
  useApprovalPoMutation,
  useAddPaymentInformationMutation,
  useEditPaymentInformationMutation,
  useCreatePoGroupMutation,
  useAddEtadateMutation,
  useEditEtadateMutation,
  useFinalEtaMutation,
  // email subject and handover date
  useAddEmailHandoverMutation,
  useAddEtdBatchMutation,
  //description and current status
  useUpdateDescriptionMutation,
  useUpdateCurrentStatusMutation,
  useAddSettingBankinformationMutation,
  useEditSettingBankinformationMutation,
  useDeleteSettingBankinformationMutation,
  useAddPaymentTermMutation,
  useEditPaymentTermMutation,
  useDeletePaymentTermMutation,
  useAddPaymentModeMutation,
  useEditPaymentModeMutation,
  useDeletePaymentModeMutation,
  // supplier mutation end
  useGetSorcingStageQuery,
  useGetOrderStageQuery,
  useGetShipmentModesQuery,
  useGetOriginsQuery,
  useGetSeaPortsQuery,
  useGetAirPortsQuery,
  useGetDischargesQuery,
  useCreateCiMutation,
  useGetItemsQuery,
  useGetItemQuery,
  useGetSolutionDetailsMutation,
  useGetCurrenciesQuery,
  useGetAllCurrenciesQuery,
  useGetSupplierCategoriesQuery,
  useGetProjectItemQuery,
  useGetSourcingProjectItemQuery,
  useGetOrderItemQuery,
  useGetOrdercomparisionQuery,
  useGetOrderPoItemQuery,
  useGetSupplierTypesQuery,
  useGetPaymentsModeQuery,
  useGetPaymentTermsQuery,
  useGetShipmentsModeQuery,
  useGetEtasQuery,
  useGetProjectDocumentsQuery,
  useGetProjectDocumentQuery,
  useGetProjectBatchQuery,
  useGetApprovedForex,
  useGetPoGroupQuery,
  useGetRMADocumentQuery,
  useGetChamberuizedDocumentQuery,
  useGetPermitDocumentQuery,
  useGetOrderPoDocumentQuery,
  useGetPoGroupDocumentQuery,
  useGetSampleDocumentQuery,
  useGetIncotermsQuery,
  useGetLoadingPortalsQuery,
  useGetAirLoadingPortsQuery,
  useGetSeaLoadingPortsQuery,
  useGetShipmentModesAddQuery,
  useGetShipmentDestinationsQuery,
  useGetEtaDetailQuery,
  useGetBatchQuery,
  useCreateOrderMutation,
  useUpdateOrderMutation,
  useDeleteOrderMutation,
  useDeletesupplierRepersentativeMutation,
  useUpdateOrderSourceMutation,
  useDeletesupplierBankMutation,
  useGetPaSummaryQuery,
  useGetSettingClientQuery,
  useGetSettingClientRepresentativeQuery,
  useAddClientMutaion,
  useEditClientRepresentativeMutation,
  useEditClientMutation,
  useAddClientRepresentativeMutation,
  useRemoveMemberMutation,
  useGetSubSolutionsQuery,
  useAssignPersonnelMutation,
  useAssignSolutionMutation,
  useGetSubSolutionsPerProjectMutation,
  useRemoveSolutionMutation,
  useGetPoQuery,
  useGetBatchesQuery,
  useGetBatchDetailQuery,
  useGetProjectMembersQuery,
  useDeleteSolutionMutation,
  useDeleteSubSolutionMutation,
  useEditSubSolutionMutation,
  useEditSolutionMutation,
  useAddNewSubSolutionMutation,
  useChangePmrMutation,
  useAttachDocumentMutation,
  useGetBoqQuery,
  useSubmitBankMutation,
  useDeleteBatchMutation,

  //Payment module use API's
  useGetForexQuery,
  useUpdateForexNeedStatusMutation,
  useApproveForexMutation,
  useClearForexMutation,
  useCreateStatusForexMutation,
  useCreateStatusForexAchievedMutation,
  useCreateForexExpirationRemarkMutation,
  useGetForexProjectQuery,
  useGetStatusForexQuery,
  useGetForexExpirationRemarkQuery,
  useGetForexProjectIeInitiatedQuery,
  useGetOrderListQuery,
  useGetForexPaymentModeQuery,
  useGetForexOpeningToQuery,
  useGetForexShipmentModeQuery,
  useGetForexLoadingOriginQuery,
  useGetForexDischargePortQuery,
  useGetForexAirLoadingPortQuery,
  useGetSeaLoadingPortQuery,
  useGetForexIncotermsQuery,
  useGetForexDetailQuery,
  useSelectForexNeedBankMutation,
  useSelectSupplierPaymentBankMutation,
  useSelectSupplierPaymentCurrencyMutation,
  useUpdateSupplierPaymentPIMutation,
  useInititateForexIEMutation,
  useInititateForexClientMutation,
  useAddForexTermConditionMutation,
  useAddForexPlanningReceivableMutation,
  useUpdateForexPlaningReceivableMutation,
  useCheckedForexPlanningReceivableMutation,
  useUpdateForexTermConditionMutation,
  useGetBankBalanceQuery,
  useGetForexReceivableQuery,
  useGetReceivableManufacturersQuery,
  useGetReceivableBanksQuery,
  useGetForexNeedsQuery,
  useGetPaymentTrackingForProjectQuery,
  useGetPaymentTrackingForProjectReportQuery,
  useGetPaymentTrackingForVendorQuery,
  useGetStatementOfAccountQuery,
  useGetSoaReportMutation,
  useGetSupplierCreditQuery,
  useGetSupplierPaymentQuery,
  useGetForexPaymentTrackingProjectsQuery,
  useGetForexPaymentTrackingVendorQuery,
  useViewPaymentTrackingHistoryQuery,
  useViewSupplierPaymentHistoryQuery,
  useViewSupplierCreditHistoryQuery,
  useUpdatePaymentOnPaymentTrackingMutation,
  useUpdateSupplierPaymentOnSupplierPaymentMutation,
  useAddSupplierCreditMutation,
  useUpdateSupplierCreditMutation,
  useAddPaySupplierCreditMutation,
  useGetBankListForGapAnalysisQuery,
  useGetGapAnalysisForBankQuery,
  useGetForexSummarizedPiQuery,
  useUpdateForexBankMutation,
  useGetCreditNotesQuery,
  useGetReceivableHistoryQuery,
  useUpdateCreditNoteMutation,

  //RMA API's
  useGetMissedItemsQuery,
  useGetMissedItemsDetailsQuery,
  useGetDeliveredItemsQuery,
  useGetDeliveredItemDetailQuery,
  useGetRmaQuery,
  useGetRmaDetailsQuery,
  useRmaExportPreviousDocumentMutation,
  useRmaExportSecurityClearanceMutation,
  useGetRmaMissedItemDocumentsQuery,
  useGetMissedItemImportDeclarationDocumentsQuery,
  useGetRmaMissedItemVendorListQuery,
  useGetRmaMissedItemFrightForwarderListQuery,
  useRmaShipmentArrangementMutation,
  useMissedItemShipmentArrangementMutation,
  useGetCiDocumentQuery,
  useGetPiDocumentQuery,
  useUpdateEtdMissedItemMutation,
  useUpdateEtdRmaMutation,
  useUpdateMissedItemStatusMutation,
  useUpdateRmaStatusMutation,
  useGetRmaStatusTrackingQuery,
  useGetMissedItemStatusTrackingQuery,

  //Faq
  useGetFaqVideosQuery,
  useGetFaqQuestionsQuery,
  useGetFaqAnswersQuery,
  useUploadFaqVideoMutation,
  useAnswerFaqMutation,
  useAskQuestionMutation,

  // batch
  useCreateBatchMutation,
  useGetCiQuery,
  useGetPlQuery,
  useGetConsigneeInformationQuery,
  useGetShipmentInformationQuery,
  useGetMandatoryDocumentsQuery,
  useGetTermsQuery,
  useCreateBatchGroupMutation,
  useReportRmaMutation,
  useGetBatchGroupQuery,
  useAttachDeliveryNoteMutation,
  useCreateMissedItemMutation,
  useEditETDMutation,
  useGetTermsAndConditionsQuery,
  useEditBatchStatusMutation,
  useUpdateTermsAndConditionsMutation,
  useGetBatchStatusHistoryQuery,
  useUpdateConsigneeInformationMutation,
  useGetBanksQuery,
  useUpdateMandatoryDocumentMutation,
  useUpdateBatchDeliveryMutation,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useDeactivateRoleMutation,
  useGetSupplierMappingQuery,

  //userAccesss
  useGetRolesQuery,
  useGetRoleDetailQuery,
  useGetPermissionsQuery,
  useGetProjectRolesQuery,
  useGetUsersQuery,
  useGetUserInfoQuery,
  useUpdateTermsMutation,
  useUpdateShipmentInformationMutation,
  useAssignShipmentMutation,
  useGetpaymentModeQuery,
  useGetpaymentIncotermQuery,
  useGetSettingShipmentModeQuery,
  useGetSettingShipmentDestinationsQuery,
  useGetProjectRoleQuery,
  useGetProjectRolePermissionQuery,
  useGetDeliveredItemQuery,
  useDeleteBoqMutation,
  useGetForexOptionQuery,
  useGetRequestLetterDocQuery,
  useGetUnderTakingDocQuery,
  useGetBankSubmissionDocQuery,
  useGetSwiftPermitDocQuery,
  //Approval
  useGetApproverQuery,
  useApprovalApproveMutation,
  useApprovalRejectMutation,
  useApprovalRetractMutation,
  useSendForApproverMutation,
  useSendForApproverPAMutation,
  useSendForApproverPRFMutation,
  useSendForApproverPCMutation,

  //Notification
  useGetNotificationQuery,
  useReadNotificationMutation,

  //stock setting store module
  useGetStoreQuery,
  useGetStatusQuery,
  useGetItemTypeQuery,
  useGetToolTypeQuery,
  useGetItemCategorysQuery,
  useAddStoreSettingMutation,
  useAddToStoreToolMutation,
  useAddToStoreAssetsMutation,
  useAddPoToStoreToolMutation,
  useAddPoToStoreAssetMutation,
  useAddToStoreToInventoryMutation,
  useAddPoToStoreToInventoryMutation,
  useAddStatusSettingMutation,
  useAddInventoryTypeSettingMutation,
  useAddToolTypeMutation,
  useAddItemCategoryMutation,
  useEditStoreSettingMutation,
  useEditStatusSettingMutation,
  useEditInventoryTypeMutation,
  useEditToolTypeMutation,
  useEditItemCategoryMutation,
  useDeleteStoreSettingMutation,
  useDeleteStatusSettingMutation,
  useDeleteInventoryTypeMutation,
  useDeleteToolTypeMutation,
  useDeleteItemCategoryMutation,
  useAddUnitOfMeasurementMutation,
  useEditUnitOfMeasurementMutation,
  useGetUnitOfMeasurementQuery,
  useGetAssetCatagoriesQuery,
  useAddAssetCatagoriesMutation,
  useEditAssetCatagoriesMutation,
  useDeleteAssetCatagoriesMutation,
  useDeleteUnitOfMeasurementMutation,
  useBatchRequestToolsMutation,
  useBatchRequestAssetsMutation,
  useBatchRequestInventoryMutation,
  useInventoryAcceptMutation,
  useInventoryDelineMutation,
  useToolsAcceptMutation,
  useAssetAcceptMutation,
  useAssetsAcceptMutation,
  useToolsDelineMutation,
  useAssetsDelineMutation,
  useInventoryUpdateMutation,
  useToolsUpdateMutation,
  useGetBatchToolRequestQuery,
  useGetBatchAssetRequestQuery,
  useGetBatchAssetsReturnQuery,
  useGetBatchInventoryRequestQuery,
  useGetInventoryDetailQuery,
  useGetBatchInventoryReturnQuery,
  useGetBatchToolReturnQuery,
  useGetIncomingItemsQuery,
  useGetIncomingToolsQuery,
  useGetIncomingAssetsQuery,
  //Stock purchased request API
  useGetPurchasedRequestQuery,
  useAddPurchasedRequestMutation,
  useGetToolsQuery,
  useGetGeneralToolsQuery,
  useGetApprovedItemsQuery,
  useGetRequestItemsQuery,
  useGetToolsRequestsQuery,
  useGetToolsTransactionQuery,
  useGetToolRequestedQuery,
  useGetAssetsRequestedQuery,
  useGetInventoryRequestedQuery,
  useGetInventoryTransactionQuery,
  useGetInventorysRequestsQuery,
  useGetInventorysBatchReturnsQuery,
  useGetInventorysBatchConsumQuery,
  useGetToolsBatchReturnsQuery,
  useGetAssetsRequestsQuery,
  useGetAssetsTransactionQuery,
  useGetAssetsRequestsBatchQuery,
  useGetInventoryQuery,
  useGetGeneralInventoryQuery,
  useGetFixedAssetsQuery,
  useGetGeneralFixedAssetsQuery,
  useGetFixedInventoryAssetsQuery,
  useToolRequestMutation,
  useAssetRequestMutation,
  useInventoryRequestMutation,
  useGetTransactionQuery,
  useDispatchedToolsMutation,
  useDispatchedItemsMutation,
  useReturnedToolsMutation,
  useTransferredToolsMutation,
  useTransferredItemsMutation,
  useTransferredAssetsMutation,
  useInStoreItemsRequestMutation,
  useItemsRequestMutation,
  useToolCancelMutation,
  useItemsCancelMutation,
  useAssetsCancelMutation,
  useAssetsReceiveMutation,
  useToolsReturnMutation,
  useItemsReturnBatchMutation,
  useAssetsReturnBatchMutation,
  useAssetsConsumeBatchMutation,
  useInventorysAcceptsBatchMutation,
  useToolsAcceptsBatchMutation,
  useToolsRejectBatchMutation,
  useInventoryRejectBatchMutation,
  useAssetsRejectBatchMutation,
  useAssetsAcceptsBatchMutation,
  useConsumeAproveBatchMutation,
  useInventoryAcceptsBatchMutation,
  useAddToolMutation,
  useDeletePurchasedRequestMutation,
  useItemsReturnMutation,
  useAssetReturnMutation,
  useItemsAcceptMutation,
  useItemsInventoryConfirmMutation,
  useItemsConsumeConfirmMutation,
  useItemsAssetsConfirmMutation,

  useItemsInventoryReceiveMutation,
  useItemsInventoryReturnMutation,
  useItemsInventoryConsumeMutation,
  useItemsInventoryAcceptMutation,
  useGetProjectStoreQuery,
  useGetProjectStoreItemsQuery,
  useGetProjectStoreItemsInventoryQuery,
  useGetProjectStoreAssetsQuery,
  useGetToolHistoryQuery,
  useGetInventoryHistoryQuery,
  useGetRecordToolsQuery,
  useGetInventoryDashboardQuery,
  useGetToolsDashboardQuery,
  useGetTopSuppliersQuery,
  // get all orders
  useGetAllOrdersQuery,
  useGetNoBoqQuery,
  useGetAllBatchesQuery,
  //price comparision
  usePriceComparisionMutation,
  usePriceComparisionAttachementMutation,
  useTraningPriceComparisionMutation,
  useFinalPriceComparisionMutation,
  useCreateOrderPriceComparisonMutation,
  useGetFinalPriceByProjectQuery,
  useGetPriceComparisionAttachementQuery,
  // guarantee Preparation
  useGetAllGuaranteeQuery,
  useGetAllClearedGuaranteeQuery,
  useGetAllExpiredGuaranteeQuery,
  useGetExpiredGuaranteeQuery,
  useGetSummaryGuaranteeQuery,
  useGetProjectGuaranteeQuery,
  useGetAllGuaranteeTypeQuery,
  useGetAllGuaranteeBalanceQuery,
  useCreateGuaranteeMutation,
  useClearGuaranteeMutation,
  useEditGuaranteeMutation,
  useCreateGuaranteeBalanceMutation,
  useEditGuaranteeBalanceMutation,
  //cost build upd

  useGetCostBuildUpBatchesQuery,
  useGetCostBuildUpIndexQuery,
  useGetCostBuildUpDetailQuery,
  useGetCostBuildUpDetailFinanceQuery,
  useCreateCostBuildUpMutation,
  useUpdateCostBuildUpMutation,
  useUpdateCostBuildUpStatusMutation,

  //approval
  useGetApprovalModuleQuery,
  useGetSingleApprovalModuleQuery,
  useUpdateApprovalModuleMutation,
  useCreateOrderServiceMutation,
} = SCMApi;
