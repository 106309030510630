import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { DropdownButton, Dropdown } from "react-bootstrap";

import {
  useDownloadRequestedGrnByGrnIdMutation,
  useGenerateGrnForOffshoreItemsByRequestIdMutation,
  useGenerateGrnForPrfMutation,
  useGetStoreQuery,
  useGetUserInfoQuery,
  useGetItemCategorysQuery,
} from "../../features/SCMApi";
import { Link, Navigate } from "react-router-dom";
import SuccessMessage from "../../components/common/SuccessMessage";
import AutoClosePopup from "./SuccessMessage/AutoClosePopup ";
import { Tooltip } from "@mui/material";
import RequestGrnModal from "./RequestGrnModal";
import ImageModal from "../../components/Sourcing/PriceComparison/ImageModal";
import { IMG_BASE_URL } from "../../api/endPoint";
function GRNSidebar({
  open,
  onclose,
  selectedItem,
  setSelectedItem,
  originalItems,
  setMessage,
  setIsOpen,
  setType,
}) {
  const { data: userinfo } = useGetUserInfoQuery();
  // const [userId,setUserId]=useState('');
  const [downloadRequestedGrnByGrnId] =
    useDownloadRequestedGrnByGrnIdMutation();
  const [generateGrnForPrf] = useGenerateGrnForPrfMutation();
  const [generateGrnForOffshoreItemsByRequestId] =
    useGenerateGrnForOffshoreItemsByRequestIdMutation();
  const [requestedGrn, setRequestedGrn] = useState([]);
  const [items, setItems] = useState(selectedItem || []);
  const [editQty, setEditQty] = useState([]);
  const [maxQtyValue, setMaxQtyValue] = useState(null);
  const [selectedStore, setSelectedStore] = useState([]);
  const [itemType, setItemType] = useState([]);
  const [modalImage, setModalImage] = useState(null);

  const [item_part_number, setItemPartNumber] = useState([]);
  const [isConsumeAble, setIsConsumeAble] = useState([]);
  const userId = userinfo?.id;
  const [warningMessage, setWarningMessage] = useState([]);
  const [viewListOfPrf, setViewListOfPrf] = useState(false);
  const [category, setCategory] = useState("");

  const { data: ItemCategory } = useGetItemCategorysQuery();

  // const handleDownload=()=>{

  //                       Navigate('/download/grn-pdf')
  // }
  const handleGenerateGRn = () => {
    const checkedItems = selectedItem.items?.filter(
      (item) => item.isChecked === true
    );
    const newArrayData = { ...selectedItem, items: checkedItems };
    //  const newData={...newArrayData,userId:userId};

    // setSelectedItem(newData);
    if (selectedItem?.is_onshore == 1) {
      generateGrnForPrf(newArrayData)
        .unwrap()
        .then((response) => {
          onclose();
          setIsOpen(true);
          setMessage(response?.message);
          setType("success");
        })
        .catch((error) => {
          setIsOpen(true);
          setMessage(error?.data?.message);
          setType("danger");
        });
    }
    if (selectedItem?.is_onshore == 0) {
      generateGrnForOffshoreItemsByRequestId(newArrayData)
        .unwrap()
        .then((response) => {
          onclose();
        })
        .catch((error) => {});
    }
    setViewListOfPrf(!viewListOfPrf);
  };
  const handleIsConsumable = (index) => {
    if (
      selectedItem &&
      selectedItem.items &&
      index >= 0 &&
      index < selectedItem.items.length
    ) {
      const updatedIsConsumeAble = [...isConsumeAble];
      updatedIsConsumeAble[index] =
        updatedIsConsumeAble[index] === undefined ||
        updatedIsConsumeAble[index] === false
          ? true
          : false;
      setIsConsumeAble(updatedIsConsumeAble); // to update the state data

      const updatedItems = [...selectedItem.items];
      updatedItems[index] = {
        ...updatedItems[index],
        isConsumeAble: updatedIsConsumeAble[index],
      };

      setSelectedItem({
        ...selectedItem,
        items: updatedItems,
      }); // to update the selected item
    }
  };

  const handleImageClick = (src) => {
    setModalImage(src);
  };

  const handleCloseModal = () => {
    setModalImage(null);
  };

  const handleStoreSelect = (option, index) => {
    if (
      selectedItem &&
      selectedItem.items &&
      index >= 0 &&
      index < selectedItem.items.length
    ) {
      const updatedStoreType = [...selectedStore];
      updatedStoreType[index] = option;
      setSelectedStore(updatedStoreType); // to update the state data

      const updatedItems = [...selectedItem.items];
      updatedItems[index] = {
        ...updatedItems[index],
        store: option,
      };
      setSelectedItem({
        ...selectedItem,
        items: updatedItems,
      }); // to update the selected item
    }
  };
  const handleItemSelect = (option, index) => {
    if (
      selectedItem &&
      selectedItem.items &&
      index >= 0 &&
      index < selectedItem.items.length
    ) {
      const updatedItemType = [...itemType];
      updatedItemType[index] = option;

      const updatedItems = [...selectedItem.items];
      updatedItems[index] = {
        ...updatedItems[index],
        ItemType: option,
      };

      setItemType(updatedItemType);
      setSelectedItem({
        ...selectedItem,
        items: updatedItems,
      });
    }
  };
  const handleQuantityChange = (subItem, value) => {
    if (selectedItem?.is_onshore == 1) {
      let newArray = [];
      const index = selectedItem?.items?.findIndex(
        (item) => item.item_part_number === subItem.item_part_number
      );
      const updatesItems = [...selectedItem.items];
      if (index !== -1) {
        const updatedItem = { ...updatesItems[index] };
        // if (action === 'increase') {
        updatedItem.qty = value;
        // } else {
        //     updatedItem.qty = (updatedItem.qty ?? 0) - 1;
        // }
        updatedItem.pi_total_cost = updatedItem.qty * updatedItem.pi_unit_cost;
        updatesItems[index] = updatedItem;
      }
      setSelectedItem({ ...selectedItem, items: updatesItems });
    } else {
      let newArray = [];
      const index = selectedItem?.items?.findIndex(
        (item) => item.item_part_number === subItem.item_part_number
      );
      const updatesItems = [...selectedItem.items];
      if (index !== -1) {
        const updatedItem = { ...updatesItems[index] };
        // if (action === 'increase') {
        updatedItem.delivered_qty = value;
        // } else {
        //   updatedItem.delivered_qty = (updatedItem.delivered_qty ?? 0) - 1;
        // }
        // updatedItem.total_price=(updatedItem.delivered_qty)*(updatedItem.unit_price);
        updatesItems[index] = updatedItem;
      }
      setSelectedItem({ ...selectedItem, items: updatesItems });
    }
  };
  const handleIsChecked = (index) => {
    // const updatedEditQty = [...editQty]; // Create a copy of the array
    // updatedEditQty[index] = !updatedEditQty[index]; // Toggle the value at the specified index
    // setEditQty(updatedEditQty); // Update the state with the modified array
    if (
      selectedItem &&
      selectedItem.items &&
      index >= 0 &&
      index < selectedItem.items.length
    ) {
      const updatedIsChecked = [...editQty];
      updatedIsChecked[index] = !updatedIsChecked[index];
      setEditQty(updatedIsChecked); // to update the state data

      const updatedItems = [...selectedItem.items];
      updatedItems[index] = {
        ...updatedItems[index],
        isChecked: updatedIsChecked[index],
        isConsumeAble: false,
        store: (
          store?.data?.find((store) => store?.store_name === "HQ Office") || {}
        )?.id,
        ItemType: "item",
      };
      setSelectedItem({
        ...selectedItem,
        items: updatedItems,
        userId: userId,
      }); // to update the selected item
    }
  };
  const { data: store } = useGetStoreQuery();

  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  return (
    <>
      <div
        id="myModal"
        className={`mini-modal ${open ? "show" : ""}`}
        style={{ display: open ? "block" : "none" }}
      >
        <div
          className="mini-modal-content"
          onClick={(e) => e.stopPropagation()}
          style={{ marginLeft: "45%", width: "55%" }}
        >
          <div className="create-project-modal-title">
            <div>Items in PRF</div>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setSelectedItem([]);
                setIsConsumeAble([]);
                setSelectedStore([]);
                setItemType([]);
                setEditQty([]);
                onclose();
              }}
            ></button>
          </div>
          <div className="modal-body task-order-name font-serif mb-5 rounded">
            <div
              className="p-2 rounded"
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "white",
              }}
            >
              <div className="d-flex align-items-center">
                <div className="info flex-grow-1 me-2">
                  {" "}
                  <h4>{"Purchased By: " + selectedItem.purchased_by}</h4>
                  <p>
                    {"Purpose of purchase: " +
                      selectedItem?.purpose_of_purchase}
                  </p>
                  <p>{"Supplier: " + selectedItem?.supplier}</p>
                  <p>{"For Project: " + selectedItem?.department_or_project}</p>
                </div>

                <div>
                  <img
                    src={selectedItem.receipt}
                    alt="Receipt Unavailable"
                    className="img-fluid rounded"
                    style={{
                      width: "120px",
                      height: "80px",
                      objectFit: "cover",
                    }}
                    onClick={() => handleImageClick(selectedItem.receipt)}
                  />
                </div>
                {modalImage && (
                  <ImageModal
                    src={modalImage}
                    alt="Receipt Unavailable"
                    onClose={handleCloseModal}
                  />
                )}
              </div>

              <div className="sidebar-body">
                {selectedItem?.optional_flag == 0 && (
                  <ul
                    className="list-group"
                    style={{ maxHeight: "600px", overflowY: "scroll" }}
                  >
                    {selectedItem?.items?.map((subItem, index) => (
                      <li
                        key={subItem.id}
                        className="list-group-item"
                        style={{ backgroundColor: "white" }}
                      >
                        <div className="row">
                          <div className="col-md-12">
                            <div className="d-flex align-items-center">
                              <input
                                type="checkbox"
                                checked={subItem?.isChecked || editQty[index]}
                                disabled={selectedItem?.optional_flag == 1}
                                onChange={() => handleIsChecked(index)}
                                className="me-2"
                              />
                              <label className="font-bold">Select Item</label>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-2">
                          <div className="col-md-2">
                            {" "}
                            <div className="d-grid">
                              <label className="font-bold">Quantity</label>
                              {editQty[index] &&
                              selectedItem?.optional_flag !== 1 ? (
                                <>
                                  <input
                                    type="number"
                                    value={
                                      selectedItem?.is_onshore == 1
                                        ? subItem.qty
                                        : subItem.delivered_qty
                                    }
                                    onChange={(e) => {
                                      const value = parseInt(
                                        e.target.value,
                                        10
                                      );
                                      if (!isNaN(value)) {
                                        if (
                                          (selectedItem?.is_onshore == 1
                                            ? value <= originalItems[index].qty
                                            : value <=
                                              originalItems[index]
                                                .delivered_qty) &&
                                          value >= 0
                                        ) {
                                          const updatedWarningMessages = [
                                            ...warningMessage,
                                          ];
                                          updatedWarningMessages[index] = "";
                                          setWarningMessage(
                                            updatedWarningMessages
                                          );
                                          handleQuantityChange(subItem, value);
                                        } else {
                                          const updatedWarningMessages = [
                                            ...warningMessage,
                                          ];
                                          updatedWarningMessages[index] =
                                            "can't insert above original or less than 0 qty";
                                          setWarningMessage(
                                            updatedWarningMessages
                                          );
                                        }
                                      }
                                    }}
                                    min="0"
                                    style={{ width: "50px" }}
                                  />
                                  {warningMessage[index] && (
                                    <span className="alert alert-warning p-1 italic fs-6">
                                      {warningMessage[index]}
                                    </span>
                                  )}
                                </>
                              ) : (
                                <span>
                                  {selectedItem?.is_onshore == 1
                                    ? subItem.qty
                                    : subItem?.delivered_qty}
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="d-grid">
                              <label className="font-bold">Item Name</label>
                              <input
                                type="text"
                                className="form-control"
                                value={subItem.item_part_number || ""}
                                onChange={(e) => {
                                  const updatedItems = [...selectedItem.items];
                                  updatedItems[index] = {
                                    ...updatedItems[index],
                                    item_part_number: e.target.value,
                                  };
                                  setSelectedItem({
                                    ...selectedItem,
                                    items: updatedItems,
                                  });
                                }}
                                disabled={!editQty[index]}
                              />
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="d-grid">
                              <label className="font-bold">
                                Item Description
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={subItem.item_description || ""}
                                onChange={(e) => {
                                  const updatedItems = [...selectedItem.items];
                                  updatedItems[index] = {
                                    ...updatedItems[index],
                                    item_description: e.target.value,
                                  };
                                  setSelectedItem({
                                    ...selectedItem,
                                    items: updatedItems,
                                  });
                                }}
                                disabled={!editQty[index]}
                              />
                            </div>
                          </div>

                          <div className="col-md-2">
                            {" "}
                            <div className="d-grid">
                              <label className="font-bold">Item Category</label>
                              <select
                                type="search"
                                className="inventory-search-input"
                                onChange={(e) => setCategory(e.target.value)}
                              >
                                <option value="" selected disabled>
                                  Select Item Category
                                </option>
                                {ItemCategory?.data?.map((items) => (
                                  <option value={items?.id}>
                                    {items?.category}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-2">
                          <div className="col-md-4">
                            <div className="d-grid">
                              <label className="font-bold">Item Type</label>
                              <select
                                className="form-control"
                                onChange={(e) =>
                                  handleItemSelect(e.target.value, index)
                                }
                              >
                                <option selected disabled>
                                  Select Item Type
                                </option>
                                <option value="item">Item</option>
                                <option value="tool">Tool</option>
                                <option value="asset">Asset</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="d-grid">
                              <label className="font-bold">Store</label>
                              <select
                                className="form-control"
                                onChange={(e) =>
                                  handleStoreSelect(e.target.value, index)
                                }
                              >
                                <option value="">Select Store</option>
                                {store?.data
                                  ?.filter((i) => i?.is_permanent == 1)
                                  ?.map((items) => (
                                    <option value={items?.id}>
                                      {items?.store_name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="d-flex align-items-center">
                              <input
                                type="checkbox"
                                checked={
                                  subItem?.isConsumeAble || isConsumeAble[index]
                                }
                                disabled={selectedItem?.optional_flag == 1}
                                onChange={() => handleIsConsumable(index)}
                                className="me-2"
                              />
                              <label className="font-bold">Is Consumable</label>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}

                <div className="d-flex justify-content-center mt-3">
                  {selectedItem?.optional_flag == 0 ? (
                    <button
                      type="button"
                      className="btn btn-success py-2 px-4"
                      onClick={() => setViewListOfPrf(!viewListOfPrf)}
                    >
                      Generate GRN
                    </button>
                  ) : (
                    <Link to={`/download/grn-pdf/${selectedItem?.id}`}>
                      <button
                        type="button"
                        className="btn btn-primary py-2 px-4"
                        onClick={handleGenerateGRn}
                      >
                        Download GRN
                      </button>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {viewListOfPrf && (
        <RequestGrnModal
          generateGrnConfirmation={true}
          modal={setViewListOfPrf}
          confirmGenerate={() => handleGenerateGRn()}
        />
      )}
    </>
  );
}

export default GRNSidebar;
