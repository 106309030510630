import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../api/endPoint";
import axios from "axios";
import { useGetSuppliersOrdersQuery } from "../../features/SCMApi";
import Select from "react-select";

export default function SupplierOnShoreFilter({
  setSearchSupplierCategory,
  handleSearchSubmit,
  searchSupplierCategory,
  searchSupplier,
  setSearchSupplier,
}) {
  const [supplierCategoryData, setSupplierCategoryData] = useState([]);
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/scm/supplier/supplier_category`)
      .then((response) => setSupplierCategoryData(response?.data?.data))
      .catch((error) => {});
  }, []);

  const suppliersList = [];
  const { data: Suppliers } = useGetSuppliersOrdersQuery();
  Suppliers?.data?.map((items) =>
    suppliersList.push({
      value: items?.id,
      label: items?.distributor_name,
    })
  );

  return (
    <div>
      <form className="payable-selector" onSubmit={handleSearchSubmit}>
        <div style={{ gridTemplateColumns: "100px 282px" }}>
          <label className="create-bach-input-label" htmlFor="origin">
            Supplier Category
          </label>
          <select
            className="custom-select prg"
            name="category"
            id="category"
            placeholder="Category"
            value={searchSupplierCategory}
            onChange={(e) => setSearchSupplierCategory(e.target.value)}
          >
            <option value="">Select category</option>
            {supplierCategoryData?.map((items) => (
              <option value={items?.name}>{items?.name}</option>
            ))}
          </select>
        </div>
        <div className="d-flex gap-2">
          <label className="create-bach-input-label" htmlFor="origin">
            Supplier
          </label>
          <select
            className="custom-select prg"
            name="supplier"
            id="supplier"
            placeholder="supplier"
            value={searchSupplier}
            onChange={(e) => setSearchSupplier(e.target.value)}
          >
            <option value="">Select supplier</option>
            {suppliersList.map((item) => (
              <option key={item.value} value={item.label}>
                {item.label}
              </option>
            ))}
          </select>
          {/* <Select
            options={suppliersList}
            isSearchable={true}
            value={searchSupplier}
            onChange={(e) => setSearchSupplier(e.target.value)}
          /> */}
        </div>
      </form>
    </div>
  );
}
