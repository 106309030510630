import React from "react";
import { useState } from "react";
import AddBoq from "../../components/products/AddBoq";
import DetailedBoq from "../../components/products/DetailedBoq";
import "./boq.style.css";
import Productsidebar from "../../pages/products/Index";
import { useGetBoqQuery, useGetUserInfoQuery } from "../../features/SCMApi";
import { API_BASE_URL } from "../../api/endPoint";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import AddIcon from "@mui/icons-material/Add";
import CheckPermission from "../../components/common/checkPermission/checkPermission";
import usePagination from "../../components/Pagination/Pagination";
import { Pagination } from "@mui/material";

export default function Boq() {
  const [addBoqModalToggle, setAddBoqModal] = useState(false);
  const [detailedBoqModalToggle, setDetailedBoqModal] = useState(false);
  function HandleAddBoqModal() {
    setDetailedBoqModal(false);
    setAddBoqModal(true);
   
  }
  function HandleDetailedBoqModal() {
    setAddBoqModal(false);
    setDetailedBoqModal(true);
  }
  let listOfSolution = 0;
  const { data: boq } = useGetBoqQuery();
  
  const countSolution = (items) => {
    const listOfSolution = [];
    items?.product_boq?.map((list) => {
      const condition = listOfSolution.includes(list?.solution_id);

      // ? null
      condition ? <></> : listOfSolution.push(list?.solution_id);
    });
    return listOfSolution.length;
  };
  const { data: userinfo } = useGetUserInfoQuery();
  let [page, setPage] = React.useState(1);
  const PER_PAGE = 6;
  const count = Math.ceil(boq?.data?.length / PER_PAGE);
  const _DATA = usePagination(boq?.data, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <Productsidebar name={"BoQ"}>
      <div className="col-md-10">
        <div>
          {CheckPermission("add_boq", userinfo) ? (
            <div
              className="create-project-btn mt-4"
              onClick={HandleAddBoqModal}
            >
              <AddIcon />
              Add new
            </div>
          ) : (
            ""
          )}
          <div className="document-card-container">
            {_DATA?.currentData()?.map((items) => (
              <div className="document-card">
                <div className="card-content">
                  <div className="document-title">
                    <div className="name"> {items?.project_name}</div>
                  </div>
                  <div className="detail">
                    <div className="left-detail">
                      <div className="number">{items?.product_boq?.length}</div>
                      <div className="type">Bill of Quantities</div>
                    </div>
                    <div className="right-detail">
                      <div className="number">{countSolution(items)}</div>
                      <div className="type"></div>
                    </div>
                  </div>
                </div>
                <div className="divider"></div>
                <a
                  className="download-boq"
                  href={
                    API_BASE_URL + `/scm/product/scm-boq/download/${items?.id}`
                  }
                  target="_blank"
                >
                  {" "}
                  Download BoQ
                  <FileDownloadIcon sx={{ fontSize: "15px" }} />
                </a>
              </div>
            ))}
          </div>
          <div className="faq-pagination-container">
            {count > 1 ? (
              <Pagination
                count={count}
                size="large"
                page={page}
                variant="outlined"
                shape="rounded"
                onChange={handleChange}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  margin: "5px",
                }}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      {addBoqModalToggle ? <AddBoq ToggleModal={setAddBoqModal} /> : <></>}
      {detailedBoqModalToggle ? <DetailedBoq /> : <></>}
    </Productsidebar>
  );
}
