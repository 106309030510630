import React from "react";
import NavBar from "../../Layout/NavBar";
import notfoundimg from "../../assets/images/BoQerrorpageillust-01 1.png";
export default function Notfound() {
  const token = localStorage.getItem("accessToken");

  return (
    <>
      {token ? <NavBar /> : <></>}
      <div className="page">
        <img className="not-found" src={notfoundimg}></img>
      </div>
    </>
  );
}
