import React from "react";
import "./orders.style.css";
import NavBar from "../../Layout/NavBar";
import SourcingBatch from "../../components/Sourcing/SourcingBatch";

export default function Batch() {
  return (
    <>
      <NavBar />
      <div className="page">
        <SourcingBatch />
      </div>
    </>
  );
}
