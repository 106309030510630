import React from "react";
import PrfContainer from "./PRF/PrfContainer";
import PrfApproval from "./PRF/PrfApproval";
import PrfDataContainer from "./PRF/PrfDataContainer";

const PRFCheckComponent = ({ prf_id }) => {
  return (
    <div className="d-flex gap-3">
      <PrfContainer prf_id={prf_id} />
      <div className="" style={{ width: "40%" }}>
        <PrfApproval prf_id={prf_id} />
        <PrfDataContainer prf_id={prf_id} />
      </div>
    </div>
  );
};

export default PRFCheckComponent;
