import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import {
 
  useUpdateOrderSourceMutation
} from "../../../features/SCMApi";
import Selector from "../../Payment/ForexApproval/Select";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function IsOnShoreModal({
  show = false,
  handleUpdateEtdModal,
  orderId
}) {
  const [updateOrderSource] = useUpdateOrderSourceMutation();
  const [orderSource, setOrderSource] = React.useState(null);

  const params = {
    id: orderId,
    is_offshore: orderSource == "off-shore" ? true : false,
  };

  const orderSources = ["off-shore", "on-shore"];
  const HandleSubmit = async (e) => {
    e.preventDefault();

    updateOrderSource(params)
      .unwrap()
      .then((response) => {
        handleUpdateEtdModal(false);

      })
      .catch(function (err) {

      });
  };
  return (
    <BootstrapDialog
      onClose={handleUpdateEtdModal}
      aria-labelledby="customized-dialog-title"
      open={show}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={handleUpdateEtdModal}
        sx={{ width: 400, color: "#575757" }}
      >
        Select order source
      </BootstrapDialogTitle>
      <form onSubmit={HandleSubmit} style={{ height: 150 }}>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "-15px",
                }}
              >
                <Selector
                  name={orderSources}
                  onChange={setOrderSource}
                  required={true}
                  placeHolder={"Select source"}
                />
              </div>
            </Grid>
          </Grid>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "inherent",
              marginTop: "30px",
            }}
          >
            <Button
              variant="contained"
              type="reset"
              sx={{
                backgroundColor: "#fff",
                color: "#575757",
                borderRadius: "25px",
                "&:hover": {
                  backgroundColor: "#ececec",
                  color: "#575757",
                },
                width: "112px",
                textTransform: "none",
              }}
              onClick={handleUpdateEtdModal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={HandleSubmit}
              sx={{
                borderRadius: "25px",
                width: "112px",
                backgroundColor: "#064b4f",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#186569",
                  color: "#fff",
                },
                textTransform: "none",
              }}
            >
              Update
            </Button>
          </DialogActions>
        </DialogContent>
      </form>
    </BootstrapDialog>
  );
}
