import React from "react";
import { useState } from "react";
import WestIcon from "@mui/icons-material/West";
import {
  useGetProjectStoreItemsInventoryQuery,
  useGetProjectStoreItemsQuery,
  useGetUserInfoQuery,
  useToolRequestMutation,
  useInventoryRequestMutation,
  useGetProjectQuery,
  useGetStoreQuery,
  useGetItemCategorysQuery,
  useGetProjectStoreAssetsQuery,
} from "../../../features/SCMApi";
import { IMG_BASE_URL } from "../../../api/endPoint";
import NoRecord from "../../common/NoRecord";
import StockDialog from "../../common/StockDialog";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { IconButton, Pagination, Tooltip } from "@mui/material";
import ConfirmRequestInventory from "../StockModal/ConfirmRequestInventory";
import EditInventory from "../StockModal/EditInventory";
import EditTools from "../StockModal/EditTools";
import ConfirmRequest from "../StockModal/ConfirmRequest";
import usePagination from "../../Pagination/Pagination";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import InventoryReport from "../DownloadReports/InventoryReport";
import TransferredStore from "../StockModal/TransferedStore";
import TransformIcon from "@mui/icons-material/Transform";
import TransferedStoreItems from "../StockModal/TransferedStoreItems";
import TransferredAssets from "../StockModal/TransferedAssets";
export default function ProjectDetail(props) {
  const [requestedProject, setRequestedProject] = useState(
    props?.data?.project_id
  );

  
  const [search, setSearch] = useState("");
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  
  const HandleFragment = (e, tool) => {
    props?.setFragment("projectdetail");
    props?.setProjectData(tool);
  };
  function HandleBack() {
    props?.setFragment("index");
  }
  const [Items, setItems] = useState("Tool");
  function onChangeValue(event) {
    setItems(event.target.value);
  }

  const { data: projectItems } = useGetProjectStoreItemsQuery(
    props?.data?.project_id
  );
  const { data: projectItemsInventory } = useGetProjectStoreItemsInventoryQuery(
    props?.data?.project_id
  );
  const { data: projectAssets } = useGetProjectStoreAssetsQuery(
    props?.data?.project_id
  );

  
  const { data: projectdata } = useGetProjectQuery(props?.data?.project_id);
  const [dialog, setDialog] = useState(false);
  const [confirm, setConfirm] = useState(false);
  function HandleRequest() {
    setConfirm(true);
  }
  const [toolRequest, res] = useToolRequestMutation();
  const [inventoryRequest, src] = useInventoryRequestMutation();
  const [quantity, setQuantity] = useState(null);
  function HandleToolRequest(e, id) {
    let requested;
    Items == "Tool"
      ? (requested = {
          id: id,
          requester_id: userinfo?.id,
          requested_project: requestedProject,
          requested_quantity: quantity,
        })
      : (requested = {
          id: id,
          requester_id: userinfo?.id,
          requested_project: requestedProject,
          quantity: quantity,
        });

    Items == "Tool"
      ? toolRequest(requested)
          .unwrap()
          .then((response) => {
            setConfirm(false);
            
            props?.setMessages("Tool Requested successfully");
          })
          .then((error) => {
            
          })
      : inventoryRequest(requested)
          .unwrap()
          .then((response) => {
            setConfirm(false);
            
            props?.setMessages("Tool Requested successfully");
          })
          .then((error) => {
            
          });

    // axios
    //   .post(`${API_BASE_URL}/scm/stock/tools/request/${id}`, {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   })
    //   .then(function (response) {
   
    //   })
    //   .catch(function (error) {
    
    //   });
  }
  let ProjectRequest = [];
  if (Items === "Tool") {
    projectItems?.data?.map((items) =>
      items?.is_requested == 0 ||
      (items?.is_requested == 1 && items?.amount != 0)
        ? ProjectRequest.push(items)
        : ""
    );
  } else if (Items === "Inventory") {
    const filteredItems = projectItemsInventory?.data?.filter(
      (items) => items?.transfer_requested == 0
    );
    filteredItems?.map((items) =>
      items?.is_requested == 0 ||
      (items?.is_requested == 1 && items?.amount != 0)
        ? ProjectRequest.push(items)
        : ""
    );
  } else if (Items === "Assets") {
    projectAssets?.data?.map((items) =>
      items?.is_requested == 0 ||
      (items?.is_requested == 1 && items?.amount != 0)
        ? ProjectRequest.push(items)
        : ""
    );
  }
  const { data: userinfo } = useGetUserInfoQuery();


  let SK = userinfo?.role?.role_name == "Store Keeper";
  let FE = userinfo?.role?.role_name == "Field Engineer";
  let PM = userinfo?.role?.role_name == "Project Manager";


  let create_by = userinfo?.id;
  const [updateInventory, setupdateInventory] = useState(null);
  const [inventory, setInventory] = useState(false);
  const [tool, setTool] = useState(false);
  const [updateTool, setupdateTool] = useState(null);
  const HandleEditTool = () => {
    setTool(true);
  };
  const HandleEditInventory = (e) => {
    setInventory(true);
  };

  const [confirmRequest, setConfirmRequest] = useState(false);
  const [confirmRequestTool, setConfirmRequestTool] = useState(false);
  const [transferTool, setTransferTool] = useState(false);
  const [transferAssets, setTransferAssets] = useState(false);
  const [transferItems, setTransferItems] = useState(false);
  const [confirmRequestItems, setConfirmRequestItems] = useState([]);
  function HandleConfirmRequest() {
    setConfirmRequest(true);
  }
  function HandleConfirmRequestTool() {
    setConfirmRequestTool(true);
  }
  function HandleTransferTool() {
    setTransferTool(true);
  }
  function HandleTransferAssets() {
    setTransferAssets(true);
  }
  function HandleTransferItem() {
    setTransferItems(true);
  }
  function handleBatchRequest(e, items, i) {
    e.preventDefault();
    const value = e.target.value;
    const tools = [...confirmRequestItems];
    tools[i] = { ...items, requested_amount: value };
    //  setAmount(tools);
    setConfirmRequestItems(tools);
  }
  let data;
  data = confirmRequestItems.filter(function (element) {
    return element !== undefined;
  });


  const [store_id, setStore_id] = useState("");
  const [category, setCategory] = useState("");
  const { data: store } = useGetStoreQuery();
  const { data: ItemCategory } = useGetItemCategorysQuery();

  const filteredData = (category, search, store_id) => {
    return ProjectRequest?.filter((items) => {
      if (category === "") {
        return items;
      } else if (items?.category_id == category) {
        return items;
      }
    })
      ?.filter((items) => {
        if (search === "") {
          return items;
        } else if (
          items.item_name?.toLowerCase().includes(search.toLowerCase())
        ) {
          return items;
        }
      })
      ?.filter((items) => {
        if (store_id === "") {
          return items;
        } else if (items?.store?.id == store_id) {
          return items;
        }
      })
      .map((items) => items);
  };

  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(
    filteredData(category, search, store_id)?.length / PER_PAGE
  );
  const _DATA = usePagination(
    filteredData(category, search, store_id),
    PER_PAGE
  );
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  let users = projectdata?.data?.users?.find(
    (user) => user?.id == userinfo?.id
  );

  const filterdStore = store?.data?.filter(
    (storeData) => storeData?.project?.id == props?.data?.project_id
  );


  return (
    <div>
      <div className="tool-header-container ">
        <div className="create-project-btn" onClick={HandleBack}>
          <WestIcon
            sx={{
              "&:hover": {
                backgroundColor: "#fff",
                color: "#186569",
              },
            }}
          />
          Go Back
        </div>
        <InventoryReport
          data={filteredData(search, search, category, store_id)}
          type={Items == "Tool" ? "Tool Lists" : "Inventory Lists"}
          is_tool={Items == "Tool" ? true : false}
        />

        <Tooltip title="Batch Request">
          <Inventory2Icon
            sx={{ color: "#064b4f" }}
            onClick={
              Items == "Tool" ? HandleConfirmRequestTool : HandleConfirmRequest
            }
          />
        </Tooltip>
        <Tooltip title="Transfer Store to Store">
          <TransformIcon
            sx={{ color: "#064b4f" }}
            onClick={
              Items == "Tool"
                ? HandleTransferTool
                : Items == "Assets"
                  ? HandleTransferAssets
                  : HandleTransferItem
            }
          />
        </Tooltip>
        <div className="d-flex gap-3 " onChange={onChangeValue}>
          <input
            checked={Items === "Tool"}
            id="default-radio-1"
            type="radio"
            value="Tool"
            name="default-radio"
            className="cursor-pointer"
          />
          <label for="default-radio-1" className="prg">
            Tools
          </label>

          <input
            checked={Items === "Inventory"}
            id="default-radio-2"
            type="radio"
            value="Inventory"
            name="default-radio"
            className="cursor-pointer"
          />
          <label for="default-radio-2" className="prg">
            Items
          </label>
          <input
            checked={Items === "Assets"}
            id="default-radio-2"
            type="radio"
            value="Assets"
            name="default-radio"
            className="cursor-pointer"
          />
          <label for="default-radio-2" className="prg">
            Assets
          </label>
        </div>
        <input
          onChange={handleSearchChange}
          placeholder="search"
          type="search"
          className="inventory-search-input"
        />
        <select
          type="search"
          className="inventory-search-input"
          onChange={(e) => setCategory(e.target.value)}
        >
          <option value="">Item Group</option>
          {ItemCategory?.data?.map((items) => (
            <option value={items?.id}>{items?.category}</option>
          ))}
        </select>
        <select
          type="search"
          className="inventory-search-input"
          onChange={(e) => setStore_id(e.target.value)}
        >
          <option value="">Store</option>
          {filterdStore?.map((items) => (
            <option value={items?.id}>{items?.store_name}</option>
          ))}
        </select>
        <select
          type="search"
          className="inventory-search-input"
          onChange={(e) => setCategory(e.target.value)}
        >
          <option value="">Item Group</option>
          {ItemCategory?.data?.map((items) => (
            <option value={items?.id}>{items?.category}</option>
          ))}
        </select>
        <div className="h5-pri ">{props?.data?.project?.project_name}</div>
      </div>
      {_DATA?.currentData()?.length !== 0 ? (
        <>
          {" "}
          <table>
            <thead className="stock-table-header">
              <tr>
                <th style={{ width: "5%" }}></th>
                <th style={{ width: "17%" }}>Item Name</th>
                <th style={{ width: "14%" }}>Model</th>
                <th style={{ width: "10%" }}>Type</th>
                <th style={{ width: "15%" }}>Store</th>
                <th style={{ width: "20%" }}>Quantity</th>
                <th style={{ width: "11%" }}>Status</th>
                <th style={{ width: "20%" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {_DATA?.currentData()?.map((tool, index) => (
                <tr className="stock-table-row">
                  <td
                    className=""
                    onClick={(e) => {
                      HandleFragment(e, tool);
                      setupdateTool(null);
                    }}
                  >
                    <img
                      className="stock-img"
                      src={`${IMG_BASE_URL}${tool?.image}`}
                      alt=""
                    />
                  </td>
                  <td
                    className="text-start"
                    onClick={(e) => {
                      HandleFragment(e, tool);
                    }}
                  >
                    {tool?.item_name}
                  </td>
                  <td
                    className="text-start"
                    onClick={(e) => {
                      HandleFragment(e, tool);
                    }}
                  >
                    {tool?.model === null ? "-" : tool?.model}
                  </td>
                  {Items == "Tool" ? (
                    <td
                      className="text-start"
                      onClick={(e) => {
                        HandleFragment(e, tool);
                      }}
                    >
                      {tool?.tool_type_id === null
                        ? "-"
                        : tool?.tool_type?.tool_type}
                    </td>
                  ) : Items == "Inventory" ? (
                    <td
                      className="text-start"
                      onClick={(e) => {
                        HandleFragment(e, tool);
                      }}
                    >
                      {tool?.type?.inventory_type}
                    </td>
                  ) : Items == "Assets" ? (
                    <td
                      className="text-start"
                      onClick={(e) => {
                        HandleFragment(e, tool);
                      }}
                    >
                      {tool?.aseet_category?.name}
                    </td>
                  ) : (
                    <></>
                  )}
                  <td
                    className="text-start"
                    onClick={(e) => {
                      HandleFragment(e, tool);
                    }}
                  >
                    {" "}
                    {tool?.store_id === null ? "-" : tool?.store?.store_name}
                  </td>
                  <td
                    className="text-start"
                    onClick={(e) => {
                      HandleFragment(e, tool);
                    }}
                  >
                    {/* type */}
                    {`${tool?.amount === null ? "-" : tool?.amount} ${
                      tool?.uom_id == null ? "-" : tool?.uom?.name
                    }`}
                  </td>
                  <td
                    className="text-start"
                    onClick={(e) => {
                      HandleFragment(e, tool);
                    }}
                  >
                    {tool?.status_id == null ? "-" : tool?.status?.status_name}
                  </td>
                 
                  {Items == "Tool" ? (
                    <td className="text-start">
                      {true ? (
                        projectdata?.data?.users?.find(
                          (user) => user?.id == userinfo?.id
                        ) != undefined ? (
                          <div className="d-grid gap-3">
                            <button
                              className="form-save"
                              onClick={() => {
                                HandleRequest();
                                setDialog(tool);
                              }}
                            >
                              Request
                            </button>
                          </div>
                        ) : (
                          ""
                        )
                      ) : tool?.store?.is_permanent == 1 ? (
                        <div className="d-grid gap-3">
                          <button
                            className="form-save"
                            onClick={() => {
                              HandleRequest();
                              setDialog(tool);
                            }}
                          >
                            Request
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                      {PM ? (
                        tool?.is_group_item == 1 ? (
                          ProjectRequest[0]?.project?.project_participants?.find(
                            (user) => user?.id == userinfo?.id
                          ) != undefined ? (
                            <div className="d-grid gap-3">
                              <button
                                className="form-save"
                                onClick={() => {
                                  HandleRequest();
                                  setDialog(tool);
                                }}
                              >
                                Request
                              </button>
                            </div>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )
                      ) : SK ? (
                        <div className="d-flex justify-content-center gap-2 align-items-center">
                          <ModeEditOutlineOutlinedIcon
                            onClick={() => {
                              HandleEditTool(true);
                              setupdateTool(tool);
                            }}
                            sx={{
                              color: "#52FFAC",
                              "&:hover": {
                                backgroundColor: "#fff",
                                color: "#52FFAC",
                                border: "1px solid #52FFAC",
                                borderRadius: 25,
                              },
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </td>
                  ) : (
                    <td className="text-start">
                      {FE ? (
                        ProjectRequest[0]?.project?.project_participants?.find(
                          (user) => user?.id == userinfo?.id
                        ) != undefined ? (
                          <div className="d-grid gap-3">
                            <button
                              className="form-save"
                              onClick={() => {
                                HandleRequest();
                                setDialog(tool);
                              }}
                            >
                              Request
                            </button>
                          </div>
                        ) : (
                          ""
                        )
                      ) : tool?.store?.is_permanent == 1 ? (
                        <div className="d-grid gap-3">
                          <button
                            className="form-save"
                            onClick={() => {
                              HandleRequest();
                              setDialog(tool);
                            }}
                          >
                            Request
                          </button>
                        </div>
                      ) : (
                        ""
                      )}

                      {PM ? (
                        tool?.is_group == 1 ? (
                          ProjectRequest[0]?.project?.project_participants?.find(
                            (user) => user?.id == userinfo?.id
                          ) != undefined ? (
                            <div className="d-grid gap-3">
                              <button
                                className="form-save"
                                onClick={() => {
                                  HandleRequest();
                                  setDialog(tool);
                                }}
                              >
                                Request
                              </button>
                            </div>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )
                      ) : SK ? (
                        <div className="d-flex justify-content-center gap-2 align-items-center">
                          <ModeEditOutlineOutlinedIcon
                            onClick={(e) => {
                              HandleEditInventory(e);
                              setupdateInventory(tool);
                            }}
                            sx={{
                              color: "#52FFAC",
                              "&:hover": {
                                backgroundColor: "#fff",
                                color: "#52FFAC",
                                border: "1px solid #52FFAC",
                                borderRadius: 25,
                              },
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          {data?.length >= 2 ? (
            Items != "Tool" ? (
              <div className="inventory-header-container">
                <button className="form-save " onClick={HandleConfirmRequest}>
                  Batch Request
                </button>
              </div>
            ) : (
              <div className="inventory-header-container">
                <button
                  className="form-save "
                  onClick={HandleConfirmRequestTool}
                >
                  Batch Request
                </button>
              </div>
            )
          ) : (
            ""
          )}
        </>
      ) : (
        <NoRecord />
      )}
      {confirm ? (
        <StockDialog
          projectId={setRequestedProject}
          setDialog={setConfirm}
          dialog={`${dialog?.item_name} `}
          data={dialog}
          setQuantity={setQuantity}
          action={(e) => HandleToolRequest(e, dialog?.id)}
        />
      ) : (
        ""
      )}
      {confirmRequest ? (
        <ConfirmRequestInventory data={data} modal={setConfirmRequest} />
      ) : confirmRequestTool ? (
        <ConfirmRequest data={data} modal={setConfirmRequestTool} />
      ) : (
        ""
      )}
      {inventory ? (
        <EditInventory data={updateInventory} modal={setInventory} />
      ) : tool ? (
        <EditTools data={updateTool} modal={setTool} />
      ) : (
        ""
      )}
      {transferTool ? (
        <TransferredStore
          data={data}
          modal={setTransferTool}
          projectID={props?.data?.project_id}
        />
      ) : (
        ""
      )}
      {transferAssets ? (
        <TransferredAssets
          data={data}
          modal={setTransferAssets}
          projectID={props?.data?.project_id}
        />
      ) : (
        ""
      )}
      {transferItems ? (
        <TransferedStoreItems
          item={true}
          data={data}
          modal={setTransferItems}
          projectID={props?.data?.project_id}
        />
      ) : (
        ""
      )}
      {count > 1 ? (
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
          className="p-2"
        />
      ) : (
        ""
      )}
    </div>
  );
}
