import React, { useState } from "react";
import { useAddClientMutaion } from "../../../../features/SCMApi";
import axios from "axios";
import { API_BASE_URL } from "../../../../api/endPoint";
import "../../logistics.css";
import Button from "@mui/material/Button";
import attachmentIcon from "../../../../assets/SVG/attach.svg";
import { DOC_BASE_URL } from "../../../../api/endPoint";
import { useGetRmaMissedItemDocumentsQuery } from "../../../../features/SCMApi";

export default function CustomsClearance(props) {
  // const [addClient, response] = useAddClientMutaion();
  const { data: customDocumentData } = useGetRmaMissedItemDocumentsQuery();

  const [warehouseFee, setWarehouseFee] = useState(null);
  const [freightTax, setFreightTax] = useState(null);

  const documentData =
    props?.previousData !== undefined ? props?.previousData : [];

  let params = {
    batch_id: props?.batchId,
    missed_item_id: props.missedItemId,
    freight_tax: freightTax,
    warehouse_fee: warehouseFee,
  };
  function HandleCancel() {
    props.modal(false);
  }
  const handleWarehouseFee = (e) => {
    setWarehouseFee(e?.target?.files[0]);
  };
  const handleFreightTax = (e) => {
    setFreightTax(e?.target?.files[0]);
  };



  const HandleSubmit = async (e) => {
    e.preventDefault();
    documentData?.length === 0
      ? axios
          .post(`${API_BASE_URL}/scm/missed-item/customs-clearance`, params, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            props?.successMessage(true);
            props?.message(
              "Customs clearance document attached successfully!!!"
            );
            
            HandleCancel();
          })
          .then((error) => {
           
          })
      : axios
          .post(
            `${API_BASE_URL}/scm/missed-item/customs-clearance/${documentData[0]?.id}`,
            params,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            props?.successMessage(true);
            props?.message("Custom clearance updated successfully!!!");
           
            HandleCancel();
          })
          .then((error) => {
           
          });
  };

  const [pdFile, setpdFile] = useState();

  function handleChange(e) {
    setpdFile(e?.target?.files?.[0]);
  }

  return (
    <>
      <div id="myModal" className="modal" onClick={HandleCancel}>
        <div
          className="mini-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="logisticsRma-create-project-modal-title">
            <div className="previousDocumnetTitle">Customs Clearance</div>
            <div onClick={HandleCancel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="42"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>

          <div className="rmaDownloadBox">
            <span className="rmaDownloadLabel">Import Declaration</span>
            <a
              href={`${DOC_BASE_URL}scm/document/download?file_path=${customDocumentData?.data[0]?.import_declaration}`}
            >
              <Button sx={{}}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="#186569"
                  class="bi bi-download"
                  viewBox="0 0 16 16"
                >
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                </svg>
              </Button>
            </a>
          </div>

          <div className="rmaFileAttachLabel">Attach Freight Tax</div>
          <div className="rmaFileAttachLabelDivider" />

          <form
            className="form-container rmaMissedItemForm"
            onSubmit={HandleSubmit}
          >
            <div className="form-card-container">
              <div className="form-card-information">
                {documentData?.length !== 0 ? (
                  <>
                    <p
                      className="forex-attached-file-warning"
                      style={{ marginTop: "-10px", marginBottom: "20px" }}
                    >
                      Custom clearance document already attached !!!
                    </p>
                  </>
                ) : (
                  <></>
                )}
                <div className="card-center">
                  <div className="card-form">
                    <div className="input-labels inputLabelsHolder">
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "10px" }}
                      >
                        Freight Tax
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "10px" }}
                      >
                        Warehouse Fee
                      </div>
                    </div>
                    <div className="input-fields">
                      <div
                        className="file-chooser rmaMissedItemFile-choosers"
                        style={{
                          backgroundImage: `url(${attachmentIcon})`,
                        }}
                      >
                        <input
                          required={documentData?.length === 0 ? true : false}
                          type="file"
                          className="file-input"
                          name="boq"
                          onChange={(e) => handleWarehouseFee(e)}
                        />
                        <div className="rmaFileUploader">
                          {warehouseFee
                            ? warehouseFee?.name.slice(0, 10) + "...."
                            : documentData?.length !== 0
                            ? "File exist"
                            : " File"}
                        </div>
                      </div>
                      <div
                        className="file-chooser rmaMissedItemFile-choosers"
                        style={{
                          backgroundImage: `url(${attachmentIcon})`,
                        }}
                      >
                        <input
                          required={documentData?.length === 0 ? true : false}
                          type="file"
                          className="file-input"
                          name="boq"
                          onChange={(e) => handleFreightTax(e)}
                        />
                        <div className="rmaFileUploader">
                          {freightTax
                            ? freightTax?.name.slice(0, 10) + "...."
                            : documentData?.length !== 0
                            ? "File exist"
                            : " File"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-buttons" style={{ marginLeft: "24%" }}>
                <button
                  type="cancel"
                  className="form-cancel clientCancel"
                  style={{ width: "112px", height: "32px" }}
                  onClick={HandleCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="form-save clientSave"
                  style={{ width: "112px", height: "32px" }}
                >
                  {documentData?.length !== 0 ? "Update" : "Save"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
