import React from "react";
import { useState } from "react";

import {
  useGetStoreQuery,
  useGetUserInfoQuery,
  useGetApproverQuery,
  useGetBatchInventoryRequestQuery,
  useItemsInventoryReceiveMutation,
} from "../../../../features/SCMApi";

import NoRecord from "../../../common/NoRecord";
import { IMG_BASE_URL } from "../../../../api/endPoint";
import StockDialog from "../../../common/StockDialog";
import NavBar from "../../../../Layout/NavBar";
import { NavLink, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BatchReturn from "../../StockModal/BatchReturn";

export default function ReceivedBatchItems(props) {
  const HandleFragment = (tool) => {
    props?.setFragment("detail");
    props?.setData(tool);
  };
  const params = useParams();
  const { data: BatchItem } = useGetBatchInventoryRequestQuery(params?.id);

  let tools =
    BatchItem?.data?.requested_items == undefined
      ? "[]"
      : BatchItem?.data?.requested_items;
  let BatchTools = JSON.parse(tools);

  const newUpdatedArray = BatchTools.map((item) => ({
    ...item,
    confirmed_by: BatchItem?.data.confirmed_by,
    transaction_id: BatchItem?.data.id,
    inventory_type: "item",
    transaction_type: "request",
    requester_id: BatchItem?.data.requester_id,
    requested_project: BatchItem?.data.requested_project,
    requested_date: BatchItem?.data.requested_date,
    store_name: BatchItem?.data.store_name,
  }));

  const [confirm, setConfirm] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [confirmReturn, setConfirmReturn] = useState(false);
  const [confirmConsume, setConfirmConsume] = useState(false);
  const [loading, setLoading] = useState(false);

  function HandleReceive() {
    setConfirm(true);
  }
  function HandleReturn() {
    setConfirmReturn(true);
  }
  function HandleConsume() {
    setConfirmConsume(true);
  }
  const { data: userinfo } = useGetUserInfoQuery();

  const [success, setSuccess] = useState(false);
  success &&
    setTimeout(() => {
      setSuccess(false);
    }, 3000);

  const { data: store } = useGetStoreQuery();
  let SK =
    userinfo?.role?.role_name == "Store Keeper" ||
    userinfo?.role?.role_name == "Super Admin";
  let FE = userinfo?.role?.role_name == "Field Engineer";
  let PM = userinfo?.role?.role_name == "Project Manager"; // userinfo?.role?.role_name == "Super Admin" ||
  let create_by = userinfo?.id;

  const [updateTool, setupdateTool] = useState(null);

  const [Accept, setAccept] = useState(false);
  const [remark, setRemark] = useState(null);
  function HandleRequest() {
    setConfirm(true);
  }
  function HandleAccept() {
    setAccept(true);
  }

  const [amount, setAmount] = useState();

  const [error, setError] = useState();

  let items =
    BatchItem?.data == undefined ? "[]" : BatchItem?.data?.requested_items;
  let BatchItems = JSON.parse(items);
  const filtered_data = BatchItems?.filter((batchItems) =>
    batchItems?.returned_amount
      ? batchItems?.consumed_amount
        ? batchItems?.current_consum_amount
          ? batchItems?.current_return_amount
            ? batchItems?.requested_amount >
              batchItems?.returned_amount +
                batchItems?.consumed_amount +
                batchItems?.current_consum_amount +
                batchItems?.current_return_amount
            : batchItems?.requested_amount >
              batchItems?.returned_amount +
                batchItems?.consumed_amount +
                batchItems?.current_consum_amount
          : batchItems?.requested_amount >
            batchItems?.returned_amount + batchItems?.consumed_amount
        : batchItems?.requested_amount > batchItems?.returned_amount
      : batchItems?.requested_amount > 0
  );

  const { data: getApprover } = useGetApproverQuery({
    module_name: "Tool",
    id: params?.id,
  });
  const [selectedItems, setSelectedItems] = useState([]);
  const toggleSelection = (id, items) => {
    const selectedIndex = selectedItems.indexOf(id);
    let newSelectedItems = [...selectedItems];

    if (selectedIndex === -1) {
      newSelectedItems.push(id);
    } else {
      newSelectedItems.splice(selectedIndex, 1);
    }

    setSelectedItems(newSelectedItems);
  };
  const getSelectedItems = () => {
    return filtered_data?.filter((item) => selectedItems.includes(item.id));
  };
  let itemsReceived = getSelectedItems()?.flat(1);

  const { data: loggedUser } = useGetUserInfoQuery();

  const [ItemRequest] = useItemsInventoryReceiveMutation();
  function HandleToolReceive(e, dialog) {
    let requested = {
      id: params?.id,
      received_by: userinfo?.id,
      tool_id: dialog?.tool_id,
      requested_quantity: dialog?.requested_quantity,
      received_items: BatchItem?.data?.requested_items,
      newUpdatedArray,
      is_batch: true,
    };

    ItemRequest(requested)
      .unwrap()
      .then((response) => {
        setConfirm(false);
      })
      .catch((error) => {
        setError(error?.data?.message);
      });
  }

  // let request_items = getApprover?.data.current[0]?.requested_items
  //   ? JSON?.parse(getApprover?.data.current[0]?.requested_items)
  //   : "[]";
  // let isRequested =
  //   getApprover?.data.current[0]?.is_returned == undefined
  //     ? 0
  //     : getApprover?.data.current[0]?.is_returned;
  // let isConfirmed =
  //   getApprover?.data.current[0]?.is_confirmed == undefined
  //     ? 0
  //     : getApprover?.data.current[0]?.is_confirmed;
  // let isStockKeeper =
  //   request_items[0]?.store?.store_keeper_id == undefined
  //     ? true
  //     : loggedUser?.id == request_items[0]?.store?.store_keeper_id;

  return (
    <>
      <NavBar
        placeholder={props?.placeholder}
        handleSearchChange={props?.handleSearchChange}
      />
      <div className="page">
        <div className="d-flex justify-content-start my-2">
          <NavLink to={`/stocks/onuse`} className="price-comparison">
            <span className="d-flex justify-content-center gap-4 px-3 py-3 align-items-center">
              <ArrowBackIcon />
              Go Back
            </span>
          </NavLink>
        </div>
        {filtered_data?.length !== 0 ? (
          <>
            <div className="">
              <div className="d-flex justify-content-between align-items-center bg-white px-3 py-2">
                <div className="tool-item-status">
                  {getApprover?.data?.current?.length == 0
                    ? "No need for approval"
                    : getApprover?.data?.current[0]?.is_approved == 0
                      ? `${getApprover?.data?.current[0]?.role_name} approval pending`
                      : getApprover?.data?.current[0]?.is_approved == 2
                        ? "Rejected"
                        : "Approved"}
                </div>{" "}
                {success ? (
                  <div className="h5-pri">Received Successfully</div>
                ) : (
                  ""
                )}
                <div className="items-counter">
                  <div className="text-center">{filtered_data?.length}</div>
                  <div>Items Count</div>
                </div>
              </div>
            </div>
            <table style={{ width: "100%" }}>
              <thead className="stock-table-header">
                <tr>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "3%" }}
                  ></th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "10%" }}
                  ></th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "17%" }}
                  >
                    Item Name
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "14%" }}
                  >
                    Model
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "10%" }}
                  >
                    Type
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "15%" }}
                  >
                    Store
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "10%" }}
                  >
                    Amount
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "11%" }}
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {filtered_data?.map((tool, index) =>
                  tool?.is_returned == 0 ? (
                    <tr key={index} className="stock-table-row">
                      <td>
                        {BatchItem?.data?.is_requester_received == 1 ? (
                          <input
                            type="checkbox"
                            checked={selectedItems.includes(tool.id)}
                            onChange={() => toggleSelection(tool.id, tool)}
                          />
                        ) : (
                          ""
                        )}
                      </td>
                      <td
                        className=""
                        onClick={() => {
                          HandleFragment(tool);
                          setupdateTool(null);
                        }}
                      >
                        <img
                          className="stock-img"
                          src={`${IMG_BASE_URL}${tool?.image}`}
                          alt=""
                        />
                      </td>
                      <td
                        className="text-start"
                        onClick={() => {
                          HandleFragment(tool);
                        }}
                      >
                        {tool?.item_name}
                      </td>
                      <td
                        className="text-start"
                        onClick={() => {
                          HandleFragment(tool);
                        }}
                      >
                        {tool?.model === null ? "-" : tool?.model}
                      </td>
                      <td
                        className="text-start"
                        onClick={() => {
                          HandleFragment(tool);
                        }}
                      >
                        {tool?.tool_type_id === null
                          ? "-"
                          : tool?.tool_type?.tool_type}
                      </td>
                      <td
                        className="text-start"
                        onClick={() => {
                          HandleFragment(tool);
                        }}
                      >
                        {tool?.store_id === null
                          ? "-"
                          : tool?.store?.store_name}
                      </td>
                      <td
                        className="text-start"
                        onClick={() => {
                          HandleFragment(tool);
                        }}
                      >
                        {`${tool?.requested_amount === null ? "-" : tool?.returned_amount ? (tool?.current_consum_amount ? tool?.requested_amount - (tool?.returned_amount + tool?.current_consum_amount) : tool?.requested_amount - tool?.returned_amount) : tool?.requested_amount} ${
                          tool?.uom_id == null ? "-" : tool?.uom?.name
                        }`}
                      </td>
                      <td
                        className="text-start"
                        onClick={() => {
                          HandleFragment(tool);
                        }}
                      >
                        {tool?.status_id == null
                          ? "-"
                          : tool?.status?.status_name}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )
                )}
              </tbody>
            </table>

            <div
              className="batch-header-container text-start mt-2"
              style={{ display: "flex" }}
            >
              {true ? (
                BatchItem?.data?.is_requester_received == 0 &&
                userinfo?.id == BatchItem?.data?.requester_id ? (
                  loading ? (
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : error ? (
                    <div className="spinner-border text-danger">{error}</div>
                  ) : success ? (
                    <div className="alert alert-success" role="alert">
                      {success}
                    </div>
                  ) : (
                    <button
                      className="form-save"
                      onClick={() => {
                        HandleReceive();
                        setDialog(BatchItem?.data);
                        setLoading(true);
                      }}
                    >
                      Receive
                    </button>
                  )
                ) : userinfo?.id == BatchItem?.data?.requester_id ? (
                  <>
                    <button
                      disabled={selectedItems?.length <= 0 ? true : false}
                      className={
                        selectedItems?.length <= 0
                          ? "form-save bg-secondary text-white"
                          : "form-save"
                      }
                      onClick={() => {
                        HandleReturn();
                      }}
                    >
                      Return
                    </button>
                    <button
                      disabled={selectedItems?.length <= 0 ? true : false}
                      className={
                        selectedItems?.length <= 0
                          ? "form-save bg-secondary text-white"
                          : "form-save"
                      }
                      onClick={() => {
                        HandleConsume();
                      }}
                    >
                      Consumed
                    </button>
                  </>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          <NoRecord />
        )}

        {confirm ? (
          <StockDialog
            project_id={1}
            setDialog={setConfirm}
            dialog={`Number of items: ${filtered_data?.length} `}
            data={dialog}
            error={error}
            name={"Receive"}
            action={(e) => HandleToolReceive(e, dialog)}
          />
        ) : (
          ""
        )}
        {confirmReturn ? (
          <BatchReturn
            modal={setConfirmReturn}
            data={itemsReceived}
            is_items={true}
          />
        ) : (
          ""
        )}
        {confirmConsume ? (
          <BatchReturn
            modal={setConfirmConsume}
            data={itemsReceived}
            is_consumed={true}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}
