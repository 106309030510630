import * as React from "react";
import Grid from "@mui/material/Grid";
import "../logistics.css";
import MissedItemDetailOrderList from "./missedItemDetailComponent/MissedItemDetailOrderList";
import MissedItemDetailStages from "./missedItemDetailComponent/MissedItemDetailStages";

export default function MissedItemBatchInfo(props) {
  return (
    <>
      <Grid
        container
        spacing={2}
        direction={{ xs: "row", sm: "row", md: "row" }}
      >
        <MissedItemDetailOrderList data={props?.data} />
        <MissedItemDetailStages
          missedItemId={props?.data?.id}
          stageData={props?.data}
          batchId={props?.data?.batch_id}
          projectId={props?.data?.project_id}
          successMessage={props.successMessage}
          message={props.message}
        />
      </Grid>
    </>
  );
}
