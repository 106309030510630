import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useReportRmaMutation } from "../../features/SCMApi";

export default function UpdateStatus(props) {
  const field = document.getElementById("failedQty");
  const [numberOfFailed, setNumberOfFailed] = useState();
  const [reportRma] = useReportRmaMutation();
  // field.value = props?.selectedItem?.failedQty;
  const { projectId } = useParams();
  function handleSubmit(e) {
    e.preventDefault();
    let Item = { ...props.selectedItem, qty: numberOfFailed };
    const rmaForm = {
      project_id: projectId,
      items: Item,
      batch_id: props.selectedItem?.batch_id,
    };
    reportRma(rmaForm)
      .unwrap()
      .then((response) => {})
      .then((error) => {});
  }
  return (
    <div>
      <div id="myModal" className="mini-modal">
        <div className="mini-modal-content">
          <form
            className="updates-status-form"
            onSubmit={(e) => handleSubmit(e)}
          >
            <div className="card-title">Update Product Status</div>
            <div className="divider"></div>
            <div className="item-information">
              <div className="items-row">
                <div className="item-label">Product Number</div>
                <div className="item-value">
                  {props.selectedItem?.item_part_number}
                </div>
              </div>
              <div className="items-row">
                <div className="item-label">Item Description</div>
                <div className="item-value description">
                  {props.selectedItem?.item_description}
                </div>
              </div>
              <div className="item-information">
                <div className="items-row">
                  <div className="item-label">Quantity</div>
                  <div className="item-value">{props.selectedItem?.qty}</div>
                </div>
              </div>
              <div className="divider"></div>{" "}
              <div className="failed-items">
                <div className="sub-title">Failed Product</div>
                <div className="failed-items-from-container">
                  <div className="failed-items-from">
                    <div className="label">Quantity</div>
                    <input
                      required
                      type="number"
                      className="text-input"
                      id="failedQty"
                      onChange={(e) => setNumberOfFailed(e.target.value)}
                    />
                  </div>
                  <div className="button-container">
                    <button
                      type="cancel"
                      className="form-cancel"
                      onClick={(e) => {
                        props?.setUpdateStatusModal(false);
                      }}
                    >
                      {" "}
                      cancel
                    </button>
                    <button type="submit" className="form-save">
                      {" "}
                      submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
