import React from "react";
import usePagination from "../../Pagination/Pagination";
import { Pagination } from "@mui/material";
import SuccessMessage from "../SuccessMessage";
import PaymentDataTable from "./creditNote";
import ReceivableMobileComponent from "../ForexPlanning/ReceivableMobileComponent";
import { styled } from "@mui/material/styles";
import CheckPermission from "../../common/checkPermission/checkPermission";
import {
  useGetUserInfoQuery,
  useGetCreditNotesQuery,
  useGetSuppliersQuery,
} from "../../../features/SCMApi";
import InitiateForexButton from "../ForexApproval/InitiateForexButton";

const TotalDisplay = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "20px",
  lineHeight: "28px",
  color: "#186569",
  border: "1px solid #aeb8c9",
  backgroundColor: "#FFFFFF",
  width: "362px",
  height: "50px",
  borderRadius: "5px",
  marginBottom: "-5px",
  marginTop: "15px",
}));

const ReceivableDisplay = (props) => {
  const { data: creditNotes } = useGetCreditNotesQuery();
  const grandTotals = [];

  for (const total in props?.data) {
    grandTotals.push(Number(props?.data[total]?.amount));
  }

  let grandTotal =
    Math.round(
      (grandTotals
        .filter((e) => typeof e == "number")
        .reduce((prev, acc) => prev + acc, 0) +
        Number.EPSILON) *
        100
    ) / 100;

  const [successMessage, setSuccessMessage] = React.useState(false);
  const [message, setMessage] = React.useState("");
  successMessage &&
    setTimeout(() => {
      setSuccessMessage(false);
    }, 3000);

  let [page, setPage] = React.useState(1);
  const PER_PAGE = 5;
  const count = Math.ceil(
    props?.data?.length && props?.data?.length / PER_PAGE
  );
  const _DATA = usePagination(props?.data, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  const { data: userinfo } = useGetUserInfoQuery();


  return (
    <>
      <div className="gap-analysis-web-view">
        <PaymentDataTable data={creditNotes?.data} />
      </div>
      <div className="gap-analysis-mobile-view">
        {CheckPermission("add_receivable", userinfo) ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginLeft: "40px",
            }}
          >
            <InitiateForexButton
              title="Add New"
              name="addNew"
              message={setMessage}
              successMessage={setSuccessMessage}
              style={{
                height: "40px",
                width: "362px",
                iconFont: "25px",
                iconMargin: "8px",
                borderRadius: "30px",
                fontSize: "16px",
              }}
            />
          </div>
        ) : (
          <></>
        )}
        <TotalDisplay>
          {grandTotal?.toLocaleString()}
          <span className="projectTotal receivable-total-mobile-view">
            Grand Total
          </span>
        </TotalDisplay>
        {successMessage === true ? (
          <SuccessMessage
            style={{
              marginTop: "10px",
              marginBottom: "-20px",
              backgroundColor: "#52ffab96",
              marginRight: "10px",
              width: "362px",
            }}
            className={"success_messages_text_style_mobile_View"}
            Message={message}
          />
        ) : (
          <></>
        )}

        {_DATA?.currentData()?.map((receivable) => (
          <ReceivableMobileComponent
            forexReceivable={receivable}
            successMessage={setSuccessMessage}
            message={setMessage}
          />
        ))}
        {count > 1 ? (
          <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              margin: "5px",
            }}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default ReceivableDisplay;
