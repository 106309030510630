import * as React from "react";
import TextField from "@mui/material/TextField";

const MultipleTextInput = (props) => {
  const mainSx = {
    width: "91%",
    marginTop: "7px",
    marginBottom: "7px",
    marginLeft: "2px",
  };
  const inputProps = {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "11px",
    lineHeight: "14px",
    letterSpacing: "0.105em",
    color: "#353945",
  };

  const [onChangeValue, setOnChangeValue] = React.useState(null);
  const handleChange = (e) => {
    setOnChangeValue(e.target.value);
  };

  onChangeValue !== null
    ? props?.setText(onChangeValue)
    : props?.setText(props?.defaultValue);

  return (
    <>
      <TextField
        multiline
        rows={5}
        placeholder="Description"
        variant="filled"
        onChange={handleChange}
        sx={mainSx}
        InputProps={{
          disableUnderline: true,
          sx: inputProps,
        }}
        defaultValue={props?.defaultValue}
        required={props?.required}
      />
    </>
  );
};

export default MultipleTextInput;
