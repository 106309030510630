import { Tooltip } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../api/endPoint";
import {
  useCreateBatchMutation,
  useGetPoQuery,
  useGetSupplierOriginsQuery,
} from "../../features/SCMApi";
import MultiSelect from "./MultiSelect";
import NoRecord from "./NoRecord";
// import { v4 as uuidv4 } from "uuid";

export default function CreateBatchModal(props) {
  const { ProjectId } = useParams();
  const { data: response } = useGetSupplierOriginsQuery();
  const { data: po } = useGetPoQuery(100);
  const [origin, setOrigin] = useState();
  const [orders, setOrders] = useState([]);
  const [selectedOrders, setSelectedOrder] = useState("");
  const [summarized_pi_id, setSummarized] = useState("");
  const [selectedManufacturers, setManufacturer] = useState([]);
  const [batch_name, setBatchName] = useState();
  const [createBatch] = useCreateBatchMutation();
  const [quantityValues, setQuantityValues] = useState({});
  const [quantityErrors, setQuantityErrors] = useState({});

  let manufacturers = [];
  let OrderList = [];

  const [SelectedOrderList, setList] = useState([]);

  const selectedForexItems = [];
  let is_batch = false;
  if (SelectedOrderList?.length > 0) {
    selectedForexItems.push(...SelectedOrderList);
  }

  useEffect(() => {
    axios({
      method: "get",
      // http://172.16.50.134:8001/api/scm/payment/batch-forex
      url: `${API_BASE_URL}/scm/payment/approved-forex/${ProjectId}`,
      responseType: "stream",
    }).then(function (response) {
      setOrders(
        JSON.parse(response?.data == undefined ? "[]" : response?.data)
      );
    });
  }, []);

  useEffect(() => {
    if (selectedOrders !== null) {
      axios({
        method: "get",
        url: `${API_BASE_URL}/scm/payment/summerized-pi/${selectedOrders}`,
        responseType: "json",
      })
        .then(function (response) {
          if (response.headers["content-type"].includes("application/json")) {
            const itemsData = response?.data;

            setList(itemsData?.data?.items);
            setSummarized(itemsData?.data?.id || "");
          } else {
            setList([]);
            setSummarized("");
          }
        })
        .catch((error) => {});
    }
  }, [selectedOrders]);

  orders?.data?.map((items) =>
    OrderList.push({
      value: items?.id,
      label: items?.forex_name,
    })
  );

  const [batchItems, setBatchItems] = useState([]);
  // const [allBatchItems, setAllBatchItems] = useState([]);
  let allBatchItems = [];
  SelectedOrderList?.map((items) => allBatchItems?.push(items?.id));

  const handleChangeBatch = (e, items) => {
    const { checked } = e.target;

    if (items === "all") {
      if (checked) {
        setBatchItems(allBatchItems);

        const initialValues = {};
        selectedForexItems.forEach((item) => {
          initialValues[item.id] = item.remaining_qty || 0;
        });
        setQuantityValues(initialValues);
        setQuantityErrors({});
      } else {
        setBatchItems([]);
        setQuantityValues({});
        setQuantityErrors({});
      }
    } else {
      if (checked) {
        // Add the permission to the permissions array
        setBatchItems((prevBatchItem) => [...prevBatchItem, items?.id]);
      } else {
        // Remove the permission from the permissions array
        setBatchItems((prevBatchItem) =>
          prevBatchItem.filter((id) => id !== items?.id)
        );
      }
      setQuantityErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[items.id];
        return newErrors;
      });
      setQuantityValues((prevValues) => ({
        ...prevValues,
        [items.id]: items.remaining_qty || 0, // Use default value or 0
      }));
    }
  };

  const [quantityError, setQuantityError] = useState(null);

  const handleQuantityChange = (e, item, index) => {
    const { value } = e.target;
    const intValue = value !== "" ? parseInt(value) : 1;

    const selectedForexItem = selectedForexItems.find(
      (selectedItem) => selectedItem.id === item.id
    );

    const reserved_quantity = selectedForexItem?.remaining_qty || 0;
    const validatedQty = Math.max(1, Math.min(intValue, reserved_quantity));

    setQuantityValues((prevValues) => ({
      ...prevValues,
      [index]: validatedQty,
    }));

    if (intValue < 1 || intValue > reserved_quantity) {
      setQuantityError(`Quantity must be between 1 and ${reserved_quantity}`);
    } else {
      setQuantityError(null);
    }
  };
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);

  useEffect(() => {
    setIsSaveButtonDisabled(!!quantityError);
  }, [quantityError]);
  const [selectedOrigins, setSelectedOrigins] = useState([]);

  function handleOrigin(index, value) {
    setSelectedOrigins((prev) => {
      const updatedOrigins = [...prev];

      updatedOrigins[index] = value;
      return updatedOrigins;
    });
  }

  // const items = SelectedOrderList.map((item) => batchItems.includes(item.id));
  const items = SelectedOrderList?.map((item, index) => {
    return {
      ...item,
      is_batch:
        item?.is_batch === true
          ? true
          : batchItems.includes(item.id)
            ? true
            : false,
      current_batch: batchItems.includes(item.id) ? true : false,
      missed_item: false,
      rma: false,
      delivery_group: false,
      qty: batchItems.includes(item.id)
        ? quantityValues[index] || item?.remaining_qty || 0
        : 0,
      country_of_origin: selectedOrigins[index] || "",
      id: item?.id,
    };
  });

  function handleSubmit(e) {
    e.preventDefault();
    const form = {
      // batch_name,
      forex_id: selectedOrders,
      project_id: ProjectId,
      items: items,
      origin_id: origin,
      summarized_pi_id,
    };

    createBatch(form)
      .unwrap()
      .then((response) => {
        props?.ToggleModal(false);
      })
      .catch((error) => {});
  }

  let checkSelected =
    selectedOrders === ""
      ? 0
      : selectedForexItems.filter((item) => !item?.is_batch).length === 0
        ? 1
        : 2;

  return (
    <div
      id="myModal"
      className="medium-modal "
      onClick={(e) => props?.ToggleModal(false)}
    >
      <div
        className="medium-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="create-project-modal-title">
          <div>Create Batch</div>
          <div
            className="close-modal-button"
            onClick={(e) => props?.ToggleModal(false)}
          >
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.4612 5.78721L19.8657 7.19175L7.46095 19.5965L6.0564 18.192L18.4612 5.78721ZM7.17507 5.51376L20.3505 18.6891L18.8589 20.1807L5.68351 7.00531L7.17507 5.51376Z"
                fill="#353945"
              />
            </svg>
          </div>
        </div>
        <form
          className="create-batch-form"
          onSubmit={(event) => handleSubmit(event)}
        >
          <div className="create-batch-option-group">
            <div className="input-group-grid gap-2">
              <label className="create-bach-input-label" htmlFor="origin">
                Shipping Origin
              </label>
              <select
                required
                className="text-input"
                name=""
                id="origin"
                onChange={(e) => setOrigin(e.target.value)}
              >
                <option selected="true" disabled="disabled">
                  Choose origin
                </option>
                {response?.data?.map((items) => (
                  <option value={items?.id}>{items?.name}</option>
                ))}
              </select>
            </div>
            <div className="input-group-grid gap-2">
              <label className="create-bach-input-label" htmlFor="Forex">
                Forex
              </label>
              <select
                required
                className="text-input"
                name=""
                id="Forex"
                onChange={(e) => setSelectedOrder(e.target.value)}
              >
                <option selected="true" disabled="disabled">
                  Choose Forex
                </option>
                {OrderList?.map((item) => (
                  <option value={item?.value}>{item?.label}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="create-batch-items-description">
            <>
              <div className="items-manufacturer"></div>
              <div className="items-description-divider"></div>
              {selectedForexItems?.length !== 0 ? (
                <label className="d-flex justify-content-start mb-2 gap-2">
                  <input
                    type="checkbox"
                    onChange={(e) => handleChangeBatch(e, "all")}
                  />
                  <span className="prg-pri">Select All</span>
                </label>
              ) : (
                ""
              )}
              <div className="items-checkbox">
                {checkSelected == 0 ? (
                  <div class="alert alert-secondary" role="alert">
                    Please Select Forex to Proceed
                  </div>
                ) : checkSelected == 1 ? (
                  <div class="alert alert-secondary" role="alert">
                    All Items have been batched!
                  </div>
                ) : (
                  <>
                    {selectedForexItems?.map((items, index) =>
                      items?.is_batch === false ? (
                        <>
                          <div className="check-box-field ">
                            <input
                              type="checkbox"
                              checked={batchItems?.includes(items?.id)}
                              onChange={(e) => handleChangeBatch(e, items)}
                            />
                            {batchItems.includes(items.id) && (
                              <div className="d-flex flex-row gap-1">
                                <div>
                                  <input
                                    type="number"
                                    className="text-input"
                                    style={{ width: "45px" }}
                                    value={
                                      quantityValues[index] ||
                                      items?.remaining_qty
                                    }
                                    onChange={(e) =>
                                      handleQuantityChange(e, items, index)
                                    }
                                    placeholder="Qty"
                                  />
                                </div>
                                <div>
                                  <select
                                    className="text-input"
                                    style={{ width: "122px" }}
                                    name=""
                                    id="country"
                                    value={selectedOrigins[index] || ""}
                                    onChange={(e) =>
                                      handleOrigin(index, e.target.value)
                                    }
                                  >
                                    <option value="" disabled>
                                      Country of origin
                                    </option>
                                    {response?.data?.map((items) => (
                                      <option
                                        key={items?.id}
                                        value={items?.name}
                                      >
                                        {items?.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            )}
                            <label htmlFor={items?.id}>
                              <Tooltip title={items?.item_description}>
                                <p
                                  className="prg mb-0"
                                  style={{ fontSize: 12 }}
                                >
                                  {items?.item_description}
                                </p>
                              </Tooltip>
                            </label>
                          </div>
                        </>
                      ) : (
                        ""
                      )
                    )}
                  </>
                )}
              </div>

              {quantityError && (
                <p className="form-control is-invalid !important">
                  {quantityError}
                </p>
              )}
            </>
          </div>
          <div className="form-buttons mt-4">
            <button
              type="cancel"
              className="form-cancel"
              onClick={(e) => {
                props.ToggleModal(false);
              }}
            >
              {" "}
              Cancel
            </button>
            <button
              type="submit"
              className="form-save"
              disabled={quantityError}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
