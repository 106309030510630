import React from "react";
import "./aging.css";
import PayableFilteredTable from "./PayableFilteredTable";
import AgingTopBar from "../../../components/Payment/ForexApproval/PaymentSideBar";
import { Tab, Tabs } from "react-bootstrap";

const PayableIndex = () => {
  return (
    <AgingTopBar title={"Aging"}>
      <div className="payable-container">
        <PayableFilteredTable />
      </div>
    </AgingTopBar>
  );
};

export default PayableIndex;
