import React from "react";
import { Login } from "../../components/Login/Login";
import { LoginTempo } from "../../components/Login/LoginTempo/LoginTempo";

const index = () => {
  return (
    <div>
      <Login />
      {/* <LoginTempo /> */}
    </div>
  );
};
export default index;
