import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../api/endPoint";
import { Tooltip } from "@mui/material";
import { Label } from "react-bootstrap";
import { useCreateBatchGroupMutation, useGetListOfUnitOfMeasurmentMutation } from "../../features/SCMApi";

export default function CreateGroupModal(props) {

  const { batchId } = useParams();  
  const [GetListOfUnitOfMeasurment] = useGetListOfUnitOfMeasurmentMutation();
  const [uom,setUom]=useState(null);

  const [groupList,setGroupList]=useState(props?.group?.map((item) => ({
    ...item,
    delivered_qty: item?.remaining_quantity ?? item?.qty, // You can set the initial value as needed
  })));

  const [createGroup, src] = useCreateBatchGroupMutation();
  const [item, setItems] = useState();
  const [group_name, setGroupName] = useState();
  const [warningMessage,setWarningMessage]=useState([]);

  const handleQuantityChange = (id, value) => {
    const targetItemIndex = groupList.findIndex((item) => item.id === id);
    
     let updatedItem={};
    if (targetItemIndex !== -1) {
        // if(actionType=='increment'){
             updatedItem = { ...groupList[targetItemIndex], delivered_qty:value  };
        // }
        // else{
        //    updatedItem = { ...groupList[targetItemIndex], delivered_qty: groupList[targetItemIndex].delivered_qty - 1 };
        // }
      
      // Create a new array with the updated item
      const updatedGroupList = [...groupList];
      updatedGroupList[targetItemIndex] = updatedItem;
      // Update the state with the new array
      setGroupList(updatedGroupList);
      // Now, you can use 'updatedItem' as needed.
    } else {
      console.warn("Item not found with the specified ID");
    }
  };
  
  let itemsGroup = [];
  groupList?.map((item, index) =>
    itemsGroup.push({
      ...item,
    })
  );
  itemsGroup = [
    ...itemsGroup?.filter(
      (item) => item?.missed_item == false && item?.rma == false 
    ),
  ];



  function IDGenerator() {
    let idGenerator = "abcdefghijklmnopqrstuvwxyz0123456789";
    let idGeneratorLength = idGenerator.length;
    let generatedId = "";
    for (let s = 0; s <= 5; s++) {
      generatedId += idGenerator.charAt(
        Math.floor(Math.random() * idGeneratorLength)
      );
    }
    return generatedId;
  }
  // function getSelectedCheckedItems(className) {
  //   const checkbox = document.getElementsByClassName(className);
  //   const checked = [];
  //   Object.keys(checkbox).map((box) =>
  //     checkbox[box].checked == true ? checked.push(checkbox[box].id) : null
  //   );
  //   let groupItemList = items;

  //   let groupItems = groupItemList?.map((item) => {
  //     return {
  //       delivery_group:
  //         item?.delivery_group == true
  //           ? true
  //           : checked.includes(item?.id)
  //           ? true
  //           : false,
  //       hs_code: item?.hs_code,
  //       id: item?.id,
  //       item_description: item?.item_description,
  //       item_part_number: item?.item_part_number,
  //       missed_item: false,
  //       po_group: item?.po_group,
  //       qty: item?.qty,
  //       rma: item?.rma,
  //       total_price: item?.total_price,
  //       unit_price: item?.unit_price,
  //       uom: item?.uom,
  //       current_delivery: checked.includes(item?.id) ? true : false,
  //     };
  //   });

  

  //   return groupItems;
  // }
  const handleUOMSelect=(id,value)=>{
    
     const targetItemIndex = groupList.findIndex((item) => item.id === id);
    
     let updatedItem={};
    if (targetItemIndex !== -1) {
        // if(actionType=='increment'){
             updatedItem = { ...groupList[targetItemIndex], uom:value  };
        // }
        // else{
        //    updatedItem = { ...groupList[targetItemIndex], delivered_qty: groupList[targetItemIndex].delivered_qty - 1 };
        // }
      
      // Create a new array with the updated item
      const updatedGroupList = [...groupList];
      updatedGroupList[targetItemIndex] = updatedItem;
      // Update the state with the new array
      setGroupList(updatedGroupList);
      // Now, you can use 'updatedItem' as needed.
    } else {
      console.warn("Item not found with the specified ID");
    }
  }
  useEffect(()=>{
      const getUom=()=>{
        GetListOfUnitOfMeasurment()
      .unwrap()
      .then((response) => {
        setUom(response?.data);
        
      })
      .then((error) => {
        
      });
      }
       getUom();
  },[]);
  const [groupItems, setGroupItems] = useState([]);
  let allGroupItems = [];
  itemsGroup?.map((items) => allGroupItems?.push(items?.id));
  const handleChangeGroup = (e, items) => {
    const { checked } = e.target;

    if (items == "all") {
      // Select All option is checked

      if (checked) {
        // Add all permissions to the permissions array
        setGroupItems(allGroupItems);
      } else {
        // Uncheck Select All and remove all permissions from the array
        setGroupItems([]);
      }
    } else {
      if (checked) {
        // Add the permission to the permissions array
        setGroupItems((prevGroupItem) => [...prevGroupItem, items?.id]);
      } else {
        // Remove the permission from the permissions array
        setGroupItems((prevGroupItem) =>
          prevGroupItem.filter((id) => id !== items?.id)
        );
      }
    }
  };
  // const items = SelectedOrderList.map((item) => batchItems.includes(item.id));
  const items = itemsGroup?.map((item) => {
    return {
      delivery_group:
        item?.delivery_group == true
          ? true
          : groupItems.includes(item?.id)
          ? true
          : false,
      hs_code: item?.hs_code,
      id: item?.id,
      item_description: item?.item_description,
      item_part_number: item?.item_part_number,
      missed_item: false,
      po_group: item?.po_group,
      qty: item?.qty,
      delivered_qty:item?.delivered_qty,
      rma: item?.rma,
      total_price: item?.total_price,
      unit_price: item?.unit_price,
      uom: item?.uom,
      current_delivery: groupItems.includes(item?.id) ? true : false,
    };
  });
  const checkedItems=items.filter((item)=>groupItems.includes(item.id));
  
  function HandleCancel() {
    props.ToggleModal(false);
    
  }
  function handleSubmit(e, className) {
    e.preventDefault();
    const createGroupForm = {
      batch_id: batchId,
      items: checkedItems,
    };
    
    if (createGroupForm?.items && Array.isArray(createGroupForm.items)) {
      // Map over the items array and update each item's `uom` property
      createGroupForm.items = createGroupForm.items.map(item => {
          // Check if `uom` property does not exist or is `null`
          if (!item.hasOwnProperty('uom') || item.uom === null) {
              // Set default value for `uom` property to "pcs"
              item.uom = "pcs";
          }
          return item; // Return the updated item
      });
  }

    
    // createGroup(createGroupForm)
    //   .unwrap()
    //   .then((response) => {
   
    //     props.ToggleModal(false);
    //   })
    //   .catch((error) => {
    
    //   });
    
    axios({
      method: "post",
      url: `${API_BASE_URL}/scm/item-delivery`,
      data: createGroupForm,
      headers: { "Content-Type": "application/json" },
    })
      .then(function (response) {
        props.ToggleModal(false);
        window.location.reload(true);
       
      })
      .catch(function (error) {
        //handle error
       
      });
    
  }
  

  return (
    <div>
      {itemsGroup?.length != 0 ? (
        <label className="d-flex justify-content-start mb-2 ms-5 gap-2">
          <input
            type="checkbox"
            onChange={(e) => handleChangeGroup(e, "all")}
          />
          <span className="prg-pri">Select All</span>
        </label>
      ) : (
        ""
      )}
      <form
        className="form-container "
        onSubmit={(e) => handleSubmit(e, "createGroup-items")}
      >
         <div className="form-card-container">
          <div className="">
            <div className="card-center">
              <div className="ms-5">
                <div className="supplier-input-labels">
                  
                  {itemsGroup?.map((items,index) =>
                    (items?.remaining_quantity ?? items?.qty) >0  ? (
                      <div className="text-center">
                        <div className="d-grid gap-2 justify-content-start">  
                            <label className="d-flex gap-2 align-items-center justify-content-start">
                              <input
                                type="checkbox"
                                checked={groupItems?.includes(items?.id)}
                                onChange={(e) => handleChangeGroup(e, items)}
                              />
                              <Tooltip title={items?.item_description}>
                                <div className="prg text-start">
                                  {items?.item_description?.length >= 150
                                    ? items?.item_description?.slice(0, 150) + "..."
                                    : items?.item_description}
                                </div>
                              </Tooltip>
                            </label>
                             {/* {groupItems?.includes(items?.id) && 
                              <div className="d-flex gap-4 align-items-center quantity-control ml-10 justify-content-center">
                                <div><strong>Available Qty {items?.remaining_quantity ?? items?.qty}</strong></div>
                                <div className="d-flex gap-4 align-items-center ml-5">
                                  <button className="text-sm font-bold rounded " type="button" disabled={items?.delivered_qty <= 1 } onClick={()=>handleQuantityChange(items?.id,"decrement")}>-</button>
                                     <label>{ items?.delivered_qty}</label>
                                  <button className="text-sm font-bold rounded" type="button" disabled={items?.remaining_quantity ?? items?.qty <= items?.delivered_qty} onClick={()=>handleQuantityChange(items?.id,"increment")}>+</button>
                                </div>
                            </div>} */}
                            {groupItems?.includes(items?.id) && (
                                    <div className="d-flex gap-4 align-items-center quantity-control ml-10 justify-content-center">
                                    <div>
                                    <strong>Available Qty {items?.remaining_quantity ?? items?.qty}</strong>
                                    </div>
                                    <div className="ml-5">
                                    <input
                                        type="number"
                                        value={items?.delivered_qty}
                                    onChange={(e) => {
                                          const value = parseInt(e.target.value, 10);
                                         
                                          if (!isNaN(value)) {
                                            if(value <= (items?.remaining_quantity ? items?.remaining_quantity : items?.qty)){
                                              const updatedWarningMessages = [...warningMessage];
                                                    updatedWarningMessages[index] = "";
                                                    setWarningMessage(updatedWarningMessages);
                                                handleQuantityChange(items?.id, value);
                                          }
                                          else{
                                                const updatedWarningMessages = [...warningMessage];
                                                updatedWarningMessages[index] = "can't group above qty";
                                                setWarningMessage(updatedWarningMessages);
                                          }
                                        }
                                    }}
                                    min="0"
                                    />
                                    {warningMessage[index] && <span className="alert alert-warning p-1 italic fs-6" style={{text:'red'}}>{warningMessage[index]}</span>}
                                    </div>
                                    <div>
                                      <strong> Unit of Measurement </strong> 
                                      <select
                                        type="search"
                                        className="select-option-create-order"
                                        onChange={(e) =>
                                          handleUOMSelect(items?.id,e.target.value)
                                        }
                                        style={{ width: "100px" }}
                                      >
                                        <option value="">Pcs</option>
                                        {uom
                                          ?.map((items) => (
                                            <option value={items?.name}>
                                              {items?.name}
                                            </option>
                                          ))}
                                      </select>
                                    </div>
                                    </div>
                                    )}

                           </div>
                      </div>
                    ) : (
                      ""
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
    
        <div className="d-flex justify-content-center gap-3 mt-2">
          <button
            type="cancel"
            className="form-cancel"
            onClick={(e) => HandleCancel(e)}
          >
            {" "}
            Cancel
          </button>
          <button type="submit" className="form-save">
            Save
          </button>
        </div>
      </form>
    </div>
  );
}
