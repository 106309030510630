import React from "react";
import { useState } from "react";
import { useRequestForGrnMutation } from "../../features/SCMApi";
import { useRequestPrfMutation } from "../../features/SCMApi";

export default function RequestGrnModal(props) {
  const [requestForGrn] = useRequestForGrnMutation();
  const [requestPRF] = useRequestPrfMutation();
  const [inputValues, setInputValues] = useState({});
  const [disabledButtons, setDisabledButtons] = useState({});


  const handleInputChange = (itemId, value) => {
    const updatedInputValues = { ...inputValues, [itemId]: value };
    setInputValues(updatedInputValues);

    const isDisabled = !value; // Disable button if input value is empty
    setDisabledButtons((prevState) => ({ ...prevState, [itemId]: isDisabled }));
  };

  let request = {
    po_id: props?.data?.id,
  };

  const handleImageUpload = (id, file) => {
    if (!file) return;

    const updatedInputValues = { ...inputValues };
    updatedInputValues[`image-${id}`] = file; // Store the file object directly
    setInputValues(updatedInputValues);
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();

    requestPRF(request)
      .unwrap()
      .then((response) => {
        props.setMessages("PRF Requested successfully");
        props.modal(false);
      })
      .catch((error) => {});
  };

  const requestGrn = (requestPrfId) => {
    const data = {
      requestPrfId: requestPrfId,
      reason: inputValues[requestPrfId],

      receipt: inputValues[`image-${requestPrfId}`], // Send the file object
    };

    requestForGrn(data)
      .unwrap()
      .then((response) => {


        props?.setType("success");
        props?.setMessage("successfully requested");
        props?.setIsOpen(true);
      })
      .catch((error) => {
        // Handle error response

        props?.setType("danger");
        props?.setMessage(error?.data?.message || "An error occurred");
        props?.setIsOpen(true);
      });

    // Close modal (assuming props is accessible)
    props?.modal(false);
  };


  return (
    <>
      {!props?.generateGrnConfirmation ? (
        <div
          id="Modal"
          className="dialog-modal"
          onClick={(e) => {
            props?.modal(false);
          }}
        >
          <div
            style={{
              background: "#ffffff",
              borderRadius: "6px",
              width: "726px",
              height: "fit-content",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",

              gap: "30px",
              padding: "32px 30px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className="dialog-info"
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                marginBottom: "20px",
              }}
            >
              Are you sure to request?
            </div>
            <div style={{ width: "100%" }}>
              {props?.data?.map((prfItem) => (
                <div
                  key={prfItem?.id}
                  className="d-flex justify-content-between align-items-center mb-3"
                  style={{
                    borderBottom: "1px solid #eee",
                    paddingBottom: "15px",
                  }}
                >
                  <div style={{ flexGrow: 1 }}>
                    <input
                      type="text"
                      className="form-control mb-2"
                      required
                      value={inputValues[prfItem?.id] || ""}
                      onChange={(e) =>
                        handleInputChange(prfItem?.id, e.target.value)
                      }
                      placeholder="Type a reason"
                      style={{
                        width: "100%",
                        marginBottom: "10px",
                        padding: "10px",
                        borderRadius: "4px",
                        border: "1px solid #ccc",
                      }}
                      id={`item-${prfItem?.id}`}
                    />
                    <b>Add Receipt Image</b>
                    <input
                      type="file"
                      accept="image/*"
                      className="form-control"
                      required
                      onChange={(e) =>
                        handleImageUpload(prfItem?.id, e.target.files[0])
                      }
                      style={{
                        width: "100%",
                        padding: "5px",
                        borderRadius: "4px",
                        border: "1px solid #ccc",
                      }}
                      id={`image-upload-${prfItem?.id}`}
                    />
                    {inputValues[`image-${prfItem?.id}`] && (
                      <img
                        src={inputValues[`image-${prfItem?.id}`]}
                        alt="Uploaded Preview"
                        style={{
                          marginTop: "10px",
                          maxWidth: "100%",
                          maxHeight: "200px",
                          borderRadius: "4px",
                          border: "1px solid #ccc",
                        }}
                      />
                    )}
                  </div>{" "}
                  <button
                    className="send-for-approval-btn"
                    disabled={disabledButtons[prfItem?.id] || undefined}
                    onClick={() => requestGrn(prfItem?.id)}
                    style={{
                      backgroundColor: disabledButtons[prfItem?.id]
                        ? "#ccc"
                        : "#007bff",
                      color: "#fff",
                      padding: "10px 15px",
                      borderRadius: "4px",
                      border: "none",
                      cursor: disabledButtons[prfItem?.id]
                        ? "not-allowed"
                        : "pointer",
                      marginLeft: "15px",
                    }}
                  >
                    GRN Request
                  </button>
                </div>
              ))}
            </div>
            <div
              className="confirm-dialog-controller"
              style={{ marginTop: "20px" }}
            >
              <div
                className="dialog-cancel-btn"
                style={{
                  backgroundColor: "#f8f9fa",
                  padding: "10px 20px",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  props?.modal(false);
                }}
              >
                Cancel
              </div>
              {!props?.requestGrn && (
                <button

                  type="submit"
                  onClick={(e) => HandleSubmit(e)}
                  className="form-save"
                  style={{
                    backgroundColor: "#007bff",
                    color: "#fff",
                    padding: "10px 20px",
                    borderRadius: "4px",
                    marginLeft: "15px",
                    cursor: "pointer",
                    border: "none",
                  }}
                >
                  Request
                </button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          id="Modal"
          className="dialog-modal"
          onClick={(e) => {
            props?.modal(false);
          }}
        >
          <div
            style={{
              background: "#ffffff",
              borderRadius: "6px",
              width: "726px",
              height: "fit-content",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",

              gap: "30px",
              padding: "32px 30px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className="dialog-info"
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                marginBottom: "20px",
                textAlign: "center",
              }}
            >
              <strong>
                Are you sure you want to generate for the checked items only?
              </strong>
            </div>
            <div
              className="confirm-dialog-controller"
              style={{ marginTop: "20px" }}
            >
              <div
                className="dialog-cancel-btn"
                style={{
                  backgroundColor: "#f8f9fa",
                  padding: "10px 20px",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  props?.modal(false);
                }}
              >
                Cancel
              </div>
              {!props?.requestGrn && (
                <button

                  type="submit"
                  onClick={(e) => props?.confirmGenerate()}
                  className="form-save"
                  style={{
                    backgroundColor: "#007bff",
                    color: "#fff",
                    padding: "10px 20px",
                    borderRadius: "4px",
                    marginLeft: "15px",
                    cursor: "pointer",
                    border: "none",
                  }}
                >
                  Confirm and Generate
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
