import { Alert, Box } from "@mui/material";

export default function SuccessMessage(props) {
  return (
    <Box
      sx={{
        width: "100%",
        color: "#FFF",
        marginTop: props?.style?.marginTop,
        marginBottom: props?.style?.marginBottom,
      }}
    >
      <Alert
        sx={{
          mb: props?.style?.mb ? props?.style?.mb : 2,
          width: props?.style?.width,
          marginLeft: props?.style?.marginLeft,
          marginRight: props?.style?.marginRight,
          backgroundColor: props?.style?.backgroundColor,
          "& .MuiAlert-icon": {
            fontSize: 25,
            color: "#186569",
          },
        }}
      >
        <span
          className={
            props?.className ? props?.className : "success_messages_text_style"
          }
        >
          {props?.Message}
        </span>
      </Alert>
    </Box>
  );
}
