import { IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { API_BASE_URL, IMG_BASE_URL } from "../../api/endPoint";
import {
  useAddBillToMutation,
  useDeleteOrderMutation,
  useGetEtaDetailQuery,
  useGetOrderPoItemQuery,
  useGetProjectQuery,
  useGetProjectRolePermissionQuery,
  useGetProjectRolesQuery,
  useGetSuppliersOrdersQuery,
  useGetUserInfoQuery,
  useUpdateDescriptionMutation,
  useUpdateOrderSourceMutation,
} from "../../features/SCMApi";
import NavBar from "../../Layout/NavBar";
import SourcingIndex from "../../pages/Sourcing/SourcingIndex";
import EtaPoDetail from "./EtaPoDetail";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import NoRecord from "../common/NoRecord";
import CheckProjectPermission from "../common/checkPermission/CheckProjectPermission";
import { Edit } from "@mui/icons-material";
import EditPi from "./EditPi";
import CheckPermission from "../common/checkPermission/checkPermission";
export default function OrderDescription({ setMessages }) {
  const params = useParams();
  const { data: etaDetail } = useGetEtaDetailQuery(params.id);
  const { data: orderpo } = useGetOrderPoItemQuery(params.id);

  const location = useLocation();
  const [description, setDescription] = useState(
    etaDetail?.data[0]?.description
  );
  const [showProduct, setShowProduct] = useState();
  const [updateDescription] = useUpdateDescriptionMutation();
  const HandleUpdateProduct = async (e) => {
    let updatedescription = {
      id: params?.id,
      description,
    };
    e.preventDefault();
    updateDescription(updatedescription)
      .unwrap()
      .then((response) => {

        setMessages("Updated Successfully");
        setShowProduct(false);
      })
      .then((error) => {

      });

  };

  return (
    <div className="bill-card p-1 mb-2">
      {!showProduct && (
        <div>
          <div className="text-center">
            <div className="h5-pri  text-center">
              {etaDetail?.data[0]?.description}
            </div>
            <div className="lc-label text-center">Description</div>
          </div>
          <div className="d-flex justify-content-center">
            <IconButton
              variant="text"
              size="small"
              sx={{
                zIndex: 1,
                backgroundColor: "#fff",
                color: "#186569",
                "&:hover": {
                  backgroundColor: "#186569",
                  color: "#fff",
                },
              }}
              onClick={() => setShowProduct((prev) => !prev)}
            >
              <ModeEditOutlinedIcon />
            </IconButton>
          </div>
        </div>
      )}
      {showProduct && (
        <div className="d-grid justify-content-center">
          <div className="lc-label text-start">Description</div>
          <textarea
            type="text"
            className="sourcing-text-input-textarea"
            rows={4}
            cols={50}
            onChange={(e) => setDescription(e.target.value)}
          >
            {description}
          </textarea>
          <div className="d-flex justify-content-center gap-3">
            <IconButton
              variant="text"
              size="small"
              sx={{
                zIndex: 1,
                backgroundColor: "#fff",
                color: "#186569",

                "&:hover": {
                  backgroundColor: "#186569",
                  color: "#fff",
                },
              }}
              onClick={HandleUpdateProduct}
            >
              <CheckIcon />
            </IconButton>
            <IconButton
              variant="text"
              size="small"
              sx={{
                zIndex: 1,
                backgroundColor: "#fff",
                color: "#186569",

                "&:hover": {
                  backgroundColor: "#186569",
                  color: "#fff",
                },
              }}
              onClick={() => setShowProduct((prev) => !prev)}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      )}
      {/* <div className="lc-label mb-2 text-center">Description</div> */}
    </div>
  );
}
