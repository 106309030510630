import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddToolType from "./SettingModal/AddToolType";
import EditToolType from "./SettingModal/EditToolType";
import {
  useDeleteToolTypeMutation,
  useGetToolTypeQuery,
} from "../../features/SCMApi";
import DeleteModal from "../Supplier/SupplierModal/DeleteModal";

export default function ToolType() {
  const [addToolType, setAddToolType] = useState(false);
  const [editToolType, setEditToolType] = useState(false);
  const [showClientDeleteModal, setShowClientDeleteModal] = useState(false);
  const [toolTypeToDeleteId, setToolTypeToDeleteId] = useState(null);
  const [currentToolType, setCurrentToolType] = useState(null);

  const { data: tooltype } = useGetToolTypeQuery();
  const [deleteToolType] = useDeleteToolTypeMutation();

  const handleAddToolTypeModal = () => {
    setAddToolType(true);
  };

  const handleEditToolTypeModal = (items) => {
    setEditToolType(true);
    setCurrentToolType(items);
  };

  const toggleDeleteModal = () => {
    setShowClientDeleteModal(!showClientDeleteModal);
  };

  const handleOpenDeleteModal = (id) => {
    setToolTypeToDeleteId(id);
    toggleDeleteModal();
  };

  const handleDeleteToolType = () => {
    const deleted = {
      id: toolTypeToDeleteId,
    };
    deleteToolType(deleted)
      .unwrap()
      .then(() => {
        console.log("Tool type deleted successfully");
      })
      .catch((error) => {
        console.error("Error deleting tool type:", error);
      });
    toggleDeleteModal();
  };

  return (
    <div className="pcard incoterm">
      <section className="pi-header">
        <h3>Tool Type</h3>
      </section>
      {tooltype?.data?.map((items) => (
        <section className="pi-body" key={items.id}>
          <p>{items?.tool_type}</p>
          <p>{items?.description}</p>
          <div>
            <ModeEditOutlineOutlinedIcon
              onClick={() => handleEditToolTypeModal(items)}
              sx={{
                color: "#52FFAC",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#52FFAC",
                  border: "1px solid #52FFAC",
                  borderRadius: 25,
                },
              }}
            />
            <DeleteOutlineOutlinedIcon
              onClick={() => handleOpenDeleteModal(items.id)}
              sx={{
                color: "#FF5252",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#FF5252",
                  border: "1px solid #FF5252",
                  borderRadius: 25,
                },
              }}
            />
          </div>
        </section>
      ))}

      <section className="pi-footer">
        <div onClick={handleAddToolTypeModal} className="pi-footer-icon">
          <AddIcon />
        </div>
      </section>

      {addToolType && <AddToolType modal={setAddToolType} />}
      {editToolType && (
        <EditToolType modal={setEditToolType} data={currentToolType} />
      )}

      <DeleteModal
        show={showClientDeleteModal}
        handleDeleteModal={toggleDeleteModal}
        name={"tool type"}
        deletedId={toolTypeToDeleteId}
        navigationto={"smm/tool-type"}
        handleDelete={handleDeleteToolType}
      />
    </div>
  );
}
