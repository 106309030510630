import axios from "axios";
import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../api/endPoint";
import { useEditETDMutation } from "../../features/SCMApi";

export default function EditETD(props) {
  const [etd, setEtd] = useState();
  const [editETD] = useEditETDMutation(props?.current);
  const { batchId } = useParams();
  function handleSubmit(e) {
    e.preventDefault();
    const edtForm = {
      batchId,
      etd,
    };
    
    
    editETD(edtForm)
      .unwrap()
      .then((response) => {
        
        props?.ToggleModal(false);
      })
      .then((error) => {
       
      });
    // axios({
    //   method: "post",
    //   url: `${API_BASE_URL}/scm/logistics/batch-etd/${batchId}`,
    //   data: edtForm,
    //   headers: { "Content-Type": "application/json" },
    // })
    //   .then(function (response) {
    //     //handle success
  
    //   })
    //   .catch(function (response) {
    //     //handle error
    
    //   });
  }
  return (
    <div>
      <div
        id="Modal"
        className="edit-mini-modal"
        onClick={(e) => {
          props?.ToggleModal(false);
          
        }}
      >
        <form
          onSubmit={(e) => handleSubmit(e)}
          className="edit-mini-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="edit-dialog-info">Edit ETD</div>
          <div className="edit-modal-divider"></div>
          <div className="row gap-3 centered-content-column">
            <div className="col-12  ">
              <div className="">
                <div className="edit-input-label mb-1">ETD</div>
                <input
                  required
                  onChange={(e) => setEtd(e.target.value)}
                  // defaultValue={solution_name}
                  //   placeholder={props?.current}
                  type="date"
                  className="select-option"
                />
              </div>
            </div>
            <div className="confirm-dialog-controller">
              <button
                type="cancel"
                className="form-cancel"
                onClick={(e) => {
                  props?.ToggleModal(false);
                }}
              >
                Cancel
              </button>
              <button
                className="form-save"
                type={"submit"}
                // onClick={(e) => {
                //   handleSubmit();
                // }}
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
