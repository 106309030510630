import React from "react";

export default function NBEDialog(props) {


  return (
    <div
      id="Modal"
      className="dialog-modal "
      onClick={(e) => {
        props?.setDialog(false);
        props?.setters(false);
      }}
    >
      <div
        className="stock-dialog-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="dialog-info">Are you sure to clear</div>
        <div className="dialog-info">
          <div className="prg">{props?.forex_name}</div>
        </div>

        <div className="confirm-dialog-controller">
          <div
            className="dialog-cancel-btn"
            onClick={(e) => {
              props?.setDialog(false);
            }}
          >
            No
          </div>
          <button
            type={"submit"}
            onClick={(e) => {
              props.action(e, props?.dialog?.id);
            }}
            className={"form-save"}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
}
