import { useState } from "react";
import "./notification.style.css";
import LaunchIcon from "@mui/icons-material/Launch";
import CircleIcon from "@mui/icons-material/Circle";
import ClearIcon from "@mui/icons-material/Clear";
import { NavLink } from "react-router-dom";
import {
  useGetNotificationQuery,
  useReadNotificationMutation,
} from "../../features/SCMApi";

const NotificationCard = ({ show = false, setShow }) => {
  const [newNotification, setNewNotification] = useState(null);
  const [seenNotification, setSeenNotification] = useState(null);
  const [newDefaultNotification, setNewDefaultNotification] = useState(true);

  const handelNewNotification = (e) => {
    setSeenNotification(false);
    setNewNotification(true);
  };
  const handelSeenNotification = (e) => {
    setNewNotification(false);
    setSeenNotification(true);
    setNewDefaultNotification(false);
  };

  const { data: notifications } = useGetNotificationQuery();
  const [readNotification] = useReadNotificationMutation();

  const unreadNotifications =
    notifications &&
    notifications?.filter((notification) => notification?.read_at == null);
  const readNotifications =
    notifications &&
    notifications?.filter((notification) => notification?.read_at !== null);

 

  const handleRead = (id) => {
    const params = { id };
    readNotification(params)
      .unwrap()
      .then((response) => {
       
      })
      .catch((error) => {
        
      });
  };
  return (
    <div
      className={
        show ? "notification-card-container" : "notification-display-none"
      }
    >
      <div className="notification-bar-container">
        <div
          onClick={handelNewNotification}
          className={
            newNotification === true || newDefaultNotification === true
              ? "notification-new-button"
              : "notification-seen-button"
          }
          style={{ borderTopLeftRadius: "6px" }}
        >
          New
        </div>
        <div
          onClick={handelSeenNotification}
          className={
            seenNotification === true
              ? "notification-new-button"
              : "notification-seen-button"
          }
          style={{ borderTopRightRadius: "6px" }}
        >
          Seen
        </div>
      </div>
      {newNotification === true || newDefaultNotification === true ? (
        <div className="all-notifications-container">
          {unreadNotifications?.map((notification) => (
            <NavLink
              onClick={() => handleRead(notification?.id)}
              to={
                notification?.data?.type == "Document"
                  ? `/logistics/batches/${notification?.data?.project_id}/batchDetail/${notification?.data?.document_id}/documentPreparation`
                  : notification?.data?.type == "PA"
                  ? `/sourcing/paStageManufacturer/${notification?.data?.order_id}`
                  : notification?.data?.type == "PO"
                  ? `/poStageDetail/${notification?.data?.po_id}`
                  : ""
              }
            >
              <div
                key={notification?.id}
                className="single-notification-container"
                onClick={(e) => {}}
              >
                <div className="notification-value">
                  {notification?.data?.message}
                </div>
                <div className="notification-module-name-and-date">
                  <div>
                    {notification?.data?.type == "Document"
                      ? "Logistics"
                      : "Sourcing"}
                  </div>
                  <div
                    className="notification-module-date-divider"
                    style={{ color: "#353945d3" }}
                  >
                    .
                  </div>
                  <div>
                    {new Date(notification?.created_at)
                      ?.toLocaleString("en-US", {
                        hour12: true,
                      })
                      ?.replace(",", "")}
                  </div>
                </div>
                <span className="notification-status-icon">
                  <CircleIcon
                    style={{
                      fontSize: "13px",
                      color: "#FF5252",
                    }}
                  />
                </span>
                <span id="cancel" className="notification-status-icon">
                  <ClearIcon
                    style={{
                      fontSize: "13px",
                      color: "#FF5252",
                      fontWeight: "bolder",
                    }}
                    onClick={() => handleRead(notification?.id)}
                  />
                </span>
              </div>
            </NavLink>
          ))}
        </div>
      ) : (
        <></>
      )}
      {seenNotification === true ? (
        <div className="all-notifications-container">
          {readNotifications?.map((notification) => (
            <NavLink
              onClick={() => handleRead(notification?.id)}
              to={
                notification?.data?.type == "Document"
                  ? `/logistics/batches/${notification?.data?.project_id}/batchDetail/${notification?.data?.document_id}/documentPreparation`
                  : notification?.data?.type == "PA"
                  ? `/sourcing/paStageManufacturer/${notification?.data?.order_id}`
                  : notification?.data?.type == "PO"
                  ? `/poStageDetail/${notification?.data?.po_id}`
                  : ""
              }
            >
              <div
                key={notification?.id}
                className="single-notification-container-for-seen"
              >
                <div className="notification-value">
                  {notification?.data?.message}
                </div>
                <div className="notification-module-name-and-date">
                  <div>
                    {notification?.data?.type == "Document"
                      ? "Logistics"
                      : "Sourcing"}
                  </div>
                  <div
                    className="notification-module-date-divider"
                    style={{ color: "#353945d3" }}
                  >
                    .
                  </div>
                  <div>
                    {" "}
                    {new Date(notification?.created_at)
                      ?.toLocaleString("en-US", {
                        hour12: true,
                      })
                      ?.replace(",", "")}
                  </div>
                </div>
              </div>
            </NavLink>
          ))}
        </div>
      ) : (
        <></>
      )}
      <div className="notification-footer-container">
        {newNotification === true || newDefaultNotification === true ? (
          <>
            <div
              className="notification-mark-as-read-button"
              onClick={() => handleRead()}
            >
              Mark all as read
            </div>
            <div className="notification-view-all-button">
              <NavLink
                to="/notification"
                state={{ new: true }}
                style={{
                  color: "#186569",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <LaunchIcon className="rmaExportImportLinkIcon" />
                View all
              </NavLink>
            </div>
          </>
        ) : seenNotification === true ? (
          <div className="notification-view-all-button notification-view-all-full-width">
            <NavLink
              to="/notification"
              state={{ seen: true }}
              style={{
                color: "#186569",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <LaunchIcon className="rmaExportImportLinkIcon" />
              View all
            </NavLink>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default NotificationCard;
