import React from "react";
import attachmentIcon from "../../assets/SVG/attach.svg";

export default function FileChooser(props) {
  function handleChange(event) {
    props?.setters
      ? props?.setters(event.target.files[0])
      : props?.setter(event.target.files[0]);
  }
  return (
    <div
      className={`file-chooser cl-9 px-0 ${props.className}`}
      style={{ backgroundImage: `url(${attachmentIcon})` }}
    >
      <input
        id={props.id}
        required={props?.notRequired ? !props?.notRequired : true}
        type={"file"}
        class="file-input"
        onChange={(e) => handleChange(e)}
      />
      <label
        htmlFor={props.id}
        style={{
          position: "relative",
          top: "-28px",
          left: "5px",
          width: "10rem",
          // zIndex: "-1",
        }}
      >
        {props?.state
          ? props?.state?.name?.length > 16
            ? props?.state?.name?.slice(0, 15) + "..."
            : props?.state?.name
          : "Choose File"}
      </label>
    </div>
  );
}
