import React, { useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../../api/endPoint";
import "../../../../components/Logistics/logistics.css";
import Select from "../../ForexApproval/Select";
import DatePicker from "../../ForexPlanning/DatePicker";
import MultipleTextInput from "../../ForexPlanning/MultipleTextInput";
import {
  useUpdateCreditNoteMutation,
  useGetReceivableManufacturersQuery,
  useGetForexOpeningToQuery,
  useGetReceivableBanksQuery,
} from "../../../../features/SCMApi";

export default function EditCreditNote(props) {
  const [updateCreditNote] = useUpdateCreditNoteMutation();

  const [amount, setAmountName] = useState();


  let params = {
    payed_amount: Number(amount),
    id: props?.previousData,
  };

  function HandleCancel() {
    props.modal(false);
  }

  const HandleSubmit = async (e) => {
    e.preventDefault();
    // axios
    //   .post(
    //     `${API_BASE_URL}/scm/payment/forex-receivable/${props?.previousData?.id}`,
    //     params,
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   )
    //   .then((response) => {

    //     HandleCancel();
    //     props.refreshe();
    //   })
    //   .then((error) => {

    //   });
    updateCreditNote(params)
      .unwrap()
      .then((response) => {
        HandleCancel();
        props?.successMessage(true);
        props?.message(response?.message);
        props?.resStatus(response.status)

      })
      .then((error) => {

      });
  };

  return (
    <>
      <div id="myModal" className="modal" onClick={HandleCancel}>
        <div
          className="mini-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="logisticsRma-create-project-modal-title">
            <div className="editPayment">Edit Credit Note</div>
            <div onClick={HandleCancel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="42"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>

          <form
            className="form-container paymentForexApprovalinitiateForex"
            onSubmit={HandleSubmit}
          >
            <div className="form-card-container">
              <div className="form-card-information">
                <div className="card-center">
                  <div className="card-form">
                    <div className="input-labels">
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "30px" }}
                      >
                        Amount
                      </div>
                    </div>
                    <div className="forex-input-fields">
                      <div>
                        <input
                          required
                          type="text"
                          className="forex-select-option"
                          style={{ marginTop: "20px" }}
                          placeholder="Amount"
                          defaultValue={props?.previousData?.amount}
                          onChange={(event) =>
                            setAmountName(event.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="form-buttons clientForm"
                style={{ marginTop: "20px" }}
              >
                <button
                  type="cancel"
                  className="form-cancel clientCancel"
                  style={{ width: "112px", height: "32px" }}
                  onClick={HandleCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="form-save clientSave"
                  style={{ width: "112px", height: "32px" }}
                  onClick={HandleSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
