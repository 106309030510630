import React from "react";
import Button from "@mui/material/Button";

const ViewMoreButton = () => {
  return (
    <>
      <Button
        variant="contained"
        disableElevation
        size="medium"
        sx={{
          width: "105px",
          height: "37px",
          textTransform: "none",
          fontSize: "13px",
          fontWeight: "normal",
          backgroundColor: "#fff",
          color: "#186569",
          border: "1px solid #186569",
          "&:hover": {
            backgroundColor: "#186569",
            color: "#fff",
            border: "1px solid #fff",
          },
        }}
      >
        View More
      </Button>
    </>
  );
};

export default ViewMoreButton;
