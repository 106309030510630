import React from "react";
import PaymentTracking from "../../components/Sourcing/PaymentTracking";
import NavBar from "../../Layout/NavBar";
import SourcingIndex from "./SourcingIndex";

export default function PaymentTrackingindex() {
  return (
    <SourcingIndex>
      <div className="">
        <PaymentTracking />
      </div>
    </SourcingIndex>
  );
}
