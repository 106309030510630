import React, { useState } from "react";
import "../../../../components/Logistics/logistics.css";
import { useViewPaymentTrackingHistoryQuery } from "../../../../features/SCMApi";
import "../../../Payment/forex.style.css";
import "../../logistics.css";
import NoRecord from "../../../common/NoRecord";
import {
  useGetMissedItemStatusTrackingQuery,
  useGetRmaStatusTrackingQuery,
} from "../../../../features/SCMApi";

export default function StatusModal(props) {
  const { data: RmaStatusHistory } = useGetRmaStatusTrackingQuery(props?.rmaId);
  const { data: missedItemStatusHistory } = useGetMissedItemStatusTrackingQuery(
    props?.missedItemId
  );

  const historyData =
    props?.name === "MissedItemStatus"
      ? missedItemStatusHistory?.data
      : props?.name === "RmaStatus"
      ? RmaStatusHistory?.data
      : props?.name === "batchStatus"
      ? props?.data
      : "";


  function HandleCancel() {
    props.modal(false);
  }

  const statusStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "95%",
    marginTop: "10px",
    // minHeight: "10px",
    fontFamily: `Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif`,
    fontStyle: "normal",
    fontWeight: 450,
    fontSize: "14px",
    lineHeight: "15px",
    letterSpacing: "0.105em",
    color: "#353945",
    marginLeft: "10px",
  };
  const statusDetailContainer = {
    display: "flex",
    justifyContent: "space-between",
    margin: "0 16px 0 10px",
  };
  const statusDetailsContainer = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    minWidth: "28%",
  };
  const statusDetailsPersonContainer = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    minWidth: "45%",
    borderRadius: "5px",
  };
  const statusDateAndPersonDetail = {
    textAlign: "left",
    fontFamily: `Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif`,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "15px",
    letterSpacing: "0.105em",
    color: "#186569",
    margin: "16px 0 10px 0",
  };
  const statusDateDetail = {
    textAlign: "left",
    fontFamily: `Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif`,
    fontStyle: "normal",
    fontWeight: 450,
    fontSize: "14px",
    lineHeight: "15px",
    letterSpacing: "0.105em",
    color: "#aeb8c9",
    margin: "16px 0 10px 0",
  };
  const rmaMissedItemStatusHistoryDivider = {
    borderBottom: "1px solid #186569",
    margin: "0 -15px 0 -10px",
  };

  return (
    <>
      <div id="myModal" className="modal" onClick={HandleCancel}>
        <div
          className="mini-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="logisticsRma-create-project-modal-title">
            <div style={{ marginRight: "56%" }}>Status History</div>
            <div onClick={HandleCancel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="42"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>
          {historyData?.length !== 0 ? (
            <>
              <form className="paymentTrackingForm" onSubmit={""}>
                {historyData?.map((history) => (
                  <div className="rmaMissedItemStatusContainer">
                    <div
                      className="rma_missed_item_status_detail"
                      style={statusStyle}
                    >
                      <span style={{ margin: "10px" }}>
                        {history?.status && history?.status}
                      </span>
                      <div style={statusDetailContainer}>
                        <div style={statusDetailsPersonContainer}>
                          <span style={statusDateAndPersonDetail}>
                            {history?.user?.name}
                          </span>
                        </div>
                        <div style={statusDetailsContainer}>
                          <span style={statusDateDetail}>
                            {history?.created_at &&
                              history?.created_at?.substring(-2, 10)}
                          </span>
                        </div>
                      </div>
                      <div
                        className="rma_missed_item_status_history"
                        style={rmaMissedItemStatusHistoryDivider}
                      ></div>
                    </div>
                  </div>
                ))}
              </form>
            </>
          ) : (
            <NoRecord />
          )}
        </div>
      </div>
    </>
  );
}
