import Close from "@mui/icons-material/Close";
import React from "react";
import { useState } from "react";
import {
  useAddItemCategoryMutation,
  useAddPaymentTermMutation,
  useGetProjectsQuery,
  useGetUsersQuery,
} from "../../../features/SCMApi";
import "./modal.css";

export default function AddItemsCategory(props) {
  const [ItemCategory, res] = useAddItemCategoryMutation();
  const [category, setCategory] = useState(null);
  const [description, setDescription] = useState(null);
  let Category = {
    category,
    description,
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    ItemCategory(Category)
      .unwrap()
      .then((response) => {
        
        props.modal(false);
        props.setMessages("Payment Term created successfully");
      })
      .then((error) => {
        
      });
  };
  function HandleCancel() {
    props.modal(false);
  }

  return (
    <div className="pi-modal" onClick={HandleCancel}>
      <div className="pi-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="pi-modal-header">
          <div className="d-flex justify-content-between">
            <h5>Add Items Category</h5>
            <Close onClick={HandleCancel} />
          </div>
        </div>
        <hr />
        <div className="pi-modal-content">
          <form className="pi-form" onSubmit={HandleSubmit}>
            <div className="d-grid gap-3">
              <div className="d-flex gap-2 align-items-baseline">
                <div className="supplier-input-label">Name</div>
                <input
                  onChange={(e) => setCategory(e.target.value)}
                  type="text"
                  className={`stock-text-input`}
                  required
                />
              </div>
              <div className="d-flex gap-0 align-items-baseline">
                <div className="supplier-input-label">Description</div>
                <textarea
                  cols="30"
                  rows="5"
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="pi-modal-footer">
              <button onClick={HandleCancel}>Cancel</button>
              <button type="submit">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
