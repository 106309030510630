import React from "react";

import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import ForexSideBar from "../../../components/Payment/ForexApproval/PaymentSideBar";
import { useState } from "react";
import PaymentCollectionModal from "./PaymentCollectionModal";
import {
  useGetAllForexPaymentCollectionsQuery,
  useGetAllPaymentCollectionsQuery,
  useGetPaymentCollectionsQuery,
  useGetProjectQuery,
  useGetProjectsQuery,
} from "../../../features/SCMApi";
import NoRecord from "../../common/NoRecord";
import { useParams } from "react-router-dom";
import UpdatePaymentCollectionModal from "./UpdatePaymentCollections";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Pagination, Tooltip } from "@mui/material";
import usePagination from "../../Pagination/Pagination";
import AllprojectPaymentCollectionReport from "./DownloadReports/AllprojectPaymentCollectionReport";
import OnShore from "./OnShore";
import OffShore from "./OffShore";
import ForexCollectionReport from "./DownloadReports/ForexCollectionReport";
export default function AllPaymentCollection({ type }) {
  const params = useParams();
  const { data: paymentCollection } = useGetAllPaymentCollectionsQuery();
  const { data: forexPaymentCollection } =
    useGetAllForexPaymentCollectionsQuery();
  const { data: projects } = useGetProjectsQuery();

  let count = 0;
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState();

  function HandleAdd() {
    setAdd(true);
  }

  const [milestone, setMilestone] = useState("");
  const [status, setStatus] = useState("");
  const [project, setProject] = useState("");

  const filteredData = (project) => {
    return paymentCollection?.data
      ?.filter((items) => {
        if (project === "") {
          return items;
        } else if (items?.collection_project[0]?.id == project) {
          return items;
        }
      })
      ?.map((items) => items);
  };
  const filteredDataOffshore = (project) => {
    return forexPaymentCollection?.data
      ?.filter((items) => {
        if (project === "") {
          return items;
        } else if (items?.collection_project[0]?.id == project) {
          return items;
        }
      })
      ?.map((items) => items);
  };

  let PFO = "";
  let PM = "";

  project?.data?.users?.map((items) =>
    items?.role?.role_name == "Project Finance Officer"
      ? (PFO = items?.name)
      : ""
  );
  project?.data?.users?.map((items) =>
    items?.role?.role_name == "Project Manager" ? (PM = items?.name) : ""
  );
  const [filter, setFilter] = useState(false);
  function HandleFilter() {
    setFilter(!filter);
    setMilestone("");
    setStatus("");
    setProject("");
  }
  let [page, setPage] = useState(1);
  const PER_PAGE = 8;



  const [collected, setCollected] = useState(0);

  const CheckBoxHandelonShore = () => {
    setCollected(!collected);
  };
  const filteredforex = filteredDataOffshore(project)?.filter(
    (forexs) => forexs.is_collected == collected
  );
  const filteredPayment = filteredData(project)?.filter(
    (Payment) => Payment.is_collected == collected
  );


  const p_count = Math.ceil(filteredPayment?.length / PER_PAGE);
  const p_count_f = Math.ceil(filteredforex?.length / PER_PAGE);
  const _DATA = usePagination(filteredPayment, PER_PAGE);
  const _DATA_f = usePagination(filteredforex, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  const handleChangeForex = (e, p) => {
    setPage(p);
    _DATA_f.jump(p);
  };

  const { data: showproject } = useGetProjectQuery(project);
  let milestoneamount = showproject?.data?.milestone_amount;
  let milestonepush = [];
  for (let i = 1; i <= milestoneamount; i++) {
    milestonepush.push({
      id: i,
      name: `milestone ${i}`,
    });
  }



  let forexsum = 0;
  filteredforex?.map((items) => (forexsum += Number(items?.ci_value)));
  let paymentsum = 0;
  filteredData(project)?.map(
    (items) => (paymentsum += Number(items?.planned_amount))
  );
  let actualsum = 0;
  filteredPayment?.map((items) => (actualsum += Number(items?.actual_amount)));


  return (
    <>
      <div className="payment-collection-container">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            {" "}
            <div className="d-flex gap-1 py-4 px-2 align-items-center">
              {type === true ? (
                <AllprojectPaymentCollectionReport
                  data={filteredData(project)}
                />
              ) : (
                <ForexCollectionReport data={filteredforex} />
              )}

              {paymentCollection?.data?.length > 1 ? (
                <div className="d-flex gap-4  align-items-start">
                  {filter ? (
                    <Tooltip title="Filter On">
                      <FilterAltIcon
                        onClick={HandleFilter}
                        style={{ fontSize: "32px", color: "#064b4f" }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Filter Off">
                      <FilterAltOffIcon
                        onClick={HandleFilter}
                        style={{ fontSize: "32px", color: "#064b4f" }}
                      />
                    </Tooltip>
                  )}
                  {filter ? (
                    <>
                      <select
                        className="report-filter-selector"
                        onChange={(e) => setProject(e.target.value)}
                      >
                        <option value="" selected>
                          Project
                        </option>
                        {projects?.data?.map((items) => (
                          <option value={items?.id}>
                            {items?.project_name}
                          </option>
                        ))}
                      </select>
                    </>
                  ) : (
                    ""
                  )}{" "}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div>
            {/* {type == true ? (
              ""
            ) : ( */}
            <div className="d-flex justify-content-end mt-3 p-3">
              <div className="d-flex align-items-center gap-2  me-3">
                <button
                  value={0}
                  onClick={(e) => setCollected(0)}
                  className={
                    collected == 0
                      ? "report-module-select-active"
                      : "report-module-select"
                  }
                ></button>
                <label className="module-label">Uncollected</label>
              </div>
              <div className="d-flex align-items-center gap-2 me-3">
                <button
                  value={1}
                  onClick={(e) => setCollected(1)}
                  className={
                    collected == 1
                      ? "report-module-select-active"
                      : "report-module-select"
                  }
                >
                  {" "}
                </button>
                <label className="module-label">Collected</label>
              </div>
            </div>
            {/* )} */}
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="report-project-total">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="text-muted h5">
                {type == true ? "Total Planned Amount" : "Total value"}
              </div>
              <div className="h4">
                {" "}
                {type == true ? "ETB" : "$"}
                {type == true
                  ? paymentsum?.toLocaleString()
                  : forexsum?.toLocaleString()}
              </div>
            </div>
          </div>
          {type == true && collected == 1 ? (
            <div className="report-project-total">
              <div className="d-flex justify-content-between align-items-center ">
                <div className="text-muted h5">Total Actual Amount</div>
                <div className="h4"> ETB {actualsum?.toLocaleString()}</div>
              </div>
            </div>
          ) : (
            <div style={{ width: "100%" }}>
              <div className="prg"></div>
            </div>
          )}
        </div>

        {type === true ? (
          <OnShore
            _DATA={_DATA}
            p_count={p_count}
            page={page}
            handleChange={handleChange}
            all={true}
          />
        ) : (
          <OffShore
            _DATA={_DATA_f}
            p_count={p_count_f}
            page={page}
            handleChange={handleChangeForex}
            all={true}
          />
        )}
      </div>

      {add ? <PaymentCollectionModal all={true} modal={setAdd} /> : ""}
      {edit ? (
        <UpdatePaymentCollectionModal all={true} modal={setEdit} data={data} />
      ) : (
        ""
      )}
    </>
  );
}
