import React from "react";
import Grid from "@mui/material/Grid";
import "../logistics.css";
import { Box } from "@mui/material";
import DeliveredItemETD from "./DeliveredItemHeadingComponent/DeliveredItemETD";
import DeliveredItemProfile from "./DeliveredItemHeadingComponent/DeliveredItemProfile";
import DeliveredItemOrigin from "./DeliveredItemHeadingComponent/DeliveredItemOrigin";
import SuccessMessage from "../../Payment/SuccessMessage";

export default function DeliveredItemDetailsHeading(props) {
  const [successMessage, setSuccessMessage] = React.useState(false);
  const [message, setMessage] = React.useState("");
  successMessage &&
    successMessage &&
    setTimeout(() => {
      setSuccessMessage(false);
    }, 3000);
  return (
    <>
      <Box marginTop={"20px"}>
        <Grid container spacing={{ xs: 2, md: 2 }}>
          {successMessage === true ? (
            <>
              <SuccessMessage
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  width: "98.5%",
                  marginLeft: "17px",
                  backgroundColor: "#52ffab96",
                  mb: "-10px",
                }}
                Message={message}
              />
            </>
          ) : (
            <></>
          )}
          <DeliveredItemETD
            data={props?.data}
            setSuccessMessage={setSuccessMessage}
            setMessage={setMessage}
          />
          <DeliveredItemProfile
            data={props?.data?.project?.project_participants}
          />
          <DeliveredItemOrigin data={props?.data?.batch_origin?.name} />
        </Grid>
      </Box>
    </>
  );
}
