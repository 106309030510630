import React from "react";
import { NavLink } from "react-router-dom";
import PaymentApproval from "../../../assets/SVG/paymentApproval.png";
import ForexApproval from "../../../assets/SVG/forex.png";
import { IoMdGitPullRequest } from "react-icons/io";
import { GiReturnArrow } from "react-icons/gi";
import { MdStore } from "react-icons/md";
export default function AssetsRequestMenu() {
  return (
    <div className="supplier-menu-container mt-3">
      {/* <NavLink to={"requested/assets"} className="payment-menu-card">
        <div className="text-center">
          <IoMdGitPullRequest
            style={{ width: 80, height: 80, color: "#186569" }}
          />
        </div>
        <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
          Requested Assets
        </span>
      </NavLink>
      <NavLink to={"returned/assets"} className="payment-menu-card">
        <div className="text-center">
          <GiReturnArrow style={{ width: 80, height: 80, color: "#186569" }} />
        </div>
        <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
          Returned Assets
        </span>
      </NavLink> */}
      <NavLink to={"storeTransferAsset"} className="payment-menu-card">
        <div className="text-center">
          <MdStore style={{ width: 80, height: 80, color: "#186569" }} />
        </div>
        <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
          Store Transfer{" "}
        </span>
      </NavLink>
    </div>
  );
}
