import React, { useState } from "react";
import "./modal.css";
import {
  useGetRolesQuery,
  useUpdateApprovalModuleMutation,
} from "../../../features/SCMApi";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { API_BASE_URL } from "../../../api/endPoint";
import axios from "axios";

export default function AddPaModal(props) {
  const { data: roles } = useGetRolesQuery();
  let unselectedRole = [];
  props?.items?.map(
    (items) =>
      (unselectedRole = roles?.data?.filter(
        (role) => role?.id === items?.role?.id
      ))
  );
  const [selectedRoles, setSelectedRoles] = useState([
    { level: props?.items?.length + 1, role_id: "" },
  ]);

  const addRoleSelect = () => {
    setSelectedRoles([...selectedRoles, { level: "", role_id: "" }]);
  };

  const handleRoleChange = (index, role) => {
    const updatedRoles = [...selectedRoles];
    updatedRoles[index].role_id = role;
    setSelectedRoles(updatedRoles);
  };

  const handleInputChange = (index, input) => {
    const updatedRoles = [...selectedRoles];
    updatedRoles[index].level = Number(input);
    setSelectedRoles(updatedRoles);
  };

  const removeRoleSelect = (index) => {
    const updatedRoles = [...selectedRoles];
    updatedRoles.splice(index, 1);
    setSelectedRoles(updatedRoles);
  };
  const [update] = useUpdateApprovalModuleMutation();
  let params = {
    id: props?.id,
    approval_module: props?.type,
    data: props?.data?.approval_stages
      ? {
          approval_stages: [...props?.data?.approval_stages, ...selectedRoles],
        }
      : {},
  };
  function HandleSubmit(e) {
    e.preventDefault();

    axios
      .post(
        `${API_BASE_URL}/scm/approval_modules/${params?.approval_module}/${params?.id}`,
        params?.data,
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      )
      .then((response) => {
        
        props?.setMessage("Updated successfully!!!");
      })
      .catch(function (err) {
        
      });
  }
  
  if (!props.showPa) {
    return null;
  }

  return (
    <div className="pi-modal" onClick={props.onClose}>
      <div className="pi-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="pi-modal-header">
          <h3>Add New Validator</h3>
        </div>
        <hr />
        <div className="pi-modal-content">
          <form className="pi-form">
            <div className="pi-inputs">
              {selectedRoles.map((selectedRole, index) => (
                <section className="pi-inputs-modal" key={index}>
                  <label>level</label>

                  <input
                    style={{
                      width: "20%",
                      background: "#ececec",
                      border: "1px solid #ececec",
                      borderRadius: "6px",
                      outline: "none",
                      height: "30px",
                      fontWeight: 400,
                      color: "#353945",
                    }}
                    type="text"
                    value={selectedRole?.level}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                  />
                  <label>Roles</label>
                  <select
                    required
                    style={{
                      width: "100%",
                      background: "#ececec",
                      border: "1px solid #ececec",
                      borderRadius: "6px",
                      outline: "none",
                      height: "30px",
                      fontWeight: 400,
                      color: "#353945",
                    }}
                    className="select-option"
                    value={selectedRole.role}
                    onChange={(e) => handleRoleChange(index, e.target.value)}
                  >
                    <option value="" disabled>
                      Select Role
                    </option>
                    {roles?.data?.map((items) => (
                      <option key={items.id} value={items.id}>
                        {items?.role_name}
                      </option>
                    ))}
                  </select>

                  <div
                    className="pi-footer-icon"
                    onClick={() => removeRoleSelect(index)}
                  >
                    <RemoveIcon />
                  </div>
                </section>
              ))}
              <div className="pi-footer-icon" onClick={addRoleSelect}>
                {" "}
                <AddIcon />
              </div>
            </div>
            <div className="pi-modal-footer">
              <button onClick={props.onClose}>Cancel</button>
              <button onClick={(e) => HandleSubmit(e)}>Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
