// import Close from "@mui/icons-material/Close";
// import React from "react";
// import { useState } from "react";
// import {
//   useAssetsAcceptsBatchMutation,
//   useGetUserInfoQuery,
//   useInventoryAcceptsBatchMutation,
//   useInventorysAcceptsBatcMutation,
// } from "../../../features/SCMApi";
// import { IMG_BASE_URL } from "../../../api/endPoint";
// import ErrorMessage from "../../Payment/ErrorMessage";
// import { useParams } from "react-router-dom";

// export default function BatchReject(props) {
//   function HandleCancel() {
//     props.modal(false);

//   }

//   const filter_data = props?.data?.filter((item) => item?.returned_amount > 0);


//   const [confirmRequestItems, setConfirmRequestItems] = useState(props?.data);
//   const [loading, setLoading] = useState(false);
//   const [formData, setFormData] = useState(filter_data);
//   function handleBatchRequest(e, items, i) {
//     e.preventDefault();
//     const value = e.target.value;
//     const tools = [...formData];
//     tools[i] = { ...items, remark: value, is_returned: true };
//     setFormData(tools);
//   }


//   const { data: userinfo } = useGetUserInfoQuery();
//   const params = useParams();
//   let batchAccepts = {
//     id: params?.id,
//     // accepted_items: JSON.stringify(formData),
//     // accepted_by: userinfo?.id,
//   };
//   const [ToolsAccept, res] = useInventorysAcceptsBatcMutation();
//   const [AssetsAccept] = useAssetsAcceptsBatchMutation();
//   const [ToolsItems] = useInventoryAcceptsBatchMutation();
//   const [errordisplay, setErrorDisplay] = useState(false);
//   const [error, setError] = useState();


//   const HandleSubmit = async (e) => {


//     setLoading(true);
//     e.preventDefault();
//     props?.is_item == true
//       ? ToolsAccept(batchAccepts)
//           .unwrap()
//           .then((response) => {

//             props.modal(false);
//             setLoading(true);
//           })
//           .catch((error) => {
//             setError(error?.data?.message);
//             setErrorDisplay(true);

//             setLoading(false);

//           })
//       : ToolsItems(batchAccepts)
//           .unwrap()
//           .then((response) => {

//             props.modal(false);
//             setLoading(true);
//           })
//           .catch((error) => {
//             setError(error?.data?.message);
//             setErrorDisplay(true);

//             setLoading(false);

//           });
//   };
//   const HandleSubmitAssets = async (e) => {
//     setLoading(true);
//     e.preventDefault();
//     AssetsAccept(batchAccepts)
//       .unwrap()
//       .then((response) => {

//         props.modal(false);
//         setLoading(true);
//       })
//       .catch((error) => {
//         setError(error?.data?.message);
//         setErrorDisplay(true);

//         setLoading(false);

//       });
//   };

//   let count = 0;

//   setTimeout(() => {
//     setErrorDisplay(false);
//   }, 10000);

//   return (
//     <div id="mymodal" className="modal" onClick={() => HandleCancel()}>
//       <div className="modal-content-md" onClick={(e) => e.stopPropagation()}>
//         <div className="create-project-modal-title">
//           <h5>Batch Accept </h5>
//           <Close onClick={HandleCancel} />
//         </div>

//         {errordisplay ? <ErrorMessage Message={error} /> : ""}

//         <div className="pi-modal-footer d-flex justify-content-center">
//           <button onClick={HandleCancel}>Cancel</button>
//           <button
//             disabled={formData?.length == 0}
//             type="submit"
//             className={
//               formData?.length >= 1
//                 ? "form-save "
//                 : loading
//                   ? "form-save bg-secondary text-white"
//                   : "form-save"
//             }
//             class={loading ? "form-save bg-secondary text-white" : "form-save"}
//             onClick={(e) =>
//               !props?.isAssets ? HandleSubmit(e) : HandleSubmitAssets(e)
//             }
//           >
//             {loading ? "Loading..." : "Return"}
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// }

import { FaCheck } from "react-icons/fa";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  useAssetsRejectBatchMutation,
  useInventoryRejectBatchMutation,
  useToolsRejectBatchMutation,
} from "../../../features/SCMApi";
import ErrorMessage from "../../Payment/ErrorMessage";

export default function BatchReject(props) {
  const [ItemReject, res] = useInventoryRejectBatchMutation();
  const [AssetsReject] = useAssetsRejectBatchMutation();
  const [ToolsReject] = useToolsRejectBatchMutation();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [error, setError] = useState();

  const [errordisplay, setErrorDisplay] = useState(false);
  const params = useParams();

  let batchAccepts = {
    id: params?.id,
  };
  const handleConfirm = () => {
    setShowModal(true);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    props?.is_item == true
      ? ItemReject(batchAccepts)
          .unwrap()
          .then((response) => {
            
            props.modal(false);
            setRejected(true);
            setLoading(true);
            handleCancel();
          })
          .catch((error) => {
            setError(error?.data?.message);
            setErrorDisplay(true);
            setLoading(false);
            
          })
      : ToolsReject(batchAccepts)
          .unwrap()
          .then((response) => {
            
            props.modal(false);
            setRejected(true);
            setLoading(true);
            handleCancel();
          })
          .catch((error) => {
            setError(error?.data?.message);
            setErrorDisplay(true);
            setLoading(false);
           
          });
  };
  const HandleSubmitAssets = async (e) => {
    setLoading(true);
    e.preventDefault();
    AssetsReject(batchAccepts)
      .unwrap()
      .then((response) => {
        
        props.modal(false);
        setRejected(true);
        setLoading(true);
        handleCancel();
      })
      .catch((error) => {
        setError(error?.data?.message);
        setErrorDisplay(true);
        setLoading(false);
        
      });
  };
  return (
    <div>
      <button
        class={"form-save bg-secondary text-white"}
        onClick={handleConfirm}
      >
        {rejected ? "Rejected" : loading ? "Loading..." : "Reject"}{" "}
      </button>
      {showModal && (
        <div className="modal-container">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content " style={{ minHeight: "0" }}>
              <div class="modal-header">
                <h5 className="modal-title">Confirm reject?</h5>
                <button className="btn-close" onClick={handleCancel} />
              </div>
              <div> {errordisplay ? <ErrorMessage Message={error} /> : ""}</div>

              <div className="modal-body">
                <p>Are you sure to reject this Items ?</p>
                <button
                  className="form-save"
                  onClick={(e) =>
                    !props?.isAssets ? handleSubmit(e) : HandleSubmitAssets(e)
                  }
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
