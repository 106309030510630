import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

function usePagination(data, itemsPerPage) {
  const navigate = useNavigate();
  const location = useLocation();

  const getPageFromUrl = () => {
    const params = new URLSearchParams(location.search);
    const page = parseInt(params.get("page"), 10);
    return isNaN(page) ? 1 : page;
  };

  const [currentPage, setCurrentPage] = useState(getPageFromUrl());
  const maxPage = Math.ceil(data?.length / itemsPerPage);

  // useEffect(() => {
  //   const pageFromUrl = getPageFromUrl();
  //   if (currentPage !== pageFromUrl) {
  //     setCurrentPage(pageFromUrl);
  //   }
  // }, [location.search]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (currentPage > 1) {
      params.set("page", currentPage);
    } else {
      params.delete("page");
    }
    navigate({ search: params.toString() }, { replace: true });
  }, [currentPage, navigate]);

  function currentData() {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return data?.slice(begin, end);
  }

  function next() {
    setCurrentPage((page) => Math.min(page + 1, maxPage));
  }

  function prev() {
    setCurrentPage((page) => Math.max(page - 1, 1));
  }

  function jump(page) {
    const pageNumber = Math.max(1, page);
    setCurrentPage(Math.min(pageNumber, maxPage));
  }

  return { next, prev, jump, currentData, currentPage, maxPage };
}

export default usePagination;
