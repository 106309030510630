import { Pagination } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import CheckProjectPermission from "../../../components/common/checkPermission/CheckProjectPermission";
import CreateBatchModal from "../../../components/common/CreateBatchModal";
import BankSubmission from "../../../components/Logistics/BankSubmission";
import BatchCard from "../../../components/Logistics/BatchCard";
import CustomClearance from "../../../components/Logistics/CustomClearance";
import usePagination from "../../../components/Pagination/Pagination";
import {
  useGetBatchesQuery,
  useGetProjectQuery,
  useGetProjectRolePermissionQuery,
  useGetProjectsQuery,
  useGetUserInfoQuery,
} from "../../../features/SCMApi";
import NavBar from "../../../Layout/NavBar";
import EditPaymentModal from "./EditPaymentModal";
import Index from "./Index";
export default function Batches() {
  const [createBatchModal, setCreateBatchModal] = useState(false);
  const [editPaymentModal, setEditPaymentModal] = useState(false);
  const [bankSubmissionModal, setBankSubmissionModal] = useState(false);
  const { ProjectId } = useParams();
  const { data: batches } = useGetBatchesQuery(ProjectId);

  const { data: project } = useGetProjectsQuery();

  const selectedProject = [];
  selectedProject.push(
    project?.data?.filter((project) => project?.id == ProjectId)
  );

  const [search, setSearch] = useState("");

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const { data: projectmembers, isSuccess } = useGetProjectQuery(ProjectId);
  const { data: roleRoles } = useGetProjectRolePermissionQuery(ProjectId);
  const { data: userinfo } = useGetUserInfoQuery();
  const [items, setItems] = useState(batches?.data);


  let [page, setPage] = React.useState(1);
  const PER_PAGE = 6;
  const count = Math.ceil(batches?.data?.length / PER_PAGE);
  const _DATA = usePagination(batches?.data, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <Index
      title={"Batches"}
      name={selectedProject[0]?.[0]?.project_name}
      navlink={`/logistics/batches/${ProjectId}`}
      handleSearchChange={handleSearchChange}
      placeholder={"search Batch..."}
    >
      <>
        {editPaymentModal ? (
          <EditPaymentModal ToggleModal={setEditPaymentModal} />
        ) : (
          <></>
        )}
        {createBatchModal ? (
          <CreateBatchModal ToggleModal={setCreateBatchModal} />
        ) : (
          <></>
        )}

        {bankSubmissionModal ? (
          <BankSubmission ToggleModal={setBankSubmissionModal} />
        ) : (
          <></>
        )}
        {editPaymentModal ? (
          <EditPaymentModal ToggleModal={setEditPaymentModal} />
        ) : (
          <></>
        )}
        {CheckProjectPermission(
          "create_batch",
          projectmembers,
          userinfo,
          roleRoles
        ) ? (
          <div
            className="create-project-btn mt-4"
            onClick={(e) => setCreateBatchModal(true)}
          >
            Create Batch
          </div>
        ) : (
          ""
        )}
        <div className="mt-4 logistics-batch-container ">
          {_DATA
            ?.currentData()
            ?.filter((items) => {
              if (search === "") {
                return items;
              } else if (items.batch_name?.toLowerCase().includes(search)) {
                return items;
              }
            })
            ?.map((items) => (
              <BatchCard batch={items} />
            ))}
        </div>
        <div className="faq-pagination-container">
          {count > 1 ? (
            <Pagination
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                margin: "5px",
              }}
            />
          ) : (
            ""
          )}
        </div>
      </>
    </Index>
  );
}
