import axios from "axios";
import React, { useState } from "react";
import { API_BASE_URL } from "../../api/endPoint";
import {
  useEditSolutionMutation,
  useEditSubSolutionMutation,
} from "../../features/SCMApi";

export default function EditSolution(props) {
  const [department_name, setSubSolution] = useState(
    props?.params?.department_name
  );
  const [abbrivation, setAbbreviation] = useState(props?.params?.abbrivation);
  const [id, setId] = useState(props?.params?.id);
  const [editSolution] = useEditSolutionMutation();

  let solution = {
    id: props?.params?.id,
    department_name,
    abbrivation,
    department_pmr_id: props?.params?.department_pmr_id,
  };

  function handleSubmit() {
    editSolution(solution)
      .unwrap()
      .then((response) => {

        props?.ToggleModal(false);
        props?.ToggleParentModal(false);
        props?.setSuccessMessage(true);
        props?.setMessage("Solution updated successfully");
        // window.location.reload();
      })
      .then((error) => {

      });
    // axios({
    //   method: "post",
    //   url: `${API_BASE_URL}/scm/product/solutions-update/${id}`,
    //   data: solution,
    //   headers: { "Content-Type": "application/json" },
    // })
    //   .then(function (response) {
    //     props?.ToggleModal(false);
    //     props?.ToggleParentModal(false);
    //     props?.setSuccessMessage(true);
    //     props?.setMessage("Solution updated successfully");
    //     window.location.reload(true);

    //   })
    //   .catch(function (response) {
    //     //handle error

    //   });
  }

  return (
    <>
      <div
        id="Modal"
        className="edit-mini-modal"
        onClick={(e) => {
          props?.ToggleModal(false);

        }}
      >
        <div
          className="edit-mini-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="edit-dialog-info">Edit Solution</div>
          <div className="edit-modal-divider"></div>
          <div className="row gap-3">
            <div className="d-flex gap-3">
              <div className="">
                <div className="edit-input-label mb-1">Solution Name</div>
                <input
                  onChange={(e) => setSubSolution(e.target.value)}
                  defaultValue={department_name}
                  type="text"
                  className="select-option"
                />
              </div>
              <div className="">
                <div className="edit-input-label mb-1">Abbreviation</div>
                <input
                  onChange={(e) => setAbbreviation(e.target.value)}
                  defaultValue={abbrivation}
                  type="text"
                  className="select-option"
                />
              </div>
            </div>
            <div className="confirm-dialog-controller justify-content-center">
              <button
                className="form-cancel"
                onClick={(e) => {
                  props?.ToggleModal(false);
                }}
              >
                Cancel
              </button>
              <button
                className="form-save"
                type={"submit"}
                onClick={(e) => {
                  handleSubmit();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
