import React, { useEffect } from "react";
import { useState } from "react";
import { API_BASE_URL, IMG_BASE_URL } from "../../../api/endPoint";
import {
  useGetInventoryQuery,
  useGetInventorysRequestsQuery,
  useGetToolsQuery,
  useGetUserInfoQuery,
  useInStoreItemsRequestMutation,
  useItemsAcceptMutation,
  useItemsInventoryAcceptMutation,
  useItemsInventoryConfirmMutation,
  useGetApproverQuery,
  useApprovalApproveMutation,
  useApprovalRejectMutation,
  useGetItemCategorysQuery,
  useGetStoreQuery,
  useGetAssetsRequestsQuery,
  useAssetsCancelMutation,
} from "../../../features/SCMApi";
import NoRecord from "../../common/NoRecord";
import StockDialog from "../../common/StockDialog";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import axios from "axios";
import InventoryApprovalModal from "../../common/InventoryApprovalModal";
import InventoryApprovalRejectModal from "../../common/InventoryApprovalRejectModal";
import { IconButton, Pagination } from "@mui/material";
import { NavLink } from "react-router-dom";
import ArrowCircleRightTwoToneIcon from "@mui/icons-material/ArrowCircleRightTwoTone";
import usePagination from "../../Pagination/Pagination";
import BatchItems from "../../../assets/SVG/Batchitems.png";
import DispatchedTools from "../DownloadReports/DispatchedTools";
import ReturnedTools from "../DownloadReports/ReturnedTools";
import StockIndex from "../../../pages/Stocks/StockIndex";
import { useLocation } from "react-router-dom";

export default function InStoreAssets({ inventory }) {
  const [isBatch, setIsBatch] = useState(0);

  const { data: RequestedAsset } = useGetAssetsRequestsQuery();

  const { data: loggedUser } = useGetUserInfoQuery();
  let notRequest = [];
  RequestedAsset?.data?.map((items) =>
    (items.is_requested == 1 && items?.is_confirmed == 0) ||
    items?.is_returned == 1
      ? notRequest.push(items)
      : ""
  );
  const batchDatas =
    isBatch == 0
      ? notRequest?.filter((items) => items?.is_batch == 0)
      : isBatch == 1
        ? notRequest?.filter((items) => items?.is_batch == 1)
        : "";
  const userdata = batchDatas?.filter(
    (items) =>
      items?.store_keeper_id == loggedUser?.id ||
      items?.requester_id == loggedUser?.id ||
      items?.project_members?.some(
        (member) => member?.user_id == loggedUser?.id
      ) ||
      loggedUser?.role?.role_name == "Inventory Manager" ||
      loggedUser?.role?.role_name == "Super Admin"
  );
  const [search, setSearch] = useState("");
  const [store_id, setStore_id] = useState("");
  const [category, setCategory] = useState("");
  const { data: store } = useGetStoreQuery();
  const { data: ItemCategory } = useGetItemCategorysQuery();
  const [cancle, setCancle] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState();
  const [error, setError] = useState();
  const [dialog, setDialog] = useState(false);
  const [AssetCancel, src] = useAssetsCancelMutation();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  function HandleCancle(e) {
    setCancle(true);
    setSelectedProjectId(e);
  }
  function HandleAssetCancel(e, dialog) {
    let requested = {
      requested_asset_id: selectedProjectId,
    };

    AssetCancel(requested)
      .unwrap()
      .then((response) => {
        setCancle(false);
      })
      .catch((error) => {
        setError(error?.data?.message);
      });
  }
  const filteredData = (search, category, store_id) => {
    return userdata
      ?.filter((items) => {
        if (search == "") {
          return items;
        } else if (
          items.item_name?.toLowerCase().includes(search.toLowerCase()) ||
          items.model?.toLowerCase().includes(search.toLowerCase()) ||
          items.serial_number?.toLowerCase().includes(search.toLowerCase())
        ) {
          return items;
        }
      })
      ?.filter((items) => {
        if (category === "") {
          return items;
        } else if (items?.requested_date?.substring(0, 10) == category) {
          return items;
        }
      })
      ?.filter((items) => {
        if (store_id === "") {
          return items;
        } else if (items?.store_id == store_id) {
          return items;
        }
      })
      ?.filter((newArrayData) => {
        if (startDate === "") {
          return newArrayData;
        } else if (
          newArrayData?.requested_date?.substring(0, 10) >= startDate
        ) {
          return newArrayData;
        }
      })
      ?.filter((newArrayData) => {
        if (endDate === "") {
          return newArrayData;
        } else if (newArrayData?.requested_date?.substring(0, 10) <= endDate) {
          return newArrayData;
        }
      })
      .map((newArrayData) => newArrayData);
  };
  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pagenum = parseInt(queryParams.get("page"), 10);
  useEffect(() => {
    if (pagenum) {
      setPage(pagenum);
    }
  }, [pagenum]);

  const count = Math.ceil(
    filteredData(search, category, store_id)?.length / PER_PAGE
  );
  const _DATA = usePagination(
    filteredData(search, category, store_id),
    PER_PAGE
  );
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <div>
      <StockIndex>
        <div className="tool-header-container d-flex gap-3 justify-content-center">
          <div className="d-flex justify-content-end mt-3 p-3">
            <div className="d-flex align-items-center gap-2  me-3">
              <button
                value={0}
                onClick={(e) => setIsBatch(0)}
                className={
                  isBatch == 0
                    ? "report-module-select-active"
                    : "report-module-select"
                }
              ></button>
              <label className="module-label">Single</label>
            </div>
            <div className="d-flex align-items-center gap-2 me-3">
              <button
                value={1}
                onClick={(e) => setIsBatch(1)}
                className={
                  isBatch == 1
                    ? "report-module-select-active"
                    : "report-module-select"
                }
              >
                {" "}
              </button>
              <label className="module-label">Batch </label>
            </div>
          </div>
          <input
            onChange={handleSearchChange}
            placeholder="search"
            type="search"
            className="inventory-search-input"
          />
          <select
            type="search"
            className="inventory-search-input"
            onChange={(e) => setStore_id(e.target.value)}
          >
            <option value="">Store</option>
            {store?.data?.map((items) => (
              <option value={items?.id}>{items?.store_name}</option>
            ))}
          </select>
          <span>Start Date</span>
          <input
            type="date"
            className="inventory-search-input"
            onChange={(e) => setStartDate(e.target.value)}
          />{" "}
          <span>End Date</span>
          <input
            type="date"
            className="inventory-search-input"
            onChange={(e) => setEndDate(e.target.value)}
          />
          {/* <select
          type="search"
          className="inventory-search-input"
          onChange={(e) => setCategory(e.target.value)}
        >
          <option value="">Item Group</option>
          {ItemCategory?.data?.map((items) => (
            <option value={items?.id}>{items?.category}</option>
          ))}
        </select> */}
          <div className="d-grid gap-2 justify-content-center mb-1">
            <DispatchedTools items={true} />
            <span className="prg-pri">Dispatched Items</span>
          </div>
          {/* <div className="d-grid gap-2 mb-1  justify-content-center">
          <ReturnedTools items={true} />
          <span className="prg-pri">Returned Items</span>
        </div> */}
        </div>
        {_DATA?.currentData() !== 0 ? (
          <>
            <table style={{ width: "100%" }}>
              <thead className="stock-table-header">
                <tr>
                  <th style={{ width: "10%" }}></th>
                  <th style={{ width: "10%" }}>Item Name</th>
                  <th style={{ width: "10%" }}>Model</th>
                  <th style={{ width: "10%" }}>Type</th>
                  <th style={{ width: "10%" }}>Store</th>
                  <th style={{ width: "10%" }}>Project</th>
                  <th style={{ width: "10%" }}>Status</th>
                  <th style={{ width: "10%" }}>Requester</th>
                  <th style={{ width: "10%" }}>Requested Quantity</th>
                  <th style={{ width: "10%" }}>Approval status </th>
                  <th style={{ width: "15%" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {_DATA?.currentData()?.map((items) => (
                  <tr className="stock-table-row">
                    <td className="">
                      {items?.image != null ? (
                        <img
                          className="stock-img"
                          src={`${IMG_BASE_URL}${items?.image}`}
                          alt=""
                        />
                      ) : (
                        <img
                          sx={{ width: 50, height: 50, borderRadius: 0 }}
                          src={BatchItems}
                          alt=""
                        />
                      )}
                    </td>
                    <td className="text-start">
                      {items?.is_batch == 1
                        ? "Batch requests"
                        : items?.item_name}
                      {/* {items?.item_name} */}
                    </td>
                    <td className="text-start">{items?.model}</td>
                    <td className="text-start">
                      {items?.inventory_type_id === null
                        ? "-"
                        : items?.inventory_type}
                    </td>
                    <td className="text-start">
                      {items?.store_name === null ? "-" : items?.store_name}
                    </td>
                    <td className="text-start">
                      {items?.requested_project_name === null
                        ? "-"
                        : items?.requested_project_name}
                    </td>
                    <td className="text-start">
                      {items?.status_name === null ? "-" : items?.status_name}
                    </td>
                    <td className="text-start">{items?.requester_name}</td>
                    <td className="text-start">{items?.requested_quantity}</td>

                    <td className="text-start">
                      {items?.is_approved == 0
                        ? "Pending"
                        : items?.is_approved == 1
                          ? "Approved"
                          : "Rejected"}
                    </td>
                    <td className="text-start">
                      <div className="mb-4">
                        {items?.requester_id == loggedUser?.id &&
                        items?.is_requester_received == 0 ? (
                          <button
                            // disabled={selectedItems?.length <= 0 ? true : false}
                            className={"form-save bg-danger text-white"}
                            onClick={() => {
                              HandleCancle(items?.id);
                            }}
                          >
                            cancel request
                          </button>
                        ) : (
                          ""
                        )}
                      </div>{" "}
                      <div className="mt-2">
                        {items?.is_batch == 1 ? (
                          <NavLink
                            className="form-save p-2"
                            to={`/stocks/requestbatchasset/${items?.id}`}
                          >
                            View More
                          </NavLink>
                        ) : (
                          <NavLink to={`/stocks/requests/assets/${items?.id}`}>
                            <ArrowCircleRightTwoToneIcon
                              sx={{ color: "#186569" }}
                            />
                          </NavLink>
                        )}{" "}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : (
          <NoRecord />
        )}
        {count > 1 ? (
          <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            className="p-2"
          />
        ) : (
          ""
        )}
      </StockIndex>
      {cancle ? (
        <StockDialog
          project_id={1}
          setDialog={setCancle}
          data={dialog}
          error={error}
          name={"Cancle"}
          action={(e) => HandleAssetCancel(e, dialog)}
        />
      ) : (
        ""
      )}{" "}
    </div>
  );
}
