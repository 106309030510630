import React from "react";

export default function EditSolution() {
  return (
    <div>
      {" "}
      <div id="myModal" className="mini-modal">
        <div className="mini-modal-content">
          <div className="create-project-modal-title">Edit Solution</div>
          <div className="add-solution-form">
            <div className="form-container">
              <div className="inputs-group">
                <div class="form-buttons">
                  <button
                    type="cancel"
                    class="form-cancel"
                    // onClick={handelClose}
                  >
                    {" "}
                    Cancel
                  </button>
                  <button
                    type="submit"
                    class="form-save"
                    // onClick={handleSubmit}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
