import { Button, Modal } from "@mui/material";
import React, { useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { NavLink } from "react-bootstrap";
import { useParams } from "react-router-dom";

const SuccessModal = ({ setOpen, open, isOrder }) => {
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const params = useParams();
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description"
      >
        <div
          style={{
            backgroundColor: "#fff",
            padding: " 35px",
            textAlign: "center",
          }}
        >
          <CheckCircleIcon
            style={{
              fontSize: 100,
              marginBottom: "10px",
              color: "#064b4f",
            }}
          />
          <h2 id="success-modal-title">Success!</h2>
          <p id="success-modal-description">
            Your Price has been Compared successfully.
          </p>
          {isOrder == true ? (
            ""
          ) : (
            <div className="mb-2">
              <strong>NB:</strong>{" "}
              <span className="prg text-danger">
                Don't forget to send for Technical Approval
              </span>
            </div>
          )}
          <a
            href={`/sourcing/orders/${params?.id}`}
            className="price-comparison"
          >
            Ok
          </a>
        </div>
      </Modal>
    </>
  );
};

export default SuccessModal;
