import React, { useState } from "react";
import { useEditSubSolutionMutation } from "../../features/SCMApi";

export default function EditSubSolutionModal(props) {
  const [solution_name, setSubSolution] = useState(
    props?.params?.solution_name
  );
  const [department_id, setDepartment] = useState(props?.params?.department_id);
  const [id, setId] = useState(props?.params?.id);

  const [editSolution] = useEditSubSolutionMutation();


  function handleSubmit() {

    editSolution({ solution_name, department_id, id })
      .unwrap()
      .then((response) => {

        props?.ToggleModal(false);
        props?.ToggleParentModal(false);
        props?.setSuccessMessage(true);
        props?.setMessage("sub solution updated successfully");
        // window.location.reload();
      })
      .then((error) => {
      
      });
  }
  return (
    <>
      <div
        id="Modal"
        className="edit-mini-modal"
        onClick={(e) => {
          props?.ToggleModal(false);
          
        }}
      >
        <div
          className="edit-mini-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="edit-dialog-info">Edit Sub Solution</div>
          <div className="edit-modal-divider"></div>
          <div className="row gap-3 centered-content-column">
            <div className="col-12  ">
              <div className="">
                <div className="edit-input-label mb-1">SubSolution</div>
                <input
                  onChange={(e) => setSubSolution(e.target.value)}
                  defaultValue={solution_name}
                  type="text"
                  className="select-option"
                />
              </div>
            </div>
            <div className="confirm-dialog-controller">
              <button
                className="form-cancel"
                onClick={(e) => {
                  props?.ToggleModal(false);
                }}
              >
                Cancel
              </button>
              <button
                className="form-save"
                type={"submit"}
                onClick={(e) => {
                  handleSubmit();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
