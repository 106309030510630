import React from "react";

export default function RmaBatchCards(props) {
  
  return (
    <>
      <div className=" batch-detail-card">
        <div className="batch-detail-card-sub">
          <div className="batch-card-left-grid">
            <div className="batch-container">
              <div className="missed-item-logistics-batch-name">
                {props?.data && props?.data?.rma_name}
              </div>
            </div>
            <div className="missed-item-origin-group">
              <div className="origin-value">
                {props?.data && props?.data?.batch?.batch_origin?.name}
              </div>
              <div className="origin-label">Origin</div>
            </div>
          </div>

          <div className="logistics-batch-items-container">
            <div className="missed-items-batch-item">
              <div
                className="logistics-batch-value"
                style={{ marginLeft: "10%" }}
              >
                {props?.data && props?.data?.items?.length}
              </div>
              <div className="logistics-batch-label">Item</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
