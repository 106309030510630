import { Pagination } from "@mui/material";
import React, { useState } from "react";
import {
  useGetAllBatchesQuery,
  useGetAllOrdersQuery,
  useGetForexQuery,
  useGetNoBoqQuery,
  useGetOrderItemQuery,
  useGetPaymentTrackingForProjectReportQuery,
  useGetSuppliersQuery,
} from "../../features/SCMApi";
import usePagination from "../Pagination/Pagination";
import ExcelJs from "exceljs";
import DownloadReport from "./DownloadReport";
import AllSctsReport from "./AllSCTSReport";
import NoRecord from "../common/NoRecord";
import MultiSelect from "../common/MultiSelect";

export default function SourcingReport({
  modal,
  project_id,
  country_id,
  supplier_id,
  profit,
  project,
  stage,
  module,
}) {
  function HandleModal() {
    modal(true);
  }

  const { data: orderDetail } = useGetAllOrdersQuery();
  const { data: suppliers } = useGetSuppliersQuery();
  const { data: NoBoqorders } = useGetNoBoqQuery();
  const { data: PaymentTrackingProject } =
    useGetPaymentTrackingForProjectReportQuery();
  const { data: Batch } = useGetAllBatchesQuery();
  const { data: forex } = useGetForexQuery();
  const orderReport = orderDetail?.data?.pa;
  const manufacturers = [];
  const data = [];
  const po = NoBoqorders?.data.map((items) => items.po);

  const [silcon, setSilcon] = useState(false);
  const [redington, setRedington] = useState(false);
  function HanldeSilconetworks() {
    setSilcon(!silcon);
  }
  function HanldeRedington() {
    setRedington(!redington);
  }
  let silconFZE = silcon == true ? "8b93d1fd-9406-4c9c-99f7-0995b87481c1" : "";
  let redingtonFilter =
    redington == true ? "7c67f045-cd13-4667-a254-f3db7dd4c4d5" : "";
  const [withOut, setWithOut] = useState([]);
  
  const filteredData = (
    country_id,
    supplier_id,
    profit,
    project_id,
    silconFZE,
    redingtonFilter,
    stage
  ) => {
    
    return orderDetail?.data
      ?.filter((items) => {
        if (project_id === "") {
          return items;
        } else if (items?.project_id == project_id) {
          return items;
        }
      })
      ?.filter((items) => {
        if (country_id === "") {
          return items;
        } else if (items?.order?.supplier?.origin_id == country_id) {
          return items;
        }
      })
      ?.filter((items) => {
        if (stage === "") {
          return items;
        } else if (items?.order?.status_id == stage) {
          return items;
        }
      })
      ?.filter((items) => {
        if (supplier_id === "") {
          return items;
        } else if (items?.order?.supplier_id == supplier_id) {
          return items;
        }
      })
      ?.filter((items) => {
        if (withOut?.length == 0) {
          return items;
        } else if (
          !withOut?.some((withItem) => items?.order?.supplier_id == withItem)
        ) {
          return items;
        }
      })

      ?.filter((items, index) => {
        if (profit === "") {
          return items;
        } else if (
          profit == "profit" 
          
        ) {
          return items;
        } else if (
          profit == "loss" &&
          boqTotalCost[index]?.boqTotal - boqTotalCost[index]?.finalCost < 0
        ) {
          return items;
        } else if (
          profit == "breakeven" &&
          boqTotalCost[index]?.boqTotal - boqTotalCost[index]?.finalCost == 0
        ) {
          return items;
        }
      })
      .map((items) => {
        return {
          ...items,
          payment_data: PaymentTrackingProject?.data
            ?.map((pay) => (items?.id == pay.po_id ? pay : undefined))
            .filter(function (element) {
              return element !== undefined;
            }),
          lc_open_to: forex?.data
            ?.map((forex) =>
              forex.items
                ?.map((foritems) =>
                  foritems.order_id == items?.order_id
                    ? forex?.forex_term
                    : undefined
                )
                .filter(function (element) {
                  return element !== undefined;
                })
            )
            .filter(function (element, index) {
              return element?.length != 0;
            })
            .find((element) => element[0][0]),
          etd: Batch?.data?.map((batch) =>
            items?.project_id == batch?.project_id ? batch.etd : ""
          ),
        };
      });
  };

  const boqTotalCost = [];

  filteredData(
    country_id,
    supplier_id,
    profit,
    project_id,
    silconFZE,
    redingtonFilter,
    stage
  )?.map((items, index) => manufacturers?.push(items?.po));
  for (let i = 0; i < manufacturers?.length; i++) {
    const boqTotal =
      manufacturers &&
      manufacturers[i]
        ?.map((item) => item?.boq_total_cost)
        ?.reduce((acc, curr) => acc + curr, 0);

    const finalCost =
      manufacturers &&
      manufacturers[i]
        ?.map((item) => item?.pi_total_cost)
        ?.reduce((acc, curr) => acc + curr, 0);
    boqTotalCost?.push({
      id: i,
      boqTotal,
      finalCost,
    });
  }
  let projectTotal = 0;
  boqTotalCost?.map((items) => (projectTotal += items?.finalCost));

  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(
    filteredData(country_id, supplier_id, profit, project_id)?.length / PER_PAGE
  );
  const _DATA = usePagination(
    filteredData(country_id, supplier_id, profit, project_id),
    PER_PAGE
  );
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  

  let SupplierList = [];
  suppliers?.data?.map((items) =>
    SupplierList.push({
      value: items?.id,
      label: items?.distributor_name,
    })
  );
  return (
    <>
      {filteredData(
        country_id,
        supplier_id,
        profit,
        project_id,
        silconFZE,
        redingtonFilter,
        stage
      )?.length != 0 ? (
        <div className="report-sourcing-container">
          <div className="d-flex justify-content-between align-items-center">
            <div className="report-project-total">
              <div className="d-flex justify-content-between align-items-center ">
                <div className="text-muted h5">Project Total</div>
                <div className="h4">${projectTotal?.toLocaleString()}</div>
              </div>
            </div>
            {/* <div className="d-flex  justify-content-center gap-2 px-2">
              <input
                type="checkbox"
                className="stock-checkmark"
                onChange={HanldeSilconetworks}
              />
              <span className="prg">Report with out Silconetworks FZE</span>
            </div>
            <div className="d-flex  justify-content-center gap-2 px-2">
              <input
                onChange={HanldeRedington}
                type="checkbox"
                className="stock-checkmark"
              />
              <span className="prg">Report with out Redington</span>
            </div> */}
            <div style={{ width: "100%" }}>
              <div className="prg">With out supplier</div>
              <MultiSelect
                required
                list={SupplierList}
                setters={setWithOut}
                // label={"Sub Solution"}
                width={"100%"}
              />
            </div>

            {project_id == "" ? (
              <AllSctsReport
                data={filteredData(
                  country_id,
                  supplier_id,
                  profit,
                  project_id,
                  silconFZE,
                  redingtonFilter,
                  stage
                )}
                project_id={project_id}
                project={project}
              />
            ) : (
              <DownloadReport
                data={filteredData(
                  country_id,
                  supplier_id,
                  profit,
                  project_id,
                  silconFZE,
                  redingtonFilter,
                  stage
                )}
                project_id={project_id}
                project={project}
              />
            )}
          </div>

          <div className="d-grid gap-2 mb-4">
            <div className="report-sourcing-table-header">
              <div className="sourcing-table-headers">
                <div className="">Order Name</div>
                <div className="">BoQ Total Cost</div>
                <div>Final Total Cost</div>
                <div>Profit/Loss</div>
                <div>ETA </div>
                <div>Supplier</div>
                <div>Manufacturer</div>
                <div>Total Cost</div>
              </div>
            </div>
            <div className="report-card">
              {filteredData(
                country_id,
                supplier_id,
                profit,
                project_id,
                silconFZE,
                redingtonFilter,
                stage
              )?.map((items, index) => (
                <div className="report-sourcing-table-header mb-2">
                  
                  <div className="sourcing-table-headers">
                    <div className="">{items?.order?.order_name}</div>
                    <div className="">
                      $ {boqTotalCost[index]?.boqTotal.toLocaleString()}
                    </div>
                    $ {boqTotalCost[index]?.finalCost.toLocaleString()}
                    <div
                      className={
                        boqTotalCost[index]?.boqTotal -
                          boqTotalCost[index]?.finalCost <
                        0
                          ? "text-danger"
                          : "text-success"
                      }
                    >
                      $
                      {(
                        boqTotalCost[index]?.boqTotal -
                        boqTotalCost[index]?.finalCost
                      ).toLocaleString()}
                    </div>
                    {items?.order?.finalEta == 0 ? (
                      <div>
                        {items?.order?.eta == null
                          ? "Pending"
                          : items?.order?.eta}
                      </div>
                    ) : (
                      "Done"
                    )}
                    <div>{items?.order?.supplier?.distributor_name}</div>
                    {/* <div>{items?.order?.status?.status_name}</div> */}
                    <div>{items?.manufacturer?.manufacturer_name}</div>${" "}
                    {boqTotalCost[index]?.finalCost.toLocaleString()}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* {count > 1 ? (
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
          className="p-2"
        />
      ) : (
        ""
      )} */}
        </div>
      ) : (
        <div className="report-sourcing-container">
          <NoRecord />
        </div>
      )}
    </>
  );
}
