import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../../api/endPoint";
import {
  useCreatePoGroupMutation,
  useGetEtaDetailQuery,
  useGetOrderPoItemQuery,
} from "../../../features/SCMApi";
import NoRecord from "../../common/NoRecord";

export default function CreateGroup(props) {
  function HandleCancel() {
    props.modal(false);

  }

  const params = useParams();
  const { data: orderpo } = useGetOrderPoItemQuery(params.id);
  const [GroupName, setGroupName] = useState([]);
  const [po_id, setPo] = useState();
  const [project_id, setProjectId] = useState();
  const [Group_Name, setGroupNames] = useState();
  const [all, setAll] = useState();
  let allGroupItems = orderpo?.data[0]?.order?.pis[0]?.pi;

  const toggleHandler = (item, index) => () => {
    if (item == "all") {
      setGroupName(allGroupItems);
    } else {
      setGroupName((state, stindex) => ({
        ...state,
        [index]: state[index]
          ? null
          : {
              item_part_number: item.item_part_number,
              item_description: item.item_description,
              qty: item.qty,
              uom: item.uom,
              pi_unit_cost: item.pi_unit_cost,
              pi_total_cost: item.pi_total_cost,
            },
      }));
    }
  };

  useEffect(() => {

  }, [GroupName]);
  const checkedItem = [];
  // Object.keys(unchecked)?.map((element) =>
  //   unchecked[element].checked == true
  //     ? checkedItem.push(unchecked[element].id)
  //     : null
  // );

  const GroupData = Object.keys(GroupName).map((key) => GroupName[key]);
  let items = GroupData.filter((element) => element !== null);

  let information = {
    po_id: props?.poid,
    items,
    project_id: orderpo?.data[0]?.project_id,
  };
  const [CreateGroup, res] = useCreatePoGroupMutation();
  const HandleSubmit = async (e) => {
    e.preventDefault();
    // axios
    //   .post(`${API_BASE_URL}/scm/sourcing/po-group`, information, {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   })
    //   .then((response) => {

    //    props.setMessages("Po Group created successfully")
    //    props?.modal(false)
    //   })
    //   .then((error) => {

    //   });
    CreateGroup(information)
      .unwrap()
      .then((response) => {

        props.setMessages("PO Group create successfully");
        props.modal(false);
      })
      .then((error) => {

      });
  };

  return (
    <div>
      <label className="d-flex justify-content-start mb-2 gap-2 ms-2">
        <input type="checkbox" onChange={toggleHandler("all", null)} />
        <span className="prg-pri">Select All</span>
      </label>
      {props?.poid !== undefined ? (
        <form className="form-container " onSubmit={HandleSubmit}>
          {allGroupItems?.map((items, index) => (
            <div className="d-flex flex-column ">
              <label className="d-flex gap-2 align-items-baseline ">
                <input
                  onChange={toggleHandler(items, index)}
                  checked={GroupName[index]}
                  type="checkbox"
                />
                <span className="group-prg">
                  {items.item_description
                    ? items?.item_description
                    : items?.course_description}
                </span>
              </label>
            </div>
          ))}

          <div className="d-flex justify-content-center gap-3 mt-2">
            <button
              type="cancel"
              className="form-cancel"
              onClick={HandleCancel}
            >
              {" "}
              Cancel
            </button>
            <button type="submit" className="form-save">
              Save
            </button>
          </div>
        </form>
      ) : (
        <NoRecord />
      )}
    </div>
  );
}
