import React, { useState } from "react";
import "../../../pages/Payment/Payable/aging.css";
import { NavLink } from "react-router-dom";
import {
  useGetProjectPRFQuery,
  useGetProjectQuery,
  useGetProjectRolePermissionQuery,
  useGetProjectsQuery,
  useGetUserInfoQuery,
} from "../../../features/SCMApi";
import SelectOption from "../../common/SelectOption";
import ForexSideBar from "../../../components/Payment/ForexApproval/PaymentSideBar";
import { IoMdArrowRoundBack } from "react-icons/io";
import OverallPayments from "./overallPayments";
import CheckProjectPermission from "../../common/checkPermission/CheckProjectPermission";
import { useParams } from "react-router-dom";
import CheckPermission from "../../common/checkPermission/checkPermission";

export default function PrfIndex(props) {
  const params = useParams();

  const { data: roleRoles } = useGetProjectRolePermissionQuery(
    params?.project_id
  );
  const { data: userinfo } = useGetUserInfoQuery();
  const { data: projectmembers } = useGetProjectQuery(params?.project_id);
  const [selectedProject, setProject] = useState();
  const [isOverallPayment, setIsOverallPayment] = useState(true);
  const [overallPayment, setOverallPayment] = useState(false);

  const { data: projectPrf } = useGetProjectPRFQuery(selectedProject?.value);

  const { data: response } = useGetProjectsQuery();
  const Projectdata = [];

  response?.data?.map((items) => {
    if (selectedProject === items.id) {
      Projectdata.push(items);
    }
  });


  const [message, setMessage] = useState(null);
  const HandleMessage = () => {
    setMessage("Please Select Project To Proceed");
  };
  const [search, setSearch] = useState("");

  const handleSearchChange = (e) => {
    setSearch(e.target.value);

  };

  const list = [];
  response?.data?.map((items) => {
    if (items?.isActive == 1) {
      list.push({
        value: items?.id,
        label: items?.project_name,
      });
    }
  });
  const toggleHistory = () => {
    setOverallPayment(!overallPayment);
    setIsOverallPayment(!isOverallPayment);
  };

  return (
    <ForexSideBar>
      <div className="dashboard-left-section  mt-5">
        <div className="d-flex justify-content-start">
          {CheckPermission("view_payment_tracking", userinfo) ? (
            <button className="prg-payableHistory" onClick={toggleHistory}>
              {isOverallPayment ? (
                "Overall Payments"
              ) : (
                <div className="d-flex justify-content-around align-items-center">
                  <IoMdArrowRoundBack />
                  <div className="px-2 h-[20px]">Go back</div>
                </div>
              )}
            </button>
          ) : null}
        </div>

        {!overallPayment ? (
          <>
            {/* <Utility setters={setProject} message={message} /> */}
            <div className="d-flex justify-content-center">
              <SelectOption
                options={list}
                setters={setProject}
                value={selectedProject}
              />
            </div>
            <div className="h5-pri text-center">{message}</div>
            <div className="sourcing-dashboard-mini-card d-flex justify-content-between">
              <div className="h5-pri">Payment Request Form</div>
              <div className="d-flex flex-column ">
                <div className="numberOf-order text-center">
                  {projectPrf?.data == null ? "0" : projectPrf?.data?.length}
                </div>
              </div>
              {selectedProject?.value == undefined ? (
                <>
                  <div className="view-more-po" onClick={HandleMessage}>
                    View More
                  </div>
                </>
              ) : (
                <NavLink
                  to={`${selectedProject?.value}`}
                  className="view-more-po"
                >
                  View More
                </NavLink>
              )}
            </div>
          </>
        ) : (
          <OverallPayments />
        )}
      </div>
    </ForexSideBar>
  );
}
