import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { ArcElement } from "chart.js";
import { Pie } from "react-chartjs-2";
import { useGetToolsDashboardQuery } from "../../features/SCMApi";
import NoRecord from "../common/NoRecord";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

export default function ToolsDashboard() {
  const { data: dashboard } = useGetToolsDashboardQuery();
  let labels = [];
  let datas = [];
  dashboard?.data?.map((items) => labels.push(items?.status_name));
  dashboard?.data?.map((items) => datas.push(items?.countedByStatus));
 
  const options = {
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "right",
      },
      datalabels: {
        formatter: (value, context) => {
          const datapoints = context.chart.data.datasets[0].data;
          function totalSum(total, datapoint) {
            return total + datapoint;
          }
          const totalValue = datapoints.reduce(totalSum, 0);
          const percentageValue = ((value / totalValue) * 100).toFixed(1);
          const display = `${percentageValue}%`;
          return display;
        },
        display: true,
        align: "center",
        borderRadius: 3,
        color: "#000",
        font: {
          size: 12,
        },
      },
    },
  };
  const data = {
    labels: labels,
    datasets: [
      {
        data: datas,
        backgroundColor: ["#AEB8C9", "#04D7D7", "#FABB06", "#29A7EB"],
        hoverOffset: 4,
      },
    ],
  };

  return (
    <div className="dashboard-card">
      <div className="dashboard-title ps-3 pt-2">Tools</div>
      <div className="divider"></div>
      {data?.datasets[0]?.data.length != 0 ? (
        <div
          style={{
            height: "350px",
            width: " 350px",
            padding: "13px",
            position: "relative",
            top: "-43px",
          }}
        >
          {" "}
          <Pie options={options} data={data} />
        </div>
      ) : (
        <NoRecord />
      )}
    </div>
  );
}
