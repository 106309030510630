import React, { useEffect, useState } from "react";
import EmployeeRecievableFilter from "../../../pages/Payment/Recievable/EmployeeReceivable/EmployeeReceivableFilter";
import axios from "axios";
import { API_BASE_URL } from "../../../api/endPoint";
import Button from "@mui/material/Button";
import UploadIcon from "@mui/icons-material/Upload";
import CircularProgress from "@mui/material/CircularProgress";

function EmployeeReceivable() {
  const [productData, setProductData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({ newly_paid: "" });

  const itemsPerPage = 5;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/scm/settings/payment/employeereceivable`,
          {
            params: {
              searchQuery,
              startDate,
              endDate,
            },
          }
        );
        setProductData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [searchQuery, startDate, endDate]);

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      await handleImport(selectedFile);
    }
  };

  const handleImport = async (selectedFile) => {
    const formData = new FormData();
    formData.append("file", selectedFile);

    setIsLoading(true);
    setErrorMessage("");

    try {
      await axios.post(
        `${API_BASE_URL}/scm/settings/payment/employeereceivable`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const response = await axios.get(
        `${API_BASE_URL}/scm/settings/payment/employeereceivable`,
        {
          params: {
            searchQuery,
            startDate,
            endDate,
          },
        }
      );
      setProductData(response.data);
    } catch (error) {
      console.error("Error uploading file:", error);
      setErrorMessage("Failed to upload the file. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (item) => {
    setSelectedItem({
      ...item,
      newly_paid: "", // Initialize newly_paid to an empty string or 0
    });
    setSuccessMessage(""); // Clear success message
    setErrorMessage("");
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  const handleSaveChanges = async () => {
    if (selectedItem) {
      // Ensure that newly_paid is a number
      const newlyPaidAmount = parseFloat(selectedItem.newly_paid) || 0; // Get newly paid amount
      const totalPaidAmount = parseFloat(selectedItem.paid) || 0; // Get previous paid amount

      const updatedItem = {
        ...selectedItem,
        paid: newlyPaidAmount, // Update paid amount with the new payment
        newly_paid: undefined, // Clear newly_paid field after saving
      };

      try {
        await axios.put(
          `${API_BASE_URL}/scm/settings/payment/employeereceivable/${selectedItem.id}`,
          updatedItem
        );
        const response = await axios.get(
          `${API_BASE_URL}/scm/settings/payment/employeereceivable`,
          {
            params: {
              searchQuery,
              startDate,
              endDate,
            },
          }
        );
        setProductData(response.data);
        setSuccessMessage("Successfully updated the employee receivable.");
        handleCloseModal();
      } catch (error) {
        const message =
          error.response && error.response.data
            ? error.response.data.message || "An error occurred."
            : "An error occurred.";
        setErrorMessage(message);
        handleCloseModal();
      }
    }
  };

  const filteredData = productData.filter((item) => {
    const matchesQuery = item.employee_name
      ? item.employee_name.toLowerCase().includes(searchQuery.toLowerCase())
      : false;
    const matchesStartDate =
      !startDate || new Date(item.created_at) >= new Date(startDate);
    const matchesEndDate =
      !endDate || new Date(item.created_at) <= new Date(endDate);

    return matchesQuery && matchesStartDate && matchesEndDate;
  });

  const numberOfPages = Math.ceil(filteredData.length / itemsPerPage);
  const paginatedData = filteredData.slice(startIndex, endIndex);

  const totalSum = filteredData.reduce((sum, item) => {
    const totalValue = parseFloat(item.total) || 0;
    return sum + totalValue;
  }, 0);

  return (
    <div className="mb-3 bg-white rounded border">
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "25px",
            }}
          >
            <input
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileChange}
              style={{ display: "none" }}
              id="upload-file"
            />
            <label htmlFor="upload-file">
              <Button
                variant="contained"
                startIcon={<UploadIcon />}
                component="span"
                style={{
                  marginRight: "10px",
                  backgroundColor: "#064b4f",
                  color: "white",
                }}
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Import"
                )}
              </Button>
            </label>
          </div>

          <div
            style={{ flexGrow: 1, display: "flex", justifyContent: "center" }}
          >
            <EmployeeRecievableFilter
              searchQuery={searchQuery}
              startDate={startDate}
              endDate={endDate}
              handleSearchSubmit={handleSearchSubmit}
              setSearchQuery={setSearchQuery}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </div>

          <div style={{ paddingRight: "30px" }}>
            <div className="items-counter" style={{ textAlign: "center" }}>
              <div>Total Sum</div>
              <div>{totalSum.toLocaleString()} Birr</div>
            </div>
          </div>
        </div>

        {errorMessage && (
          <div style={{ color: "red", marginTop: "10px", textAlign: "center" }}>
            {errorMessage}
          </div>
        )}

        {successMessage && (
          <div
            style={{ color: "green", marginTop: "10px", textAlign: "center" }}
          >
            {successMessage}
          </div>
        )}
      </div>

      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <table
          className="table"
          style={{ width: "95%", borderCollapse: "collapse", margin: "auto" }}
        >
          <thead>
            <tr className="table-info" style={{ backgroundColor: "#f1f1f1" }}>
              <th
                style={{
                  padding: "10px",
                  textAlign: "left",
                  border: "1px solid #ddd",
                }}
              >
                NO
              </th>
              <th
                style={{
                  padding: "10px",
                  textAlign: "left",
                  border: "1px solid #ddd",
                }}
              >
                Employee Name
              </th>
              <th
                style={{
                  padding: "10px",
                  textAlign: "left",
                  border: "1px solid #ddd",
                }}
              >
                Car
              </th>
              <th
                style={{
                  padding: "10px",
                  textAlign: "left",
                  border: "1px solid #ddd",
                }}
              >
                PMA
              </th>
              <th
                style={{
                  padding: "10px",
                  textAlign: "left",
                  border: "1px solid #ddd",
                }}
              >
                Paid
              </th>
              <th
                style={{
                  padding: "10px",
                  textAlign: "left",
                  border: "1px solid #ddd",
                }}
              >
                Remaining
              </th>
              <th
                style={{
                  padding: "10px",
                  textAlign: "left",
                  border: "1px solid #ddd",
                }}
              >
                Total
              </th>
              <th
                style={{
                  padding: "10px",
                  textAlign: "left",
                  border: "1px solid #ddd",
                }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {paginatedData.map((item, index) => (
              <tr
                key={item.id}
                style={{
                  backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#fff",
                  border: "1px solid #ddd",
                }}
              >
                <td
                  style={{
                    padding: "10px",
                    textAlign: "left",
                    border: "1px solid #ddd",
                  }}
                >
                  {startIndex + index + 1}
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "left",
                    border: "1px solid #ddd",
                  }}
                >
                  {item.employee_name}
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "left",
                    border: "1px solid #ddd",
                  }}
                >
                  {item.car_maintenance}
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "left",
                    border: "1px solid #ddd",
                  }}
                >
                  {item.pma}
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "left",
                    border: "1px solid #ddd",
                  }}
                >
                  {item.paid}
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "left",
                    border: "1px solid #ddd",
                  }}
                >
                  {item.remaining}
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "left",
                    border: "1px solid #ddd",
                  }}
                >
                  {item.total}
                </td>
                <td>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleEdit(item)}
                    style={{ backgroundColor: "#064b4f", color: "white" }}
                  >
                    Edit
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div
        className="pagination"
        style={{ display: "flex", justifyContent: "center", margin: "20px 0" }}
      >
        <Button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        {Array.from({ length: numberOfPages }, (_, index) => (
          <Button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            style={{
              margin: "0 5px",
              backgroundColor: currentPage === index + 1 ? "#064b4f" : "white",
              color: currentPage === index + 1 ? "white" : "black",
            }}
          >
            {index + 1}
          </Button>
        ))}
        <Button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === numberOfPages}
        >
          Next
        </Button>
      </div>

      {isModalOpen && selectedItem && (
        <div className="modal fade show d-block" tabIndex="-1">
          <div className="modal-dialog" style={{ maxWidth: "750px" }}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Employee Receivable</h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={handleCloseModal}
                ></button>
              </div>
              <div
                className="modal-body"
                style={{
                  paddingBottom: "0",
                  maxHeight: "400px",
                  overflowY: "auto",
                }}
              >
                <form>
                  <div className="mb-3">
                    <label className="form-label">Employee Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      value={selectedItem.employee_name} // Display employee name
                      readOnly
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      Previously Paid Amount:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={selectedItem.paid} // Display previously paid amount
                      readOnly
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Enter New Paid Amount:</label>
                    <input
                      type="number"
                      className="form-control"
                      value={selectedItem.newly_paid || ""} // Controlled input for newly paid amount
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          newly_paid: e.target.value, // Update newly_paid on change
                        })
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Total Paid Amount:</label>
                    <input
                      type="text"
                      className="form-control"
                      value={(
                        parseFloat(selectedItem.paid) +
                        parseFloat(selectedItem.newly_paid || 0)
                      ).toFixed(2)} // Calculate total
                      readOnly
                    />
                  </div>
                </form>
              </div>
              <div
                className="modal-footer"
                style={{ padding: "10px 0", margin: "0" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleCloseModal}
                    style={{ marginRight: "10px" }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-success" // Adjust the color as needed
                    onClick={handleSaveChanges}
                    style={{ marginLeft: "10px" }}
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EmployeeReceivable;
