import React from "react";
import {
  useApprovalApproveMutation,
  useApprovalRejectMutation,
  useApprovalRetractMutation,
  useGetApproverQuery,
  useGetOrderPoItemQuery,
  useGetPRFQuery,
  useGetUserInfoQuery,
  useSendForApproverPCMutation,
  useSendForApproverPRFMutation,
  useShowPRFQuery,
} from "../../../features/SCMApi";
import { useParams } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Tooltip } from "@mui/material";
import { useState } from "react";

export default function PCApproval({ id }) {
  const params = useParams();
  // const { data: orderpo } = useGetOrderPoItemQuery(params.id);
  const { data: prf } = useShowPRFQuery(params?.prf_id);

  const { data: loggedUser } = useGetUserInfoQuery();


  const [show, setShow] = useState();
  const [confirmation, setConfirmation] = useState(false);
  const [feedback, setFeedBack] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false);
  const [retractConfirmation, setRetractConfirmation] = useState(false);
  const [approvalReject] = useApprovalRejectMutation();
  const [approvalApprove] = useApprovalApproveMutation();
  const [approvalRetract] = useApprovalRetractMutation();
  function confirmationHandler() {
    setConfirmation(true);
  }
  function retractConfirmationHandler() {
    setRetractConfirmation(true);
  }

  const { data: getApprover } = useGetApproverQuery({
    module_name: "PC",
    id: id,
  });

  let currentApproverUser =
    loggedUser &&
    getApprover &&
    getApprover?.data?.current.find((user) => user?.user_id == loggedUser?.id);
  let isApprover = currentApproverUser !== undefined ? true : false;

  let feedBack =
    getApprover &&
    getApprover?.data?.history.find((user) => user?.feedback)?.feedback
      ? getApprover?.data?.history.find((user) => user?.feedback)?.feedback
      : false;

  let feedBackUser =
    getApprover &&
    getApprover?.data?.history.find((user) => user?.feedback)?.name
      ? getApprover?.data?.history.find((user) => user?.feedback)?.name
      : false;

  let isPaApproved =
    getApprover?.data?.current &&
    getApprover?.data?.current.find((paData) => paData?.is_approved == 1)
      ?.is_approved == 1
      ? true
      : getApprover?.data?.current.find((paData) => paData?.is_approved == 0)
            ?.is_approved == 0
        ? false
        : getApprover?.data?.current.find((paData) => paData?.is_approved == 2)
              ?.is_approved == 2
          ? null
          : "";
  let roleName =
    getApprover &&
    getApprover?.data?.current.find((paData) => paData?.level)?.role_name;
  let level = getApprover?.data?.current.find((paData) => paData?.level)?.level;
  const approvalStatus = {
    level,
    finalApproved: isPaApproved == 1 ? true : isPaApproved == 0 ? false : null,
    feedBack,
    feedBackUser,
  };
  //Retract approval functionality
  let currentLevel = getApprover?.data?.current[0]?.level;

  let approvedHistoryForCurrentApprover = getApprover?.data?.history?.find(
    (user) => user?.approval_type == 0 && user?.user_id == loggedUser?.id
  );

  let canRetract =
    approvedHistoryForCurrentApprover?.level + 1 == currentLevel ? true : false;
  const po_number = prf?.order?.po_number;
  let dataForApproval = {
    module_name: "PC",
    module_id: id,
    user_id: loggedUser?.id,
    feedback,
  };

  const handleDecline = () => {
    setButtonDisable(true);

    approvalReject(dataForApproval)
      .unwrap()
      .then((response) => {
        setConfirmation(false);
        setButtonDisable(false);
        setShow(false);

      })
      .catch((error) => {

        setButtonDisable(false);
      });
  };
  const handleApprovals = () => {
    setButtonDisable(true);
    approvalApprove(dataForApproval)
      .unwrap()
      .then((response) => {

        setConfirmation(false);
        setButtonDisable(false);
      })
      .then((error) => {

        setButtonDisable(false);
      });
    // axios
    //   .post(`${API_BASE_URL}/scm/approval/approve`, dataForApproval)
    //   .then((response) => {
    //     setConfirmation(false);

    //   })
    //   .catch((err) =>

    //   );
  };
  const handleRetract = () => {
    setButtonDisable(true);

    approvalRetract(dataForApproval)
      .unwrap()
      .then((response) => {

        // props.setMessages("Approved Successfully");
        setRetractConfirmation(false);
        setButtonDisable(false);
      })
      .then((error) => {

        setButtonDisable(false);
      });
    // axios
    //   .post(`${API_BASE_URL}/scm/approval/retract`, dataForApproval)
    //   .then((response) => {
    //     setRetractConfirmation(false);

    //   })
    //   .catch((err) =>

    //   );
  };
  let PCApproved = {
    id: id,
  };
  const [sendForApprover] = useSendForApproverPCMutation();
  const handelSendForApproval = () => {
    setButtonDisable(true);

    sendForApprover(PCApproved)
      .unwrap()
      .then((response) => {

        setButtonDisable(false);
      })
      .catch((error) => {

        setButtonDisable(false);
      });
    // axios
    //   .post(`${API_BASE_URL}/scm/sourcing/orders/send_approval`, PaApproved)
    //   .then((response) => {

    //     approvalStage = false;
    //   })
    //   .catch((err) =>

    //   );
  };
  return (
    <>
      <div className="smManufacturer-card">
        <div className="Lc-card">
          <div className="lc-value">
            {approvalStatus?.finalApproved == false &&
            getApprover &&
            getApprover?.data?.current?.length !== 0 ? (
              <div
                style={{
                  color: "#fcce37",
                  textAlign: "center",
                }}
              >
                Level {approvalStatus?.level} (
                {roleName?.length > 21
                  ? roleName?.slice(0, 20) + "..."
                  : roleName}
                ) approval pending
              </div>
            ) : approvalStatus?.finalApproved == true ? (
              <span
                style={{
                  color: "#064B4F",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Approved
                <CheckCircleOutlineIcon />
              </span>
            ) : approvalStatus?.finalApproved == null ? (
              <Tooltip
                title={`${approvalStatus?.feedBackUser} reject PO with comment ${approvalStatus?.feedBack}`}
              >
                <span
                  style={{
                    color: "#FF5252",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  PC rejected
                </span>
              </Tooltip>
            ) : (
              "Waiting for Approval"
            )}
          </div>
          <div className="lc-label">Status</div>
        </div>
      </div>
      {isPaApproved != true &&
      isApprover == true &&
      getApprover?.data?.current[0]?.is_approved != 2 ? (
        <div className="d-grid gap-2 justify-content-center">
          <div className="d-flex  gap-3 mt-3">
            <button
              type="button"
              class="decline-btn mb-2"
              onClick={() => setShow((prev) => !prev)}
            >
              Decline
            </button>
            <button
              type="button"
              class="approval-btn"
              onClick={confirmationHandler}
            >
              Approve
            </button>
          </div>
          {show && (
            <div style={{ left: "0px" }} class="pa-comment-container">
              <div className="prg p-2">Comment</div>
              <textarea
                className="po-comment-section mx-3"
                onChange={(e) => setFeedBack(e.target.value)}
              ></textarea>
              <button
                type="button"
                class="comment-decline-btn mx-3 my-1"
                onClick={handleDecline}
                disabled={buttonDisable}
              >
                Decline
              </button>
              <button
                type="button"
                class="comment-decline-btn mx-3 my-1"
                style={{
                  backgroundColor: "#fff",
                  color: "#606060",
                  border: "1px solid #606060",
                }}
                onClick={() => setShow((prev) => !prev)}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      ) : (
        ""
      )}
      {isPaApproved != true && canRetract == true ? (
        <div className="d-flex justify-content-center gap-3 mt-3">
          <button
            type="button"
            class="approval-btn"
            onClick={retractConfirmationHandler}
            style={{ backgroundColor: "#FF5252" }}
          >
            Retract Approval
          </button>
        </div>
      ) : (
        <></>
      )}
      {getApprover?.data?.current?.length == 0 ? (
        <div className="d-flex justify-content-center my-3">
          <button
            className={
              // approvalStage === true
              "send-for-approval-btn"
              //  "send-for-approval-btn-dissable"
            }
            onClick={handelSendForApproval}
            // // disabled={approvalStage === true ? false : true}
            // disabled={buttonDisable}
          >
            Send for Approval
          </button>
        </div>
      ) : (
        ""
      )}

      {confirmation ? (
        <div
          id="Modal"
          className="dialog-modal "
          onClick={(e) => {
            setConfirmation(false);
          }}
        >
          <div
            className="dialog-modal-content"
            onClick={(e) => e.stopPropagation()}
            style={{ height: "30%" }}
          >
            <div className="dialog-info text-center">
              Are you sure to approve
              <div className="prg text-center mt-2">{po_number}</div>
            </div>
            <div className="confirm-dialog-controller">
              <div
                className="dialog-cancel-btn"
                onClick={(e) => {
                  setConfirmation(false);
                }}
              >
                Cancel
              </div>
              <button
                type="submit"
                onClick={handleApprovals}
                disabled={buttonDisable}
                className="dialog-delete-btn"
              >
                Approve
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {retractConfirmation ? (
        <div
          id="Modal"
          className="dialog-modal "
          onClick={(e) => {
            setConfirmation(false);
          }}
        >
          <div
            className="dialog-modal-content"
            onClick={(e) => e.stopPropagation()}
            style={{ height: "30%" }}
          >
            <div className="dialog-info text-center">
              Are you sure to retract
              <div className="prg text-center mt-2">{po_number}</div>
            </div>
            <div className="confirm-dialog-controller">
              <div
                className="dialog-cancel-btn"
                onClick={(e) => {
                  setRetractConfirmation(false);
                }}
              >
                No
              </div>
              <button
                type={"submit"}
                onClick={handleRetract}
                className="dialog-delete-btn"
                disabled={buttonDisable}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
