import { useState } from "react";
import {
  useDispatchedItemsMutation,
  useDispatchedToolsMutation,
} from "../../../features/SCMApi";
import InventoryReport from "./InventoryReport";

export default function DispatchedModal(props) {
  const [Dispatched] = useDispatchedToolsMutation();
  const [DispatchedItems] = useDispatchedItemsMutation();
  const [start_date, setStartDate] = useState();
  const [end_date, setEndDate] = useState();
  const [data, setData] = useState(null);
  let form = { start_date, end_date };
  function handleSubmit(e) {
    e.preventDefault();
    props?.items != true
      ? Dispatched(form)
          .unwrap()
          .then((response) => {
            setData(response?.data);

          })
          .then((error) => {

          })
      : DispatchedItems(form)
          .unwrap()
          .then((response) => {
            setData(response?.data);

          })
          .then((error) => {

          });
  }

  return (
    <div
      id="Modal"
      className="dialog-modal "
      onClick={(e) => {
        props?.setDialog(false);
      }}
    >
      <div
        className="stock-dialog-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="dialog-info">Please Select start date and end date</div>

        <div className="d-flex gap-2 align-items-baseline">
          <div className="supplier-input-label"> Start Date</div>
          <input
            onChange={(e) => setStartDate(e.target.value)}
            type="date"
            className={`stock-text-input`}
            required
          />
        </div>
        <div className="d-flex gap-2 align-items-baseline">
          <div className="supplier-input-label">End Date</div>
          <input
            onChange={(e) => setEndDate(e.target.value)}
            type="date"
            className={`stock-text-input`}
            required
          />
        </div>
        {data != null ? (
          <div className="d-flex align-items-center">
            <InventoryReport
              data={data}
              type="Dispatch Tools List"
              is_tool={true}
            />
            <span className="prg-pri">Download Dispatched Items</span>
          </div>
        ) : (
          ""
        )}

        <div className="confirm-dialog-controller">
          <div
            className="dialog-cancel-btn"
            onClick={(e) => {
              props?.setDialog(false);
            }}
          >
            No
          </div>
          <button
            type={"submit"}
            onClick={(e) => {
              handleSubmit(e);
            }}
            className={"form-save"}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
}
