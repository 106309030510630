import React from "react";
import Select from "react-select";

export default function MultiSelect(props) {
  function handleChange(selectedOption) {
    // props.setters(selectedOption?.value);
    const selectedValue = [];
    Object.keys(selectedOption).map((key) => {
      selectedValue.push(selectedOption[key].value);
    });
    props.setters(selectedValue);
  }
  const dataPush = [];
  if (props?.list !== null) {
    for (let id in props?.list) {
      dataPush.push(props?.list[id]?.value);
    }
  }
  return (
    <div style={{ width: `${props?.width ? props.width : "250px"}` }}>
      <div>{props?.label}</div>
      <Select
        required
        options={props?.list}
        isSearchable={true}
        isMulti
        onChange={(e) => handleChange(e)}
        menuPlacement="auto"
      />
    </div>
  );
}
