import axios from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL, DOC_BASE_URL } from "../../api/endPoint";
import { useSubmitBankMutation } from "../../features/SCMApi";
import FileChooser from "../common/FileChooser";
import { Tooltip } from "@mui/material";

export default function BankSubmission(props) {
  const hasSubmittedDocuments = props?.previousData?.length > 0 ? true : false;
  const hasCoverLetter = props?.previousData?.find(
    (item) => item?.cover_letter != ""
  );
  const hasCommercialInvoice = props?.previousData?.find(
    (item) => item?.commercial_invoice != ""
  );
  const hasPackingList = props?.previousData?.find(
    (item) => item?.packing_list != ""
  );
  const hasCertificateOfOrigin = props?.previousData?.find(
    (item) => item?.certificate_of_origin != ""
  );
  const hasBillofLoading = props?.previousData?.find(
    (item) => item?.loading_bill != ""
  );
  const hasBeneficiaryCertificate = props?.previousData?.find(
    (item) => item?.beneficiary_certificate != ""
  );
  const hasFreightInvoice = props?.previousData?.find(
    (item) => item?.freight_invoice != ""
  );
  const hasVesselCertificate = props?.previousData?.find(
    (item) => item?.vessel_certificate != ""
  );

  const [cover_letter, setCoverLetter] = useState(
    hasCoverLetter !== undefined ? { name: "File Exist" } : ""
  );
  const [commercial_invoice, setCommercialInvoice] = useState(
    hasCommercialInvoice !== undefined ? { name: "File Exist" } : ""
  );
  const [packing_list, setPackingList] = useState(
    hasPackingList !== undefined ? { name: "File Exist" } : ""
  );
  const [certificate_of_origin, setCertificateOriginCopy] = useState(
    hasCertificateOfOrigin !== undefined ? { name: "File Exist" } : ""
  );
  const [loading_bill, setLoadingBill] = useState(
    hasBillofLoading !== undefined ? { name: "File Exist" } : ""
  );
  const [beneficiary_certificate, setBeneficiaryCertificate] = useState(
    hasBeneficiaryCertificate !== undefined ? { name: "File Exist" } : ""
  );
  const [freight_invoice, setFreightInvoice] = useState(
    hasFreightInvoice !== undefined ? { name: "File Exist" } : ""
  );
  const [vessel_certificate, setVesselCertificate] = useState(
    hasVesselCertificate !== undefined ? { name: "File Exist" } : ""
  );
  const { batchId } = useParams();
  const [submitBank] = useSubmitBankMutation();

 
  function handleSubmit() {
    const form = {
      batch_id: batchId,
      cover_letter,
      commercial_invoice,
      packing_list,
      certificate_of_origin,
      loading_bill,
      beneficiary_certificate,
      freight_invoice,
      vessel_certificate,
    };
    // submitBank({
    //   batch_id: batchId,
    //   cover_letter,
    //   commercial_invoice,
    //   packing_list,
    //   certificate_of_origin,
    //   loading_bill,
    //   beneficiary_certificate,
    //   freight_invoice,
    //   vessel_certificate,
    // })
    //   .unwrap()
    //   .then((response) => {
    
    //     // window.location.reload();
    //   })
    //   .then((error) => {
    
    //   });
    
    props?.previousData.length
      ? axios
          .post(
            `${API_BASE_URL}/scm/logistics/bank-submition-documents/${props?.previousData[0]?.id}`,
            form,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(function (response) {
            
            props.ToggleModal(false);
          })
          .catch(function (error) {
            
          })
      : axios
          .post(
            `${API_BASE_URL}/scm/logistics/bank-submition-documents`,
            form,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(function (response) {
            
            props.ToggleModal(false);
          })
          .catch(function (error) {
            
          });
  }

  return (
    <div className="mini-modal" onClick={(e) => props?.ToggleModal(false)}>
      <div className="mini-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="create-project-modal-title">
          Bank Submission
          <div
            className="close-modal-button"
            onClick={(e) => props?.modal(false)}
          >
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.4612 5.78721L19.8657 7.19175L7.46095 19.5965L6.0564 18.192L18.4612 5.78721ZM7.17507 5.51376L20.3505 18.6891L18.8589 20.1807L5.68351 7.00531L7.17507 5.51376Z"
                fill="#353945"
              />
            </svg>
          </div>
        </div>
        <div className="form-card-container mb-4">
          <div className="input-group-fine">
            <div className="d-flex gap-3">
              <div className="d-flex gap-2 align-items-baseline">
                <div className="bank-input-label">Cover Letter</div>
                <FileChooser
                  id={"cover_letter"}
                  state={cover_letter}
                  setters={setCoverLetter}
                />
              </div>
              {hasSubmittedDocuments === true &&
              hasCoverLetter !== undefined ? (
                <Tooltip title="Download">
                  <a
                    href={`${DOC_BASE_URL}scm/document/download?file_path=${hasCoverLetter?.cover_letter}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="#186569"
                      class="bi bi-download"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                    </svg>
                  </a>
                </Tooltip>
              ) : (
                <></>
              )}
            </div>

            <div className="d-flex gap-3">
              <div className="d-flex gap-2 align-items-baseline">
                <div className="bank-input-label">Commercial Invoice</div>
                <FileChooser
                  id={"commercial_invoice"}
                  state={commercial_invoice}
                  setters={setCommercialInvoice}
                />
              </div>
              {hasSubmittedDocuments === true &&
              hasCommercialInvoice !== undefined ? (
                <Tooltip title="Download">
                  <a
                    href={`${DOC_BASE_URL}scm/document/download?file_path=${hasCommercialInvoice?.commercial_invoice}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="#186569"
                      class="bi bi-download"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                    </svg>
                  </a>
                </Tooltip>
              ) : (
                <></>
              )}
            </div>

            <div className="d-flex gap-3">
              <div className="d-flex gap-2 align-items-baseline">
                <div className="bank-input-label">Packing List</div>
                <FileChooser
                  id={"packing_list"}
                  state={packing_list}
                  setters={setPackingList}
                />
              </div>
              {hasSubmittedDocuments === true &&
              hasPackingList !== undefined ? (
                <Tooltip title="Download">
                  <a
                    href={`${DOC_BASE_URL}scm/document/download?file_path=${hasPackingList?.packing_list}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="#186569"
                      class="bi bi-download"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                    </svg>
                  </a>
                </Tooltip>
              ) : (
                <></>
              )}
            </div>

            <div className="d-flex gap-3">
              <div className="d-flex gap-2 align-items-baseline">
                <div className="bank-input-label">Certificate of Origin</div>
                <FileChooser
                  id={"certificate_of_origin"}
                  state={certificate_of_origin}
                  setters={setCertificateOriginCopy}
                />
              </div>
              {hasSubmittedDocuments === true &&
              hasCertificateOfOrigin !== undefined ? (
                <Tooltip title="Download">
                  <a
                    href={`${DOC_BASE_URL}scm/document/download?file_path=${hasCertificateOfOrigin?.certificate_of_origin}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="#186569"
                      class="bi bi-download"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                    </svg>
                  </a>
                </Tooltip>
              ) : (
                <></>
              )}
            </div>

            <div className="d-flex gap-3">
              <div className="d-flex gap-2 align-items-baseline">
                <div className="bank-input-label">Bill of Loading / AWB</div>
                <FileChooser
                  id={"loading_bill"}
                  state={loading_bill}
                  setters={setLoadingBill}
                />
              </div>
              {hasSubmittedDocuments === true &&
              hasBillofLoading !== undefined ? (
                <Tooltip title="Download">
                  <a
                    href={`${DOC_BASE_URL}scm/document/download?file_path=${hasBillofLoading?.loading_bill}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="#186569"
                      class="bi bi-download"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                    </svg>
                  </a>
                </Tooltip>
              ) : (
                <></>
              )}
            </div>

            <div className="d-flex gap-3">
              <div className="d-flex gap-2 align-items-baseline">
                <div className="bank-input-label">Beneficiary Certificate</div>
                <FileChooser
                  id={"beneficiary_certificate"}
                  state={beneficiary_certificate}
                  setters={setBeneficiaryCertificate}
                />
              </div>
              {hasSubmittedDocuments === true &&
              hasBeneficiaryCertificate !== undefined ? (
                <Tooltip title="Download">
                  <a
                    href={`${DOC_BASE_URL}scm/document/download?file_path=${hasBeneficiaryCertificate?.beneficiary_certificate}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="#186569"
                      class="bi bi-download"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                    </svg>
                  </a>
                </Tooltip>
              ) : (
                <></>
              )}
            </div>

            <div className="d-flex gap-3">
              <div className="d-flex gap-2 align-items-baseline">
                <div className="bank-input-label">Freight Invoice</div>
                <FileChooser
                  id={"freight_invoice"}
                  state={freight_invoice}
                  setters={setFreightInvoice}
                />
              </div>
              {hasSubmittedDocuments === true &&
              hasFreightInvoice !== undefined ? (
                <Tooltip title="Download">
                  <a
                    href={`${DOC_BASE_URL}scm/document/download?file_path=${hasFreightInvoice?.freight_invoice}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="#186569"
                      class="bi bi-download"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                    </svg>
                  </a>
                </Tooltip>
              ) : (
                <></>
              )}
            </div>

            <div className="d-flex gap-3">
              <div className="d-flex gap-2 align-items-baseline">
                <div className="bank-input-label"> Vessel Certificate</div>
                <FileChooser
                  id={"vessel_certificate"}
                  state={vessel_certificate}
                  setters={setVesselCertificate}
                />
              </div>
              {hasSubmittedDocuments === true &&
              hasVesselCertificate !== undefined ? (
                <Tooltip title="Download">
                  <a
                    href={`${DOC_BASE_URL}scm/document/download?file_path=${hasVesselCertificate?.vessel_certificate}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="#186569"
                      class="bi bi-download"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                    </svg>
                  </a>
                </Tooltip>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="form-buttons">
            <button
              type="cancel"
              className="form-cancel"
              style={{ width: "100px" }}
              onClick={(e) => {
                props.ToggleModal(false);
              }}
            >
              {" "}
              Cancel
            </button>
            <button
              type="submit"
              className="form-save"
              style={{ width: "100px" }}
              onClick={(event) => handleSubmit(event)}
            >
              {props?.previousData.length ? "Update" : "Save"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
