import React from "react";
import clipboardCopy from "clipboard-copy";

const CopyLink = ({ linkToCopy }) => {
  const handleCopyLink = () => {
    clipboardCopy(linkToCopy)
      .then(() => {
        alert("Link copied to clipboard!");
      })
      .catch((error) => {
        alert("Failed to copy link:", error);
      });
  };

  return (
    <div>
      <button
        className="sourcing-postage-big-btn px-1 py-3"
        onClick={handleCopyLink}
      >
        Copy PRF Link
      </button>
    </div>
  );
};

export default CopyLink;
