import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  useCreatePrfMutation,
  useGetPaymentsModeQuery,
  useGetSettingBankQuery,
  useGetSuppliersOrdersQuery,
  useGetUsersQuery,
} from "../../features/SCMApi";
import CloseIcon from "@mui/icons-material/Close";
import GeneratePRF from "./GeneratePRF";
import { API_BASE_URL } from "../../api/endPoint";

export default function CreatePrf(props) {
  const [budget_number, setBudgetNumber] = useState(props?.data?.budget_number);
  const [requested_by, setRequestBy] = useState(props?.data?.requested_by?.id);
  const [prepared_by, setPreparedBy] = useState(props?.data?.prepared_by?.id);
  const [approved_by, setApprovedBy] = useState(props?.data?.approved_by?.id);
  const [paid_by, setPaidBy] = useState(props?.data?.paid_by?.id);
  const [bank_id, setBank] = useState(props?.data?.bank_id);
  const [account_type, setAccountType] = useState(props?.data?.account_type);
  const [amount, setAmount] = useState();
  const [paymentDate, setPaymentDate] = useState();
  const [creditNoteAmount, setCreditNoteAmount] = useState();
  const [EFTAmount, setEFTAmount] = useState();
  const [PLCAmount, setPLCAmount] = useState();
  const [supplier_id, setSupplierId] = useState(
    props?.poData?.order?.supplier?.id
  );

  const [payment_mode, setPaymentMode] = useState(
    props?.data?.payment_mode?.id
  );
  const [reason_for, setReasonFor] = useState(props?.data?.reason_for);
  const [is_tot, setToT] = useState(props?.data?.is_tot === 0 ? false : true);

  const [with_out_withholding, setWithOutHolding] = useState(
    props?.data?.with_out_withholding == 1 ? true : false
  );
  const [with_out_vat, setWithOutVAT] = useState(
    props?.data?.with_out_vat == 1 ? true : false
  );
  const [selectedOptiontot, setSelectedOptionTot] = useState(
    props?.data?.is_tot == 1 ? 1 : props?.data?.is_tot == 2 ? 2 : 0
  );
  const [selectedOptionWithholdings, setSelectedOptionWithholdings] = useState(
    props?.data?.with_out_withholding == 1
      ? 1
      : props?.data?.with_out_withholding == 2
        ? 2
        : 0
  );

  const handleOptionChange = (e) => {
    setSelectedOptionTot(e.target.value);
  };

  const handleWithholdingOptionChange = (e) => {
    setSelectedOptionWithholdings(e.target.value);
  };
  function HandleCancel() {
    props?.modal(false);
  }

  const { data: paymentmode } = useGetPaymentsModeQuery();

  useEffect(() => {
    setSupplierId(props?.PoData?.order?.supplier?.id);
  }, [props?.PoData?.order?.supplier?.id]);

  const { data: bankList } = useGetSettingBankQuery();
  const accountTypes = ["birr", "retention"];
  const { data: users } = useGetUsersQuery();
  let form = {
    id: props?.PoData?.id,
    amount,
    budget_number,
    requested_by,
    prepared_by,
    approved_by,
    paid_by,
    bank_id,
    account_type,
    payment_mode,
    reason_for,
    is_tot: is_tot == true ? selectedOptiontot : 0,
    paymentDate,
    with_out_vat,
    with_out_withholding:
      with_out_withholding == false ? selectedOptionWithholdings : 0,
  };

  const { data: Suppliers } = useGetSuppliersOrdersQuery();
  const [IENetworkSolutionsID, setIENetworkSolutionsID] = useState("");
  const [IENetworksLLCId, setIENetworksLLCId] = useState("");
  const [paymentModeName, setPaymentModeName] = useState(null);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/scm/payment/prf/credit_note/${supplier_id}`)
      .then((response) => {
        setCreditNoteAmount(response?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [supplier_id]);
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/scm/prfs_LLC/${IENetworksLLCId}`)
      .then((response) => {
        setEFTAmount(response?.data?.data);
      })
      .catch((error) => {
        console.error(error, "error for LLC");
      });
  }, [IENetworksLLCId]);
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/scm/prfs_PLC/${IENetworkSolutionsID}`)
      .then((response) => {
        setPLCAmount(response?.data?.data);
      })
      .catch((error) => {
        console.error(error, "error for PLC");
      });
  }, [IENetworkSolutionsID]);

  const [createPRF] = useCreatePrfMutation();
  const credit =
    paymentmode?.data?.find((payment) => payment?.id == payment_mode)
      ?.payment_mode == "Credit Note";
  const EFT =
    paymentmode?.data?.find((payment) => payment?.id == payment_mode)
      ?.payment_mode == "EFT";
  const PLC =
    paymentmode?.data?.find((payment) => payment?.id == payment_mode)
      ?.payment_mode == "PLC";

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (credit) {
      form.amount = Math.min(amount, creditNoteAmount?.creditNoteAmount);
    }
    if (EFT) {
      form.amount = Math.min(amount, EFTAmount?.amount);
    }
    if (PLC) {
      form.amount = Math.min(amount, PLCAmount?.amount);
    }

    createPRF(form)
      .unwrap()
      .then((response) => {
        props?.modal(false);
        props?.successMessage(true);
        props?.message("PRF created successfully!!!");
      })
      .catch((error) => {
        console.error("Error creating PRF", error);
      });
  };

  const CheckBoxHandelToT = () => {
    setToT(!is_tot);
  };
  const CheckBoxHandelWOWithHolder = () => {
    setWithOutHolding(!with_out_withholding);
  };
  const CheckBoxHandelWOVat = () => {
    setWithOutVAT(!with_out_vat);
  };

  let payed_amount = props?.PoData?.payed_amount;
  let total_amount = props?.PoData?.total_payment - payed_amount;

  return (
    <div>
      <div id="myModal" className="medium-modal" onClick={HandleCancel}>
        <div
          className="medium-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="create-project-modal-title">
            <div>Generate PRF</div>
            <CloseIcon onClick={HandleCancel} />
          </div>
          {props?.data != null && props?.isApproved ? (
            <GeneratePRF data={props?.data} />
          ) : (
            ""
          )}

          <form
            className="create-order-form mt-4"
            enctype="multipart/form-data"
            onSubmit={(e) => handleSubmit(e)}
          >
            <div className="order-supplier">
              <div className="supplier-input-labels">
                <div className="d-flex align-items-center gap-4">
                  <div className="d-flex flex-column">
                    <div className="d-flex  justify-content-center gap-2 px-2">
                      <input
                        checked={is_tot}
                        type="checkbox"
                        className="stock-checkmark"
                        onChange={CheckBoxHandelToT}
                      />
                      <span className="h6">TOT(Turn Over Tax)</span>
                    </div>
                    {is_tot == true ? (
                      <div
                        className="d-flex align-items-center gap-3"
                        // style={{ marginTop: "-18px" }}
                      >
                        <div className="d-flex align-items-center justify-content-center gap-2 px-2">
                          <input
                            type="radio"
                            value={1}
                            checked={selectedOptiontot == 1}
                            onChange={handleOptionChange}
                          />
                          <div className="">With 2%</div>
                        </div>
                        <div className="d-flex align-items-center justify-content-center gap-2 px-2">
                          <input
                            type="radio"
                            value={2}
                            checked={selectedOptiontot == 2}
                            onChange={handleOptionChange}
                          />
                          <div className="">With 10%</div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="d-flex  justify-content-center gap-2 px-2">
                    <input
                      defaultChecked={
                        props?.data?.with_out_vat == 1 ? true : false
                      }
                      type="checkbox"
                      className="stock-checkmark"
                      onChange={CheckBoxHandelWOVat}
                    />
                    <span className="h6">With out VAT</span>
                  </div>
                  <div className="d-flex flex-column">
                    <div className="d-flex justify-content-center gap-2 px-2">
                      <input
                        defaultChecked={
                          props?.data?.with_out_vat == 1 ? true : false
                        }
                        type="checkbox"
                        className="stock-checkmark"
                        onChange={CheckBoxHandelWOWithHolder}
                      />
                      <span className="h6">With out WithHolding</span>
                    </div>
                    {!with_out_withholding && (
                      <div className="d-flex align-items-center gap-3">
                        <label className="d-flex align-items-center gap-2">
                          <input
                            type="radio"
                            value={1}
                            checked={selectedOptionWithholdings == 1}
                            onChange={handleWithholdingOptionChange}
                          />
                          <span>With 2%</span>
                        </label>
                        <label className="d-flex align-items-center gap-2">
                          <input
                            type="radio"
                            value={2}
                            checked={selectedOptionWithholdings == 2}
                            onChange={handleWithholdingOptionChange}
                          />
                          <span>With 30%</span>
                        </label>
                      </div>
                    )}
                  </div>
                </div>

                <div className="d-flex justify-content-center gap-2">
                  <div className="prg-pri">Total Payment:</div>
                  <div className="prg">
                    {Number(total_amount).toLocaleString()}
                  </div>
                </div>
                {amount > total_amount &&
                payment_mode !== "1fe38a43-b9fa-11ee-a0bc-00e93a68026e" ? (
                  <div className="prg-error text-danger text-center">
                    Please correct your amount
                  </div>
                ) : (
                  ""
                )}

                <div className="d-grid justify-content-center gap-3">
                  <div className="d-flex gap-2 align-items-baseline">
                    <div className="supplier-input-label">Budget Number</div>
                    <input
                      value={budget_number}
                      type="text"
                      className={`supplier-text-input`}
                      required
                      onChange={(e) => setBudgetNumber(e.target.value)}
                    />
                  </div>

                  <div className="d-flex gap-2 align-items-baseline">
                    <div className="supplier-input-label">Payment Mode</div>
                    <select
                      value={payment_mode}
                      name=""
                      id=""
                      className="supplier-select-option"
                      required
                      onChange={(e) => {
                        const selectedPaymentModeId = e.target.value;
                        setPaymentMode(selectedPaymentModeId);

                        const selectedPaymentMode = paymentmode?.data?.find(
                          (item) => item.id === selectedPaymentModeId
                        );

                        setPaymentModeName(
                          selectedPaymentMode?.payment_mode || ""
                        );

                        if (selectedPaymentMode?.payment_mode === "PLC") {
                          const IENetworksId = Suppliers?.data?.find(
                            (supplier) =>
                              supplier?.distributor_name ===
                              "IE NETWORKSOLUTIONS"
                          );

                          if (IENetworksId) {
                            setIENetworkSolutionsID(IENetworksId?.id);
                          }
                        }
                        if (selectedPaymentMode?.payment_mode === "EFT") {
                          const IENetworksLLCId = Suppliers?.data.find(
                            (supplier) =>
                              supplier?.distributor_name === "IE Netwoks LLC"
                          );
                          if (IENetworksLLCId) {
                            setIENetworksLLCId(IENetworksLLCId?.id);
                          }
                        }
                      }}
                    >
                      <option value="" disabled selected hidden>
                        Choose Payment Mode
                      </option>
                      {paymentmode?.data?.map((items) => (
                        <option value={items.id}>{items.payment_mode}</option>
                      ))}
                    </select>
                  </div>
                  {credit ? (
                    <>
                      <div className="d-flex justify-content-center gap-2">
                        <div className="prg-pri" style={{}}>
                          Credit Note:
                        </div>
                        <div className="prg">
                          {Number(
                            creditNoteAmount?.creditNoteAmount
                              ? creditNoteAmount?.creditNoteAmount
                              : 0
                          )?.toLocaleString()}
                        </div>
                      </div>
                      {Number(amount) >
                      Number(creditNoteAmount?.creditNoteAmount) ? (
                        <div className="prg-error text-danger text-center">
                          Please correct your amount
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {EFT ? (
                    <>
                      <div className="d-flex justify-content-center gap-2">
                        <div className="prg-pri" style={{}}>
                          Total EFT amount:
                        </div>
                        <div className="prg">
                          {Number(
                            EFTAmount?.amount ? EFTAmount?.amount : 0
                          )?.toLocaleString()}
                        </div>
                      </div>

                      {Number(amount) > Number(EFTAmount?.amount) &&
                      Number(amount) > Number(total_amount) ? (
                        <div className="prg-error text-danger text-center">
                          Please correct your amount
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {PLC ? (
                    <>
                      <div className="d-flex justify-content-center gap-2">
                        <div className="prg-pri" style={{}}>
                          Total PLC amount:
                        </div>
                        <div className="prg">
                          {Number(
                            PLCAmount?.amount ? PLCAmount?.amount : 0
                          )?.toLocaleString()}
                        </div>
                      </div>

                      {Number(amount) > Number(PLCAmount?.amount) &&
                      Number(amount) > Number(total_amount) ? (
                        <div className="prg-error text-danger text-center">
                          Please correct your amount
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  <div className="d-flex gap-2 align-items-baseline">
                    <div className="supplier-input-label">Amount</div>
                    <input
                      value={amount}
                      type="number"
                      className={`supplier-text-input`}
                      required
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </div>
                  <div className="d-flex gap-2 align-items-baseline">
                    <div className="supplier-input-label">Payment Date</div>
                    <input
                      value={paymentDate}
                      type="date"
                      className={`supplier-text-input`}
                      required
                      onChange={(e) => setPaymentDate(e.target.value)}
                    />
                  </div>
                  <div className="d-flex gap-2 align-items-baseline">
                    <div className="supplier-input-label">Account Type</div>
                    <select
                      value={account_type}
                      name=""
                      id=""
                      className="supplier-select-option"
                      required
                      onChange={(e) => setAccountType(e.target.value)}
                    >
                      <option value="" disabled selected hidden>
                        Choose Account Type
                      </option>
                      {accountTypes?.map((items) => (
                        <option value={items}>{items}</option>
                      ))}
                    </select>
                  </div>
                  <div className="d-flex gap-2 align-items-baseline">
                    <div className="supplier-input-label">Bank</div>
                    <select
                      value={bank_id}
                      name=""
                      id=""
                      className="supplier-select-option"
                      required
                      onChange={(e) => setBank(e.target.value)}
                    >
                      <option value="" disabled selected hidden>
                        Choose Bank
                      </option>
                      {bankList?.data?.map((items) => (
                        <option value={items.id}>{items.bank_name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="d-flex gap-2 align-items-baseline">
                    <div className="supplier-input-label">Requested By</div>
                    <select
                      value={requested_by}
                      name=""
                      id=""
                      className="supplier-select-option"
                      required
                      onChange={(e) => setRequestBy(e.target.value)}
                    >
                      <option value="" disabled selected hidden>
                        Choose Requested By
                      </option>
                      {users?.data?.map((items) => (
                        <option value={items.id}>{items.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="d-flex gap-2 align-items-baseline">
                    <div className="supplier-input-label">Prepared By</div>
                    <select
                      value={prepared_by}
                      name=""
                      id=""
                      className="supplier-select-option"
                      required
                      onChange={(e) => setPreparedBy(e.target.value)}
                    >
                      <option value="" disabled selected hidden>
                        Choose Prepared By
                      </option>
                      {users?.data?.map((items) => (
                        <option value={items.id}>{items.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="d-flex gap-2 align-items-baseline">
                    <div className="supplier-input-label">Approved By</div>
                    <select
                      value={approved_by}
                      name=""
                      id=""
                      className="supplier-select-option"
                      required
                      onChange={(e) => setApprovedBy(e.target.value)}
                    >
                      <option value="" disabled selected hidden>
                        Choose Approved By
                      </option>
                      {users?.data?.map((items) => (
                        <option value={items.id}>{items.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="d-flex gap-2 align-items-baseline">
                    <div className="supplier-input-label">Paid By</div>
                    <select
                      value={paid_by}
                      name=""
                      id=""
                      className="supplier-select-option"
                      required
                      onChange={(e) => setPaidBy(e.target.value)}
                    >
                      <option value="" disabled selected hidden>
                        Choose Paid By
                      </option>
                      {users?.data?.map((items) => (
                        <option value={items.id}>{items.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="d-flex gap-2 align-items-baseline">
                    <div className="supplier-input-label">Reason For</div>
                    <textarea
                      type="text"
                      className="sourcing-text-input-textarea"
                      rows={4}
                      cols={50}
                      onChange={(e) => setReasonFor(e.target.value)}
                    >
                      {reason_for}
                    </textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-buttons ">
              <button type="cancel" class="form-cancel" onClick={HandleCancel}>
                Cancel
              </button>
              <button
                type="submit"
                disabled={amount > total_amount ? true : false}
                class={
                  amount > total_amount
                    ? "form-save bg-secondary text-white"
                    : "form-save"
                }
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
