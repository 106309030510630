import React from "react";
import Faq from "./Faq";
import Accordion from "react-bootstrap/Accordion";
import {
  useGetFaqAnswersQuery,
  useGetFaqQuestionsQuery,
  useGetFaqVideosQuery,
} from "../../features/SCMApi";
import { NavLink } from "react-router-dom";
import NoRecord from "../../components/common/NoRecord";
import { useState } from "react";
import AnswerFaqQuestions from "../../components/home/AnswerFaqQuestions";
import AskQuestion from "./AskQuestion";

export default function FaqIndex(props) {
  const { data: faqs } = useGetFaqAnswersQuery();
  const [askQuestionModal, setAskQuestionModal] = useState();
  const { data: faqVideos } = useGetFaqVideosQuery();
  const { data: faqQuestions } = useGetFaqQuestionsQuery();


  return (
    <Faq>
      {askQuestionModal ? (
        <AskQuestion ToggleModal={setAskQuestionModal} />
      ) : (
        <></>
      )}
      <div
        className="create-project-btn"
        onClick={(e) => setAskQuestionModal(true)}
      >
        Ask Question
      </div>
      <div className="faq-main-content">
        <div className="top-questions">
          <div className="card-title">Top Questions</div>
          <div className="divider"></div>
          <div className="top-question-container">
            {faqs?.data.length > 0 ? (
              <div className="according-container mt-2">
                {" "}
                {faqs?.data?.map((items) => (
                  <Accordion defaultActiveKey="0" flush>
                    <Accordion.Item eventKey={items?.id}>
                      <Accordion.Header className="local-border-none">
                        {items?.question}
                      </Accordion.Header>
                      <Accordion.Body>{items?.answer}</Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                ))}
              </div>
            ) : (
              <NoRecord />
            )}
          </div>
        </div>
        <div className="faq-main-right-section">
          <NavLink
            to={"TotalQuestions"}
            className="total-questions-card total-q-green-linear"
          >
            <div className="faq-main-right-section-number-font">
              {faqQuestions?.data?.length < 1
                ? "No data"
                : faqQuestions?.data?.length < 1}
            </div>
            <div className="faq-main-right-section-text-font">
              Total Questions
            </div>
          </NavLink>

          {/* <div className="total-questions-card total-q-blue-linear">
            <div className="faq-main-right-section-number-font">8</div>
            <div className="faq-main-right-section-text-font">
              New Questions
            </div>
          </div> */}

          <NavLink
            to={"FaqVideos"}
            className="total-questions-card total-q-red-linear"
          >
            <div className="faq-main-right-section-number-font">
              {faqVideos?.data?.length < 1
                ? "No data"
                : faqVideos?.data?.length}
            </div>
            <div className="faq-main-right-section-text-font">
              Video Tutorials
            </div>
          </NavLink>
        </div>
      </div>
    </Faq>
  );
}
