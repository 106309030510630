import React, { useEffect, useState } from "react";
import "../forex.style.css";
import { IconButton, Pagination, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ForexSideBar from "../../../components/Payment/ForexApproval/PaymentSideBar";
import {
  useGetGrossProfitBirrQuery,
  useGetGrossProfitQuery,
} from "../../../features/SCMApi";
import usePagination from "../../Pagination/Pagination";
import { NavLink } from "react-router-dom";
import product from "./../../../assets/SVG/gift.png";
import service from "./../../../assets/SVG/service.png";
import NoRecord from "../../common/NoRecord";
import GrossReport from "./GrossReport";
import GrossProfitModal from "./GrossProfitModal";
import { API_BASE_URL } from "../../../api/endPoint";
import axios from "axios";

export default function GrossProfitProduct() {
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const handleSubmit = () => {


  };
  const { data: gross } = useGetGrossProfitBirrQuery({ start, end });

  const [search, setSearch] = useState("");
  const handleSearchChange = (e) => {
    setSearch(e.target.value);

  };
  const filteredData = () => {
    return gross?.data
      ?.filter((items) => {
        if (search === "") {
          return items;
        } else if (
          items.project?.toLowerCase().includes(search.toLowerCase())
        ) {
          return items;
        }
      })

      .map((items) => items);
  };
  let [page, setPage] = React.useState(1);
  const PER_PAGE = 9;
  const count = Math.ceil(
    gross == undefined ? [] : filteredData()?.length / PER_PAGE
  );
  const _DATA = usePagination(filteredData(), PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  const [Items, setItems] = useState("all");
  let grossData = gross?.data?.filter(
    (items) => items?.gross_profit?.length != 0
  );
  let revenueData = grossData?.filter(
    (items) => items?.gross_profit[0]?.revenue != 0
  );
  let TotalRevenueAmount = 0;
  let TotalActualAmount = 0;
  grossData?.map(
    (items, index) =>
      (TotalRevenueAmount += Number(items?.gross_profit[0].revenue))
  );
  grossData?.map(
    (items, index) => (TotalActualAmount += Number(items?.gross_profit[0].cost))
  );

  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [data, setData] = useState([]);
  const [project_id, setProjectId] = useState("");

  const handleCardClick = (item) => {
    setSelectedItem(item);
    setProjectId(item);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null);
    setProjectId(null);
  };
  useEffect(() => {
    if (project_id && start && end) {


      axios
        .get(
          // "https://jsonplaceholder.typicode.com/posts"
          `${API_BASE_URL}/scm/get-prf-revenue/${project_id}?startDate=${start}&endDate=${end}`
        )
        .then((response) => {
          setData(response?.data);

        })
        .catch((error) =>
        {}
        );
    }
  }, [project_id, start, end]);


  return (
    <ForexSideBar
      title={"Gross Profit"}
      name={"Product"}
      navlink={`/payment/grossprofit`}
      handleSearchChange={handleSearchChange}
      placeholder={"search Project..."}
    >
      <div className="tool-header-container d-flex gap-3 justify-content-center align-items-center mb-2">
        <h5 className="logistics-batch-name">Please select date</h5>
        <div className="form-group">
          <label htmlFor="startDate" className="origin-label">
            Start Date:
          </label>
          <input
            type="date"
            className="form-control"
            id="startDate"
            value={start}
            onChange={(e) => setStart(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="endDate" className="origin-label">
            End Date:
          </label>
          <input
            type="date"
            className="form-control"
            id="endDate"
            value={end}
            onChange={(e) => setEnd(e.target.value)}
          />
        </div>
        {grossData?.length && <GrossReport data={grossData} />}
      </div>
      <div className="supplier-menu-container">
        {_DATA?.currentData()?.map((items) => (
          <div
            className="gross-card d-grid align-items-start"
            style={{ cursor: "pointer" }}
            onClick={() => handleCardClick(items?.project_id)}
          >
            <div className="">
              <div className="forex-detail-card-sub">
                <div className="forex-card-left-grid">
                  <div className="batch-container">
                    <Tooltip title={items?.project}>
                      <div className="logistics-batch-name mt-3">
                        {items?.project?.length > 20
                          ? items?.project.slice(0, 20) + "..."
                          : items?.project}
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>
              {items?.gross_profit?.length != 0 ? (
                <div className="d-flex justify-content-center gap-3 my-4 mx-3">
                  {items?.gross_profit?.map((gross) => (
                    <>
                      <div className="d-grid gap-2">
                        <div>
                          <div className="origin-values">
                            {gross?.currency ? gross?.currency : "-"}
                          </div>
                          <div className="origin-label">currency</div>
                        </div>
                        <div>
                          <div className="origin-values">
                            {gross?.revenue == null
                              ? 0
                              : Number(gross?.revenue).toLocaleString()}
                          </div>

                          <div className="origin-label">Revenue</div>
                        </div>

                        <div className="origin-group">
                          <div className="origin-values">
                            {Number(gross?.cost).toLocaleString()}
                          </div>

                          <div className="origin-label">Actual Cost</div>
                        </div>
                        <div className="">
                          <div className="origin-values">
                            {Number(gross?.gross_profit).toLocaleString()}
                          </div>
                          <div className="origin-label">
                            Gross Profit Amount
                          </div>
                        </div>
                        <div className="">
                          <div className="origin-values">
                            {Number(
                              gross?.revenue - gross?.cost
                            ).toLocaleString()}
                          </div>
                          <div className="origin-label">Gross Profit</div>
                        </div>
                        <div className="origin-group">
                          <div className="origin-values ">
                            {TotalRevenueAmount
                              ? Number(
                                  (gross?.revenue / TotalRevenueAmount) * 100
                                )?.toLocaleString()
                              : 0}
                            %
                          </div>
                          <div className="origin-label">GPP</div>
                        </div>
                      </div>
                      <div className="border"></div>
                    </>
                  ))}
                </div>
              ) : (
                <NoRecord />
              )}
            </div>
          </div>
        ))}
      </div>
      {count > 1 ? (
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            margin: "5px",
          }}
        />
      ) : (
        ""
      )}
      {selectedItem && (
        <GrossProfitModal
          show={showModal}
          handleClose={handleCloseModal}
          item={selectedItem}
          data={data}
        />
      )}
    </ForexSideBar>
  );
}
