import React from "react";
import { MdOutlineCloudDownload } from "react-icons/md";
import { API_BASE_URL } from "../../../../api/endPoint";
import { Link } from "react-router-dom";

const SOASummaryReport = () => {
  return (
    <Link to={`${API_BASE_URL}/scm/payment/soa_summery/0`} target="_blank">
      <div
        style={{
          color: "#ffffff",
          border: "1.5px solid #064b4f",
          borderRadius: "4px",
          backgroundColor: "#064b4f",
          fontWeight: "400",
          padding: "6px",
          fontSize: "14px",
          lineHeight: "16px",
          letterSpacing: "0.105em",
          outline: "none",
          height: "32px",
          color: "#ffffff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "12px",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <MdOutlineCloudDownload />
        SOA Summary Report
      </div>
    </Link>
  );
};

export default SOASummaryReport;
