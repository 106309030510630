import * as React from "react";
import {
  useAddForexTermConditionMutation,
  useGetForexPaymentModeQuery,
  useGetForexShipmentModeQuery,
  useGetForexLoadingOriginQuery,
  useGetForexDischargePortQuery,
  useGetForexAirLoadingPortQuery,
  useGetSeaLoadingPortQuery,
  useGetForexOpeningToQuery,
  useGetForexIncotermsQuery,
} from "../../../../features/SCMApi";
import axios from "axios";
import { API_BASE_URL } from "../../../../api/endPoint";
import "../../../../components/Logistics/logistics.css";
import Select from "../Select";
import DatePicker from "../../ForexPlanning/DatePicker";
import MultiSelect from "../MultiSelect";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import MUISelect from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { useParams } from "react-router-dom";

const AddTermAndConditionsModal = (props) => {
  const forexId = useParams();
  const [addForexTerm] = useAddForexTermConditionMutation();
  const { data: paymentMode } = useGetForexPaymentModeQuery();
  const { data: openingSupplier } = useGetForexOpeningToQuery();
  const { data: modeOfShipment } = useGetForexShipmentModeQuery();
  const { data: loadingOrigin } = useGetForexLoadingOriginQuery();
  const { data: airportLoading } = useGetForexAirLoadingPortQuery();
  const { data: seaportLoading } = useGetSeaLoadingPortQuery();
  const { data: portOfDischarge } = useGetForexDischargePortQuery();
  const { data: incoterms } = useGetForexIncotermsQuery();

  const [paymentModeValue, setPaymentModeValue] = React.useState(null);
  const [validityDate, setValidityDate] = React.useState(null);
  const [openingTo, setOpeningTo] = React.useState(null);
  const [shipmentMode, setShipmentMode] = React.useState(null);
  const [transShipment, setTransShipment] = React.useState(null);
  const [partialShipment, setPartialShipment] = React.useState(null);
  const [countryOfOrigin, setCountryOfOrigin] = React.useState(null);
  const [portOfLoading, setPortOfLoading] = React.useState(null);
  const [dischargePort, setDischargePort] = React.useState(null);
  const [freightId, setFreightId] = React.useState(null);
  const [incoterm, setIncoterm] = React.useState(null);
  const [openingDate, setOpeningDate] = React.useState(null);
  const [expiration_date, setExpirationDate] = React.useState(null);
  const [referenceNumber, setReferenceNumber] = React.useState(null);
  const [approval_date, setApprovedDate] = React.useState("");

  const shipmentPortData =
    shipmentMode === "Air"
      ? countryOfOrigin !== null
        ? airportLoading?.data
            ?.filter(
              (airPort) =>
                airPort?.country?.toLowerCase() ===
                countryOfOrigin?.toLowerCase()
            )
            ?.map((airPort) => airPort?.port_name)
            ?.flat()
        : ["Not yet"]
      : shipmentMode === "Sea"
        ? seaportLoading?.data
            ?.filter((seaPort) => seaPort?.country === countryOfOrigin)
            ?.map((seaPort) => seaPort?.port_name)
            ?.flat()
        : ["Please select shipment mode"];

  const shipmentMapData =
    shipmentPortData?.length !== 0 ? shipmentPortData : ["No port available"];
  const origin = loadingOrigin?.data?.find(
    (origin) => origin?.name === countryOfOrigin
  )?.id;

  //MultiSelect
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 226,
        marginTop: -10,
      },
    },
    disableScrollLock: false,
  };

  // const [originName, setOriginName] = React.useState([]);
  // const origin = loadingOrigin?.data
  //   ?.filter((origin) => originName?.includes(origin?.name))
  //   ?.map((origin) => origin?.id);
  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setOriginName(typeof value === "string" ? value?.split(",") : value);
  // };

  let params = {
    payment_mode_id: paymentMode?.data?.find(
      (modeOfPayment) => modeOfPayment?.payment_mode === paymentModeValue
    )?.id,
    validity_date: validityDate,
    opening_to: openingSupplier?.data?.find(
      (airPort) => airPort?.distributor_name === openingTo
    )?.id,
    shipment_mode_id: modeOfShipment?.data?.find(
      (modeOfShipment) => modeOfShipment?.shipment_mode === shipmentMode
    )?.id,
    forex_id: forexId?.id,
    trans_shipment: transShipment,
    partial_shipment: partialShipment,
    loading_origin_id: origin,

    air_loading_id: airportLoading?.data?.find(
      (airPort) => airPort?.port_name === portOfLoading
    )?.id,
    sea_loading_id: seaportLoading?.data?.find(
      (seaport) => seaport?.port_name === portOfLoading
    )?.id,
    discharge_port_id: portOfDischarge?.data?.find(
      (airPort) => airPort?.port_name === dischargePort
    )?.id,
    incoterm_id: incoterms?.data?.find(
      (incot) => incot?.incoterm_name === incoterm
    )?.id,
    freight: freightId,
    opening_date: openingDate,
    expiration_date: expiration_date,
    approval_date: approval_date,
    reference_number: referenceNumber,
    expiration_date,
  };

  function HandleCancel() {
    props.modal(false);
  }


 
  const HandleSubmit = async (e) => {
    
    e.preventDefault();
    addForexTerm(params)
      // axios
      //   .post(`${API_BASE_URL}/scm/payment/forex-term`, params)
      //   .then((response) =>)
      //   .catch((error) => );
      .unwrap()
      .then((response) => {
        HandleCancel();
        props?.successMessage(true);
        props?.message("Term and condition created successfully!!!");
      
      })
      .then((error) => {
        
      });
  };

  const transShipmentData = ["Allowed", "Not allowed"];
  const partialShipmentData = ["Allowed", "Not allowed"];
  const freight = ["Freight on board", "Freight on destination"];

  return (
    <>
      <div id="myModal" className="modal" onClick={HandleCancel}>
        <div
          className="mini-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="logisticsRma-create-project-modal-title">
            <div className="AddNew">Add Terms</div>
            <div onClick={HandleCancel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="42"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>

          <form
            className="form-container paymentForexApprovalinitiateForex"
            onSubmit={HandleSubmit}
          >
            <div className="form-card-container">
              <div className="form-card-information">
                <div className="card-center">
                  <div className="card-form">
                    <div className="input-labels">
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "16px" }}
                      >
                        Payment Mode
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "10px", marginLeft: "-25px" }}
                      >
                        Reference No.
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "10px" }}
                      >
                        Validity Date
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "10px" }}
                      >
                        PI Date
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "10px" }}
                      >
                        Approval Date
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "10px" }}
                      >
                        Expiration Date
                      </div>

                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "14px" }}
                      >
                        Opening to
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "9px" }}
                      >
                        Mode of Shipment
                      </div>

                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "5px" }}
                      >
                        Trans Shipment
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "10px" }}
                      >
                        Partial Shipment
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "5px" }}
                      >
                        Country of Origin
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "7px" }}
                      >
                        Port of Loading
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "5px" }}
                      >
                        Port of Discharge
                      </div>
                      {/* <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "5px" }}
                      >
                        Freight
                      </div> */}
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "5px" }}
                      >
                        Incoterm
                      </div>
                    </div>
                    <div className="forex-input-fields">
                      <div>
                        <Select
                          name={paymentMode?.data?.map(
                            (modeOfPayment) => modeOfPayment?.payment_mode
                          )}
                          onChange={setPaymentModeValue}
                          required={true}
                        />
                      </div>
                      <div style={{ marginLeft: "-4px" }}>
                        <input
                          required
                          defaultValue={""}
                          className="payment-term-and-condition-input"
                          placeholder="Enter reference number."
                          onChange={(event) =>
                            setReferenceNumber(event.target.value)
                          }
                        />
                      </div>
                      <div style={{ marginLeft: "-4px" }}>
                        <input
                          required
                          type="number"
                          defaultValue={""}
                          className="payment-term-and-condition-input"
                          placeholder="Enter validity date"
                          onChange={(event) =>
                            setValidityDate(event.target.value)
                          }
                        />
                      </div>
                      <div style={{ marginLeft: "-4px" }}>
                        <input
                          type="date"
                          className="payment-term-and-condition-input"
                          placeholder="Enter opening date"
                          required
                          onChange={(e) => setOpeningDate(e.target.value)}
                        />
                      </div>
                      <div style={{ marginLeft: "-4px" }}>
                        <input
                          type="date"
                          className="payment-term-and-condition-input"
                          placeholder="Enter opening date"
                          required
                          onChange={(e) => setApprovedDate(e.target.value)}
                        />
                      </div>

                      <div style={{ marginLeft: "-4px" }}>
                        <input
                          type="date"
                          className="payment-term-and-condition-input"
                          placeholder="Enter expiration date"
                          required
                          onChange={(e) => setExpirationDate(e.target.value)}
                        />
                      </div>

                      <div>
                        <Select
                          name={openingSupplier?.data?.map(
                            (supplier) => supplier?.distributor_name
                          )}
                          onChange={setOpeningTo}
                          required={true}
                        />
                      </div>
                      <div>
                        <Select
                          name={modeOfShipment?.data?.map(
                            (shipmentMode) => shipmentMode?.shipment_mode
                          )}
                          onChange={setShipmentMode}
                          required={true}
                        />
                      </div>
                      <div>
                        <Select
                          name={transShipmentData}
                          onChange={setTransShipment}
                          required={true}
                        />
                      </div>
                      <div>
                        <Select
                          name={partialShipmentData}
                          onChange={setPartialShipment}
                          required={true}
                        />
                      </div>
                      <div>
                        {/* <MultiSelect
                          name={loadingOrigin?.data?.map(
                            (origin) => origin?.name
                          )}
                          required={true}
                        /> */}
                        <Select
                          name={loadingOrigin?.data?.map(
                            (origin) => origin?.name
                          )}
                          onChange={setCountryOfOrigin}
                          required={true}
                        />
                        {/* <FormControl sx={{ m: 1, width: 226 }}>
                          <MUISelect
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={originName}
                            onChange={(e) => handleChange(e)}
                            input={<OutlinedInput label="Tag" />}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                            sx={{
                              width: 226,
                              height: 32,
                              backgroundColor: "#ececec",
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              color: "#186569",
                            }}
                            required={true}
                          >
                            {loadingOrigin?.data &&
                              loadingOrigin?.data?.map((origin) => (
                                // <Tooltip title={order?.order_name} arrow>
                                <MenuItem
                                  key={origin?.id}
                                  value={origin?.name}
                                  sx={{
                                    color: "#186569",
                                  }}
                                >
                                  <Checkbox
                                    checked={
                                      originName.indexOf(origin?.name) > -1
                                    }
                                    sx={{
                                      color: "#186569",
                                      height: "25px",
                                      "&.Mui-checked": {
                                        color: "#186569",
                                        height: "25px",
                                      },
                                    }}
                                  />
                                  <ListItemText primary={origin?.name} />
                                </MenuItem>
                                //  </Tooltip>
                              ))}
                          </MUISelect>
                        </FormControl> */}
                      </div>
                      <div>
                        <Select
                          name={shipmentMapData}
                          onChange={setPortOfLoading}
                          required={true}
                        />
                      </div>
                      <div>
                        <Select
                          name={portOfDischarge?.data?.map(
                            (dischargePort) => dischargePort?.port_name
                          )}
                          onChange={setDischargePort}
                          required={true}
                        />
                      </div>
                      {/* <div>
                        <Select
                          name={freight}
                          onChange={setFreightId}
                          required={true}
                        />
                      </div> */}
                      <div>
                        <Select
                          name={incoterms?.data?.map(
                            (incoterm) => incoterm?.incoterm_name
                          )}
                          onChange={setIncoterm}
                          required={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-buttons clientForm">
                <button
                  type="cancel"
                  className="form-cancel clientCancel"
                  style={{ width: "112px", height: "32px" }}
                  onClick={HandleCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="form-save clientSave"
                  style={{ width: "112px", height: "32px" }}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddTermAndConditionsModal;
