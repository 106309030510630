import { Tooltip } from "@mui/material";
import React from "react";
import {
  useGetManufacturersQuery,
  useGetMappingsQuery,
} from "../../features/SCMApi";
import NoRecord from "../common/NoRecord";
export default function MappingManufaturer(props) {
  const {
    data: mappings,
    isError,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetManufacturersQuery();

  return (
    <div className="">
      {mappings?.data?.length !== 0 ? (
        <>
          {mappings?.data
            ?.filter((items) => {
              if (props.search == "") {
                return items;
              } else if (
                items.manufacturer_name?.toLowerCase().includes(props.search)
              ) {
                return items;
              }
            })
            .map((items) => (
              <div className="mapping-card-container primary pb-5 mb-3">
                <div className="mapping-card-header mt-4 ms-5">
                  {items.manufacturer_name}
                </div>
                <div className="row products_padding">
                  {items?.product_supplier?.map((product) => (
                    <div class="col-sm-3 col-md-2 me-3">
                      <div class="card mapping">
                        <Tooltip title={product?.distributor_name}>
                          <div>
                            <p class="h5 text-center my-2">
                              {product?.distributor_name?.length >= 10
                                ? product?.distributor_name.slice(0, 10) + "..."
                                : product?.distributor_name}
                            </p>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </>
      ) : (
        <NoRecord />
      )}
    </div>
  );
}
