// import React from "react";

// export default function ComparedPrice() {
//   return <div>ComparedPrice</div>;
// }

import React, { useState } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import {
  useDeleteOrderMutation,
  useGetFinalPriceByProjectQuery,
  useGetProjectItemQuery,
  useGetProjectQuery,
  useGetProjectRolePermissionQuery,
  useGetProjectsQuery,
  useGetUserInfoQuery,
} from "../../../features/SCMApi";
import AddIcon from "@mui/icons-material/Add";
import { API_BASE_URL, IMG_BASE_URL } from "../../../api/endPoint";
import NoRecord from "../../common/NoRecord";
import SourcingIndex from "../../../pages/Sourcing/SourcingIndex";
import CheckProjectPermission from "../../common/checkPermission/CheckProjectPermission";
import { Pagination, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import SuccessMessage from "../../common/SuccessMessage";
import { Alert, Box, Collapse, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import usePagination from "../../Pagination/Pagination";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import RecommendIcon from "@mui/icons-material/Recommend";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import ReactPaginate from "react-paginate";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
export default function ComparedPrice(props) {
  const [createOrderModalToggle, setCreateOrderModalToggle] = useState(false);

  function handleCreateOrderModal() {
    setCreateOrderModalToggle(true);
  }
  // const [createOrderModalToggle, setCreateOrderModalToggle] = useState(false);
  // function handleCreateOrderModal() {
  //   setCreateOrderModalToggle(true);
  // }
  const params = useParams();
  const { data: projectdetail } = useGetProjectItemQuery(params.id);
  const order = [];
  order.push({ data: projectdetail?.data?.order });


 

  let componentData = projectdetail?.data?.order?.map((items) => items);


  const { data: responses } = useGetProjectsQuery();
  const Projectdata = [];

  responses?.data?.map((items) => {
    if (params.id === items.id) {
      Projectdata.push(items);
    }
  });
  let avatar = [];
  const { data: projectmembers, isSuccess } = useGetProjectQuery(params.id);
  const { data: priceComparison } = useGetFinalPriceByProjectQuery(params.id);
  const { data: roleRoles } = useGetProjectRolePermissionQuery(params.id);
  const { data: userinfo } = useGetUserInfoQuery();

  const [deletedorder, setDeletedOrder] = useState();
  const [confirmation, setConfirmation] = useState(false);
  function confirmationHandler() {
    setConfirmation(true);
  }
  let deleted = {
    id: deletedorder?.id,
  };
  const [deleteorders, res] = useDeleteOrderMutation();
  const location = useLocation();
  const [timeOut, setTimeOut] = useState(true);
  setTimeout(() => {
    setTimeOut(false);
  }, 3000);
  const [open, setOpen] = useState(true);

  let [page, setPage] = useState(0);
  const PER_PAGE = 12;
  const count = Math.ceil(
    componentData?.filter((items) => {
      if (props?.search === "") {
        return items;
      } else if (
        items?.order_name?.toLowerCase()?.includes(props?.search?.toLowerCase())
      ) {
        return items;
      }
      return false;
    }).length / PER_PAGE
  );
  const pageVisted = page * PER_PAGE;
  const handleChange = ({ selected }) => {
    setPage(selected);
  };

  let pa = projectdetail?.data?.pa?.map((items) => items);
  return (
    <SourcingIndex>
      {componentData?.length !== 0 ? (
        <div className="order-card-container mt-3">
          {priceComparison?.data?.map((dada) => (
            <div className="order-card">
              <div className="ms-2 mt-2">
                {dada?.is_approved == 0 ? (
                  <Tooltip title={"Pending"}>
                    <PendingActionsIcon sx={{ color: "#ffcc00" }} />
                  </Tooltip>
                ) : dada?.is_approved == 1 ? (
                  <Tooltip title={"Approved"}>
                    <RecommendIcon sx={{ color: "#064b4f" }} />
                  </Tooltip>
                ) : dada?.is_approved == 2 ? (
                  <Tooltip title={"Rejected"}>
                    <ThumbDownAltIcon sx={{ color: "#dc3545" }} />
                  </Tooltip>
                ) : (
                  ""
                )}
              </div>

              <NavLink to={`final/${dada.id}`}>
                <div className="order-info">
                  <div className="order-project">
                    <div>
                      <Tooltip title={dada?.created_by?.name}>
                        <div className="project-name">
                          {dada?.created_by == null
                            ? "Unknown"
                            : dada?.created_by?.name?.length >= 19
                              ? dada?.created_by?.name?.slice(0, 20) + "..."
                              : dada?.created_by?.name}
                        </div>
                      </Tooltip>
                      <div className="project-label">Created by</div>
                    </div>

                    <div>
                      <div className="project-name text-center">
                        {dada?.final_list_price?.length}
                      </div>
                      <div className="project-label">Items</div>
                    </div>
                  </div>
                </div>
                <div className="divider"></div>
              </NavLink>
              <div className="order-supplier">
                <Tooltip title={dada?.created_at}>
                  <div className="order-supplier">
                    {dada?.created_at?.length >= 60
                      ? dada?.created_at?.slice(0, 60) + "..."
                      : dada?.created_at}
                  </div>
                </Tooltip>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <NoRecord />
      )}
      <div className="mt-3">
        {count > 1 ? (
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={count}
            onPageChange={handleChange}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        ) : (
          ""
        )}
      </div>
    </SourcingIndex>
  );
}
