import React from "react";
import IconButton from "@mui/material/IconButton";
import CachedIcon from "@mui/icons-material/Cached";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import AddTermAndConditionsModal from "./Modals/AddTermAndConditionsModal";
import AddIcon from "@mui/icons-material/Add";
import EditTermAndConditionsModal from "./Modals/EditTermAndConditionsModal";
import ApproveForexModal from "./Modals/ApproveForexModal";

const IconButtons = (props) => {
  const [addTermConditionsModal, setAddTermConditionsModal] =
    React.useState(false);
  function AddTermConditionModalHandler() {
    setAddTermConditionsModal(true);
  }
  const [editTermConditionsModal, setEditTermConditionsModal] =
    React.useState(false);
  function EditTermConditionModalHandler() {
    setEditTermConditionsModal(true);
  }

  const [showDeletePaymentModal, toggelDeletePaymentModal] =
    React.useState(false);
  const toggleDeletePaymentHandler = (e) => {
    toggelDeletePaymentModal(!showDeletePaymentModal);
  };

  const handleIconButtonClick = (e, name) => {
    return name === "nOTermCondition" ? (
      AddTermConditionModalHandler()
    ) : name === "termCondition" ? (
      EditTermConditionModalHandler()
    ) : name === "foreaxApprovalButton" ? (
      toggleDeletePaymentHandler()
    ) : (
      <></>
    );
  };

  // successMessage && setTimeout(() => {
  //   setSuccessMessage(false);
  // }, 30000);

  return (
    <>
      <IconButton
        variant="text"
        size="small"
        sx={{
          backgroundColor: "#064b4f",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#186569",
            color: "#fff",
          },
        }}
        onClick={(e) => handleIconButtonClick(e, props?.name)}
      >
        {props?.name === "foreaxApprovalButton" ? (
          <CachedIcon />
        ) : props?.name === "nOTermCondition" ? (
          <AddIcon />
        ) : props?.name === "termCondition" ? (
          <ModeEditOutlinedIcon />
        ) : props?.name === "editForex" ? (
          <ModeEditOutlinedIcon />
        ) : (
          <></>
        )}
      </IconButton>
      {addTermConditionsModal ? (
        <AddTermAndConditionsModal
          modal={setAddTermConditionsModal}
          previousData={""}
          successMessage={props?.setSuccessMessage}
          message={props?.setMessage}
        />
      ) : (
        <></>
      )}
      {editTermConditionsModal ? (
        <EditTermAndConditionsModal
          modal={setEditTermConditionsModal}
          previousData={props?.previousData}
          successMessage={props?.setSuccessMessage}
          message={props?.setMessage}
        />
      ) : (
        <></>
      )}
      <ApproveForexModal
        show={showDeletePaymentModal}
        handleDeleteModal={toggleDeletePaymentHandler}
        forexId={props?.forexId}
        successMessage={props?.successMessage}
        message={props?.message}
        width={props?.style?.width}
        approved={props.approvalStatus}
        errorMessage={props.error}
      />
    </>
  );
};

export default IconButtons;
