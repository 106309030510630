import React from "react";
import SideBar from "./SideBar";

const index = (props) => {
  const token = localStorage.getItem("accessToken");
  return (
    <>
      {!token ? (
        <div>{props.children}</div>
      ) : (
        <div className="layout">
          <div>
            <SideBar />
          </div>
          <div>
            <div className="row">{props.children}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default index;
