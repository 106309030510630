import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import NavBar from "../../Layout/NavBar";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
export default function Supplier(props) {
  const [open, setOpen] = useState(true);

  return (
    <>
      <NavBar
        placeholder={props?.placeholder}
        handleSearchChange={props.handleSearchChange}
        handleChange={props.handleChange}
      />

      <div className="block">
        <div className="all-breadcrumb">
          <Breadcrumb className="">
            <Breadcrumb.Item className="prg" href="/smm">
              Supplier
            </Breadcrumb.Item>
            {/* <NavLink className="prg" to="smm">
              Supplier
            </NavLink> */}
            <Breadcrumb.Item className="prg" href={props.navlink}>
              {props.title}
            </Breadcrumb.Item>
            {props.name === undefined ? (
              ""
            ) : (
              <Breadcrumb.Item className="prg-pri" active>
                {props.name}
              </Breadcrumb.Item>
            )}
          </Breadcrumb>
        </div>
        <div className="page">
          {/* <div className="side-nav sub-side-menu">
              <div className="sidebar-text">
                <ul class="nav nav-pills flex-column  justify-content-around">
                  <li class="nav-item">
                    <NavLink
                      className=" nav-link text-white"
                      to="/manufacturers"
                      activeClassName="active"
                    >
                      Manufacturer
                    </NavLink>
                  </li>
                  <li class="nav-item">
                    <NavLink
                      className=" nav-link text-white"
                      to="/supplier"
                      activeClassName="active"
                    >
                      Suppliers
                    </NavLink>
                  </li>
                  <li class="nav-item">
                    <NavLink
                      className="nav-link text-white"
                      to="/mapping"
                      activeClassName="active"
                    >
                      SPMS Mapping
                    </NavLink>
                  </li>
                  <li class="nav-item">
                    <NavLink
                      className="nav-link text-white"
                      to="/freightforwarder"
                      activeClassName="active"
                    >
                      Freight Forward
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div> */}

          <div className="" onClick={(e) => e.stopPropagation(e)}>
            {props?.Message !== null && props?.Message !== undefined ? (
              <Box sx={{ width: "100%", color: "#FFF" }}>
                <Collapse in={open}>
                  <Alert
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                    sx={{
                      mb: 2,
                      backgroundColor: "#52FFAB96",
                      color: "#000",
                      "& .MuiAlert-icon": {
                        fontSize: 25,
                        color: "#186569",
                      },
                    }}
                  >
                    {props?.Message}
                  </Alert>
                </Collapse>
              </Box>
            ) : (
              ""
            )}

            {props.children}
          </div>
        </div>
      </div>
    </>
  );
}
