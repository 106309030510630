import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import { API_BASE_URL } from "../../api/endPoint";
import Project from "../../pages/Projects/Project";
import { useParams } from "react-router-dom";
import { useDeleteBoqMutation, useHasBoqQuery } from "../../features/SCMApi";
import ConfirmDelete from "../common/ConfirmDelete";
import CheckProjectPermission from "../common/checkPermission/CheckProjectPermission";
import DownloadIcon from "@mui/icons-material/Download";
import { Tooltip } from "@mui/material";
import FileSaver from "file-saver";
import BoqTemplate from "../../assets/Template/IE-BOQtemplate.xlsx";
export default function BoqComponent(props) {
  const { projectId } = useParams();
  const [deleteBoqMutation, { deleteBoqError }] = useDeleteBoqMutation();
  const [confirmDialog, setConfirmDialog] = useState(false);

  const { data: checkBoq } = useHasBoqQuery(projectId);
  const hasBoq = checkBoq;
  
  function deleteBoqHandler() {
    deleteBoqMutation(projectId)
      .unwrap()
      .then((response) => {
        
        setConfirmDialog(false);
        props?.setMessage("BoQ deleted successfully");
        props?.setSuccessMessage(true);
      })
      .then((error) => {
        
      });
    
    deleteBoqError?.error == undefined ? response() : <></>;
  }
  function response() {
    setConfirmDialog(false);
    props?.setMessage("failed to deleted BoQ");
    props?.setSuccessMessage(true);
    // props?.setIsError(true);
  }
  const handleDownload = () => {
    FileSaver.saveAs(BoqTemplate, "Boq Template.xlsx");
    
  };
  return (
    <>
      <div
        className="InvolvedSolution-container"
        style={{ marginBottom: "0px", marginTop: "0px", marginRight: "0px" }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="card-title">BoQ</div>
          <Tooltip title="Download Boq Template">
            <div className="px-3">
              <DownloadIcon
                onClick={handleDownload}
                sx={{ color: "#064b4f" }}
              />
            </div>
          </Tooltip>
        </div>

        <div className="divider"> </div>
        <div className="d-flex justify-content-center">
          {CheckProjectPermission(
            "add_boq",
            props?.projectmembers,
            props?.userinfo,
            props?.roleRoles
          ) ? (
            <div
              className="create-project-btn ms-1 mt-2 mb-2"
              style={{ width: "125px" }}
              onClick={(e) => props?.ToggleModal(true)}
            >
              <AddIcon />
              Add Boq
            </div>
          ) : (
            <></>
          )}
          {hasBoq == 1 ? (
            <a
              className="create-project-btn ms-1 mt-2 mb-2"
              href={API_BASE_URL + `/scm/product/scm-boq/download/${projectId}`}
            >
              <CloudDownloadIcon />
              Download BoQ
            </a>
          ) : (
            <></>
          )}
          {CheckProjectPermission(
            "delete_boq",
            props?.projectmembers,
            props?.userinfo,
            props?.roleRoles
          ) ? (
            hasBoq == 1 ? (
              <div
                className="create-project-btn ms-1 mt-2 mb-2"
                style={{ width: "125px" }}
                onClick={(e) => setConfirmDialog(true)}
              >
                <DeleteIcon />
                Delete BoQ
              </div>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </div>
      </div>
      {confirmDialog ? (
        <ConfirmDelete
          action={deleteBoqHandler}
          setters={setConfirmDialog}
          setDialog={setConfirmDialog}
          dialog={`Delete BoQ ?`}
        />
      ) : (
        <></>
      )}
    </>
  );
}
