import React from "react";
import Settingsidebar from "../../pages/Settings/SettingIndex";
import { NavLink } from "react-router-dom";
import { LuShip, LuUsers } from "react-icons/lu";
import {
  MdOutlinePayments,
  MdOutlineInventory2,
  MdOutlineBadge,
} from "react-icons/md";
import { BiCategoryAlt } from "react-icons/bi";
import { BsBank } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import Clients from "../../assets/SVG/handshake.png";
import CheckPermission from "../common/checkPermission/checkPermission";
import { useGetUserInfoQuery } from "../../features/SCMApi";

export default function SettingMenu() {
  const icons = {
    fontSize: "70px",
    color: "#186569",
    textAlign: "center",
  };
  const { data: userinfo } = useGetUserInfoQuery();

  return (
    <Settingsidebar>
      <div className="page">
        <div className="menu-container">
          <NavLink to={"/settings/shipment"} className="setting-menu-card">
            <div className="text-center">
              <LuShip style={icons} />
            </div>
            <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
              Shipment
            </span>
          </NavLink>
          <NavLink to={"/settings/payment"} className="setting-menu-card">
            <div className="text-center">
              <MdOutlinePayments style={icons} />
            </div>
            <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
              Payment
            </span>
          </NavLink>
          <NavLink to={"/settings/bank"} className="setting-menu-card">
            <div className="text-center">
              <BsBank style={icons} />
            </div>
            <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
              Bank
            </span>
          </NavLink>
          <NavLink to={"/settings/stock"} className="setting-menu-card">
            <div className="text-center">
              <MdOutlineInventory2 style={icons} />
            </div>
            <span className="h5-pri text-center " style={{ fontSize: "20px" }}>
              Stock
            </span>
          </NavLink>
          <NavLink to={"/settings/clients"} className="setting-menu-card">
            <div className="text-center">
              <img
                src={Clients}
                style={{ width: 70, height: 70, borderRadius: "0px" }}
              />
            </div>
            <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
              Clients
            </span>
          </NavLink>
          {CheckPermission("all", userinfo) ? (
            <>
              <NavLink to={"/settings/approval"} className="setting-menu-card">
                <div className="text-center">
                  <DoneAllIcon style={icons} />
                </div>
                <span
                  className="h5-pri text-center"
                  style={{ fontSize: "20px" }}
                >
                  Approval
                </span>
              </NavLink>
              <NavLink
                to={"/settings/useraccess"}
                className="setting-menu-card"
              >
                <div className="text-center">
                  <MdOutlineBadge style={icons} />
                </div>
                <span
                  className="h5-pri text-center"
                  style={{ fontSize: "20px" }}
                >
                  User Access
                </span>
              </NavLink>
              <NavLink to={"/settings/users"} className="setting-menu-card">
                <div className="text-center">
                  <LuUsers style={icons} />
                </div>
                <span
                  className="h5-pri text-center"
                  style={{ fontSize: "20px" }}
                >
                  Users
                </span>
              </NavLink>
            </>
          ) : (
            ""
          )}
          {CheckPermission("add_supplier", userinfo) && (
            <NavLink
              to={"/settings/supplierCategory"}
              className="setting-menu-card"
            >
              <div className="text-center">
                <BiCategoryAlt style={icons} />
              </div>
              <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
                Supplier Category
              </span>
            </NavLink>
          )}
        </div>
      </div>
    </Settingsidebar>
  );
}
