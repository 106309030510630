import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import React from "react";
import WarningIcon from "@mui/icons-material/Warning";
import DialogActions from "@mui/material/DialogActions";

export default function ErrorModal({ handleClose, errormessage, open }) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div className="text-center">
            <WarningIcon
              sx={{
                color: "red",
                width: 100,
                height: 100,
              }}
            />
          </div>

          <DialogContentText id="alert-dialog-description">
            {errormessage ? errormessage : " "}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
