import React from "react";
import ExcelJS from "exceljs";
import {
  useGetBatchesQuery,
  useGetProjectItemQuery,
} from "../../features/SCMApi";

function App({ project_id, project, data }) {
  const downloadExcel = (buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `IE NETWORKS-${
      project_id == "" ? "All" : project?.project_name
    }-SCTS with PA `;
    document.body.appendChild(link);
    link.click();
  };

  const { data: orderDetail } = useGetProjectItemQuery(project_id);
  const { data: batches } = useGetBatchesQuery(project_id);
  function date(dates) {
    const dateStr = dates;
    const date = new Date(dateStr);
    const options = {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    

    return formattedDate;
  }
  const mapData = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("SUMMARY");

    const titleCell = worksheet.getCell("A1");
    titleCell.alignment = { vertical: "middle", horizontal: "center" };

    titleCell.border = {
      top: { style: "thin", color: { argb: "000000" } },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
    };
    titleCell.font = { size: 12, name: "Times New Roman", bold: true };
    titleCell.value = `IE NETWORKS-${
      project_id == "" ? "All" : project?.project_name
    }-PA Summary`;
    titleCell.alignment = { horizontal: "center" };
    titleCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "00B0F0" },
    };

    worksheet.mergeCells("A1:W1");

    const headerRow = worksheet.addRow([
      "No.",
      project_id == "" ? "Project" : null,
      "Item Description",
      "Order",
      "Boq Total Cost (USD)",
      "Final Total Cost (USD)",
      "Profit/Loss (USD)",
      "Supplier Name",
      "LC Open To",
      "Payment Mode",
      "Advanced Payment",
      "Payment Status",
      "Supplier Bank Details",
      "Port of Loading",
      "Mode of Shipment",
      "ETA",
      "ETD",
      "Email Subject",
      "Supplier Contact person Detail",
      "Shipment Batch",
      "Handover LO",
      "Handover Date",
      "Current Status",
    ]);

    headerRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "BFBFBF" },
      };
      cell.font = {
        color: { argb: "000000" },
        bold: true,
        size: 12,
        name: "Times New Roman",
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });

    let No = 0;
    const boqTotalCost = [];
    const manufacturers = [];
    data && data.map((items) => manufacturers?.push(items?.po));

    for (let i = 0; i < manufacturers?.length; i++) {
      const boqTotal =
        manufacturers &&
        manufacturers[i]
          ?.map((item) => item?.boq_total_cost)
          ?.reduce((acc, curr) => acc + curr, 0);

      const finalCost =
        manufacturers &&
        manufacturers[i]
          ?.map((item) => item?.pi_total_cost)
          ?.reduce((acc, curr) => acc + curr, 0);
      boqTotalCost?.push({
        id: i,
        boqTotal,
        finalCost,
      });
    }

    const fetchedData = data && data;
    
    fetchedData &&
      fetchedData?.map((items, index) => {
        const row = worksheet?.addRow([
          (No += 1),
          project_id == "" ? items?.project_po?.project_name : null,
          items?.order?.description == undefined
            ? "-"
            : items?.order?.description,
          items?.order?.order_name,
          boqTotalCost[index]?.boqTotal?.toLocaleString(),
          boqTotalCost[index]?.finalCost?.toLocaleString(),
          (
            boqTotalCost[index]?.boqTotal - boqTotalCost[index]?.finalCost
          )?.toLocaleString(),
          items?.order?.supplier?.distributor_name,
          (items?.lc_open_to &&
            items?.lc_open_to[0][0]?.forex_opening_to?.distributor_name ==
              undefined) ||
          null
            ? "-"
            : items?.lc_open_to &&
              items?.lc_open_to[0][0]?.forex_opening_to?.distributor_name,
          items?.payment_information_id == null
            ? "NA"
            : items?.payment_information_po?.payment_term?.payment_term,
          items?.payment_data && items?.payment_data?.length == 0
            ? "-"
            : items?.payment_data && items?.payment_data[0]?.advance_paid == 0
              ? `${
                  items?.payment_data &&
                  items?.payment_data[0]?.advance_percentage == null
                    ? 0
                    : items?.payment_data[0]?.advance_percentage
                }%`
              : "Paid",
          //payment Status

          items?.total_payment == null
            ? `0 %`
            : items?.payed_amount == null
              ? "Unpaid"
              : Number((items?.payed_amount / items.total_payment) * 100) > 99
                ? "Paid"
                : `${Number(
                    (items?.payed_amount / items.total_payment) * 100
                  ).toLocaleString()} %`,
          //payemt status end
          items?.order?.supplier?.supplier_bank?.length == 0
            ? "NA"
            : items?.order?.supplier?.supplier_bank?.map(
                (Bankitems) =>
                  `${
                    Bankitems?.bank_name == null
                      ? "Unknown"
                      : Bankitems?.bank_name
                  }(${
                    Bankitems?.country?.name == null
                      ? "-"
                      : Bankitems?.country?.name
                  })
                         AC.No (${Bankitems?.bank_currency}) : ${
                           Bankitems?.account_number
                         }
                      Swift code: ${Bankitems?.swift_code}
                      IBAN No (${Bankitems?.bank_currency}): ${Bankitems?.iban}`
              ),
          items?.payment_information_id == null
            ? "NA"
            : items?.payment_information_po?.loading_port == null
              ? `Any port in ${items?.payment_information_po?.origin?.name}`
              : `Any port in ${items?.payment_information_po?.loading_port?.port_name}`,
          items?.payment_information_id == null
            ? "NA"
            : items?.payment_information_po?.shipment_mode?.shipment_mode,
          items?.order?.eta == null ? "Pending" : items?.order?.eta,
          items?.order?.etd == null ? "Pending" : items?.order?.etd,
          items?.order?.email_subject == null
            ? "-"
            : items?.order?.email_subject,
          `${
            items?.order?.supplier?.supplier_representatives[0]?.full_name ==
            undefined
              ? "-"
              : items?.order?.supplier?.supplier_representatives[0]?.full_name
          } 
          ${
            items?.order?.supplier?.supplier_representatives[0]?.email == null
              ? "-"
              : items?.order?.supplier?.supplier_representatives[0]?.email
          }`,
          `Batch#${items?.order?.batch == null ? "-" : items?.order?.batch}`,
          items?.project_po?.users
            ?.map((pro) =>
              pro.pivot?.role_id == "6b829425-8842-42c4-b68c-cc001a22985f"
                ? pro?.name
                : undefined
            )
            ?.filter(function (element) {
              return element !== undefined;
            })
            .map((items) => items),

          items?.order?.handover_date == null
            ? "-"
            : items?.order?.handover_date,
          `${
            (items?.order?.handover_date,
            items?.order?.status_id == null
              ? "-"
              : items?.order?.status?.status_name)
          }
            ${date(
              items?.order?.updated_at == null
                ? items?.order?.created_at
                : items?.order?.updated_at
            )}
            `,
        ]);

        
        row.font = { size: 12, name: "Times New Roman" };

        row.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };

        row.eachCell((cell) => {
          cell.border = {
            top: { style: "thin", color: { argb: "000000" } },
            left: { style: "thin", color: { argb: "000000" } },
            bottom: { style: "thin", color: { argb: "000000" } },
            right: { style: "thin", color: { argb: "000000" } },
          };
        });
        row.eachCell((cell) => {
          if (cell.address.includes("G")) {
            if (
              boqTotalCost[index]?.boqTotal - boqTotalCost[index]?.finalCost <
              0
            ) {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFFF0000" },
              };
            } else {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "00B050" },
              };
            }
          }
        });
        row.eachCell((cell) => {
          if (cell.address.includes("H")) {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "BFBFBF" },
            };
          }
        });
      });
    let FooterBoqtotal = 0;
    let Footertotalcost = 0;
    fetchedData &&
      fetchedData?.map(
        (items, index) =>
          (FooterBoqtotal = FooterBoqtotal + boqTotalCost[index]?.boqTotal)
      );
    fetchedData &&
      fetchedData?.map(
        (items, index) =>
          (Footertotalcost = Footertotalcost + boqTotalCost[index]?.finalCost)
      );
    const FooterRow = worksheet.addRow([
      "Total ",
      "",
      "",
      "",
      FooterBoqtotal?.toLocaleString(),
      Footertotalcost?.toLocaleString(),
      (FooterBoqtotal - Footertotalcost)?.toLocaleString(),
      "",
      " ",
      "",
      " ",
      " ",
      " ",
      " ",
      " ",
      " ",
      " ",
      " ",
      " ",
      " ",
      " ",
      " ",
      " ",
    ]);
    FooterRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "BFBFBF" },
      };
      cell.font = {
        color: { argb: "000000" },
        bold: true,
        size: 12,
        name: "Times New Roman",
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "right",
        wrapText: true,
      };
    });
    // worksheet.getColumn("L").eachCell({ includeEmpty: true }, (cell) => {
    //   cell.style = { ...cell.style, wrapText: true };
    // });
    worksheet.getColumn("B").hidden = project_id == "" ? false : true;
    // worksheet.getColumn("P").hidden = true;
    worksheet.columns.forEach((column, index) => {
      column.width = 20;
      column.height = 20;
      worksheet.getRow(index).height = 50;
    });
    // const lastRow = worksheet.lastRow;
    // const titleRow = worksheet.addRow();


    // titleRow.getCell(2).value = "Summary";
    // titleRow.getCell(2).font = { bold: true };
    // titleRow.getCell(2).alignment = { horizontal: "center" };
    worksheet.columns[0].width = 10;
    worksheet.columns[1].width = 30;
    worksheet.getRow(1).height = 30;
    workbook.xlsx.writeBuffer().then((buffer) => {
      downloadExcel(buffer);
    });
  };

  return (
    <div>
      <button onClick={mapData} className="report-download">
        Download Report
      </button>
    </div>
  );
}

export default App;
