import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import EditPaymentPlaning from "./Modal/EditPaymentPlanning";
import EditPaymentTracking from "../PaymentTracking/Modal/EditPaymentTrackingModal";
import EditCreditNote from "../PaymentTracking/Modal/EditCreditNote";

const EditPaymentButton = (props) => {
  const [editData, setEditData] = React.useState({});

  const [editPaymentModal, setEditPaymentModal] = React.useState(false);
  const [editPaymentTrackingModal, setEditPaymentTrackingModal] =
    React.useState(false);
  const [editCreditNote, setEditCreditNote] = React.useState(false);

  function EditModalHandler() {
    props?.name === "paymentTracking"
      ? setEditPaymentTrackingModal(true)
      : props.name === "credit_note"
      ? setEditCreditNote(true)
      : setEditPaymentModal(true);
  }
  const handleEditPaymentButtonClick = (e, name) => {
    return EditModalHandler();
  };

  return (
    <>
      <Tooltip title="Edit Row">
        <IconButton
          variant="text"
          size="small"
          disabled={props?.disabled}
          sx={{
            backgroundColor: props?.style?.backgroundColor
              ? props?.style?.backgroundColor
              : "#fff",
            color: props?.style?.color ? props?.style?.color : "#064B4F",
            marginRight: props?.style?.marginRight,
            "&:hover": {
              backgroundColor: "#186569",
              color: "#fff",
            },
          }}
          onClick={(e) => (
            (setEditData({
              no: props?.editData?.no,
              vendor: props?.editData?.vendor,
              product: props?.editData?.product,
              description: props?.editData?.description,
              bank: props?.editData?.bank,
              expectedDate: props?.editData?.expectedDate,
              amount: props?.editData?.amount,
              wayOfPayment: props?.editData?.wayOfPayment,
            }),
            1),
            handleEditPaymentButtonClick()
          )}
        >
          <ModeEditOutlineOutlinedIcon sx={{ fontSize: "14px" }} />
        </IconButton>
      </Tooltip>
      {editPaymentModal ? (
        <EditPaymentPlaning
          modal={setEditPaymentModal}
          previousData={props?.editData}
          successMessage={props?.successMessage}
          message={props?.message}
        />
      ) : (
        <></>
      )}
      {editPaymentTrackingModal ? (
        <EditPaymentTracking
          modal={setEditPaymentTrackingModal}
          previousData={props?.editData}
          successMessage={props?.successMessage}
          message={props?.message}
        />
      ) : (
        <></>
      )}
      {editCreditNote ? (
        <EditCreditNote
          modal={setEditCreditNote}
          previousData={props?.editData}
          successMessage={props?.successMessage}
          message={props?.message}
          resStatus={props?.resStatus}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default EditPaymentButton;
