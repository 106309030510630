import React, { useEffect } from "react";
import { IMG_BASE_URL } from "../../api/endPoint";
import { useGetProjectQuery } from "../../features/SCMApi";
import { useParams } from "react-router-dom";
import { useState } from "react";

export default function MemberSlider(props) {
  const { projectId } = useParams();
  let memberSlides = [];
  let index = 3;
  const { data: projectmembers } = useGetProjectQuery(projectId);
  
  const membersList = [];
  projectmembers?.data?.users?.map((members) => membersList.push(members));
  let avatar = [];
  memberSlides.push(...projectmembers?.data?.users?.slice(0, 3));

  
  const [currentSlide, setCurrent] = useState(memberSlides);
  useEffect(() => {
    projectmembers?.data?.users?.map((members) => membersList.push(members));
    memberSlides = projectmembers?.data?.users?.slice(0, 3);
    setCurrent(memberSlides);
  }, []);
  function handlePreviews() {
    setCurrent(props?.handlePreviews(currentSlide));
  }
  function handleNext() {
    setCurrent(props?.handleNext(currentSlide));
  }
  function roleFinder(id) {
    // let roleName = "";
    
    const roleName = props?.roleRoles?.data?.roles?.find(
      (role) => role?.id == id
    );
    
    return roleName?.role_name;
  }
  const member_container = {
    gridTemplateColumns: "210px 210px 210px",
    marginTop: "10px",
    marginBottom: "0px",
  };
  return (
    <div
      className="slider-assigned-team"
      style={{ marginTop: "0px", marginBottom: "0px" }}
    >
      <div className=" slider-btn" onClick={(e) => handlePreviews(index - 3)}>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_3027_3045)">
            <path
              d="M22.8 3.69992C23.0445 3.96658 23.1722 4.2777 23.1834 4.63325C23.1945 4.98881 23.0667 5.29992 22.8 5.56658L12.3 16.0666L22.8 26.5666C23.0445 26.811 23.1722 27.1166 23.1834 27.4833C23.1945 27.8499 23.0667 28.1666 22.8 28.4333C22.5556 28.6999 22.25 28.8388 21.8834 28.8499C21.5167 28.861 21.2 28.7333 20.9334 28.4666L9.23335 16.7666C9.12224 16.6555 9.04447 16.5444 9.00002 16.4333C8.95558 16.3221 8.93336 16.1999 8.93336 16.0666C8.93336 15.9333 8.95558 15.811 9.00002 15.6999C9.04447 15.5888 9.12224 15.4777 9.23335 15.3666L20.9334 3.66658C21.1778 3.42214 21.4834 3.29992 21.85 3.29992C22.2167 3.29992 22.5334 3.43325 22.8 3.69992Z"
              fill="#FFD752"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_3027_3045"
              x="-1.06665"
              y="-2.7002"
              width="34.2507"
              height="45.5508"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_3027_3045"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_3027_3045"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <div className="assigned-team-grid " style={member_container}>
        {currentSlide?.map((members, key) => (
          <div key={key} className="assigned-team-member">
            
            <div className="assigned-team-controller">
              {props?.checkPermission(
                "delete_member_on_project",
                props?.projectmembers,
                props?.userinfo,
                props?.roleRoles
              ) ? (
                <div
                  className="team-controller"
                  onClick={(e) => {
                    props?.HandleEdit(e);
                    props?.setMember(members?.id);
                    props?.setProject(members?.pivot?.project_id);
                    props?.setName(members?.name);
                  }}
                >
                  <svg
                    width="8"
                    height="9"
                    viewBox="0 0 8 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.2625 9C1.0625 9 0.8875 8.925 0.7375 8.775C0.5875 8.625 0.5125 8.45 0.5125 8.25V1.125H0.375C0.266667 1.125 0.177083 1.08958 0.10625 1.01875C0.0354167 0.947917 0 0.858333 0 0.75C0 0.641667 0.0354167 0.552083 0.10625 0.48125C0.177083 0.410417 0.266667 0.375 0.375 0.375H2.35C2.35 0.266667 2.38542 0.177083 2.45625 0.10625C2.52708 0.0354167 2.61667 0 2.725 0H5.275C5.38333 0 5.47292 0.0354167 5.54375 0.10625C5.61458 0.177083 5.65 0.266667 5.65 0.375H7.625C7.73333 0.375 7.82292 0.410417 7.89375 0.48125C7.96458 0.552083 8 0.641667 8 0.75C8 0.858333 7.96458 0.947917 7.89375 1.01875C7.82292 1.08958 7.73333 1.125 7.625 1.125H7.4875V8.25C7.4875 8.45 7.4125 8.625 7.2625 8.775C7.1125 8.925 6.9375 9 6.7375 9H1.2625ZM2.5875 6.8C2.5875 6.90833 2.62292 6.99792 2.69375 7.06875C2.76458 7.13958 2.85417 7.175 2.9625 7.175C3.07083 7.175 3.16042 7.13958 3.23125 7.06875C3.30208 6.99792 3.3375 6.90833 3.3375 6.8V2.5625C3.3375 2.45417 3.30208 2.36458 3.23125 2.29375C3.16042 2.22292 3.07083 2.1875 2.9625 2.1875C2.85417 2.1875 2.76458 2.22292 2.69375 2.29375C2.62292 2.36458 2.5875 2.45417 2.5875 2.5625V6.8ZM4.6625 6.8C4.6625 6.90833 4.69792 6.99792 4.76875 7.06875C4.83958 7.13958 4.92917 7.175 5.0375 7.175C5.14583 7.175 5.23542 7.13958 5.30625 7.06875C5.37708 6.99792 5.4125 6.90833 5.4125 6.8V2.5625C5.4125 2.45417 5.37708 2.36458 5.30625 2.29375C5.23542 2.22292 5.14583 2.1875 5.0375 2.1875C4.92917 2.1875 4.83958 2.22292 4.76875 2.29375C4.69792 2.36458 4.6625 2.45417 4.6625 2.5625V6.8Z"
                      fill="#FF5252"
                    />
                  </svg>
                </div>
              ) : (
                <></>
              )}
              <div className="assigned-participant-img">
                {members?.avatar ? (
                  <img
                    className="participant-avatars"
                    src={`${IMG_BASE_URL}${members?.avatar}`}
                    alt=""
                  />
                ) : (
                  <div className="participant-default-avatar">
                    <div style={{ display: "none" }}>
                      {(avatar = members?.name.split(" "))}
                    </div>
                    {avatar[0]?.charAt(0)}
                    {avatar[1]?.charAt(0)}
                  </div>
                )}
              </div>
            </div>
            <div className="member-profile">
              <div
                className="participant-name"
                style={{ height: "15px", overflow: "hidden" }}
              >
                {members?.name}
              </div>
              <div
                className="participant-name"
                style={{ height: "15px", overflow: "hidden" }}
              >
                {roleFinder(members?.pivot?.role_id)}
              </div>
              
            </div>
          </div>
        ))}
      </div>
      <div className=" slider-btn" onClick={(e) => handleNext()}>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_3027_3043)">
            <path
              d="M9.2 28.3001C8.95556 28.0334 8.82778 27.7223 8.81667 27.3667C8.80556 27.0112 8.93334 26.7001 9.2 26.4334L19.7 15.9334L9.2 5.43341C8.95556 5.18897 8.82778 4.88341 8.81667 4.51675C8.80556 4.15008 8.93334 3.83341 9.2 3.56675C9.44445 3.30008 9.75 3.16119 10.1167 3.15008C10.4833 3.13897 10.8 3.26675 11.0667 3.53341L22.7667 15.2334C22.8778 15.3445 22.9556 15.4556 23 15.5667C23.0444 15.6779 23.0667 15.8001 23.0667 15.9334C23.0667 16.0667 23.0444 16.189 23 16.3001C22.9556 16.4112 22.8778 16.5223 22.7667 16.6334L11.0667 28.3334C10.8222 28.5779 10.5167 28.7001 10.15 28.7001C9.78334 28.7001 9.46667 28.5667 9.2 28.3001Z"
              fill="#FFD752"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_3027_3043"
              x="-1.184"
              y="-2.85059"
              width="34.2507"
              height="45.5508"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_3027_3043"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_3027_3043"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
    </div>
  );
}
