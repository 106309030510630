import React from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

export default function ProjectCalender() {
  return (
    <div className="calender-container">
      <div className="card-title">Due Date</div>
      <div className="divider"></div>

      <Calendar />
    </div>
  );
}
