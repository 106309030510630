import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import StatusSetting from "./StatusSetting";
import ItemsType from "./ItemsType";
import ToolType from "./ToolType";
import ItemCategory from "./ItemCategory";
import UnitofMeasurement from "./UnitofMeasurement";
import AssetCatagories from "./AssetCatagories";

export default function ToolSetting() {
  return (
    <div className="pbody-container">
      <StatusSetting />
      <ItemsType />
      <ToolType />
      <ItemCategory />
      <UnitofMeasurement />
      <AssetCatagories />
    </div>
  );
}
