import React from "react";

export default function CheckList(props) {
  return (
    <div className="ci-checklist">
      <div className="check-list-items">
        <div className="ci-labels">{props.Label}</div>
        <div className="check-list-values-container">
          <div className="check-list-values">
            <div className="group">
              <div className="sm-label">Original</div>
              <input
                onChange={(e) => props?.originalSetters(e.target.value)}
                className="number-input"
                type="number"
                min="0"
                step="1"
                name="original-input"
                id="original-input"
                defaultValue={props?.original}
              />
            </div>
            <div className="group">
              <div className="sm-label">Copy</div>
              <input
                onChange={(e) => props?.copySetters(e.target.value)}
                className="number-input"
                min="0"
                step="1"
                type="number"
                name="copy-input"
                id="copy-input"
                defaultValue={props?.copy}
              />
            </div>
          </div>
          <input type="checkbox" name="" id="" className="check-box-ci" />
        </div>
      </div>
    </div>
  );
}
