import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { FormControl } from "react-bootstrap";
import { useState } from "react";
import {
  useAssignShipmentMutation,
  useGetFrightForwardersQuery,
  useGetSuppliersQuery,
} from "../../features/SCMApi";
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../api/endPoint";

export default function ShipmentArrangementModal(props) {
  const { batchId } = useParams();
  const [assignShipment] = useAssignShipmentMutation();
  const { data: suppliers } = useGetSuppliersQuery();
  const { data: freightForwarder } = useGetFrightForwardersQuery();
  const [freight_forwarder_id, setFreightForwarderId] = useState();
  const [vendor_id, setVendorId] = useState();
  function HandleClose() {
    props.ToggleModal(false);
  }
  function handleSubmit(e) {
    const freightShipmentFrom = {
      freight_forwarder_id,
      batch_id: batchId,
    };
    const vendorShipmentFrom = {
      vendor_id,

      batch_id: batchId,
    };
    
    assignShipment(
      arrangement == "ByFreightForwarder"
        ? freightShipmentFrom
        : vendorShipmentFrom
    )
      .unwrap()
      .then((response) => {
        
        props?.ToggleModal(false);
      })
      .then((error) => {
        
      });
    // axios({
    //   method: "post",
    //   url: `${API_BASE_URL}/scm/logistics/shipmentArrangement`,
    //   data:
    //     arrangement == "ByFreightForwarder"
    //       ? freightShipmentFrom
    //       : vendorShipmentFrom,
    //   headers: { "Content-Type": "application/json" },
    // })
    //   .then(function (response) {
    //     //handle success
    
    //   })
    //   .catch(function (response) {
    //     //handle error
    
    //   });
  }
  
  const [arrangement, setArrangement] = useState("ByFreightForwarder");
  return (
    <div id="myModal" className="mini-modal" onClick={HandleClose}>
      <div className="mini-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="create-project-modal-title d-flex justify-content-between align-items-baseline p-2">
          <div>Shipment Arrangement</div>
          <CloseIcon onClick={HandleClose} />
        </div>
        <div className="">
          <div className="row gap-2 align-items-center justify-content-center">
            <div className="row align-items-center justify-content-center">
              <div className="shipment-arrangement-form-radio-group ">
                <div className="radio-group">
                  <input
                    type="radio"
                    name="arrangement"
                    id="By Freight Forwarder"
                    onChange={(e) => setArrangement("ByFreightForwarder")}
                  />
                  <label
                    htmlFor="By Freight Forwarder"
                    className={`${
                      arrangement == "ByFreightForwarder"
                        ? "activeRadio"
                        : "inActiveRadio"
                    }`}
                  >
                    By Freight Forwarder
                  </label>
                </div>
                <div className="radio-group">
                  <input
                    type="radio"
                    name="arrangement"
                    id="By Vendor"
                    onChange={(e) => setArrangement("vendor")}
                  />
                  <label
                    htmlFor="By Vendor"
                    className={`${
                      arrangement == "vendor" ? "activeRadio" : "inActiveRadio"
                    }`}
                  >
                    By Vendor
                  </label>
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-center ">
              <select
                name=""
                id=""
                className="select-option-logistics"
                onChange={(e) =>
                  arrangement == "ByFreightForwarder"
                    ? setFreightForwarderId(e.target.value)
                    : setVendorId(e.target.value)
                }
              >
                <option
                  value="ola"
                  onChange={(e) =>
                    arrangement == "ByFreightForwarder"
                      ? setFreightForwarderId(e.target.value)
                      : setVendorId(e.target.value)
                  }
                >
                  Select{" "}
                </option>
                {arrangement == "ByFreightForwarder"
                  ? freightForwarder?.data?.map((item) => (
                      <option value={item?.id}>
                        {item?.frieght_forwarder_name}
                      </option>
                    ))
                  : suppliers?.data?.map((item) => (
                      <option value={item?.id}>{item?.distributor_name}</option>
                    ))}
              </select>
            </div>
            <div className="row mt-4 align-items-center justify-content-center gap-3">
              <button
                type="cancel"
                className="col-6 form-cancel"
                onClick={HandleClose}
              >
                {" "}
                Cancel
              </button>
              <button
                type="submit"
                className="col-6 form-save"
                onClick={(e) => handleSubmit(e)}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
