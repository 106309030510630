import React from "react";

export default function Spinner() {
  return (
    <div className="d-flex align-items-center gap-2" style={{ width: "55%" }}>
      <div className="input-label">Uploading</div>
      <div class="loader"></div>
    </div>
  );
}
