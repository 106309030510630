import React from "react";
import { useState } from "react";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { IconButton } from "@mui/material";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

import {
  useGetBatchToolRequestQuery,
  useGetStoreQuery,
  useBatchToolQuery,
  useGetUserInfoQuery,
  useToolRequestMutation,
  useToolRequestRequestMutation,
  useGetIncomingItemsQuery,
  useInventoryAcceptMutation,
  useInventoryDelineMutation,
} from "../../../features/SCMApi";

import { Toll } from "@mui/icons-material";

import { NavLink, useParams } from "react-router-dom";
import NavBar from "../../../Layout/NavBar";
import StockDialog from "../../common/StockDialog";
import { API_BASE_URL, IMG_BASE_URL } from "../../../api/endPoint";
import NoRecord from "../../common/NoRecord";
import { useAccordionButton } from "react-bootstrap";
import axios from "axios";
import EditInventory from "../StockModal/EditInventory";
export default function IncomingInventories(props) {
  const [InventoryAccept, src] = useInventoryAcceptMutation();
  const [InventoryDeline, srcs] = useInventoryDelineMutation();
  const { data: userinfo } = useGetUserInfoQuery();
  let SK = userinfo?.role?.role_name == "Store Keeper";
  let FE = userinfo?.role?.role_name == "Field Engineer";
  let PM = userinfo?.role?.role_name == "Project Manager"; // userinfo?.role?.role_name == "Super Admin" ||
  // userinfo?.role?.role_name == "Field Engineer";
  let create_by = userinfo?.id;
  const [confirmAccept, setConfirmAccept] = useState(false);
  const [confirmDecline, setConfirmDecline] = useState(false);
  const [ItemsInventory, setItems] = useState();
  function HandleConfirmAccept(e, Itemkey) {
    Object.keys(Incoming_datas).map((key, index) =>
      key === Itemkey ? setItems(Incoming_datas[Itemkey]) : []
    );
    setConfirmAccept(true);
  }
  function HandleConfirmDecline(e, Itemkey) {
    Object.keys(Incoming_datas).map((key, index) =>
      key === Itemkey ? setItems(Incoming_datas[Itemkey]) : []
    );
    setConfirmDecline(true);
  }


  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
     {}
    );

    return (
      <p className="setting-accordion-p mb-0" onClick={decoratedOnClick}>
        {children}
      </p>
    );
  }
  const { data: Incoming } = useGetIncomingItemsQuery();
  const Incoming_datas = Incoming == undefined ? {} : Incoming;

  {
    Object.keys(Incoming_datas).map((key, index) => {
      
    });
  }


  function HandleInventoryAccpet(e) {
    let accepted = {
      items: ItemsInventory,
    };
    InventoryAccept(accepted)
      .unwrap()
      .then((response) => {
        setConfirmAccept(false);

        props?.setMessages("Tool Accepted successfully");
      })
      .then((error) => {

      });
  }
  function HandleInventoryDecline(e) {
    let Decline = {
      items: ItemsInventory,
    };
    InventoryDeline(Decline)
      .unwrap()
      .then((response) => {
        setConfirmDecline(false);

        props?.setMessages("Tool Declined successfully");
      })
      .then((error) => {

      });
  }
  const [updateInventory, setupdateInventory] = useState(null);
  const [inventoriesupdate, setInventory] = useState(false);

  function HandleItem() {
    setInventory(true);
  }
  let count = 0;

  return (
    <>
      {Object.keys(Incoming_datas).map((key, index) => (
        <Accordion>
          <Card className="mb-2 mt-0 stockAccordionCardAccordionCard">
            <div className="card-header incomingTitle">
              <CustomToggle eventKey="0">
                <span className="paymentTrackingProjectName">{key}</span>
              </CustomToggle>
            </div>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <div>
                  {Incoming_datas[key] !== 0 ? (
                    <>
                      <table style={{ width: "100%" }}>
                        <thead className="stock-table-header">
                          <tr>
                            <th
                              className="stock-table-header-text text-center"
                              style={{ width: "5%" }}
                            >
                              No
                            </th>

                            <th
                              className="stock-table-header-text text-center"
                              style={{ width: "25%" }}
                            >
                              Item Description
                            </th>
                            <th
                              className="stock-table-header-text text-center"
                              style={{ width: "25%" }}
                            >
                              Item Number
                            </th>

                            <th
                              className="stock-table-header-text text-center"
                              style={{ width: "10%" }}
                            >
                              Amount
                            </th>
                            <th
                              className="stock-table-header-text text-center"
                              style={{ width: "25%" }}
                            >
                              Store
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {Incoming_datas[key]?.map((items, index) => (
                            <tr className="stock-table-row">
                              <td className="text-center">{(count += 1)}</td>
                              <td className="text-start">
                                {items?.description === null
                                  ? "-"
                                  : items?.description}
                              </td>
                              <td className="text-center">
                                {" "}
                                {items?.item_number === null
                                  ? "-"
                                  : items?.item_number}
                              </td>
                              <td className="text-center">
                                {`${
                                  items?.amount === null ? "-" : items?.amount
                                } `}
                              </td>
                              <td className="text-center">
                                {`${
                                  items?.store_id === null
                                    ? "-"
                                    : items?.store?.store_name
                                } `}
                              </td>
                              <td className="text-center">
                                <ModeEditOutlineOutlinedIcon
                                  onClick={() => {
                                    HandleItem(true);
                                    setupdateInventory(items);
                                  }}
                                  sx={{
                                    color: "#52FFAC",
                                    "&:hover": {
                                      backgroundColor: "#fff",
                                      color: "#52FFAC",
                                      border: "1px solid #52FFAC",
                                      borderRadius: 25,
                                    },
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      <div className="batch-header-container">
                        <button
                          className="form-save "
                          onClick={(e) => HandleConfirmAccept(e, key)}
                        >
                          Accept
                        </button>
                        <button
                          className="stock-decline"
                          onClick={(e) => HandleConfirmDecline(e, key)}
                        >
                          Decline
                        </button>
                      </div>
                    </>
                  ) : (
                    <NoRecord />
                  )}

                  {confirmAccept ? (
                    <StockDialog
                      setDialog={setConfirmAccept}
                      name="Accept"
                      receive_title={"You Accept"}
                      action={(e) => HandleInventoryAccpet(e)}
                    />
                  ) : (
                    ""
                  )}
                  {confirmDecline ? (
                    <StockDialog
                      setDialog={setConfirmDecline}
                      name="Decline"
                      receive_title={"You Received"}
                      action={(e) => HandleInventoryDecline(e)}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          {inventoriesupdate ? (
            <EditInventory data={updateInventory} modal={setInventory} />
          ) : (
            ""
          )}
        </Accordion>
      ))}
    </>
  );
}
