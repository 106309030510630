import React, { useEffect, useState } from "react";
import { useGetSuppliersOrdersQuery } from "../../../features/SCMApi";
import "../../../pages/Payment/Payable/aging.css";
import OverallPaymentSummary from "./overallPaymentSummary";
import { API_BASE_URL } from "../../../api/endPoint";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import Pagination from "@mui/material/Pagination";
import moment from "moment";

const OverallPayments = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [unpaidPrfs, setUnpaidPrfs] = useState([]);
  const [searchSupplier, setSearchSupplier] = useState("");
  const [searchDate, setSearchDate] = useState("");
  const [loading, setLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [submitting, setSubmitting] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const suppliersList = [];
  const { data: Suppliers } = useGetSuppliersOrdersQuery();
  Suppliers?.data?.map((items) =>
    suppliersList.push({
      value: items?.id,
      label: items?.distributor_name,
    })
  );

  useEffect(() => {
    setLoading(!Suppliers);
    setSelectedRows([]);
  }, [Suppliers, searchSupplier]);

  //  ============> GET UNPAID PRF DATA <===============
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/scm/prf_old/unpaid`)
      .then((response) => {
        setUnpaidPrfs(response?.data);
        setLoading(false);

      })
      .catch((response) => {

      });
  }, [searchSupplier, searchDate]);

  //  ============> POST UNPAID PRF DATA'S ID <===============
  const handleRowSelect = (id) => {
    const isSelected = selectedRows.includes(id);
    if (isSelected) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };
  const PRF_Ids = {
    prfs: selectedRows,
  };
  const handleSelectionSubmit = async (e) => {

    e.preventDefault();
    setSubmitting(true);
    try {
      const response = await axios.post(
        `${API_BASE_URL}/scm/prf_old/unpaid`,
        PRF_Ids
      );
      setUnpaidPrfs(response?.data);
      setSelectedRows([]);
      setSubmitting(false);

    } catch (error) {
      setSubmitting(false);
      console.error("Error:", error.response?.data?.error || error.message);
    }
  };

  useEffect(() => {
    const allFiltersEmpty = !searchSupplier && !startDate && !endDate;

    if (allFiltersEmpty) {
      setFilteredData(unpaidPrfs?.data || []);
      setPage(1);
    } else {
      const filtered = unpaidPrfs?.data?.filter((item) => {
        const itemDate = moment(item?.created_at);
        const startDateMoment = startDate ? moment(startDate) : null;
        const endDateMoment = endDate ? moment(endDate) : null;

        const withinDateRange =
          (!startDateMoment ||
            itemDate.isSameOrAfter(startDateMoment, "day")) &&
          (!endDateMoment || itemDate.isSameOrBefore(endDateMoment, "day"));

        const matchesSupplier =
          searchSupplier === "" ||
          item?.order?.supplier?.distributor_name === searchSupplier;
        const matchesSearchDate =
          !searchDate || moment(searchDate).isSame(itemDate, "day");

        return (
          (withinDateRange || "") &&
          (matchesSupplier || "") &&
          matchesSearchDate
        );
      });

      setFilteredData(filtered);
      setPage(1);
    }
  }, [startDate, endDate, searchSupplier, searchDate, unpaidPrfs?.data]);

  return (
    <div className="Payable-Table-Container prg">
      <div className="payable-selector">
        <select
          className="custom-select prg"
          name="supplier"
          id="supplier"
          placeholder="supplier"
          value={searchSupplier}
          onChange={(e) => setSearchSupplier(e.target.value)}
        >
          <option value="">Select supplier</option>
          {suppliersList.map((item) => (
            <option key={item.value} value={item.label}>
              {item.label}
            </option>
          ))}
        </select>
        <div className="prg">From:</div>
        <input
          type="date"
          className="custom-select prg"
          name="startDate"
          id="startDate"
          placeholder="Start Date"
          value={startDate ? moment(startDate).format("YYYY-MM-DD") : ""}
          onChange={(e) => setStartDate(new Date(e.target.value))}
        />
        <div className="prg">To: </div>
        <input
          type="date"
          className="custom-select prg"
          name="endDate"
          id="endDate"
          placeholder="End Date"
          value={endDate ? moment(endDate).format("YYYY-MM-DD") : ""}
          onChange={(e) => setEndDate(new Date(e.target.value))}
        />
      </div>
      {loading ? (
        <div className="justify-content-center ">
          <ClipLoader size={"10px"} />
          <div className="prg">Loading ...</div>
        </div>
      ) : searchSupplier || searchDate ? (
        <div class="table-responsive border m-[20px]">
          <table className="table">
            <thead>
              <tr style={{ backgroundColor: "rgba(86, 184, 183, 0.2)" }}>
                <th>Select</th>
                <th>Supplier Name</th>
                <th>Project</th>
                <th>Amount</th>
                <th>Created at</th>
                <th>Reason</th>
              </tr>
            </thead>
            <tbody>
              {rowsPerPage > 0 && unpaidPrfs?.data
                ? filteredData
                    .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                    .map((item) => {
                      return (
                        <tr key={item.id}>
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedRows.includes(item.id)}
                              onChange={() => handleRowSelect(item.id)}
                            />
                          </td>
                          <td>{item?.order?.supplier?.distributor_name}</td>
                          <td>{item.order?.project?.project_name}</td>
                          <td>{item?.total_payment}</td>
                          <td>
                            {moment(item?.created_at).format("MMMM D, YYYY")}
                          </td>
                          <td>{item?.reason_for}</td>
                        </tr>
                      );
                    })
                : null}
            </tbody>
          </table>

          {selectedRows.length > 0 && (
            <>
              {submitting ? (
                <div className="form-save m-3">
                  <ClipLoader size={10} color={"#123abc"} />
                  <div className="prg">Loading ...</div>
                </div>
              ) : (
                <button
                  className="form-save m-3"
                  onClick={handleSelectionSubmit}
                >
                  Mark Selected PRF as paid
                </button>
              )}
            </>
          )}
          <Pagination
            count={Math.ceil(filteredData.length / rowsPerPage)}
            page={page}
            className="d-flex justify-content-end mb-3"
            onChange={handleChangePage}
            rowsPerPageOptions={[10, 15, 25]}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setPage(1);
            }}
          />
        </div>
      ) : (
        <OverallPaymentSummary
          unpaidPrfs={unpaidPrfs ? unpaidPrfs : []}
          Suppliers={Suppliers ? Suppliers : []}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          handleRowSelect={handleRowSelect}
          handleSelectionSubmit={handleSelectionSubmit}
          submitting={submitting}
        />
      )}
    </div>
  );
};

export default OverallPayments;
