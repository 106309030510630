import React from "react";
import Grid from "@mui/material/Grid";

const RmaDetailExImContainer = (props) => {
  return (
    <>
      <Grid
        item
        container
        direction={{
          xs: "row",
          sm: "row",
          md: "column",
          lg: "column",
          xl: "column",
        }}
        xs={12}
        sm={12}
        md={12}
        lg={3.5}
        xl={3.5}
      >
        {props?.children}
      </Grid>
    </>
  );
};

export default RmaDetailExImContainer;
