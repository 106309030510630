import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../api/endPoint";
import axios from "axios";
import {
  useGetSupplierOriginsQuery,
  useUpdateFreightForwarderAddressMutation,
} from "../../features/SCMApi";
import DoneIcon from "@mui/icons-material/Done";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function FreightForwarderEdit(props) {
  const [showBank, setShowBank] = useState(false);
  const params = useParams();
  const [product_id, setProductid] = useState();
  const [frieght_forwarder_name, setManufacturer_name] = useState(
    props?.value?.data?.frieght_forwarder_name
  );
  const [origin_id, setManufacturer_origin] = useState(
    props?.value?.data?.origin_id
  );
  const [website, setManufacturer_website] = useState(
    props?.value?.data?.website
  );
  const [address, setManufacturer_address] = useState(
    props?.value?.data?.address
  );
  const [postal_code, setPobox] = useState(props?.value?.data?.postal_code);
  const [city, setManufacturer_city] = useState(props?.value?.data?.city);

  const { data: origin } = useGetSupplierOriginsQuery();

  let addressmanufacturer = {
    id: params.id,
    frieght_forwarder_name,
    origin_id,
    city,
    website,
    address,
    postal_code,
  };
  const [UpdateAddress, res] = useUpdateFreightForwarderAddressMutation();
  const HandleEditSubmit = async (e) => {
    e.preventDefault();
   

    // axios
    //   .put(
    //     `${API_BASE_URL}/scm/supplier/fright-forwarders/${params.id}`,
    //     addressmanufacturer,
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   )
    //   .then((response) => {
    
    //     // props.setMessages("Freight Forwarder address updated successfully");
    //     // props.showaddress(false);
    //   })
    //   .then((error) => {
    
    //   });
    UpdateAddress(addressmanufacturer)
      .unwrap()
      .then((response) => {
        
        props.setMessages("Freight Forwarder address updated successfully");
        props.showaddress(false);
      })
      .then((error) => {
        
      });
  };
  const HandleCloseAddress = async () => {
    props.showaddress(false);
  };
  return (
    <div>
      {" "}
      <div className="supplier-address-container-edit text-center">
        <div className="d-flex flex-column">
          <div className="h5">Name</div>
          <div className=" plg mt-2 mb-1">
            <input
              type="text"
              className="supplier-text-input-edit"
              defaultValue={props?.value?.data?.frieght_forwarder_name}
              onChange={(e) => setManufacturer_name(e.target.value)}
            />
          </div>
        </div>
        <div className="d-flex flex-column">
          <div className="h5">P.O BOX</div>
          <div className=" plg mt-2 mb-1">
            <input
              type="text"
              className="supplier-text-input-edit"
              defaultValue={props?.value?.data?.postal_code}
              onChange={(e) => setPobox(e.target.value)}
            />
          </div>
        </div>

        <div className="d-flex flex-column">
          <div className="h5">Website</div>
          <div className="h5 mt-2 mb-3 prg">
            <input
              type="text"
              className="supplier-text-input-edit"
              defaultValue={props?.value?.data?.website}
              onChange={(e) => setManufacturer_website(e.target.value)}
            />
          </div>
        </div>
        <div className="d-flex flex-column">
          <div className="h5">Address</div>
          <div className=" plg mt-2 mb-1">
            <input
              type="text"
              className="supplier-text-input-edit"
              defaultValue={props?.value?.data?.address}
              onChange={(e) => setManufacturer_address(e.target.value)}
            />
          </div>
        </div>
        <div className="d-flex flex-column">
          <div className="h5">City</div>
          <div className=" plg mt-2 mb-1">
            <input
              name=""
              id=""
              required
              className="supplier-text-input-edit"
              defaultValue={props?.value?.data?.city}
              onChange={(e) => setManufacturer_city(e.target.value)}
            />
          </div>
        </div>
        <div className="d-flex flex-column">
          <div className="h5">Origin</div>
          <div className=" plg mt-2 mb-1">
            <select
              name=""
              id=""
              className="supplier-select-option-edit"
              defaultValue={props?.value?.data?.origin_id}
              onChange={(e) => setManufacturer_origin(e.target.value)}
            >
              {origin?.data?.map((items) => (
                <option value={items.id}>{items.name}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end p-2 gap-2">
        <IconButton
          variant="text"
          size="medium"
          sx={{
            backgroundColor: "#186569",
            color: "#fff",
            border: "1px solid #186569",
            "&:hover": {
              backgroundColor: "#fff",
              color: "#186569",
              border: "1px solid #186569",
            },
            marginTop: "3%",
          }}
          onClick={HandleEditSubmit}
        >
          <DoneIcon />
        </IconButton>
        <IconButton
          variant="text"
          size="medium"
          sx={{
            backgroundColor: "#186569",
            color: "#fff",
            border: "1px solid #186569",
            "&:hover": {
              backgroundColor: "#fff",
              color: "#186569",
              border: "1px solid #186569",
            },
            marginTop: "3%",
          }}
          onClick={HandleCloseAddress}
        >
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  );
}
