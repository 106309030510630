import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { useGetProjectStoreQuery } from "../../../features/SCMApi";
import NoRecord from "../../common/NoRecord";
export default function Projects(props) {
  const HandleFragment = (items) => {
    props?.setFragment("detail");
    props?.setData(items);
  };
  // let projects = [
  //   { id: 1, project_name: "ERA", project_manager: "Surafel Kifel" },
  //   { id: 2, project_name: "Ethswitch", project_manager: "Henok Petros" },
  //   { id: 2, project_name: "MoTL", project_manager: "Kiya Gebru" },
  // ];
  let store_keeper = true;
  const { data: projects } = useGetProjectStoreQuery();
  return (
    <>
      {projects?.data?.length !== 0 ? (
        <div className="stock-project-container">
          {projects?.data?.map((project) => (
            <div
              className="stock-project-card"
              onClick={() => HandleFragment(project)}
            >
              <div className="d-grid gap-4 py-3 px-3">
                <div>
                  <div className="stock-project-name">
                    {project?.project?.project_name}
                  </div>
                  <div className="small mt-0">project</div>
                </div>
                <div>
                  {project?.project?.project_participants
                    ?.filter((i) => i?.role?.role_name == "Project Manager")
                    ?.slice(0, 2)
                    ?.map((items) => (
                      <div className="h5-pri">{items?.name}</div>
                    ))}
                  <div className="small mt-0">Project Manager</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <NoRecord />
      )}
    </>
  );
}
