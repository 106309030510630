import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import SupplierCreditDetail from "./SupplierCreditDetail";

const SupplierCreditHistoryDetailButton = (props) => {
  const [editData, setEditData] = React.useState({});

  const [editPaymentTrackingModal, setEditPaymentTrackingModal] =
    React.useState(false);

  function EditModalHandler() {
    setEditPaymentTrackingModal(true);
  }
  const handleEditPaymentButtonClick = (e, name) => {
    return EditModalHandler();
  };

  return (
    <>
      <Tooltip title="View payment history">
        <IconButton
          variant="text"
          size="small"
          disabled={props?.disabled}
          sx={{
            backgroundColor: "#eff2f3",
            color: "#064B4F",
            "&:hover": {
              backgroundColor: "#186569",
              color: "#fff",
            },
          }}
          onClick={(e) => (
            (setEditData({
              no: props?.editData?.no,
              vendor: props?.editData?.vendor,
              product: props?.editData?.product,
              description: props?.editData?.description,
              bank: props?.editData?.bank,
              expectedDate: props?.editData?.expectedDate,
              amount: props?.editData?.amount,
              wayOfPayment: props?.editData?.wayOfPayment,
            }),
            1),
            handleEditPaymentButtonClick()
          )}
        >
          <VisibilityOutlinedIcon sx={{ fontSize: "14px" }} />
        </IconButton>
      </Tooltip>

      {editPaymentTrackingModal ? (
        <SupplierCreditDetail
          modal={setEditPaymentTrackingModal}
          previousData={props?.editData}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default SupplierCreditHistoryDetailButton;
