import React from "react";
import { useState } from "react";
import Mapping from "../../components/SPSMapping/Mapping";
import { useGetMappingsQuery } from "../../features/SCMApi";

export default function SpsMapping() {
  const { data: solutions } = useGetMappingsQuery();
  const [selectedSolution, setSolution] = useState();

  const mapping = [];
  const mapFirst = [];
  const [count, setCount] = useState(0);
  const [countTracker, setTracker] = useState(0);
  const [mappState, setMap] = useState();

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  if (selectedSolution) {
    mapFirst.push(
      ...solutions?.data?.solutionMapping?.filter(
        (items) => items?.id == selectedSolution
      )
    );
    mapFirst.map((items) =>
      mapping.push({
        id: items?.id,
        name: items?.abbrivation,
      })
    );

    mapFirst?.map((items) =>
      items?.solution?.map((sol) =>
        mapping.push({
          id: sol?.id,
          name: sol?.solution_name,
          pid: sol?.department_id,
        })
      )
    );
  }
  if (count > countTracker) {
    setMap(mapping);
    setTracker(countTracker + 1);
    forceUpdate();
  }
  
  return (
    <div className="">
      <div className="row mx-auto  w-50 gap-3">
        <select
          className="col-6 utility-select-option spms-option"
          onChange={(e) => {
            setSolution(e.target.value);
            setCount(count + 1);
          }}
        >
          <option selected="true" disabled="disabled">
            Choose Solution
          </option>
          {solutions?.data?.solutionMapping?.map((items) => (
            <option value={items?.id}>{items?.department_name}</option>
          ))}
        </select>
      </div>
      <div style={{ height: "100%" }}>
        {solutions?.data?.solutionMapping?.map((items) =>
          selectedSolution == items?.id ? (
            <Mapping count={countTracker} nodes={mapFirst} id={items?.id} />
          ) : (
            <></>
          )
        )}
      </div>
    </div>
  );
}
