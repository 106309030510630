import React, { useEffect, useState } from "react";
import "../Recievable.css";
import ExcelJS from "exceljs";
import { LuDownloadCloud } from "react-icons/lu";
// import { API_BASE_URL } from "../../../../api/endPoint";
// import axios from "axios";

function RecivableHistoryDownload({ productData }) {
  //   const [productData, setProductData] = useState([]);
  const currentDate = new Date().toLocaleDateString();

  //   useEffect(() => {
  //     fetchData();
  //   }, []);

  //   const fetchData = () => {
  //     axios
  //       .get(`${API_BASE_URL}/scm/getHistory`)
  //       .then((response) => {

  //         setProductData(response.data.data);
  //       })
  //       .catch((err) => {
  //         console.error("Error fetching agingHistoryRecievable data:", err);
  //       });
  //   };

  const calculateDateDifference = (endDate, currentDate) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const endDateTime = new Date(endDate).setHours(0, 0, 0, 0);
    const currentDateTime = new Date(currentDate).setHours(0, 0, 0, 0);
    return Math.ceil((endDateTime - currentDateTime) / oneDay);
  };

  const groupDataByDateDifference = () => {
    const idRanges = [
      { label: "<1 day", data: [] },
      { label: "1-30 days", data: [] },
      { label: "31-60 days", data: [] },
      { label: "61-90 days", data: [] },
      { label: "91-120 days", data: [] },
      { label: "121-150 days", data: [] },
      { label: ">150 days", data: [] },
    ];

    productData.forEach((row) => {
      const dateDifference = calculateDateDifference(
        row.actualCollectionDate,
        row.plannedCollectionDate
      );

      if (dateDifference < 1) {
        idRanges[0].data.push(row);
      } else if (dateDifference >= 1 && dateDifference <= 30) {
        idRanges[1].data.push(row);
      } else if (dateDifference > 30 && dateDifference <= 60) {
        idRanges[2].data.push(row);
      } else if (dateDifference > 60 && dateDifference <= 90) {
        idRanges[3].data.push(row);
      } else if (dateDifference > 90 && dateDifference <= 120) {
        idRanges[4].data.push(row);
      } else if (dateDifference > 120 && dateDifference <= 150) {
        idRanges[5].data.push(row);
      } else {
        idRanges[6].data.push(row);
      }
    });

    return idRanges;
  };

  const handleDownload = () => {
    const groupedData = groupDataByDateDifference();

    // Create a new workbook
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("AgingReceivables");

    // Add headers
    const projectHeader = [
      "NO",
      "Project Name",
      "Currency",
      "plannedAmmount",
      "actualAmmount",
      "plannedCollectionDate",
      "actualCollectionDate",
      "ACD - PCD",
      "Reason",
      "previousStatus",
      "currentStatus",
      "Date Range",
    ];

    sheet.addRow([""]).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ffffff" },
    };
    sheet.addRow(["                   IE NETWORK SOLUTIONS PL"]).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ffffff" },
    };
    sheet.addRow(["                   COLLECTED ACCOUNT RECEIVABLES"]).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ffffff" },
    };
    sheet.addRow([`                   AT THE DATE ${currentDate}`]).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ffffff" },
    };

    // Add project header
    sheet.addRow([""]);
    sheet.addRow(projectHeader).fill = {
      type: "pattern",
      pattern: "solid",
      color: { argb: "ffffff" },
      fgColor: { argb: "4db8ff" },
    };
    sheet.addRow([""]);

    // Add project data with range name above each group
    groupedData.forEach((range) => {
      // Add range name
      sheet.addRow([`Date Range: ${range.label}`]);

      // Set fill color based on the range label
      let fillColor;
      switch (range.label) {
        case "<1 day":
          fillColor = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "00FF00" },
          }; // green
          break;
        case "1-30 days":
          fillColor = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "0000FF" },
          }; // blue
          break;
        case "31-60 days":
          fillColor = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFF00" },
          }; // yellow
          break;
        case "61-90 days":
          fillColor = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFA500" },
          }; // orange
          break;
        case "91-120 days":
          fillColor = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF0000" },
          }; // red
          break;
        case "121-150 days":
          fillColor = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "A52A2A" },
          }; // brown
          break;
        case ">150 days":
          fillColor = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "000000" },
          }; // black
          break;
        default:
          fillColor = {
            type: "pattern",
            pattern: "solid",

            fgColor: { argb: "ffffff" },
          }; // default white
      }

      if (fillColor) {
        sheet.lastRow.fill = fillColor;

        // Set font color to white for blue and black backgrounds
        if (["0000FF", "000000"].includes(fillColor.fgColor.argb)) {
          sheet.lastRow.font = {
            color: { argb: "FFFFFF" },
          };
        }
      }

      range.data.forEach((row, index) => {
        sheet.addRow([
          index + 1,
          row.project,
          row.currency,
          row.plannedAmmount,
          row.actualAmmount,
          row.plannedCollectionDate,
          row.actualCollectionDate,
          calculateDateDifference(
            row.actualCollectionDate,
            row.plannedCollectionDate
          ),
          row.reason,
          row.previousStatus,
          row.currentStatus,
          row.range,
        ]);
      });

      sheet.addRow([]);
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `Aging History Receivable ${currentDate}.xlsx`;
      link.click();
    });
  };

  return (
    <div className="exportIcon">
      <button className="buttonExport" onClick={handleDownload}>
        <LuDownloadCloud />
        Export History
      </button>
    </div>
  );
}

export default RecivableHistoryDownload;
