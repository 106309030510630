import "../Recievable.css";
import React, { useEffect, useState } from "react";
import RecievableFilter from "../RecievableFilter";
import RecievableSummeryTable from "./recievableSummeryTable";
import axios from "axios";
import { API_BASE_URL } from "../../../../api/endPoint";
import RecivableDownload from "./recivableDownload";
import ConfirmRecive from "./confirmRecive";
import RecievableHistory from "./RecivableHistory";

function RecievableAging() {
  let Counter_150 = 0;
  let Counter_120_150 = 0;
  let Counter_90_120 = 0;
  let Counter_60_90 = 0;
  let Counter_30_60 = 0;
  let Counter_0_30 = 0;
  let Counter_0 = 0;
  const [productData, setProductData] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/scm/agingRecievable`)
      .then((Response) => {

        setProductData(Response.data.data);
      })
      .catch((err) => {
        console.error("response from back end from aging err:", err);
      });
  }, []);


  const [searchQuery, setSearchQuery] = useState("");
  const [searchOptionProjectId, setSearchOptionProjectId] = useState("");
  const [searchOptionDate, setSearchOptionDate] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [showHistory, setShowHistory] = useState(false);
  const [historyIndex, setHistoryIndex] = useState("abc");

  const toggleHistory = () => {
    setShowHistory(!showHistory);
  };

  useEffect(() => {
    setHistoryIndex(showHistory ? "Back" : "Recivable History");
  }, [showHistory]);

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setCurrentPage(0);
  };

  const calculateDateDifference = (endDate, currentDate) => {
    const oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day
    const endDateTime = new Date(endDate).setHours(0, 0, 0, 0);
    const currentDateTime = new Date(currentDate).setHours(0, 0, 0, 0);
    return Math.ceil((endDateTime - currentDateTime) / oneDay);
  };

  let curentDate = searchOptionDate
    ? searchOptionDate
    : new Date().toLocaleDateString();
  const filteredData = productData?.filter((item) => {
    return (
      item.currency &&
      item.currency.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (searchOptionProjectId === "" ||
        item.project_id === searchOptionProjectId) &&
      (searchOptionDate === "" ||
        new Date(item.expectedDate) <= new Date(searchOptionDate))
    );
  });


  return (
    <div className="mb-3 bg-white rounded border">
      <div className="d-flex justify-content-end">
        <button
          className="form-save d-flex justify-content-center align-items-center mt-3"
          onClick={toggleHistory}
        >
          {historyIndex}
        </button>
      </div>
      {!showHistory ? (
        <>
          {" "}
          <div>
            <RecievableFilter
              searchQuery={searchQuery}
              searchOptionProjectId={searchOptionProjectId}
              searchOptionDate={searchOptionDate}
              handleSearchSubmit={handleSearchSubmit}
              setSearchQuery={setSearchQuery}
              setSearchOptionProjectId={setSearchOptionProjectId}
              setSearchOptionDate={setSearchOptionDate}
            />
          </div>
          <RecivableDownload
            productData={filteredData}
            curentDate={curentDate}
          />
          {searchQuery || searchOptionProjectId || searchOptionDate ? (
            //after filtered
            //
            <div style={{ display: "flex" }}>
              <div style={{ flex: "2%" }}></div>
              <div
                style={{
                  flex: "96%",
                  overflow: "auto",
                }}
              >
                <table
                  style={{ margin: "20px" }}
                  className="prg recivableTable"
                >
                  <thead>
                    <tr
                      className="recivableTr"
                      style={{ backgroundColor: "rgba(86, 184, 183, 0.1)" }}
                    >
                      <th className="recivableTh">NO</th>
                      <th style={{ minWidth: "200px " }}>Project Name</th>
                      <th className="recivableTh">Contract Date</th>
                      <th className="recivableTh">Milestone</th>
                      <th className="recivableTh">Amount</th>
                      <th className="recivableTh">ATP/DN Sign Date</th>
                      <th className="recivableTh">Payment Request Date</th>
                      <th className="recivableTh">Payment Expected Date</th>
                      <th className="recivableTh">Report Date</th>
                      <th className="recivableTh">ATP - RD</th>
                      <th className="recivableTh">PR - RD</th>
                      <th className="recivableTh">PE - RD</th>
                      <th className="recivableTh">Reason</th>
                      <th className="recivableTh">Status</th>
                      <th className="recivableTh">Recived</th>
                    </tr>
                  </thead>
                  <tbody className="recivabletbody">
                    <tr className="recivableTr">
                      <th
                        colSpan={15}
                        style={{
                          textAlign: "left",
                          paddingLeft: "50px",
                          backgroundColor: "rgba(235, 51, 35, 1)",
                        }}
                      >
                        More Than 150
                      </th>
                    </tr>
                    {filteredData?.map((item, index) => {
                      if (
                        calculateDateDifference(
                          curentDate,
                          item.expectedDate
                            ? item.expectedDate
                            : item.paymentRequestDate
                        ) > 150
                      ) {
                        Counter_150++;
                        return (
                          <tr className="recivableTr" key={index}>
                            <td className="recivableTd">{Counter_150}</td>
                            <td className="recivableTd">{item.project}</td>
                            <td className="recivableTd">{item.contractDate}</td>
                            <td className="recivableTd">{item.milestone}</td>
                            <td className="recivableTd">
                              {item.currency}:{" "}
                              {item.amount !== null
                                ? item.amount.toLocaleString()
                                : ""}
                            </td>
                            <td className="recivableTd">
                              {item.ATPSignedDate}
                            </td>
                            <td className="recivableTd">
                              {item.paymentRequestDate}
                            </td>
                            <td className="recivableTd">{item.expectedDate}</td>
                            <td className="recivableTd">{curentDate}</td>
                            <td className="recivableTd">
                              {calculateDateDifference(
                                curentDate,
                                item.ATPSignedDate
                              )}
                            </td>
                            <td className="recivableTd">
                              {calculateDateDifference(
                                curentDate,
                                item.paymentRequestDate
                              )}
                            </td>{" "}
                            <td className="recivableTd">
                              {calculateDateDifference(
                                curentDate,
                                item.expectedDate
                              )}
                            </td>{" "}
                            <td className="recivableTd">{item.Reason}</td>
                            <td className="recivableTd">{item.status}</td>
                            <td className="recivableTd">
                              <ConfirmRecive item={item} />
                            </td>
                            {/* <td className="recivableTd">
                        {calculateDateDifference(
                          curentDate,item.ATPSignedDate,
                          
                        )}
                      </td> */}
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                  <tbody className="recivabletbody">
                    <tr className="recivableTr">
                      <th
                        colSpan={15}
                        style={{
                          textAlign: "left",
                          paddingLeft: "50px",
                          backgroundColor: "rgba(235, 51, 35, 1)",
                        }}
                      >
                        120-150
                      </th>
                    </tr>
                    {filteredData?.map((item, index) => {
                      if (
                        calculateDateDifference(
                          curentDate,
                          item.expectedDate
                            ? item.expectedDate
                            : item.paymentRequestDate
                        ) > 120 &&
                        calculateDateDifference(
                          curentDate,
                          item.expectedDate
                            ? item.expectedDate
                            : item.paymentRequestDate
                        ) < 151
                      ) {
                        Counter_120_150++;
                        return (
                          <tr className="recivableTr" key={index}>
                            <td className="recivableTd">{Counter_120_150}</td>
                            <td className="recivableTd">{item.project}</td>
                            <td className="recivableTd">{item.contractDate}</td>
                            <td className="recivableTd">{item.milestone}</td>
                            <td className="recivableTd">
                              {item.currency}:{" "}
                              {item.amount !== null
                                ? item.amount.toLocaleString()
                                : ""}
                            </td>
                            <td className="recivableTd">
                              {item.ATPSignedDate}
                            </td>
                            <td className="recivableTd">
                              {item.paymentRequestDate}
                            </td>
                            <td className="recivableTd">{item.expectedDate}</td>
                            <td className="recivableTd">{curentDate}</td>
                            <td className="recivableTd">
                              {calculateDateDifference(
                                curentDate,
                                item.ATPSignedDate
                              )}
                            </td>
                            <td className="recivableTd">
                              {calculateDateDifference(
                                curentDate,
                                item.paymentRequestDate
                              )}
                            </td>
                            <td className="recivableTd">
                              {calculateDateDifference(
                                curentDate,
                                item.expectedDate
                              )}
                            </td>
                            <td className="recivableTd">{item.Reason}</td>
                            <td className="recivableTd">{item.status}</td>
                            <td className="recivableTd">
                              <ConfirmRecive item={item} />
                            </td>
                            {/* <td className="recivableTd">
                      {calculateDateDifference(curentDate, item.projectenddate)}
                    </td> */}
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                  <tbody className="recivabletbody">
                    <tr className="recivableTr">
                      <th
                        colSpan={15}
                        style={{
                          textAlign: "left",
                          paddingLeft: "50px",
                          backgroundColor: "rgba(235, 51, 35, 1)",
                        }}
                      >
                        91-120
                      </th>
                    </tr>
                    {filteredData?.map((item, index) => {
                      if (
                        calculateDateDifference(
                          curentDate,
                          item.expectedDate
                            ? item.expectedDate
                            : item.paymentRequestDate
                        ) > 90 &&
                        calculateDateDifference(
                          curentDate,
                          item.expectedDate
                            ? item.expectedDate
                            : item.paymentRequestDate
                        ) < 121
                      ) {
                        Counter_90_120++;
                        return (
                          <tr className="recivableTr" key={index}>
                            <td className="recivableTd">{Counter_90_120}</td>
                            <td className="recivableTd">{item.project}</td>
                            <td className="recivableTd">{item.contractDate}</td>
                            <td className="recivableTd">{item.milestone}</td>
                            <td className="recivableTd">
                              {item.currency}:{" "}
                              {item.amount !== null
                                ? item.amount.toLocaleString()
                                : ""}
                            </td>
                            <td className="recivableTd">
                              {item.ATPSignedDate}
                            </td>
                            <td className="recivableTd">
                              {item.paymentRequestDate}
                            </td>
                            <td className="recivableTd">{item.expectedDate}</td>
                            <td className="recivableTd">{curentDate}</td>
                            <td className="recivableTd">
                              {calculateDateDifference(
                                curentDate,
                                item.ATPSignedDate
                              )}
                            </td>
                            <td className="recivableTd">
                              {calculateDateDifference(
                                curentDate,
                                item.paymentRequestDate
                              )}
                            </td>
                            <td className="recivableTd">
                              {calculateDateDifference(
                                curentDate,
                                item.expectedDate
                              )}
                            </td>
                            <td className="recivableTd">{item.Reason}</td>
                            <td className="recivableTd">{item.status}</td>
                            <td className="recivableTd">
                              <ConfirmRecive item={item} />
                            </td>
                            {/* <td className="recivableTd">
                      {calculateDateDifference(curentDate, item.projectenddate)}
                    </td> */}
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                  <tbody className="recivabletbody">
                    <tr className="recivableTr">
                      <th
                        colSpan={15}
                        style={{
                          textAlign: "left",
                          paddingLeft: "50px",
                          backgroundColor: "rgba(246, 193, 66, 1)",
                        }}
                      >
                        61-90
                      </th>
                    </tr>
                    {filteredData?.map((item, index) => {
                      if (
                        calculateDateDifference(
                          curentDate,
                          item.expectedDate
                            ? item.expectedDate
                            : item.paymentRequestDate
                        ) > 60 &&
                        calculateDateDifference(
                          curentDate,
                          item.expectedDate
                            ? item.expectedDate
                            : item.paymentRequestDate
                        ) < 91
                      ) {
                        Counter_60_90++;
                        return (
                          <tr className="recivableTr" key={index}>
                            <td className="recivableTd">{Counter_60_90}</td>
                            <td className="recivableTd">{item.project}</td>
                            <td className="recivableTd">{item.contractDate}</td>
                            <td className="recivableTd">{item.milestone}</td>
                            <td className="recivableTd">
                              {item.currency}:{" "}
                              {item.amount !== null
                                ? item.amount.toLocaleString()
                                : ""}
                            </td>
                            <td className="recivableTd">
                              {item.ATPSignedDate}
                            </td>
                            <td className="recivableTd">
                              {item.paymentRequestDate}
                            </td>
                            <td className="recivableTd">{item.expectedDate}</td>
                            <td className="recivableTd">{curentDate}</td>
                            <td className="recivableTd">
                              {calculateDateDifference(
                                curentDate,
                                item.ATPSignedDate
                              )}
                            </td>
                            <td className="recivableTd">
                              {calculateDateDifference(
                                curentDate,
                                item.paymentRequestDate
                              )}
                            </td>
                            <td className="recivableTd">
                              {calculateDateDifference(
                                curentDate,
                                item.expectedDate
                              )}
                            </td>
                            <td className="recivableTd">{item.Reason}</td>
                            <td className="recivableTd">{item.status}</td>
                            <td className="recivableTd">
                              <ConfirmRecive item={item} />
                            </td>
                            {/* <td className="recivableTd">
                      {calculateDateDifference(curentDate, item.projectenddate)}
                    </td> */}
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                  <tbody className="recivabletbody">
                    <tr className="recivableTr">
                      <th
                        colSpan={15}
                        style={{
                          textAlign: "left",
                          paddingLeft: "50px",
                          backgroundColor: "rgba(255, 253, 84, 1)",
                        }}
                      >
                        31-60
                      </th>
                    </tr>
                    {filteredData?.map((item, index) => {
                      if (
                        calculateDateDifference(
                          curentDate,
                          item.expectedDate
                            ? item.expectedDate
                            : item.paymentRequestDate
                        ) > 30 &&
                        calculateDateDifference(
                          curentDate,
                          item.expectedDate
                            ? item.expectedDate
                            : item.paymentRequestDate
                        ) < 61
                      ) {
                        Counter_30_60++;
                        return (
                          <tr className="recivableTr" key={index}>
                            <td className="recivableTd">{Counter_30_60}</td>
                            <td className="recivableTd">{item.project}</td>
                            <td className="recivableTd">{item.contractDate}</td>
                            <td className="recivableTd">{item.milestone}</td>
                            <td className="recivableTd">
                              {item.currency}:{" "}
                              {item.amount !== null
                                ? item.amount.toLocaleString()
                                : ""}
                            </td>
                            <td className="recivableTd">
                              {item.ATPSignedDate}
                            </td>
                            <td className="recivableTd">
                              {item.paymentRequestDate}
                            </td>
                            <td className="recivableTd">{item.expectedDate}</td>
                            <td className="recivableTd">{curentDate}</td>
                            <td className="recivableTd">
                              {calculateDateDifference(
                                curentDate,
                                item.ATPSignedDate
                              )}
                            </td>
                            <td className="recivableTd">
                              {calculateDateDifference(
                                curentDate,
                                item.paymentRequestDate
                              )}
                            </td>
                            <td className="recivableTd">
                              {calculateDateDifference(
                                curentDate,
                                item.expectedDate
                              )}
                            </td>
                            <td className="recivableTd">{item.Reason}</td>
                            <td className="recivableTd">{item.status}</td>
                            <td className="recivableTd">
                              <ConfirmRecive item={item} />
                            </td>
                            {/* <td className="recivableTd">
                      {calculateDateDifference(curentDate, item.projectenddate)}
                    </td> */}
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                  <tbody className="recivabletbody">
                    <tr className="recivableTr">
                      <th
                        colSpan={15}
                        style={{
                          textAlign: "left",
                          paddingLeft: "50px",
                          backgroundColor: "rgba(76, 175, 234, 1)",
                        }}
                      >
                        1-30
                      </th>
                    </tr>
                    {filteredData?.map((item, index) => {
                      if (
                        calculateDateDifference(
                          curentDate,
                          item.expectedDate
                            ? item.expectedDate
                            : item.paymentRequestDate
                        ) > 0 &&
                        calculateDateDifference(
                          curentDate,
                          item.expectedDate
                            ? item.expectedDate
                            : item.paymentRequestDate
                        ) < 31
                      ) {
                        Counter_0_30++;
                        return (
                          <tr className="recivableTr" key={index}>
                            <td className="recivableTd">{Counter_0_30}</td>
                            <td className="recivableTd">{item.project}</td>
                            <td className="recivableTd">{item.contractDate}</td>
                            <td className="recivableTd">{item.milestone}</td>
                            <td className="recivableTd">
                              {item.currency}:{" "}
                              {item.amount !== null
                                ? item.amount.toLocaleString()
                                : ""}
                            </td>
                            <td className="recivableTd">
                              {item.ATPSignedDate}
                            </td>
                            <td className="recivableTd">
                              {item.paymentRequestDate}
                            </td>
                            <td className="recivableTd">{item.expectedDate}</td>
                            <td className="recivableTd">{curentDate}</td>
                            <td className="recivableTd">
                              {calculateDateDifference(
                                curentDate,
                                item.ATPSignedDate
                              )}
                            </td>
                            <td className="recivableTd">
                              {calculateDateDifference(
                                curentDate,
                                item.paymentRequestDate
                              )}
                            </td>
                            <td className="recivableTd">
                              {calculateDateDifference(
                                curentDate,
                                item.expectedDate
                              )}
                            </td>
                            <td className="recivableTd">{item.Reason}</td>
                            <td className="recivableTd">{item.status}</td>
                            <td className="recivableTd">
                              <ConfirmRecive item={item} />
                            </td>
                            {/* <td className="recivableTd">
                      {calculateDateDifference(curentDate, item.projectenddate)}
                    </td> */}
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                  <tbody className="recivabletbody">
                    <tr className="recivableTr">
                      <th
                        colSpan={15}
                        style={{
                          textAlign: "left",
                          paddingLeft: "50px",
                          backgroundColor: "rgba(160, 205, 99, 1)",
                        }}
                      >
                        Current & Upcoming
                      </th>
                    </tr>
                    {filteredData?.map((item, index) => {
                      if (
                        calculateDateDifference(
                          curentDate,
                          item.expectedDate
                            ? item.expectedDate
                            : item.paymentRequestDate
                        ) < 1
                      ) {
                        Counter_0++;
                        return (
                          <tr className="recivableTr" key={index}>
                            <td className="recivableTd">{Counter_0}</td>
                            <td className="recivableTd">{item.project}</td>
                            <td className="recivableTd">{item.contractDate}</td>
                            <td className="recivableTd">{item.milestone}</td>
                            <td className="recivableTd">
                              {item.currency}:{" "}
                              {item.amount !== null
                                ? item.amount.toLocaleString()
                                : ""}
                            </td>
                            <td className="recivableTd">
                              {item.ATPSignedDate}
                            </td>
                            <td className="recivableTd">
                              {item.paymentRequestDate}
                            </td>
                            <td className="recivableTd">{item.expectedDate}</td>
                            <td className="recivableTd">{curentDate}</td>
                            <td className="recivableTd">
                              {calculateDateDifference(
                                curentDate,
                                item.ATPSignedDate
                              )}
                            </td>
                            <td className="recivableTd">
                              {calculateDateDifference(
                                curentDate,
                                item.paymentRequestDate
                              )}
                            </td>
                            <td className="recivableTd">
                              {calculateDateDifference(
                                curentDate,
                                item.expectedDate
                              )}
                            </td>
                            <td className="recivableTd">{item.Reason}</td>
                            <td className="recivableTd">{item.status}</td>
                            <td className="recivableTd">
                              <ConfirmRecive item={item} />
                            </td>
                            {/* <td className="recivableTd">
                      {calculateDateDifference(curentDate, item.projectenddate)}
                    </td> */}
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
              </div>
              <div style={{ flex: "2%" }}></div>
            </div>
          ) : (
            //before filtered
            //
            <RecievableSummeryTable />
          )}
        </>
      ) : (
        <RecievableHistory />
      )}
    </div>
  );
}

export default RecievableAging;
