import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddStatus from "./SettingModal/AddStatus";
import EditStatus from "./SettingModal/EditStatus";
import {
  useDeleteStatusSettingMutation,
  useGetStatusQuery,
} from "../../features/SCMApi";
import DeleteModal from "../Supplier/SupplierModal/DeleteModal";

export default function StatusSetting() {
  const [addStatus, setAddStatus] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [showClientDeleteModal, setShowClientDeleteModal] = useState(false);
  const [statusToDeleteId, setStatusToDeleteId] = useState(null);
  const [currentStatus, setCurrentStatus] = useState(null);

  const { data: status } = useGetStatusQuery();
  const [deleteStatus] = useDeleteStatusSettingMutation();

  const handleAddStatusModal = () => {
    setAddStatus(true);
  };

  const handleEditStatusModal = (status) => {
    setEditStatus(true);
    setCurrentStatus(status);
  };

  const toggleDeleteModal = () => {
    setShowClientDeleteModal(!showClientDeleteModal);
  };

  const handleOpenDeleteModal = (id) => {
    setStatusToDeleteId(id);
    toggleDeleteModal();
  };

  const handleDeleteStatus = () => {
    const deleted = {
      id: statusToDeleteId,
    };
    deleteStatus(deleted)
      .unwrap()
      .then(() => {
        console.log("Status deleted successfully");
      })
      .catch((error) => {
        console.error("Error deleting status:", error);
      });
    toggleDeleteModal();
  };

  return (
    <div className="pcard shipment-destination">
      <section className="pi-header">
        <h3>Status</h3>
      </section>
      {status?.data?.map((items) => (
        <section className="pi-body" key={items.id}>
          <p>{items?.status_name}</p>
          <p>{items?.description}</p>
          <div>
            <ModeEditOutlineOutlinedIcon
              onClick={() => handleEditStatusModal(items)}
              sx={{
                color: "#52FFAC",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#52FFAC",
                  border: "1px solid #52FFAC",
                  borderRadius: 25,
                },
              }}
            />
            <DeleteOutlineOutlinedIcon
              onClick={() => handleOpenDeleteModal(items.id)}
              sx={{
                color: "#FF5252",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#FF5252",
                  border: "1px solid #FF5252",
                  borderRadius: 25,
                },
              }}
            />
          </div>
        </section>
      ))}

      <section className="pi-footer">
        <div onClick={handleAddStatusModal} className="pi-footer-icon">
          <AddIcon />
        </div>
      </section>

      {addStatus && <AddStatus modal={setAddStatus} />}
      {editStatus && <EditStatus modal={setEditStatus} data={currentStatus} />}

      <DeleteModal
        show={showClientDeleteModal}
        handleDeleteModal={toggleDeleteModal}
        name={"status"}
        deletedId={statusToDeleteId}
        navigationto={"smm/status"}
        handleDelete={handleDeleteStatus}
      />
    </div>
  );
}
