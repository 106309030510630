import React, { useState } from "react";
import {
  useGetCostBuildUpBatchesQuery,
  useGetCostBuildUpIndexQuery,
} from "../../../features/SCMApi";
import BatchCard from "../../../components/Logistics/BatchCard";
import { Pagination } from "@mui/material";
import usePagination from "../../../components/Pagination/Pagination";
import ForexSideBar from "../../../components/Payment/ForexApproval/PaymentSideBar";
import { NavLink } from "react-router-dom";
import CostBuildUp from "./Report/CostBuildUp";
import NoRecord from "../../../components/common/NoRecord";

export default function Index() {
  const { data: allBatches } = useGetCostBuildUpBatchesQuery();
  const { data: report } = useGetCostBuildUpIndexQuery();
  // const batches = allBatches?.data;
  const batches = allBatches?.data?.filter((items) => items.NBE_cleared == 1);
  let [page, setPage] = useState(1);
  const PER_PAGE = 6;
  const count = Math.ceil(batches?.length / PER_PAGE);
  const _DATA = usePagination(batches, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  const [search, setSearch] = useState("");

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <ForexSideBar
      title={"Cost BuildUp"}
      // name={selectedProject[0]?.[0]?.project_name}
      // navlink={`/logistics/batches/${ProjectId}`}
      // handleSearchChange={handleSearchChange}
      // placeholder={"search Batch..."}
    >
      {batches?.length != 0 ? (
        <>
          <CostBuildUp data={report?.data} />
          <div className="mt-4 logistics-batch-container ">
            {_DATA
              ?.currentData()
              ?.filter((items) => {
                if (search === "") {
                  return items;
                } else if (items.batch_name?.toLowerCase().includes(search)) {
                  return items;
                }
              })
              ?.map((items) => (
                <NavLink to={`${items?.id}`} className=" batch-detail-card">
                 
                  <div className="batch-detail-card-sub">
                    <div className="batch-card-left-grid">
                      <div className="batch-container">
                        <div className="logistics-batch-name">
                          {/* {items?.batch?.batch_name} */}
                          {items?.forex_name}
                        </div>
                        {/* <div className="batch-date">June 22 2021</div> */}
                      </div>
                      <div className="origin-group">
                        <div className="origin-value">
                          {items?.batch?.batch_origin?.name}
                        </div>
                        {/* {items?.id} */}
                        <div className="origin-label">Origin</div>
                      </div>
                    </div>

                    {/* <div className="logistics-batch-items-container">
                      <div className="logistics-batch-item">
                        <div className="logistics-batch-value">
                          {items?.batch?.items?.length}
                        </div>
                        <div className="logistics-batch-label">Items</div>
                      </div>
                    </div> */}
                  </div>
                  <div className="status">Active</div>
                </NavLink>
              ))}
          </div>
          <div className="faq-pagination-container">
            {count > 1 ? (
              <Pagination
                count={count}
                size="large"
                page={page}
                variant="outlined"
                shape="rounded"
                onChange={handleChange}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  margin: "5px",
                }}
              />
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        <NoRecord />
      )}
    </ForexSideBar>
  );
}
