import { Tooltip } from "@mui/material";
import React from "react";
import { DOC_BASE_URL } from "../../api/endPoint";
import documentPath from "./../../assets/Template/comercialInvoicetemplate.docx";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
import { useGetCiDocumentQuery } from "../../features/SCMApi";
import NoRecord from "../common/NoRecord";
export default function CIDocument(props) {
  const { data: ciDoc } = useGetCiDocumentQuery(
    "0c26b20b-63bd-4159-8c2a-cf1fa902be96"
  );
  
  const items = ciDoc?.data?.details[0]?.items
    ? JSON.parse(ciDoc?.data?.details[0]?.items)
    : null;
  items?.map((items, index) => (items.table_index = index + 1));

  let total_price = 0;
  items?.map((item) => (total_price = total_price + item?.pi_total_price));
  const CiData = {
    port: ciDoc?.data?.port,
    ...ciDoc?.data?.details[0],
    items,
    total_price,
  };
  function loadFile(url, callback) {
    PizZipUtils.getBinaryContent(url, callback);
  }
  const generateDocument = () => {
    loadFile(documentPath, function (error, content) {
      if (error) {
        throw error;
      }
      var zip = new PizZip(content);
      var doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      });
      doc.setData(CiData);
      try {
        // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
        doc.render();
      } catch (error) {
        // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
        function replaceErrors(key, value) {
          if (value instanceof Error) {
            return Object.getOwnPropertyNames(value).reduce(function (
              error,
              key
            ) {
              error[key] = value[key];
              return error;
            },
            {});
          }
          return value;
        }
        

        if (error.properties && error.properties.errors instanceof Array) {
          const errorMessages = error.properties.errors
            .map(function (error) {
              return error.properties.explanation;
            })
            .join("\n");
          
          // errorMessages is a humanly readable message looking like this :
          // 'The tag beginning with "foobar" is unopened'
        }
        throw error;
      }
      var out = doc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      }); //Output the document using Data-URI
      saveAs(out, "generatedCi.docx");
    });
  };
  
  return (
    <>
      <div style={{ margin: "10px 30px" }}>
        <span
          style={{
            color: "#353945",
            fontWeight: "500",
            fontSize: "16px",
            marginLeft: "10px",
          }}
        >
          CI Document
        </span>
      </div>
      {props?.data?.data[0]?.pi !== "" ? (
        <div
          style={{
            border: "2px #ECECEC solid",
            display: "flex",
            justifyContent: "space-between",
            margin: "5px auto",
            padding: "3px",
            alignSelf: "center",
            width: "90%",
          }}
        >
          <span
            style={{
              marginLeft: "4%",
              marginTop: "5px",
              color: "#353945",
              fontWeight: "400",
              fontSize: "14px",
            }}
          >
            {props?.batchName}-CI
          </span>
          <Tooltip title="Download">
            <button className="download" onClick={generateDocument}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="#186569"
                class="bi bi-download"
                viewBox="0 0 16 16"
              >
                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
              </svg>
            </button>
          </Tooltip>
        </div>
      ) : (
        <NoRecord />
      )}
    </>
  );
}
