import React from "react";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { IMG_BASE_URL } from "../../../api/endPoint";
import { useGetRecordToolsQuery } from "../../../features/SCMApi";
import StockIndex from "../../../pages/Stocks/StockIndex";
import { date } from "../../dateFormator";

export default function History() {
  const { data: ToolRecord } = useGetRecordToolsQuery();

  const [search, setSearch] = useState("");
  let [page, setPage] = useState(0);

  const PER_PAGE = 7;
  const handleSearchChange = (e) => {
    setSearch(e.target.value);

  };
  const count = Math.ceil(
    ToolRecord?.data?.filter((items) => {
      if (search === "") {
        return items;
      } else if (items.requester_name?.includes(search.toLowerCase())) {
        return items;
      }
      return false;
    }).length / PER_PAGE
  );
  const pageVisted = page * PER_PAGE;
  const handleChange = ({ selected }) => {
    setPage(selected);
  };

  return (
    <StockIndex>
      <div className="inventory-header-container">
        <input
          onChange={handleSearchChange}
          placeholder="search"
          type="search"
          className="inventory-search-input"
        />
      </div>
      <div className="finance-table-container">
        <table
          className="table table-striped table-bordered "
          style={{
            width: "auto",
            minWidth: "2000px",
            borderCollapse: "collapse",
          }}
        >
          <thead className="stock-table-header ">
            <tr>
              <th style={{ width: "7.5%" }}></th>
              <th style={{ width: "7.5%" }}>Employee Name</th>
              <th style={{ width: "7.5%" }}>Item</th>
              <th style={{ width: "9%" }}>Requested Date</th>
              <th style={{ width: "7.5%" }}>Requested Project </th>
              <th style={{ width: "7.5%" }}>Approved By</th>
              <th style={{ width: "9%" }}>Approved Date</th>
              <th style={{ width: "7.5%" }}>Returned Date </th>
              <th style={{ width: "6%" }}>Quantity</th>
              <th style={{ width: "6%" }}>Price</th>
              <th style={{ width: "7.5%" }}>Status</th>
              <th style={{ width: "7.5%" }}>Stock Keeper</th>
              <th style={{ width: "7.5%" }}>Store Name</th>
              <th style={{ width: "7.5%" }}>Remark</th>
            </tr>
          </thead>
          <tbody>
            {ToolRecord?.data
              ?.filter((items) => {
                if (search === "") {
                  return items;
                } else if (
                  items.requester_name?.toLowerCase().includes(search)
                ) {
                  return items;
                }
              })
              .slice(pageVisted, pageVisted + PER_PAGE)
              .map((tool) => (
                <tr className="stock-table-row">
                  <td className="">
                    <img
                      className="stock-img"
                      src={`${IMG_BASE_URL}${tool?.image}`}
                      alt=""
                    />
                  </td>{" "}
                  <td className="text-start">
                    {tool?.requester_name == null ? "-" : tool?.requester_name}
                  </td>
                  <td className="text-start">{tool?.item_name}</td>
                  <td className="text-start">{date(tool?.requested_date)}</td>
                  <td className="text-start">{tool?.project?.project_name}</td>
                  <td className="text-start">
                    {tool?.confirmed_by == null ? "-" : tool?.confirmed_by}
                  </td>
                  <td className="text-start">
                    {tool?.confirmed_date == null
                      ? "-"
                      : date(tool?.confirmed_date)}
                  </td>
                  <td className="text-start">{date(tool?.returned_date)}</td>
                  <td className="text-start">{tool?.requested_quantity}</td>
                  <td className="text-start">{`${
                    tool?.price == null ? "-" : tool?.price
                  } ${tool?.symbol == null ? "-" : tool?.symbol}`}</td>
                  <td className="text-start">
                    {tool?.status?.status_name == null
                      ? "-"
                      : tool?.status?.status_name}
                  </td>
                  <td className="text-start">
                    {tool?.storekeeper_name == null
                      ? "-"
                      : tool?.storekeeper_name}
                  </td>
                  <td className="text-start">
                    {tool?.store?.store_name == null
                      ? "-"
                      : tool?.store?.store_name}
                  </td>
                  <td className="text-start">
                    {tool?.remark == null ? "-" : tool?.remark}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="mt-3">
        {count > 1 ? (
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={count}
            onPageChange={handleChange}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        ) : (
          ""
        )}
      </div>
    </StockIndex>
  );
}
