import { Tab, Tabs } from "react-bootstrap";
import AllGuarantee from "../../../components/Payment/Guarantee/AllGuarantee";
import ProjectContainer from "../../../components/Payment/Guarantee/ProjectContainer";
import GuaranteeSummary from "../../../components/Payment/Guarantee/GuaranteeSummary";
import GuaranteeBalance from "../../../components/Payment/Guarantee/GuaranteeBalance";
import ClearedGuaranteeIndex from "../../../components/Payment/Guarantee/ClearGuaranteeIndex";
import LessThanGuaranteeIndex from "../../../components/Payment/Guarantee/LessThan15Guarantee";
import ExpiredGuaranteeIndex from "../../../components/Payment/Guarantee/ExpiredGuaranteen";
export default function GuaranteeNav() {
  return (
    <Tabs
      defaultActiveKey="all"
      id="uncontrolled-tab-example"
      className="mb-3 d-flex justify-content-center bg-white rounded gap-4 "
    >
      <Tab eventKey="all" title="All">
        <AllGuarantee />
      </Tab>

      <Tab eventKey="tobeexpired" title="< 15 days">
        <LessThanGuaranteeIndex />
      </Tab>
      <Tab eventKey="expired" title="Expired">
        <ExpiredGuaranteeIndex />
      </Tab>
      <Tab eventKey="Cleared" title="Cleared">
        <ClearedGuaranteeIndex />
      </Tab>
      <Tab eventKey="project" title="Project">
        <ProjectContainer />
      </Tab>
      <Tab eventKey="summary" title="Summary">
        <GuaranteeSummary />
      </Tab>
      <Tab eventKey="guarantees" title="Guarantees">
        <GuaranteeBalance />
      </Tab>
    </Tabs>
  );
}
