import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import EditSupplierPaymentTracking from "./EditSupplierPaymentTrackingModal";
import AddIcon from "@mui/icons-material/Add";
import AddSupplierCredit from "./AddSupplierCredit";

const AddSupplierCreditButton = (props) => {
  const [editData, setEditData] = React.useState({});

  const [editPaymentModal, setEditPaymentModal] = React.useState(false);
  const [editPaymentTrackingModal, setEditPaymentTrackingModal] =
    React.useState(false);
  const [editCreditNote, setEditCreditNote] = React.useState(false);

  function EditModalHandler() {
    props?.name === "paymentTracking"
      ? setEditPaymentTrackingModal(true)
      : props.name === "credit_note"
      ? setEditCreditNote(true)
      : setEditPaymentModal(true);
  }
  const handleEditPaymentButtonClick = (e, name) => {
    return EditModalHandler();
  };

  return (
    <>
      <Tooltip title="Add Row">
        <IconButton
          // size="small"
          sx={{
            width: "35px",
            height: "35px",
            backgroundColor: "#064b4f",
            color: "#ececec",
            "&:hover": {
              backgroundColor: "#186569",
              color: "#ececec",
            },
          }}
          onClick={(e) => (
            (setEditData({
              no: props?.editData?.no,
              vendor: props?.editData?.vendor,
              product: props?.editData?.product,
              description: props?.editData?.description,
              bank: props?.editData?.bank,
              expectedDate: props?.editData?.expectedDate,
              amount: props?.editData?.amount,
              wayOfPayment: props?.editData?.wayOfPayment,
            }),
            1),
            handleEditPaymentButtonClick()
          )}
        >
          <AddIcon style={{ fontSize: 18 }} />
        </IconButton>
      </Tooltip>

      {editPaymentTrackingModal ? (
        <AddSupplierCredit
          isadd={props?.isadd}
          isSupplier={props?.isSupplier}
          modal={setEditPaymentTrackingModal}
          supplier_id={props?.supplier_id}
          previousData={props?.editData}
          successMessage={props?.successMessage}
          message={props?.message}
          totalPayment={props?.totalPayment}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default AddSupplierCreditButton;
