import React, { useState } from "react";
import { useCreateSupplierMutation } from "../../../features/SCMApi";
import axios from "axios";
import { API_BASE_URL } from "../../../api/endPoint";
import "../Settings.css";

export default function CreateRepresentativeModal(props) {
  const [full_name, setFullname] = useState();
  const [email, setEmail] = useState();
  const [role, setRole] = useState();
  const [phone_numbers, setPhone_numbers] = useState([0]);
  const [createSupplier, response] = useCreateSupplierMutation();

  const handleChange = (e, i) => {
    e.preventDefault();
    const value = e.target.value;
    const phoneList = [...phone_numbers];
    phoneList[i] = value;
    setPhone_numbers(phoneList);
  };

  let params = {
    full_name: full_name,
    email: email,
    role: role,
    phone_numbers: phone_numbers,
    client_id: props?.clientId,
  };

  function HandleCancel() {
    props.modal(false);
  }

  const HandleSubmit = async (e) => {
    e.preventDefault();
    axios
      .post(`${API_BASE_URL}/scm/settings/clientrepresentatives`, params, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        
      })
      .catch((error) => {
        
        HandleCancel();
      });
    // createSupplier(params)
    //   .unwrap()
    //   .then((response) => {
    
    //   })
    //   .then((error) => {
    
    //   });
  };

  const [count, setCount] = useState([]);

  return (
    <div>
      <div id="myModal" className="modal">
        <div className="modal-content-md">
          <div className="create-project-modal-title clientRepModal">
            <div className="addRepTitle">Add Representative</div>
            <div onClick={HandleCancel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="42"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>

          <form className="form-container" onSubmit={HandleSubmit}>
            <div className="form-card-container">
              <div className="form-card-information">
                <div className="">
                  <div className="card-center">
                    <div className="card-form">
                      <div className="input-labels">
                        <div className="input-label">Representative Name</div>
                        <div className="input-label">Email</div>
                        <div className="input-label">Role</div>
                        <div className="input-label">Phone</div>
                      </div>
                      <div className="input-fields">
                        <input
                          type="text"
                          className="text-input"
                          onChange={(e) => setFullname(e.target.value)}
                        />
                        <input
                          type="text"
                          className="text-input"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <input
                          type="text"
                          className="text-input"
                          onChange={(e) => setRole(e.target.value)}
                        />

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "13%",
                            gap: "4px",
                          }}
                        >
                          <div className="d-flex">
                            <input
                              type={"number"}
                              className="supplier-text-input"
                              onChange={(event) => handleChange(event, 0)}
                            ></input>
                            <button
                              className="btn-add"
                              onClick={(event) => {
                                setCount((current) => [
                                  ...current,
                                  count[1] + 1,
                                ]);
                                event.preventDefault();
                              }}
                            >
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.0688 14.5834C10.2674 14.5834 10.4316 14.5184 10.5615 14.3886C10.6914 14.2587 10.7563 14.0945 10.7563 13.8959V10.7792H13.9188C14.1021 10.7792 14.2587 10.7143 14.3886 10.5844C14.5184 10.4546 14.5834 10.2903 14.5834 10.0917C14.5834 9.8931 14.5184 9.72886 14.3886 9.599C14.2587 9.46914 14.0945 9.40421 13.8959 9.40421H10.7563V6.08129C10.7563 5.89796 10.6914 5.74136 10.5615 5.6115C10.4316 5.48164 10.2674 5.41671 10.0688 5.41671C9.87018 5.41671 9.70594 5.48164 9.57608 5.6115C9.44622 5.74136 9.38129 5.9056 9.38129 6.10421V9.40421H6.08129C5.89796 9.40421 5.74136 9.46914 5.6115 9.599C5.48164 9.72886 5.41671 9.8931 5.41671 10.0917C5.41671 10.2903 5.48164 10.4546 5.6115 10.5844C5.74136 10.7143 5.9056 10.7792 6.10421 10.7792H9.38129V13.9188C9.38129 14.1021 9.44622 14.2587 9.57608 14.3886C9.70594 14.5184 9.87018 14.5834 10.0688 14.5834ZM10 19.1667C8.70143 19.1667 7.49449 18.9337 6.37921 18.4677C5.26393 18.0018 4.29379 17.3563 3.46879 16.5313C2.64379 15.7063 1.9983 14.7362 1.53233 13.6209C1.06636 12.5056 0.833374 11.2987 0.833374 10C0.833374 8.71671 1.06636 7.5174 1.53233 6.40212C1.9983 5.28685 2.64379 4.31671 3.46879 3.49171C4.29379 2.66671 5.26393 2.0174 6.37921 1.54379C7.49449 1.07018 8.70143 0.833374 10 0.833374C11.2834 0.833374 12.4827 1.07018 13.598 1.54379C14.7132 2.0174 15.6834 2.66671 16.5084 3.49171C17.3334 4.31671 17.9827 5.28685 18.4563 6.40212C18.9299 7.5174 19.1667 8.71671 19.1667 10C19.1667 11.2987 18.9299 12.5056 18.4563 13.6209C17.9827 14.7362 17.3334 15.7063 16.5084 16.5313C15.6834 17.3563 14.7132 18.0018 13.598 18.4677C12.4827 18.9337 11.2834 19.1667 10 19.1667ZM10 17.7917C12.1389 17.7917 13.9723 17.0278 15.5 15.5C17.0278 13.9723 17.7917 12.1389 17.7917 10C17.7917 7.86115 17.0278 6.02782 15.5 4.50004C13.9723 2.97226 12.1389 2.20837 10 2.20837C7.86115 2.20837 6.02782 2.97226 4.50004 4.50004C2.97226 6.02782 2.20837 7.86115 2.20837 10C2.20837 12.1389 2.97226 13.9723 4.50004 15.5C6.02782 17.0278 7.86115 17.7917 10 17.7917Z"
                                  fill="#186569"
                                />
                              </svg>
                            </button>
                          </div>
                          {count?.map((item, i) => (
                            <input
                              type={"text"}
                              className="supplier-text-input"
                              onChange={(event) => handleChange(event, i + 1)}
                            ></input>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-buttons repreForm">
                <button
                  type="cancel"
                  className="form-cancel repreCanel"
                  onClick={HandleCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="form-save repreSave"
                  onClick={HandleSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
