import { useState } from "react";
import {
  useCreateLessonLearnedMutation,
  useUploadSupplierPiMutation,
} from "../../../features/SCMApi";
import attachmentIcon from "../../../assets/SVG/attach.svg";
import { API_BASE_URL } from "../../../api/endPoint";
import axios from "axios";

export default function SupplierPiModal(props) {
  const [pi, setPi] = useState({});

  function handleChange(e) {
    setPi(e?.target?.files?.[0]);
  }


  const [uploadPI] = useUploadSupplierPiMutation();
  let form = { supplier_pi: pi };
  // let form = new FormData();
  // form?.append("supplier_pi", pi);

  function handleSubmit(e) {
    e?.preventDefault();
    axios
      .post(
        `${API_BASE_URL}/scm/supplier-payment/attach-pi/${props?.data?.id}`,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {

        //window.location.reload(true);
      })
      .then((error) => {

      });
  }
  return (
    <div
      id="Modal"
      className="dialog-modal "
      onClick={(e) => {
        props?.modal(false);
      }}
    >
      <div
        className="stock-dialog-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="dialog-info">Attach PI</div>
        <div className="input-label">PI Import</div>
        <div
          className="file-chooser cl-9 px-0"
          style={{ backgroundImage: `url(${attachmentIcon})` }}
        >
          <input
            id="chooser"
            required
            type={"file"}
            class="file-input"
            multiple
            onChange={(e) => {
              handleChange(e);
            }}
          />
          <label
            htmlFor="chooser"
            style={{
              position: "relative",
              top: "-28px",
              left: "5px",
              width: "10rem",
              // zIndex: "-1",
            }}
          >
            {pi
              ? pi?.name?.length > 16
                ? pi?.name?.slice(0, 15) + "..."
                : pi?.name
              : "Choose File"}
          </label>
        </div>
        <div className="confirm-dialog-controller">
          <div
            className="dialog-cancel-btn"
            onClick={(e) => {
              props?.modal(false);
            }}
          >
            No
          </div>
          <button
            type={"submit"}
            onClick={(e) => {
              handleSubmit(e);
            }}
            className={"form-save"}
          >
            Attach
          </button>
        </div>
      </div>
    </div>
  );
}
