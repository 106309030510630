import React from "react";
import { useState } from "react";
import StockIndex from "../../../pages/Stocks/StockIndex";
import ToolDetail from "../Tool/ToolDetail";
import ProjectDetail from "./ProjectDetail";
import Projects from "./Projects";

export default function Index() {
  const [fragment, setFragment] = useState("index");
  const [data, setData] = useState();
  const [projectId, setProjectId] = useState();
  const [Projectdata, setProjectData] = useState(null);
  return (
    <StockIndex>
      {fragment === "index" ? (
        <Projects setFragment={setFragment} setData={setData} />
      ) : fragment === "detail" ? (
        <ProjectDetail
          setProjectData={setProjectData}
          setFragment={setFragment}
          data={data}
        />
      ) : fragment === "projectdetail" ? (
        <ToolDetail
          isproject={true}
          setFragment={setFragment}
          data={Projectdata}
        />
      ) : (
        ""
      )}
    </StockIndex>
  );
}
