import React from "react";
import Settingsidebar from "../../../pages/Settings/SettingIndex";
import AddIcon from "@mui/icons-material/Add";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import CreatePermission from "../SettingModal/CreatePermission";
import { Table } from "react-bootstrap";
import { useGetUsersQuery } from "../../../features/SCMApi";
import { IMG_BASE_URL } from "../../../api/endPoint";
import UserAccessPermission from "../SettingModal/UserAccessPermission";
import EditUserAccessModal from "../SettingModal/EditUserAccessModal";
import AddUserModal from "../SettingModal/AddUserModal";
import { Tooltip } from "@mui/material";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
export default function Users() {
  const [userpermission, setUserPermisssion] = useState(false);
  const [Edituserpermission, setEditUserPermisssion] = useState(false);
  const [userinfo, setUserinfo] = useState(null);
  function Handleuserpermission() {
    setUserPermisssion(true);
  }
  function HandleEdituserpermission() {
    setEditUserPermisssion(true);
  }

  const [Messages, setMessages] = useState(null);
  const { data: users } = useGetUsersQuery();
  
  let avatar;
  const [search, setSearch] = useState("");
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    
  };
  const [AddUser, setAddUser] = useState(false);
  const HandleAddUser = () => {
    setAddUser(true);
  };
  // let us = users?.data;
  // const usersData = us?.sort(function (a, b) {
  //   const nameA = a.name?.toUpperCase();
  //   const nameB = b.name.toUpperCase();

  //   if (nameA < nameB) {
  //     return -1;
  //   }
  //   if (nameA > nameB) {
  //     return 1;
  //   }

  //   return 0;
  // });

  
  const items = [
    { id: "1", name: "John" },
    { id: "2", name: "Alice" },
    { id: "3", name: "Bob" },
    { id: "4", name: "Charlie" },
  ];

  // Sort the array based on the "name" property
  const [sortAscDesc, setSortAscDesc] = useState(false);
  const usersData = users?.data == undefined ? [] : users?.data && users?.data;
  const data = JSON.parse(JSON.stringify(usersData)); // Create a deep copy of the array
  data.sort((a, b) =>
    sortAscDesc == false
      ? a.name.localeCompare(b.name)
      : b.name.localeCompare(a.name)
  );

  

  return (
    <Settingsidebar>
      <div className="page">
        <div className="permission-card-container mt-4">
          <div className="d-flex justify-content-center">
            <input
              className="username-search"
              type={"text"}
              placeholder="Username"
              onChange={handleSearchChange}
            ></input>

            <div className="create-project-btn mt-4" onClick={HandleAddUser}>
              <AddIcon />
              Add User
            </div>
          </div>
          <div className="permission-divider"></div>
          <table>
            <thead className="users-table-header">
              <tr>
                <th style={{ width: "10%" }}>
                  <div className="d-flex gap-2 justify-content-center">
                    User
                    <SortByAlphaIcon
                      onClick={() => setSortAscDesc(!sortAscDesc)}
                    />
                  </div>
                </th>
                <th style={{ width: "20%" }}>Role</th>
                <th style={{ width: "50%" }}>Permission</th>
                <th style={{ width: "10%" }}></th>
              </tr>
            </thead>
            <tbody>
              {data
                ?.filter((items) => {
                  if (search === "") {
                    return items;
                  } else if (items.name?.toLowerCase().includes(search)) {
                    return items;
                  }
                })
                .map((userinfo) => (
                  <tr>
                    <td className="user-table-data">
                      <div className="userinfo">
                        <div className="participantManufacturer-img">
                          {userinfo?.avatar != null ? (
                            <img
                              src={`${IMG_BASE_URL}${userinfo?.avatar}`}
                              alt=""
                            />
                          ) : (
                            <div className="default-avatar">
                              <div style={{ display: "none" }}>
                                {(avatar = userinfo?.name.split(" "))}
                              </div>
                              {avatar[0]?.charAt(0)}
                              {avatar[1]?.charAt(0)}
                            </div>
                          )}
                        </div>
                        <div className="userinfo-container">
                          <div className="username">{userinfo?.name}</div>
                          <div className="user-email">{userinfo?.email}</div>
                        </div>
                      </div>
                    </td>
                    {userinfo?.role !== null ? (
                      <td className="user-table-data">
                        <div class="row">
                          <div class="user-role-rounded my-2 mx-3 mb-3 col-3">
                            <div class="d-flex align-items-baseline">
                              <div class="supplier-product-rounded-text mx-2">
                                {userinfo?.role?.role_name}
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    ) : (
                      <p className="prg text-center mt-4">Role doesn't Exist</p>
                    )}
                    <td className="user-table-data">
                      {userinfo?.permissions?.length !== 0 ? (
                        <div className="permission-display-container">
                          {userinfo?.permissions?.map((permission) => (
                            <div class="user-role-rounded bg-warning my-3  col-3">
                              <div class="d-flex align-items-baseline">
                                <span
                                  title={permission?.permission_name}
                                  class="supplier-product-rounded-text mx-2"
                                >
                                  {permission?.permission_name?.length >= 18
                                    ? permission?.permission_name.slice(0, 18) +
                                      "..."
                                    : permission?.permission_name}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <p className="prg text-center mt-4">
                          permission doesn't Exist
                        </p>
                      )}
                    </td>
                    {userinfo?.role === null ? (
                      <td className="user-table-data">
                        {" "}
                        <IconButton
                          variant="text"
                          size="small"
                          sx={{
                            zIndex: 1,
                            backgroundColor: "#fff",
                            color: "#186569",
                            "&:hover": {
                              backgroundColor: "#186569",
                              color: "#fff",
                            },
                          }}
                          onClick={() => {
                            setUserinfo(userinfo);
                            Handleuserpermission();
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      </td>
                    ) : (
                      <td className="user-table-data">
                        {" "}
                        <IconButton
                          variant="text"
                          size="small"
                          sx={{
                            zIndex: 1,
                            backgroundColor: "#fff",
                            color: "#186569",
                            "&:hover": {
                              backgroundColor: "#186569",
                              color: "#fff",
                            },
                          }}
                          onClick={() => {
                            setUserinfo(userinfo);
                            HandleEdituserpermission();
                          }}
                        >
                          <ModeEditOutlinedIcon />
                        </IconButton>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      {userpermission ? (
        <UserAccessPermission
          modal={setUserPermisssion}
          setMessages={setMessages}
          user={userinfo}
        />
      ) : (
        <></>
      )}
      {AddUser ? (
        <AddUserModal
          modal={setAddUser}
          setMessages={setMessages}
          user={userinfo}
        />
      ) : (
        <></>
      )}
      {Edituserpermission ? (
        <EditUserAccessModal
          user={userinfo}
          modal={setEditUserPermisssion}
          setMessages={setMessages}
        />
      ) : (
        <></>
      )}
    </Settingsidebar>
  );
}
