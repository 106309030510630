import { IconButton, Input } from "@mui/material";
import React, { useState } from "react";
import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
import axios from "axios";
import { API_BASE_URL } from "../../../api/endPoint";

export default function AddUserModal(props) {
  function HandleCancel() {
    props.modal(false);
  }
  const [file, setFile] = useState(null);
  const [image, setImage] = useState({ preview: "", data: "" });

  const handleChange = function loadFile(e) {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setImage(img);
  };
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [password_confirmation, setPasswordConfirmation] = useState();
  let adduser = {
    name,
    email,
    avatar: file,
    password,
    password_confirmation,
  };

  const HandleSubmit = async (e) => {
    const form = new FormData();
    form.append("name", name);
    form.append("email", email);
    form.append("avatar", image.data);
    form.append("password", password);
    form.append("password_confirmation", password_confirmation);

    e?.preventDefault();
    axios
      .post(`${API_BASE_URL}/register`, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        
        window.location.reload(true);
        props.setMessages("Permission assigned successfully");
      })
      .then((error) => {
        
      });
    // createSupplier(params)
    //   .unwrap()
    //   .then((response) => {
    
    //   })
    //   .then((error) => {
    
    //   });
  };
  
  return (
    <div id="myModal" className="modal">
      <div className="modal-content-md">
        <div className="create-project-modal-title clientRepModal">
          <div className="">Add User</div>
          <div onClick={HandleCancel}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="42"
              fill="currentColor"
              class="bi bi-x-lg"
              viewBox="0 0 16 16"
            >
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
            </svg>
          </div>
        </div>

        <form className="form-container" onSubmit={HandleSubmit}>
          <div className="form-card-container">
            <div className="form-card-information">
              <div className="">
                <div className="user-card-center">
                  <div className="user-profile ">
                    <div id="image">
                      {image?.preview === "" ? (
                        <img
                          src={`https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png`}
                        />
                      ) : (
                        <img src={image?.preview} />
                      )}

                      <div id="camera--icon">
                        <input
                          name="Select File"
                          type="file"
                          onChange={handleChange}
                          accept="image/*"
                        ></input>
                        <CameraAltRoundedIcon />
                      </div>
                    </div>
                  </div>
                  <div className="card-form">
                    <div className="input-labels">
                      <div className="input-label">Name</div>
                      <div className="input-label"> Email</div>
                      <div className="input-label">Password</div>
                      <div className="input-label">Confirm Password</div>
                    </div>

                    <div className="input-fields">
                      <input
                        type="text"
                        className="text-input"
                        onChange={(e) => setName(e.target.value)}
                      />
                      <input
                        type="email"
                        className="text-input"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <input
                        type="password"
                        className="text-input"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <input
                        type="password"
                        className="text-input"
                        onChange={(e) =>
                          setPasswordConfirmation(e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-buttons repreForm">
              <button
                type="cancel"
                className="form-cancel repreCanel"
                onClick={HandleCancel}
              >
                Cancel
              </button>
              <button type="submit" className="form-save repreSave">
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
