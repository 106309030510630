import React from "react";
import { useState } from "react";
import {
  useGetProjectQuery,
  useGetSuppliersOrdersQuery,
  usePriceComparisionMutation,
  useTraningPriceComparisionMutation,
} from "../../../features/SCMApi";
import SourcingIndex from "../../../pages/Sourcing/SourcingIndex";
import attachmentIcon from "../../../assets/SVG/attach.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import { useParams, useNavigate } from "react-router-dom";
import CompariedDataModal from "./CompariedDataModal";
import ErrorMessage from "../../Payment/ErrorMessage";
import SuccessModal from "../../common/successModal";
import Select from "react-select";

export default function Index(props) {
  const { data: suppliers } = useGetSuppliersOrdersQuery();
  const [formData, setFormData] = useState([{ supplier_id: "", pi: null }]);
  const params = useParams();
  const { data: project } = useGetProjectQuery(params.id);
  const addForm = () => {
    setFormData([...formData, { supplier_id: "", pi: null }]);
  };
  const handleSelectChange = (selectedOption, index) => {
    const newFormData = [...formData];
    newFormData[index].supplier_id = selectedOption ? selectedOption.value : ""; // If selectedOption is null, set an empty string or any other default value
    setFormData(newFormData);
  };

  const handleFileChange = (event, index) => {
    const newFormData = [...formData];
    newFormData[index].pi = event.target.files[0];
    setFormData(newFormData);
  };
  const removeForm = (index) => {
    const newFormData = [...formData];
    newFormData.splice(index, 1);
    setFormData(newFormData);
  };
  let supplier_id = [];
  let pi = [];
  formData?.map((items) => supplier_id.push(items?.supplier_id));
  formData?.map((items) => pi.push(items?.pi));
 
  const [priceComparision, res] = usePriceComparisionMutation();
  const [traningPriceComparision, resp] = useTraningPriceComparisionMutation();
  const form = new FormData();
  supplier_id.forEach((item) => {
    form.append("suppliers[]", item);
  });
  pi.forEach((item) => {
    form.append("pis[]", item);
  });
  const [comparisonModal, setComparisonModal] = useState(false);
  const [data, setData] = useState();

  const [loading, setLoading] = useState(false);
  const [error, setErrorMessage] = useState("");
  const [errordisplay, setErrorDisplay] = useState(false);
  let navigate = useNavigate();
  // function HandleSubmit(e) {
  //   setLoading(true);
  //   e.preventDefault();
  //   priceComparision(form)
  //     .unwrap()
  //     .then((response) => {
  //       // setComparisonModal(true);
  //       navigate(`compared/${response?.id}`, {
  //         state: response?.list_price_data,
  //       });
  //       setLoading(false);
  //       setData(response);

  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       setErrorMessage(error?.data?.message);
  //       setErrorDisplay(true);
  //       console.error(
  //         error,

  //         "Response for created order from backend for price err"
  //       );
  //       // handle the error here
  //     });
  // }
  function HandleSubmit(e) {
    setLoading(true);
    e.preventDefault();

    props?.order === "product"
      ? priceComparision(form)
          .unwrap()
          .then((response) => {
            navigate(`compared/${response?.id}`, {
              state: response?.list_price_data,
            });
            setLoading(false);
            setData(response);
            
          })
          .catch((error) => {
            setLoading(false);
            setErrorMessage(error?.data?.message);
            setErrorDisplay(true);
            console.error(
              error,
              "Response for created order from backend for price err"
            );
          })
      : props?.order === "training"
        ? traningPriceComparision(form)
            .unwrap()
            .then((response) => {
              navigate(`compared/${response?.id}`, {
                state: response?.list_price_data,
              });
              setLoading(false);
              setData(response);
              
            })
            .catch((error) => {
              setLoading(false);
              setErrorMessage(error?.data?.message);
              setErrorDisplay(true);
              console.error(
                error,
                "Response for created order from backend for traning err"
              );
            })
        : alert("unknown error");
  }

  setTimeout(() => {
    setErrorDisplay(false);
  }, 10000);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(false);
  };

  
  function FormFields({ index }) {
    return (
      <div className="supplier-attachment-container mb-2">
        {/* <select
          value={formData[index].supplier_id}
          className="supplier-selector"
          onChange={(e) => handleSelectChange(e, index)}
        >
          <option value="" selected>
            Supplier
          </option>
          {suppliers?.data?.map((items) => (
            <option value={items?.id}>{items?.distributor_name}</option>
          ))}
        </select> */}

        <Select
          value={suppliers?.data
            ?.filter((items) => items?.id === formData[index]?.supplier_id)
            ?.map((item) => ({
              label: item?.distributor_name,
              value: item?.id,
            }))}
          className="supplier-selector"
          style={{ color: "black" }}
          onChange={(selectedOption) =>
            handleSelectChange(selectedOption, index)
          }
          options={suppliers?.data?.map((item) => ({
            value: item.id,
            label: item.distributor_name,
          }))}
        />
        <div className="d-flex gap-3 align-items-center">
          <div className="attach-pi-text">Attach PI</div>
          <div
            className="price-comparison-file-chooser cl-9 px-0"
            style={{ backgroundImage: `url(${attachmentIcon})` }}
          >
            <input
              id="chooser"
              type={"file"}
              class="file-input"
              multiple
              onChange={(e) => handleFileChange(e, index)}
            />
            <label
              htmlFor="chooser"
              style={{
                position: "relative",
                bottom: "23px",
                left: "5px",
                width: "10rem",
              }}
            >
              {formData[index].pi ? formData[index].pi?.name : "Choose File"}
            </label>
          </div>
          {formData?.length != 1 ? (
            <DeleteIcon
              onClick={() => removeForm(index)}
              style={{ color: "red" }}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }

  return (
    <SourcingIndex
      title={`${project?.data?.project_name} Orders`}
      navlink={`/sourcing/orders/${params?.id}`}
      name={"Price Comparison"}
    >
      <div className="price-comparison-container">
        <div className="p-4">
          <button className="add-client-representative" onClick={addForm}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 16C7.75238 16 7.54762 15.919 7.38571 15.7571C7.22381 15.5952 7.14286 15.3905 7.14286 15.1429V8.85714H0.857143C0.609524 8.85714 0.404762 8.77619 0.242857 8.61429C0.0809525 8.45238 0 8.24762 0 8C0 7.75238 0.0809525 7.54762 0.242857 7.38571C0.404762 7.22381 0.609524 7.14286 0.857143 7.14286H7.14286V0.857143C7.14286 0.609524 7.22381 0.404762 7.38571 0.242857C7.54762 0.0809525 7.75238 0 8 0C8.24762 0 8.45238 0.0809525 8.61429 0.242857C8.77619 0.404762 8.85714 0.609524 8.85714 0.857143V7.14286H15.1429C15.3905 7.14286 15.5952 7.22381 15.7571 7.38571C15.919 7.54762 16 7.75238 16 8C16 8.24762 15.919 8.45238 15.7571 8.61429C15.5952 8.77619 15.3905 8.85714 15.1429 8.85714H8.85714V15.1429C8.85714 15.3905 8.77619 15.5952 8.61429 15.7571C8.45238 15.919 8.24762 16 8 16Z"
                fill="white"
              />
            </svg>
          </button>
          {errordisplay ? <ErrorMessage Message={error} /> : ""}
        </div>
        {formData?.map((item, index) => (
          <FormFields key={index} index={index} />
        ))}
        {formData?.length != 1 ? (
          <div className="d-flex justify-content-end gap-4 m-4">
            <div className="dialog-cancel-btn">Cancel</div>
            <button
              onClick={HandleSubmit}
              disabled={loading}
              className={
                loading ? "form-save bg-secondary text-white" : "form-save"
              }
            >
              {loading ? "Loading....." : "Compare Price"}
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
      {comparisonModal ? (
        <CompariedDataModal
          setComparisonModal={setComparisonModal}
          data={data?.list_price_data}
          id={data?.id}
          setOpen={setOpen}
        />
      ) : (
        ""
      )}

      <SuccessModal open={open} />
    </SourcingIndex>
  );
}
