import React, { useState } from "react";
import axios from "axios";
import {
  useCreateOrderMutation,
  useCreatePrfMutation,
  useGeneratePrfMutation,
  useGetBankListForGapAnalysisQuery,
  useGetBoqtypesQuery,
  useGetManufacturersQuery,
  useGetMillstonesQuery,
  useGetPaymentsModeQuery,
  useGetProductsQuery,
  useGetProjectQuery,
  useGetProjectsQuery,
  useGetSettingBankQuery,
  useGetSuppliersOrdersQuery,
  useGetSuppliersQuery,
  useGetUsersQuery,
  useUpdatePrfMutation,
} from "../../features/SCMApi";
import MultiSelect from "../common/MultiSelect";
import ManufacturerForm from "./ManufacturerForm";
import { API_BASE_URL } from "../../api/endPoint";
import CloseIcon from "@mui/icons-material/Close";
import { NavLink, useParams } from "react-router-dom";
import LoadingScreen from "react-loading-screen";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import WarningIcon from "@mui/icons-material/Warning";
import FileSaver from "file-saver";
import PiTemplate from "../../assets/Template/IE-Sample PI.xlsx";
import DownloadIcon from "@mui/icons-material/Download";
import { Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Download } from "@mui/icons-material";
import GeneratePRF from "./GeneratePRF";

export default function UpdatePRF(props) {
  const [budget_number, setBudgetNumber] = useState(props?.data?.budget_number);
  const [requested_by, setRequestBy] = useState(props?.data?.requested_by?.id);
  const [prepared_by, setPreparedBy] = useState(props?.data?.prepared_by?.id);
  const [approved_by, setApprovedBy] = useState(props?.data?.approved_by?.id);
  const [paid_by, setPaidBy] = useState(props?.data?.paid_by?.id);
  const [bank_id, setBank] = useState(props?.data?.bank_id);
  const [account_type, setAccountType] = useState(props?.data?.account_type);
  const [amount, setAmount] = useState(props?.data?.amount);
  const [payment_mode, setPaymentMode] = useState(
    props?.data?.payment_mode?.id
  );
  const [reason_for, setReasonFor] = useState(props?.data?.reason_for);
  const [is_tot, setToT] = useState(props?.data?.is_tot == 0 ? false : true);
  const [with_out_withholding, setWithOutHolding] = useState(
    props?.data?.with_out_withholding == 1 ? true : false
  );
  const [with_out_vat, setWithOutVAT] = useState(
    props?.data?.with_out_vat == 1 ? true : false
  );
  const [selectedOptiontot, setSelectedOptionTot] = useState(
    props?.data?.is_tot == 1 ? 1 : props?.data?.is_tot == 2 ? 2 : 0
  );
  const handleOptionChange = (e) => {
    setSelectedOptionTot(e.target.value);
  };

  function HandleCancel() {
    props?.modal(false);
  }
  const { data: paymentmode } = useGetPaymentsModeQuery();
  const { data: bankList } = useGetSettingBankQuery();
  const accountTypes = ["birr", "retention"];
  const { data: users } = useGetUsersQuery();
  const params = useParams();
  let form = {
    id: params?.prf_id,
    amount,
    budget_number,
    requested_by,
    prepared_by,
    approved_by,
    paid_by,
    bank_id,
    account_type,
    payment_mode,
    reason_for,
    is_tot: is_tot == true ? selectedOptiontot : 0,
    with_out_withholding,
    with_out_vat,
  };
  const [UpdatePRF] = useUpdatePrfMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    UpdatePRF(form)
      .unwrap()
      .then((response) => {

        // props.setMessages("Create successfully");
        props.modal(false);
      })
      .catch((error) => {

      });
    // axios({
    //   method: "post",
    //   url: `${API_BASE_URL}/scm/sourcing/prf/${props?.order_id}`,
    //   data: form,
    //   headers: { "Content-Type": "application/json" },
    // })
    //   .then(function (response) {
    //     // handle success
    //     // props.ToggleModal(false);
    //     // window.location.reload(true);

    //   })
    //   .catch(function (error) {
    //     //handle error

    //   });
  };


  const CheckBoxHandelToT = () => {
    setToT(!is_tot);
  };
  const CheckBoxHandelWOWithHolder = () => {
    setWithOutHolding(!with_out_withholding);
  };
  const CheckBoxHandelWOVat = () => {
    setWithOutVAT(!with_out_vat);
  };

  let payed_amount =
    props?.data?.payed_amount == undefined ? 0 : props?.data?.payed_amount;
  let total_amount = props?.data?.total_payment - payed_amount;
  return (
    <div>
      <div id="myModal" className="medium-modal" onClick={HandleCancel}>
        <div
          className="medium-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="create-project-modal-title">
            <div>Update PRF Information</div>
            <CloseIcon onClick={HandleCancel} />
          </div>
          {props?.data != null && props?.isApproved ? (
            <GeneratePRF data={props?.data} />
          ) : (
            ""
          )}

          <form
            className="create-order-form mt-4"
            enctype="multipart/form-data"
            onSubmit={(e) => handleSubmit(e)}
          >
            <div className="order-supplier">
              <div className="supplier-input-labels">
                <div className="d-flex align-items-center gap-4">
                  <div className="d-flex  justify-content-center gap-2 px-2">
                    <input
                      checked={is_tot}
                      type="checkbox"
                      className="stock-checkmark"
                      onChange={CheckBoxHandelToT}
                    />
                    <span className="h6">TOT(Turn Over Tax)</span>
                  </div>
                  <div className="d-flex  justify-content-center gap-2 px-2">
                    <input
                      defaultChecked={
                        props?.data?.with_out_vat == 1 ? true : false
                      }
                      type="checkbox"
                      className="stock-checkmark"
                      onChange={CheckBoxHandelWOVat}
                    />
                    <span className="h6">With out VAT</span>
                  </div>
                  <div className="d-flex  justify-content-center gap-2 px-2">
                    <input
                      defaultChecked={
                        props?.data?.with_out_withholding == 1 ? true : false
                      }
                      type="checkbox"
                      className="stock-checkmark"
                      onChange={CheckBoxHandelWOWithHolder}
                    />
                    <span className="h6">With out WithHolding</span>
                  </div>
                </div>

                {is_tot == true ? (
                  <div
                    className="d-flex align-items-center gap-3"
                    style={{ marginTop: "-28px" }}
                  >
                    <div className="d-flex align-items-center justify-content-center gap-2 px-2">
                      <input
                        type="radio"
                        value={1}
                        checked={selectedOptiontot == 1}
                        onChange={handleOptionChange}
                      />
                      <div className="">With 2%</div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center gap-2 px-2">
                      <input
                        type="radio"
                        value={2}
                        checked={selectedOptiontot == 2}
                        onChange={handleOptionChange}
                      />
                      <div className="">With 10%</div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="d-flex justify-content-center gap-2">
                  <div className="prg-pri">Total Payment:</div>
                  <div className="prg">
                    {Number(total_amount)?.toLocaleString()}
                  </div>
                </div>
                {amount > total_amount ? (
                  <div className="prg-error text-danger text-center">
                    Please correct your amount
                  </div>
                ) : (
                  ""
                )}
                <div className="d-grid justify-content-center gap-3">
                  <div className="d-flex gap-2 align-items-baseline">
                    <div className="supplier-input-label">amount</div>
                    <input
                      value={amount}
                      type="number"
                      className={`supplier-text-input`}
                      required
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </div>
                  <div className="d-flex gap-2 align-items-baseline">
                    <div className="supplier-input-label">Budget Number</div>
                    <input
                      value={budget_number}
                      type="text"
                      className={`supplier-text-input`}
                      required
                      onChange={(e) => setBudgetNumber(e.target.value)}
                    />
                  </div>

                  <div className="d-flex gap-2 align-items-baseline">
                    <div className="supplier-input-label">Payment Mode</div>
                    <select
                      value={payment_mode}
                      name=""
                      id=""
                      className="supplier-select-option"
                      required
                      onChange={(e) => setPaymentMode(e.target.value)}
                    >
                      <option value="" disabled selected hidden>
                        Choose Payment Mode
                      </option>
                      {paymentmode?.data?.map((items) => (
                        <option value={items.id}>{items.payment_mode}</option>
                      ))}
                    </select>
                  </div>
                  <div className="d-flex gap-2 align-items-baseline">
                    <div className="supplier-input-label">Account Type</div>
                    <select
                      value={account_type}
                      name=""
                      id=""
                      className="supplier-select-option"
                      required
                      onChange={(e) => setAccountType(e.target.value)}
                    >
                      <option value="" disabled selected hidden>
                        Choose Account Type
                      </option>
                      {accountTypes?.map((items) => (
                        <option value={items}>{items}</option>
                      ))}
                    </select>
                  </div>
                  <div className="d-flex gap-2 align-items-baseline">
                    <div className="supplier-input-label">Bank</div>
                    <select
                      value={bank_id}
                      name=""
                      id=""
                      className="supplier-select-option"
                      required
                      onChange={(e) => setBank(e.target.value)}
                    >
                      <option value="" disabled selected hidden>
                        Choose Bank
                      </option>
                      {bankList?.data?.map((items) => (
                        <option value={items.id}>{items.bank_name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="d-flex gap-2 align-items-baseline">
                    <div className="supplier-input-label">Requested By</div>
                    <select
                      value={requested_by}
                      name=""
                      id=""
                      className="supplier-select-option"
                      required
                      onChange={(e) => setRequestBy(e.target.value)}
                    >
                      <option value="" disabled selected hidden>
                        Choose Requested By
                      </option>
                      {users?.data?.map((items) => (
                        <option value={items.id}>{items.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="d-flex gap-2 align-items-baseline">
                    <div className="supplier-input-label">Prepared By</div>
                    <select
                      value={prepared_by}
                      name=""
                      id=""
                      className="supplier-select-option"
                      required
                      onChange={(e) => setPreparedBy(e.target.value)}
                    >
                      <option value="" disabled selected hidden>
                        Choose Prepared By
                      </option>
                      {users?.data?.map((items) => (
                        <option value={items.id}>{items.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="d-flex gap-2 align-items-baseline">
                    <div className="supplier-input-label">Approved By</div>
                    <select
                      value={approved_by}
                      name=""
                      id=""
                      className="supplier-select-option"
                      required
                      onChange={(e) => setApprovedBy(e.target.value)}
                    >
                      <option value="" disabled selected hidden>
                        Choose Approved By
                      </option>
                      {users?.data?.map((items) => (
                        <option value={items.id}>{items.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="d-flex gap-2 align-items-baseline">
                    <div className="supplier-input-label">Paid By</div>
                    <select
                      value={paid_by}
                      name=""
                      id=""
                      className="supplier-select-option"
                      required
                      onChange={(e) => setPaidBy(e.target.value)}
                    >
                      <option value="" disabled selected hidden>
                        Choose Paid By
                      </option>
                      {users?.data?.map((items) => (
                        <option value={items.id}>{items.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="d-flex gap-2 align-items-baseline">
                    <div className="supplier-input-label">Reason For</div>
                    <textarea
                      type="text"
                      className="sourcing-text-input-textarea"
                      rows={4}
                      cols={50}
                      onChange={(e) => setReasonFor(e.target.value)}
                    >
                      {reason_for}
                    </textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-buttons ">
              <button type="cancel" class="form-cancel" onClick={HandleCancel}>
                Cancel
              </button>
              <button
                type="submit"
                disabled={amount > total_amount ? true : false}
                class={
                  amount > total_amount
                    ? "form-save bg-secondary text-white"
                    : "form-save"
                }
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
