import React from "react";
import { Autocomplete, TextField } from "@mui/material";

const MuiSelect = ({ options, setters, value, isinput, label }) => {
  const handleOptionChange = (event, value) => {
    setters(value);
  };
 
  const style = {
    width: isinput ? 250 : 300,
    height: isinput ? "30px" : "35px",
    backgroundColor: "#eff2f3",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "14px",
    letterSpacing: "0.105em",
    borderRadius: "6px",
    color: "#000",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiOutlinedInput-input": {
      marginTop: "0px",
    },
  };

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(options) => options.label}
      value={value}
      sx={style}
      onChange={handleOptionChange}
      renderInput={(params) => (
        <TextField
          sx={{ marginTop: "-10px", color: "#000" }}
          {...params}
          label={isinput ? label : "Select Project"}
          variant="outlined"
        />
      )}
    />
  );
};

export default MuiSelect;
