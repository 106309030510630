import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

export default function FilterActions(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [isClicked, setIsClicked] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsClicked(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    // if (isSelected === false) {
    //   setIsClicked(false);
    // } else {
    //   setIsClicked(true);
    // }
  };
  const [isSupplierChecked, setIsSupplierChecked] = useState(false);
  const [onClickEdit, setOnClickEdit] = useState("");
  const [onClickDelete, setOnClickDelete] = useState("");

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Filter">
          <IconButton
            onClick={handleClick}
            size="large"
            sx={{
              ml: 1,
              //marginRight: "5px",
              paddingTop: "6px",
              color: "#186559",
            }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            aria-label="add"
          >
            {isClicked === false ? (
              <FilterAltOffIcon fontSize="40px" />
            ) : (
              <FilterAltIcon fontSize="40px" />
            )}
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 12,
              height: 12,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 12,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          sx={{ color: "#064b4f" }}
          onClick={(e) => {
            props.onClickProject(true);
            setIsClicked(true);
          }}
        >
          Project
        </MenuItem>
        <MenuItem
          sx={{ color: "#064b4f" }}
          onClick={(e) => {
            props.onClickBank(true);
            setIsClicked(true);
          }}
        >
          Bank
        </MenuItem>
        <MenuItem
          sx={{ color: "#064b4f" }}
          onClick={(e) => {
            props.onClickSupplier(true);
            setIsClicked(true);
          }}
        >
          Supplier
        </MenuItem>
      </Menu>
    </>
  );
}
