import React from "react";
import ForexSideBar from "../../../components/Payment/ForexApproval/PaymentSideBar";
import { NavLink, useLocation } from "react-router-dom";
import GuaranteeNav from "./GuaranteeNavBar";

const GuaranteeIndex = () => {
  const location = useLocation();
  return (
    <ForexSideBar>
      <GuaranteeNav />
    </ForexSideBar>
  );
};

export default GuaranteeIndex;
