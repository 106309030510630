import React, { useState } from "react";
import StockIndex from "../../../pages/Stocks/StockIndex";
import AllTransaction from "./transaction/AllTransaction";
import TransactionAssets from "./transaction/TransactionAssets";
import TransactionItems from "./transaction/TransactionItems";
import TransactionTools from "./transaction/TransactionTools";

function Transaction() {
  const [Inventory, setInventory] = useState("All");
  function onChangeValue(event) {
    setInventory(event.target.value);
  }
  return (
    <StockIndex>
      <div
        className="tool-header-container d-flex gap-3 justify-content-center "
        onChange={onChangeValue}
      >
        <input
          checked={Inventory === "All"}
          id="default-radio-1"
          type="radio"
          value="All"
          name="default-radio"
          className="cursor-pointer"
        />
        <label for="default-radio-1" className="prg">
          All
        </label>
        <input
          checked={Inventory === "Tool"}
          id="default-radio-1"
          type="radio"
          value="Tool"
          name="default-radio"
          className="cursor-pointer"
        />
        <label for="default-radio-1" className="prg">
          Tools
        </label>

        <input
          checked={Inventory === "Items"}
          id="default-radio-2"
          type="radio"
          value="Items"
          name="default-radio"
          className="cursor-pointer"
        />
        <label for="default-radio-2" className="prg">
          Items
        </label>
        <input
          checked={Inventory === "Assets"}
          id="default-radio-2"
          type="radio"
          value="Assets"
          name="default-radio"
          className="cursor-pointer"
        />
        <label for="default-radio-2" className="prg">
          Assets
        </label>
      </div>
      {Inventory == "All" ? (
        <AllTransaction />
      ) : Inventory == "Tool" ? (
        <TransactionTools />
      ) : Inventory == "Items" ? (
        <TransactionItems />
      ) : Inventory == "Assets" ? (
        <TransactionAssets />
      ) : (
        "undifine"
      )}
    </StockIndex>
  );
}

export default Transaction;
