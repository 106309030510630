import React from "react";
import { useGetNotificationQuery } from "../../features/SCMApi";
import PaymentTrackingDashboard from "./PaymentTrackingDashboard";
import TaskContainer from "./TaskContainer";

export default function Task() {
  const { data: notifications } = useGetNotificationQuery();
  const unreadNotifications =
    notifications &&
    notifications?.filter((notification) => notification?.read_at == null);
  return (
    <>
      {unreadNotifications?.length != 0 ? (
        <div className="task-card">
          <div className="home-card-label">Tasks</div>
          <div className="divider"></div>
          <div className="rma-task-container">
            <TaskContainer />
          </div>
        </div>
      ) : (
        <PaymentTrackingDashboard />
      )}
    </>
  );
}
