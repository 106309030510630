import React, { useState } from "react";
import ForexSideBar from "../../../components/Payment/ForexApproval/PaymentSideBar";
import {
  useGetPrfBudgetQuery,
  useGetPrfBudgetOfficeQuery,
  useGetBudgetApprovedQuery,
  useApprovedMonthlyBudgetQuery,
} from "../../../features/SCMApi";
import CashinFlow from "./CashinFlow";
import CashoutFlow from "./CashoutFlow";
import ApprovedBudget from "./ApprovedBudget";
import ApprovedMonthlyBudgets from "./ApprovedMonthlyBudgets";

export default function Index() {
  const { data: projectBudget, loading: budgetLoading } =
    useGetPrfBudgetQuery();
  const { data: OfficeprojectBudget } = useGetPrfBudgetOfficeQuery();
  const { data: approvedBudgets } = useGetBudgetApprovedQuery();
  const { data: approvedMonthlyBudgets } = useApprovedMonthlyBudgetQuery();

  const [types, setType] = useState("project");
  return (
    <ForexSideBar title={"Budget"} name={"Budget"} navlink={`/payment`}>
      <div className="card mb-3">
        <div className="d-flex justify-content-center gap-5 p-4 ">
          <div className="d-flex align-items-center gap-2 me-3">
            <button
              value={"project"}
              onClick={(e) => setType("project")}
              className={
                types == "project"
                  ? "report-module-select-active"
                  : "report-module-select"
              }
            ></button>
            <label className="module-label">Project Budget</label>
          </div>
          <div className="d-flex align-items-center gap-2">
            <button
              value={"officeProject"}
              onClick={(e) => setType("officeProject")}
              className={
                types == "officeProject"
                  ? "report-module-select-active"
                  : "report-module-select"
              }
            ></button>
            <label className="module-label">Office Project Budget</label>
          </div>

          <div className="d-flex align-items-center gap-2">
            <button
              value={"ApprovedBudgets"}
              onClick={(e) => setType("ApprovedBudgets")}
              className={
                types == "ApprovedBudgets"
                  ? "report-module-select-active"
                  : "report-module-select"
              }
            ></button>
            <label className="module-label">Budget Approval</label>
          </div>
          <div className="d-flex align-items-center gap-2">
            <button
              value={"allApprovedBudget"}
              onClick={(e) => setType("allApprovedBudget")}
              className={
                types == "allApprovedBudget"
                  ? "report-module-select-active"
                  : "report-module-select"
              }
            ></button>
            <label className="module-label">Approved Budget</label>
          </div>
        </div>
      </div>
      <div>
        {types == "project" && (
          <>
            <h1 className="h5-pri text-center">Collection</h1>
            <hr />
            <CashinFlow data={projectBudget?.data?.total_collection} />
            <br />
          </>
        )}

        {types == "project" || types == "officeProject" ? (
          <>
            <br />
            <h1 className="h5-pri text-center">Budget</h1>
            <hr />
            <CashoutFlow
              data={
                types == "project"
                  ? projectBudget?.data?.prf_budget
                  : OfficeprojectBudget?.data?.office_budget
              }
            />
          </>
        ) : (
          ""
        )}
        {types == "ApprovedBudgets" && (
          <ApprovedBudget data={approvedBudgets} />
        )}
        {types == "allApprovedBudget" && (
          <ApprovedMonthlyBudgets data={approvedMonthlyBudgets?.data} />
        )}
      </div>
    </ForexSideBar>
  );
}
