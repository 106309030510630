import * as React from "react";
import NavBar from "../../../Layout/NavBar";
import LogisticsBreadCramp from "../../../components/Logistics/LogisticsRmaBreadCramp";
import DeliveredItemDetailsHeading from "../../../components/Logistics/DeliveredItem/DeliveredItemDetailsHeading";
import DeliveredItemBatchDetail from "../../../components/Logistics/DeliveredItem/DeliveredItemBatchDetail";
import { useLocation, useParams } from "react-router-dom";
import SuccessMessage from "../../../components/Payment/SuccessMessage";
import { useGetDeliveredGroupByBatchIdMutation, useGetDeliveredItemListByGroupIdMutation, useGetUserInfoQuery, useRequestGrnForGroupItemsByGroupIdMutation } from "../../../features/SCMApi";
import AutoClosePopup from "../../GRN/SuccessMessage/AutoClosePopup ";


function DeliveryItemsInGroupId() {
    const [ getDeliveredItemListByGroupId ]=useGetDeliveredItemListByGroupIdMutation();
    const location = useLocation();
    const { state } = location;
    const {groupId}=useParams();
    const [requestGrnForGroupItemsByGroupId]=useRequestGrnForGroupItemsByGroupIdMutation();
    const [successMessage, setSuccessMessage] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [deliveredItemGroup,setDeliveredItemGroup]=React.useState([]);
    const [groupItems,setGroupItems]=React.useState([]);
    const { data: userinfo } = useGetUserInfoQuery();
    const userId=userinfo?.id;
    // successMessage &&
    //   setTimeout(() => {
    //     setSuccessMessage(false);
    //   }, 3000);
      const [isOpen, setIsOpen] = React.useState(false);
      const [type, setType] = React.useState('success');



      React.useEffect(()=>{
           const getGroup=()=>{
                   getDeliveredItemListByGroupId(groupId).unwrap()
                                                .then((response)=>{
                                                   
                                                   setGroupItems(response.data);
                                                })
                                                .catch((error)=>{
                                                   
                                                });
           }
           getGroup();
      },[]);

      const handleRequestGRN=()=>{
        
        const requestGrn=()=>{
          
                        requestGrnForGroupItemsByGroupId(groupId).unwrap()
                                    .then((response)=>{
                                      setIsOpen(true);
                                      setMessage(response?.message);
                                      setType("success");
                                      
                                    })
                                    .catch((error)=>{
                                      setIsOpen(true);
                                      setMessage(error?.data?.message);
                                      setType("danger");
                                        
                                    });
                                }
                requestGrn();
        }

  return ( <>
        <AutoClosePopup isOpen={isOpen} onClose={() => setIsOpen(false)} type={type} message={message} />
    <NavBar />
    <LogisticsBreadCramp />
    <div className="page">
      {successMessage === true ? (
        <>
          <SuccessMessage
            style={{
              marginTop: "10px",
              width: "100%",
              marginLeft: "0px",
              backgroundColor: "#52ffab96",
              mb: "-10px",
            }}
            Message={message}
          />
        </>
      ) : (
        <></>
      )}
     
     
            <>
              <DeliveredItemDetailsHeading data={groupItems} />
                        <div className="container mt-4" > 
                            <div className="row gap-3" style={{ maxHeight: '600px', overflowY: 'auto' }}>
                                {groupItems?.item?.map((item, index) => (
                                    <div key={index} className="col-md-3 bg-white rounded mb-4 p-10" style={{backgroundColor:'white'}}>
                                        <span> <strong>  item - {index+1}  </strong></span><p>{item?.item_description} <strong className="m-2" style={{display:'block'}}>Qty   :  {"    "+item?.delivered_qty}</strong></p>
                                         
                                    </div>
                                ))}
                             </div>
                                <button type="button" className="flex justify-content-center bg-success font-bold  p-2 col-md-2 fs-6 rounded mt-10 text-white " onClick={handleRequestGRN} style={{text:"white"}}>Request To GRN</button>
                            </div>
            </>
    </div>
  </>)
}

export default DeliveryItemsInGroupId