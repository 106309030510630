import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  useChangeClientMutation,
  useGetSettingClientQuery,
} from "../../features/SCMApi";
// import useGetSettingClientQuery from "./../../features/SCMApi";
export default function ChangeClientModal(props) {
  const { data: clients } = useGetSettingClientQuery();
  const [changeClient] = useChangeClientMutation();
  const [client_id, setClient] = useState(null);
  const { projectId } = useParams();

  function handleSubmit() {
    const changeClientFrom = {
      id: projectId,
      client_id: client_id ? client_id : props?.previousData?.id,
    };

    changeClient(changeClientFrom)
      .unwrap()
      .then((response) => {

        handleCancel();
      })
      .then((error) => {

      });
  }
  function handleCancel() {
    props?.ToggleModal(false);
  }
  return (
    <>
      <div
        id="Modal"
        className="edit-mini-modal"
        onClick={(e) => {
          props?.ToggleModal(false);
        }}
      >
        <div
          className="edit-mini-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="edit-dialog-info">Change client</div>
          <div className="edit-modal-divider"></div>
          <div className="row gap-3 centered-content-column">
            <div className="col-12  ">
              <div className="">
                <div className="edit-input-label mb-1">Clients</div>
                <select
                  name=""
                  id=""
                  className="file-chooser"
                  onClick={(e) => {
                    setClient(e.target.value);
                  }}
                >
                  <option value="" disabled selected hidden>
                    {props?.previousData?.client_name}
                  </option>
                  {clients?.data?.map((item) => (
                    <option value={item?.id}>{item.client_name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="confirm-dialog-controller">
              <button
                className="form-cancel"
                onClick={(e) => {
                  props?.ToggleModal(false);
                }}
              >
                Cancel
              </button>
              <button
                className="form-save"
                type={"submit"}
                onClick={(e) => {
                  handleSubmit();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
