import React from "react";
import IconButton from "@mui/material/IconButton";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import EditBankModal from "../Modals/EditBankModal";
const EditBankButton = (props) => {
  //Update Bank toggle
  const [showUpdateBankModal, toggleUpdateBankModal] = React.useState(false);
  const toggleUpdateBankHandler = (e) => {
    toggleUpdateBankModal(!showUpdateBankModal);
  };

  return (
    <>
      <IconButton
        variant="text"
        size="small"
        sx={{
          backgroundColor: "#064b4f",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#186569",
            color: "#fff",
          },
          marginTop: "-25px",
          marginRight: "15px",
        }}
        onClick={(e) => toggleUpdateBankHandler(e, props?.name)}
      >
        <ModeEditOutlinedIcon />
      </IconButton>

      <EditBankModal
        show={showUpdateBankModal}
        handleUpdateBankModal={toggleUpdateBankHandler}
        successMessage={props?.setSuccessMessage}
        message={props?.setMessage}
        passedValue={props?.passedValue}
      />
    </>
  );
};

export default EditBankButton;
