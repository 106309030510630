import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {
  useGetBankBalanceQuery,
  useGetBankListForGapAnalysisQuery,
  useGetForexNeedsQuery,
  useGetForexReceivableQuery,
  useGetGapAnalysisForBankQuery,
} from "../../features/SCMApi";
import ChartDataLabels from "chartjs-plugin-datalabels";
import NoRecord from "../common/NoRecord";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

export const options = {
  indexAxis: "y",
  responsive: true,

  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      color: "#FFF",
      align: "center",
      display: false,
    },
  },
  options: {
    plugins: {
      legend: {
        position: "right",
        labels: {
          usePointStyle: true,
        },
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
    },
  },
};

export default function ForexDasboard(props) {
  const { data: bankBalance } = useGetBankBalanceQuery();
  const { data: receivableData } = useGetForexReceivableQuery();
  const { data: forexNeedData } = useGetForexNeedsQuery();
  const balanceGrandTotal = [];
  for (const total in bankBalance?.data) {
    balanceGrandTotal.push(Number(bankBalance?.data[total]?.amount));
  }
  let balanceGrandTotals =
    Math.round(
      (balanceGrandTotal
        .filter((e) => typeof e == "number")
        .reduce((prev, acc) => prev + acc, 0) +
        Number.EPSILON) *
        100
    ) / 100;

  const receivableGrandTotal = [];
  for (const total in receivableData?.data) {
    receivableGrandTotal.push(Number(receivableData?.data[total]?.amount));
  }

  let receivableGrandTotals =
    Math.round(
      (receivableGrandTotal
        .filter((e) => typeof e == "number")
        .reduce((prev, acc) => prev + acc, 0) +
        Number.EPSILON) *
        100
    ) / 100;

  const forexNeedGrandTotal = [];
  for (const total in forexNeedData?.data?.forex_need) {
    forexNeedGrandTotal.push(
      Number(forexNeedData?.data?.forex_need[total]?.amount)
    );
  }

  let forexNeedGrandTotals =
    Math.round(
      (forexNeedGrandTotal
        .filter((e) => typeof e == "number")
        .reduce((prev, acc) => prev + acc, 0) +
        Number.EPSILON) *
        100
    ) / 100;
  const { data: bankList } = useGetBankListForGapAnalysisQuery();
  const [selectedBank, setSelectedBank] = React.useState(null);
  const filteredBankList = bankList?.data?.map((bank) => bank?.bank_name);
  const bankId =
    selectedBank === null
      ? bankList?.data[0]?.id
      : bankList?.data !== []
      ? bankList?.data
          ?.filter((bank) => bank?.bank_name === selectedBank)
          ?.map((bank) => bank?.id)
          .flat()
          .toString()
      : "0";
  const { data: gapAnalysisForBank } = useGetGapAnalysisForBankQuery(bankId);
  const defaultBank = bankList?.data
    .filter((bank) => bank?.bank_name === bankList?.data[0]?.bank_name)
    ?.map((bank) => bank?.bank_name)
    .toString();

  const datas = [];
  for (let forexNeed in gapAnalysisForBank?.data?.forexNeed) {
    datas.push({
      ...gapAnalysisForBank?.data?.forexNeed[forexNeed],
      balance:
        gapAnalysisForBank?.data?.forexBalance !== null
          ? gapAnalysisForBank?.data?.forexBalance?.amount
          : 0,
      receivable:
        gapAnalysisForBank?.data?.receivableAmount !== null
          ? gapAnalysisForBank?.data?.receivableAmount
          : 0,
    });
  }

  const totalReceivable =
    gapAnalysisForBank?.data?.receivableAmount !== null
      ? gapAnalysisForBank?.data?.receivableAmount
      : 0;
  const totalBalance =
    gapAnalysisForBank?.data?.forexBalance !== null
      ? gapAnalysisForBank?.data?.forexBalance?.amount
      : 0;
  const totalNeed =
    Math.round(
      (datas?.reduce((total, item) => (total += Number(item.amount) || 0), 0) ||
        0 + Number.EPSILON) * 1000
    ) / 1000;
  const totalGap =
    Math.round(
      (totalBalance + totalReceivable - totalNeed + Number.EPSILON) * 1000
    ) / 1000;
  const labels = [
    ["Total Forex Need", forexNeedGrandTotals],
    ["Total Receivables", receivableGrandTotals],
    ["Total Balance", balanceGrandTotals],
    ["Total Need", totalNeed],
    ["Gap", totalGap],
  ];
  const Forcolor = [
    forexNeedGrandTotals,
    receivableGrandTotals,
    balanceGrandTotals,
    totalNeed,
    totalGap,
  ];
  const colours = Forcolor.map((value) => (value < 0 ? "#FF5252" : "#064b4f"));
  const data = {
    labels,
    datasets: [
      {
        data: [
          forexNeedGrandTotals,
          receivableGrandTotals,
          balanceGrandTotals,
          totalNeed,
          totalGap,
        ],
        backgroundColor: colours,
      },
    ],
  };
  return (
    <div
      className={props?.Notask ? "dashboard-card" : "no-task-dashboard-card"}
    >
      <div className="dashboard-title ps-3 pt-2">Forex</div>

      <div className="divider"></div>
      {data?.datasets[0]?.data.length != 0 ? (
        <Bar
          className={props?.Notask ? "" : "forex-bar"}
          options={options}
          data={data}
        />
      ) : (
        <NoRecord />
      )}
    </div>
  );
}
