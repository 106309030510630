import { useState, useEffect } from "react";

// Import Worker
import { Worker } from "@react-pdf-viewer/core";
// Import the main Viewer component
import { Viewer } from "@react-pdf-viewer/core";
// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
// default layout plugin
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
// Import styles of default layout plugin
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import FileChooser from "../../components/common/FileChooser#";
import CheckList from "./CheckList";
import Comparison from "./Comparison";
import Index from "./Index/Index";
import { useParams } from "react-router-dom";
import CiTemplate from "./../../assets/Template/comercialInvoicetemplate.docx";
import PiTemplate from "./../../assets/Template/pltemplate.docx";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
import {
  useGetAirPortsQuery,
  useGetCiQuery,
  useGetPlQuery,
  useGetDischargesQuery,
  useGetItemQuery,
  useGetOriginsQuery,
  useGetSeaPortsQuery,
  useGetShipmentModesQuery,
  useGetConsigneeInformationQuery,
  useGetShipmentInformationQuery,
  useGetMandatoryDocumentsQuery,
  useGetTermsQuery,
  useGetAirLoadingPortsQuery,
  useGetSeaLoadingPortsQuery,
  useGetShipmentModesAddQuery,
  useGetSupplierOriginsQuery,
  useUpdateConsigneeInformationMutation,
  useGetReceivableBanksQuery,
  useUpdateMandatoryDocumentMutation,
  useUpdateTermsMutation,
  useGetShipmentDestinationsQuery,
  useUpdateShipmentInformationMutation,

  //Approval
  useGetApproverQuery,
  useSendForApproverMutation,
  useApprovalApproveMutation,
  useApprovalRejectMutation,
  useApprovalRetractMutation,
  useGetUserInfoQuery,
  useGetBatchDetailQuery,
  useGetProjectQuery,
  useGetProjectRolePermissionQuery,
} from "../../features/SCMApi";
import { API_BASE_URL } from "../../api/endPoint";
import ComparisonDropDown from "./ComparisonDropDown";
import NavBar from "../../Layout/NavBar";
import axios from "axios";
// import pdfChecking from "./../../assets/Template/ResponsiveGuidelines.pdf";
import { object } from "yup/lib/locale";
import CheckProjectPermission from "../../components/common/checkPermission/CheckProjectPermission";

//Imports for approval
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Alert, AlertTitle, Tooltip } from "@mui/material";
import ModalSuccess from "../../components/common/ModalSuccess";

function CiComparison() {
  const { batchId } = useParams();


  //Approval detail implementation
  const { data: batchDetail } = useGetBatchDetailQuery(batchId);
  const { data: loggedUser } = useGetUserInfoQuery();
  const { data: projectmembers, isSuccessfull } = useGetProjectQuery(
    batchDetail?.data[0]?.project_id
  );
  const { data: roleRoles } = useGetProjectRolePermissionQuery(
    batchDetail?.data[0]?.project_id
  );
  const { data: getApprover } = useGetApproverQuery({
    module_name: "Document",
    id: batchId,
  });
  const [Success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sendForApprover] = useSendForApproverMutation();
  const [approvalApprove] = useApprovalApproveMutation();
  const [approvalReject] = useApprovalRejectMutation();
  const [approvalRetract] = useApprovalRetractMutation();

  const [show, setShow] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [retractConfirmation, setRetractConfirmation] = useState(false);
  const [retractLastApprover, setRetractLastApprover] = useState(false);
  function confirmationHandler() {
    setConfirmation(true);
  }
  function retractConfirmationHandler() {
    setRetractConfirmation(true);
  }

  Success &&
    setTimeout(() => {
      setSuccess(false);
    }, 10000);
  let currentApproverUser =
    loggedUser &&
    getApprover &&
    getApprover?.data?.current.find((user) => user?.user_id === loggedUser?.id);

  let isDocumentApproved =
    batchDetail?.data[0] && batchDetail?.data[0]?.document_approval == 1
      ? true
      : batchDetail?.data[0]?.document_approval == 0
        ? false
        : batchDetail?.data[0]?.document_approval == 2
          ? null
          : "";

  const normalApprover =
    getApprover &&
    getApprover?.data?.current.find(
      (user) => user?.user_id === loggedUser?.id
    ) !== undefined
      ? false
      : true;
  
  const lastApprover =
    getApprover &&
    getApprover?.data?.current.find(
      (user) => user?.level === user?.max_approval_count
    )
      ? getApprover?.data?.current.find(
          (user) => user?.level === user?.max_approval_count
        )
      : false;
  const feedBackTimeStamps =
    getApprover &&
    getApprover?.data?.history
      ?.filter((user) => user?.feedback !== null)
      ?.map((filterdFeedback) =>
        new Date(filterdFeedback?.approved_at)?.valueOf()
      );
  const latestFeedbackTimeInMilisecond =
    feedBackTimeStamps && Math.max(...feedBackTimeStamps);
  const latestFeedbackTime = new Date(latestFeedbackTimeInMilisecond)
    ?.toLocaleString("sv")
    ?.replace(",", "");

  let feedBack =
    getApprover &&
    getApprover?.data?.history.find((user) => user?.feedback)?.feedback
      ? latestFeedbackTime &&
        getApprover?.data?.history?.filter(
          (user) => user?.approved_at == latestFeedbackTime
        )[0]
      : false;
  let documentApprovalStatus = {
    level:
      getApprover &&
      getApprover?.data?.current.find(
        (documentDocument) => documentDocument?.level
      )?.level,
    finalApproved:
      isDocumentApproved == 1 ? true : isDocumentApproved == 0 ? false : null,
    roleName:
      getApprover &&
      getApprover?.data?.current.find(
        (documentDocument) => documentDocument?.level
      )?.role_name,
    feedback: feedBack,
  };

  let isApprover = currentApproverUser !== undefined ? true : false;
  const [feedback, setFeedBack] = useState(null);

  let dataForApproval = {
    module_name: "Document",
    module_id: batchId,
    user_id: loggedUser?.id,
    feedback,
  };

  const approvedId = {
    id: batchId,
  };

  //Retract approval functionality
  let currentLevel = getApprover?.data?.current[0]?.level;

  const sendForApproval =
    getApprover &&
    batchDetail &&
    batchDetail?.data[0]?.approval_stage_id !== null &&
    getApprover?.data?.current?.length !== 0
      ? true
      : getApprover &&
          getApprover?.data?.current?.length === 0 &&
          batchDetail &&
          batchDetail?.data[0]?.approval_stage_id === null
        ? false
        : null;
  let finalApproved =
    isDocumentApproved == 1 ? true : isDocumentApproved == 0 ? false : null;
  let approvedHistoryForCurrentApprover = getApprover?.data?.history?.find(
    (user) => user?.approval_type == 0 && user?.user_id == loggedUser?.id
  );

  let canRetract =
    approvedHistoryForCurrentApprover?.level + 1 === currentLevel
      ? true
      : false;

  const handelSendForApproval = () => {
    sendForApprover(approvedId)
      .unwrap()
      .then((response) => {

      })
      .then((error) => {

      });
  };

  const handleApprovals = () => {
    approvalApprove(dataForApproval)
      .unwrap()
      .then((response) => {

        setConfirmation(false);
        setRetractLastApprover(true);
      })
      .then((error) => {

      });
  };

  const handleDecline = () => {
    approvalReject(dataForApproval)
      .unwrap()
      .then((response) => {
        setConfirmation(false);
        setShow(false);
      })
      .then((error) => {

      });
  };
  const handleRetract = () => {
    approvalRetract(dataForApproval)
      .unwrap()
      .then((response) => {

        // props.setMessages("Approved Successfully");
        setRetractConfirmation(false);
      })
      .then((error) => {

      });
  };


  //Approval logic end

  // const [template, setTemplate] = useState();
  const { data: ciDoc } = useGetCiQuery(batchId);
  const { data: plDoc } = useGetPlQuery(batchId);

  const { data: terms } = useGetTermsQuery(batchId);
  const { data: banks } = useGetReceivableBanksQuery();
  const { data: mandatory_docs, isSuccess } =
    useGetMandatoryDocumentsQuery(batchId);
  const { data: consigneeInformation } =
    useGetConsigneeInformationQuery(batchId);
  const { data: shipmentInformation } = useGetShipmentInformationQuery(batchId);
  const [updateConsigneeInformation] = useUpdateConsigneeInformationMutation();
  const [updateMandatoryDoc] = useUpdateMandatoryDocumentMutation();
  const [updateTerms] = useUpdateTermsMutation();
  const [updateShipment] = useUpdateShipmentInformationMutation();
  
  // # mandatory document's state
  const [loading_bill_copy, setBillLoadingCopy] = useState(null);
  const [setOfAvailablePort, setAvailablePort] = useState(null);
  const [loading_bill_original, setBillLoadingOriginal] = useState(null);
  const [commercial_invoice_copy, setCommercialInvoiceCopy] = useState(null);
  const [commercial_invoice_original, setCommercialInvoiceOriginal] =
    useState(null);
  const [packing_list_copy, setPackingListCopy] = useState(null);
  const [packing_list_original, setPackingListOriginal] = useState(null);
  const [origin_certificate_copy, setOriginCertificateCopy] = useState(null);
  const [origin_certificate_original, setOriginCertificateOriginal] =
    useState(null);
  // # bank information's state
  const [issue_bank_lc, setIssuingBankLC] = useState(null);
  const [issue_bank_tf, setIssuingBankTf] = useState(null);
  const [permit_number, setPermitNumber] = useState(null);
  const [bank_id, setBank] = useState(null);
  const [bankAddress, setBankAddress] = useState(null);
  // const [countryLoading, setLoadingCountry] = useState(
  //   terms?.data?.forex_sea_loading?.id || terms?.data?.forex_air_loading?.id
  // );
  // # terms and condition's state
  const [opening_date, setOpeningDate] = useState(null);
  const [opening_to, setOpeningTo] = useState(null);
  const [freight, setFreight] = useState(null);
  const [incoterm_id, setIncoTerm] = useState(null);
  const [payment_mode_id, setPaymentMode] = useState(null);
  const [shipment_mode_id, setShipmentMode] = useState(null);
  const [trans_shipment, setTransShipment] = useState(null);
  const [item_description, setItemDescription] = useState(null);
  const [partial_shipment, setPartialShipment] = useState(null);
  const [loading_origin_id, setLoadingCountry] = useState(null);
  const [air_loading_id, setAirLoadingId] = useState(null);
  const [sea_loading_id, setSeaLoadingId] = useState(null);
  const [discharge_port_id, setDischargePortId] = useState(null);

  // # shipment information's state
  // 'ship_to'=> $validator['ship_to'],
  // 'ship_address'=> $validator['ship_address'],
  // 'attorney_name'=> $validator['attorney_name'],
  // 'attorney_email'=> $validator['attorney_email'],
  // 'attorney_phone'=> $validator['attorney_phone'],
  const [ship_address, setShipAddress] = useState(null);
  const [ship_to, setShipTo] = useState(null);
  const [attorney_name, setAttorneyName] = useState(null);
  const [attorney_email, setAttorneyEmail] = useState(null);
  const [attorney_phone, setAttorneyPhone] = useState(null);
  // const []
  const items_ordered = ciDoc?.data?.details[0]?.items
    ? JSON.parse(ciDoc?.data?.details[0]?.items)
    : null;
  items_ordered?.map((items, index) => (items.table_index = index + 1));

  let items = [];
  items_ordered?.map((item) =>
    items?.push({
      ...item,
      item_part_number:
        item?.item_part_number == null ? "-" : item?.item_part_number,
      unit_price: Number(item?.unit_price)?.toLocaleString(),
      total_price: Number(item?.total_price)?.toLocaleString(),
      qty: Number(item?.qty)?.toLocaleString(),
      country_of_origin: item?.country_of_origin
        ? item?.country_of_origin
        : plDoc?.data?.details[0]?.Origin,
    })
  );
 

  let total_price = 0;
  items_ordered?.map((item) => (total_price = total_price + item?.total_price));
  let freightCost = 0;
  const freights = ciDoc?.data?.details[0]?.freight
    ? ciDoc?.data?.details[0]?.freight
    : null;
  freightCost = (Number(freights) + Number(total_price)).toLocaleString();

  // ciDoc?.data?.details[0]?.map(
  //   (item) => (freightCost = item?.freightCost + total_price)
  // );

  const CiData = {
    port: ciDoc?.data?.port,
    ...ciDoc?.data?.details[0],
    items,
    freight: freights,
    freightCost,
    total_price,
  };


  const BankList = [];
  banks?.data?.map((item, index) =>
    BankList.push({ value: item?.id, label: item?.bank_name })
  );
  

  const plItems = ciDoc?.data?.details[0]?.items
    ? JSON.parse(ciDoc?.data?.details[0]?.items)
    : null;

  if (plItems) {
    plItems.forEach((item, index) => {
      item.table_index = index + 1;
      item.country_of_origin = item?.country_of_origin
        ? item?.country_of_origin
        : plDoc?.data?.details[0]?.Origin;
    });
  }

  const PlData = {
    port: ciDoc?.data?.port,
    ...plDoc?.data?.details[0],
    plItems,
  };
  
  function loadFile(url, callback) {
    PizZipUtils.getBinaryContent(url, callback);
  }
  const generateDocument = (template) => {
    loadFile(
      template === "CiTemplate" ? CiTemplate : PiTemplate,
      function (error, content) {
        if (error) {
          throw error;
        }
        var zip = new PizZip(content);
        var doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
        });
        doc.setData(template === "CiTemplate" ? CiData : PlData);
        try {
          // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
          doc.render();
        } catch (error) {
          // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
          function replaceErrors(key, value) {
            if (value instanceof Error) {
              return Object.getOwnPropertyNames(value).reduce(function (
                error,
                key
              ) {
                error[key] = value[key];
                return error;
              }, {});
            }
            return value;
          }
          

          if (error.properties && error.properties.errors instanceof Array) {
            const errorMessages = error.properties.errors
              .map(function (error) {
                return error.properties.explanation;
              })
              .join("\n");
            
            // errorMessages is a humanly readable message looking like this :
            // 'The tag beginning with "foobar" is unopened'
          }
          throw error;
        }
        var out = doc.getZip().generate({
          type: "blob",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        }); //Output the document using Data-URI
        saveAs(
          out,
          template == "CiTemplate"
            ? "Commercial Invoice.docx"
            : "Packing List.docx"
        );
      }
    );
  };


  // creating new plugin instance
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  // pdf file onChange state
  const [pdfFile, setPdfFile] = useState(null);
  useEffect(() => {
    
    axios
      .get(
        `${API_BASE_URL}/scm/documents/forex-permit-document/${CiData?.forex_id}`
      )
      .then((response) => {

      })
      .then((error) => {
        
      });
  
    // const element = document.getElementById("file-Chooser");
    // element.value = checking;
  }, []);
  // pdf file error state
  const [pdfError, setPdfError] = useState("");

  // handle file onChange event
  const allowedFiles = ["application/pdf"];
  const handleFile = (e) => {
    let selectedFile = e.target.files[0];

    if (selectedFile) {
      if (selectedFile && allowedFiles.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onloadend = (e) => {
          setPdfError("");
          setPdfFile(e.target.result);
        };
      } else {
        setPdfError("Not a valid pdf: Please select only PDF");
        setPdfFile("");
      }
    } else {

    }
  };
  const portLoading = [];

  function setPort() {

    checkShipment() == "Sea" ? (
      portLoading.push(
        ...seaPorts?.data?.filter(
          (items) =>
            items?.origin_id ==
            (loading_origin_id
              ? loading_origin_id
              : terms?.data?.loading_origin_id)
        )
      )
    ) : checkShipment() == "Air" ? (
      portLoading.push(
        ...airPorts?.data?.filter(
          (items) =>
            items?.origin_id ==
            (loading_origin_id
              ? loading_origin_id
              : terms?.data?.loading_origin_id)
        )
      )
    ) : (
      <></>
    );

    return portLoading;
  }
  // const [selectedShipmentModes, setSelectedShipmentModes] = useState();

  // useEffect(() => {
  //   setTimeout(
  //     setPort()?.map((items) =>
  //       listOfLoadingPorts.push({ value: items?.id, label: items?.port_name })
  //     ),
  //     4000
  //   );
  // }, [
  //   shipment_mode_id ? shipment_mode_id : terms?.data?.forex_shipment_mode?.id,
  // ]);
  function copyText(value) {
    /* Get the text field */

    /* Select the text field */
    // search.select();
    // search.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(value);

    /* Alert the copied text */
    // alert("Copied the text: " + value);
  }
  const { CiId } = useParams();

  const { data: ci, isFetching, isLoading } = useGetItemQuery(CiId);
  // const { data: origins } = useGetOriginsQuery();
  const { data: origins } = useGetSupplierOriginsQuery();

  // const { data: airPorts } = useGetAirPortsQuery();
  // const { data: seaPorts } = useGetSeaPortsQuery();
  const { data: airPorts, isSuccess: is_success_air } =
    useGetAirLoadingPortsQuery();
  const { data: seaPorts, isSuccess: is_success } =
    useGetSeaLoadingPortsQuery();

  const { data: airDischarger } = useGetShipmentDestinationsQuery();
  const { data: shipmentMode } = useGetShipmentModesAddQuery();

  // const { data: shipmentMode } = useGetShipmentModesQuery();
  const freighList = [
    { value: "prepaid", label: "PrePaid" },
    { value: "FOB", label: "Freight on Board" },
  ];
  const StaticList = [
    { value: "Allowed", label: "Allowed" },
    { value: "Not Allowed", label: "Not Allowed" },
  ];
  const incoTermList = [
    { value: "FCA", label: "Free Carrier" },
    { value: "FAS", label: "Free Alongside Ship " },
    { value: "FOB", label: "Free on Board" },
    { value: "CPT", label: "Carriage paid to" },
    { value: "CIP", label: "Carriage and Insurance paid to" },
    { value: "CFR", label: "Cost and Freight" },
    { value: "CIF", label: "Cost, Insurance and Freight" },
    { value: "DPU", label: "Delivered at Place Unloaded" },
    { value: "DDP", label: "Delivered Duty Paid" },
  ];
  const LCtype = [
    { value: "commercial", label: "commercial LC" },
    { value: "standby", label: "standby LC" },
    { value: "revocable", label: "revocable LC" },
    { value: "irrevocable", label: "irrevocable LC" },
    { value: "revolving", label: "revolving LC" },
    { value: "red clause", label: "red clause LC" },
  ];

  if (isLoading) return <div className="page">Loading...</div>;
  const dischargeList = airDischarger?.data.map((item) => ({
    value: item?.id,
    label: item?.port_name,
  }));
  const shipmentModeList = shipmentMode?.data?.map((items) => ({
    value: items?.id,
    label: items?.shipment_mode,
  }));
  const countryList = origins?.data?.map((items) => ({
    value: items?.id,
    label: items?.name,
  }));
  const seaProtList = seaPorts?.data?.map((items) => ({
    value: items?.id,
    label: items?.port_name,
  }));
  const airProtList = airPorts?.data?.map((items) => ({
    value: items?.id,
    label: items?.port_name,
  }));
  const listOfLoadingPorts = [];
  if (
    shipment_mode_id == undefined &&
    isSuccess &&
    is_success &&
    is_success_air
  ) {
    // setShipmentMode(terms?.data?.shipment_mode_id);

    
  }

  
  function handleConsigneeUpdate() {
    // const lc = [issue_bank_lc];
    // const tf = [issue_bank_tf];
    // const pr = [permit_number];
    setLoading(true);

    const consigneeForm = {
      issue_bank_lc: issue_bank_lc
        ? issue_bank_lc
        : consigneeInformation?.data?.issue_bank_lc,
      issue_bank_tf: issue_bank_tf
        ? issue_bank_tf
        : consigneeInformation?.data?.issue_bank_tf,
      permit_number: permit_number
        ? permit_number
        : consigneeInformation?.data?.permit_number,
      bank_id: bank_id ? bank_id : consigneeInformation?.data?.bank?.id,
      batch_id: batchId,
      bankAddress: bankAddress
        ? bankAddress
        : consigneeInformation?.data?.bank?.bank_address[0]?.address,
    };

    // // axios({
    // //   method: "POST",
    // //   url: `${API_BASE_URL}/scm/logistics/document-preparation/consignee/${consigneeForm?.batchId}`,
    // //   data: consigneeForm,
    // //   headers: { "Content-Type": "application/json" },
    // // })
    // //   .then(function (response) {
    // //     //handle success

    // //   })
    // //   .catch(function (response) {
    // //     //handle error
  
    // //   });

    updateConsigneeInformation(consigneeForm)
      .unwrap()
      .then((response) => {

        setSuccess(true);
        setLoading(false);
      })
      .then((error) => {

      });

  }
  function updateMandatoryDocument(e) {
    e.preventDefault();
    setLoading(true);
    const mandatoryForm = {
      batch_id: batchId,
      loading_bill_copy: loading_bill_copy
        ? loading_bill_copy
        : mandatory_docs?.data?.loading_bill_copy,
      loading_bill_original: loading_bill_original
        ? loading_bill_original
        : mandatory_docs?.data?.loading_bill_original,
      commercial_invoice_copy: commercial_invoice_copy
        ? commercial_invoice_copy
        : mandatory_docs?.data?.commercial_invoice_copy,
      commercial_invoice_original: commercial_invoice_original
        ? commercial_invoice_original
        : mandatory_docs?.data?.commercial_invoice_original,
      packing_list_copy: packing_list_copy
        ? packing_list_copy
        : mandatory_docs?.data?.packing_list_copy,
      packing_list_original: packing_list_original
        ? packing_list_original
        : mandatory_docs?.data?.packing_list_original,
      origin_certificate_copy: origin_certificate_copy
        ? origin_certificate_copy
        : mandatory_docs?.data?.origin_certificate_copy,
      origin_certificate_original: origin_certificate_original
        ? origin_certificate_original
        : mandatory_docs?.data?.origin_certificate_original,
    };
    
    // axios({
    //   method: "POST",
    //   url: `${API_BASE_URL}/scm/logistics/document-preparation/mandatory-document/${batchId}`,
    //   data: mandatoryForm,
    //   // headers: { "Content-Type": "application/json" },
    // })
    //   .then(function (response) {
    //     //handle success

    //   })
    //   .catch(function (response) {
    //     //handle error

    //   });
    updateMandatoryDoc(mandatoryForm)
      .unwrap()
      .then((response) => {

        setSuccess(true);
        setLoading(false);
      })
      .then((error) => {

      });

  }
  function updateTermsAndConditions() {
    setLoading(true);
    let termsFormAir = {
      batch_id: batchId,
      opening_date: opening_date ? opening_to : terms?.data?.opening_date,
      opening_to: opening_to ? opening_to : terms?.data?.opening_to,
      freight: freight ? freight : terms?.data?.frieght,
      incoterm_id: incoterm_id ? incoterm_id : terms?.data?.incoterm_id,
      payment_mode_id: payment_mode_id
        ? payment_mode_id
        : terms?.data?.payment_mode_id,
      shipment_mode_id: shipment_mode_id
        ? shipment_mode_id
        : terms?.data?.shipment_mode_id,
      trans_shipment: trans_shipment
        ? trans_shipment
        : terms?.data?.trans_shipment,
      item_description: item_description
        ? item_description
        : terms?.data?.item_description,
      partial_shipment: partial_shipment
        ? partial_shipment
        : terms?.data?.partial_shipment,
      loading_origin_id: loading_origin_id
        ? loading_origin_id
        : terms?.data?.loading_origin_id,
      air_loading_id,
      sea_loading_id,
      discharge_port_id: discharge_port_id
        ? discharge_port_id
        : terms?.data?.discharge_port_id,
      freight: freight ? freight : terms?.data?.freight,
    };
    let termsFormSea = {
      batch_id: batchId,
      opening_date: opening_date ? opening_to : terms?.data?.opening_date,
      opening_to: opening_to ? opening_to : terms?.data?.opening_to,
      freight: freight ? freight : terms?.data?.frieght,
      incoterm_id: incoterm_id ? incoterm_id : terms?.data?.incoterm_id,
      payment_mode_id: payment_mode_id
        ? payment_mode_id
        : terms?.data?.payment_mode_id,
      shipment_mode_id: shipment_mode_id
        ? shipment_mode_id
        : terms?.data?.shipment_mode_id,
      trans_shipment: trans_shipment
        ? trans_shipment
        : terms?.data?.trans_shipment,
      item_description: item_description
        ? item_description
        : terms?.data?.item_description,
      partial_shipment: partial_shipment
        ? partial_shipment
        : terms?.data?.partial_shipment,
      loading_origin_id: loading_origin_id
        ? loading_origin_id
        : terms?.data?.loading_origin_id,
      sea_loading_id,
      discharge_port_id: discharge_port_id
        ? discharge_port_id
        : terms?.data?.discharge_port_id,
      freight: freight ? freight : terms?.data?.freight,
    };

    updateTerms(checkShipment() == "Air" ? termsFormAir : termsFormSea)
      .unwrap()
      .then((response) => {

        setLoading(false);
        setSuccess(true);
      })
      .then((error) => {

      });

    // axios({
    //   method: "POST",
    //   url: `${API_BASE_URL}/scm/logistics/document-preparation/terms/${batchId}`,
    //   data: termsForm,
    //   // headers: { "Content-Type": "application/json" },
    // })
    //   .then(function (response) {
    //     //handle success

    //   })
    //   .catch(function (response) {
    //     //handle error

    //   });

  }
  function updateShipmentInformation() {

    setLoading(true);
    const shipmentForm = {
      batch_id: batchId,
      ship_address: ship_address
        ? ship_address
        : shipmentInformation?.data?.ship_address,
      ship_to: ship_to ? ship_to : shipmentInformation?.data?.ship_to,
      attorney_name: attorney_name
        ? attorney_name
        : shipmentInformation?.data?.attorney_name,
      attorney_email: attorney_email
        ? attorney_email
        : shipmentInformation?.data?.attorney_email,
      attorney_phone: attorney_phone
        ? attorney_phone
        : shipmentInformation?.data?.attorney_phone,
    };

    updateShipment(shipmentForm)
      .unwrap()
      .then((response) => {

        setSuccess(true);
        setLoading(false);
      })
      .then((error) => {

      });
    // axios({
    //   method: "POST",
    //   url: `${API_BASE_URL}/scm/logistics/document-preparation/shipment-owner/${batchId}`,
    //   data: shipmentForm,
    //   // headers: { "Content-Type": "application/json" },
    // })
    //   .then(function (response) {
    //     //handle success
  
    //   })
    //   .catch(function (response) {
    //     //handle error

    //   });


  }
  function checkShipment() {
    const selectedShipmentModes = shipment_mode_id
      ? shipment_mode_id
      : terms?.data?.forex_shipment_mode?.id;

    const condition = shipmentMode?.data?.filter(
      (modes) => selectedShipmentModes == modes?.id
    );
    const response = condition?.map((mode) => mode?.shipment_mode);


    return response;
  }
  function reArrangeDat(data) {
    const ports = [];
    Object.keys(data)?.map((items) =>
      data[parseInt(items)]?.map((item) => ports.push(item))
    );


    const portList = [];
    ports?.map((items) =>
      portList.push({ value: items?.id, label: items?.port_name })
    );
    // ports.map((item)=>ports.map((item)=>{ value: "FCA", label: "Free Carrier" },)
    return portList;
  }

  return (
    <Index
      title={`Document Preparation`}
      name={`batch Name`}
      navlink={`/logistics/batches/`}
    >
      <div className="document-preparation-approval-card-container">
        <>
          <div className="smManufacturer-card">
            <div className="Lc-card">
              <div className="lc-value">
                {getApprover &&
                getApprover?.data?.current?.length !== 0 &&
                isDocumentApproved !== true ? (
                  <span
                    style={{
                      color: "#fcce37",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {currentApproverUser !== undefined
                      ? "Waiting for your approval"
                      : `Level ${documentApprovalStatus?.level} (
                        
                          ${
                            documentApprovalStatus?.roleName?.length > 21
                              ? documentApprovalStatus?.roleName?.slice(0, 20) +
                                "..."
                              : documentApprovalStatus?.roleName
                          }
                          
                      ) approval pending`}

                    <MoreHorizIcon />
                  </span>
                ) : isDocumentApproved === true ? (
                  <span
                    style={{
                      color: "#064B4F",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Document Approved
                    <CheckCircleOutlineIcon />
                  </span>
                ) : getApprover &&
                  getApprover?.data?.current?.length === 0 &&
                  feedBack !== false ? (
                  <span
                    style={{
                      color: "#FF5252",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Document rejected
                  </span>
                ) : (
                  "Waiting to send for approver"
                )}
              </div>
              <div className="lc-label">Status</div>
            </div>
          </div>
        </>

        {getApprover &&
        getApprover?.data?.history?.length !== 0 &&
        isDocumentApproved === false &&
        sendForApproval === false &&
        feedBack !== false ? (
          <div className="smManufacturer-card">
            <div className="Lc-card">
              <div
                className="lc-label"
                style={{ fontSize: "14px" }}
              >{`${feedBack?.name} Declined the document with feedback ${feedBack?.feedback}`}</div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {sendForApproval === false ? (
        <div className="lc-warning ">
          {finalApproved === false ? (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="24" height="24" fill="white" fill-opacity="0.01" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8ZM12 10C12.5523 10 13 10.4477 13 11V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V11C11 10.4477 11.4477 10 12 10Z"
                fill="white"
              />
            </svg>
          ) : (
            <></>
          )}
          <div>
            {finalApproved === true
              ? "Document approved"
              : "Please make sure to add all valid inputs for document preparation"}
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="download-container mt-3">
        <button
          className="download-btn"
          onClick={(e) => {
            generateDocument("PiTemplate");
          }}
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 8.90625C5.925 8.90625 5.85625 8.89375 5.79375 8.86875C5.73125 8.84375 5.66875 8.8 5.60625 8.7375L2.775 5.90625C2.6625 5.79375 2.60938 5.65937 2.61563 5.50312C2.62188 5.34687 2.68125 5.2125 2.79375 5.1C2.90625 4.9875 3.04062 4.93125 3.19687 4.93125C3.35312 4.93125 3.4875 4.9875 3.6 5.1L5.4375 6.95625V0.5625C5.4375 0.4 5.49062 0.265625 5.59687 0.159375C5.70312 0.0531251 5.8375 0 6 0C6.1625 0 6.29688 0.0531251 6.40313 0.159375C6.50938 0.265625 6.5625 0.4 6.5625 0.5625V6.95625L8.41875 5.1C8.53125 4.9875 8.66562 4.93125 8.82187 4.93125C8.97812 4.93125 9.1125 4.9875 9.225 5.1C9.3375 5.2125 9.39375 5.34687 9.39375 5.50312C9.39375 5.65937 9.3375 5.79375 9.225 5.90625L6.39375 8.7375C6.33125 8.8 6.26875 8.84375 6.20625 8.86875C6.14375 8.89375 6.075 8.90625 6 8.90625ZM1.125 12C0.825 12 0.5625 11.8875 0.3375 11.6625C0.1125 11.4375 0 11.175 0 10.875V8.75625C0 8.59375 0.0531251 8.45937 0.159375 8.35312C0.265625 8.24687 0.4 8.19375 0.5625 8.19375C0.725 8.19375 0.859375 8.24687 0.965625 8.35312C1.07187 8.45937 1.125 8.59375 1.125 8.75625V10.875H10.875V8.75625C10.875 8.59375 10.9281 8.45937 11.0344 8.35312C11.1406 8.24687 11.275 8.19375 11.4375 8.19375C11.6 8.19375 11.7344 8.24687 11.8406 8.35312C11.9469 8.45937 12 8.59375 12 8.75625V10.875C12 11.175 11.8875 11.4375 11.6625 11.6625C11.4375 11.8875 11.175 12 10.875 12H1.125Z"
              fill="#064B4F"
            />
          </svg>
          Download PL
        </button>
        <button
          className="download-btn"
          onClick={(e) => {
            generateDocument("CiTemplate");
          }}
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 8.90625C5.925 8.90625 5.85625 8.89375 5.79375 8.86875C5.73125 8.84375 5.66875 8.8 5.60625 8.7375L2.775 5.90625C2.6625 5.79375 2.60938 5.65937 2.61563 5.50312C2.62188 5.34687 2.68125 5.2125 2.79375 5.1C2.90625 4.9875 3.04062 4.93125 3.19687 4.93125C3.35312 4.93125 3.4875 4.9875 3.6 5.1L5.4375 6.95625V0.5625C5.4375 0.4 5.49062 0.265625 5.59687 0.159375C5.70312 0.0531251 5.8375 0 6 0C6.1625 0 6.29688 0.0531251 6.40313 0.159375C6.50938 0.265625 6.5625 0.4 6.5625 0.5625V6.95625L8.41875 5.1C8.53125 4.9875 8.66562 4.93125 8.82187 4.93125C8.97812 4.93125 9.1125 4.9875 9.225 5.1C9.3375 5.2125 9.39375 5.34687 9.39375 5.50312C9.39375 5.65937 9.3375 5.79375 9.225 5.90625L6.39375 8.7375C6.33125 8.8 6.26875 8.84375 6.20625 8.86875C6.14375 8.89375 6.075 8.90625 6 8.90625ZM1.125 12C0.825 12 0.5625 11.8875 0.3375 11.6625C0.1125 11.4375 0 11.175 0 10.875V8.75625C0 8.59375 0.0531251 8.45937 0.159375 8.35312C0.265625 8.24687 0.4 8.19375 0.5625 8.19375C0.725 8.19375 0.859375 8.24687 0.965625 8.35312C1.07187 8.45937 1.125 8.59375 1.125 8.75625V10.875H10.875V8.75625C10.875 8.59375 10.9281 8.45937 11.0344 8.35312C11.1406 8.24687 11.275 8.19375 11.4375 8.19375C11.6 8.19375 11.7344 8.24687 11.8406 8.35312C11.9469 8.45937 12 8.59375 12 8.75625V10.875C12 11.175 11.8875 11.4375 11.6625 11.6625C11.4375 11.8875 11.175 12 10.875 12H1.125Z"
              fill="#064B4F"
            />
          </svg>
          Download CI
        </button>
      </div>

      <div className="comparison-container">
        <div className="ci-form-check">
          {CheckProjectPermission(
            "logistics_stages_access",
            projectmembers,
            loggedUser,
            roleRoles
          ) ? (
            <>
              <div className="bill-of-loading-section">
                <div className="bill-of-loading-section-label mt-3">
                  Bill of Loading / AWB
                </div>
                <FileChooser />
                <button className="form-save mt-3 mb-3">Save</button>
              </div>
              <div className="check-list-title my-2">Cover Letter, PL & CI</div>
              <form
                className="mandatory-documents"
                onSubmit={(e) => updateMandatoryDocument(e)}
              >
                <CheckList
                  copySetters={setCommercialInvoiceCopy}
                  originalSetters={setCommercialInvoiceOriginal}
                  Label={"Commercial Invoice"}
                  original={mandatory_docs?.data?.commercial_invoice_original?.toString()}
                  copy={mandatory_docs?.data?.commercial_invoice_copy?.toString()}
                />
                <CheckList
                  Label={"Packing List"}
                  copySetters={setPackingListCopy}
                  originalSetters={setPackingListOriginal}
                  original={mandatory_docs?.data?.packing_list_original?.toString()}
                  copy={mandatory_docs?.data?.packing_list_copy?.toString()}
                />
                <CheckList
                  copySetters={setOriginCertificateCopy}
                  originalSetters={setOriginCertificateOriginal}
                  Label={"Certificate Of Origin"}
                  original={mandatory_docs?.data?.origin_certificate_original?.toString()}
                  copy={mandatory_docs?.data?.origin_certificate_copy?.toString()}
                />
                <CheckList
                  copySetters={setBillLoadingCopy}
                  originalSetters={setBillLoadingOriginal}
                  Label={"Bill Of Loading"}
                  original={mandatory_docs?.data?.loading_bill_original?.toString()}
                  copy={mandatory_docs?.data?.loading_bill_copy?.toString()}
                />
                <div>
                  <button className="form-save mt-3 " type="submit">
                    {loading ? "Loading...." : "Save"}
                  </button>
                </div>
              </form>
              <div className=" full-divider"></div>
              <div className="terms-condition">
                <ComparisonDropDown
                  setters={setBank}
                  Label={"Consignee Bank"}
                  List={BankList}
                  id={"consignee-bank"}
                  text={consigneeInformation?.data?.bank?.bank_name}
                  toClipboard={copyText}
                />
                <Comparison
                  setters={setBankAddress}
                  Title={"Consignee Bank Address"}
                  id={"consignee-bank-Address"}
                  text={
                    consigneeInformation?.data?.bank?.bank_address[0]?.address
                  }
                  toClipboard={copyText}
                />
                <Comparison
                  setters={setIssuingBankTf}
                  Title={"Issuing Bank TF"}
                  id={"Issuing-Bank-TF"}
                  text={consigneeInformation?.data?.issue_bank_tf}
                  toClipboard={copyText}
                />
                <Comparison
                  setters={setIssuingBankLC}
                  Title={"Issuing Bank LC"}
                  id={"Issuing-Bank-LC"}
                  text={consigneeInformation?.data?.issue_bank_lc}
                  toClipboard={copyText}
                />
                <Comparison
                  setters={setPermitNumber}
                  Title={"Permit Number"}
                  id={"Permit-Number"}
                  text={consigneeInformation?.data?.permit_number}
                  toClipboard={copyText}
                />
                <div>
                  <button
                    className="form-save "
                    onClick={handleConsigneeUpdate}
                  >
                    {loading ? "Loading...." : "Save"}
                  </button>
                </div>

                <div className="ci-divider"></div>
                <Comparison
                  setters={setShipTo}
                  Title={"Ship to"}
                  id={"ship-to"}
                  text={shipmentInformation?.data?.ship_to}
                  toClipboard={copyText}
                />

                <Comparison
                  setters={setShipAddress}
                  Title={"Ship to Address"}
                  id={"Ship-to-Address"}
                  text={shipmentInformation?.data?.ship_address}
                  toClipboard={copyText}
                />
                <Comparison
                  setters={setAttorneyName}
                  Title={"Attorney Name"}
                  id={"Attorney-Name"}
                  text={shipmentInformation?.data?.attorney_name}
                  toClipboard={copyText}
                />
                <Comparison
                  setters={setAttorneyEmail}
                  Title={"Attorney Email"}
                  id={"Attorney-Email"}
                  text={shipmentInformation?.data?.attorney_email}
                  toClipboard={copyText}
                />
                <Comparison
                  setters={setAttorneyPhone}
                  Title={"Attorney Telephone"}
                  id={"Attorney-Telephone"}
                  text={shipmentInformation?.data?.attorney_phone}
                  toClipboard={copyText}
                />

                <div>
                  <button
                    className="form-save "
                    onClick={updateShipmentInformation}
                  >
                    {loading ? "Loading...." : "Save"}
                  </button>
                </div>
                <div className="ci-divider"></div>
                {/* <Comparison Title={"Type of LC"} /> */}
                <Comparison
                  setters={setItemDescription}
                  big={true}
                  Title={"Item Description"}
                  id={"item-disc"}
                  text={terms?.data?.item_description}
                  toClipboard={copyText}
                />
                <ComparisonDropDown
                  Label="Type of LC"
                  List={LCtype}
                  id={"Type-of-LC"}
                  text={terms?.data?.term?.lc_type}
                  toClipboard={copyText}
                />
                <Comparison
                  setters={setFreight}
                  Title={"Frieght Payment"}
                  id={"freight-payment"}
                  text={terms?.data?.freight}
                  toClipboard={copyText}
                />
                {/* 
            <Comparison
              Title={"Delivery Time"}
              id={"Delivery-Time"}
              text={ci?.data?.term?.time_of_arrival || "Not Specified"}
              toClipboard={copyText}
            /> */}
                <ComparisonDropDown
                  setters={setShipmentMode}
                  Label="Shipment Mode"
                  List={shipmentModeList}
                  id={"Shipment-Mode"}
                  text={terms?.data?.forex_shipment_mode?.shipment_mode}
                  toClipboard={copyText}
                />
                <ComparisonDropDown
                  setters={setTransShipment}
                  Label={"Trans shipment"}
                  List={StaticList}
                  id={"Trans-shipment"}
                  text={terms?.data?.trans_shipment}
                  toClipboard={copyText}
                />

                <ComparisonDropDown
                  setters={setPartialShipment}
                  Label={"partial shipment"}
                  List={StaticList}
                  id={"partial-shipment"}
                  text={terms?.data?.partial_shipment}
                  toClipboard={copyText}
                />
                <ComparisonDropDown
                  setters={setLoadingCountry}
                  code="44E"
                  Label="Loading Country"
                  List={countryList}
                  id={"Loading-Country"}
                  text={
                    terms?.data?.forex_sea_loading
                      ? terms?.data?.forex_sea_loading?.country
                      : terms?.data?.forex_air_loading?.country
                  }
                  toClipboard={copyText}
                />
                <ComparisonDropDown
                  setters={
                    checkShipment() == "Air" ? setAirLoadingId : setSeaLoadingId
                  }
                  code="44E"
                  Label="Loading Port"
                  List={reArrangeDat(
                    checkShipment() == "Sea" ? (
                      [
                        seaPorts?.data?.filter(
                          (items) =>
                            items?.origin_id ==
                            (loading_origin_id
                              ? loading_origin_id
                              : terms?.data?.loading_origin_id)
                        ),
                      ]
                    ) : checkShipment() == "Air" ? (
                      [
                        airPorts?.data?.filter(
                          (items) =>
                            items?.origin_id ==
                            (loading_origin_id
                              ? loading_origin_id
                              : terms?.data?.loading_origin_id)
                        ),
                      ]
                    ) : (
                      <></>
                    )
                  )}
                  id={"Loading-Port"}
                  text={
                    terms?.data?.forex_air_loading?.port_name ||
                    terms?.data?.forex_sea_loading?.port_name
                  }
                  toClipboard={copyText}
                />
             
                <ComparisonDropDown
                  setters={setDischargePortId}
                  code="44F"
                  Label="Discharging Port"
                  List={dischargeList}
                  id={"Discharging-Port"}
                  text={terms?.data?.forex_discharge_port?.port_name}
                  toClipboard={copyText}
                />
                {/* <Comparison
              Title={"Discharging Port"}
              id={"Discharging-Port"}
              text={ci?.data?.air_discharge?.port_name}
              toClipboard={copyText}
            /> */}
                {/* <Comparison
              Title={"Freight"}
              id={"Freight"}
              text={ci?.data?.term?.frieght_payment}
              toClipboard={copyText}
            /> */}
                {/* <Comparison
              Title={"Incoterm"}
              id={"Incoterm"}
              text={ci?.data?.term?.incoterm}
              toClipboard={copyText}
            /> */}
                {/* <ComparisonDropDown
              setters={setFreight}
              Label="Freight"
              List={freighList}
              id={"Freight"}
              text={terms?.data?.frieght}
              toClipboard={copyText}
            /> */}
                <ComparisonDropDown
                  setters={setIncoTerm}
                  Label="Inco Term"
                  List={incoTermList}
                  id={"Incoterm"}
                  text={terms?.data?.incoterms?.incoterm_name}
                  toClipboard={copyText}
                />
              </div>
              <div className="form-buttons mt-3">
                <button
                  className="form-save"
                  onClick={(e) => updateTermsAndConditions()}
                >
                  {loading ? "Loading...." : "Save"}
                </button>
              </div>
              <div className="ci-divider"></div>
              <button
                className={
                  sendForApproval === false
                    ? "send-for-approval-btn"
                    : "send-for-approval-btn-dissable"
                }
                onClick={handelSendForApproval}
                disabled={sendForApproval}
              >
                Send for Approval
              </button>{" "}
            </>
          ) : (
            <></>
          )}
          {isDocumentApproved === false && isApprover === true ? (
            <div className="d-flex justify-content-center gap-3 mt-3">
              <button
                type="button"
                class="decline-btn mb-2"
                onClick={() => setShow((prev) => !prev)}
              >
                Decline
              </button>
              <button
                type="button"
                class="approval-btn"
                onClick={confirmationHandler}
              >
                Approve
              </button>
            </div>
          ) : (
            ""
          )}
          {lastApprover !== false &&
          normalApprover === false &&
          retractLastApprover === true ? (
            <div className="d-flex justify-content-center gap-3 mt-1 mb-1">
              <button
                type="button"
                class="approval-btn"
                onClick={retractConfirmationHandler}
                style={{ backgroundColor: "#FF5252" }}
              >
                Retract Approval
              </button>
            </div>
          ) : (
            <></>
          )}
          {isDocumentApproved !== true && canRetract === true ? (
            <div className="d-flex justify-content-center gap-3 mt-1 mb-1">
              <button
                type="button"
                class="approval-btn"
                onClick={retractConfirmationHandler}
                style={{ backgroundColor: "#FF5252" }}
              >
                Retract Approval
              </button>
            </div>
          ) : (
            <></>
          )}
          {show && (
            <div class="pa-comment-container">
              <div className="prg p-2">Comment</div>
              <textarea
                className="po-comment-section mx-3"
                onChange={(e) => setFeedBack(e.target.value)}
              ></textarea>
              <button
                type="button"
                class="comment-decline-btn mx-3 my-1"
                onClick={handleDecline}
              >
                Decline
              </button>
              <button
                type="button"
                class="comment-decline-btn mx-3 my-1"
                style={{
                  backgroundColor: "#fff",
                  color: "#606060",
                  border: "1px solid #606060",
                }}
                onClick={() => setShow((prev) => !prev)}
              >
                Cancel
              </button>
            </div>
          )}
          {confirmation ? (
            <div
              id="Modal"
              className="dialog-modal "
              onClick={(e) => {
                setConfirmation(false);
              }}
            >
              <div
                className="dialog-modal-content"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="dialog-info text-center">
                  Are you sure to approve
                  {/* <div className="prg text-center mt-2">{props?.ordername}</div> */}
                </div>
                <div className="confirm-dialog-controller">
                  <div
                    className="dialog-cancel-btn"
                    onClick={(e) => {
                      setConfirmation(false);
                    }}
                  >
                    Cancel
                  </div>
                  <button
                    type={"submit"}
                    onClick={handleApprovals}
                    className="dialog-delete-btn"
                  >
                    Approve
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {retractConfirmation ? (
            <div
              id="Modal"
              className="dialog-modal "
              onClick={(e) => {
                setConfirmation(false);
              }}
            >
              <div
                className="dialog-modal-content"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="dialog-info text-center">
                  Are you sure to retract
                  {/* <div className="prg text-center mt-2">{props?.ordername}</div> */}
                </div>
                <div className="confirm-dialog-controller">
                  <div
                    className="dialog-cancel-btn"
                    onClick={(e) => {
                      setRetractConfirmation(false);
                    }}
                  >
                    No
                  </div>
                  <button
                    type={"submit"}
                    onClick={handleRetract}
                    className="dialog-delete-btn"
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="pdf-view-container">
          {/* Upload PDF */}
          <form>
            <label>
              <h5>Upload PDF</h5>
            </label>
            <br></br>

            <input
              id="file-Chooser"
              type="file"
              className="form-control"
              onChange={handleFile}
            ></input>

            {/* we will display error message in case user select some file
other than pdf */}
            {pdfError && <span className="text-danger">{pdfError}</span>}
          </form>

          <div className="viewer">
            {/* render this if we have a pdf file */}
            {pdfFile && (
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
                <Viewer
                  fileUrl={pdfFile}
                  plugins={[defaultLayoutPluginInstance]}
                ></Viewer>
              </Worker>
            )}

            {/* render this if we have pdfFile state null   */}
            {!pdfFile && <>No file is selected yet</>}
          </div>
        </div>
      </div>
      {/* <CreateCI /> */}
      {Success && (
        <ModalSuccess
          setOpen={setSuccess}
          open={Success}
          Message={"Information Updated Successfully"}
        />
      )}
    </Index>
  );
}

export default CiComparison;
