import React from "react";
import {
  useApprovalApproveMutation,
  useApprovalRejectMutation,
  useApprovalRetractMutation,
  useGetApproverQuery,
  useGetOrderPoItemQuery,
  useGetPRFQuery,
  useGetUserInfoQuery,
  useSendForApproverPRFMutation,
  useShowPRFQuery,
} from "../../../features/SCMApi";
import { useParams } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Tooltip } from "@mui/material";
import { useState } from "react";

export default function PrfApproval(props) {
  const params = useParams();
  // const { data: orderpo } = useGetOrderPoItemQuery(params.id);
  const { data: prf } = useShowPRFQuery(
    props?.prf_id ? props?.prf_id : params?.prf_id
  );

  const { data: loggedUser } = useGetUserInfoQuery();
  const prfID = props?.prf_id ? props?.prf_id : prf?.data?.id;

  const [show, setShow] = useState();
  const [confirmation, setConfirmation] = useState(false);
  const [feedback, setFeedBack] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false);
  const [retractConfirmation, setRetractConfirmation] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [approvalReject] = useApprovalRejectMutation();
  const [approvalApprove] = useApprovalApproveMutation();
  const [approvalRetract] = useApprovalRetractMutation();
  function confirmationHandler() {
    setConfirmation(true);
  }
  function retractConfirmationHandler() {
    setRetractConfirmation(true);
  }

  const { data: getApprover } = useGetApproverQuery({
    module_name: "PRF",
    id: prfID,
  });

  let currentApproverUser =
    loggedUser &&
    getApprover &&
    getApprover?.data?.current.find((user) => user?.user_id == loggedUser?.id);
  let isApprover = currentApproverUser !== undefined ? true : false;

  let feedBack =
    getApprover &&
    getApprover?.data?.history.find((user) => user?.feedback)?.feedback
      ? getApprover?.data?.history.find((user) => user?.feedback)?.feedback
      : false;

  let feedBackUser =
    getApprover &&
    getApprover?.data?.history.find((user) => user?.feedback)?.name
      ? getApprover?.data?.history.find((user) => user?.feedback)?.name
      : false;

  let isPaApproved =
    getApprover?.data?.current &&
    getApprover?.data?.current.find((paData) => paData?.prf_approval == 1)
      ?.prf_approval == 1
      ? true
      : getApprover?.data?.current.find((paData) => paData?.prf_approval == 0)
            ?.prf_approval == 0
        ? false
        : getApprover?.data?.current.find((paData) => paData?.prf_approval == 2)
              ?.prf_approval == 2
          ? null
          : "";
  let roleName =
    getApprover &&
    getApprover?.data?.current.find((paData) => paData?.level)?.role_name;
  let level = getApprover?.data?.current.find((paData) => paData?.level)?.level;
  const approvalStatus = {
    level,
    finalApproved: isPaApproved == 1 ? true : isPaApproved == 0 ? false : null,
    feedBack,
    feedBackUser,
  };
  //Retract approval functionality
  let currentLevel = getApprover?.data?.current[0]?.level;

  let approvedHistoryForCurrentApprover = getApprover?.data?.history?.find(
    (user) => user?.approval_type == 0 && user?.user_id == loggedUser?.id
  );

  let canRetract =
    approvedHistoryForCurrentApprover?.level + 1 == currentLevel ? true : false;
  const po_number = prf?.order?.po_number;
  let dataForApproval = {
    module_name: "PRF",
    module_id: prf?.data?.id,
    user_id: loggedUser?.id,
    feedback,
  };

  const handleDecline = () => {
    setButtonDisable(true);

    approvalReject(dataForApproval)
      .unwrap()
      .then((response) => {
        setConfirmation(false);
        setButtonDisable(false);

        setShow(false);
      })
      .then((error) => {
        setButtonDisable(false);
      });
  };
  const handleApprovals = () => {
    setButtonDisable(true);

    approvalApprove(dataForApproval)
      .unwrap()
      .then((response) => {
        setConfirmation(false);
        setButtonDisable(false);
      })
      .catch((error) => {
        setErrorMessage(true);
        setButtonDisable(false);
      });
    // axios
    //   .post(`${API_BASE_URL}/scm/approval/approve`, dataForApproval)
    //   .then((response) => {
    //     setConfirmation(false);

    //   })
    //   .catch((err) =>

    //   );
  };
  const handleRetract = () => {
    setButtonDisable(true);

    approvalRetract(dataForApproval)
      .unwrap()
      .then((response) => {
        // props.setMessages("Approved Successfully");
        setRetractConfirmation(false);
        setButtonDisable(false);
      })
      .then((error) => {
        setButtonDisable(false);
      });
    // axios
    //   .post(`${API_BASE_URL}/scm/approval/retract`, dataForApproval)
    //   .then((response) => {
    //     setRetractConfirmation(false);

    //   })
    //   .catch((err) =>

    //   );
  };
  let PRFApproved = {
    id: params?.prf_id,
  };
  const [sendForApprover] = useSendForApproverPRFMutation();
  const handelSendForApproval = () => {
    setButtonDisable(true);

    sendForApprover(PRFApproved)
      .unwrap()
      .then((response) => {
        setButtonDisable(false);
      })
      .catch((error) => {
        setButtonDisable(false);
      });
    // axios
    //   .post(`${API_BASE_URL}/scm/sourcing/orders/send_approval`, PaApproved)
    //   .then((response) => {

    //     approvalStage = false;
    //   })
    //   .catch((err) =>

    //   );
  };
  return (
    <>
      <div className="smManufacturer-card">
        <div className="Lc-card">
          <div className="lc-value">
            {approvalStatus?.finalApproved == false &&
            getApprover &&
            getApprover?.data?.current?.length !== 0 ? (
              <div
                style={{
                  color: "#fcce37",
                  textAlign: "center",
                  fontSize: window.innerWidth <= 768 ? "12px" : "",
                }}
              >
                Level {approvalStatus?.level} (
                {roleName?.length > 21
                  ? roleName?.slice(0, 20) + "..."
                  : roleName}
                ) approval pending
              </div>
            ) : approvalStatus?.finalApproved === true ? (
              <span
                style={{
                  color: "#064B4F",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: window.innerWidth <= 768 ? "12px" : "",
                }}
              >
                Approved
                <CheckCircleOutlineIcon />
              </span>
            ) : approvalStatus?.finalApproved == null ? (
              <Tooltip
                title={`${approvalStatus?.feedBackUser} reject PO with comment ${approvalStatus?.feedBack}`}
              >
                <span
                  style={{
                    color: "#FF5252",
                    display: "flex",
                    justifyContent: "center",
                    fontSize: window.innerWidth <= 768 ? "12px" : "",
                  }}
                >
                  PO rejected
                </span>
              </Tooltip>
            ) : (
              "Waiting for Approval"
            )}
          </div>
          <div className="lc-label">Status</div>
        </div>
      </div>
      {isPaApproved !== true &&
      isApprover === true &&
      getApprover?.data?.current[0]?.prf_approval !== 2 ? (
        <div className="d-flex justify-content-center gap-3 mt-3">
          <button
            type="button"
            className="decline-btn mb-2"
            onClick={() => setShow((prev) => !prev)}
            style={{ fontSize: window.innerWidth <= 768 ? "12px" : "" }}
          >
            Decline
          </button>
          <button
            type="button"
            className="approval-btn"
            onClick={confirmationHandler}
            style={{ fontSize: window.innerWidth <= 768 ? "12px" : "" }}
          >
            Approve
          </button>
        </div>
      ) : (
        ""
      )}
      {isPaApproved !== true && canRetract === true ? (
        <div className="d-flex justify-content-center gap-3 mt-3">
          <button
            type="button"
            className="approval-btn"
            onClick={retractConfirmationHandler}
            style={{
              backgroundColor: "#FF5252",
              fontSize: window.innerWidth <= 768 ? "12px" : "18px",
            }}
          >
            Retract Approval
          </button>
        </div>
      ) : (
        <></>
      )}
      {getApprover?.data?.current?.length == 0 ? (
        <div className="d-flex justify-content-center my-3">
          <button
            className="send-for-approval-btn"
            onClick={handelSendForApproval}
            style={{
              fontSize: window.innerWidth <= 768 ? "12px" : "18px",
            }}
          >
            Send for Approval
          </button>
        </div>
      ) : (
        ""
      )}
      {show && (
        <div style={{ left: "72px" }} className="pa-comment-container">
          <div className="prg p-2">Comment</div>
          <textarea
            className="po-comment-section mx-3"
            onChange={(e) => setFeedBack(e.target.value)}
          ></textarea>
          <button
            type="button"
            className="comment-decline-btn mx-3 my-1"
            onClick={handleDecline}
            disabled={buttonDisable}
            style={{ fontSize: window.innerWidth <= 768 ? "12px" : "18px" }}
          >
            Decline
          </button>
          <button
            type="button"
            className="comment-decline-btn mx-3 my-1"
            onClick={() => setShow((prev) => !prev)}
            style={{
              backgroundColor: "#fff",
              color: "#606060",
              border: "1px solid #606060",
              fontSize: window.innerWidth <= 768 ? "12px" : "18px",
            }}
          >
            Cancel
          </button>
        </div>
      )}
      {confirmation ? (
        <div
          id="Modal"
          className="dialog-modal "
          onClick={(e) => {
            setConfirmation(false);
          }}
        >
          <div
            className="dialog-modal-content"
            onClick={(e) => e.stopPropagation()}
            style={{ height: "30%" }}
          >
            <div className="dialog-info text-center">
              Are you sure to approve
              <div className="prg text-center mt-2">{po_number}</div>
            </div>
            <div className="confirm-dialog-controller">
              <div
                className="dialog-cancel-btn"
                onClick={(e) => {
                  setConfirmation(false);
                }}
                style={{ fontSize: window.innerWidth <= 768 ? "12px" : "18px" }}
              >
                Cancel
              </div>
              <button
                type="submit"
                onClick={handleApprovals}
                disabled={buttonDisable}
                className="dialog-delete-btn"
                style={{ fontSize: window.innerWidth <= 768 ? "12px" : "18px" }}
              >
                Approve
              </button>
            </div>
           
          </div>
        </div>
      ) : (
        <></>
      )}
      {retractConfirmation ? (
        <div
          id="Modal"
          className="dialog-modal "
          onClick={(e) => {
            setConfirmation(false);
          }}
        >
          <div
            className="dialog-modal-content"
            onClick={(e) => e.stopPropagation()}
            style={{ height: "30%" }}
          >
            <div className="dialog-info text-center">
              Are you sure to retract
              <div className="prg text-center mt-2">{po_number}</div>
            </div>
            <div className="confirm-dialog-controller">
              <div
                className="dialog-cancel-btn"
                onClick={(e) => {
                  setRetractConfirmation(false);
                }}
                style={{ fontSize: window.innerWidth <= 768 ? "12px" : "18px" }}
              >
                No
              </div>
              <button
                type={"submit"}
                onClick={handleRetract}
                className="dialog-delete-btn"
                disabled={buttonDisable}
                style={{ fontSize: window.innerWidth <= 768 ? "12px" : "18px" }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
