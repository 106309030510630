import React from "react";
import InventoryHistory from "./InventoryHistory";
import ToolHistory from "./InventoryHistory";
import ItemDescription from "./ItemDescription";
import ToolItemDescription from "./ItemDescription";

export default function InventoryDetail(props) {

  return (
    <div>
      <ItemDescription setFragment={props?.setFragment} data={props?.data} />
      <InventoryHistory data={props?.data} />
    </div>
  );
}
