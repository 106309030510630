import React from "react";
import { Breadcrumb } from "react-bootstrap";
import NavBar from "../../Layout/NavBar";

export default function Faq(props) {
  return (
    <>
      <NavBar />
      <div className="all-breadcrumb">
        <Breadcrumb className="ms-3">
          <Breadcrumb.Item className="prg" href="/Faq">
            FAQ
          </Breadcrumb.Item>
          <Breadcrumb.Item className="prg" href={props.navlink}>
            {props.title}
          </Breadcrumb.Item>
          {props.name === undefined ? (
            ""
          ) : (
            <Breadcrumb.Item className="prg-pri" active>
              {props.name}
            </Breadcrumb.Item>
          )}
        </Breadcrumb>
      </div>
      <div className="page">{props.children}</div>
    </>
  );
}
