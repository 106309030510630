import React, { useState } from "react";
import Settingsidebar from "../../../pages/Settings/SettingIndex";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import CreatePermission from "../SettingModal/CreatePermission";
import CheckIcon from "@mui/icons-material/Check";
import {
  useGetPermissionsQuery,
  useGetRolesQuery,
} from "../../../features/SCMApi";
import { Pagination, Tooltip } from "@mui/material";
import axios from "axios";
import RoleDetailModal from "../SettingModal/RoleDetailModal";
import usePagination from "../../Pagination/Pagination";
import { API_BASE_URL } from "../../../api/endPoint";
import Role from "./Role";
import Permission from "./Permission";
export default function Index() {
  const [Messages, setMessages] = useState(null);
  return (
    <Settingsidebar name={"User Access"} Messages={Messages}>
      <div className="page">
        <div className="setting-useraccess-container">
          <Role setMessages={setMessages} />
          <Permission />
        </div>
      </div>
    </Settingsidebar>
  );
}
