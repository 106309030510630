import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  useGetNotificationQuery,
  useReadNotificationMutation,
} from "../../features/SCMApi";
import LaunchIcon from "@mui/icons-material/Launch";
import CircleIcon from "@mui/icons-material/Circle";
import ClearIcon from "@mui/icons-material/Clear";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
export default function TaskContainer() {
  const [newNotification, setNewNotification] = useState(null);
  const [seenNotification, setSeenNotification] = useState(null);
  const [newDefaultNotification, setNewDefaultNotification] = useState(true);

  const handelNewNotification = (e) => {
    setSeenNotification(false);
    setNewNotification(true);
  };
  const handelSeenNotification = (e) => {
    setNewNotification(false);
    setSeenNotification(true);
    setNewDefaultNotification(false);
  };

  const { data: notifications } = useGetNotificationQuery();
  const [readNotification] = useReadNotificationMutation();

  const unreadNotifications =
    notifications &&
    notifications?.filter((notification) => notification?.read_at == null);
  const readNotifications =
    notifications &&
    notifications?.filter((notification) => notification?.read_at !== null);

  

  const handleRead = (id) => {
    const params = { id };
    readNotification(params)
      .unwrap()
      .then((response) => {
        
      })
      .catch((error) => {
        
      });
  };
  return (
    <>
      {unreadNotifications?.length != 0 ? (
        newNotification === true || newDefaultNotification === true ? (
          <div className="tasks-dashboard-container">
            {unreadNotifications?.map((notification) => (
              <div className="mb-2">
                <div className="task-icons-container">
                  <div className="d-grid gap-2">
                    <div className="task-order-name">
                      {notification?.data?.type === "Document"
                        ? "Document Approval"
                        : notification?.data?.name}
                    </div>
                    <div className="task-order-name">
                      {notification?.data?.type === "PO" ? (
                        <p>
                          <strong>PO</strong> is waiting for your approval
                        </p>
                      ) : notification?.data?.type === "PA" ? (
                        <p>
                          <strong>PA</strong>is waiting for your approval
                        </p>
                      ) : notification?.data?.type === "Document" ? (
                        <p>
                          <strong>Document</strong> Approval
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="d-flex gap-2 align-items-center">
                    <CancelOutlinedIcon
                      onClick={() => handleRead(notification?.id)}
                      sx={{ color: "#FF5252", cursor: "pointer" }}
                    />
                    <NavLink
                      onClick={() => handleRead(notification?.id)}
                      to={
                        notification?.data?.type == "Document"
                          ? `/logistics/batches/${notification?.data?.project_id}/batchDetail/${notification?.data?.document_id}/documentPreparation`
                          : notification?.data?.type == "PA"
                          ? `/sourcing/paStageManufacturer/${notification?.data?.order_id}`
                          : notification?.data?.type == "PO"
                          ? `/poStageDetail/${notification?.data?.po_id}`
                          : ""
                      }
                    >
                      <PlayCircleOutlineIcon sx={{ color: "#186569" }} />
                    </NavLink>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <></>
        )
      ) : (
        ""
      )}
    </>
  );
}
