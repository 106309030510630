import React from "react";

function AllPrice({ comparedData }) {


  return (
    <>
      <div className="text-center py-3 bg-light font-weight-bold h4">
        All Price
      </div>

      <div className="finance-table-container">
        <table
          className="table table-striped table-bordered "
          style={{
            border: "1px solid #333",
            width: "auto",
            minWidth: "1500px",
            borderCollapse: "collapse",
          }}
        >
          <thead className="stock-table-header">
            <tr>
              <td
                className="stock-table-header-text text-center"
                style={{ width: "10%" }}
              >
                Product
              </td>
              {Object.keys(comparedData)
                .reduce((suppliers, productName) => {
                  return comparedData[productName].reduce(
                    (columns, supplier) => {
                      if (
                        !columns.find((col) => col === supplier.SupplierName)
                      ) {
                        columns.push(supplier.SupplierName);
                      }
                      return columns;
                    },
                    suppliers
                  );
                }, [])
                .map((supplierName) => (
                  <React.Fragment key={`head-${supplierName}`}>
                    <td
                      colSpan={3}
                      className="stock-table-header-text text-center"
                    >
                      {supplierName}
                    </td>
                  </React.Fragment>
                ))}
            </tr>
            <tr>
              <td
                className="stock-table-header-text text-center"
                style={{ width: "10%" }}
              ></td>
              {Object.keys(comparedData)
                .reduce((suppliers, productName) => {
                  return comparedData[productName].reduce(
                    (columns, supplier) => {
                      if (
                        !columns.find((col) => col === supplier.SupplierName)
                      ) {
                        columns.push(supplier.SupplierName);
                      }
                      return columns;
                    },
                    suppliers
                  );
                }, [])
                .map((supplierName) => (
                  <React.Fragment key={`head-${supplierName}`}>
                    <td className="text-center stock-table-header-text">
                      Quantity
                    </td>
                    <td className="text-center stock-table-header-text">
                      Unit Price
                    </td>
                    <td className="text-center stock-table-header-text">
                      Total Price
                    </td>
                  </React.Fragment>
                ))}
            </tr>
          </thead>
          <tbody>
            {Object.keys(comparedData).map((productName) => (
              <tr key={`row-${productName}`} className="stock-table-row">
                <td>{productName}</td>
                {Object.keys(comparedData)
                  .reduce((suppliers, productName) => {
                    return comparedData[productName].reduce(
                      (columns, supplier) => {
                        if (
                          !columns.find((col) => col === supplier.SupplierName)
                        ) {
                          columns.push(supplier.SupplierName);
                        }
                        return columns;
                      },
                      suppliers
                    );
                  }, [])
                  .map((supplierName) => {
                    const data = comparedData[productName].find(
                      (sup) => sup.SupplierName === supplierName
                    );
                    return (
                      <React.Fragment
                        key={`data-${productName}-${supplierName}`}
                      >
                        <td className="text-center">{data ? data.qty : "-"}</td>
                        <td className="text-center">
                          {data ? data.unit_cost : "-"}
                        </td>
                        <td className="text-center">
                          {data ? data.total_cost : "-"}
                        </td>
                      </React.Fragment>
                    );
                  })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default AllPrice;
