import React, { useState } from "react";
import {
  useGetAllCurrenciesQuery,
  useGetProjectsQuery,
  useGetStatusQuery,
  useGetStoreQuery,
  useGetToolsTransactionQuery,
  useGetUnitOfMeasurementQuery,
} from "../../../../features/SCMApi";
import usePagination from "../../../Pagination/Pagination";
import NoRecord from "../../../common/NoRecord";
import { date } from "../../../dateFormator";
import { IMG_BASE_URL } from "../../../../api/endPoint";
import { Pagination } from "@mui/material";
import TransactionReport from "./TransactionReport";

function TransactionTools() {
  const { data: store } = useGetStoreQuery();
  const { data: uomuom } = useGetUnitOfMeasurementQuery();
  const { data: projects } = useGetProjectsQuery();
  const { data: currencies } = useGetAllCurrenciesQuery();
  const { data: status } = useGetStatusQuery();
  const { data: transactionTool } = useGetToolsTransactionQuery();
  const safeTransaction = Array.isArray(transactionTool) ? transactionTool : [];
  const newArrayData = safeTransaction.map((obj) => {
    const getFirstObject = (array) =>
      Array.isArray(array) && array.length > 0 ? array[0] : {};
    const tool_transactions = getFirstObject(obj.tool_transactions);
    const tool_user_transactions = getFirstObject(obj.tool_user_transactions);

    let additionalProps = {};

    // Determine the additional properties based on inventory_type
    additionalProps = {
      item_name: tool_transactions.item_name || "",
      image: tool_transactions.image || "",
      model: tool_transactions.model || "",
      serial_number: tool_transactions.serial_number || "",
      purchased_date: tool_transactions.date_of_purchased || "",
      uom: tool_transactions.uom_id || "",
      currencies: tool_transactions.currency_id || "",
      price: tool_transactions.price || "",
      is_batch: tool_user_transactions?.is_batch || "",
    };

    return {
      ...obj,
      ...additionalProps,
    };
  });

  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const filteredData = (search, startDate, endDate) => {
    return newArrayData
      ?.filter((items) => {
        if (search == "") {
          return items;
        } else if (
          items.tool_name?.toLowerCase().includes(search.toLowerCase())
        ) {
          return items;
        }
      })
      ?.filter((newArrayData) => {
        if (startDate === "") {
          return newArrayData;
        } else if (newArrayData?.created_at?.substring(0, 10) >= startDate) {
          return newArrayData;
        }
      })
      ?.filter((newArrayData) => {
        if (endDate === "") {
          return newArrayData;
        } else if (newArrayData?.created_at?.substring(0, 10) <= endDate) {
          return newArrayData;
        }
      })

      .map((items) => items);
  };
  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(
    filteredData(search, startDate, endDate)?.length / PER_PAGE
  );
  const _DATA = usePagination(
    filteredData(search, startDate, endDate),
    PER_PAGE
  );
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <div>
      <div className="tool-header-container d-flex gap-3 justify-content-center">
        <TransactionReport
          data={filteredData(search, startDate, endDate)}
          type={"Tool Transaction Report"}
          inventory_type={"Tool"}
        />
        <input
          onChange={handleSearchChange}
          placeholder="search"
          type="search"
          className="inventory-search-input"
        />
        <span>Start Date</span>
        <input
          type="date"
          className="inventory-search-input"
          onChange={(e) => setStartDate(e.target.value)}
        />{" "}
        <span>End Date</span>
        <input
          type="date"
          className="inventory-search-input"
          onChange={(e) => setEndDate(e.target.value)}
        />
      </div>
      {_DATA?.currentData()?.length !== 0 ? (
        <div className="finance-table-container">
          <table
            className="table table-striped table-bordered "
            style={{
              width: "auto",
              minWidth: "2000px",
              borderCollapse: "collapse",
            }}
          >
            <thead className="stock-table-header">
              <tr>
                <th style={{ width: "5%" }}></th>
                <th style={{ width: "7%" }}>Item Name</th>
                <th style={{ width: "7%" }}>Inventory Type/Category</th>
                <th style={{ width: "5%" }}>Upstream Number</th>
                <th style={{ width: "5%" }}>Transaction Number</th>
                <th style={{ width: "5%" }}>Downstream Number</th>
                <th style={{ width: "5%" }}>Model</th>
                <th style={{ width: "5%" }}>Stock Type</th>
                <th style={{ width: "5%" }}>Is Batch</th>
                <th style={{ width: "5%" }}>Inventory type</th>
                <th style={{ width: "5%" }}>Transaction Types</th>
                <th style={{ width: "6%" }}>Serial No</th>
                <th style={{ width: "6%" }}>Purchased Date</th>
                <th style={{ width: "5%" }}>UOM</th>
                <th style={{ width: "5%" }}>Qty</th>
                <th style={{ width: "5%" }}>currency</th>
                <th style={{ width: "5%" }}>Unit Price</th>
                <th style={{ width: "5%" }}>Total Price</th>
                <th style={{ width: "6%" }}>Source Store</th>
                <th style={{ width: "6%" }}>Destination</th>
                <th style={{ width: "6%" }}>Status</th>
                <th style={{ width: "7%" }}>Remark</th>
              </tr>
            </thead>
            <tbody>
              {_DATA?.currentData()?.map((tool) => (
                <tr className="stock-table-row">
                  <td className="">
                    {tool?.image != null ? (
                      <img
                        className="stock-img"
                        src={`${IMG_BASE_URL}${tool?.image}`}
                        alt=""
                      />
                    ) : (
                      <img
                        sx={{ width: 50, height: 50, borderRadius: 0 }}
                        // src={BatchItems}
                        alt=""
                      />
                    )}
                  </td>
                  <td className="text-start">
                    {" "}
                    {tool?.item_name ? tool?.item_name : "-"}
                  </td>
                  <td className="text-start">
                    {" "}
                    {tool?.inventory_type ? tool?.inventory_type : "-"}
                  </td>
                  <td className="text-start">
                    {tool?.second_id
                      ? `IE-${tool?.transaction_type == "register" ? "PO" : tool?.transaction_type == "return" ? "PO" : tool?.transaction_type == "request" ? "RQ" : tool?.transaction_type == "transfer" ? (tool?.task_type == "Allocation Out" ? "RQ" : tool?.task_type == "Allocation In" ? "PO" : "-") : "_"}-${tool?.created_at?.split("T")[0]}-${String(tool?.second_id).padStart(4, "0")}`
                      : "-"}
                  </td>
                  <td className="text-start">
                    {tool?.second_id
                      ? `IE-${tool?.transaction_type == "register" ? "SI" : tool?.transaction_type == "return" ? "SI" : tool?.transaction_type == "request" ? "SO" : tool?.transaction_type == "transfer" ? (tool?.task_type == "Allocation Out" ? "SO" : tool?.task_type == "Allocation In" ? "SI" : "-") : "_"}-${tool?.created_at?.split("T")[0]}-${String(tool?.second_id).padStart(4, "0")}`
                      : "-"}
                  </td>
                  <td className="text-start">
                    {tool?.second_id
                      ? `IE-${tool?.transaction_type == "register" ? "GR" : tool?.transaction_type == "return" ? "GR" : tool?.transaction_type == "request" ? "DR" : tool?.transaction_type == "transfer" ? (tool?.task_type == "Allocation Out" ? "DR" : tool?.task_type == "Allocation In" ? "GR" : "-") : "_"}-${tool?.created_at?.split("T")[0]}-${String(tool?.second_id).padStart(4, "0")}`
                      : "-"}
                  </td>
                  <td className="text-start">
                    {tool?.model === null ? "-" : tool?.model}
                  </td>
                  <td className="text-start">
                    {tool?.transaction_type == "register"
                      ? "Stock In"
                      : tool?.transaction_type == "return"
                        ? "Stock In"
                        : tool?.transaction_type == "request"
                          ? "Stock Out"
                          : tool?.transaction_type == "transfer"
                            ? tool?.task_type == "Allocation Out"
                              ? "Stock Out"
                              : tool?.task_type == "Allocation In"
                                ? "Stock In"
                                : "-"
                            : "_"}
                  </td>
                  <td className="text-start">
                    {tool?.is_batch ? "Batch  Request" : ""}
                  </td>
                  <td className="text-start">{tool?.inventory_type}</td>{" "}
                  <td className="text-start">{tool?.task_type}</td>
                  <td className="text-start">{tool?.serial_number || "-"}</td>
                  <td className="text-start">
                    {tool?.purchased_date ? date(tool?.purchased_date) : "-"}
                  </td>
                  <td className="text-start">
                    {tool?.uom
                      ? uomuom?.data
                          ?.filter((uom) => uom?.id == tool?.uom)
                          ?.map((uom) => uom?.name) || "?"
                      : "-"}
                  </td>
                  <td className="text-start">{tool?.qty ? tool?.qty : "-"}</td>
                  <td className="text-start">
                    {tool?.currencies
                      ? currencies?.data
                          ?.filter(
                            (currencies) =>
                              currencies?.currency_id == tool?.currencies
                          )
                          .map((currencies) => currencies?.code)
                      : "-"}
                  </td>
                  <td className="text-start">
                    {tool?.price == null ? "-" : tool?.price}
                  </td>
                  <td className="text-start">
                    {tool?.price == null
                      ? "-"
                      : tool?.qty
                        ? tool?.price * tool?.qty
                        : "-"}
                  </td>
                  <td className="text-start">
                    {tool?.source
                      ? store?.data?.filter(
                          (store) => store?.id == tool?.source
                        )[0]?.store_name ||
                        projects?.data?.filter(
                          (project) => project?.id == tool?.source
                        )[0]?.project_name ||
                        tool?.source
                      : "-"}
                  </td>
                  <td className="text-start">
                    {tool?.destination
                      ? projects?.data?.filter(
                          (project) => project?.id == tool?.destination
                        )[0]?.project_name ||
                        store?.data?.filter(
                          (store) => store?.id == tool?.destination
                        )[0]?.store_name ||
                        tool?.destination
                      : "-"}
                  </td>
                  <td className="text-start">
                    {tool?.status
                      ? status?.data
                          ?.filter((stat) => stat?.id == tool?.status)
                          ?.map((stat) => stat?.status_name)[0] || tool?.status
                      : tool?.status}
                  </td>
                  <td className="text-start">
                    {tool?.remark ? tool?.remark : "no remark"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <NoRecord />
      )}
      {count > 1 ? (
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
          className="p-2"
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default TransactionTools;
