import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';

const AutoCloseAlert = ({ type, message, onClose }) => {
  return (
    <Alert variant={type} onClose={onClose} dismissible className="position-fixed top-0 start-30 p-4" style={{ width: '20%', height: 'auto',zIndex: 1000 }}>
      <strong>{message}</strong>
    </Alert>
  );
};
const AutoClosePopup = ({ isOpen, onClose, type, message }) => {
  const [show, setShow] = useState(isOpen);

  useEffect(() => {
    setShow(isOpen);

    if (isOpen) {
      const timeout = setTimeout(() => {
        setShow(false);
        onClose();
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [isOpen, onClose]);

  return show ? (
    <AutoCloseAlert type={type} message={message} onClose={() => setShow(false)} />
  ) : null;
};

export default AutoClosePopup 