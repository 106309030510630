import React, { useState } from "react";
import { useGetProjectsQuery } from "../../features/SCMApi";

export default function StockDialog(props) {
  const [selectedProject, setSelectedProject] = useState("");
  const { data: project } = useGetProjectsQuery();

  const handleProjectSelection = (projectId) => {
    setSelectedProject(projectId);
    props?.projectId(projectId);
  };

  const props_project_id_ = props?.project_id;

  if (props_project_id_ && props_project_id_ !== selectedProject) {
    setSelectedProject(props_project_id_);
  }

  const isProjectSelected = () => {
    return selectedProject !== "";
  };

  const dif =
    props?.data?.requested_quantity -
    (props?.data?.returned_quantity +
      props?.data?.consumed_amount +
      props?.data?.current_return_amount +
      props?.data?.current_consumed_amount);

  return (
    <div
      id="Modal"
      className="dialog-modal"
      onClick={(e) => {
        props?.setDialog(false);
      }}
    >
      <div
        className="stock-dialog-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="dialog-info">Are you sure to {props?.name}</div>
        <div className="dialog-info">
          {props?.data?.requested_quantity ? (
            <div className="prg">{`${props?.data?.requested_quantity} ${
              props?.data?.uom?.name === undefined
                ? "-"
                : props?.data?.uom?.name
            } ${props?.dialog}`}</div>
          ) : (
            <div className="prg">
              {props?.dialog === undefined ? "" : props?.dialog}
            </div>
          )}
          <div className="prg-error text-danger">{props?.error}</div>
        </div>

        {props?.name === "request" &&
          !(
            props?.data?.project_id && props?.data?.store?.is_permanent === 0
          ) && (
            <div
              className="d-flex gap-2 align-items-baseline"
              style={{ justifyContent: "center", marginRight: "140px" }}
            >
              <div className="supplier-input-label">Project</div>
              <select
                name=""
                id=""
                className="select-option-create-order"
                onChange={(e) => handleProjectSelection(e.target.value)}
                required
              >
                <option value="" disabled selected>
                  Choose Project
                </option>
                {project?.data?.map((items) => (
                  <option key={items.id} value={items.id}>
                    {items?.project_name}
                  </option>
                ))}
              </select>
            </div>
          )}

        {props?.data?.uom?.is_countable === 1 && (
          <div
            className="d-flex gap-2 align-items-baseline"
            style={{ justifyContent: "center", marginRight: "140px" }}
          >
            <div className="supplier-input-label">Quantity</div>
            <input
              onChange={(e) => props?.setQuantity(e.target.value)}
              type="number"
              min="0"
              className={`stock-text-input`}
              required
            />
          </div>
        )}
        <div className="prg">
          {props?.data?.current_return_amount > 0 ? (
            <>{`Return requested ${props?.data?.current_return_amount} ${props?.data?.uom_name}`}</>
          ) : (
            ""
          )}
          <br />
          {props?.data?.returned_quantity > 0 ? (
            <>{`Returned ${props?.data?.returned_quantity} ${props?.data?.uom_name}`}</>
          ) : (
            ""
          )}
        </div>
        <div className="prg">
          {props?.data?.current_consumed_amount > 0 ? (
            <>{`Consume requested ${props?.data?.current_consumed_amount} ${props?.data?.uom_name}`}</>
          ) : (
            ""
          )}
          <br />
          {props?.data?.consumed_amount > 0 ? (
            <>{`Consumed ${props?.data?.consumed_amount} ${props?.data?.uom_name}`}</>
          ) : (
            ""
          )}
        </div>

        {props?.is_return === true && (
          <div className="d-flex gap-2 align-items-baseline">
            <div className="supplier-input-label">
              Quantity to {props?.name == "consume" ? "Consume" : "Return"}
            </div>
            <input
              onChange={(e) => props?.setQuantity(e.target.value)}
              type="number"
              className={`stock-text-input`}
              required
            />
          </div>
        )}

        {props?.setRemark !== undefined && (
          <div className="d-grid gap-1 align-items-baseline">
            <div className="ms-2 prg">Remark</div>
            <textarea
              cols="30"
              rows="5"
              onChange={(e) => props?.setRemark(e.target.value)}
            ></textarea>
          </div>
        )}

        <div className="confirm-dialog-controller">
          <div
            className="dialog-cancel-btn"
            onClick={(e) => {
              props?.setDialog(false);
            }}
          >
            No
          </div>
          <button
            type={"submit"}
            onClick={(e) => {
              if (isProjectSelected()) {
                props.setDialog(false);
                props.action(e, props?.dialog?.id);
              } else {
                alert("Please select a project.");
              }
            }}
            disabled={
              Number(props?.quantity) < 1 ||
              Number(props?.quantity) >
                Number(dif ? dif : props?.data?.amount) ||
              !isProjectSelected()
            }
            className={
              props?.quantity < 1 ||
              Number(props?.quantity) >
                Number(dif ? dif : props?.data?.amount) ||
              !isProjectSelected()
                ? "form-save bg-secondary text-white"
                : "form-save"
            }
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
}
