import axios from "axios";
import { API_BASE_URL } from "../../../../api/endPoint";
import { FaCheck } from "react-icons/fa";
import { useState } from "react";

export default function ConfirmRecive({ item }) {
  const [showModal, setShowModal] = useState(false);
  const [amount, setAmount] = useState(null);
  const [alerts, setAlerts] = useState(null);
  const [seccess, setSeccess] = useState(null);
  const handleConfirm = () => {
    setShowModal(true);
  };

  const handleCancel = () => {
    setShowModal(false);
  };
  function handleSubmit(e) {
    e.preventDefault();
    if (amount == null) {
      setAlerts("Please insert Amount of money");
    } else {
      e.preventDefault();
      axios
        .post(`${API_BASE_URL}/scm/getRecieved`, {
          ammount: amount,
          id: item.id,
          table: item.table,
        })
        .then((response) => {
          
          if (response.data.status === "Success") {
            setSeccess("Success");
            window.location.reload();
          }
        })
        .catch((err) => {
          console.error("Error", err);
        });
    }
    // alert(props?.data?.id);


  }
  return (
    <div>
      <button
        className="form-save d-flex justify-content-center align-items-center mt-3"
        onClick={handleConfirm}
      >
        Received
      </button>
      {showModal && (
        <div className="modal-container">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content " style={{ minHeight: "0" }}>
              <div class="modal-header">
                <h5 className="modal-title">Confirm paid?</h5>
                <button className="btn-close" onClick={handleCancel}></button>
              </div>
              <div style={{ color: "red", fontWeight: "bold" }}>{alerts}</div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {seccess ? (
                  <div
                    style={{
                      background: "Green",
                      fontWeight: "bold",
                      color: "white",
                      width: "200px",
                      justifyContent: "center",
                    }}
                  >
                    <>
                      <FaCheck />
                    </>
                    {"   "}
                    <>{seccess}</>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="modal-body">
                <p>Insert the amount of money received from {item.project}:</p>
              </div>
              <form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <label>Amount:</label>
                <input
                  type="number"
                  required
                  max={item.amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
                <div className="d-flex flex-row form-buttons modal-footer">
                  <button className="form-save" type="submit">
                    Yes
                  </button>
                  <button className="form-cancel" onClick={handleCancel}>
                    No
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
