import React from "react";
import "../../pages/Sourcing/orders.style.css";
// import NavBar from "../../Layout/NavBar";
import EtaFollowup from "./EtaFollowup";
import { useGetProjectsQuery } from "../../features/SCMApi";
import { useParams } from "react-router-dom";
import SourcingIndex from "../../pages/Sourcing/SourcingIndex";
import { useState } from "react";
export default function EtaProduct() {
  const params = useParams();
  const { data: response } = useGetProjectsQuery();
  const Projectdata = [];

  response?.data?.map((items) => {
    if (params.id === items.id) {
      Projectdata.push(items);
    }
  });
  
  const [search, setSearch] = useState("");

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  return (
    <SourcingIndex
      name={Projectdata[0]?.project_name}
      names="ETA Followup"
      handleSearchChange={handleSearchChange}
      placeholder={"search ETA order..."}
    >
      <EtaFollowup search={search} order_type={"Product"} />
    </SourcingIndex>
  );
}
