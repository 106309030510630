import React from "react";
import "../../pages/Sourcing/orders.style.css";
// import NavBar from "../../Layout/NavBar";
import EtaFollowup from "./EtaFollowup";
import { useGetProjectsQuery } from "../../features/SCMApi";
import { useParams } from "react-router-dom";
import SourcingIndex from "../../pages/Sourcing/SourcingIndex";
import { useState } from "react";
import { Tab } from "bootstrap";
import { Tabs } from "react-bootstrap";
export default function EtaService() {
  const params = useParams();
  const { data: response } = useGetProjectsQuery();
  const Projectdata = [];

  response?.data?.map((items) => {
    if (params.id === items.id) {
      Projectdata.push(items);
    }
  });
  
  const [search, setSearch] = useState("");

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  return (
    <SourcingIndex
      name={Projectdata[0]?.project_name}
      names="ETA Followup"
      handleSearchChange={handleSearchChange}
      placeholder={"search ETA order..."}
    >
      <Tabs
        defaultActiveKey="Product"
        id="uncontrolled-tab-example"
        className="mb-3 d-flex justify-content-center bg-white rounded gap-4 border forexApprovalNav"
      >
        <Tab eventKey="Product" title="Service Material">
          <EtaFollowup
            search={search}
            order_type={"Service"}
            serviceType={"Material"}
          />
        </Tab>
        <Tab eventKey="Service" title="Service traning">
          <EtaFollowup
            search={search}
            order_type={"Service"}
            serviceType={"Training"}
          />
        </Tab>
      </Tabs>
    </SourcingIndex>
  );
}
