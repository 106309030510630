import React, { useState } from "react";
import "../../../../components/Logistics/logistics.css";
import Selector from "../Select";
import {
  useGetOrderListQuery,
  useGetForexProjectIeInitiatedQuery,
  useInititateForexIEMutation,
  useInititateForexClientMutation,
  useGetReceivableBanksQuery,
} from "../../../../features/SCMApi";
import InitiateForexIeItemList from "../InitiateForexIeItemList";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { API_BASE_URL } from "../../../../api/endPoint";

export default function InitiateForexModal(props) {
  const [initiateForexIe] = useInititateForexIEMutation();
  const [initiateForexClient] = useInititateForexClientMutation();
  const { data: bankData } = useGetReceivableBanksQuery();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 480,
        marginTop: 0,
      },
    },
    disableScrollLock: false,
  };

  const { data: projectList } = useGetForexProjectIeInitiatedQuery();
  const [project, setProject] = useState(null);
  const [bankId, setBankId] = useState(null);
  const projectId =
    projectList?.data &&
    projectList?.data
      .filter((projectName) => projectName?.project_name === project)
      ?.map((project) => project?.id)
      .toString();

  const { data: orderList } = useGetOrderListQuery(projectId);


  const [orderName, setOrderName] = React.useState([]);
  const [orderItemListsArray, setOrderItemLists] = React.useState([]);

  let orderItemLists = [];
  const [selectItem, setSelectItem] = useState(null);

  const allOrderItemList = [];

  orderList &&
    orderList.map((order) =>
      order.pis.map((piList) => allOrderItemList.push(piList?.pi))
    );
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    const newValue = typeof value === "string" ? value.split(",") : value;
    setOrderName(newValue);
    setSelectItem(true);

    const updatedOrderItems = orderList
      .filter((order) => newValue.includes(order?.order_name))
      .map((order) => order?.id);
    setOrderItemLists(updatedOrderItems);
  };


  for (let index = 0; index <= orderName?.length; index++) {
    orderList &&
      orderList
        ?.filter((order) => order?.order_name === orderName[index])
        .map((order) =>
          orderItemLists.push({
            name: order?.order_name,
            id: order?.id,
            pis: order?.pis,
          })
        );
  }


  let params1 = {
    project_id:
      projectList?.data !== undefined
        ? projectId
        : props?.clientData
            ?.filter((pro) => pro?.project_name === project)
            .map((data) => data?.id)
            ?.toString(),
    bank_id: bankData?.data?.find((bank) => bank?.bank_name === bankId)?.id,
    items: orderItemListsArray,
  };

  let params2 = {
    project_id: props?.clientData
      ?.filter((pro) => pro?.project_name === project)
      .map((data) => data?.id)
      ?.toString(),
    bank_id: bankData?.data?.find((bank) => bank?.bank_name === bankId)?.id,
  };
  function HandleCancel() {
    props.modal(false);
  }
  const projectName =
    projectList?.data !== undefined
      ? projectList?.data
          .filter((projectName) => projectName?.project_name === project)
          ?.map((project) => project?.project_name)
          ?.toString()
      : props?.clientData
          ?.filter((pro) => pro?.project_name === project)
          .map((data) => data?.project_name)
          ?.toString();


  const HandleSubmit = async (e) => {
    e.preventDefault();


    if (selectItem === false) {
      props?.initiatedBy === "InitiateforexIe" ? (
        axios
          .post(`${API_BASE_URL}/scm/payment/forex`, params1)
          .then((response) => {
            HandleCancel();
            props?.successMessage(true);
            props?.message(`${projectName} forex initiated successfully!!!`);
 
          })
          .catch((error) =>
          {

          })
      ) : (
        // initiateForexIe(params1)
        //   .unwrap()
        //   .then((response) => {
        //     HandleCancel();
        //     props?.successMessage(true);
        //     props?.message(`${projectName} forex initiated successfully!!!`);

        //   })
        //   .catch((error) => {

        //   })
        // axios
        //   .post(`${API_BASE_URL}/scm/payment/forex`, params1)
        //   .then((response) => )
        //   .catch((error) => )
        <></>
      );
    }
    props?.initiatedBy === "InitiateforexClient" ? (
      initiateForexClient(params2)
        .unwrap()
        .then((response) => {
          HandleCancel();
          props?.successMessage(true);
          props?.message(`${projectName} forex initiated successfully!!!`);


        })
        .then((error) => {

        })
    ) : (
      // axios
      //   .post(`${API_BASE_URL}/scm/payment/forex-client`, params2)
      //   .then((response) =>)
      //   .catch((error) => )
      <></>
    );
  };

  return (
    <>
      <div id="myModal" className="modal" onClick={HandleCancel}>
        <div
          className="mini-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="logisticsRma-create-project-modal-title">
            <div className="initiateForexTitle">Initiate Forex</div>
            <div onClick={HandleCancel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="42"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>

          <form
            className="form-container paymentForexApprovalinitiateForex"
            onSubmit={HandleSubmit}
          >
            <div className="form-card-container">
              <div className="form-card-information">
                {props?.name !== "InitiateforexClient" ? (
                  <>
                    {selectItem === true ? (
                      <p className="select-order-list-info-message">
                        Please select item from the list
                      </p>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
                <div className="card-center">
                  <div className="card-form">
                    <div className="input-labels">
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "16px" }}
                      >
                        Project
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "5px" }}
                      >
                        Bank
                      </div>
                      {props?.name !== "InitiateforexClient" ? (
                        <>
                          <div
                            className="rmaFileInputLabel"
                            style={{ marginTop: "5px" }}
                          >
                            Order
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="forex-input-fields">
                      <div>
                        <Selector
                          name={
                            props?.clientData !== undefined
                              ? props?.clientData?.map(
                                  (project) => project?.project_name
                                )
                              : props?.projectIe?.map(
                                  (project) => project?.project_name
                                )
                          }
                          onChange={setProject}
                          required={true}
                        />
                      </div>
                      <div>
                        <Selector
                          name={
                            bankData?.data &&
                            bankData?.data?.map((bank) => bank?.bank_name)
                          }
                          onChange={setBankId}
                          required={true}
                        />
                      </div>
                      {props?.name !== "InitiateforexClient" ? (
                        <>
                          <div>
                            <FormControl sx={{ m: 1, width: 226 }}>
                              <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={orderName}
                                onChange={handleChange}
                                input={<OutlinedInput label="Tag" />}
                                renderValue={(selected) => selected.join(", ")}
                                MenuProps={MenuProps}
                                sx={{
                                  width: 250,
                                  height: 32,
                                  backgroundColor: "#ececec",
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                  },
                                  color: "#186569",
                                }}
                                required={true}
                              >
                                {orderList &&
                                  orderList?.map((order) => (
                                    // <Tooltip title={order?.order_name} arrow>
                                    <MenuItem
                                      key={order?.id}
                                      value={order?.order_name}
                                      sx={{
                                        color: "#186569",
                                        width: 300,
                                      }}
                                    >
                                      <Checkbox
                                        checked={
                                          orderName.indexOf(order?.order_name) >
                                          -1
                                        }
                                        sx={{
                                          color: "#186569",
                                          height: "25px",
                                          "&.Mui-checked": {
                                            color: "#186569",
                                            height: "25px",
                                          },
                                        }}
                                      />
                                      <ListItemText
                                        title={order.order_name}
                                        arrow
                                        primary={order?.order_name}
                                      />
                                    </MenuItem>
                                    //  </Tooltip>
                                  ))}
                              </Select>
                            </FormControl>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>

                <InitiateForexIeItemList
                  orderData={orderItemLists}
                  allOrderItemList={allOrderItemList.flat()}
                  setOrderList={setOrderItemLists}
                  isSelected={setSelectItem}
                />
              </div>
              <div className="form-buttons clientForm">
                <button
                  type="cancel"
                  className="form-cancel clientCancel"
                  style={{ width: "112px", height: "32px" }}
                  onClick={HandleCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  // onClick={handleClick}
                  className="form-save clientSave"
                  style={{ width: "112px", height: "32px" }}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
