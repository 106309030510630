import React from "react";
import PRF_Supplier from "./PRF_Supplier";
import Revenue from "./Revenue";

import { MdOutlineCancel } from "react-icons/md";
const GrossProfitModal = ({ show, handleClose, item, data }) => {

  return (
    <div
      className={`modal ${show ? "show" : ""} `}
      tabIndex="-1"
      role="dialog"
      style={{
        display: show ? "block" : "none",
      }}
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
        style={{
          maxWidth: "1520px",
          width: "auto",
          height: "auto",
        }}
      >
        <div
          class="modal-content"
          style={{
            marginLeft: "15%",
            width: "80%",
            height: "fit-content",
          }}
        >
          <div class="modal-header">
            <h5 class="modal-title">Gross Profit Details</h5>
            <MdOutlineCancel
              size={20}
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            />
          </div>
          <div class="modal-body" style={{ border: "1px #ddd" }}>
            <div class="row ">
              <div class="col-sm-6">
                <p className="prg" style={{ fontSize: "18px" }}>
                  <strong>PRF Supplier</strong>
                </p>
                <PRF_Supplier data={data?.PRF ? data?.PRF : []} />
              </div>
              <div class="col-sm-6  ">
                <p className="prg" style={{ fontSize: "18px" }}>
                  <strong>Revenue</strong>
                </p>
                <Revenue data={data?.Revenue ? data?.Revenue : []} />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="form-cancel"
              data-dismiss="modal"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrossProfitModal;
