import React, { useState } from "react";
import attachmentIcon from "../../assets/SVG/attach.svg";
import FileChooser from "../common/FileChooser";
import SelectOption from "../common/SelectOption";

export default function ManufacturerForm(props) {
  
  const [pi, setPi] = useState({});
  const [manufacturer_id, setMAnufacturer] = useState();
  let obj = { manufacturer_id: manufacturer_id?.value, pi: pi };
  
  props.ManufacturerSetter[props?.count] = manufacturer_id;
  props.PiSetter[props?.count] = obj;
  function handleChange(e) {
    setPi(e?.target?.files?.[0]);
    
  }
  
  // // ////////
  const list = [];
  props?.manufacturer?.map((items) => {
    list.push({
      value: items?.id,
      label: items?.manufacturer_name,
    });
  });
  return (
    <div className="manufacturer-form">
      <div className="input-groups">
        <div className="input-label">
          {props?.type == "Product"
            ? "Manufacturer"
            : props?.type == "Service"
              ? "Service"
              : "Training"}
        </div>
        {/* //////////////// */}
        <SelectOption
          // className="select-option-create-order "
          options={list}
          setters={setMAnufacturer}
          value={manufacturer_id}
          isinput={true}
        />
        {/* //////////////// */}
        {/* <select
          id=""
          className="select-option-create-order "
          onChange={(e) => setMAnufacturer(e.target.value)}
        >
          <option selected="true" disabled="disabled">
            {props?.type == "Product"
              ? " Choose Manufacturer"
              : props?.type == "Service"
                ? " Choose Service provider"
                : "Choose Training provider"}
          </option>
          {props.manufacturer?.map((items) => (
            <option value={items.id}>{items.manufacturer_name}</option>
          ))}
        </select> */}
        <div className="input-label">PI Import</div>
        <div
          className="file-chooser cl-9 px-0"
          style={{ backgroundImage: `url(${attachmentIcon})` }}
        >
          <input
            id="chooser"
            required
            type={"file"}
            class="file-input"
            multiple
            onChange={(e) => {
              
              handleChange(e);
            }}
          />
          <label
            htmlFor="chooser"
            style={{
              position: "relative",
              top: "-28px",
              left: "5px",
              width: "10rem",
              // zIndex: "-1",
            }}
          >
            {pi
              ? pi?.name?.length > 16
                ? pi?.name?.slice(0, 15) + "..."
                : pi?.name
              : "Choose File"}
          </label>
        </div>
      </div>
    </div>
  );
}
