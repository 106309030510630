import React from "react";
import { useState } from "react";
import StockIndex from "../../../pages/Stocks/StockIndex";
import Assets from "./Assets";
import AssetDetail from "./AssetDetail";

export default function Index(props) {
  const [fragment, setFragment] = useState("index");
  const [data, setData] = useState();

  return (
    <StockIndex>
      {fragment === "index" ? (
        <Assets
          setMessages={props?.setMessage}
          setFragment={setFragment}
          setData={setData}
        />
      ) : fragment === "detail" ? (
        <AssetDetail setFragment={setFragment} data={data} />
      ) : (
        ""
      )}
    </StockIndex>
  );
}
