import Settingsidebar from "../../pages/Settings/SettingIndex";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import "./Settings.css";
import React, { useState } from "react";
// import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import CreateClientSettingModal from "./SettingModal/CreateClientSettingModal";
import CreateRepresentativeModal from "./SettingModal/CreateRepresentativeModal";
import AddIcon from "@mui/icons-material/Add";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  useGetSettingClientQuery,
  useGetSettingClientRepresentativeQuery,
} from "../../features/SCMApi";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import IconButton from "@mui/material/IconButton";
import DeleteClientModal from "./DeleteClientModal";
import DeleteRepresentativeModal from "./DeleteRepresentativeModal ";
import EditClientRepresentative from "./EditRepresentative";
import EditClient from "./EditClient";
import { Button, Pagination, Tooltip } from "@mui/material";
import ClientIndex from "../../pages/Clients/ClientIndex";
import NoRecord from "../common/NoRecord";
import usePagination from "../Pagination/Pagination";

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
   {}
  );

  return (
    <p className="setting-accordion-p mb-0" onClick={decoratedOnClick}>
      {children}
    </p>
  );
}

export default function SettingProjects(props) {
  const { data: clientInfo } = useGetSettingClientQuery();
  const { data: representaniveInfo } = useGetSettingClientRepresentativeQuery();

  const [createClientModal, setCreateclient] = useState(false);
  const [createRepresentativeModal, setCreaterepresentative] = useState(false);
  const [editRepresentativeModal, setEditrepresentative] = useState(false);
  const [editClientModal, setEditClient] = useState(false);

  function createClientHandler() {
    setCreateclient(true);
  }
  function createRepresentativeHandler() {
    setCreaterepresentative(true);
  }

  //Send Info for Representative edit modal
  const [representativeId, setRepresentativeId] = useState(null);
  const [repName, setRepName] = useState(null);
  const [repEmail, setRepEmail] = useState(null);
  const [repRole, setRepRole] = useState(null);
  const [repPhone, setRepPhone] = useState(null);
  const [repClientId, setRepClientId] = useState(null);

  //send info for Client edit modal
  const [clientId, setClientId] = useState(null);
  const [clientName, setClientName] = useState(null);
  const [clientPost, setClientPost] = useState(null);
  const [clientAddrese, setClientAddrese] = useState(null);
  const [clientTelephone, setClientTelephone] = useState(null);

  //Delete Client  toggle
  const [showClientDeleteModal, toggelClientDeleteModal] =
    React.useState(false);
  const toggleClientDeleteHandler = (e) => {
    toggelClientDeleteModal(!showClientDeleteModal);
  };

  //Delete Representative  toggle
  const [showRepresentativeDeleteModal, toggelRepresentativeDeleteModal] =
    React.useState(false);
  const toggleRepresentativeDeleteHandler = (e) => {
    toggelRepresentativeDeleteModal(!showRepresentativeDeleteModal);
  };

  let [page, setPage] = useState(1);

  const PER_PAGE = 5;
  const count = Math.ceil(clientInfo?.data?.length / PER_PAGE);
  const _DATA = usePagination(clientInfo?.data, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  const [search, setSearch] = useState("");
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
   
  };
  return (
    <ClientIndex
      handleSearchChange={handleSearchChange}
      placeholder={"search Client..."}
    >
      {clientInfo?.data?.length !== 0 ? (
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <p style={{ fontSize: "22px", fontWeight: "500" }}>Clients</p>
              <IconButton
                // size="small"
                sx={{
                  width: "40px",
                  height: "40px",
                  backgroundColor: "#064b4f",
                  color: "#ececec",
                  "&:hover": {
                    backgroundColor: "#186569",
                    color: "#ececec",
                  },
                }}
                onClick={createClientHandler}
              >
                <AddIcon style={{ fontSize: 30 }} />
              </IconButton>
            </div>
            <div className="divider"></div>
            <div className="setting-accordion-header">
              <p
                style={{
                  position: "relative",
                  left: "50px",
                }}
                className="headerStyle"
              >
                Name
              </p>
              <p className="headerStyle">Address</p>
              <p className="headerStyle">P.O.Box</p>
              <p className="headerStyle">Telephone</p>
              <p className=""></p>
            </div>
            {_DATA
              .currentData()
              ?.filter((items) => {
                if (props?.search === "") {
                  return items;
                } else if (items.client_name?.toLowerCase().includes(search)) {
                  return items;
                }
              })
              ?.map((info) => (
                <Accordion onClick={(e) => (setClientId(info?.id), 1)}>
                  <Card className="mb-2 mt-0">
                    <div className="bg-white card-header accordionHeader">
                      <CustomToggle
                        eventKey="0"
                        className="card-header-setting"
                      >
                        <div class="setting-representative-container">
                          <div class="representative-header">Client Name</div>
                          <p className="prg">{info?.client_name}</p>
                        </div>
                        <div class="setting-representative-container">
                          <div class="representative-header">Address</div>
                          <Tooltip title={info?.address}>
                            <p className="prg">
                              {info?.address?.length >= 30
                                ? info?.address.slice(0, 18) + "..."
                                : info?.address}
                            </p>
                          </Tooltip>
                        </div>
                        <div class="setting-representative-container">
                          <div class="representative-header">Postal Code</div>
                          <p className="prg">
                            {info?.postal_code === null
                              ? "N/A"
                              : info?.postal_code}
                          </p>
                        </div>
                        <div class="setting-representative-container">
                          <div class="representative-header">Telephone</div>
                          <p className="prg">{info?.telephone}</p>
                        </div>
                        <div className="d-flex gap-3"></div>
                      </CustomToggle>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <IconButton
                          variant="text"
                          size="small"
                          sx={{
                            zIndex: 1,
                            backgroundColor: "#fff",
                            color: "#52FFAC",
                            "&:hover": {
                              backgroundColor: "#52FFAC",
                              color: "#fff",
                            },
                            marginLeft: "-30px",
                            marginBottom: "3px",
                          }}
                          onClick={(e) => {
                            setClientName(info?.client_name);
                            setClientPost(info?.postal_code);
                            setClientId(info?.id);
                            setClientAddrese(info?.address);
                            setClientTelephone(info?.telephone);
                            setEditClient(true);
                          }}
                        >
                          <ModeEditOutlinedIcon />
                        </IconButton>

                        <IconButton
                          variant="text"
                          size="small"
                          sx={{
                            backgroundColor: "#fff",
                            color: "#FF5252",
                            "&:hover": {
                              backgroundColor: "#FF5252",
                              color: "#fff",
                            },
                            // marginLeft: "-20px",
                            marginBottom: "3px",
                          }}
                          // onClick={ toggleClientDeleteHandler}
                          onClick={(e) => {
                            setClientId(info?.id);
                            toggleClientDeleteHandler();
                          }}
                        >
                          <DeleteOutlinedIcon />
                        </IconButton>
                      </div>
                    </div>

                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        {representaniveInfo?.data
                          ?.filter((repInfo) => repInfo?.client_id === info?.id)
                          ?.map((rep) => (
                            <div className="border mb-2 accordionHeader bg-light">
                              <div className="p-4 settingRepr">
                                <div class="setting-representative-container">
                                  <div class="prg representative-header">
                                    FullName
                                  </div>
                                  <p className="prg">{rep?.full_name}</p>
                                </div>
                                <div class="setting-representative-container">
                                  <div class="prg representative-header">
                                    Email
                                  </div>
                                  <p className="prg">{rep?.email}</p>
                                </div>
                                <div class="setting-representative-container">
                                  <div class="prg representative-header">
                                    Role
                                  </div>
                                  <p className="prg">{rep?.role}</p>
                                </div>
                                <div class="setting-representative-container">
                                  <div class="prg representative-header">
                                    Phone Number
                                  </div>
                                  <ul className="list-inline">
                                    {rep?.c_r_phone_number?.map((phone) => (
                                      <li className="prg">
                                        {phone?.phone_number}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                              <div className="editDeleteButton">
                                <IconButton
                                  variant="text"
                                  size="small"
                                  sx={{
                                    zIndex: 1,
                                    width: "50px",
                                    height: "50px",
                                    backgroundColor: "#f8f9fa",
                                    color: "#52FFAC",
                                    "&:hover": {
                                      backgroundColor: "#52FFAC",
                                      color: "#fff",
                                    },
                                  }}
                                  onClick={(e) => {
                                    setRepresentativeId(rep?.id);
                                    setRepName(rep?.full_name);
                                    setRepEmail(rep?.email);
                                    setRepPhone(
                                      rep?.c_r_phone_number?.map(
                                        (phone) => phone?.phone_number
                                      )
                                    );
                                    setRepClientId(rep?.client_id);
                                    setRepRole(rep?.role);
                                    setEditrepresentative(true);
                                  }}
                                >
                                  <ModeEditOutlinedIcon />
                                </IconButton>

                                <IconButton
                                  variant="text"
                                  size="small"
                                  sx={{
                                    width: "50px",
                                    height: "50px",
                                    backgroundColor: "#f8f9fa",
                                    color: "#FF5252",
                                    "&:hover": {
                                      backgroundColor: "#FF5252",
                                      color: "#fff",
                                    },
                                  }}
                                  onClick={(e) => {
                                    setRepresentativeId(rep?.id);
                                    toggleRepresentativeDeleteHandler();
                                  }}
                                >
                                  <DeleteOutlinedIcon />
                                </IconButton>
                              </div>
                            </div>
                          ))}
                        <div className="d-flex justify-content-center mt-3 mb-3">
                          <Button
                            sx={{
                              textTransform: "none",
                              width: "fit-content",
                              backgroundColor: "#064b4f",
                              color: "#ececec",
                              borderRadius: "25px",
                              justifyContent: "center",
                              "&:hover": {
                                backgroundColor: "#186569",
                                color: "#ececec",
                              },
                            }}
                            onClick={createRepresentativeHandler}
                          >
                            <AddCircleOutlineIcon />
                            <span
                              style={{
                                fontWeight: "500",
                                marginLeft: "10px",
                              }}
                            >
                              Add New Representative
                            </span>
                          </Button>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              ))}
          </div>
          {createClientModal ? (
            <CreateClientSettingModal
              modal={setCreateclient}
              refreshe={useGetSettingClientQuery}
            />
          ) : (
            <></>
          )}
          {createRepresentativeModal ? (
            <CreateRepresentativeModal
              modal={setCreaterepresentative}
              refreshe={useGetSettingClientRepresentativeQuery}
              clientId={clientId}
            />
          ) : (
            <></>
          )}
          {editRepresentativeModal ? (
            <EditClientRepresentative
              modalToggle={setEditrepresentative}
              repName={repName}
              repEmail={repEmail}
              repRole={repRole}
              repPhone={repPhone}
              repClientId={repClientId}
              representativeId={representativeId}
            />
          ) : (
            <></>
          )}
          {editClientModal ? (
            <EditClient
              modalToggle={setEditClient}
              clientName={clientName}
              clientPost={clientPost}
              clientAddrese={clientAddrese}
              clientTelephone={clientTelephone}
              clientId={clientId}
            />
          ) : (
            <></>
          )}
          <DeleteClientModal
            show={showClientDeleteModal}
            handleDeleteModal={toggleClientDeleteHandler}
            clientId={clientId}
            refreshe={useGetSettingClientQuery}
          />
          <DeleteRepresentativeModal
            show={showRepresentativeDeleteModal}
            handleDeleteModal={toggleRepresentativeDeleteHandler}
            repId={representativeId}
            refreshe={useGetSettingClientQuery}
          />
          <div className="mt-3 mb-3">
            {count > 1 ? (
              <Pagination
                count={count}
                size="large"
                page={page}
                variant="outlined"
                shape="rounded"
                onChange={handleChange}
              />
            ) : (
              ""
            )}
          </div>{" "}
        </div>
      ) : (
        <NoRecord />
      )}
    </ClientIndex>
  );
}
