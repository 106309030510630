import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import EditBankButton from "./EditBankButton";
import { useGetUserInfoQuery } from "../../../../features/SCMApi";
import CheckPermission from "../../../common/checkPermission/checkPermission";
const ForexBankDetail = (props) => {
  const Bank = styled(Paper)(({ theme }) => ({
    height: "90px",
    [theme.breakpoints.between("md", "lg")]: {
      marginLeft: "35px",
      marginRight: "-45px",
      marginBottom: "-85%",
    },
    [theme.breakpoints.between("xs", "sm", "md")]: {
      marginBottom: "40px",
    },
    color: theme.palette.text.secondary,
    alignItems: "center",
    border: "1px solid #AEB8C9",
  }));
  const { data: userinfo } = useGetUserInfoQuery();

  return (
    <>
      <Bank elevation={0} sx={{ marginTop: "20px" }}>
        <div className="origin-group rmaHeading">
          <div
            className="rmaValue"
            style={{ fontSize: "16spx", marginTop: "10px" }}
          >
            Bank
          </div>
          <div
            className="origin-label"
            style={{ fontSize: "14px", marginTop: "10px" }}
          >
            {props.data?.map((forex) => forex?.forex_bank?.bank_name)}
            {CheckPermission("edit_forex_need_bank", userinfo) ? (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <EditBankButton
                  name="editBank"
                  passedValue={props.data?.map(
                    (forex) => forex?.forex_bank?.bank_name
                  )}
                  setSuccessMessage={props?.successMessage}
                  setMessage={props?.message}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Bank>
    </>
  );
};

export default ForexBankDetail;
