import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Supplier from "../../pages/Supplier";
import {
  useDeletesupplierBankMutation,
  useDeletesupplierRepersentativeMutation,
  useGetFrightForwarderQuery,
  useGetUserInfoQuery,
} from "../../features/SCMApi";
import RepresentativeModal from "./SupplierModal/RepresentativeModal";
import BankinformationModal from "./SupplierModal/BankInformationModal";
import FreightForwarderBankinformationModal from "./SupplierModal/FreightForwarderBankInformation";
import FreightForwarderRepresentativeModal from "./SupplierModal/FreightForwarderRepresetative";
import Select from "react-select";
import SupplierBankDetailEditModal from "./SupplierModal/SupplierBankDetailEditModal";
import SupplierRepresentativeEditModal from "./SupplierModal/SupplierRepresentativeEditModal";
import FreightForwarderEdit from "./FreightForwarderEditAddress";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteModal from "./SupplierModal/DeleteModal";
import usePagination from "@mui/material/usePagination/usePagination";
import { Pagination } from "@mui/material";
import { Check } from "@mui/icons-material";
import CheckPermission from "../common/checkPermission/checkPermission";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ConfirmDelete from "../common/ConfirmDelete";

export default function FreightForwarderDetail() {
  const [showaddress, setShowAddress] = useState(false);
  const [showBank, setShowBank] = useState(false);

  const [createRepresentatives, setRepresentative] = useState(false);
  function createRepresentativeHandler() {
    setRepresentative(true);
  }
  const [createBankinformation, setBankinformation] = useState(false);
  function createBankinformationHandler() {
    setBankinformation(true);
  }
  const params = useParams();
  const {
    data: Frightforwarderdetail,
    isError,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetFrightForwarderQuery(params.id);

  const [
    createSupplierManufatcurerEditModal,
    setCreatesupplierManufacturerEdit,
  ] = useState(false);
  const [EditSupplierRepersentativeEditModal, setEditsupplierRepresetative] =
    useState(false);
  function createRepresentativeHandler() {
    setRepresentative(true);
  }
  function editBankDetailHandler(items) {
    setEditsupplierRepresetative(true);
    setEditsupplierRepresetative(items);
  }
  function createSupplierEditManufacturerHandler(items) {
    setCreatesupplierManufacturerEdit(true);
    setCreatesupplierManufacturerEdit(items);
  }
  const [showClientDeleteModal, toggelClientDeleteModal] = useState(false);
  const toggleClientDeleteHandler = (e) => {
    toggelClientDeleteModal(!showClientDeleteModal);
  };
  const [fright_forwarders_id, setfright_forwarders_id] = useState(null);
  let [page, setPage] = useState(1);
  let [pagebank, setPagebank] = useState(1);
  const PER_PAGE = 5;
  const count = Math.ceil(
    Frightforwarderdetail?.data?.frieght_forwarder_representative?.length /
      PER_PAGE
  );
  const countbank = Math.ceil(
    Frightforwarderdetail?.data?.frieght_forwarder_bank?.length / PER_PAGE
  );
  const _DATA = usePagination(
    Frightforwarderdetail?.data?.frieght_forwarder_representative,
    PER_PAGE
  );
  const _DATABank = usePagination(
    Frightforwarderdetail?.data?.frieght_forwarder_bank,
    PER_PAGE
  );
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  const handleChangeBank = (e, p) => {
    setPagebank(p);
    _DATABank.jump(p);
  };
  const [messages, setMessages] = useState(null);
  const { data: userinfo } = useGetUserInfoQuery();
  const [id, setID] = useState();
  const [representative, setRepresentatives] = useState();
  const [deleteDialog, setDialog] = useState(false);
  const [isConfirmation, setConfirmation] = useState(false);
  const DeleteHandler = () => {
    setDialog(true);
  };
  const [deleteRepresentative, res] = useDeletesupplierRepersentativeMutation();
  const [deleteBankDialog, setBankDialog] = useState(false);
  const [deleteBank, ress] = useDeletesupplierBankMutation();

  const DeleteBankHandler = () => {
    setBankDialog(true);
  };
  console?.log(id, "hahah id");
  let deletedId = {
    id: id,
  };
  const actionRemove = () => {
    deleteRepresentative(deletedId)
      .unwrap()
      .then((response) => {
        
        setDialog(false);
        setMessages("Representative deleted successfully");
        // window.location.reload();
      })
      .then((error) => {
        
      });
  };
  const actionRemoveBank = () => {
    deleteBank(deletedId)
      .unwrap()
      .then((response) => {
        
        setBankDialog(false);
        setMessages("Bank information deleted successfully");
        // window.location.reload();
      })
      .then((error) => {
        
      });
  };
  return (
    <Supplier
      name={Frightforwarderdetail?.data?.frieght_forwarder_name}
      title={"Freight Forwarder"}
      navlink={"/supplier/frightforwarder"}
      actives={"active"}
      Message={messages}
    >
      <div className="every-detail-page">
        <div className="row my-4">
          <div className="col-md-12">
            <div className="card supplier-card-representative  mb-4">
              <div className="h5-pri mx-4 my-4">Address</div>
              {!showaddress && (
                <>
                  <div className="supplier-address-container text-center px-3">
                    <div className="d-flex flex-column">
                      <div className="h5">P.O BOX</div>
                      <div className=" plg mt-2 mb-1">
                        {Frightforwarderdetail?.data?.postal_code}
                      </div>
                    </div>

                    <div className="address-divider"></div>
                    <div className="d-flex flex-column">
                      <div className="h5">Website</div>
                      <div className="h5 mt-2 mb-3 prg">
                        {Frightforwarderdetail?.data?.website}
                      </div>
                    </div>
                    <div className="address-divider"></div>
                    <div className="d-flex flex-column">
                      <div className="h5">Address</div>
                      <div className=" plg mt-2 mb-1">
                        {Frightforwarderdetail?.data?.address}
                      </div>
                    </div>
                    <div className="address-divider"></div>
                    <div className="d-flex flex-column">
                      <div className="h5">City</div>
                      <div className=" plg mt-2 mb-1">
                        {Frightforwarderdetail?.data?.city}
                      </div>
                    </div>

                    <div className="address-divider"></div>
                    <div className="d-flex flex-column">
                      <div className="h5">Origin</div>
                      <div className=" plg mt-2 mb-1">
                        {
                          Frightforwarderdetail?.data?.frieght_forwarder_origin
                            ?.name
                        }
                      </div>
                    </div>
                  </div>
                  {CheckPermission("edit_freight_forwarder", userinfo) ? (
                    <div className="d-flex justify-content-end p-3">
                      <IconButton
                        variant="text"
                        size="medium"
                        sx={{
                          backgroundColor: "#186569",
                          color: "#fff",
                          border: "1px solid #186569",
                          "&:hover": {
                            backgroundColor: "#fff",
                            color: "#186569",
                            border: "1px solid #186569",
                          },
                          marginTop: "3%",
                        }}
                        onClick={() => setShowAddress((prev) => !prev)}
                      >
                        <ModeEditOutlinedIcon />
                      </IconButton>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}

              {showaddress && (
                <>
                  <FreightForwarderEdit
                    value={Frightforwarderdetail}
                    showaddress={setShowAddress}
                    setMessages={setMessages}
                  />
                </>
              )}
            </div>
          </div>
          <div className="col-md-12 mb-3">
            <div className="card supplier-card-representative bg-white shadow-sm">
              <div className="d-flex justify-content-between ">
                <div className="h5 mx-4 my-3">Contact Person </div>
              </div>
              <div className="card-body">
                {Frightforwarderdetail?.data?.frieght_forwarder_representative.map(
                  (items) => (
                    <div className="supplier-representative">
                      <div className="d-flex justify-content-between supplier-representative-sm p-3">
                        <div className="prg supplier-representative-container">
                          <div className="representative-header">Full Name</div>
                          {items.full_name}
                        </div>
                        <div className="prg supplier-representative-container">
                          <div className="representative-header">Email</div>
                          {items.email}
                        </div>

                        <div className="prg supplier-representative-container">
                          <div className="representative-header">
                            Phone number
                          </div>
                          {items.s_r_phone_number.map((item) => (
                            <>
                              {item.phone_number}
                              <br />
                            </>
                          ))}
                        </div>
                        {/* edit modal */}
                        <div className="supplier-representative-container">
                          {CheckPermission(
                            "edit_freight_contact_person",
                            userinfo
                          ) ? (
                            <>
                              <div className="prg representative-header">
                                Action
                              </div>
                              <IconButton
                                variant="text"
                                size="small"
                                sx={{
                                  zIndex: 1,
                                  backgroundColor: "#fff",
                                  color: "#186569",

                                  "&:hover": {
                                    backgroundColor: "#186569",
                                    color: "#fff",
                                  },
                                  marginLeft: "-30px",
                                  marginBottom: "3px",
                                }}
                                onClick={() =>
                                  createSupplierEditManufacturerHandler(items)
                                }
                              >
                                <ModeEditOutlinedIcon />
                              </IconButton>
                              <IconButton
                                variant="text"
                                size="small"
                                sx={{
                                  zIndex: 1,
                                  backgroundColor: "#fff",
                                  color: "#ff5252",

                                  "&:hover": {
                                    backgroundColor: "#ff5252",
                                    color: "#fff",
                                  },
                                  marginBottom: "3px",
                                }}
                                onClick={(e) => {
                                  DeleteHandler();
                                  setRepresentatives(items.full_name);
                                  setID(items?.id);
                                }}
                              >
                                <DeleteOutlineIcon />
                              </IconButton>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  )
                )}
                <div className="d-flex justify-content-between align-items-center mt-1 float-end">
                  {count > 1 ? (
                    <Pagination
                      count={count}
                      size="large"
                      page={page}
                      variant="outlined"
                      shape="rounded"
                      onChange={handleChange}
                    />
                  ) : (
                    ""
                  )}
                  {CheckPermission("add_freight_bank_detail", userinfo) ? (
                    <>
                      <IconButton
                        variant="text"
                        size="medium"
                        className=""
                        sx={{
                          backgroundColor: "#186569",
                          color: "#fff",
                          border: "1px solid #186569",

                          "&:hover": {
                            backgroundColor: "#fff",
                            color: "#186569",
                            border: "1px solid #186569",
                          },
                        }}
                        onClick={createRepresentativeHandler}
                      >
                        <AddIcon />
                      </IconButton>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 mb-3">
            <div className="card supplier-card-representative bg-white shadow-sm">
              <div className="h5-pri text-center my-2 ">Bank Detail</div>
              <div className="divider"></div>
              <div className="supplier-bank-header text-center p-3">
                <div className="prg fw-bold">Currency</div>
                <div className="prg fw-bold">Account Name</div>
                <div className="prg fw-bold">Account Number</div>
                <div className="prg fw-bold">Bank</div>
                <div className="prg fw-bold">Swift Code</div>
                <div className="prg fw-bold">IBAN</div>
                <div className="prg"></div>
              </div>
              <div className="card-body">
                <div className="d-grid gap-3 ">
                  {Frightforwarderdetail?.data?.frieght_forwarder_bank.map(
                    (items) =>
                      items?.isDeleted == 0 ? (
                        <div className="supplier-representative">
                          {!showBank && (
                            <>
                              <div className="supplier-bank-body text-center p-3">
                                <div className="prg supplier-representative-container">
                                  <div className="representative-header">
                                    Currency
                                  </div>
                                  {items.bank_currency}
                                </div>
                                <div className="prg supplier-representative-container">
                                  <div className="representative-header">
                                    Account Name
                                  </div>
                                  {items?.account_name === null
                                    ? "Unknown"
                                    : items?.account_name}
                                </div>
                                <div className="prg supplier-representative-container">
                                  <div className="representative-header">
                                    Account Number
                                  </div>
                                  {items.account_number}
                                </div>
                                <div className="prg supplier-representative-container">
                                  <div className="representative-header">
                                    Bank Name
                                  </div>
                                  {items.bank_name}
                                </div>
                                <div className="prg supplier-representative-container">
                                  <div className="representative-header">
                                    Swift code
                                  </div>
                                  {items.swift_code}
                                </div>
                                <div className="prg supplier-representative-container">
                                  <div className="representative-header">
                                    IBAN
                                  </div>
                                  {items.iban}
                                </div>
                                <div className="prg supplier-representative-container">
                                  {CheckPermission(
                                    "edit_freight_bank_detail",
                                    userinfo
                                  ) ? (
                                    <>
                                      <div className="representative-header">
                                        Action
                                      </div>
                                      <IconButton
                                        variant="text"
                                        size="small"
                                        sx={{
                                          zIndex: 1,
                                          backgroundColor: "#fff",
                                          color: "#186569",

                                          "&:hover": {
                                            backgroundColor: "#186569",
                                            color: "#fff",
                                          },

                                          marginBottom: "3px",
                                        }}
                                        onClick={() =>
                                          editBankDetailHandler(items)
                                        }
                                      >
                                        <ModeEditOutlinedIcon />
                                      </IconButton>
                                      <IconButton
                                        variant="text"
                                        size="small"
                                        sx={{
                                          zIndex: 1,
                                          backgroundColor: "#fff",
                                          color: "#ff5252",

                                          "&:hover": {
                                            backgroundColor: "#ff5252",
                                            color: "#fff",
                                          },
                                          marginBottom: "3px",
                                        }}
                                        onClick={(e) => {
                                          DeleteBankHandler();
                                          setRepresentatives(items.bank_name);
                                          setID(items?.id);
                                        }}
                                      >
                                        <DeleteOutlineIcon />
                                      </IconButton>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      ) : (
                        ""
                      )
                  )}
                </div>

                <div className="d-flex justify-content-between align-items-center mt-1 float-end">
                  {countbank > 1 ? (
                    <Pagination
                      count={countbank}
                      size="large"
                      page={pagebank}
                      variant="outlined"
                      shape="rounded"
                      onChange={handleChangeBank}
                    />
                  ) : (
                    ""
                  )}
                  {CheckPermission("add_freight_bank_detail", userinfo) ? (
                    <IconButton
                      variant="text"
                      size="medium"
                      sx={{
                        backgroundColor: "#186569",
                        color: "#fff",
                        border: "1px solid #186569",

                        "&:hover": {
                          backgroundColor: "#fff",
                          color: "#186569",
                          border: "1px solid #186569",
                        },
                      }}
                      onClick={createBankinformationHandler}
                    >
                      <AddIcon />
                    </IconButton>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          {CheckPermission("remove_freight_forwarder", userinfo) ? (
            <div
              className="d-flex justify-content-center
          mb-3"
            >
              <button
                type="submit"
                className="supplier-remove-btn"
                onClick={(e) => {
                  setfright_forwarders_id(params?.id);
                  toggleClientDeleteHandler();
                }}
              >
                Remove Freight Forward
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
        {createRepresentatives ? (
          <FreightForwarderRepresentativeModal
            modal={setRepresentative}
            setMessages={setMessages}
          />
        ) : (
          <></>
        )}
        {createBankinformation ? (
          <FreightForwarderBankinformationModal
            modal={setBankinformation}
            setMessages={setMessages}
          />
        ) : (
          <></>
        )}
        {createSupplierManufatcurerEditModal ? (
          <SupplierRepresentativeEditModal
            modal={setCreatesupplierManufacturerEdit}
            value={createSupplierManufatcurerEditModal}
            setMessages={setMessages}
          />
        ) : (
          <></>
        )}
        {EditSupplierRepersentativeEditModal ? (
          <SupplierBankDetailEditModal
            value={EditSupplierRepersentativeEditModal}
            modal={setEditsupplierRepresetative}
            setMessages={setMessages}
          />
        ) : (
          <></>
        )}
      </div>
      {deleteBankDialog ? (
        <ConfirmDelete
          action={actionRemoveBank}
          setters={setConfirmation}
          setDialog={setBankDialog}
          dialog={`Are You Sure to Delete ${representative} ?`}
        />
      ) : (
        ""
      )}
      {deleteDialog ? (
        <ConfirmDelete
          action={actionRemove}
          setters={setConfirmation}
          setDialog={setDialog}
          dialog={`Are You Sure to Delete ${representative} ?`}
        />
      ) : (
        ""
      )}
      <DeleteModal
        show={showClientDeleteModal}
        handleDeleteModal={toggleClientDeleteHandler}
        name={"fright-forwarders"}
        deletedId={fright_forwarders_id}
        navigationto={"supplier/frightforwarder"}
      />
    </Supplier>
  );
}
