import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import SettingIndex from "../../pages/Settings/SettingIndex";
import StoreSetting from "./StoreSetting";
import ToolSetting from "./ToolSetting";

export default function StockSettingTab() {
  return (
    <SettingIndex>
      <Tabs
        defaultActiveKey="store"
        id="uncontrolled-tab-example"
        className="mb-3 d-flex justify-content-center bg-white rounded gap-4 border"
      >
        <Tab eventKey="store" title="Store">
          <StoreSetting />
        </Tab>
        <Tab eventKey="inventory" title="Tools & Inventory" className="">
          <ToolSetting />
        </Tab>
      </Tabs>
    </SettingIndex>
  );
}
