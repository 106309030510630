import * as React from "react";
import MissedItemBatchCard from "../../../components/Logistics/MissedItem/MissedItemBatchCard";
import NavBar from "../../../Layout/NavBar";
import { NavLink } from "react-router-dom";
import LogisticsBreadCramp from "../../../components/Logistics/LogisticsRmaBreadCramp";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useGetMissedItemsQuery } from "../../../features/SCMApi";
import { useParams } from "react-router-dom";
import Index from "../Index/Index";
import { useState } from "react";
import usePagination from "../../../components/Pagination/Pagination";
import { Pagination } from "@mui/material";

export default function MissedItemIndex() {
  const { ProjectId } = useParams();
  const { data: missedItemList } = useGetMissedItemsQuery(ProjectId);
  const [search, setSearch] = useState("");

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  let [page, setPage] = React.useState(1);
  const PER_PAGE = 9;
  const count = Math.ceil(missedItemList?.data?.length / PER_PAGE);
  const _DATA = usePagination(missedItemList?.data, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <Index
      title={"Batches"}
      // name={selectedProject[0]?.[0]?.project_name}
      navlink={`/logistics/batches/${ProjectId}`}
      handleSearchChange={handleSearchChange}
      placeholder={"search Missed items..."}
    >
      <div className="">
        <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
          <Grid container spacing={{ xs: 2, md: 2 }}>
            {missedItemList?.data !== {} ? (
              missedItemList?.data
                .filter((items) => {
                  if (search === "") {
                    return items;
                  } else if (
                    items.missed_item_name?.toLowerCase().includes(search)
                  ) {
                    return items;
                  }
                })
                ?.map((missedItem) => (
                  <>
                    <Grid
                      item
                      marginRight={{ xs: "10px", sm: "0px", md: "0px" }}
                      marginLeft={{ xs: "10px", sm: "0px", md: "0px" }}
                      xs={12}
                      sm={6}
                      md={4}
                      lg={4}
                      xl={4}
                      xxl={4}
                      key={missedItem?.id}
                    >
                      <NavLink
                        to={`details/${missedItem?.id}`}
                        state={{ data: missedItem?.id }}
                      >
                        <MissedItemBatchCard data={missedItem} />
                      </NavLink>
                    </Grid>
                  </>
                ))
            ) : (
              <></>
            )}
          </Grid>
          {count > 1 ? (
            <Pagination
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                margin: "5px",
              }}
            />
          ) : (
            ""
          )}
        </Box>
      </div>
    </Index>
  );
}
