//<------------------------SCM-GoDADDY------------------->
export const API_BASE_URL = "https://scm-backend.ienetworks.co/api";
export const IMG_BASE_URL = "https://scm-backend.ienetworks.co";
export const DOC_BASE_URL = "https://scm-backend.ienetworks.co/api/";
export const LOGOUT_API = "https://scm-backend.ienetworks.co/api/logout";
export const LOGIN_WITH_EMAIL = "https://scm-backend.ienetworks.co/api/login";
export const PM =
  "https://pm-backend.ienetworks.co/v1/monthlyBudget/month/project";

// # Development server configuration
// export const API_BASE_URL = "http://172.16.32.94:8000/api";
// export const IMG_BASE_URL = "http://172.16.32.94:8000";
// export const DOC_BASE_URL = "http://172.16.32.94:8000/api/";
// export const LOGOUT_API = "http://172.16.32.94:8000/api/logout";
// export const LOGIN_WITH_EMAIL = "http://172.16.32.94:8000/api/login";
// export const PM =
//   "https://pm-back.ienetworks.co/v1/monthlyBudget/month/project";

export const LOGIN_REDIRECT_URL =
  "https://scm-backend.ienetworks.co/api/microsoft-graph/redirect";
export const LOGIN_CALLBACK =
  "https://scm-backend.ienetworks.co/api/o365callback";
export const LOGIN_REDIRECT_URL_GOOGLE =
  "https://scm-backend.ienetworks.co/api/google/redirect";
export const LOGIN_CALLBACK_GOOGLE =
  "https://scm-backend.ienetworks.co/api/googlecallback";
