import { Tooltip } from "@mui/material";
import React from "react";
import { API_BASE_URL } from "../../api/endPoint";
import { useGetPoGroupDocumentQuery } from "../../features/SCMApi";

import NoRecord from "../common/NoRecord";

export default function PoDocument(props) {
  const { data: PoGroupDoc } = useGetPoGroupDocumentQuery(props.SelectedOrder);

  return (
    <>
      <div style={{ margin: "10px 30px" }}>
        <span
          style={{
            color: "#353945",
            fontWeight: "500",
            fontSize: "16px",
            marginLeft: "10px",
          }}
        >
          PO Document
        </span>
      </div>
      {PoGroupDoc?.data?.length !== 0 ? (
        <>
          {PoGroupDoc?.data?.map((items) => (
            <>
              <div
                style={{
                  border: "2px #ECECEC solid",
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "5px auto",
                  padding: "3px",
                  alignSelf: "center",
                  width: "90%",
                }}
              >
                <span
                  style={{
                    marginLeft: "4%",
                    marginTop: "5px",
                    color: "#353945",
                    fontWeight: "400",
                    fontSize: "14px",
                  }}
                >
                  {items?.group_name}
                </span>
                <div>
                  <Tooltip title="Download">
                    <a
                      href={`${API_BASE_URL}/scm/sourcing/document-preparation/generatepo/${items?.id}`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#186569"
                        class="bi bi-download"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                      </svg>
                    </a>
                  </Tooltip>
                </div>
              </div>
            </>
          ))}
        </>
      ) : (
        <NoRecord />
      )}
    </>
  );
}
