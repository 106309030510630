import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { ArcElement } from "chart.js";

import { Pie } from "react-chartjs-2";
import {
  useGetForexPaymentTrackingProjectsQuery,
  useGetForexPaymentTrackingVendorQuery,
  useGetManufacturersQuery,
  useGetPaymentTrackingForProjectQuery,
  useGetPaymentTrackingForVendorQuery,
  useGetProjectsQuery,
  useGetSuppliersQuery,
} from "../../features/SCMApi";
import NoRecord from "../common/NoRecord";
import ChartDataLabels from "chartjs-plugin-datalabels";
import context from "react-bootstrap/esm/AccordionContext";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  ChartDataLabels
);

const options = {
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
  plugins: {
    legend: {
      display: true,
      position: "right",
    },
    datalabels: {
      formatter: (value, context) => {
        const datapoints = context.chart.data.datasets[0].data;
        function totalSum(total, datapoint) {
          return total + datapoint;
        }
        const totalValue = datapoints.reduce(totalSum, 0);
        const percentageValue = ((value / totalValue) * 100).toFixed(1);
        const display = `${percentageValue}%`;
        return display;
      },
      display: true,
      align: "center",
      borderRadius: 3,
      color: "#000",
      font: {
        size: 12,
      },
    },
  },
};

export default function PaymentTrackingDashboard(props) {
  const { data: response } = useGetForexPaymentTrackingProjectsQuery();

  const [project, setProjects] = useState();

  const [vendorId, setVendor] = useState(
    "240d8ca5-9c2f-488c-b2ae-48ddab95107b"
  );
  const { data: supplier } = useGetManufacturersQuery();
  const { data: PaymentTrackingProject } =
    useGetPaymentTrackingForProjectQuery(project);
  const { data: PaymentTrackingVendor } =
    useGetPaymentTrackingForVendorQuery(vendorId);
  const { data: VendorList } = useGetForexPaymentTrackingVendorQuery();

  const [VendororProject, setVendororProject] = useState(0);
  function onChangeValue(event) {
    setVendororProject(event.target.value);
  }
  let totalPaymentvendor =
    Math.round(
      (PaymentTrackingVendor?.data
        ?.filter((pro) => pro?.id === vendorId)
        ?.map((totalPayment) => Number(totalPayment?.total_amount))
        ?.filter((e) => typeof e == "number")
        ?.reduce((prev, acc) => prev + acc, 0) +
        Number.EPSILON) *
        100
    ) / 100;

  let paidAmountvendor =
    Math.round(
      (PaymentTrackingVendor?.data
        ?.filter((pro) => pro?.id === vendorId)
        ?.map((totalPayment) => Number(totalPayment?.paid_amount))
        ?.filter((e) => typeof e == "number")
        ?.reduce((prev, acc) => prev + acc, 0) +
        Number.EPSILON) *
        100
    ) / 100;
  let balancePaymentvendor =
    Number(totalPaymentvendor) - Number(paidAmountvendor);

  let totalPayment =
    Math.round(
      (PaymentTrackingProject?.data
        ?.filter((pro) => pro?.id === project)
        ?.map((totalPayment) => Number(totalPayment?.total_amount))
        ?.filter((e) => typeof e == "number")
        ?.reduce((prev, acc) => prev + acc, 0) +
        Number.EPSILON) *
        100
    ) / 100;
  let paidAmount =
    Math.round(
      (PaymentTrackingProject?.data
        ?.filter((pro) => pro?.id === project)
        ?.map((totalPayment) => Number(totalPayment?.paid_amount))
        ?.filter((e) => typeof e == "number")
        ?.reduce((prev, acc) => prev + acc, 0) +
        Number.EPSILON) *
        100
    ) / 100;
  let balancePayment = Number(totalPayment) - Number(paidAmount);
  

  const [projectCheckbox, setProjectCheckbox] = React.useState(true);
  const [vendorCheckbox, setVendorCheckbox] = React.useState(false);
  const [checked1, setChecked1] = React.useState(false);
  const [checked2, setChecked2] = React.useState(false);

  const data = {
    labels: ["Total Payment", "Balance Payment", "Paid Amount"],
    label: projectCheckbox
      ? [totalPayment, balancePayment, paidAmount]
      : [totalPaymentvendor, balancePaymentvendor, paidAmountvendor],
    datasets: [
      {
        data: projectCheckbox
          ? [totalPayment, balancePayment, paidAmount]
          : [totalPaymentvendor, balancePaymentvendor, paidAmountvendor],
        backgroundColor: ["#AEB8C9", "#04D7D7", "#FABB06"],
        hoverOffset: 4,
      },
    ],
  };
  

  return (
    <div
      className={props?.Notask ? "dashboard-card" : "no-task-dashboard-card"}
    >
      <div className="title-container-payment">
        <div className="dashboard-title ps-3 pt-2">Payment Tracking</div>

        <FormControl sx={{ marginTop: "10px", backgroundColor: "#fff" }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            defaultValue="frightForwarder"
          >
            <FormControlLabel
              sx={{
                color: "#AEB8C9",
                ".MuiFormControlLabel-label": checked1 === true && {
                  color: "#186569",
                },
              }}
              value="frightForwarder"
              control={
                <Radio
                  onChange={(e) => {
                    setChecked1(true);
                    setChecked2(false);
                    setProjectCheckbox(true);
                  }}
                  sx={{
                    color: "#AEB8C9",
                    "&.Mui-checked": {
                      color: "#186569",
                    },
                  }}
                />
              }
              label={
                <div className="shipment-arra-radio-button-lable">Project</div>
              }
            />

            <FormControlLabel
              sx={{
                color: "#AEB8C9",
                ".MuiFormControlLabel-label": checked2 === true && {
                  color: "#186569",
                },
              }}
              value="byVendor"
              control={
                <Radio
                  onClick={(e) => setProjectCheckbox(false)}
                  onChange={(e) => {
                    setChecked1(false);
                    setChecked2(true);
                    setVendorCheckbox(true);
                  }}
                  sx={{
                    color: "#AEB8C9",
                    "&.Mui-checked": {
                      color: "#186569",
                    },
                  }}
                />
              }
              label={
                <div className="shipment-arra-radio-button-lable">Vendor </div>
              }
            />
          </RadioGroup>
        </FormControl>
        {projectCheckbox ? (
          <select
            name=""
            id=""
            className="project-select-option mt-2 me-3"
            required
            value={project}
            onChange={(e) => setProjects(e.target.value)}
          >
            <option value="" disabled selected hidden>
              Select Project
            </option>
            {PaymentTrackingProject?.data?.map((items) => (
              <option value={items.id}>{items.project_name}</option>
            ))}
          </select>
        ) : vendorCheckbox ? (
          <select
            name=""
            id=""
            className="project-select-option mt-2 me-3"
            required
            value={vendorId}
            onChange={(e) => setVendor(e.target.value)}
          >
            <option value="" disabled selected hidden>
              Select Vendor
            </option>
            {VendorList?.data?.map((items) => (
              <option value={items.id}>{items.distributor_name}</option>
            ))}
          </select>
        ) : (
          ""
        )}
      </div>

      <div className="divider"></div>

      {/* {totalPayment != 0 || balancePayment != 0 || paidAmount != 0 ? ( */}
      <div
        style={{
          height: "400px",
          width: " 400px",
          padding: "13px",
          position: "relative",
          top: props?.Notask ? "-65px" : "-40px",
        }}
      >
        {projectCheckbox ? (
          totalPayment != 0 || balancePayment != 0 || paidAmount != 0 ? (
            <div className="title-container-payment">
              <Pie options={options} data={data} />
              <div className="d-flex gap-2">
                {data?.datasets?.map((items, index) => (
                  <div className=" payment-count">
                    {items?.backgroundColor?.map((colors, cindex) => (
                      <div className="d-flex gap-2 align-items-center">
                        <div
                          style={{
                            width: "40px",
                            height: "12px",
                            background: `${colors}`,
                          }}
                        ></div>
                        <div style={{ color: "#AEB8C9", fontSize: 14 }}>
                          {items?.data[cindex]?.toLocaleString()}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "grid",
                height: props?.Notask ? "320px" : "220px",

                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <NoRecord />
            </div>
          )
        ) : totalPaymentvendor != 0 ||
          balancePaymentvendor != 0 ||
          paidAmountvendor != 0 ? (
          <div className="title-container-payment">
            <Pie options={options} data={data} />
            <div className="d-flex gap-2">
              {data?.datasets?.map((items, index) => (
                <div className=" payment-count">
                  {items?.backgroundColor?.map((colors, cindex) => (
                    <div className="d-flex gap-2 align-items-center">
                      <div
                        style={{
                          width: "40px",
                          height: "12px",
                          background: `${colors}`,
                        }}
                      ></div>
                      <div style={{ color: "#AEB8C9", fontSize: 14 }}>
                        {items?.data[cindex]?.toLocaleString()}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "grid",
              height: "220px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NoRecord />
          </div>
        )}
      </div>
    </div>
  );
}
