import * as React from "react";

import axios from "axios";
import "../../components/Logistics/logistics.css";

import DatePicker from "../Payment/ForexPlanning/DatePicker";
import {
  useGetForexAirLoadingPortQuery,
  useGetForexDetailQuery,
  useGetForexDischargePortQuery,
  useGetForexIncotermsQuery,
  useGetForexLoadingOriginQuery,
  useGetForexOpeningToQuery,
  useGetForexPaymentModeQuery,
  useGetForexShipmentModeQuery,
  useGetSeaLoadingPortQuery,
  useUpdateTermsAndConditionsMutation,
} from "../../features/SCMApi";
import Select from "../../components/Payment/ForexApproval/Select";
import { useParams } from "react-router-dom";

const EditTermAndConditionLogistics = (props) => {
  const params = useParams();
  const forexId = props?.forexId;
  const [updateTerm] = useUpdateTermsAndConditionsMutation();
  const { data: forexDetail } = useGetForexDetailQuery(forexId);
  const { data: paymentMode } = useGetForexPaymentModeQuery();
  const { data: openingSupplier } = useGetForexOpeningToQuery();
  const { data: modeOfShipment } = useGetForexShipmentModeQuery();
  const { data: loadingOrigin } = useGetForexLoadingOriginQuery();
  const { data: airportLoading } = useGetForexAirLoadingPortQuery();
  const { data: seaportLoading } = useGetSeaLoadingPortQuery();
  const { data: portOfDischarge } = useGetForexDischargePortQuery();
  const { data: incoterms } = useGetForexIncotermsQuery();

  const forexTermId = forexDetail?.data
    .map((forexData) =>
      forexData?.forex_term?.map((forexTermData) => forexTermData?.id)
    )
    .flat();

  //Previous Data
  const previousPaymentModeData = props?.previousData?.map(
    (value) => value?.forex_payment_mode?.payment_mode
  );
  const previousOpeningToData = props?.previousData?.map(
    (value) => value?.opening_to
  );
  const previousOpeningDateData = props?.previousData?.map(
    (value) => value?.opening_date
  );
  const previousModeOfShipmentData = props?.previousData?.map(
    (value) => value?.forex_shipment_mode?.shipment_mode
  );
  const previousTransShipmentData = props?.previousData?.map(
    (value) => value?.trans_shipment
  );
  const previousPartialShipmentData = props?.previousData?.map(
    (value) => value?.partial_shipment
  );
  const previousCountryOfOriginData = props?.previousData?.map(
    (value) => value?.forex_origin?.name
  );

  const previousPortOfLoadingData = props?.previousData?.map((value) =>
    value?.forex_sea_loading !== null
      ? value?.forex_sea_loading?.port_name
      : value?.air_loading_id !== null
        ? value?.forex_air_loading?.port_name
        : ["Unspecified"]
  );
  const previousDischargingPort = props?.previousData?.map(
    (value) => value?.forex_discharge_port?.port_name
  );
  const previousFreight = props?.previousData?.map((value) => value?.freight);
  const previousIncoterm = props?.previousData?.map(
    (value) => value?.incoterms?.incoterm_name
  );

  //Previous Value

  const previousPaymentMode = paymentMode?.data
    ?.filter(
      (modeOfPayment) =>
        modeOfPayment?.payment_mode === previousPaymentModeData?.toString()
    )
    .map((value) => value.id);
  const previousOpeningTo = openingSupplier?.data
    ?.filter(
      (distributer) =>
        distributer?.distributor_name === previousOpeningToData?.toString()
    )
    .map((id) => id?.distributor_name);

  const previousOpeningDate = props?.previousData?.map(
    (value) => value?.opening_date
  );

  const previousModeOfShipment = modeOfShipment?.data
    ?.filter(
      (shipmentMode) =>
        shipmentMode?.shipment_mode === previousModeOfShipmentData?.toString()
    )
    .map((id) => id?.id);
  const previousCountryOfOrigin = loadingOrigin?.data
    ?.filter(
      (origin) => origin?.name === previousCountryOfOriginData?.toString()
    )
    .map((id) => id?.id);
  const previousAirPort = airportLoading?.data
    ?.filter(
      (airPort) => airPort?.port_name === previousPortOfLoadingData?.toString()
    )
    .map((id) => id?.id);
  const previousSeaPortData = seaportLoading?.data
    ?.filter(
      (seaport) => seaport?.port_name === previousPortOfLoadingData?.toString()
    )
    .map((id) => id?.id);
  const previousDischargingPortData = portOfDischarge?.data
    ?.filter(
      (airPort) => airPort?.port_name === previousDischargingPort?.toString()
    )
    .map((id) => id?.id);

  const [paymentModeValue, setPaymentModeValue] = React.useState(null);
  const [openingDate, setOpeningDate] = React.useState(null);
  const [openingTo, setOpeningTo] = React.useState(null);
  const [shipmentMode, setShipmentMode] = React.useState(
    props?.previousData
      ?.map((value) => value?.forex_shipment_mode?.shipment_mode)
      ?.flat()
      ?.toString()
  );
  const [transShipment, setTransShipment] = React.useState(
    props?.previousData?.map((value) => value?.trans_shipment)?.toString()
  );
  const [partialShipment, setPartialShipment] = React.useState(
    props?.previousData?.map((value) => value?.partial_shipment)?.toString()
  );
  const [countryOfOrigin, setCountryOfOrigin] = React.useState(
    props?.previousData
      ?.map((value) => value?.forex_origin?.name)
      ?.flat()
      ?.toString()
  );
  const [portOfLoading, setPortOfLoading] = React.useState(null);
  const [dischargePort, setDischargePort] = React.useState(null);
  const [freightId, setFreightId] = React.useState(
    props?.previousData?.map((value) => value?.freight)
  );
  const [incoterm, setIncoterm] = React.useState(
    props?.previousData?.map((value) => value?.incoterms?.incoterm_name)
  );

  const origin = loadingOrigin?.data
    ?.filter((origin) => origin?.name?.includes(countryOfOrigin))
    ?.map((id) => id?.id)
    ?.flat()
    ?.toString();

  function HandleCancel() {
    props.ToggleModal(false);
  }
  // ${forexTermId.toString()}

  const HandleSubmit = async (e) => {
    let param = {
      id: params?.batchId,
      item_description: null,
      payment_mode_id:
        paymentModeValue === null
          ? previousPaymentMode?.toString()
          : paymentMode?.data
              ?.filter((modeOfPayment) =>
                modeOfPayment?.payment_mode.includes(paymentModeValue)
              )
              .map((id) => id?.id)
              ?.flat()
              ?.toString(),
      opening_date:
        openingDate !== null
          ? `${openingDate?.$y}-${openingDate?.$M + 1}-${openingDate?.$D}`
          : previousOpeningDate?.toString(),
      opening_to:
        openingTo === null
          ? previousOpeningTo?.toString()
          : openingSupplier?.data
              ?.filter((airPort) =>
                airPort?.distributor_name?.includes(openingTo)
              )
              ?.map((id) => id?.id)
              ?.flat()
              ?.toString(),
      shipment_mode_id:
        shipmentMode === null
          ? previousModeOfShipment?.toString()
          : modeOfShipment?.data
              ?.filter((modeOfShipment) =>
                modeOfShipment?.shipment_mode.includes(shipmentMode)
              )
              ?.map((id) => id?.id)
              ?.flat()
              ?.toString(),
      forex_id: forexId?.id,
      trans_shipment: transShipment,
      partial_shipment: partialShipment,
      loading_origin_id:
        countryOfOrigin === null
          ? previousCountryOfOrigin?.toString()
          : origin?.includes(",33") === true
            ? origin.replace(",33", "")
            : origin?.includes(",33") === false
              ? origin
              : "",
      air_loading_id:
        portOfLoading === null
          ? previousAirPort?.toString()
          : airportLoading?.data
              ?.filter((airPort) => airPort?.port_name?.includes(portOfLoading))
              ?.map((id) => id?.id)
              ?.flat()
              ?.toString(),
      sea_loading_id:
        portOfLoading !== null
          ? previousSeaPortData.toString()
          : seaportLoading?.data
              ?.filter((seaport) => seaport?.port_name?.includes(portOfLoading))
              ?.map((id) => id?.id)
              ?.flat()
              ?.toString(),
      discharge_port_id:
        dischargePort === null
          ? previousDischargingPortData?.toString()
          : portOfDischarge?.data
              ?.filter((airPort) => airPort?.port_name.includes(dischargePort))
              ?.map((id) => id?.id)
              ?.flat()
              ?.toString(),
      incoterm_id:
        previousIncoterm === null
          ? previousIncoterm?.toString()
          : incoterms?.data
              ?.filter((incot) => incot?.incoterm_name.includes(incoterm))
              ?.map((id) => id?.id)
              ?.flat()
              ?.toString(),
      freight: freightId?.toString(),
    };
    e.preventDefault();
    updateTerm(param)
      .unwrap()
      .then((response) => {
        HandleCancel();
        // alert("Term and condition edited successfully!!!");

        props?.onTermsUpdated();
        props?.ToggleModal(false);
      })
      .catch((error) => {});
  };

  const transShipmentData = ["Allowed", "Not allowed"];
  const partialShipmentData = ["Allowed", "Not allowed"];
  const freight = ["Freight on board", "Freight on destination"];

  return (
    <>
      <div id="myModal" className="modal" onClick={HandleCancel}>
        <div
          className="mini-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="logisticsRma-create-project-modal-title">
            <div className="AddNew">Edit Terms</div>
            <div onClick={HandleCancel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="42"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>

          <form
            className="form-container paymentForexApprovalinitiateForex"
            onSubmit={HandleSubmit}
          >
            <div className="form-card-container">
              <div className="form-card-information">
                <div className="card-center">
                  <div className="card-form">
                    <div className="input-labels">
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "16px" }}
                      >
                        Payment Mode
                      </div>

                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "10px" }}
                      >
                        Opening Date
                      </div>

                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "14px" }}
                      >
                        Opening to
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "9px" }}
                      >
                        Mode of Shipment
                      </div>

                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "5px" }}
                      >
                        Trans Shipment
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "10px" }}
                      >
                        Partial Shipment
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "5px" }}
                      >
                        Country of Origin
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "7px" }}
                      >
                        Port of Loading
                      </div>
                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "5px" }}
                      >
                        Port of Discharge
                      </div>

                      <div
                        className="rmaFileInputLabel"
                        style={{ marginTop: "5px" }}
                      >
                        Incoterm
                      </div>
                    </div>
                    <div className="forex-input-fields">
                      <div>
                        <Select
                          name={paymentMode?.data?.map(
                            (modeOfPayment) => modeOfPayment?.payment_mode
                          )}
                          passedValue={previousPaymentModeData?.toString()}
                          onChange={setPaymentModeValue}
                        />
                      </div>
                      <div style={{ marginLeft: "-4px" }}>
                        <DatePicker
                          onChange={setOpeningDate}
                          passedValue={previousOpeningDateData}
                        />
                      </div>
                      <div>
                        <Select
                          name={openingSupplier?.data?.map(
                            (supplier) => supplier?.distributor_name
                          )}
                          passedValue={previousOpeningToData}
                          onChange={setOpeningTo}
                        />
                      </div>
                      <div>
                        <Select
                          name={modeOfShipment?.data?.map(
                            (shipmentMode) => shipmentMode?.shipment_mode
                          )}
                          passedValue={previousModeOfShipmentData}
                          onChange={setShipmentMode}
                        />
                      </div>
                      <div>
                        <Select
                          name={transShipmentData}
                          passedValue={previousTransShipmentData}
                          onChange={setTransShipment}
                        />
                      </div>
                      <div>
                        <Select
                          name={partialShipmentData}
                          passedValue={previousPartialShipmentData}
                          onChange={setPartialShipment}
                        />
                      </div>
                      <div>
                        <Select
                          name={loadingOrigin?.data?.map(
                            (origin) => origin?.name
                          )}
                          passedValue={previousCountryOfOriginData}
                          onChange={setCountryOfOrigin}
                        />
                      </div>
                      <div>
                        <Select
                          name={
                            shipmentMode === "Air"
                              ? countryOfOrigin !== null
                                ? airportLoading?.data
                                    ?.filter(
                                      (airPort) =>
                                        airPort?.country?.toLowerCase() ===
                                        countryOfOrigin?.toLowerCase()
                                    )
                                    .map((airPort) => airPort?.port_name)
                                    ?.flat()
                                : ["Not yet"]
                              : shipmentMode === "Sea"
                                ? seaportLoading?.data
                                    ?.filter(
                                      (seaPort) =>
                                        seaPort?.country === countryOfOrigin
                                    )
                                    .map((seaPort) => seaPort?.port_name)
                                    ?.flat()
                                : ["Please select shipment mode"]
                          }
                          passedValue={previousPortOfLoadingData}
                          onChange={setPortOfLoading}
                        />
                      </div>
                      <div>
                        <Select
                          name={portOfDischarge?.data?.map(
                            (dischargePort) => dischargePort?.port_name
                          )}
                          passedValue={previousDischargingPort}
                          onChange={setDischargePort}
                        />
                      </div>

                      <div>
                        <Select
                          name={incoterms?.data?.map(
                            (incoterm) => incoterm?.incoterm_name
                          )}
                          passedValue={previousIncoterm}
                          onChange={setIncoterm}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-buttons clientForm">
                <button
                  type="cancel"
                  className="form-cancel clientCancel"
                  style={{ width: "112px", height: "32px" }}
                  onClick={HandleCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="form-save clientSave"
                  style={{ width: "112px", height: "32px" }}
                  onClick={HandleSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditTermAndConditionLogistics;
