import React from "react";
import { Workbook } from "exceljs";
import saveAs from "file-saver";

const GeneralReport = ({ data, type, is_tool, is_return }) => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(today.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  let title = "IE NETWORKS SOLUTIONS PLC";
  let title2 = type;
  let title3 = `General Report at ${formattedDate}`;
  let count = 0;

  const downloadExcel = async () => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet();

    worksheet.mergeCells("A1:F1");
    worksheet.mergeCells("A2:F2");
    worksheet.mergeCells("A3:F3");
    const titleCell = worksheet.getCell("A1");
    const titleCell2 = worksheet.getCell("A2");
    const titleCell3 = worksheet.getCell("A3");

    titleCell.value = title;
    titleCell.alignment = { horizontal: "center", vertical: "middle" };
    titleCell2.value = title2;
    titleCell2.alignment = { horizontal: "center", vertical: "middle" };
    titleCell3.value = title3;
    titleCell3.alignment = { horizontal: "center", vertical: "middle" };

    titleCell3.font = {
      name: "Times New Roman",
    };
    titleCell3.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "8EA9DB" },
    };
    titleCell2.font = {
      name: "Times New Roman",
    };
    titleCell2.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "8EA9DB" },
    };
    titleCell.font = {
      name: "Times New Roman",
    };

    titleCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "8EA9DB" },
    };

    // Create the table headers
    const headerRow = worksheet.addRow([
      "No",
      "Inventory Type/Category",
      "UOM",
      "Quantity",
      "Frozen",
      "Available",
    ]);
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "BFBFBF" },
      };
      cell.font = {
        color: { argb: "000000" },
        bold: true,
        size: 12,
        name: "Times New Roman",
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });

    // Set column widths
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 30;
    worksheet.getColumn(6).width = 30;

    // Set the header row to bold
    worksheet.getRow(2).font = { bold: true };
    worksheet.getRow(4);

    // Populate the table with data from the map
    data?.map((items) => {
      const dataRow = worksheet?.addRow([
        (count += 1),
        items?.category
          ? items?.category?.category
            ? items?.category?.category
            : items?.category?.name
          : "-",
        items?.uom ? items?.uom?.name : "-",
        items?.amount ? items?.amount : "-",
        items?.frozen_amount ? items?.frozen_amount : "0",
        items?.aviliable ? items?.aviliable : "0",
      ]);
      dataRow.eachCell((cell) => {
        cell.border = {
          top: { style: "thin", color: { argb: "000000" } },
          left: { style: "thin", color: { argb: "000000" } },
          bottom: { style: "thin", color: { argb: "000000" } },
          right: { style: "thin", color: { argb: "000000" } },
        };
      });
    });

    // const FooterRow = worksheet.addRow([
    //   " ",
    //   "Total",
    //   PlannedTotalCost,
    //   " ",
    //   " ",
    //   ActualTotalCost,
    //   " ",
    //   " ",
    //   " ",
    //   " ",
    //   ]);
    //   FooterRow.eachCell((cell) => {
    //     cell.fill = {
    //       type: "pattern",
    //       pattern: "solid",
    //       fgColor: { argb: "BFBFBF" },
    //     };
    //     cell.font = {
    //       color: { argb: "000000" },
    //       bold: true,
    //       size: 12,
    //       name: "Times New Roman",
    //     };
    //     cell.border = {
    //       top: { style: "thin", color: { argb: "000000" } },
    //       left: { style: "thin", color: { argb: "000000" } },
    //       bottom: { style: "thin", color: { argb: "000000" } },
    //       right: { style: "thin", color: { argb: "000000" } },
    //     };
    //     cell.alignment = {
    //       vertical: "middle",
    //       horizontal: "right",
    //       wrapText: true,
    //     };
    //   });

    // Save the workbook and download the Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `General ${type}`);
  };

  return (
    <button onClick={downloadExcel} className="create-project-btn">
      General Report
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14V11H2V14H14V11H16V14C16 14.55 15.8043 15.021 15.413 15.413C15.021 15.8043 14.55 16 14 16H2ZM8 12L3 7L4.4 5.55L7 8.15V0H9V8.15L11.6 5.55L13 7L8 12Z"
          fill="white"
        />
      </svg>
    </button>
  );
};

export default GeneralReport;
