import React, { useState } from "react";
import Select from "react-select";

function SelectOption({ options, setters, value, isinput }) {
  function handleChange(value) {
    setters(value);
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: isinput == true ? 250 : 300,
      height: isinput == true ? 30 : null,
      backgroundColor: state.isFocused ? "#fff" : "#f3f3f3",
      borderColor: state.isFocused ? "#80bdff" : "#ced4da",
      color: "#333",
    }),
  };

  return (
    <Select
      options={options}
      value={value}
      onChange={handleChange}
      styles={customStyles}
    />
  );
}

export default SelectOption;
