import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { InputLabel } from "@mui/material";
import {
  useSelectForexNeedBankMutation,
  useGetBanksQuery,
  useSelectSupplierPaymentBankMutation,
} from "../../../features/SCMApi";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 180,
      color: "#186569",
      marginTop: -10,
    },
  },
  disableScrollLock: true,
};

export default function Selector(props) {
  const style = {
    width: props?.style?.width ? props?.style?.width : 140,
    height: 20,
    backgroundColor: "#fff",
    fontStyle: "normal",
    fontWeight: props?.style?.fontWeight ? props?.style?.fontWeight : 400,
    fontSize: props?.style?.fontSize ? props?.style?.fontSize : "11px",
    lineHeight: props?.style?.lineHeight ? props?.style?.lineHeight : "14px",
    letterSpacing: "0.105em",
    color: props?.style?.color ? props?.style?.color : "#353945",
    marginLeft: props?.style?.marginLeft,
    marginTop: props?.style?.marginTop,

    "& .MuiOutlinedInput-notchedOutline": {
      border: props?.style?.border ? props?.style?.border : "none",
    },
    // "& .MuiOutlinedInput-input": {
    //   marginTop: props?.style?.marginTop,
    // },
  };
  const { data: previousBankData } = useGetBanksQuery();

  const [value, setValue] = React.useState(null);
  const [addSelectedBank] = useSelectSupplierPaymentBankMutation();

  const handleChange = async (event) => {
    setValue(event.target.value);
    event.preventDefault();
    addSelectedBank({
      bank_id: event.target.value,
      id: props?.id,
    })
      .unwrap()
      .then((response) => {
        props?.successMessage(true);
        props?.setMessage("Bank Updated Successfully");

      })
      .catch((error) => {

      });
  };


  return (
    <div>
      <FormControl
        sx={{
          "& .css-67xo4a-MuiFormLabel-root-MuiInputLabel-root": {
            marginTop: "-7px",
          },
          "& .css-1qicbd3-MuiFormLabel-root-MuiInputLabel-root": {
            color: "#fff",
          },
          width: 200,
          color: "#186569",
        }}
      >
        {props?.passedValue === null ? (
          <InputLabel
            sx={{
              color: "#AEB8C9",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "11px",
              lineHeight: "14px",
              letterSpacing: "0.105em",
            }}
            disabled={true}
            id="demo-simple-select-label"
          >
            {props?.placeHolder}
          </InputLabel>
        ) : (
          <></>
        )}

        <Select
          onChange={handleChange}
          input={<OutlinedInput label="Name" />}
          MenuProps={MenuProps}
          sx={style}
          value={value !== null ? value : props?.passedValue}
          displayEmpty
          disabled={props?.disabled}
        >
          {props?.data?.map((bank) => (
            <MenuItem key={bank?.id} value={bank?.id}>
              {bank?.bank_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
