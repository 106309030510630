import React, { useEffect, useState } from "react";
import {
  useAddBankInformationMutation,
  useGetCurrenciesQuery,
  useGetManufacturersQuery,
  useGetSupplierOriginsQuery,
} from "../../../features/SCMApi";
import axios from "axios";
import { API_BASE_URL } from "../../../api/endPoint";
import { useParams } from "react-router-dom";
export default function BankinformationModal({ setMessages, refresh, modal }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const params_id = useParams();
  const {
    data: origin,
    isError,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetSupplierOriginsQuery();
  const {
    data: currencies,
    isErrorc,
    isLoadingc,
    isFetchingc,
    isSuccessc,
  } = useGetCurrenciesQuery();
  const {
    data: manufacturers,
    isErrors,
    errors,
    isLoadings,
    isFetchings,
    isSuccesss,
  } = useGetManufacturersQuery();

  const manufaturerList = [];
  manufacturers?.data?.map((items) =>
    manufaturerList.push({
      value: items?.id,
      label: items?.manufacturer_name,
    })
  );

  // const HandleSubmit = async (e) => {
  //   e.preventDefault();
  //   axios
  //     .post(`${API_BASE_URL}/scm/supplier/bankdetails`, params, {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     })
  //     .then((response) => {

  //       setMessages(response?.data?.message);
  //       refresh();
  //       modal(false);
  //     })
  //     .then((error) => {

  //     });
  //   // createSupplier(params)
  //   //   .unwrap()
  //   //   .then((response) => {

  //   //   })
  //   //   .then((error) => {

  //   //   });
  // };
  const [AddBankinfo, res] = useAddBankInformationMutation();
  const HandleSubmit = async (e) => {
    e.preventDefault();
    // axios
    //   .post(`${API_BASE_URL}/scm/supplier/representatives`, params, {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   })
    //   .then((response) => {

    //     props.modal(false);
    //     props.setMessages("Supplier representative added successfully");
    //   })
    //   .then((error) => {

    //   });
    AddBankinfo(params)
      .unwrap()
      .then((response) => {

        modal(false);
        setMessages("Supplier representative added successfully");
      })
      .then((error) => {

      });
  };
  function HandleCancel() {
    modal(false);
  }

  // bank detail
  const [bank_name, setBank_name] = useState();
  const [account_number, setAccount] = useState();
  const [swift_code, setSwift] = useState();
  const [iban, setIban] = useState();
  const [bank_country, setBankcountry] = useState();
  const [bank_currency, setBankcurrency] = useState();
  const [supplier_id, setSupplierid] = useState(params_id.id);
  const [manufacturer_id, setManufacturerid] = useState();
  const [frieght_forwarder_id, setfrieght_forwarder_id] = useState();
  let params = {
    supplier_id,
    manufacturer_id,
    frieght_forwarder_id,
    bank_name,
    swift_code,
    iban,
    bank_country,
    bank_currency,
    account_number,
  };

  return (
    <div>
      <div id="myModal" className="modal" onClick={HandleCancel}>
        <div className="modal-content-md" onClick={(e) => e.stopPropagation()}>
          <div className="create-project-modal-title">
            <div>Add Bank Information</div>
            <div onClick={HandleCancel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="42"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>
          <form className="form-container ">
            <div className="form-card-container">
              <div className="form-card-information">
                <div className="project-information">
                  <div className="form-card-title">Bank Details</div>
                  <div className="card-center">
                    <div className="card-form">
                      <div className="supplier-input-labels">
                        <div className="d-flex gap-2 align-items-baseline">
                          <div className="supplier-input-label">Bank Name</div>
                          <input
                            type="text"
                            className="supplier-text-input"
                            onChange={(e) => setBank_name(e.target.value)}
                          />
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          {" "}
                          <div className="supplier-input-label">Account No</div>
                          <input
                            type="text"
                            className="supplier-text-input"
                            onChange={(e) => setAccount(e.target.value)}
                          />
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          {" "}
                          <div className="supplier-input-label">Swift Code</div>
                          <input
                            type="text"
                            className="supplier-text-input"
                            onChange={(e) => setSwift(e.target.value)}
                          />
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          {" "}
                          <div className="supplier-input-label">IBAN</div>
                          <input
                            type="text"
                            className="supplier-text-input"
                            onChange={(e) => setIban(e.target.value)}
                          />
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          {" "}
                          <div className="supplier-input-label">
                            Bank Country
                          </div>
                          <select
                            name=""
                            id=""
                            className="supplier-select-option"
                            onChange={(e) => setBankcountry(e.target.value)}
                          >
                            {origin?.data?.map((items) => (
                              <option value={items.id}>{items.name}</option>
                            ))}
                          </select>
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          {" "}
                          <div className="supplier-input-label">
                            Bank Currency
                          </div>
                          <select
                            name=""
                            id=""
                            className="supplier-select-option"
                            onChange={(e) => setBankcurrency(e.target.value)}
                          >
                            {currencies?.data?.map((items) => (
                              <option value={items.name}>{items.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-buttons">
                <button
                  type="cancel"
                  className="form-cancel"
                  onClick={HandleCancel}
                >
                  {" "}
                  Cancel
                </button>
                <button
                  type="submit"
                  className="form-save"
                  onClick={HandleSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
