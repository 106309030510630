import axios from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL, DOC_BASE_URL } from "../../../api/endPoint";
import { Tooltip } from "@mui/material";
import usePagination from "@mui/material/usePagination/usePagination";
import {
  useCreateCostBuildUpMutation,
  useGetBatchDetailQuery,
  useUpdateCostBuildUpMutation,
} from "../../../features/SCMApi";
import { WindowSharp } from "@mui/icons-material";

export default function AddCostBuild(props) {
  let inlandTransportCost;
  let freightSeaAir;
  let transitorService;
  let training;
  let labour;
  let offshoreMaterial;
  let marineInsurance;
  let customsDuty;
  let clearingStorageWarehouse;
  let insurance;
  let bankCharge;
  let loadingUnloadingExpense;
  let vat;
  let sureTax;
  let withholdingTax;
  let exciseTax;

  if (props?.data && typeof props?.data === "object") {
    inlandTransportCost = props?.data["InLand Transport"];
    freightSeaAir = props?.data["Freight sea/air"];
    transitorService = props?.data["Transitor Service"];
    training = props?.data["Training"];
    labour = props?.data["Labour"];
    offshoreMaterial = props?.data["Offshore Material"];
    marineInsurance = props?.data["Marin insurance"];
    customsDuty = props?.data["Customs Duty"];
    clearingStorageWarehouse =
      props?.data["Clearing/Storage and Warehouse service"];
    insurance = props?.data["Insurance"];
    bankCharge = props?.data["Bank charge"];
    loadingUnloadingExpense = props?.data["Loading Unloading Expense"];
  }

  
  
  const [EX_Rate_per_Invoice, setEX_Rate_per_Invoice] = useState(
    props?.costBuildUp?.EX_Rate_per_Invoice
  );
  const [IPI_USD_Per_Invoice, setIPI_USD_Per_Invoice] = useState(
    props?.costBuildUp?.IPI_USD_Per_Invoice
  );
  const [Transitor_Service, setTransitor_Service] = useState(transitorService);
  const [Inland_Transport, setInland_Transport] = useState(inlandTransportCost);
  const [Loading_unloading_Expense, setLoading_unloading_Expense] = useState(
    loadingUnloadingExpense
  );
  const [Clearing_and_warehouse_Service, setClearing_and_warehouse_Service] =
    useState(clearingStorageWarehouse);
  const [Bank_Charge, setBank_Charge] = useState(bankCharge);
  const [Remark, setRemark] = useState(props?.costBuildUp?.Remark);
  const params = useParams();
  const { data: batch } = useGetBatchDetailQuery(params?.id);

  const form = {
    forex_id: params?.id,
    EX_Rate_per_Invoice,
    IPI_USD_Per_Invoice,
    Transitor_Service,
    project_id: props?.projectId,
    Inland_Transport,
    Loading_unloading_Expense,
    Clearing_and_warehouse_Service,
    Bank_Charge,
    Remark,
  };



  const [createCost] = useCreateCostBuildUpMutation();
  const [updateCost] = useUpdateCostBuildUpMutation();

  function HandleSubmit(e) {
    e.preventDefault();
    // props?.cost?.length == 0      ?
   
    createCost(form)
      .unwrap()
      .then((response) => {
        
        props.ToggleModal(false);
        window.location.reload(true);
      })
      .catch((error) => {
        
      });
    // : updateCost(form)
    //     .unwrap()
    //     .then((response) => {

    //       props.ToggleModal(false);
    //       window.location.reload(true);
    //     })
    //     .catch((error) => {

    //     });
  }
  console?.log(props?.cost?.length == 0, "propsjnkjdnkjnbdkj");
  return (
    <div className="mini-modal" onClick={(e) => props?.ToggleModal(false)}>
      <div className="mini-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="create-project-modal-title">
          Customs Clearance
          <div
            className="close-modal-button"
            onClick={(e) => props?.ToggleModal(false)}
          >
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.4612 5.78721L19.8657 7.19175L7.46095 19.5965L6.0564 18.192L18.4612 5.78721ZM7.17507 5.51376L20.3505 18.6891L18.8589 20.1807L5.68351 7.00531L7.17507 5.51376Z"
                fill="#353945"
              />
            </svg>
          </div>
        </div>
        <form
          className="form-card-container mb-4"
          onSubmit={(e) => HandleSubmit(e)}
        >
          <div className="form-card-information">
            <div className="project-information">
              <div className="form-card-title">Finance side</div>
              <div className="card-center">
                <div className="card-form">
                  <div className="input-group-fine">
                    <div className="d-flex gap-2 align-items-center">
                      <div className="supplier-input-label">
                        EX Rate as per Invoice
                      </div>
                      <input
                        defaultValue={EX_Rate_per_Invoice}
                        type="number"
                        className="text-input"
                        onChange={(e) => setEX_Rate_per_Invoice(e.target.value)}
                      />
                    </div>

                    <div className="d-flex gap-2 align-items-center">
                      <div className="supplier-input-label">
                        Item price In USD as per Invoice
                      </div>
                      <input
                        defaultValue={IPI_USD_Per_Invoice}
                        type="number"
                        className="text-input"
                        onChange={(e) => setIPI_USD_Per_Invoice(e.target.value)}
                      />
                    </div>

                    <div className="d-flex gap-2 align-items-center">
                      <div className="supplier-input-label">
                        Transitor Service
                      </div>
                      <input
                        disabled
                        defaultValue={Transitor_Service}
                        type="number"
                        className="text-input"
                        onChange={(e) => setTransitor_Service(e.target.value)}
                      />
                    </div>

                    <div className="d-flex gap-2 align-items-center">
                      <div className="supplier-input-label">
                        InLand Transport
                      </div>
                      <input
                        disabled
                        defaultValue={Inland_Transport}
                        type="number"
                        className="text-input"
                        onChange={(e) => setInland_Transport(e.target.value)}
                      />
                    </div>

                    <div className="d-flex gap-2 align-items-center">
                      <div className="supplier-input-label">
                        Loading Unloading Expense
                      </div>
                      <input
                        disabled
                        defaultValue={loadingUnloadingExpense}
                        type="number"
                        className="text-input"
                        onChange={(e) =>
                          setLoading_unloading_Expense(e.target.value)
                        }
                      />
                    </div>

                    <div className="d-flex gap-2 align-items-center">
                      <div className="supplier-input-label">
                        Clearing/Storage and Warehouse service{" "}
                      </div>
                      <input
                        disabled
                        defaultValue={clearingStorageWarehouse}
                        type="number"
                        className="text-input"
                        onChange={(e) =>
                          setClearing_and_warehouse_Service(e.target.value)
                        }
                      />
                    </div>

                    <div className="d-flex gap-2 align-items-center">
                      <div className="supplier-input-label">Bank charge</div>
                      <input
                        disabled
                        defaultValue={bankCharge}
                        type="number"
                        className="text-input"
                        onChange={(e) => setBank_Charge(e.target.value)}
                      />
                    </div>
                    <div className="d-flex gap-2 align-items-center">
                      <div className="supplier-input-label">Remark</div>
                      <textarea
                        type="text"
                        className="sourcing-text-input-textarea"
                        rows={4}
                        cols={50}
                        onChange={(e) => setRemark(e.target.value)}
                      >
                        {Remark}
                      </textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="form-buttons">
            <button
              type="cancel"
              className="form-cancel"
              style={{ width: "100px" }}
              onClick={(e) => {
                props.ToggleModal(false);
              }}
            >
              {" "}
              Cancel
            </button>
            <button
              type="submit"
              className="form-save"
              style={{ width: "100px" }}

              // onClick={(event) => handleSubmit(event)}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
