import React from "react";
import "./modal.css";

export default function AddIncotermsModal(props) {
  if (!props.showIncoterm) {
    return null;
  }
  return (
    <div className="pi-modal" onClick={props.onClose}>
      <div className="pi-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="pi-modal-header">
          <h3>Add Incoterm</h3>
        </div>
        <hr />
        <div className="pi-modal-content">
          <form className="pi-form">
            <div className="pi-inputs">
              <section className="pi-inputs-modal">
                <label>Name</label>
                <input type="text" />
              </section>
              <section className="pi-inputs-modal">
                <label>Category</label>
                <input type="text" />
              </section>
            </div>
            <div className="pi-modal-footer">
              <button onClick={props.onClose}>Cancel</button>
              <button>Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
