import React from "react";
import { useGetProjectsQuery } from "../../../features/SCMApi";
import { NavLink } from "react-router-dom";
import { Pagination } from "@mui/material";
import usePagination from "../../Pagination/Pagination";

export default function ProjectContainer() {
  const { data: projects } = useGetProjectsQuery();
  let [page, setPage] = React.useState(1);
  const { data: Projects } = useGetProjectsQuery();
  const PER_PAGE = 9;
  const count = Math.ceil(Projects?.data?.length / PER_PAGE);
  const _DATA = usePagination(Projects?.data, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  let numbers = 0;
  return (
    <>
      <div className="guarantee-project-container">
        {_DATA?.currentData()?.map((items) => (
          <NavLink
            to={`project/${items?.id}`}
            className="guarantee-project-card"
          >
            <div className="project-ref-container">
              <div className="h5">{items?.project_name}</div>
              <div className="d-grid gap-2">
                <div className="d-grid">
                  <div className="origin-value">EB/DTMB/BG/0002/2021</div>
                  <div className="origin-label">Bank Ref</div>
                </div>
                <div className="d-grid">
                  <div className="origin-value">EB/DTMB/BG/0002/2021</div>
                  <div className="origin-label">IE Ref</div>
                </div>
              </div>
            </div>
          </NavLink>
        ))}
      </div>
      {count > 1 ? (
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            margin: "5px",
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}
