import React from "react";
import ForexNeed from "./ForexNeed";
import ForexNeedMobileComponent from "./ForexNeedMobileComponent";
import usePagination from "../../Pagination/Pagination";
import { Pagination } from "@mui/material";
import SuccessMessage from "../SuccessMessage";

const ForexNeedDisplay = (props) => {
  const [successMessage, setSuccessMessage] = React.useState(false);
  successMessage &&
    setTimeout(() => {
      setSuccessMessage(false);
    }, 3000);

  const data = [];
  // for (let forexNeed in dumyData?.data?.forex_need) {
  //   data.push({
  //     ...dumyData?.data?.forex_need[forexNeed],
  //     financeOfficer: dumyData?.data?.finance_officer
  //       ?.map((fo) => fo?.name)
  //       ?.toString(),
  //     sourcingOfficer: dumyData?.data?.sourcing_officer
  //       ?.map((so) => so?.name)
  //       ?.toString(),
  //     bank:
  //       dumyData?.data?.forexe_bank?.find(
  //         (item) =>
  //           item?.id === dumyData?.data?.forex_need[forexNeed]?.forex_need_id
  //       )?.forex_need_bank !== null
  //         ? dumyData?.data?.forexe_bank?.find(
  //             (item) =>
  //               item?.id ===
  //               dumyData?.data?.forex_need[forexNeed]?.forex_need_id
  //           )?.forex_need_bank?.bank_name
  //         : null,
  //   });
  // }
  for (let forexNeed in props?.data?.data?.forex_need) {
    data.push({
      ...props?.data?.data?.forex_need[forexNeed],
      financeOfficer: props?.data?.data?.finance_officer
        ?.map((fo) => fo?.name)
        ?.toString(),
      sourcingOfficer: props?.data?.data?.sourcing_officer
        ?.map((so) => so?.name)
        ?.toString(),
      bank:
        props?.data?.data?.forexe_bank.find(
          (item) =>
            item?.id === props?.data?.data?.forex_need[forexNeed]?.forex_need_id
        )?.forex_need_bank !== null
          ? props?.data?.data?.forexe_bank.find(
              (item) =>
                item?.id ===
                props?.data?.data?.forex_need[forexNeed]?.forex_need_id
            )?.forex_need_bank?.bank_name
          : null,
    });
  }

  let [page, setPage] = React.useState(1);
  const PER_PAGE = 5;
  const count = Math.ceil(data.length && data.length / PER_PAGE);
  const _DATA = usePagination(data, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  return (
    <>
      <div className="gap-analysis-web-view">
        <ForexNeed data={props?.data} />
      </div>
      <div className="gap-analysis-mobile-view">
        {successMessage === true ? (
          <SuccessMessage
            style={{
              marginTop: "10px",
              marginBottom: "-20px",
              backgroundColor: "#52ffab96",
              marginRight: "10px",
              width: "362px",
            }}
            className={"success_messages_text_style_mobile_View"}
            Message={"Forex bank updated successfully!!!"}
          />
        ) : (
          <></>
        )}
        {_DATA?.currentData()?.map((bankGap) => (
          <ForexNeedMobileComponent
            forexNeedData={bankGap}
            setSuccessMessage={setSuccessMessage}
          />
        ))}
        {count > 1 ? (
          <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              margin: "5px",
            }}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default ForexNeedDisplay;
