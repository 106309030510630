import React, { useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../../api/endPoint";
import { DOC_BASE_URL } from "../../../../api/endPoint";
import "../../logistics.css";
import Button from "@mui/material/Button";
import attachmentIcon from "../../../../assets/SVG/attach.svg";
import {
  useRmaExportPreviousDocumentMutation,
  useGetRmaMissedItemDocumentsQuery,
} from "../../../../features/SCMApi";

export default function RmaPDModal(props) {
  const { data: previousDocumentData } = useGetRmaMissedItemDocumentsQuery();
  const documentData =
    props?.previousData !== undefined ? props?.previousData : [];

  const [supportLetter, setSupportLetter] = useState(null);
  const [warrantyCertificate, setWarrantyCertificate] = useState(null);


  let params = {
    batch_id: props?.batchId,
    rma_id: props?.rmaId,
    support_letter: supportLetter,
    warranty_certificate: warrantyCertificate,
  };

  const handleSupportLetter = (e) => {
    setSupportLetter(e?.target?.files[0]);
  };
  const handleWarrantyCertificate = (e) => {
    setWarrantyCertificate(e?.target?.files[0]);
  };

  function HandleCancel() {
    props.modal(false);
  }

  const HandleSubmit = async (e) => {
    e.preventDefault();
    documentData?.length === 0
      ? axios
          .post(`${API_BASE_URL}/scm/rma/export/previous-document`, params, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            props?.successMessage(true);
            props?.message("Previous document attached successfully!!!");
            
            HandleCancel();
          })
          .then((error) => {
            
          })
      : axios
          .post(
            `${API_BASE_URL}/scm/rma/export/previous-document/${documentData[0]?.id}`,
            params,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
          
            HandleCancel();
            props?.successMessage(true);
            props?.message("Previous document updated successfully!!!");
          })
          .then((error) => {
            
          });
  };



  const exportFilesLabelForExport =
    props.name === "pdEx" ? (
      [
        {
          label: "Commercial Invoice",
        },
        { label: "Packing List" },
        { label: "Import Declaration" },
      ]
    ) : (
      <></>
    );

  const exportFileLabelImport =
    props.name === "pdIm" ? (
      [
        {
          label: "Commercial Invoice",
        },
        { label: "Packing List" },
        { label: "Support Letter" },
        { label: "Export Declaration" },
      ]
    ) : (
      <></>
    );

  const importFilesLabel =
    props.name === "pdEx" ? (
      [{ label: "Support Letter" }, { label: "Warranty Certificate" }]
    ) : (
      <></>
    );

  return (
    <>
      <div id="myModal" className="modal" onClick={HandleCancel}>
        <div
          className="mini-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="logisticsRma-create-project-modal-title">
            <div className="previousDocumnetTitle">Previous Document</div>
            <div onClick={HandleCancel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="42"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>
          {props.name === "pdEx" ? (
            exportFilesLabelForExport?.map((item) => (
              <div className="rmaDownloadBox">
                <span className="rmaDownloadLabel">{item?.label}</span>
                <a
                  href={`${DOC_BASE_URL}scm/document/download?file_path=${
                    item?.label === "Commercial Invoice"
                      ? previousDocumentData?.data[0]?.pi
                      : item?.label === "Packing List"
                      ? previousDocumentData?.data[0]?.pl
                      : item?.label === "Import Declaration"
                      ? previousDocumentData?.data[0]?.import_declaration
                      : ""
                  }`}
                >
                  <Button>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="#186569"
                      class="bi bi-download"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                    </svg>
                  </Button>
                </a>
              </div>
            ))
          ) : (
            <></>
          )}
          {props.name === "pdIm" ? (
            exportFileLabelImport?.map((item) => (
              <div className="rmaDownloadBox">
                <span className="rmaDownloadLabel">{item?.label}</span>
                <a
                  href={`${DOC_BASE_URL}scm/document/download?file_path=${
                    item?.label === "Commercial Invoice"
                      ? previousDocumentData?.data[0]?.pi
                      : item?.label === "Packing List"
                      ? previousDocumentData?.data[0]?.pl
                      : item?.label === "Support Letter"
                      ? previousDocumentData?.data[0]?.support_letter
                      : item?.label === "Export Declaration"
                      ? previousDocumentData?.data[0]?.export_declaration
                      : ""
                  }`}
                >
                  <Button>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="#186569"
                      class="bi bi-download"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                    </svg>
                  </Button>
                </a>
              </div>
            ))
          ) : (
            <></>
          )}
          {props.name === "pdEx" ? (
            <>
              <div className="rmaFileAttachLabel">Attach Supporting Letter</div>
              <div className="rmaFileAttachLabelDivider" />
            </>
          ) : (
            <></>
          )}
          <form
            className="form-container rmaMissedItemForm forexApprovalForms"
            onSubmit={HandleSubmit}
          >
            <div className="form-card-container">
              {props.name === "pdEx" ? (
                <>
                  <div className="form-card-information">
                    {documentData?.length !== 0 ? (
                      <>
                        <p
                          className="forex-attached-file-warning"
                          style={{ marginTop: "0px", marginBottom: "20px" }}
                        >
                          Previous document already attached !!!
                        </p>
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="card-center ">
                      <div className="card-form">
                        <div className="rmaFileInputLabelContainer inputLabelsHolder">
                          {importFilesLabel?.map((item) => (
                            <div
                              className="rmaFileInputLabel" // style={{ marginLeft: "-30px" }}
                              style={
                                item?.label.length > 15
                                  ? { marginLeft: "-30px" }
                                  : { marginLeft: "0px" }
                              }
                            >
                              {item?.label}
                            </div>
                          ))}
                        </div>
                        <div className="input-fields">
                          <div
                            className="file-chooser rmaMissedItemFile-choosers"
                            style={{
                              backgroundImage: `url(${attachmentIcon})`,
                            }}
                          >
                            <input
                              required={
                                documentData?.length === 0 ? true : false
                              }
                              type="file"
                              className="file-input"
                              name="boq"
                              onChange={(e) => handleSupportLetter(e)}
                            />
                            <div className="rmaFileUploader">
                              {supportLetter
                                ? supportLetter?.name.slice(0, 10) + "...."
                                : documentData?.length !== 0
                                ? "File exist"
                                : " File"}
                            </div>
                          </div>
                          <div
                            className="file-chooser rmaMissedItemFile-choosers"
                            style={{
                              backgroundImage: `url(${attachmentIcon})`,
                            }}
                          >
                            <input
                              required={
                                documentData?.length === 0 ? true : false
                              }
                              type="file"
                              className="file-input"
                              name="boq"
                              onChange={(e) => handleWarrantyCertificate(e)}
                            />
                            <div className="rmaFileUploader">
                              {warrantyCertificate
                                ? warrantyCertificate?.name.slice(0, 10) +
                                  "...."
                                : documentData?.length !== 0
                                ? "File exist"
                                : " File"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              <div
                className="form-buttons rma-form-submit"
                style={
                  props.name === "pdIm"
                    ? { marginTop: "30px" }
                    : { marginTop: "0px" }
                }
              >
                <button
                  type="cancel"
                  className="form-cancel clientCancel"
                  onClick={HandleCancel}
                >
                  {" "}
                  Cancel
                </button>
                <button type="submit" className="form-save clientSave">
                  {documentData?.length !== 0 ? "Update" : "Save"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
