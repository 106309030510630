import Close from "@mui/icons-material/Close";
import React, { useState } from "react";
import Select from "react-select";
import {
  useAddStoreSettingMutation,
  useGetProjectsQuery,
  useGetUsersQuery,
} from "../../../features/SCMApi";
import "./modal.css";

export default function AddStore(props) {
  const [createStore, res] = useAddStoreSettingMutation();
  const [project_id, setProject] = useState(null);
  const [store_keeper_id, setStoreKeeper] = useState(null);
  const [store_name, setStoreName] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [is_permanent, setIsPermanent] = useState(false);

  const isStorePermanent = () => setIsPermanent(!is_permanent);

  const store = {
    store_keeper_id,
    project_id,
    store_name,
    description,
    location,
    is_permanent,
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    createStore(store)
      .unwrap()
      .then((response) => {
        props.modal(false);
        props.setMessages("Store created successfully");
      })
      .catch((error) => {
        // Handle error here
      });
  };

  function HandleCancel() {
    props.modal(false);
  }

  const { data: response } = useGetProjectsQuery();
  const { data: users } = useGetUsersQuery();

  // Transform data for react-select
  const projectOptions =
    response?.data?.map((item) => ({
      value: item.id,
      label: item.project_name,
    })) || [];
  const userOptions =
    users?.data?.map((item) => ({
      value: item.id,
      label: item.name,
    })) || [];

  const selectStyles = {
    container: (provided) => ({
      ...provided,
      width: "250px",
    }),
    control: (provided) => ({
      ...provided,
      height: "40px",
      minHeight: "40px",
    }),
    menu: (provided) => ({
      ...provided,
      width: "250px",
    }),
  };

  return (
    <div className="pi-modal" onClick={HandleCancel}>
      <div className="pi-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="pi-modal-header">
          <div className="d-flex justify-content-between">
            <h5>Add Store</h5>
            <Close onClick={HandleCancel} />
          </div>
        </div>
        <hr />
        <div className="pi-modal-content">
          <form className="pi-form" onSubmit={HandleSubmit}>
            <div className="d-grid gap-3">
              <div className="d-flex gap-2 align-items-baseline">
                <div className="supplier-input-label">Name</div>
                <input
                  value={store_name}
                  onChange={(e) => setStoreName(e.target.value)}
                  type="text"
                  className="stock-text-input"
                  required
                />
              </div>
              <div className="d-flex gap-2 align-items-baseline">
                <div className="supplier-input-label">Location</div>
                <input
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  type="text"
                  className="stock-text-input"
                  required
                />
              </div>
              <div className="d-flex gap-2 align-items-baseline">
                <div className="supplier-input-label">Project</div>
                <Select
                  value={projectOptions.find(
                    (option) => option.value === project_id
                  )}
                  onChange={(option) => setProject(option?.value)}
                  options={projectOptions}
                  placeholder="Choose Project"
                  styles={selectStyles}
                />
              </div>
              <div className="d-flex gap-2 align-items-baseline">
                <div className="supplier-input-label">Store Keeper</div>
                <Select
                  value={userOptions.find(
                    (option) => option.value === store_keeper_id
                  )}
                  onChange={(option) => setStoreKeeper(option?.value)}
                  options={userOptions}
                  placeholder="Choose User"
                  styles={selectStyles}
                />
              </div>
              <div className="d-flex flex-row-reverse gap-2 px-2">
                <input
                  type="checkbox"
                  className="stock-checkmark"
                  checked={is_permanent}
                  onChange={isStorePermanent}
                />
                <span className="h6">Permanent store</span>
              </div>
              <div className="d-flex gap-0 align-items-baseline">
                <div className="supplier-input-label">Description</div>
                <textarea
                  cols="30"
                  rows="5"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                ></textarea>
              </div>
            </div>
            <div className="pi-modal-footer">
              <button type="button" onClick={HandleCancel}>
                Cancel
              </button>
              <button type="submit">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
