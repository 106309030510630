import React, { useState } from "react";
import "../../pages/Payment/Payable/aging.css";

const EditCategoryModal = ({ category, onClose, onUpdate }) => {
  const [editedName, setEditedName] = useState(category.name);
  const [editedDescription, setEditedDescription] = useState(
    category.description
  );
  return (
    <div className="modal-container" show={true} onHide={onClose}>
      <div class="modal-dialog  modal-dialog-centered " role="document">
        <div class="modal-content" style={{ minHeight: "0" }}>
          <div class="modal-header">
            <h5 class="modal-title">Edit Category</h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
            ></button>
          </div>

          <div class="modal-body">
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-default"
                defaultValue={category.name}
                onChange={
                  (e) => setEditedName(e.target.value)
                
                }
              />
            </div>
            <textarea
              type="text"
              class="form-control"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-sm"
              placeholder="Enter category description"
              defaultValue={category.description}
              onChange={
                (e) => setEditedDescription(e.target.value)
                
              }
            ></textarea>
          </div>

          <div class="modal-footer">
            <button
              onClick={onClose}
              type="button"
              className="form-cancel"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              onClick={() =>
                onUpdate(category.id, editedName, editedDescription)
              }
              type="button"
              className="form-save"
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCategoryModal;
