import React from "react";
import { Breadcrumb } from "react-bootstrap";
import NavBar from "../../../Layout/NavBar";

export default function Index(props) {
  return (
    <div>
      <>
        <NavBar
          placeholder={props?.placeholder}
          handleSearchChange={props.handleSearchChange}
        />
        <div className="all-breadcrumb">
          <Breadcrumb className="ms-3">
            <Breadcrumb.Item className="prg" href="/logistics">
              Logistics
            </Breadcrumb.Item>
            <Breadcrumb.Item className="prg" href={props.navlink}>
              {props.title}
            </Breadcrumb.Item>
            {props.name === undefined ? (
              ""
            ) : (
              <Breadcrumb.Item className="prg-pri" active>
                {props.name}
              </Breadcrumb.Item>
            )}
          </Breadcrumb>
        </div>
        <div className="page">
          <>{props.children}</>
        </div>
      </>
    </div>
  );
}
