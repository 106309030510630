import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddItemsType from "./SettingModal/AddItemsType";
import EditItemsType from "./SettingModal/EditItemsType";
import {
  useDeleteInventoryTypeMutation,
  useGetItemTypeQuery,
} from "../../features/SCMApi";
import DeleteModal from "../Supplier/SupplierModal/DeleteModal";

export default function ItemsType() {
  const [addItemsType, setAddItemsType] = useState(false);
  const [editItemsType, setEditItemsType] = useState(false);
  const [showClientDeleteModal, setShowClientDeleteModal] = useState(false);
  const [itemsTypeToDeleteId, setItemsTypeToDeleteId] = useState(null);
  const [currentItemsType, setCurrentItemsType] = useState(null);

  const { data: itemstype } = useGetItemTypeQuery();
  const [deleteItemsType] = useDeleteInventoryTypeMutation();

  const handleAddItemsTypeModal = () => {
    setAddItemsType(true);
  };

  const handleEditItemsTypeModal = (items) => {
    setEditItemsType(true);
    setCurrentItemsType(items);
  };

  const toggleDeleteModal = () => {
    setShowClientDeleteModal(!showClientDeleteModal);
  };

  const handleOpenDeleteModal = (id) => {
    setItemsTypeToDeleteId(id);
    toggleDeleteModal();
  };

  const handleDeleteItemsType = () => {
    const deleted = {
      id: itemsTypeToDeleteId,
    };
    deleteItemsType(deleted)
      .unwrap()
      .then(() => {
        console.log("Item type deleted successfully");
      })
      .catch((error) => {
        console.error("Error deleting item type:", error);
      });
    toggleDeleteModal();
  };

  return (
    <div className="pcard shipment-mode">
      <section className="pi-header">
        <h3>Item Type</h3>
      </section>
      {itemstype?.data?.map((items) => (
        <section className="pi-body" key={items.id}>
          <p>{items?.inventory_type}</p>
          <p>{items?.description}</p>
          <div>
            <ModeEditOutlineOutlinedIcon
              onClick={() => handleEditItemsTypeModal(items)}
              sx={{
                color: "#52FFAC",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#52FFAC",
                  border: "1px solid #52FFAC",
                  borderRadius: 25,
                },
              }}
            />
            <DeleteOutlineOutlinedIcon
              onClick={() => handleOpenDeleteModal(items.id)}
              sx={{
                color: "#FF5252",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#FF5252",
                  border: "1px solid #FF5252",
                  borderRadius: 25,
                },
              }}
            />
          </div>
        </section>
      ))}

      <section className="pi-footer">
        <div onClick={handleAddItemsTypeModal} className="pi-footer-icon">
          <AddIcon />
        </div>
      </section>

      {addItemsType && <AddItemsType modal={setAddItemsType} />}
      {editItemsType && (
        <EditItemsType modal={setEditItemsType} data={currentItemsType} />
      )}

      <DeleteModal
        show={showClientDeleteModal}
        handleDeleteModal={toggleDeleteModal}
        name={"item type"}
        deletedId={itemsTypeToDeleteId}
        navigationto={"smm/item-type"}
        handleDelete={handleDeleteItemsType}
      />
    </div>
  );
}
