import React from "react";

export default function DetailedBoq() {
  return (
    <div>
      {" "}
      <div id="myModal" className="medium-modal">
        <div className="medium-modal-content">
          <div className="create-project-modal-title">AAit LAN</div>
          <div className="detailed-boq-container">
            <div className="detailed-boq-tab">
              <div className="tab-items">ALL</div>
              <div className="tab-items">MDCC</div>
              <div className="tab-items active-tab">ENS</div>
              <div className="tab-items">BAI</div>
              <div className="tab-items">ITF</div>
            </div>
            <div className="tab-container">
              <div className="tab-contents">
                <div className="row-label">Product BoQ</div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.33325 21.6666C1.79992 21.6666 1.33325 21.4666 0.933252 21.0666C0.533252 20.6666 0.333252 20.1999 0.333252 19.6666V14.8999H2.33325V19.6666H19.6666V14.8999H21.6666V19.6666C21.6666 20.1999 21.4666 20.6666 21.0666 21.0666C20.6666 21.4666 20.1999 21.6666 19.6666 21.6666H2.33325ZM10.9999 16.5666L4.56659 10.1333L5.99992 8.69992L9.99992 12.6999V0.333252H11.9999V12.6999L15.9999 8.69992L17.4333 10.1333L10.9999 16.5666Z"
                    fill="#186569"
                  />
                </svg>
              </div>

              <div className="tab-contents">
                <div className="row-label">After Sales</div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.33325 21.6666C1.79992 21.6666 1.33325 21.4666 0.933252 21.0666C0.533252 20.6666 0.333252 20.1999 0.333252 19.6666V14.8999H2.33325V19.6666H19.6666V14.8999H21.6666V19.6666C21.6666 20.1999 21.4666 20.6666 21.0666 21.0666C20.6666 21.4666 20.1999 21.6666 19.6666 21.6666H2.33325ZM10.9999 16.5666L4.56659 10.1333L5.99992 8.69992L9.99992 12.6999V0.333252H11.9999V12.6999L15.9999 8.69992L17.4333 10.1333L10.9999 16.5666Z"
                    fill="#186569"
                  />
                </svg>
              </div>

              <div className="tab-contents">
                <div className="row-label">Implementation</div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.33325 21.6666C1.79992 21.6666 1.33325 21.4666 0.933252 21.0666C0.533252 20.6666 0.333252 20.1999 0.333252 19.6666V14.8999H2.33325V19.6666H19.6666V14.8999H21.6666V19.6666C21.6666 20.1999 21.4666 20.6666 21.0666 21.0666C20.6666 21.4666 20.1999 21.6666 19.6666 21.6666H2.33325ZM10.9999 16.5666L4.56659 10.1333L5.99992 8.69992L9.99992 12.6999V0.333252H11.9999V12.6999L15.9999 8.69992L17.4333 10.1333L10.9999 16.5666Z"
                    fill="#186569"
                  />
                </svg>
              </div>

              <div className="tab-contents">
                <div className="row-label">Professional</div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.33325 21.6666C1.79992 21.6666 1.33325 21.4666 0.933252 21.0666C0.533252 20.6666 0.333252 20.1999 0.333252 19.6666V14.8999H2.33325V19.6666H19.6666V14.8999H21.6666V19.6666C21.6666 20.1999 21.4666 20.6666 21.0666 21.0666C20.6666 21.4666 20.1999 21.6666 19.6666 21.6666H2.33325ZM10.9999 16.5666L4.56659 10.1333L5.99992 8.69992L9.99992 12.6999V0.333252H11.9999V12.6999L15.9999 8.69992L17.4333 10.1333L10.9999 16.5666Z"
                    fill="#186569"
                  />
                </svg>
              </div>

              <div className="tab-contents">
                <div className="row-label">Training</div>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.33325 21.6666C1.79992 21.6666 1.33325 21.4666 0.933252 21.0666C0.533252 20.6666 0.333252 20.1999 0.333252 19.6666V14.8999H2.33325V19.6666H19.6666V14.8999H21.6666V19.6666C21.6666 20.1999 21.4666 20.6666 21.0666 21.0666C20.6666 21.4666 20.1999 21.6666 19.6666 21.6666H2.33325ZM10.9999 16.5666L4.56659 10.1333L5.99992 8.69992L9.99992 12.6999V0.333252H11.9999V12.6999L15.9999 8.69992L17.4333 10.1333L10.9999 16.5666Z"
                    fill="#186569"
                  />
                </svg>
              </div>
              <button className="assign-personal-btn">
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.5 17.5001H2.6L13.675 6.42511L12.575 5.32511L1.5 16.4001V17.5001ZM16.85 5.35011L13.65 2.15011L14.7 1.10011C14.9833 0.81678 15.3375 0.67928 15.7625 0.687613C16.1875 0.695946 16.5417 0.84178 16.825 1.12511L17.9 2.20011C18.1833 2.48345 18.325 2.83345 18.325 3.25011C18.325 3.66678 18.1833 4.01678 17.9 4.30011L16.85 5.35011ZM0.75 19.0001C0.533333 19.0001 0.354167 18.9293 0.2125 18.7876C0.0708334 18.6459 0 18.4668 0 18.2501V16.1001C0 16.0001 0.0166666 15.9084 0.05 15.8251C0.0833333 15.7418 0.141667 15.6584 0.225 15.5751L12.6 3.20011L15.8 6.40011L3.425 18.7751C3.34167 18.8584 3.25833 18.9168 3.175 18.9501C3.09167 18.9834 3 19.0001 2.9 19.0001H0.75ZM13.125 5.87511L12.575 5.32511L13.675 6.42511L13.125 5.87511Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
