import React from "react";
// import OrderCard from "../../components/Sourcing/OrderCard";
import "./orders.style.css";
// import NavBar from "../../Layout/NavBar";
import SourcingIndex from "./SourcingIndex";
import { useGetProjectsQuery } from "../../features/SCMApi";
import { Link, NavLink, useParams } from "react-router-dom";
import { useState } from "react";
// import { Tab, Tabs } from "react-bootstrap";
// import OrderProduct from "../../components/Sourcing/OrderProduct";
// import OrderService from "../../components/Sourcing/OrderService";
// import OrderTranings from "../../components/Sourcing/OrderTranings";

import Payment from "../../assets/SVG/order.png";
import Service from "../../assets/SVG/customer-service.png";
import Traning from "../../assets/SVG/counseling.png";

export default function EtaIndex() {
  const { data: response } = useGetProjectsQuery();
  const Projectdata = [];
  const params = useParams();
  response?.data?.map((items) => {
    if (params.id === items.id) {
      Projectdata.push(items);
    }
  });

  const [search, setSearch] = useState("");

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  // const [message, setMessage] = useState(null);
  return (
    <SourcingIndex
      name={Projectdata[0]?.project_name}
      names="Po Stage"
      handleSearchChange={handleSearchChange}
      placeholder={"search Order..."}
      // Message={message}
    >
      <Link
        className="download-btn m-3"
        style={{
          borderRadius: "4px",
          width: "170px",
        }}
        // to={{
        //   pathname: "./etaSummaryProduct",
        //   state: { eta: eta?.data },
        // }}
        to="./etaSummary"
      >
        <div
          className="d-flex justify-content-around align-items-center"
          style={{
            color: "#ffffff",
          }}
        >
          <div className="px-2">ETA Summary</div>
        </div>
      </Link>
      <div className="supplier-menu-container">
        <NavLink to={"EtaProduct"} className="payment-menu-card">
          <div className="text-center">
            <img
              src={Payment}
              style={{ width: 80, height: 80, borderRadius: "0px" }}
            />
          </div>
          <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
            Product
          </span>
        </NavLink>
        <NavLink to={"EtaService"} className="payment-menu-card">
          <div className="text-center">
            <img
              src={Service}
              style={{ width: 80, height: 80, borderRadius: "0px" }}
            />
          </div>
          <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
            Service
          </span>
        </NavLink>
        <NavLink to={"EtaTraning"} className="payment-menu-card">
          <div className="text-center">
            <img
              src={Traning}
              style={{ width: 80, height: 80, borderRadius: "0px" }}
            />
          </div>
          <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
            Traning
          </span>
        </NavLink>
      </div>
      {/* <Tabs
        defaultActiveKey="Product"
        id="uncontrolled-tab-example"
        className="mb-3 d-flex justify-content-center bg-white rounded gap-4 border forexApprovalNav"
      >
        <Tab eventKey="Product" title="Product">
          <OrderProduct search={search} setMessage={setMessage} />
        </Tab>
        <Tab eventKey="Service" title="Service">
          <OrderService search={search} setMessage={setMessage} />
        </Tab>
        <Tab eventKey="Traning" title="Traning">
          <OrderTranings search={search} setMessage={setMessage} />
        </Tab>
      </Tabs> */}
    </SourcingIndex>
  );
}
