import React, { useEffect, useState } from "react";
import div from "../../../components/Payment/ForexApproval/PaymentSideBar";
import {
  useApprovePrfBudgetMutation,
  useGetPrfBudgetQuery,
  useGetUserInfoQuery,
} from "../../../features/SCMApi";
import { date } from "../../../components/dateFormator";
import { Box, Modal } from "@mui/material";
import ConfirmDelete from "../../../components/common/ConfirmDelete";
import { PM } from "../../../api/endPoint";
import axios from "axios";

export default function CashoutFlow({ data }) {
  const [prfApproval] = useApprovePrfBudgetMutation();
  const [open, setOpen] = useState(false);
  const [projectPrf, setProjectPrf] = useState({});

  const sampleData = {
    "0ef10ba2-d01e-41bd-9b49-3b6855eb09c1": [
      {
        b_no: "123",
        a_no: "456",
        a_description: "Operating Expenses",
        m_budget: "Milestone A",
        expected_date: "2024-01-20",
        b_holder: "John Doe",
        b_usd: 5000,
        b_etb: 150000,
      },
      {
        b_no: "124",
        a_no: "457",
        a_description: "Capital Expenditure",
        m_budget: "Milestone B",
        expected_date: "2024-01-25",
        b_holder: "Jane Smith",
        b_usd: 8000,
        b_etb: 0,
      },
      {
        b_no: "124",
        a_no: "457",
        a_description: "Capital Expenditure",
        m_budget: "Milestone B",
        expected_date: "2024-01-25",
        b_holder: "Jane Smith",
        b_usd: 8000,
        b_etb: 0,
      },
      {
        b_no: "124",
        a_no: "457",
        a_description: "Capital Expenditure",
        m_budget: "Milestone B",
        expected_date: "2024-01-25",
        b_holder: "Jane Smith",
        b_usd: 8000,
        b_etb: 0,
      },
    ],
    "cd463b01-4ab6-4676-9a1f-3a416f062c92": [
      {
        b_no: "123",
        a_no: "456",
        a_description: "Operating Expenses",
        m_budget: "Milestone A",
        expected_date: "2024-01-20",
        b_holder: "John Doe",
        b_usd: 5000,
        b_etb: 150000,
      },
      {
        b_no: "124",
        a_no: "457",
        a_description: "Capital Expenditure",
        m_budget: "Milestone B",
        expected_date: "2024-01-25",
        b_holder: "Jane Smith",
        b_usd: 8000,
        b_etb: 240000,
      },
      {
        b_no: "124",
        a_no: "457",
        a_description: "Capital Expenditure",
        m_budget: "Milestone B",
        expected_date: "2024-01-25",
        b_holder: "Jane Smith",
        b_usd: 8000,
        b_etb: 240000,
      },
      {
        b_no: "124",
        a_no: "457",
        a_description: "Capital Expenditure",
        m_budget: "Milestone B",
        expected_date: "2024-01-25",
        b_holder: "Jane Smith",
        b_usd: 8000,
        b_etb: 240000,
      },
    ],
  };
  const projectTotals = Object.keys(sampleData)?.flatMap((projectName) => {
    const projectData = sampleData[projectName];
    const totalUSD = projectData.reduce((acc, data) => acc + data.b_usd, 0);
    const totalETB = projectData.reduce((acc, data) => acc + data.b_etb, 0);

    return {
      projectName,
      totalUSD,
      totalETB,
    };
  });
  let totalBudgetUSD = projectTotals.reduce(
    (acc, data) => acc + data.totalUSD,
    0
  );
  const [dialog, setDialog] = useState(false);
  const [dialogTransfer, setDialogTransfer] = useState(false);
  const [loading, setLoading] = useState(false);
  let totalBudgetETB = projectTotals.reduce(
    (acc, data) => acc + data.totalETB,
    0
  );
  const [selectedProjects, setSelectedProjects] = useState({}); // State to manage selected projects
  // Function to handle individual project checkbox
  const handleSelectItem = (projectName, index, data) => {
    // Update the selectedProjects state to mark the individual item as selected
    setSelectedProjects((prevSelectedProjects) => ({
      ...prevSelectedProjects,
      [`${projectName}-${index}`]:
        !prevSelectedProjects[`${projectName}-${index}`],
    }));
  };

  // Function to handle "Select All" checkbox
  const handleSelectAll = (e, projectName, data) => {
    const isChecked = e.target.checked;

    // Update the selectedProjects state for the current project
    setSelectedProjects((prevSelectedProjects) => ({
      ...prevSelectedProjects,
      [projectName]: isChecked,
    }));

    // Update the selectedProjects state for all items in the current project
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Replace 'YOUR_API_ENDPOINT' with the actual API endpoint you want to fetch data from
        const response = await axios.get(PM);

        setProjectPrf(response?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the fetchData function
  }, []);
  function mergeData(data1, data2) {
    let mergedData = {};

    // Loop through each project
    Object.keys(data1 ? data1 : [])?.forEach((projectName) => {
      // Merge arrays for the same project
      if (data2[projectName]) {
        mergedData[projectName] = data1[projectName].concat(data2[projectName]);
      } else {
        mergedData[projectName] = data1[projectName];
      }
    });

    // Include projects unique to data2
    Object.keys(data2 ? data2 : [])?.forEach((projectName) => {
      if (!mergedData[projectName]) {
        mergedData[projectName] = data2[projectName];
      }
    });

    return mergedData;
  }

  let mergedData = mergeData(data, projectPrf);
  const updateData = Object.fromEntries(
    Object.keys(mergedData ? mergedData : {}).map((projectNames) => [
      projectNames,
      (mergedData[projectNames] || []).map((items) => ({
        ...items,
        approved: selectedProjects[projectNames] || false,
      })),
    ])
  );
  const { data: userinfo } = useGetUserInfoQuery();

  const budget = Object.keys(updateData)?.flatMap((projectName) =>
    (updateData[projectName] || []).map((e) => ({
      project_id: e?.project_id ? e?.project_id : e.projectId,
      budget_category_id: "budgetcategoryid",
      budget_type_id:
        e.id || e?.taskCategory_budgetTypeId || e?.budgetCategoryId,
      amount: e?.total_payment || e?.sum || e?.budgetAmount,
      currency_id: e.order?.currency_id || e?.currency_id,
      is_certain_budget: true,
      payment_date: e?.order?.payment_date || e.to,
      approved: dialogTransfer ? false : e.approved,
      transferred: dialog ? false : e.approved,
    }))
  );
  let params = {
    user_id: userinfo?.id,
    project_monthly_budget: budget,
  };

  const HandleApprovalSubmit = async () => {
    setLoading(true);
    prfApproval(params)
      .unwrap()
      .then((response) => {
        setDialog(false);
        setLoading(false);
        window?.location.reload(true);
      })
      .then((error) => {
        setLoading(false);
      });
  };

  return (
    <div title={"Budget"} name={"Budget"} navlink={`/payment`}>
      <div className="d-flex justify-content-between align-items-end py-2">
        <div className="card mb-3" style={{ width: "18rem" }}>
          <div
            style={{
              backgroundColor: "#064b4f",
              color: "#fff",
              letterSpacing: "0.105em",
              fontWeight: "bold",
              fontSize: "14px",
              padding: "5px",
            }}
          >
            Total Budget cost
          </div>
          <ul className="list-group list-group-flush">
            <li className="list-group-item prg">
              {Number(totalBudgetUSD)?.toLocaleString()} in USD
            </li>
            <li className="list-group-item prg">
              {Number(totalBudgetETB)?.toLocaleString()} in ETB
            </li>
          </ul>
        </div>
        <div className="d-flex gap-3 ">
          <button className="form-save" onClick={() => setDialog(true)}>
            Approve
          </button>
          <button className="form-save" onClick={() => setDialogTransfer(true)}>
            Transfer to Next Month
          </button>
        </div>
      </div>
      <table
        className="table table-bordered"
        style={{ borderCollapse: "collapse", width: "100%" }}
      >
        <thead
          style={{
            fontSize: "10px",
            backgroundColor: "#000",
            letterSpacing: "0.105em",
            fontWeight: "400",
            color: "#fff ",
          }}
        >
          <tr>
            <th> </th>
            <th>BUDGET NO.</th>
            <th>ACCOUNT NO.</th>
            <th>ACCOUNT DESCRIPTION</th>
            <th>MILESTONE LINKED TO THE BUDGET</th>
            <th>EXPECTED DATE OF COLLECTION WITHIN THE MONTH</th>
            <th>BUDGET HOLDER</th>
            <th>BUDGET Amount</th>
            <th>Currency</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              colSpan="9"
              style={{
                backgroundColor: "#fff",
                color: "black",
                letterSpacing: "0.105em",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              Cash out Flow
            </td>
          </tr>
          {updateData ? (
            <>
              {Object?.keys(updateData)?.map((projectName, index) => (
                <React.Fragment key={projectName}>
                  <tr
                    style={{
                      backgroundColor: "#064b4f",
                      color: "#fff",
                      letterSpacing: "0.105em",
                      fontWeight: "bolder",
                      fontSize: "14px",
                    }}
                  >
                    <td className="d-flex align-items-center gap-2" colSpan="1">
                      <input
                        type="checkbox"
                        checked={selectedProjects[projectName] || false}
                        onClick={(e) => handleSelectAll(e, projectName, data)}
                      />
                      Select All
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                      colSpan="6"
                    >
                      {updateData[projectName][0]?.project_name ||
                        updateData[projectName][0]?.project?.project_name}{" "}
                    </td>

                    {/* <td></td> */}
                    <td
                      colSpan="4"
                      style={{ borderTopRightRadius: "6px", cursor: "pointer" }}
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      View Detail
                    </td>
                  </tr>

                  {updateData[projectName]?.flat().map((e, index) => {
                    return (
                      <tr
                        className="text-start"
                        key={`${projectName}-${index}`}
                        style={{
                          backgroundColor: "#ddd",
                          color: "#000",
                          letterSpacing: "0.105em",
                          fontWeight: "400",
                          fontSize: "14px",
                          border: "1px solid #e2e7f0",
                        }}
                      >
                        <td>
                          <input
                            type="checkbox"
                            checked={e?.approved}
                            onChange={() =>
                              handleSelectItem(projectName, index, data)
                            }
                          />
                        </td>
                        <td>{`IE-${e.project_name ? e.project_name : e?.project?.project_name}-00${index}`}</td>
                        <td>{e?.a_no}</td>
                        <td>{e?.a_description}</td>
                        <td>
                          {e?.order?.milestone_id
                            ? `Milestone ${e?.order?.milestone_id}`
                            : "-"}
                        </td>
                        <td>{date(e?.created_at ? e?.created_at : e?.to)}</td>
                        <td>
                          {e?.project
                            ? e?.project[0]?.project_participants?.find(
                                (items) =>
                                  items?.pivot?.role_id ==
                                  "96f8c153-79d5-40d1-b0b7-7fd70921f02e"
                              )?.name
                            : "-"}
                        </td>
                        <td>
                          {Number(
                            e?.total_payment
                              ? e?.total_payment
                              : e?.budgetAmount
                                ? e?.budgetAmount
                                : e.sum
                          ).toLocaleString()}
                        </td>
                        <td>
                          {[
                            { id: 1, name: "USD" },
                            { id: 2, name: "AUD" },
                            { id: 3, name: "CAD" },
                            { id: 4, name: "EUR" },
                            { id: 5, name: "GBP" },
                            { id: 6, name: "ZAR" },
                            { id: 7, name: "ETB" },
                            { id: 8, name: "Yuan" },
                          ]?.find((items) =>
                            items?.id == e.currency_id
                              ? e.currency_id
                              : e?.order?.currency_id
                          )?.name || "-"}
                        </td>
                      </tr>
                    );
                  })}
                  <tr
                    style={{
                      borderBottomLeftRadius: "6px",
                      borderBottomRightRadius: "6px",
                      backgroundColor: "#064b4f",
                      color: "#fff",
                      letterSpacing: "0.105em",
                      fontWeight: "bolder",
                      fontSize: "14px",
                    }}
                  >
                    <td
                      style={{
                        textAlign: "end",
                        borderBottomLeftRadius: "6px",
                        width: "auto",
                      }}
                      colSpan="5"
                    >
                      Total cost of{" "}
                      {updateData[projectName][0]?.project_name ||
                        updateData[projectName][0]?.project?.project_name}{" "}
                    </td>
                    {[
                      { id: 1, name: "USD" },
                      { id: 2, name: "AUD" },
                      { id: 3, name: "CAD" },
                      { id: 4, name: "EUR" },
                      { id: 5, name: "GBP" },
                      { id: 6, name: "ZAR" },
                      { id: 7, name: "ETB" },
                      { id: 8, name: "Yuan" },
                    ].map((i) => {
                      let proj = updateData[projectName]
                        .flat()
                        .filter(
                          (c) =>
                            (c?.currency_id
                              ? c?.currency_id
                              : c?.order?.currency_id) == i?.id
                        )
                        .map(
                          (e) => e?.total_payment || e?.budgetAmount || e?.sum
                        )
                        .reduce(
                          (previousValue, currentValue) =>
                            previousValue + currentValue,
                          0
                        );

                      if (proj > 0) {
                        return (
                          <td key={i.id}>
                            {Number(proj)?.toLocaleString()}
                            {i?.name}{" "}
                          </td>
                        );
                      }

                      return null; // If proj is not greater than 0, return null
                    })}

                    {/* <td style={{ borderBottomRightRadius: "6px" }}>
                      {Number(projectTotals[index]?.totalETB)?.toLocaleString()}
                    </td> */}
                  </tr>
                  <br />
                </React.Fragment>
              ))}
            </>
          ) : (
            <td
              colSpan="8"
              style={{
                backgroundColor: "#fff",
                color: "black",
                letterSpacing: "0.105em",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              <div className="d-flex gap-3">
                <div
                  className="spinner-border"
                  style={{ color: "#064b4f" }}
                  role="status"
                ></div>
                <span className="visually">Wait a few second</span>
              </div>
            </td>
          )}
        </tbody>
      </table>
      {dialog && (
        <ConfirmDelete
          action={HandleApprovalSubmit}
          setters={setDialog}
          setDialog={setDialog}
          loading={loading}
          dialog={`Are you to Approve the selected Project Budget `}
          buttonTitle={"Yes"}
        />
      )}
      {dialogTransfer && (
        <ConfirmDelete
          action={HandleApprovalSubmit}
          setters={setDialogTransfer}
          setDialog={setDialogTransfer}
          loading={loading}
          dialog={`Are you to Transfer the selected Project Budget to the next month `}
          buttonTitle={"Yes"}
        />
      )}
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        ></Box>
      </Modal>
    </div>
  );
}
