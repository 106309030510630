import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import AllPaymentCollection from "../../../components/Payment/Finance/AllPaymentCollection";
import FinanceProjectContainer from "../../../components/Payment/Finance/FinanceProjectContainer";
import ForexSideBar from "../../../components/Payment/ForexApproval/PaymentSideBar";
import "./finance.style.css";
export default function PaymentCollectionIndex({ type }) {
  return (
    <ForexSideBar name={type ? "On  Shore Collection" : "OffShore Collection"}>
      <Tabs
        defaultActiveKey="all"
        id="uncontrolled-tab-example"
        className="mb-3 d-flex justify-content-center bg-white rounded gap-4 border forexApprovalNav"
      >
        <Tab eventKey="all" title="All">
          <AllPaymentCollection type={type} />
        </Tab>
        <Tab eventKey="project" title="Project">
          <FinanceProjectContainer type={type} />
        </Tab>
      </Tabs>
    </ForexSideBar>
  );
}
