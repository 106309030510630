import React from "react";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import {
  useCreatePaymentCollectionMutation,
  useCreatePaymentTermMutation,
  useGetPaymentCollectionsQuery,
  useGetProjectPaymentTermQuery,
  useGetProjectQuery,
  useGetProjectsQuery,
} from "../../../features/SCMApi";
import DeleteIcon from "@mui/icons-material/Delete";
import NoRecord from "../../common/NoRecord";

export default function PaymentTerms(props) {
  const params = useParams();
  const { data: terms } = useGetProjectPaymentTermQuery(params?.id);

  function HandleCancel() {
    props.modal(false);
  }
  function date(dates) {
    const dateStr = dates;
    const date = new Date(dateStr);
    const options = {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);


    return formattedDate;
  }
  return (
    <div>
      <div id="myModal" className="medium-modal" onClick={HandleCancel}>
        <div
          className="medium-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="create-project-modal-title">
            <div>Project Payment Terms</div>
            <CloseIcon onClick={HandleCancel} />
          </div>

          {terms?.data?.length !== 0 ? (
            <div className="my-3  d-grid justify-content px-5">
              {terms?.data?.map((items) => (
                <>
                  <div className="d-flex justify-content-between align-items-baseline gap-5 mb-3 ">
                    <div className="paymentDetailInputLabel ">
                      <div className="d-grid gap-1">
                        <div>{date(items?.created_at)}</div>
                        <div className="origin-label">
                          {items?.is_offshore == 0 ? "Onshore" : "Offshore"}
                        </div>
                      </div>
                    </div>
                    <div className="origin-value text-end">
                      {Number(items?.amount)?.toLocaleString()}
                      <div className="origin-label">{items?.reason}</div>
                    </div>
                  </div>
                  <div className="border border-bottom"></div>
                </>
              ))}
            </div>
          ) : (
            <NoRecord />
          )}
        </div>
      </div>
    </div>
  );
}
