import React from "react";
import {
  useGetProjectsQuery,
  useGetSettingClientQuery,
} from "../../features/SCMApi";

export default function ProjectClient() {
  const { data: projects } = useGetProjectsQuery();
  const { data: clientInfo } = useGetSettingClientQuery();
  return (
    <div className="project-client">
      <div className="project-client-container">
        <div className="grid gap-1">
          <div className="home-number mb-2">{projects?.data?.length}</div>
          <div className="home-text mt-3">Active Project</div>
        </div>
        <div className="grid">
          <div className="home-number text-end mb-2">
            {clientInfo?.data?.length}
          </div>
          <div className="home-text mt-3">Clients</div>
        </div>
      </div>
    </div>
  );
}
