import axios from "axios";
import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../../api/endPoint";
import {
  useApprovalPitoPoMutation,
  useGetApproverQuery,
  useGetUserInfoQuery,
  useApprovalApproveMutation,
  useGetProjectQuery,
  useGetProjectRolePermissionQuery,
  useSendForApproverPAMutation,
  useApprovalRejectMutation,
  useApprovalRetractMutation,
} from "../../features/SCMApi";
import CheckProjectPermission from "../common/checkPermission/CheckProjectPermission";

export default function Approve(props) {
  const [show, setShow] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [retractConfirmation, setRetractConfirmation] = useState(false);
  function confirmationHandler() {
    setConfirmation(true);
  }
  function retractConfirmationHandler() {
    setRetractConfirmation(true);
  }
  let approvals = {
    order_id: props?.orderid,
  };
  const [approval, rep] = useApprovalPitoPoMutation();

  //Approval detail implementation
  const { data: loggedUser } = useGetUserInfoQuery();
  const [sendForApprover] = useSendForApproverPAMutation();
  const [approvalApprove] = useApprovalApproveMutation();
  const [approvalReject] = useApprovalRejectMutation();
  const [approvalRetract] = useApprovalRetractMutation();

  const paId = props?.paData && props?.paData.flat(1)[0]?.id;
  const { data: getApprover } = useGetApproverQuery({
    module_name: props?.isPA,
    id: paId,
  });
  let currentApproverUser =
    loggedUser &&
    getApprover &&
    getApprover?.data?.current.find((user) => user?.user_id === loggedUser?.id);
  let isPaApproved =
    getApprover?.data?.current &&
    getApprover?.data?.current.find((paData) => paData?.pa_approval == 1)
      ?.pa_approval == 1
      ? true
      : getApprover?.data?.current.find((paData) => paData?.pa_approval == 0)
            ?.pa_approval == 0
        ? false
        : getApprover?.data?.current.find((paData) => paData?.pa_approval == 2)
              ?.pa_approval == 2
          ? null
          : "";

  let feedBack =
    getApprover &&
    getApprover?.data?.history.find((user) => user?.feedback)?.feedback
      ? getApprover?.data?.history.find((user) => user?.feedback)?.feedback
      : false;
  let paApprovalStatus = {
    level: getApprover?.data?.current.find((paData) => paData?.level)?.level,
    finalApproved: isPaApproved == 1 ? true : isPaApproved == 0 ? false : null,
    userName: getApprover?.data?.current.find((paData) => paData?.level)?.name,
    feedback: feedBack,
  };

  let isApprover = currentApproverUser !== undefined ? true : false;
  // setTimeout(() => {
  //   props?.setApprovalStatus(paApprovalStatus);
  // }, 3000);

  
  const [feedback, setFeedBack] = useState("");

  let dataForApproval = {
    module_name: props?.isPA,
    module_id: paId,
    user_id: loggedUser?.id,
    feedback,
  };

  const [buttonDisable, setButtonDisable] = useState(false);

  const handleApprovals = () => {
    setButtonDisable(true);
    approvalApprove(dataForApproval)
      .unwrap()
      .then((response) => {
       
        setConfirmation(false);
        setButtonDisable(false);
      })
      .then((error) => {
        
        setButtonDisable(false);
      });
    // axios
    // .post(`${API_BASE_URL}/scm/approval/approve`, dataForApproval)
    // .then((response) => {
    //   setConfirmation(false);
    
    // })
    // .catch((err) =>
    
    // );
  };
  const handleDecline = () => {
    setButtonDisable(true);
    approvalReject(dataForApproval)
      .unwrap()
      .then((response) => {
        
        setConfirmation(false);
        setShow(false);
        setButtonDisable(false);
      });
    // axios
    //   .post(`${API_BASE_URL}/scm/approval/reject`, dataForApproval)
    //   .then((response) => {
    //     setConfirmation(false);
    
    //   })
    //   .catch((err) =>
 
    //   );
  };

  let finalApprovedd =
    isPaApproved == 1 ? true : isPaApproved == 0 ? false : null;

  

  //Retract approval functionality
  let currentLevel = getApprover?.data?.current[0]?.level;
  let approvalStage = getApprover && getApprover?.data?.current.length === 0;

  let approvedHistoryForCurrentApprover = getApprover?.data?.history?.find(
    (user) => user?.approval_type == 0 && user?.user_id == loggedUser?.id
  );

  let canRetract =
    approvedHistoryForCurrentApprover?.level + 1 === currentLevel
      ? true
      : false;

 

  const handleRetract = () => {
    setButtonDisable(true);
    approvalRetract(dataForApproval)
      .unwrap()
      .then((response) => {
        
        setRetractConfirmation(false);
        setButtonDisable(false);
      })
      .then((error) => {
       
        setButtonDisable(false);
      });
    // axios
    //   .post(`${API_BASE_URL}/scm/approval/retract`, dataForApproval)
    //   .then((response) => {
    //     setRetractConfirmation(false);
    
    //   })
    //   .catch((err) =>
   
    //   );
  };
  let PaApproved = {
    pa_id: paId,
    profit: props?.profit,
  };
  let totalProfit = props?.gplTotal - props?.newTotal;
  
  const handelSendForApproval = () => {
    setButtonDisable(true);

    sendForApprover(PaApproved)
      .unwrap()
      .then((response) => {
        
        setButtonDisable(false);
      })
      .then((error) => {
        
        setButtonDisable(false);
      });
    // axios
    //   .post(`${API_BASE_URL}/scm/sourcing/orders/send_approval`, PaApproved)
    //   .then((response) => {
    
    //     approvalStage = false;
    //   })
    //   .catch((err) =>
    
    //   );
  };
  const { data: projectmembers, isSuccess } = useGetProjectQuery(
    props?.projectid
  );
  const { data: roleRoles } = useGetProjectRolePermissionQuery(
    props?.projectid
  );
  const { data: userinfo } = useGetUserInfoQuery();
  

  return (
    <div>
      <div className="summarymanufacturer-header-BcStage">
        <div className="no"></div>
        <div className="no"></div>
        <div className="label-container">
          <div className="pa-label">Total Budget</div>
          <div className="fw-bold">
            {props?.type == "BcStage" ? (
              <span>Budget : </span>
            ) : props?.type == "PaStage" ? (
              <span>BoQ Total Cost : </span>
            ) : (
              ""
            )}
            {props.gplTotal
              ? props.gplTotal === 0
                ? props.gplTotal?.toLocaleString()
                : props.gplTotal?.toLocaleString()
              : "  -  "}
          </div>
        </div>
        <div className="label-container">
          {props?.type == "BcStage" ? (
            <>
              <div className="pa-label">Total Used Amount</div>
              <div className="fw-bold">
                <span>Total Used Amount : </span>{" "}
                {props.newTotal
                  ? props.newTotal === 0
                    ? props.newTotal?.toLocaleString()
                    : props.newTotal?.toLocaleString()
                  : "  -  "}
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        <div className="label-container">
          {props?.type == "BcStage" ? (
            <>
              {" "}
              <div className="pa-label">New Total Cost</div>
              <div className="fw-bold">
                <span> Total Cost : </span>
                {props.totalCost
                  ? props.totalCost === 0
                    ? props.totalCost?.toLocaleString()
                    : props.totalCost?.toLocaleString()
                  : "  -  "}
              </div>
            </>
          ) : props?.type == "PaStage" ? (
            <>
              <div className="pa-label">New Total Cost</div>
              <div className="fw-bold">
                <span> Total Cost : </span>
                {props.newTotal
                  ? props.newTotal === 0
                    ? props.newTotal?.toLocaleString()
                    : props.newTotal?.toLocaleString()
                  : "  -  "}
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        <div
          className={`Profit py-3 ${
            totalProfit == 0
              ? "zero"
              : totalProfit > 0
                ? "positive"
                : totalProfit < 0
                  ? "negative"
                  : ""
          }`}
        >
          {" "}
          {props?.type == "BcStage" ? <span>Variance : </span> : ""}
          {totalProfit
            ? totalProfit == 0
              ? totalProfit?.toLocaleString()
              : totalProfit?.toLocaleString()
            : "  --  "}
        </div>
      </div>
      {isPaApproved !== true &&
      isApprover === true &&
      getApprover?.data?.current[0]?.pa_approval !== 2 ? (
        <div className="d-flex justify-content-center gap-3 mt-3">
          <button
            type="button"
            class="decline-btn mb-2"
            onClick={() => setShow((prev) => !prev)}
          >
            Decline
          </button>
          <button
            type="button"
            class="approval-btn"
            onClick={confirmationHandler}
          >
            Approve
          </button>
        </div>
      ) : (
        ""
      )}
      {isPaApproved !== true && canRetract === true ? (
        <div className="d-flex justify-content-center gap-3 mt-3">
          <button
            type="button"
            class="approval-btn my-3"
            onClick={retractConfirmationHandler}
            style={{ backgroundColor: "#FF5252" }}
          >
            Retract Approval
          </button>
        </div>
      ) : (
        <></>
      )}
      {CheckProjectPermission(
        "pa_approval",
        projectmembers,
        userinfo,
        roleRoles
      ) ? (
        approvalStage === true && canRetract === false ? (
          <div className="d-flex justify-content-center my-3">
            <button
              className={
                approvalStage === true
                  ? "send-for-approval-btn"
                  : "send-for-approval-btn-dissable"
              }
              onClick={handelSendForApproval}
              // disabled={approvalStage === true ? false : true}
              disabled={buttonDisable}
            >
              Send for Approval
            </button>
          </div>
        ) : (
          ""
        )
      ) : (
        ""
      )}
      {show && getApprover?.data?.current[0]?.pa_approval !== 2 ? (
        <div class="pa-comment-container">
          <div className="prg p-2">Comment</div>
          <textarea
            className="po-comment-section mx-3"
            onChange={(e) => setFeedBack(e.target.value)}
          ></textarea>
          <button
            type="button"
            class="comment-decline-btn mx-3 my-1"
            onClick={handleDecline}
            disabled={buttonDisable}
          >
            Decline
          </button>
          <button
            type="button"
            class="comment-decline-btn mx-3 my-1"
            style={{
              backgroundColor: "#fff",
              color: "#606060",
              border: "1px solid #606060",
            }}
            onClick={() => setShow((prev) => !prev)}
          >
            Cancel
          </button>
        </div>
      ) : (
        <></>
      )}
      {confirmation ? (
        <div
          id="Modal"
          className="dialog-modal "
          onClick={(e) => {
            setConfirmation(false);
          }}
        >
          <div
            className="dialog-modal-content"
            onClick={(e) => e.stopPropagation()}
            style={{ height: "30%" }}
          >
            <div className="dialog-info text-center">
              Are you sure to approve
              <div className="prg text-center mt-2">{props?.ordername}</div>
            </div>
            <div className="confirm-dialog-controller">
              <div
                className="dialog-cancel-btn"
                onClick={(e) => {
                  setConfirmation(false);
                }}
              >
                Cancel
              </div>
              <button
                type={"submit"}
                onClick={handleApprovals}
                className="dialog-delete-btn"
                disabled={buttonDisable}
              >
                Approve
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {retractConfirmation ? (
        <div
          id="Modal"
          className="dialog-modal "
          onClick={(e) => {
            setConfirmation(false);
          }}
        >
          <div
            className="dialog-modal-content"
            onClick={(e) => e.stopPropagation()}
            style={{ height: "30%" }}
          >
            <div className="dialog-info text-center">
              Are you sure to retract
              <div className="prg text-center mt-2">{props?.ordername}</div>
            </div>
            <div className="confirm-dialog-controller">
              <div
                className="dialog-cancel-btn"
                onClick={(e) => {
                  setRetractConfirmation(false);
                }}
              >
                No
              </div>
              <button
                type={"submit"}
                onClick={handleRetract}
                disabled={buttonDisable}
                className="dialog-delete-btn"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
