import Settingsidebar from "../../pages/Settings/SettingIndex";
import "./Settings.css";
import "./paymentSettings.css";
import AddIcon from "@mui/icons-material/Add";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useState } from "react";
import AddShipmentDestination from "./SettingModal/AddShipmentDestination";
import AddShipmentMode from "./SettingModal/AddShipmentMode";
import AddLoadingPort from "./SettingModal/AddLoadingPort";
import {
  useGetpaymentIncotermQuery,
  useGetSettingShipmentDestinationsQuery,
  useGetSettingShipmentModeQuery,
  useGetShipmentModeQuery,
  useGetShipmentModesQuery,
} from "../../features/SCMApi";
import usePagination from "../Pagination/Pagination";
import { Pagination } from "@mui/material";
import AddIncotermsModal from "./SettingModal/AddIncotermsModal";

export default function SettingShipment() {
  const [shipmentDestination, setShipmentDestination] = useState(false);
  const [shipmentMode, setShipmentMode] = useState(false);
  const [loadingPort, setloadingPort] = useState(false);
  const { data: shipmentModes } = useGetSettingShipmentModeQuery();
  const { data: shipmentDestinations } =
    useGetSettingShipmentDestinationsQuery();
 
  let [page, setPage] = useState(1);
  const PER_PAGE = 5;

  const count = Math.ceil(shipmentDestinations?.data?.length / PER_PAGE);
  const _DATA = usePagination(shipmentDestinations?.data, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  const { data: incoterm } = useGetpaymentIncotermQuery();
  const [showIncoterm, setshowIncoterm] = useState(false);

  return (
    <Settingsidebar name={"Shipment"}>
      <AddShipmentDestination
        onClose={() => setShipmentDestination(false)}
        shipmentDestination={shipmentDestination}
      />
      <AddShipmentMode
        onClose={() => setShipmentMode(false)}
        shipmentMode={shipmentMode}
      />
      <AddLoadingPort
        onClose={() => setloadingPort(false)}
        loadingPort={loadingPort}
      />
      <AddIncotermsModal
        onClose={() => setshowIncoterm(false)}
        showIncoterm={showIncoterm}
      />
      <div className="page">
        <div className="pbody-container">
          <div className="pcard shipment-destination">
            <section className="pi-header">
              <h3>Shipment Destination</h3>
            </section>
            {_DATA?.currentData()?.map((items) => (
              <section className="pi-body">
                <p>{items.port_name}</p>
                <div>
                  <ModeEditOutlineOutlinedIcon sx={{ color: "#52FFAC" }} />
                  <DeleteOutlineOutlinedIcon sx={{ color: "#FF5252" }} />
                </div>
              </section>
            ))}

            {count > 1 ? (
              <Pagination
                count={count}
                size="large"
                page={page}
                variant="outlined"
                shape="rounded"
                onChange={handleChange}
              />
            ) : (
              ""
            )}
            <section className="pi-footer">
              <div
                onClick={() => setShipmentDestination(true)}
                className="pi-footer-icon"
              >
                <AddIcon />
              </div>
            </section>
          </div>
          <div className="pcard shipment-mode">
            <section className="pi-header">
              <h3>Shipment Mode</h3>
            </section>
            {shipmentModes?.data?.map((items) => (
              <section className="pi-body">
                <p>{items?.shipment_mode}</p>
                <div>
                  <ModeEditOutlineOutlinedIcon sx={{ color: "#52FFAC" }} />
                  <DeleteOutlineOutlinedIcon sx={{ color: "#FF5252" }} />
                </div>
              </section>
            ))}

            <section className="pi-footer">
              <div
                onClick={() => setShipmentMode(true)}
                className="pi-footer-icon"
              >
                <AddIcon />
              </div>
            </section>
          </div>
          <div className="pcard incoterm">
            <section className="pi-header">
              <h3>Incoterm</h3>
            </section>
            {incoterm?.data?.map((items) => (
              <section className="pi-body">
                <p>{items.incoterm_name}</p>
                <div>
                  <ModeEditOutlineOutlinedIcon sx={{ color: "#52FFAC" }} />
                  <DeleteOutlineOutlinedIcon sx={{ color: "#FF5252" }} />
                </div>
              </section>
            ))}

            <section className="pi-footer">
              <div
                onClick={() => setshowIncoterm(true)}
                className="pi-footer-icon"
              >
                <AddIcon />
              </div>
            </section>
          </div>
        </div>
      </div>
    </Settingsidebar>
  );
}
