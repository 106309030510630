import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import ForexIe from "../../../pages/Payment/ForexApproval/ForexIeIndex";
import ForexClient from "../../../pages/Payment/ForexApproval/ForexClientIndex";
import "../forex.style.css";

export default function ForexApprovalNavBar(props) {
  return (
    <Tabs
      defaultActiveKey="ieInitiated"
      id="uncontrolled-tab-example"
      className="mb-3 d-flex justify-content-center bg-white rounded gap-4 border forexApprovalNav"
    >
      <Tab eventKey="ieInitiated" title="IE Initiated">
        <ForexIe search={props.search} />
      </Tab>
      <Tab eventKey="clientInitiated" title="Client Initiated" className="">
        <ForexClient search={props.search} />
      </Tab>
    </Tabs>
  );
}
