import CloseIcon from "@mui/icons-material/Close";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { FormControl } from "react-bootstrap";
import {
  useAssignShipmentMutation,
  useGetFrightForwardersQuery,
  useGetSuppliersQuery,
} from "../../features/SCMApi";
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../api/endPoint";
import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import CreateGroupModal from "./CreateGroupModal";
import CreateMissedItem from "./CreateMissedItem";
import ExistingGroupModal from "./ExistingGroupModal";
export default function ItemDeliveryModal(props) {
 
  function HandleClose() {
    props.ToggleModal(false);
  }
 
 
  return (
    <div id="myModal" className="mini-modal" onClick={HandleClose}>
      <div className="mini-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="create-project-modal-title d-flex justify-content-between align-items-baseline p-2">
          <div>Shipment Arrangement</div>
          <CloseIcon onClick={HandleClose} />
        </div>
        <Tabs
          defaultActiveKey="Missed"
          id="uncontrolled-tab-example"
          className="d-flex justify-content-center bg-white gap-5"
        >
          <Tab eventKey="Missed" title="Missed Items" className="mb-3">
            <CreateMissedItem
              ToggleModal={props?.ToggleModal}
              items={props?.items}
            />
          </Tab>
          <Tab eventKey="creategroup" title="Create Group" className="mb-3">
            <CreateGroupModal
              ToggleModal={props?.ToggleModal}
              items={props?.items}
              group={props?.group}
            />
          </Tab>

          <Tab eventKey="existingGroup" title="Existing Group" className="mb-3">
            <ExistingGroupModal ToggleModal={props?.ToggleModal} />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}
