import React, { useState } from "react";
import SourcingIndex from "../../pages/Sourcing/SourcingIndex";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import usePagination from "../Pagination/Pagination";
import { IconButton, Pagination } from "@mui/material";
import {
  useAddEtdBatchMutation,
  useGetEtaDetailQuery,
  useGetEtasQuery,
  useGetProjectQuery,
  useGetProjectRolePermissionQuery,
  useGetProjectsQuery,
  useGetUserInfoQuery,
} from "../../features/SCMApi";
import { useParams } from "react-router-dom";
import { CiEdit } from "react-icons/ci";
import { IoAdd } from "react-icons/io5";
import CheckPermission from "../common/checkPermission/checkPermission";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import CheckProjectPermission from "../common/checkPermission/CheckProjectPermission";

const EtaSummaryPage = () => {
  const params = useParams();

  const [search, setSearch] = useState("");
  let [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const [showedit, setShowEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [Messages, setMessages] = useState();

  const { data: etaDetail } = useGetEtaDetailQuery(selectedItem?.id);
  const [batch, setBatch] = useState(etaDetail?.data[0]?.batch);
  const [number_of_batch, setNumberofBatch] = useState(
    etaDetail?.data[0]?.number_of_batch === null
      ? 1
      : etaDetail?.data[0]?.number_of_batch
  );
  const [etd, setETD] = useState(etaDetail?.data[0]?.etd);

  const [AddEmailHandoverDate, res] = useAddEtdBatchMutation();
  const { data: userinfo } = useGetUserInfoQuery();
  const { data: projectmembers } = useGetProjectQuery(params?.id);
  const { data: roleRoles } = useGetProjectRolePermissionQuery(params?.id);
  const { data: response } = useGetProjectsQuery();
  const { data: eta, isLoading: isEtaLoading } = useGetEtasQuery(params.id);

  
  const Projectdata = [];

  response?.data?.map((items) => {
    if (params.id === items.id) {
      Projectdata.push(items);
    }
  });

  let counter = 0;
  let batchpush = [];
  for (let i = 1; i <= number_of_batch; i++) {
    batchpush.push({
      id: i,
      name: `Batch ${i}`,
    });
  }

  // ============> FILTER SECTION <==============

  const PER_PAGE = 8;
  const filteredData = () => {
    return eta?.data
      ?.filter((items) => {
        if (search === "") {
          return items;
        } else if (
          items?.supplier?.distributor_name
            ?.toLowerCase()
            .includes(search.toLowerCase())
        ) {
          return items;
        }
      })
      .map((items) => items);
  };

  const _DATA = usePagination(filteredData() || [], PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const data = filteredData();
  let count = 0;

  if (Array.isArray(data)) {
    count = Math.ceil(data.length / PER_PAGE);
    
  } else {
    
  }

  // ============> ADD SECTION <==============
  const handleOpenAddModal = (item) => {
    setSelectedItem(item);
    setShow(true);
  };

  let emailhandover = {
    id: selectedItem?.id,
    etd,
    number_of_batch,
    batch,
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    
    AddEmailHandoverDate(emailhandover)
      .unwrap()
      .then((response) => {
        
        setMessages("Added Successfully");
        setShow(false);
      })
      .catch((error) => {
        
      });
  };

  // ============> EDIT SECTION <==============
  const handleOpenEditModal = (item) => {
    setSelectedItem(item);
    setShowEdit(true);
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    
    AddEmailHandoverDate(emailhandover)
      .unwrap()
      .then((response) => {
        
        setMessages("Update Successfully");
        setShowEdit(false);
      })
      .catch((error) => {
        
      });
  };

  return (
    <SourcingIndex
      name={Projectdata[0]?.project_name}
      names="ETA Followup"
      Message={Messages}
    >
      <div className="payable-container">
        <div className="Payable-Table-Container prg">
          <div className="d-flex gap-3 justify-content-end">
            <Link
              className="download-btn"
              style={{
                borderRadius: "4px",
                width: "133px",
              }}
              to="../"
            >
              <div
                className="d-flex justify-content-around align-items-center"
                style={{
                  color: "#ffffff",
                }}
              >
                <IoMdArrowRoundBack />
                <div className="px-2">Go back</div>
              </div>
            </Link>
          </div>
          <div className="input-group m-4">
            <div className="form-outline" data-mdb-input-init>
              <div style={{ position: "relative", width: "250px" }}>
                <input
                  type="text"
                  value={search}
                  onChange={(e) => handleSearchChange(e)}
                  placeholder="Enter supplier name ..."
                  style={{
                    width: "100%",
                  }}
                />
              </div>
            </div>
            <button
              onClick={handleSearchChange}
              type="button"
              style={{ width: "30px", background: "#186569", color: "white" }}
              className="btn btn-success"
              data-mdb-ripple-init
            >
              <FaSearch />
            </button>
          </div>
          <div className="table-responsive border m-[20px]">
            <table className="table table-bordered">
              <thead>
                <tr
                  className="prg"
                  style={{
                    text: "14px",
                    backgroundColor: "rgb(0, 112, 192, 0.2)",
                  }}
                >
                  <th>No. </th>
                  <th>Supplier Name</th>
                  <th>Order Name</th>
                  <th>Order Type</th>
                  <th>ETD</th>
                  <th>Batch</th>
                  <th>No. of Batch </th>
                  <th>Action </th>
                </tr>
              </thead>
              <tbody>
                {isEtaLoading ? (
                  <tr>
                    <td colSpan="8">
                      <p>Loading...</p>
                    </td>
                  </tr>
                ) : _DATA.currentData().length > 0 ? (
                  _DATA.currentData().map((items, index) => (
                    <tr key={index}>
                      <td>{(counter += 1)}</td>
                      <td>{items?.supplier?.distributor_name}</td>
                      <td>{items?.order_name}</td>
                      <td>{items?.order_type}</td>
                      <td>{items?.etd ? items?.etd : "-"}</td>
                      <td>{items?.batch ? items?.batch : "-"}</td>
                      <td>
                        {items?.number_of_batch ? items?.number_of_batch : "-"}
                      </td>
                      <td className="d-flex gap-3 align-items-center justify-content-center">
                        {CheckProjectPermission(
                          "update_logistics_etd",
                          projectmembers,
                          userinfo,
                          roleRoles
                        )
                          ? !show && (
                              <IconButton
                                variant="text"
                                size="small"
                                sx={{
                                  zIndex: 1,
                                  backgroundColor: "#fff",
                                  color: "#186569",
                                  width: "30px",
                                  height: "30px",
                                  "&:hover": {
                                    backgroundColor: "#186569",
                                    color: "#fff",
                                    width: "30px",
                                    height: "30px",
                                  },
                                }}
                                onClick={() => handleOpenAddModal(items)}
                              >
                                <IoAdd />
                              </IconButton>
                            )
                          : ""}
                        {CheckPermission("update_logistics_etd", userinfo)
                          ? !showedit && (
                              <IconButton
                                variant="text"
                                size="small"
                                sx={{
                                  zIndex: 1,
                                  backgroundColor: "#fff",
                                  color: "#186569",
                                  width: "30px",
                                  height: "30px",
                                  "&:hover": {
                                    backgroundColor: "#186569",
                                    color: "#fff",
                                    width: "30px",
                                    height: "30px",
                                  },
                                }}
                                onClick={() => handleOpenEditModal(items)}
                              >
                                <CiEdit />
                              </IconButton>
                            )
                          : ""}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8">
                      <p>No data available</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {count > 1 && (
            <Pagination
              style={{
                display: "flex",
                justifyContent: "right",
                marginTop: "20px",
              }}
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            />
          )}
        </div>
      </div>
      {show && (
        <div className="pi-modal" onClick={() => setShow(false)}>
          <div
            className="pi-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="pi-modal-header">
              <h3>Add ETD and Batch</h3>
            </div>
            <hr />
            <div className="pi-modal-content">
              <form className="pi-form">
                <div className="pi-inputs">
                  <section className="pi-inputs-modal">
                    <label>ETD </label>
                    <input
                      style={{ width: "294px" }}
                      type="date"
                      className="supplier-text-input"
                      onChange={(e) => setETD(e.target.value)}
                    />
                  </section>
                </div>
                <div className="pi-inputs">
                  <section className="pi-inputs-modal">
                    <label>Number of Batch </label>
                    <input
                      style={{ width: "294px" }}
                      type="number"
                      value={number_of_batch}
                      className="supplier-text-input"
                      placeholder="Please enter the number of batch fot this order"
                      onChange={(e) => setNumberofBatch(e.target.value)}
                    />
                  </section>
                </div>
                <div className="pi-inputs">
                  <section className="pi-inputs-modal">
                    <label>Batch </label>
                    <select
                      name=""
                      id=""
                      className="select-option-create-order "
                      style={{ width: "294px" }}
                      onChange={(e) => setBatch(e.target.value)}
                    >
                      <option selected="true" disabled="disabled">
                        Choose Batch
                      </option>
                      {batchpush?.map((items) => (
                        <option key={items.id} value={items.id}>
                          {items?.name}
                        </option>
                      ))}
                    </select>
                  </section>
                </div>
                <div className="pi-modal-footer">
                  <button onClick={() => setShow(false)}>Cancel</button>
                  <button onClick={handleAdd}>Save</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {showedit && (
        <div className="pi-modal" onClick={() => setShowEdit(false)}>
          <div
            className="pi-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="pi-modal-header">
              <h3>Edit ETD and Batch</h3>
            </div>
            <hr />
            <div className="pi-modal-content">
              <form className="pi-form">
                <div className="pi-inputs">
                  <section className="pi-inputs-modal">
                    <label>ETD </label>
                    <input
                      defaultValue={selectedItem?.etd}
                      style={{ width: "294px" }}
                      type="date"
                      className="supplier-text-input"
                      onChange={(e) => setETD(e.target.value)}
                    />
                  </section>
                </div>
                <div className="pi-inputs">
                  <section className="pi-inputs-modal">
                    <label>Number of Batch </label>
                    <input
                      defaultValue={selectedItem?.number_of_batch}
                      style={{ width: "294px" }}
                      type="number"
                      value={number_of_batch}
                      className="supplier-text-input"
                      placeholder="Please enter the number of batch fot this order"
                      onChange={(e) => setNumberofBatch(e.target.value)}
                    />
                  </section>
                </div>
                <div className="pi-inputs">
                  <section className="pi-inputs-modal">
                    <label>Batch </label>
                    <select
                      value={selectedItem?.batch}
                      name=""
                      id=""
                      className="select-option-create-order "
                      style={{ width: "294px" }}
                      onChange={(e) => setBatch(e.target.value)}
                    >
                      <option selected="true" disabled="disabled">
                        Choose Batch
                      </option>
                      {batchpush?.map((items) => (
                        <option key={items.id} value={items.id}>
                          {items?.name}
                        </option>
                      ))}
                    </select>
                  </section>
                </div>
                <div className="pi-modal-footer">
                  <button onClick={() => setShowEdit(false)}>Cancel</button>
                  <button onClick={handleEdit}>Edit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </SourcingIndex>
  );
};

export default EtaSummaryPage;
