import React from "react";
import WestIcon from "@mui/icons-material/West";
import { IMG_BASE_URL } from "../../../../api/endPoint";
import { IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import {
  useApprovalApproveMutation,
  useApprovalRejectMutation,
  useGetApproverQuery,
  useGetProjectQuery,
  useGetUserInfoQuery,
  useItemsConsumeConfirmMutation,
  useItemsInventoryAcceptMutation,
  useItemsInventoryConfirmMutation,
} from "../../../../features/SCMApi";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import StockDialog from "../../../common/StockDialog";
import InventoryApprovalModal from "../../../common/InventoryApprovalModal";
import InventoryApprovalRejectModal from "../../../common/InventoryApprovalRejectModal";
import { date } from "../../../dateFormator";

export default function ConsumedInventoryItemDescription(props) {
  const [confirm, setConfirm] = useState(false);
  const [quantity, setQuantity] = useState(null);
  const [Accept, setAccept] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [remark, setRemark] = useState(null);
  const [error, setError] = useState(null);
  function HandleRequest() {
    setConfirm(true);
  }

  const [ItemConfirm, src] = useItemsConsumeConfirmMutation();

  const { data: loggedUser } = useGetUserInfoQuery();
  const { data: projects } = useGetProjectQuery(props?.data?.requested_project);

  const params = useParams();
  function HandleinventoryRequest(e, items) {
    let requested = {
      id: params?.id,
    };
    ItemConfirm(requested)
      .unwrap()
      .then((response) => {
        setConfirm(false);
        window.location.replace("/stocks/requests/returned/items");
      })
      .catch((error) => {
        console.log(error, "erorrrrr");
        setError(error?.data?.message);
      });
  }

  const { data: userinfo } = useGetUserInfoQuery();
  let SK = userinfo?.role?.role_name == "Store Keeper";
  let FE = userinfo?.role?.role_name == "Field Engineer";
  let PM = userinfo?.role?.role_name == "Project Manager";
  let confirmed_by = userinfo?.id;
  const { data: getApprover } = useGetApproverQuery({
    module_name: "Inventory",
    id: props?.data?.id,
  });

  return (
    <div className="tool-item-description mt-3">
      <div className="d-flex justify-content-between">
        <NavLink className="create-project-btn mb-3" to={`/stocks/requests`}>
          <WestIcon
            sx={{
              "&:hover": {
                backgroundColor: "#fff",
                color: "#186569",
              },
            }}
          />
          Go Back
        </NavLink>
      </div>
      <div className="d-flex align-items-center gap-5">
        {props?.data?.image == null ? (
          <img
            className="tool-item-image"
            src="https://flowbite.com/docs/images/examples/image-1@2x.jpg"
            alt={props?.data?.item_name}
          />
        ) : (
          <img
            className="tool-item-image"
            src={`${IMG_BASE_URL}${props?.data?.image}`}
            alt={props?.data?.item_name}
          />
        )}

        <div className="">
          <div className="h4">{props?.data?.item_name}</div>
          <div className="d-flex align-items-baseline gap-1 mt-3 mb-4">
            <div className="tool-item-title">Location</div>
            <div className="small mt-0">
              {props?.data?.store_name == null
                ? "Unknown"
                : props?.data?.store_name}
            </div>
          </div>
          <div className="d-flex align-items-baseline gap-1 mt-3 mb-4">
            <div className="tool-item-title">Requested Date:</div>
            <div className="small mt-0">
              {props?.data?.requested_date == null
                ? "Unknown"
                : date(props?.data?.requested_date)}
            </div>
          </div>
          <div className="d-flex align-items-baseline gap-1 mt-3 mb-4">
            <div className="tool-item-title">Requested By:</div>
            <div className="small mt-0">
              {props?.data?.requester_name == null
                ? "Unknown"
                : props?.data?.requester_name}
            </div>
          </div>
          <div className="d-flex align-items-baseline gap-1 mt-3 mb-4">
            <div className="tool-item-title">Approved By:</div>
            <div className="small mt-0">
              {props?.data?.confirmed_user == null
                ? "Unknown"
                : props?.data?.confirmed_user}
            </div>
          </div>
          <div className="d-flex align-items-baseline gap-1 mt-3 mb-4">
            <div className="tool-item-title">Approved Date:</div>
            <div className="small mt-0">
              {props?.data?.confirmed_date == null
                ? "Unknown"
                : date(props?.data?.confirmed_date)}
            </div>
          </div>

          <div className="d-flex align-items-baseline gap-1 mt-3 mb-4">
            <div className="tool-item-title">Project :</div>
            <div className="small mt-0">
              {props?.data?.requested_project_name == null
                ? "Unknown"
                : props?.data?.requested_project_name}
            </div>
          </div>
          <div className="d-flex align-items-baseline gap-1 mt-3 mb-4">
            <div className="tool-item-title">Quantity :</div>
            <div className="small mt-0">
              {props?.data?.requested_quantity == null
                ? "Unknown"
                : props?.data?.current_consumed_amount}
            </div>
          </div>

          <div className="d-flex align-items-baseline gap-1 mt-3 mb-4">
            <div className="tool-item-title">Description: </div>
            <div className="small mt-0">
              {props?.data?.description == null
                ? "-"
                : props?.data?.description}
            </div>
          </div>
          {props?.data?.status_name != null ? (
            <div className="d-flex  gap-1 mt-3 align-items-baseline">
              <div className="tool-item-title">Status</div>
              <div className="small">{props?.data?.status_name}</div>
            </div>
          ) : (
            ""
          )}
          <br />

          <td className="text-start" style={{ display: "flex" }}>
            <button
              className="form-save"
              onClick={() => {
                HandleRequest();
                setDialog(props?.data);
              }}
            >
              Confirm
            </button>
          </td>
        </div>
      </div>

      {confirm ? (
        <StockDialog
          project_id={1}
          setDialog={setConfirm}
          dialog={`${dialog?.item_name} `}
          data={dialog}
          receive_title={"ho"}
          name="confirm"
          action={(e) => HandleinventoryRequest(e, dialog)}
          setQuantity={setQuantity}
          error={error}
        />
      ) : (
        ""
      )}
    </div>
  );
}
