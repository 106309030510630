import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetSuppliersOrdersQuery,
  usePriceComparisionAttachementMutation,
} from "../../../features/SCMApi";
import attachmentIcon from "../../../assets/SVG/attach.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import Select from "react-select";

export default function PiAttachment(props) {
  const params = useParams();
  const [formData, setFormData] = useState([
    { supplier_id: "", pi: null, name: "", priceComparison_id: params?.f_id },
  ]);
  const { data: suppliers } = useGetSuppliersOrdersQuery();

  const addForm = () => {
    setFormData([
      ...formData,
      {
        supplier_id: "",
        pi: null,
        name: "",
        priceComparison_id: params?.f_id,
      },
    ]);
  };
  const handleSelectChange = (selectedOption, index) => {
    const newFormData = [...formData];
    newFormData[index].supplier_id = selectedOption ? selectedOption.value : ""; // If selectedOption is null, set an empty string or any other default value
    setFormData(newFormData);
  };

  const handleFileChange = (event, index) => {
    const newFormData = [...formData];
    newFormData[index].pi = event.target.files[0];
    setFormData(newFormData);
  };
  const handleNameChange = (event, index) => {


    const newFormData = [...formData];
    newFormData[index].name = event.target.value;


    setFormData(newFormData);
  };
  const removeForm = (index) => {
    const newFormData = [...formData];
    newFormData.splice(index, 1);
    setFormData(newFormData);
  };
  let supplier_id = [];
  let pi = [];
  let name = [];
  let priceComparison_id = [];
  formData?.map((items) => supplier_id.push(items?.supplier_id));
  formData?.map((items) => pi.push(items?.pi));
  formData?.map((items) => name.push(items?.name));
  formData?.map((items) => priceComparison_id.push(items?.priceComparison_id));

  const [priceComparision, res] = usePriceComparisionAttachementMutation();
  const form = new FormData();
  supplier_id.forEach((item) => {
    form.append("supplier_id[]", item);
  });
  name.forEach((item) => {
    form.append("name[]", item);
  });
  pi.forEach((item) => {
    form.append("pis[]", item);
  });
  priceComparison_id.forEach((item) => {
    form.append("priceComparison_id[]", item);
  });
  const [comparisonModal, setComparisonModal] = useState(false);
  const [data, setData] = useState();

  const [loading, setLoading] = useState(false);
  const [error, setErrorMessage] = useState("");
  const [errordisplay, setErrorDisplay] = useState(false);
  let navigate = useNavigate();
  function HandleSubmit(e) {
    setLoading(true);
    e.preventDefault();

    priceComparision(form)
      .unwrap()
      .then((response) => {
        setLoading(false);
        props?.close(false);
        
      })
      .catch((error) => {
        setLoading(false);
        setErrorMessage(error?.data?.message);
        props?.close(false);
        setErrorDisplay(true);
        console.error(
          error,
          "Response for created order from backend for price err"
        );
      });
  }

  setTimeout(() => {
    setErrorDisplay(false);
  }, 10000);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(false);
  };
  
  function FormFields({ index, finalPriceData }) {
    const comparedSuppliers = Array?.from(
      new Set(finalPriceData?.map((item) => item.supplier))
    )?.map((id) => finalPriceData?.find((item) => item.supplier === id));
    
    return (
      <div className="supplier-attachment-container mb-2">
        <Select
          value={comparedSuppliers
            ?.filter(
              (items) => items?.supplier === formData[index]?.supplier_id
            )
            ?.map((item) => ({
              label: item?.SupplierName,
              value: item?.supplier,
            }))}
          className="supplier-selector"
          style={{ color: "black" }}
          onChange={(selectedOption) =>
            handleSelectChange(selectedOption, index)
          }
          options={comparedSuppliers?.map((item) => ({
            value: item.supplier,
            label: item.SupplierName,
          }))}
        />
        <div className="d-flex gap-3 align-items-center">
          <div className="attach-pi-text">Attach PI</div>
          <div
            className="price-comparison-file-chooser cl-9 px-0"
            style={{ backgroundImage: `url(${attachmentIcon})` }}
          >
            <input
              id="chooser"
              type={"file"}
              class="file-input"
              multiple
              onChange={(e) => handleFileChange(e, index)}
            />
            <label
              htmlFor="chooser"
              style={{
                position: "relative",
                bottom: "23px",
                left: "5px",
                width: "10rem",
              }}
            >
              {formData[index].pi ? formData[index].pi?.name : "Choose File"}
            </label>
          </div>
        </div>
        <div className="d-flex gap-3 align-items-center">
          <div className="attach-pi-text">Name</div>
          <input
            type={"text"}
            class="text-input"
            defaultValue={formData[index]?.name}
            onChange={(e) => handleNameChange(e, index)}
          />

          {formData?.length != 1 ? (
            <DeleteIcon
              onClick={() => removeForm(index)}
              style={{ color: "red" }}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
  
  return (
    <div class="modal ">
      <div
        style={{
          margin: "10px auto",
        }}
        role="document"
      >
        <div
          class="modal-content"
          style={{ marginLeft: 0, minHeight: 0, width: "100%" }}
        >
          <div class="modal-header">
            <h5 class="project-name" id="exampleModalScrollableTitle">
              Attach Supplier
            </h5>
            <div type="button" onClick={() => props?.close(false)}>
              <span className="" aria-hidden="true">
                &times;
              </span>
            </div>
          </div>
          <div className="ps-2">
            <button className="add-client-representative" onClick={addForm}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 16C7.75238 16 7.54762 15.919 7.38571 15.7571C7.22381 15.5952 7.14286 15.3905 7.14286 15.1429V8.85714H0.857143C0.609524 8.85714 0.404762 8.77619 0.242857 8.61429C0.0809525 8.45238 0 8.24762 0 8C0 7.75238 0.0809525 7.54762 0.242857 7.38571C0.404762 7.22381 0.609524 7.14286 0.857143 7.14286H7.14286V0.857143C7.14286 0.609524 7.22381 0.404762 7.38571 0.242857C7.54762 0.0809525 7.75238 0 8 0C8.24762 0 8.45238 0.0809525 8.61429 0.242857C8.77619 0.404762 8.85714 0.609524 8.85714 0.857143V7.14286H15.1429C15.3905 7.14286 15.5952 7.22381 15.7571 7.38571C15.919 7.54762 16 7.75238 16 8C16 8.24762 15.919 8.45238 15.7571 8.61429C15.5952 8.77619 15.3905 8.85714 15.1429 8.85714H8.85714V15.1429C8.85714 15.3905 8.77619 15.5952 8.61429 15.7571C8.45238 15.919 8.24762 16 8 16Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
          <div class="modal-body">
            {formData?.map((item, index) => (
              <FormFields
                finalPriceData={props?.finalPriceData}
                key={index}
                index={index}
              />
            ))}
          </div>
          <div class="modal-footer">
            {formData?.length != 1 ? (
              <div className="d-flex justify-content-end gap-4 m-4">
                <button
                  onClick={() => props?.close(false)}
                  className="dialog-cancel-btn"
                >
                  Cancel
                </button>
                <button
                  onClick={HandleSubmit}
                  disabled={loading}
                  className={
                    loading ? "form-save bg-secondary text-white" : "form-save"
                  }
                >
                  {loading ? "Loading....." : "Save"}
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
