import Close from "@mui/icons-material/Close";
import React from "react";
import { useState } from "react";
import {
  useAddInventoryTypeSettingMutation,
  useAddPaymentTermMutation,
  useGetProjectsQuery,
  useGetUnitOfMeasurementQuery,
  useGetUsersQuery,
} from "../../../features/SCMApi";
import "../../../components/Settings/SettingModal/modal.css";
import attachmentIcon from "../../../assets/SVG/attach.svg";
import InventoryForm from "./inventoryForm";
import ToolForm from "./ToolForm";
import EditInventory from "./EditInventory";

export default function RegisterItem(props) {
  const [InventoryType, res] = useAddInventoryTypeSettingMutation();
  const [inventory_type, setInventory_type] = useState(null);
  const [project_id, setproject] = useState(null);
  const [description, setDescription] = useState(null);

  let inventoryType = {
    inventory_type,
    description,
  };
  const HandleSubmit = async (e) => {
    e.preventDefault();
    InventoryType(inventoryType)
      .unwrap()
      .then((response) => {

        //  props.modal(false);
        // props.setMessages("Payment Term created successfully");
      })
      .then((error) => {

      });
  };
  function HandleCancel() {
    props.modal(false);
  }
  const [fragment, setFragment] = useState("inventory");
  function onChangeValue(event) {
    setFragment(event.target.value);

  }
  return (
    <div className="pi-modal" onClick={HandleCancel}>
      <div className="pi-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="pi-modal-header">
          <div className="d-flex justify-content-between">
            <h5> Register Item </h5>
            <Close onClick={HandleCancel} />
          </div>
        </div>
        <hr />
        <div className="pi-modal-content">
          <InventoryForm modal={props?.modal} />
        </div>
      </div>
    </div>
  );
}
