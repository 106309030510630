import React from "react";
import noDataSvg from "./.././../assets/SVG/noData.svg";

export default function NoRecord({ title = "No record has been found" }) {
  return (
    <div className="no-record">
      <img src={noDataSvg} alt="" />
      <div className="no-record-label">{title}</div>
    </div>
  );
}
