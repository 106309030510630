import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import IconButtons from "./IconButtons";
import {
  useGetForexDetailQuery,
  useGetUserInfoQuery,
} from "../../../features/SCMApi";
import CheckPermission from "../../common/checkPermission/checkPermission";
import { useParams } from "react-router-dom";

const TermAndConditions = styled(Paper)(({ theme }) => ({
  elevation: "0px",
  height: "395px",
  [theme.breakpoints.between("md", "lg")]: {
    marginLeft: "35px",
    marginRight: "-45px",
  },
  [theme.breakpoints.between("xs", "sm", "md")]: {
    // margin: "auto",
    marginTop: "-240px",
  },
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  border: "1px solid #AEB8C9",
  borderRadius: "6px",
}));

const TermAndConditionContainer = (props) => {
  const forexId = useParams();
  const { data: forexDetail } = useGetForexDetailQuery(forexId?.id);

  const termAndConditions = [
    {
      paymentMode: "Not Available",
      validityDate: "Not Available",
      openingDate: "Not Available",
      referenceNumber: "Not Available",
      openingTo: "Not Available",
      modeOfShipment: "Not Available",
      transShipment: "Not Available",
      partialShipment: "Not Available",
      countryOfOrigin: "Not Available",
      portOfLoading: "Not Available",
      portOfDischarge: "Not Available",
      freight: "Not Available",
      incoterm: "Not Available",
    },
  ];
  function getTermAndCondition() {
    return props.data?.map((data) =>
      data?.forex_term.length === 0 ? termAndConditions : data?.forex_term
    );
  }
  const hasTerm = props?.data?.map((data) => data?.forex_term).flat()?.length;

  const { data: userinfo } = useGetUserInfoQuery();

  return (
    <>
      {hasTerm !== 0 ? (
        <div style={{ height: "425px" }}>
          <TermAndConditions style={{ height: "425px" }} elevation={0}>
            <div className="forexTermAndConditions">Term & Condition</div>
            <div className="devider"></div>
            <div className="forextermAndConditions">
              <ul className="paymentTermAndConditionsLabel">
                <li>
                  <span className="paymentTcName">Payment Mode</span>
                </li>
                <li>
                  <span className="paymentTcName">Refc. Number</span>
                </li>
                <li>
                  <span className="paymentTcName">Validity Date</span>
                </li>
                <li>
                  <span className="paymentTcName">Approval Date</span>
                </li>
                <li>
                  <span className="paymentTcName">PI Date</span>
                </li>
                <li>
                  <span className="paymentTcName">Expiration Date</span>
                </li>
                <li>
                  <span className="paymentTcName">Remaining Date</span>
                </li>
                <li>
                  <span className="paymentTcName">Opening to</span>
                </li>
                <li>
                  <span className="paymentTcName">Mode of Shipment</span>
                </li>
                <li>
                  <span className="paymentTcName">Trans Shipment</span>
                </li>
                <li>
                  <span className="paymentTcName">Partial Shipment</span>
                </li>
                <li>
                  <span className="paymentTcName">Country of Origin</span>
                </li>
                <li>
                  <span className="paymentTcName">Port of Loading</span>
                </li>
                <li>
                  <span className="paymentTcName">Port of Discharge</span>
                </li>
                <li>
                  <span className="paymentTcName">Incoterm</span>
                </li>
              </ul>
              {getTermAndCondition()
                ?.flat()
                ?.map((values) => {
                  const maxLength = 35;
                  const distributorName =
                    values?.forex_opening_to?.distributor_name || "-";
                  const slicedDistributorName =
                    distributorName.length > maxLength
                      ? distributorName.slice(0, maxLength) + "..."
                      : distributorName;
                  return (
                    <ul className="paymentTermAndConditionsValue">
                      <li>
                        <span className="paymentTcValue">
                          {values?.forex_payment_mode?.payment_mode || "-"}
                        </span>
                      </li>
                      <li>
                        <span className="paymentTcValue">
                          {values?.reference_number || "-"}
                        </span>
                      </li>
                      <li>
                        <span className="paymentTcValue">
                          {values?.validity_date || "-"}
                        </span>
                      </li>
                      <li>
                        <span className="paymentTcValue">
                          {values?.approval_date || "-"}
                        </span>
                      </li>
                      <li>
                        <span className="paymentTcValue">
                          {values?.approval_date || "-"}
                        </span>
                      </li>
                      <li>
                        <span className="paymentTcValue">
                          {values?.expiration_date || "-"}
                        </span>
                      </li>
                      <li>
                        <span
                          className="paymentTcValue"
                          style={{
                            color:
                              parseFloat(values?.remaining_days) < 0
                                ? "red"
                                : "inherit",
                          }}
                        >
                          {values?.remaining_days || "-"}
                        </span>
                      </li>
                      <li>
                        <span
                          className="paymentTcValue"
                          title={distributorName}
                          arrow
                        >
                          {slicedDistributorName || "-"}
                        </span>
                      </li>
                      <li>
                        <span className="paymentTcValue">
                          {values?.forex_shipment_mode?.shipment_mode || "-"}
                        </span>
                      </li>
                      <li>
                        <span className="paymentTcValue">
                          {values?.trans_shipment || "-"}
                        </span>
                      </li>
                      <li>
                        <span className="paymentTcValue">
                          {values?.partial_shipment || "-"}
                        </span>
                      </li>
                      <li>
                        <span className="paymentTcValue">
                          {values?.forex_origin?.name || "-"}
                        </span>
                      </li>
                      <li>
                        <span className="paymentTcValue">
                          {values?.forex_origin?.name || "-"}
                        </span>
                      </li>
                      <li>
                        <span className="paymentTcValue">
                          {values?.forex_discharge_port?.port_name || "-"}
                        </span>
                      </li>
                      <li>
                        <span className="paymentTcValue">
                          {values?.incoterms?.incoterm_name || "-"}
                        </span>
                      </li>
                    </ul>
                  );
                })}
            </div>
            <div className="paymentTcEditButton">
              {CheckPermission("edit_term_and_condition", userinfo) ? (
                <IconButtons
                  name={hasTerm !== 0 ? "termCondition" : "nOTermCondition"}
                  previousData={getTermAndCondition()
                    ?.flat()
                    .map((values) => values)}
                  setSuccessMessage={props?.successMessage}
                  setMessage={props?.message}
                />
              ) : (
                ""
              )}
            </div>
          </TermAndConditions>
        </div>
      ) : (
        <>
          <TermAndConditions style={{ height: "425px" }} elevation={0}>
            <div className="forexTermAndConditions">Term & Condition</div>
            <div className="devider"></div>
            <div className="forextermAndConditions">
              <ul className="paymentTermAndConditionsLabel">
                <li>
                  <span className="paymentTcName">Payment Mode</span>
                </li>
                <li>
                  <span className="paymentTcName">Validity Date</span>
                </li>
                <li>
                  <span className="paymentTcName">Opening Date</span>
                </li>
                <li>
                  <span className="paymentTcName">Refc. Number</span>
                </li>
                <li>
                  <span className="paymentTcName">Opening to</span>
                </li>
                <li>
                  <span className="paymentTcName">Mode of Shipment</span>
                </li>
                <li>
                  <span className="paymentTcName">Trans Shipment</span>
                </li>
                <li>
                  <span className="paymentTcName">Partial Shipment</span>
                </li>
                <li>
                  <span className="paymentTcName">Country of Origin</span>
                </li>
                <li>
                  <span className="paymentTcName">Port of Loading</span>
                </li>
                <li>
                  <span className="paymentTcName">Port of Discharge</span>
                </li>
                {/* <li>
                  <span className="paymentTcName">Freight</span>
                </li> */}
                <li>
                  <span className="paymentTcName">Incoterm</span>
                </li>
              </ul>

              {termAndConditions?.map((values) => (
                <ul className="paymentTermAndConditionsValue">
                  <li>
                    <span className="paymentTcValue">
                      {values?.paymentMode || "-"}
                    </span>
                  </li>
                  <li>
                    <span className="paymentTcValue">
                      {values?.validityDate || "-"}
                    </span>
                  </li>
                  <li>
                    <span className="paymentTcValue">
                      {values?.openingDate || "-"}
                    </span>
                  </li>
                  <li>
                    <span className="paymentTcValue">
                      {values?.referenceNumber || "-"}
                    </span>
                  </li>
                  <li>
                    <span className="paymentTcValue">
                      {values?.openingTo || "-"}
                    </span>
                  </li>
                  <li>
                    <span className="paymentTcValue">
                      {values?.modeOfShipment || "-"}
                    </span>
                  </li>
                  <li>
                    <span className="paymentTcValue">
                      {values?.transShipment || "-"}
                    </span>
                  </li>
                  <li>
                    <span className="paymentTcValue">
                      {values?.partialShipment || "-"}
                    </span>
                  </li>
                  <li>
                    <span className="paymentTcValue">
                      {values?.countryOfOrigin || "-"}
                    </span>
                  </li>
                  <li>
                    <span className="paymentTcValue">
                      {values?.portOfLoading || "-"}
                    </span>
                  </li>
                  <li>
                    <span className="paymentTcValue">
                      {values?.portOfLoading || "-"}
                    </span>
                  </li>
                  {/* <li>
                 <span className="paymentTcValue">{values?.freight || "-"}</span>
               </li> */}
                  <li>
                    <span className="paymentTcValue">
                      {values?.incoterm || "-"}
                    </span>
                  </li>
                </ul>
              ))}
            </div>
            <div className="paymentTcEditButton">
              {CheckPermission("add_term_and_condition", userinfo) ? (
                <IconButtons
                  name={hasTerm !== 0 ? "termCondition" : "nOTermCondition"}
                  previousData={getTermAndCondition()
                    ?.flat()
                    .map((values) => values)}
                  setSuccessMessage={props?.successMessage}
                  setMessage={props?.message}
                />
              ) : (
                ""
              )}
            </div>
          </TermAndConditions>
        </>
      )}
    </>
  );
};

export default TermAndConditionContainer;
