import React from "react";
import { Link } from "react-router-dom";
import { FaSearch } from "react-icons/fa";

const FloatingProfileChecker = () => {
  return (
    <Link
      to="/payment/PrfChecker"
      className="d-flex gap-2 p-2 text-white "
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        textAlign: "center",
        lineHeight: "50px",
        cursor: "pointer",
        zIndex: "9999",
        backgroundColor: "#186569",
        borderRadius: "5px",
        color: "white !important",
        height: "35px",
      }}
    >
      <div className="d-flex align-items-center gap-3">
        <FaSearch style={{ color: "#eff2f3" }} className="" />
        <span className=" text-center " style={{ fontSize: "16px" }}>
          Search for PRF
        </span>
      </div>
    </Link>
  );
};

export default FloatingProfileChecker;
