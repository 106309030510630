import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import {
  useGetBanksQuery,
  useGetUserInfoQuery,
} from "../../../features/SCMApi";
import BankSelect from "./Select";
import CheckPermission from "../../common/checkPermission/checkPermission";

const ForexNeed = styled(Paper)(({ theme }) => ({
  elevation: "0px",
  height: "300px",
  width: "362px",
  [theme.breakpoints.between("md", "lg")]: {
    marginLeft: "35px",
    marginRight: "-45px",
  },
  [theme.breakpoints.between("xs", "sm", "md")]: {
    marginBottom: "20px",
  },
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  border: "1px solid #AEB8C9",
  borderRadius: "6px",
}));

const ForexNeedContainer = (props) => {
  const { data: userinfo } = useGetUserInfoQuery();
  const { data: bankLists } = useGetBanksQuery();

  return (
    <>
      <ForexNeed elevation={0}>
        <div className="gap-analysis-mobile-view-detail-card">
          <ul className="paymentTermAndConditionsLabel">
            <li>
              <span className="mobile-view-label">Project</span>
            </li>
            <li>
              <span className="mobile-view-label">Milestone</span>
            </li>
            <li>
              <span className="mobile-view-label">Suppler</span>
            </li>
            <li>
              <span
                className="mobile-view-label"
                style={{ marginLeft: "-50px" }}
              >
                Payment mode
              </span>
            </li>
            <li>
              <span className="mobile-view-label">Bank</span>
            </li>
            <li>
              <span className="mobile-view-label">PFO</span>
            </li>
            <li>
              <span className="mobile-view-label">SO</span>
            </li>
            <li>
              <span className="mobile-view-label">Amount</span>
            </li>
          </ul>

          <ul className="paymentTermAndConditionsValue">
            <li>
              <span className="paymentTcValue">
                {props?.forexNeedData?.project_name !== null
                  ? props?.forexNeedData?.project_name?.length > 18
                    ? props?.forexNeedData?.project_name?.slice(0, 18) + "..."
                    : props?.forexNeedData?.project_name
                  : "Not available"}
              </span>
            </li>
            {/* distributor_name */}
            <li>
              <span className="paymentTcValue">
                {props?.forexNeedData?.milestone_name !== null
                  ? props?.forexNeedData?.milestone_name?.length > 18
                    ? props?.forexNeedData?.milestone_name?.slice(0, 18) + "..."
                    : props?.forexNeedData?.milestone_name
                  : "Not available"}
              </span>
            </li>
            <li>
              <span className="paymentTcValue">
                {props?.forexNeedData?.distributor_name !== null
                  ? props?.forexNeedData?.distributor_name?.length > 18
                    ? props?.forexNeedData?.distributor_name?.slice(0, 18) +
                      "..."
                    : props?.forexNeedData?.distributor_name
                  : "Not available"}
              </span>
            </li>
            <li>
              <span className="paymentTcValue">
                {props?.forexNeedData?.payment_mode !== null
                  ? props?.forexNeedData?.payment_mode?.length > 18
                    ? props?.forexNeedData?.payment_mode?.slice(0, 18) + "..."
                    : props?.forexNeedData?.payment_mode
                  : "Not available"}
              </span>
            </li>
            <li>
              <span className="paymentTcValue">
                {
                  <BankSelect
                    placeHolder={"Select Bank..."}
                    data={bankLists?.data}
                    passedValue={props?.forexNeedData?.bank}
                    forexId={props?.forexNeedData?.forex_need_id}
                    successMessage={props?.setSuccessMessage}
                    disabled={
                      CheckPermission("edit_forex_need_bank", userinfo)
                        ? false
                        : true
                    }
                    style={{
                      width: 190,
                      color: "#186569",
                      fontWeight: 500,
                      fontSize: "13px",
                      lineHeight: "16px",
                      marginLeft: "-13px",
                      marginTop: "2px",
                      border: "1px solid #aeb8c9",
                    }}
                  />
                }
              </span>
            </li>
            <li>
              <span className="paymentTcValue">
                {props?.forexNeedData?.financeOfficer !== ""
                  ? props?.forexNeedData?.financeOfficer
                  : "Not available"}
              </span>
            </li>
            <li>
              <span className="paymentTcValue">
                {props?.forexNeedData?.sourcingOfficer
                  ? props?.forexNeedData?.sourcingOfficer
                  : "Not available"}
              </span>
            </li>
            <li>
              <span className="paymentTcValue">
                {props?.forexNeedData?.amount
                  ? Number(props?.forexNeedData?.amount)?.toLocaleString()
                  : "Not available"}
              </span>
            </li>
          </ul>
        </div>
        <div className="gap-analysis-mobile-view-description-container">
          <span className="gap-analysis-mobile-view-description-title">
            Description
          </span>
          <span className="paymentTcValue">
            {props?.forexNeedData?.description !== null
              ? props?.forexNeedData?.description
              : "Not available"}
          </span>
        </div>
      </ForexNeed>
    </>
  );
};

export default ForexNeedContainer;
