import { useEffect, useState } from "react";
import { useGetProjectsQuery } from "../../../../features/SCMApi";
import axios from "axios";
import { API_BASE_URL } from "../../../../api/endPoint";

function EmployeeRecievableFilter({
  searchQuery,
  startDate,
  endDate,
  handleSearchSubmit,
  setSearchQuery,
  setStartDate,
  setEndDate,
}) {
  return (
    <div>
      <form onSubmit={handleSearchSubmit}>
        <div className="topBar my-2">
          <div>
            Enter Name
            <input
              className="border rounded border-#184b4f px-3 border-3 py-1"
              style={{ width: "180px" }}
              type="text"
              placeholder="Enter Name"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div>
            <input
              className="border rounded border-#184b4f px-3 border-3 py-1"
              style={{ width: "180px" }}
              type="date"
              placeholder="Start Date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div>
            <input
              className="border rounded border-#184b4f px-3 border-3 py-1"
              style={{ width: "180px" }}
              type="date"
              placeholder="End Date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default EmployeeRecievableFilter;
