import { Pagination, Tooltip } from "@mui/material";
import React from "react";
import { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { IMG_BASE_URL } from "../../../api/endPoint";
import {
  useGetOrderItemQuery,
  useGetProjectPRFQuery,
  useGetProjectsQuery,
} from "../../../features/SCMApi";
import NoRecord from "../../common/NoRecord";
import usePagination from "../../Pagination/Pagination";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import RecommendIcon from "@mui/icons-material/Recommend";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import ReactPaginate from "react-paginate";
import ForexSideBar from "../../../components/Payment/ForexApproval/PaymentSideBar";

export default function PrfprojectPrf(props) {
  let { project_id } = useParams();

  const { data: projectPrf } = useGetProjectPRFQuery(project_id);

  let [page, setPage] = useState(1);
  const PER_PAGE = 12;

  const p_count = Math.ceil(projectPrf?.data?.length / PER_PAGE);
  const _DATA = usePagination(projectPrf?.data, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <ForexSideBar>
      {projectPrf?.data?.length != 0 ? (
        <div className="order-card-container mt-3">
          {_DATA
            ?.currentData()
            // ?.filter((items) => {
            //   if (props?.search === "") {
            //     return items;
            //   } else if (
            //     items?.order?.order_name?.toLowerCase().includes(props?.search)
            //   ) {
            //     return items;
            //   }
            // })
            // .slice(pageVisted, pageVisted + PER_PAGE)
            ?.map((items) => (
              <NavLink to={`prfs/${items?.id}`} className="order-card">
                <div className="ms-2 mt-2">
                  {items?.po_approval == 0 ? (
                    <Tooltip title={"Pending"}>
                      <PendingActionsIcon sx={{ color: "#ffcc00" }} />
                    </Tooltip>
                  ) : items?.po_approval == 1 ? (
                    <Tooltip title={"Approved"}>
                      <RecommendIcon sx={{ color: "#064b4f" }} />
                    </Tooltip>
                  ) : items?.po_approval == null ? (
                    <Tooltip title={"Waiting to send for approver"}>
                      <HourglassTopIcon sx={{ color: "#064b4f" }} />
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </div>

                <div className="order-info">
                  <div className="order-project">
                    <div>
                      {items?.order?.supplier_id != null ? (
                        <div className="project-name">
                          <Tooltip
                            title={items?.order?.supplier?.distributor_name}
                          >
                            <div className="project-name">
                              {items?.order?.supplier?.distributor_name
                                ?.length >= 20
                                ? items?.order?.supplier?.distributor_name?.slice(
                                    0,
                                    20
                                  ) + "..."
                                : items?.order?.supplier?.distributor_name}
                            </div>
                          </Tooltip>
                        </div>
                      ) : (
                        <div className="project-name">
                          <Tooltip
                            title={items?.order?.bill_to?.distributor_name}
                          >
                            <div className="project-name">
                              {items?.order?.bill_to?.distributor_name
                                ?.length >= 20
                                ? items?.order?.bill_to?.distributor_name?.slice(
                                    0,
                                    20
                                  ) + "..."
                                : items?.order?.bill_to?.distributor_name}
                            </div>
                          </Tooltip>
                        </div>
                      )}
                      <div className="project-label">
                        {items?.order?.supplier_id != null
                          ? "Supplier"
                          : "Bill to"}
                      </div>
                    </div>

                    <div>
                      <div className="project-name">
                        {items?.order?.order_type}
                      </div>
                      <div className="project-label">Order Type</div>
                    </div>
                  </div>
                </div>
                <div className="divider"></div>
                <div className="order-supplier">
                  <Tooltip title={items?.order?.order_name}>
                    <div className="order-supplier">
                      {items?.order.order_name?.length >= 60
                        ? items?.order.order_name?.slice(0, 60) + "..."
                        : items?.order.order_name}
                    </div>
                  </Tooltip>
                </div>
              </NavLink>
            ))}
        </div>
      ) : (
        <NoRecord />
      )}
      <div className="mt-3">
        {p_count > 1 ? (
          <Pagination
            count={p_count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            className="p-2"
          />
        ) : (
          ""
        )}
      </div>
    </ForexSideBar>
  );
}
