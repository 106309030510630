import axios from "axios";
import { API_BASE_URL } from "../../api/endPoint";
import { FaCheck } from "react-icons/fa";
import { useState } from "react";
import { MdApproval } from "react-icons/md";

export default function ConfirStore({ item, way, store, from, to, userId }) {
  const [itemId, setItemId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [amount, setAmount] = useState(null);
  const [alerts, setAlerts] = useState(null);
  const [seccess, setSeccess] = useState(null);
  const [newArray, setNewArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleConfirm = () => {
    setShowModal(true);
    const updatedArray = item.map((items) => ({
      id: items.id,
      previous_tool: items.previous_tool,
    }));
    setNewArray(updatedArray);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  function handleAccept(e) {
    setIsLoading(true);

    e.preventDefault();

    axios
      .post(`${API_BASE_URL}/scm/stock/approve-transfered-tools`, {
        transfer_approved_tools: newArray,
        type: store,
        user_id: userId,
        items: item,
      })
      .then((response) => {
        if (response.data.status === "Success") {
          setSeccess("Success");
          handleCancel();
          window.location.reload();
        }
      })
      .catch((err) => {
        console.error("Error", err);
        setIsLoading(false);
        // handleCancel();
        setError(true);
      });
  }
  return (
    <div>
      <button
        className="form-save d-flex justify-content-center align-items-center mt-3"
        onClick={handleConfirm}
      >
        <MdApproval
          className="fs-5"
          onClick={() => {
            setItemId(item?.id);
          }}
          sx={{
            color: "#52FFAC",
            "&:hover": {
              backgroundColor: "#fff",
              color: "#52FFAC",
              border: "1px solid #52FFAC",
              borderRadius: 25,
            },
          }}
        />
      </button>
      {showModal && (
        <div className="modal-container">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content " style={{ minHeight: "0" }}>
              <div class="modal-header">
                <h5 className="modal-title">Confirm Recieved?</h5>
                <button className="btn-close" onClick={handleCancel}></button>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              ></div>
              {way == "all" ? (
                <div className="modal-body">
                  <p>
                    You are reciving items from {"  "}
                    <span style={{ fontWeight: "bold" }}>{from}</span> to{"  "}
                    <span style={{ fontWeight: "bold" }}>{to}</span>
                  </p>
                </div>
              ) : (
                <div className="modal-body">
                  <p>
                    are you shure recive
                    <div style={{ fontWeight: "bold" }}> {item.item_name} </div>
                    item
                  </p>
                </div>
              )}
              <div className="d-flex flex-row form-buttons modal-footer">
                {isLoading ? (
                  <button type="button" className="" disabled>
                    Loading...
                  </button>
                ) : (
                  <>
                    {error && (
                      <span className="text-danger">Request failed</span>
                    )}
                    <button
                      className="form-save"
                      type="submit"
                      onClick={handleAccept}
                    >
                      Yes
                    </button>
                    <button className="form-cancel" onClick={handleCancel}>
                      No
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
