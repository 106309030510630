import React, { useEffect, useState } from "react";
import div from "../../../components/Payment/ForexApproval/PaymentSideBar";
import {
  useApprovePrfBudgetMutation,
  useBudgetApprovalMutation,
  useGetPrfBudgetQuery,
  useGetUserInfoQuery,
} from "../../../features/SCMApi";
import { date } from "../../../components/dateFormator";
import { Box, Modal } from "@mui/material";
import ConfirmDelete from "../../../components/common/ConfirmDelete";

export default function ApprovedMonthlyBudgets({ data }) {
  const [open, setOpen] = useState(false);

  const projectTotals = Object.keys(data ? data : {})?.flatMap(
    (projectName) => {
      const projectData = data[projectName];
      const totalUSD = projectData.reduce((acc, data) => acc + data.amount, 0);
      const totalETB = projectData.reduce((acc, data) => acc + data.amount, 0);

      return {
        projectName,
        totalUSD,
        totalETB,
      };
    }
  );
  let totalBudgetUSD = projectTotals.reduce(
    (acc, data) => acc + data.totalUSD,
    0
  );
  let totalBudgetETB = projectTotals.reduce(
    (acc, data) => acc + data.totalETB,
    0
  );
  const projectData = [
    { currency_id: "USD", amount: 100 },
    { currency_id: "EUR", amount: 200 },
    { currency_id: "USD", amount: 150 },
    { currency_id: "GBP", amount: 120 },
    // ... more data
  ];

  const result = Object.keys(data ? data : {})?.flatMap((projectName) =>
    (data[projectName] || []).reduce((acc, data) => {
      const { currency_id, amount } = data;

      // Create a new property for the currency if it doesn't exist
      acc[currency_id] = (acc[currency_id] || 0) + amount;

      return acc;
    }, {})
  );

  return (
    <div title={"Budget"} name={"Budget"} navlink={`/payment`}>
      <div className="d-flex justify-content-between align-items-end py-2">
        <div className="card mb-3" style={{ width: "18rem" }}>
          <div
            style={{
              backgroundColor: "#064b4f",
              color: "#fff",
              letterSpacing: "0.105em",
              fontWeight: "bold",
              fontSize: "14px",
              padding: "5px",
            }}
          >
            Total Budget cost
          </div>
          <ul className="list-group list-group-flush">
            {result.map((currencyObject, index) => {
              const [currency_id, totalAmount] =
                Object.entries(currencyObject)[0];

              return (
                <li className="list-group-item prg" key={index}>
                  Currency:{" "}
                  {[
                    { id: 0, name: "Unknown" },
                    { id: 1, name: "USD" },
                    { id: 2, name: "AUD" },
                    { id: 3, name: "CAD" },
                    { id: 4, name: "EUR" },
                    { id: 5, name: "GBP" },
                    { id: 6, name: "ZAR" },
                    { id: 7, name: "ETB" },
                    { id: 8, name: "Yuan" },
                  ]?.find((items) => items?.id == currency_id)?.name || "-"}
                  , Total Amount: {Number(totalAmount)?.toLocaleString()}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <table
        className="table table-bordered"
        style={{ borderCollapse: "collapse", width: "100%" }}
      >
        <thead
          style={{
            fontSize: "10px",
            backgroundColor: "#000",
            letterSpacing: "0.105em",
            fontWeight: "400",
            color: "#fff ",
          }}
        >
          <tr>
            <th>BUDGET NO.</th>
            <th>ACCOUNT NO.</th>
            <th>ACCOUNT DESCRIPTION</th>
            <th>MILESTONE LINKED TO THE BUDGET</th>
            <th>EXPECTED DATE OF COLLECTION WITHIN THE MONTH</th>
            <th>BUDGET HOLDER</th>
            <th>BUDGET Amount</th>
            <th>Currency</th>
          </tr>
        </thead>
        <tbody>
          {data ? (
            <>
              {Object?.keys(data)?.map((projectName, index) => (
                <React.Fragment key={projectName}>
                  <tr
                    style={{
                      backgroundColor: "#064b4f",
                      color: "#fff",
                      letterSpacing: "0.105em",
                      fontWeight: "bolder",
                      fontSize: "14px",
                    }}
                  >
                    <td
                      style={{
                        textAlign: "center",
                      }}
                      colSpan="8"
                    >
                      {projectName}{" "}
                    </td>

                    {/* <td></td> */}
                  </tr>

                  {data[projectName].flat().map((e, index) => {
                    return (
                      <tr
                        className="text-start"
                        key={`${projectName}-${index}`}
                        style={{
                          backgroundColor: "#ddd",
                          color: "#000",
                          letterSpacing: "0.105em",
                          fontWeight: "400",
                          fontSize: "14px",
                          border: "1px solid #e2e7f0",
                        }}
                      >
                        <td>{`IE-${projectName}-00${index}`}</td>
                        <td>{e.a_no}</td>
                        <td>{e.a_description}</td>
                        <td>
                          {e?.order?.milestone_id
                            ? `Milestone ${e?.order.milestone_id}`
                            : "-"}
                        </td>
                        <td>{date(e?.created_at)}</td>
                        <td>
                          {e?.project
                            ? e.project[0]?.project_participants?.find(
                                (items) =>
                                  items?.pivot?.role_id ==
                                  "96f8c153-79d5-40d1-b0b7-7fd70921f02e"
                              )?.name
                            : "-"}
                        </td>
                        <td>
                          {Number(e.amount ? e.amount : 0)?.toLocaleString()}
                        </td>
                        <td>
                          {" "}
                          {[
                            { id: 0, name: "Unknown" },
                            { id: 1, name: "USD" },
                            { id: 2, name: "AUD" },
                            { id: 3, name: "CAD" },
                            { id: 4, name: "EUR" },
                            { id: 5, name: "GBP" },
                            { id: 6, name: "ZAR" },
                            { id: 7, name: "ETB" },
                            { id: 8, name: "Yuan" },
                          ]?.find((items) =>
                            items?.id == e.currency_id
                              ? e.currency_id
                              : e?.order?.currency_id
                          )?.name || "-"}
                        </td>
                      </tr>
                    );
                  })}
                  <tr
                    style={{
                      borderBottomLeftRadius: "6px",
                      borderBottomRightRadius: "6px",
                      backgroundColor: "#064b4f",
                      color: "#fff",
                      letterSpacing: "0.105em",
                      fontWeight: "bolder",
                      fontSize: "14px",
                    }}
                  >
                    <td
                      style={{
                        textAlign: "end",
                        borderBottomLeftRadius: "6px",
                        width: "auto",
                      }}
                      colSpan="5"
                    >
                      Total cost of {projectName}
                    </td>
                    {[
                      { id: 0, name: "Unknown" },
                      { id: 1, name: "USD" },
                      { id: 2, name: "AUD" },
                      { id: 3, name: "CAD" },
                      { id: 4, name: "EUR" },
                      { id: 5, name: "GBP" },
                      { id: 6, name: "ZAR" },
                      { id: 7, name: "ETB" },
                      { id: 8, name: "Yuan" },
                    ].map((i) => {
                      let proj = data[projectName]
                        .flat()
                        .filter((c) => c?.currency_id === i.id)
                        .map((m) => {
                          return m.amount;
                        })
                        .reduce(
                          (previousValue, currentValue) =>
                            previousValue + currentValue,
                          0
                        );

                      if (proj > 0) {
                        return (
                          <td>
                            {Number(proj)?.toLocaleString()}
                            {i.name}{" "}
                          </td>
                        );
                      }
                    })}

                    {/* <td style={{ borderBottomRightRadius: "6px" }}>
                      {Number(projectTotals[index]?.totalETB)?.toLocaleString()}
                    </td> */}
                  </tr>
                  <br />
                </React.Fragment>
              ))}
            </>
          ) : (
            <td
              colSpan="8"
              style={{
                backgroundColor: "#fff",
                color: "black",
                letterSpacing: "0.105em",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              <div className="d-flex gap-3">
                <div
                  className="spinner-border"
                  style={{ color: "#064b4f" }}
                  role="status"
                ></div>
                <span className="visually">Wait a few second</span>
              </div>
            </td>
          )}
        </tbody>
      </table>

      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        ></Box>
      </Modal>
    </div>
  );
}
