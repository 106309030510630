import axios from "axios";
import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../../../api/endPoint";
import {
  useApprovalApproveMutation,
  useApprovalRejectMutation,
  useGetAssetsRequestedQuery,
  useGetInventoryRequestedQuery,
  useGetToolRequestedQuery,
  useGetToolsRequestsQuery,
  useGetUserInfoQuery,
  useInStoreItemsRequestMutation,
  useItemsAcceptMutation,
} from "../../../../features/SCMApi";
import NavBar from "../../../../Layout/NavBar";
import StockIndex from "../../../../pages/Stocks/StockIndex";
import RequestAssetItemDescription from "./RequestAssetItemDescriptions";

export default function RequestRequestedAsset(props) {
  const params = useParams();
  const { data: AssetDetail } = useGetAssetsRequestedQuery(params?.id);


  return (
    <StockIndex>
      <RequestAssetItemDescription
        setFragment={props?.setFragment}
        data={AssetDetail?.data}
      />
    </StockIndex>
  );
}
