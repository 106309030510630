import React from "react";

export default function CostIeSide({ cost, setTotalPerInvoice, costBuildUp }) {
  let inlandTransportCost;
  let freightSeaAir;
  let transitorService;
  let training;
  let labour;
  let offshoreMaterial;
  let marineInsurance;
  let customsDuty;
  let clearingStorageWarehouse;
  let insurance;
  let bankCharge;
  let loadingUnloadingExpense;
  let vat;
  let sureTax;
  let withholdingTax;
  let exciseTax;

  if (cost && typeof cost === "object") {
    inlandTransportCost = cost["InLand Transport"];
    freightSeaAir = cost["Freight sea/air"];
    transitorService = cost["Transitor Service"];
    training = cost["Training"];
    labour = cost["Labour"];
    offshoreMaterial = cost["Offshore Material"];
    marineInsurance = cost["Marin insurance"];
    customsDuty = cost["Customs Duty"];
    clearingStorageWarehouse = cost["Clearing/Storage and Warehouse service"];
    insurance = cost["Insurance"];
    bankCharge = cost["Bank charge"];
    loadingUnloadingExpense = cost["Loading Unloading Expense"];
  }

  

  const TotalFobETB =
    Number(cost?.EX_Rate_per_Declaretion) *
      Number(cost?.IPI_USD_per_Declaretion) +
    Number(cost?.Freight) +
    Number(cost?.Insurance) +
    Number(cost?.other == undefined ? 0 : cost?.other);
  const TotalinLandCost =
    Number(bankCharge == undefined ? 0 : bankCharge) +
    Number(loadingUnloadingExpense == undefined ? 0 : loadingUnloadingExpense) +
    Number(inlandTransportCost == undefined ? 0 : inlandTransportCost) +
    Number(
      clearingStorageWarehouse == undefined ? 0 : clearingStorageWarehouse
    ) +
    Number(marineInsurance == undefined ? 0 : marineInsurance) +
    Number(freightSeaAir == undefined ? 0 : freightSeaAir) +
    Number(exciseTax == undefined ? 0 : exciseTax) +
    Number(withholdingTax == undefined ? 0 : withholdingTax) +
    Number(sureTax == undefined ? 0 : sureTax) +
    Number(vat == undefined ? 0 : vat) +
    Number(customsDuty == undefined ? 0 : customsDuty);

  const TotalFobPerInvoice =
    Number(cost?.IPI_USD_Per_Invoice) * Number(cost?.EX_Rate_per_Invoice);
  const TotalPerInvoice = TotalFobPerInvoice
    ? TotalFobPerInvoice
    : 0 + TotalinLandCost
      ? TotalinLandCost
      : 0 -
        (withholdingTax ? Number(withholdingTax) : 0 + vat ? Number(vat) : 0);
  const TotalDeclaration =
    TotalFobETB +
    TotalinLandCost -
    (Number(cost?.withholding_tax) + Number(cost?.vat));
  setTotalPerInvoice(TotalPerInvoice);
  
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="origin-label">EX Rate as per Invoice</div>
        <div className="cost-value">
          {costBuildUp?.EX_Rate_per_Invoice
            ? Number(costBuildUp?.EX_Rate_per_Invoice)?.toLocaleString()
            : 0}
        </div>
      </div>
      <div className="divider"></div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="origin-label">Item price In USD as per Invoice</div>
        <div className="cost-value">
          {costBuildUp?.IPI_USD_Per_Invoice
            ? Number(costBuildUp?.IPI_USD_Per_Invoice)?.toLocaleString()
            : 0}
        </div>
      </div>
      <div className="divider"></div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="origin-label">Transitor Service</div>
        <div className="cost-value">
          {transitorService ? Number(transitorService)?.toLocaleString() : 0}
        </div>
      </div>
      <div className="divider"></div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="origin-label">InLand Transport</div>
        <div className="cost-value">
          {inlandTransportCost
            ? Number(inlandTransportCost)?.toLocaleString()
            : 0}
        </div>
      </div>
      <div className="divider"></div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="origin-label">Loding Unloading Expense</div>
        <div className="cost-value">
          {" "}
          {loadingUnloadingExpense
            ? Number(loadingUnloadingExpense)?.toLocaleString()
            : 0}
        </div>
      </div>
      <div className="divider"></div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="origin-label">
          Clearing/Storage and Warehouse service
        </div>
        <div className="cost-value">
          {clearingStorageWarehouse
            ? Number(clearingStorageWarehouse)?.toLocaleString()
            : 0}
        </div>
      </div>
      <div className="divider"></div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="origin-label">Bank charge</div>
        <div className="cost-value">
          {bankCharge ? Number(bankCharge)?.toLocaleString() : 0}
        </div>
      </div>
      <div className="divider"></div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="origin-label">
          Total FOB item price in ETB as per invoice
        </div>
        <div className="cost-value">
          {TotalFobPerInvoice
            ? Number(TotalFobPerInvoice)?.toLocaleString()
            : "0"}
        </div>
      </div>
      <div className="divider"></div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="origin-label">Total in Land Cost</div>
        <div className="cost-value">
          {TotalinLandCost ? Number(TotalinLandCost)?.toLocaleString() : "0"}
        </div>
      </div>
      <div className="divider"></div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="origin-label">Total as per Invoice</div>
        <div className="cost-value">
          {TotalPerInvoice ? Number(TotalPerInvoice)?.toLocaleString() : "0"}
        </div>
      </div>
      <div className="divider"></div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="origin-label">Remark</div>
        {costBuildUp?.Status == 0 ? (
          <div className="cost-value">{costBuildUp?.Remark}</div>
        ) : costBuildUp?.Status != undefined ? (
          <div className="cost-value text-success">Done</div>
        ) : (
          <div className="cost-value text-success">Unknown</div>
        )}
      </div>
    </div>
  );
}
