import React from "react";
import PrfContainer from "./PrfContainer";
import SourcingIndex from "../../../pages/Sourcing/SourcingIndex";
import { useGetOrderItemQuery } from "../../../features/SCMApi";
import { NavLink, useParams } from "react-router-dom";
import WestIcon from "@mui/icons-material/West";
import PrfApproval from "./PrfApproval";
import PrfDataContainer from "./PrfDataContainer";

export default function Index() {
  const params = useParams();
  const { data: order } = useGetOrderItemQuery(params?.id);

  return (
    <SourcingIndex
      navlink={`/postageDetail/${order?.data?.orders?.id}`}
      title={"Po Stage"}
      name={order?.data?.orders?.order_name}
      names={"PRF"}
    >
      {/* <NavLink
        to={`/postageDetail/${order?.data?.orders?.id}`}
        className="create-project-btn mb-3"
      >
        <WestIcon
          sx={{
            "&:hover": {
              backgroundColor: "#fff",
              color: "#186569",
            },
          }}
        />
        Go Back
      </NavLink> */}
      <div className="d-flex gap-3">
        <PrfContainer />
        <div className="" style={{ width: "30%" }}>
          <PrfApproval />
          <PrfDataContainer />
        </div>
      </div>
    </SourcingIndex>
  );
}
