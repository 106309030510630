import React from "react";
import { useState } from "react";
import { useAskQuestionMutation } from "../../features/SCMApi";

export default function AskQuestion(props) {
  const [askQuestion] = useAskQuestionMutation();
  const [question, setQuestion] = useState();
  function HandleCancel(e) {
    e?.preventDefault();
    props?.ToggleModal(false);
  }
  function handleSubmit(e) {
    e.preventDefault();
    const askQuestionForm = { question };

    askQuestion(askQuestionForm)
      .unwrap()
      .then((response) => {
        
        HandleCancel();
      })
      .then((error) => {});

    //   axios
    //   .post(`${API_BASE_URL}/scm/faq/videos`, faqVideosForm, {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   })
    //   .then(function (response) {
    
    //     handleCancel();
    //   })
    //   .catch(function (error) {
   
    //     // handleCancel();
    //   });
  }
  return (
    <div id="myModal" className="modal" onClick={HandleCancel}>
      <div className="modal-content-md" onClick={(e) => e.stopPropagation()}>
        <div className="create-project-modal-title">
          <div>Ask Question</div>

          <div className="close-modal-button" onClick={(e) => HandleCancel()}>
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.4612 5.78721L19.8657 7.19175L7.46095 19.5965L6.0564 18.192L18.4612 5.78721ZM7.17507 5.51376L20.3505 18.6891L18.8589 20.1807L5.68351 7.00531L7.17507 5.51376Z"
                fill="#353945"
              />
            </svg>
          </div>
        </div>
        <form className="form-container" onSubmit={(e) => handleSubmit(e)}>
          <div className="inputs-group " style={{ width: "55%" }}>
            <div className="input-label">Question</div>
            <textarea
              rows={7}
              required
              //   notRequired={false}
              //   state={video}
              //   setters={setVideo}
              onChange={(e) => setQuestion(e.target.value)}
              className="file-chooser"
              id={"videoSelector"}
              style={{ margin: "0px", height: "fit-content" }}
            />
          </div>

          <div className="form-buttons mt-4">
            <button
              type="cancel"
              className="form-cancel"
              onClick={(e) => HandleCancel(e)}
            >
              {" "}
              Cancel
            </button>
            <button type="submit" className="form-save">
              Save
            </button>{" "}
          </div>
        </form>
      </div>
    </div>
  );
}
