import React, { useState } from "react";
import { Calendar } from "react-calendar";
import { NavLink } from "react-router-dom";
import { useGetProjectsQuery } from "../../features/SCMApi";
import NavBar from "../../Layout/NavBar";
import SourcingIndex from "../../pages/Sourcing/SourcingIndex";
import BigButton from "./BigButton";
import CreateOrder from "./CreateOrder";
import OrderNotice from "./OrderNotice";
import ProfitabilityNotice from "./ProfitabilityNotice";
import Utility from "./Utility";
import SelectOption from "../common/SelectOption";
import MuiSelect from "../common/MuiSelect";

export default function SourcingContainer(props) {
  const [createOrderModalToggle, setCreateOrderModalToggle] = useState(false);
  const [selectedProject, setProject] = useState();
  const [projectname, setProjectName] = useState();
  function handleCreateOrderModal() {
    setCreateOrderModalToggle(true);
  }
  
  const { data: response } = useGetProjectsQuery();
  const Projectdata = [];

  response?.data?.map((items) => {
    if (selectedProject === items.id) {
      Projectdata.push(items);
    }
  });
  
  const [message, setMessage] = useState(null);
  const HandleMessage = () => {
    setMessage("Please Select Project To Proceed");
  };
  const [search, setSearch] = useState("");

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    
  };
  
  const list = [];
  response?.data?.map((items) => {
    if (items?.isActive == 1) {
      list.push({
        value: items?.id,
        label: items?.project_name,
      });
    }
  });
  

  return (
    <SourcingIndex>
      <div className="dashboard-left-section  mt-5">
        {/* <Utility setters={setProject} message={message} /> */}
        <div className="d-flex justify-content-center">
          {/* <SelectOption
            options={list}
            setters={setProject}
            value={selectedProject}
          /> */}

          <MuiSelect
            options={list}
            setters={setProject}
            value={selectedProject}
          />
        </div>

        <div className="h5-pri text-center">{message}</div>
        <div className="sourcing-dashboard">
          <OrderNotice
            value={selectedProject?.value}
            setMessage={setMessage}
            handler={HandleMessage}
          />
        </div>
      </div>
    </SourcingIndex>
  );
}
