import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import FolderIcon from "@mui/icons-material/Folder";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { useGetProjectDocumentQuery } from "../../features/SCMApi";
import { DOC_BASE_URL } from "../../api/endPoint";
import { useParams } from "react-router-dom";
import NoRecord from "../common/NoRecord";
import { Tab, Tabs } from "react-bootstrap";

// import { makeStyles } from "@material-ui/core";

// const UseStyles = makeStyles({
//   container: {
//     display: "flex",
//     flexWrap: "wrap",
//     "& > :not(style)": {
//       m: 1,
//       width: 600,
//       height: 300,
//     },
//   },
// });

export default function ProjectDocument() {
  // const classes = UseStyles();
  const params = useParams();
  const { data: DocumentDetail } = useGetProjectDocumentQuery(params.id);
 

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        "& > :not(style)": {
          width: "100%",
          minHeight: 128,
          marginTop: "2%",
        },
      }}
    >
      <Paper
        elevation={0}
        sx={{
          border: "1px #ECECEC solid",
          borderRadius: "8px",
        }}
      >
        <div style={{ margin: "10px 30px" }}>
          <FolderIcon style={{ fill: "#186569", fontSize: "200%" }} />
          <span
            style={{
              color: "#353945",
              fontWeight: "500",
              fontSize: "16px",
              marginLeft: "10px",
            }}
          >
            Project Document
          </span>
        </div>
        <div
          style={{
            display: "flex",
            margin: "auto",
            flexDirection: "column",
          }}
        >
          <Tabs
            defaultActiveKey="ContractDocument"
            id="uncontrolled-tab-example"
            className="mb-3 d-flex justify-content-center bg-white gap-3"
          >
            <Tab
              eventKey="ContractDocument"
              title="Contract Document"
              className="mb-3"
            >
              {DocumentDetail?.data?.length != 0 ? (
                <>
                  {" "}
                  <div style={{ margin: "10px 30px" }}>
                    <span
                      style={{
                        color: "#353945",
                        fontWeight: "500",
                        fontSize: "16px",
                        marginLeft: "10px",
                      }}
                    >
                      Contract Document
                    </span>
                  </div>
                  {DocumentDetail?.data?.map((items) =>
                    items.contract_document !== "" ? (
                      <div
                        style={{
                          border: "2px #ECECEC solid",
                          display: "flex",
                          justifyContent: "space-between",
                          margin: "3px",
                          padding: "3px",
                          alignSelf: "center",
                          width: "90%",
                        }}
                      >
                        <span
                          style={{
                            marginLeft: "4%",
                            marginTop: "5px",
                            color: "#353945",
                            fontWeight: "400",
                            fontSize: "14px",
                          }}
                        >
                          {items.contract_document.split("/")[3]}
                        </span>
                        <Tooltip title="Download">
                          <a
                            href={`${DOC_BASE_URL}scm/document/download?file_path=${items.contract_document}`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#186569"
                              class="bi bi-download"
                              viewBox="0 0 16 16"
                            >
                              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                            </svg>
                          </a>
                        </Tooltip>
                      </div>
                    ) : (
                      <NoRecord />
                    )
                  )}
                </>
              ) : (
                <NoRecord />
              )}
            </Tab>
            <Tab
              eventKey="TechnicalDocument"
              title="Technical Document"
              className="mb-3"
            >
              {DocumentDetail?.data?.length != 0 ? (
                <>
                  <div style={{ margin: "10px 30px" }}>
                    <span
                      style={{
                        color: "#353945",
                        fontWeight: "500",
                        fontSize: "16px",
                        marginLeft: "10px",
                      }}
                    >
                      Technical Document
                    </span>
                  </div>
                  {DocumentDetail?.data?.map((items) =>
                    items.technical_document !== "" ? (
                      <div
                        style={{
                          border: "2px #ECECEC solid",
                          display: "flex",
                          justifyContent: "space-between",
                          margin: "5px",
                          padding: "3px",
                          alignSelf: "center",
                          width: "90%",
                        }}
                      >
                        <span
                          style={{
                            marginLeft: "4%",
                            marginTop: "5px",
                            color: "#353945",
                            fontWeight: "400",
                            fontSize: "14px",
                          }}
                        >
                          {items.technical_document.split("/")[3]}
                        </span>
                        <Tooltip title="Download">
                          <a
                            href={`${DOC_BASE_URL}scm/document/download?file_path=${items.technical_document}`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#186569"
                              class="bi bi-download"
                              viewBox="0 0 16 16"
                            >
                              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                            </svg>
                          </a>
                        </Tooltip>
                      </div>
                    ) : (
                      <NoRecord />
                    )
                  )}
                </>
              ) : (
                <NoRecord />
              )}
            </Tab>
          </Tabs>
        </div>
      </Paper>
    </Box>
  );
}
