import React, { useState } from "react";
import {
  useGetCurrenciesQuery,
  useGetManufacturersQuery,
  useGetProductsQuery,
  useGetSupplierOriginsQuery,
} from "../../../features/SCMApi";
import axios from "axios";
import { API_BASE_URL } from "../../../api/endPoint";
import { useParams } from "react-router-dom";
import ExistingGroups from "./ExistingGroups";
export default function UpdatePaymentModal(props) {
  const [show, setShow] = useState(false);
  const [showgroup, setGroup] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {
    data: origin,
    isError,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetSupplierOriginsQuery();
  const {
    data: manufacturers,
    isErrors,
    errors,
    isLoadings,
    isFetchings,
    isSuccesss,
  } = useGetManufacturersQuery();
  const { data: solutions } = useGetProductsQuery();
  const { data: currencies, isErrorcurrency } = useGetCurrenciesQuery();

  const manufaturerList = [];
  manufacturers?.data?.map((items) =>
    manufaturerList.push({
      value: items?.id,
      label: items?.manufacturer_name,
    })
  );
  const update_id = useParams();
  const HandleSubmit = async (e) => {
    e.preventDefault();
    axios
      .put(
        `${API_BASE_URL}/scm/supplier/bankdetails/${props.value.id}`,
        params,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        
        props.modal(false);
        window.location.reload(true);
      })
      .then((error) => {
        
      });
    // createSupplier(params)
    //   .unwrap()
    //   .then((response) => {

    //   })
    //   .then((error) => {

    //   });
  };

  // bank detail
  const [bank_name, setBank_name] = useState(props?.value?.bank_name);
  const [account_number, setAccount] = useState(props?.value?.account_number);
  const [swift_code, setSwift] = useState(props?.value?.swift_code);
  const [iban, setIban] = useState(props?.value?.iban);
  const [bank_country, setBankcountry] = useState(props?.value?.bank_country);
  const [createName, setCreateName] = useState("Create Group");
  const [styleexisting, setStyleExisting] = useState("");
  const [bank_currency, setBankcurrency] = useState(
    props?.value?.bank_currency
  );
  const manufacturer = props?.value?.manufacturer_id;
  const supplier = props?.value?.supplier_id;
  const freightforwarder = props?.value?.frieght_forwarder_id;
  // contact person

  let params = {
    manufacturer_id: manufacturer == null ? null : update_id.id,
    supplier_id: supplier == null ? null : update_id.id,
    frieght_forwarder_id: freightforwarder == null ? null : update_id.id,
    bank_name,
    swift_code,
    iban,
    bank_country,
    bank_currency,
    account_number,
  };
  function HandleCancel() {
    props.modal(false);

  }
  const HandleName = () => {
    setCreateName("Group Items");
    setGroup(true);
  };
  const HandleNameCreate = () => {
    setCreateName("Create Group");
    setGroup(false);
  };



  return (
    <div>
      <div id="myModal" className="modal" onClick={HandleCancel}>
        <div className="modal-content-md" onClick={(e) => e.stopPropagation()}>
          <div className="create-project-modal-title">
            <div className="d-flex justify-content-between align-items-baseline p-2">
              <div>Update Payment</div>
              <div type="cancel" onClick={HandleCancel}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="42"
                  fill="currentColor"
                  class="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                </svg>
              </div>
            </div>
          </div>

          <form className="form-container ">
            <div className="form-card-container">
              <div className="form-card-information">
                <div className="card-center">
                  <div className="ms-5">
                    <div className="d-grid justify-content-center gap-2">
                      <div className="d-flex gap-2 align-items-baseline">
                        <select
                          required
                          name=""
                          id=""
                          className="select-order-name"
                        >
                          <option value="" disabled selected hidden>
                            Select PMR
                          </option>
                        </select>
                      </div>
                      <div className="d-flex justify-content-center gap-3">
                        <div className="payment-tracking-text mt-2 mb-0">
                          Total Amount
                        </div>
                        <div className="payment-text-bold mt-2 mb-0">
                          $ 43000
                        </div>
                      </div>
                      <div className="d-flex justify-content-center gap-3">
                        <div className="payment-tracking-text mt-2 mb-0">
                          Paid Amount
                        </div>
                        <div className="payment-text-bold mt-2 mb-0">
                          $ 43000
                        </div>
                      </div>{" "}
                      <div className="d-flex justify-content-center gap-3">
                        <div className="payment-tracking-text mt-2 mb-0">
                          Remaining Amount
                        </div>
                        <div className="payment-text-bold mt-2 mb-0">
                          $ 43000
                        </div>
                      </div>{" "}
                      <div className="d-flex justify-content-center gap-3 mb-3">
                        <div className="payment-tracking-text mt-2 mb-0">
                          Advance Payment
                        </div>
                        <div className="payment-text-bold mt-2 mb-0">43%</div>
                      </div>
                      <div className="divider"></div>
                      <div className="d-flex gap-2 justify-content-center align-items-center mt-3">
                        {" "}
                        <div className="supplier-input-label">
                          Advance Payment
                        </div>
                        <input type="radio" value="Paid" /> Paid
                        <input type="radio" value="Not paid" />
                        Not Paid
                      </div>
                      <div className="d-flex gap-2 align-items-baseline">
                        {" "}
                        <div className="supplier-input-label">New Payment</div>
                        <input
                          required
                          type="text"
                          className="supplier-text-input"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center gap-3 mt-2">
              <button
                type="cancel"
                className="form-cancel"
                onClick={HandleCancel}
              >
                {" "}
                Cancel
              </button>
              <button
                type="submit"
                className="form-save"
                onClick={HandleSubmit}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
