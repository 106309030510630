import React from "react";
import { useState, useEffect } from "react";
import { IMG_BASE_URL } from "../../../api/endPoint";
import {
  useGetInventorysRequestsQuery,
  useGetUserInfoQuery,
  useGetItemCategorysQuery,
  useGetStoreQuery,
  useGetInventorysBatchReturnsQuery,
} from "../../../features/SCMApi";
import NoRecord from "../../common/NoRecord";
import { Pagination } from "@mui/material";
import { NavLink } from "react-router-dom";
import ArrowCircleRightTwoToneIcon from "@mui/icons-material/ArrowCircleRightTwoTone";
import usePagination from "../../Pagination/Pagination";
import BatchItems from "../../../assets/SVG/Batchitems.png";
import ReturnedTools from "../DownloadReports/ReturnedTools";
import StockIndex from "../../../pages/Stocks/StockIndex";
import { useLocation } from "react-router-dom";

export default function InStoreItemsInventory({ inventory }) {
  const storedValue = localStorage.getItem("is_batch");
  const [isBatch, setIsBatch] = useState(
    storedValue ? parseInt(storedValue, 10) : 0
  );

  const { data: ReturnedSingleInventory } = useGetInventorysRequestsQuery();
  const { data: ReturnedBatchInventory } = useGetInventorysBatchReturnsQuery();
  const { data: loggedUser } = useGetUserInfoQuery();
  let notRequest = [];
  if (isBatch === 0) {
    ReturnedSingleInventory?.data?.forEach((item) => {
      if (
        item.is_returned === 1 &&
        item.is_confirmed === 1 &&
        item.current_return_amount > 0
      ) {
        notRequest.push(item);
      }
    });
  } else if (isBatch === 1) {
    ReturnedBatchInventory?.data?.forEach((item) => {
      notRequest.push(item);
    });
  }
  let updatedItems;
  if (isBatch == 1) {
    updatedItems = notRequest.map((item) => {
      let returnItems = item.return_items;
      if (returnItems && typeof returnItems === "string") {
        try {
          returnItems = JSON.parse(returnItems);
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      }

      return {
        ...item,
        return_items: returnItems || [],
      };
    });
  }

  const userdata =
    isBatch === 0
      ? notRequest?.filter(
          (items) =>
            items?.requester_id == loggedUser?.id ||
            items?.store_keeper_id == loggedUser?.id ||
            loggedUser?.role?.role_name == "Inventory Manager" ||
            loggedUser?.role?.role_name == "Super Admin"
        )
      : isBatch === 1
        ? updatedItems?.filter(
            (items) =>
              items?.requester_id == loggedUser?.id ||
              items.return_items?.[0]?.store?.store_keeper_id ==
                loggedUser?.id ||
              loggedUser?.role?.role_name == "Inventory Manager" ||
              loggedUser?.role?.role_name == "Super Admin"
          )
        : "";
  const [search, setSearch] = useState("");
  const [store_id, setStore_id] = useState("");
  const [category, setCategory] = useState("");
  const { data: store } = useGetStoreQuery();
  const { data: ItemCategory } = useGetItemCategorysQuery();
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const filteredData = (search, category, store_id) => {
    return userdata
      ?.filter((items) => {
        if (search == "") {
          return items;
        } else if (
          items.item_name?.toLowerCase().includes(search.toLowerCase()) ||
          items.model?.toLowerCase().includes(search.toLowerCase()) ||
          items.serial_number?.toLowerCase().includes(search.toLowerCase())
        ) {
          return items;
        }
      })
      ?.filter((items) => {
        if (category === "") {
          return items;
        } else if (items?.category_id == category) {
          return items;
        }
      })
      ?.filter((items) => {
        if (store_id === "") {
          return items;
        } else if (items?.store_id == store_id) {
          return items;
        }
      })
      .map((items) => items);
  };
  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pagenum = parseInt(queryParams.get("page"), 10);
  useEffect(() => {
    if (pagenum) {
      setPage(pagenum);
    }
  }, [pagenum]);

  const count = Math.ceil(
    filteredData(search, category, store_id)?.length / PER_PAGE
  );
  const _DATA = usePagination(
    filteredData(search, category, store_id),
    PER_PAGE
  );
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <StockIndex>
      <div className="tool-header-container d-flex gap-3 justify-content-center">
        <div className="d-flex justify-content-end mt-3 p-3">
          <div className="d-flex align-items-center gap-2  me-3">
            <button
              value={0}
              onClick={(e) => {
                setIsBatch(0);
                localStorage.setItem("is_batch", "0");
              }}
              className={
                isBatch == 0
                  ? "report-module-select-active"
                  : "report-module-select"
              }
            ></button>
            <label className="module-label">Single</label>
          </div>
          <div className="d-flex align-items-center gap-2 me-3">
            <button
              value={1}
              onClick={(e) => {
                setIsBatch(1);
                localStorage.setItem("is_batch", "1");
              }}
              className={
                isBatch === 1
                  ? "report-module-select-active"
                  : "report-module-select"
              }
            >
              {" "}
            </button>
            <label className="module-label">Batch </label>
          </div>
        </div>
        <input
          onChange={handleSearchChange}
          placeholder="search"
          type="search"
          className="inventory-search-input"
        />
        <select
          type="search"
          className="inventory-search-input"
          onChange={(e) => setStore_id(e.target.value)}
        >
          <option value="">Store</option>
          {store?.data?.map((items) => (
            <option value={items?.id}>{items?.store_name}</option>
          ))}
        </select>

        <select
          type="search"
          className="inventory-search-input"
          onChange={(e) => setCategory(e.target.value)}
        >
          <option value="">Item Group</option>
          {ItemCategory?.data?.map((items) => (
            <option value={items?.id}>{items?.category}</option>
          ))}
        </select>
        {/* <div className="d-grid gap-2 justify-content-center mb-1">
          <DispatchedTools items={true} />
          <span className="prg-pri">Dispatched Items</span>
        </div> */}
        <div className="d-grid gap-2 mb-1  justify-content-center">
          <ReturnedTools items={true} />
          <span className="prg-pri">Returned Items</span>
        </div>
      </div>
      {_DATA?.currentData() !== 0 ? (
        <>
          <table style={{ width: "100%" }}>
            <thead className="stock-table-header">
              <tr>
                <th style={{ width: "10%" }}></th>
                <th style={{ width: "10%" }}>ID</th>
                <th style={{ width: "10%" }}>Item Name</th>
                <th style={{ width: "10%" }}>Model</th>
                <th style={{ width: "10%" }}>Type</th>
                <th style={{ width: "10%" }}>Store</th>
                <th style={{ width: "10%" }}>Project</th>
                <th style={{ width: "10%" }}>Status</th>
                <th style={{ width: "10%" }}>Requester</th>
                <th style={{ width: "10%" }}>Returned Quantity</th>
                <th style={{ width: "10%" }}>Approval status </th>
                <th style={{ width: "15%" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {_DATA?.currentData()?.map((items) => (
                <tr className="stock-table-row">
                  <td className="">
                    {items?.image != null ? (
                      <img
                        className="stock-img"
                        src={`${IMG_BASE_URL}${items?.image}`}
                        alt=""
                      />
                    ) : (
                      <img
                        sx={{ width: 50, height: 50, borderRadius: 0 }}
                        src={BatchItems}
                        alt=""
                      />
                    )}
                  </td>
                  <td className="text-start">{items?.id}</td>
                  <td className="text-start">
                    {isBatch === 1 ? "Batch requests" : items?.item_name}
                  </td>
                  <td className="text-start">{items?.model}</td>
                  <td className="text-start">
                    {items?.inventory_type_id === null
                      ? "-"
                      : items?.inventory_type}
                  </td>
                  <td className="text-start">
                    {isBatch === 1
                      ? items?.return_items[0]?.store?.store_name
                        ? items?.return_items[0]?.store?.store_name
                        : "-"
                      : items?.store_name === null
                        ? "-"
                        : items?.store_name}
                  </td>
                  <td className="text-start">
                    {isBatch === 1
                      ? items?.return_items[0]?.project?.project_name
                        ? items?.return_items[0]?.project?.project_name
                        : "-"
                      : items?.project_name === null
                        ? "-"
                        : items?.project_name}
                  </td>
                  <td className="text-start">
                    {items?.status_name === null ? "-" : items?.status_name}
                  </td>
                  <td className="text-start">
                    {isBatch === 1
                      ? items?.requester_info?.name
                        ? items?.requester_info?.name
                        : "-"
                      : items?.name
                        ? items?.name
                        : "-"}
                  </td>

                  <td>
                    {items?.returned_quantity === null
                      ? "-"
                      : items?.returned_quantity}
                  </td>

                  <td className="text-start">
                    {isBatch == 0
                      ? items?.is_approved == 0
                        ? "Pending"
                        : items?.is_approved == 1
                          ? "Approved"
                          : "Rejected"
                      : items?.return_status}
                  </td>

                  {isBatch == 1 ? (
                    <td className="text-start">
                      <NavLink
                        className="form-save p-2"
                        to={`/stocks/returnbatchinventory/${items?.id}`}
                      >
                        <ArrowCircleRightTwoToneIcon
                          sx={{ color: "#186569" }}
                        />
                      </NavLink>
                    </td>
                  ) : (
                    <td className="text-start">
                      <NavLink
                        to={{
                          pathname: `/stocks/requests/returned/items/${items?.id}`
                        }}
                        state={"someValue"}
                      >
                        <ArrowCircleRightTwoToneIcon
                          sx={{ color: "#186569" }}
                        />
                      </NavLink>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <NoRecord />
      )}
      {count > 1 ? (
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
          className="p-2"
        />
      ) : (
        ""
      )}
    </StockIndex>
  );
}
