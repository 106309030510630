import React from "react";

export default function ConfirmDelete(props) {
  return (
    <div
      id="Modal"
      className="dialog-modal "
      onClick={(e) => {
        props?.setDialog(false);
        props?.setters(false);
      }}
    >
      <div
        className="dialog-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="dialog-info">{props?.dialog}</div>
        <div className="confirm-dialog-controller">
          <div
            className="dialog-cancel-btn"
            onClick={(e) => {
              props?.setDialog(false);
              props?.setters(false);
            }}
          >
            Cancel
          </div>
          <button
            type={"submit"}
            onClick={(e) => {
              // props.setDialog(false);
              props.setters(true);
              props.action();
            }}
            className="dialog-delete-btn"
          >
            {props?.loading ? (
              <div
                className="spinner-border"
                style={{ color: "#fff" }}
                role="status"
              ></div>
            ) : props?.buttonTitle ? (
              props.buttonTitle
            ) : (
              "Delete"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
