import { Button, Modal } from "@mui/material";
import React, { useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { NavLink } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Message } from "@mui/icons-material";

const ModalSuccess = ({ setOpen, open, Message }) => {
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const params = useParams();
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description"
      >
        <div
          style={{
            backgroundColor: "#fff",
            padding: " 35px",
            textAlign: "center",
          }}
        >
          <CheckCircleIcon
            style={{
              fontSize: 100,
              marginBottom: "10px",
              color: "#064b4f",
            }}
          />
          <h2 id="success-modal-title">Success!</h2>
          <p id="success-modal-description">{Message}</p>
          <div className="d-flex justify-content-center">
            <button className="form-save " onClick={handleClose}>
              Ok
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalSuccess;
