import React, { useState } from "react";
import PRFCheckerTopBar from "../../components/Payment/ForexApproval/PaymentSideBar";
import { FaSearch } from "react-icons/fa";
import "./prfCheck.css";
import EtaPoDetail from "./EtaPoDetail";
import axios from "axios";
import { API_BASE_URL } from "../../api/endPoint";
import PRFCheckComponent from "./PRFCheckComponent";
import NoRecord from "../common/NoRecord";
import { ClipLoader } from "react-spinners";

function PrfChecker() {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
    if (event.target.value === "") {
      setSearchResults([]);
    }
  };
  const handleCancel = () => {
    setShowModal(false);
  };

  const handleSearch = () => {
    if (!searchTerm.trim()) {
      setShowModal(true);
      return;
    }

    axios
      .get(`${API_BASE_URL}/scm/prf_po/${searchTerm}`)
      .then((response) => {
        
        setSearchResults(response?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching search results:", error);
      });
  };

  return (
    <PRFCheckerTopBar title={"PRF Checker"}>
      <div className="input-group">
        <div className="form-outline" data-mdb-input-init>
          <div style={{ position: "relative", width: "250px" }}>
            <input
              type="number"
              value={searchTerm}
              onChange={handleChange}
              placeholder="Enter prf number"
              style={{
                width: "100%",
                paddingLeft: "70px",
              }}
            />
            <span
              className="prg"
              style={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                left: "10px",
                color: "#186569",
              }}
            >
              IE-PRF-
            </span>
          </div>
        </div>
        <button
          onClick={handleSearch}
          type="button"
          style={{ width: "30px", background: "#186569", color: "white" }}
          className="btn btn-success"
          data-mdb-ripple-init
        >
          <FaSearch />
        </button>
      </div>
      {searchResults ? (
        <div
          className="d-flex justify-content-center "
          style={{ minHeight: "100vh" }}
        >
          {!searchTerm || (!searchResults.po && !searchResults.prf) ? (
            <div className="d-flex flex-col justify-content-center align-items-center">
              <ClipLoader size={"50px"} width="fit-content" />
              <div className="prg">Please Wait</div>
            </div>
          ) : (
            <>
              {searchResults.po && (
                <div
                  style={{ width: "40%", marginTop: "20px" }}
                  className="align-items-start"
                >
                  <div className="h5-pri mb-3 ">Purchase Order Information</div>
                  <EtaPoDetail
                    order_id={searchResults.po.order_id}
                    poData={searchResults.po.id}
                    isPO={"PO"}
                  />
                </div>
              )}
              {searchResults.prf && (
                <ul style={{ width: "60%", marginTop: "20px" }}>
                  <div className="h5-pri mb-3">
                    Payment Request Form Information
                  </div>
                  <PRFCheckComponent prf_id={searchResults.prf.id} />
                </ul>
              )}
            </>
          )}
        </div>
      ) : (
        <NoRecord title="Search PRF Number for Result" />
      )}
      {showModal && (
        <div className="modal-container">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div
              className="modal-content "
              style={{ minHeight: "0", width: "72%" }}
            >
              <div className="modal-body">
                <p>Please enter a PRF number</p>
              </div>
              <div className="d-flex flex-row form-buttons modal-footer">
                <button className="form-cancel" onClick={handleCancel}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </PRFCheckerTopBar>
  );
}

export default PrfChecker;
