import React, { useState } from "react";
import {
  useAddManufacturerMutation,
  useGetCurrenciesQuery,
  useGetManufacturersQuery,
  useGetProductsQuery,
  useGetSupplierCategoriesQuery,
  useGetSupplierOriginsQuery,
  useGetSupplierTypesQuery,
} from "../../../features/SCMApi";
import axios from "axios";
import { API_BASE_URL } from "../../../api/endPoint";
import Select from "react-select";
import CloseIcon from "@mui/icons-material/Close";

export default function SupplierManufacturerModal(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {
    data: origin,
    isError,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetSupplierOriginsQuery();
  const {
    data: manufacturers,
    isErrors,
    errors,
    isLoadings,
    isFetchings,
    isSuccesss,
  } = useGetManufacturersQuery();
  const { data: solutionstypes } = useGetProductsQuery();
  const { data: currencies, isErrorcurrency } = useGetCurrenciesQuery();

  const manufaturerList = [];
  manufacturers?.data?.map((items) =>
    manufaturerList.push({
      value: items?.id,
      label: items?.manufacturer_name,
    })
  );
  const [createManufacturer, response] = useAddManufacturerMutation();
  const HandleSubmit = async (e) => {
    e.preventDefault();
    createManufacturer(params)
      .unwrap()
      .then((response) => {

        props.modal(false);
        props.setMessages("Manufacturer created successfully");
      })
      .then((error) => {

      });
  };

  // freight info
  const [manufacturer_name, setManufacturer_name] = useState();
  const [origin_id, setManufacturer_origin] = useState();
  const [city, setManufacturer_city] = useState();
  const [website, setManufacturer_website] = useState();
  const [address, setManufacturer_address] = useState();
  const [description, setDescription] = useState();
  // bank detail
  const [bank_name, setBank_name] = useState();
  const [account_name, setAccountName] = useState();
  const [account_number, setAccount] = useState();
  const [swift_code, setSwift] = useState();
  const [iban, setIban] = useState();
  const [bank_country, setBankcountry] = useState();
  const [bank_currency, setBankcurrency] = useState();
  // contact person
  const [full_name, setFullname] = useState();
  const [email, setEmail] = useState();
  const [role, setRole] = useState();
  const [phone_numbers, setphone] = useState([0]);
  const [supplier_type, setSuppliertype] = useState();
  const [count, setCount] = useState([]);
  const [checked, setChecked] = useState(false);
  const [isSupplier, setisSupplier] = useState(false);
  const [solutions, setSolutions] = useState([]);
  const [supplier_type_id, setSupplierTypeData] = useState(null);
  const [solutionproduct, setSolutionproduct] = useState();
  const [postal_code, setPobox] = useState();
  const [is_offshore, setOffshore] = useState(false);
  const [category_id, setCategory_id] = useState();
  const [department, setDepartment] = useState(null);
  const CheckBoxHandel = () => {
    setChecked(!checked);
    setisSupplier(true);
  };
  const handleChange = (e, i) => {
    e.preventDefault();
    const value = e.target.value;
    const phoneList = [...phone_numbers];
    phoneList[i] = value;
    setphone(phoneList);
  };

  function HandleCancel() {
    props.modal(false);

  }

  const solutionsProdcts = [];
  solutionstypes?.data?.map((items) => {
    if (items.id == solutionproduct) {
      solutionsProdcts.push({ data: items?.solution });
    }
  });
  const { data: suppliertypes } = useGetSupplierTypesQuery();
  const SuppliertypeList = [];
  suppliertypes?.data?.map((items) =>
    SuppliertypeList.push({
      value: items?.id,
      label: items?.type_name,
    })
  );
  const dataPush = [];
  if (supplier_type_id !== null) {
    for (let id in supplier_type_id) {
      dataPush.push(supplier_type_id[id]?.value);
    }
  }
  const { data: supplierCategoryTypes } = useGetSupplierCategoriesQuery();
  const SupplierCategorytypeList = [];
  supplierCategoryTypes?.data?.map((items) =>
    SupplierCategorytypeList.push({
      value: items?.id,
      label: items?.name,
    })
  );
  let category_ids = [];
  if (category_id != null) {
    for (let id in category_id) {
      category_ids.push(category_id[id]?.value);
    }
  }

  let params = {
    manufacturer_name,
    origin_id,
    website,
    address,
    bank_name,
    account_name,
    swift_code,
    iban,
    bank_country,
    bank_currency,
    full_name,
    email,
    role,
    phone_numbers,
    description,
    account_number,
    partner_types: dataPush,
    isSupplier,
    is_offshore: is_offshore === false ? 0 : 1,
    department: department,
    category_ids,
    solutions,
    city,
    postal_code,
  };

  const handleChangesoutions = (e, i) => {
    e.preventDefault();
    const value = e.target.value;
    const Solutionlists = [...solutions];
    Solutionlists[i] = value;
    setSolutions(Solutionlists);
  };
  const CheckBoxHandelonShore = () => {
    setOffshore(!is_offshore);
  };

  return (
    <div>
      <div id="myModal" className="modal" onClick={HandleCancel}>
        <div className="modal-content-md" onClick={(e) => e.stopPropagation()}>
          <div className="create-project-modal-title">
            <div>Add Manufacturer</div>
            <CloseIcon onClick={HandleCancel} />
          </div>
          <form className="form-container" onSubmit={HandleSubmit}>
            <div className="form-card-container">
              <div className="text-center">
                <label className="d-flex gap-2">
                  <input
                    type="checkbox"
                    className="stock-checkmark"
                    onChange={CheckBoxHandel}
                  />
                  <span className="h5">is Supplier</span>
                </label>
              </div>
              <div
                style={{ marginTop: "0px" }}
                className="d-flex justify-content-center gap-2  "
              >
                <input
                  type="checkbox"
                  className="stock-checkmark"
                  onChange={CheckBoxHandelonShore}
                />
                <span className="h6 ">Off Shore</span>
              </div>
              <div className="form-card-information">
                <div className="project-information">
                  <div className="form-card-title">
                    Manufacturer Information Details
                  </div>
                  <div className="card-center">
                    <div className="card-form">
                      <div className="supplier-input-labels">
                        <div className="d-flex gap-2 align-items-baseline">
                          <div className="supplier-input-label">Name</div>
                          <input
                            type="text"
                            className="supplier-text-input"
                            required
                            onChange={(e) =>
                              setManufacturer_name(e.target.value)
                            }
                          />
                        </div>

                        <div className="d-flex gap-2 align-items-baseline">
                          <div className="supplier-input-label">Department</div>
                          <select
                            name=""
                            id=""
                            className="supplier-select-option"
                            required
                            value={department}
                            onChange={(e) => setDepartment(e.target.value)}
                          >
                            <option value="" disabled selected hidden>
                              Select Department
                            </option>
                            <option value="Sourcing">Sourcing</option>
                            <option value="Finance">Finance</option>
                            <option value="Logistics">Logistics</option>
                            <option value="Others">Others</option>
                          </select>
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          <div className="supplier-input-label">
                            Supplier Category
                          </div>
                          <div style={{ width: "199px" }}>
                            <Select
                              options={SupplierCategorytypeList}
                              isSearchable={true}
                              isMulti
                              required
                              onChange={(e) => {
                                setCategory_id(e);
                              }}
                            />
                          </div>
                        </div>

                        <div className="d-flex gap-2 align-items-baseline">
                          <div className="supplier-input-label">
                            Partner Type
                          </div>

                          <div style={{ width: "199px" }}>
                            <Select
                              options={SuppliertypeList}
                              isSearchable={true}
                              isMulti
                              required
                              onChange={(e) => {
                                setSupplierTypeData(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          <div className="supplier-input-label">
                            Description
                          </div>
                          <textarea
                            type="text"
                            className="supplier-text-input-textarea"
                            rows={4}
                            cols={50}
                            required
                            onChange={(e) => setDescription(e.target.value)}
                          ></textarea>
                        </div>
                        {checked ? (
                          <>
                            <div className="d-flex gap-2 align-items-baseline">
                              <div className="supplier-input-label">
                                Solutions
                              </div>
                              <select
                                name=""
                                id=""
                                className="supplier-select-option"
                                required
                                onChange={(e) =>
                                  setSolutionproduct(e.target.value)
                                }
                              >
                                <option value="" disabled selected hidden>
                                  Select Solution
                                </option>
                                {solutionstypes?.data?.map((items) => (
                                  <option value={items.id}>
                                    {items.department_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  {checked && (
                    <>
                      <div className="d-grid justify-content-center my-3 ms-5">
                        {" "}
                        {solutionsProdcts[0]?.data?.map((items, index) => (
                          <div className="text-center">
                            <label className="d-flex gap-2">
                              <input
                                type="checkbox"
                                className=""
                                value={items.id}
                                required
                                onChange={(event) =>
                                  handleChangesoutions(event, index)
                                }
                              />
                              <span className="h5-pri">
                                {items.solution_name}
                              </span>
                            </label>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="form-card-information">
                <div className="project-information">
                  <div className="form-card-title">Address Detail</div>
                  <div className="card-center">
                    <div className="card-form">
                      <div className="supplier-input-labels">
                        <div className="d-flex gap-2 align-items-baseline">
                          <div className="supplier-input-label">Origin</div>
                          <select
                            name=""
                            id=""
                            className="supplier-select-option"
                            required
                            onChange={(e) =>
                              setManufacturer_origin(e.target.value)
                            }
                          >
                            {origin?.data?.map((items) => (
                              <option value={items.id}>{items.name}</option>
                            ))}
                          </select>
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          <div className="supplier-input-label">City</div>

                          <input
                            type="text"
                            className="supplier-text-input"
                            required
                            onChange={(e) =>
                              setManufacturer_city(e.target.value)
                            }
                          />
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          <div className="supplier-input-label">Website</div>
                          <input
                            type="text"
                            className="supplier-text-input"
                            required
                            onChange={(e) =>
                              setManufacturer_website(e.target.value)
                            }
                          />
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          <div className="supplier-input-label">Address</div>
                          <input
                            type="text"
                            className="supplier-text-input"
                            required
                            onChange={(e) =>
                              setManufacturer_address(e.target.value)
                            }
                          />
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          <div className="supplier-input-label">P.O.BOX</div>
                          <input
                            type="text"
                            className="supplier-text-input"
                            required
                            onChange={(e) => setPobox(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-card-information">
                <div className="project-information">
                  <div className="form-card-title">Bank Details</div>
                  <div className="card-center">
                    <div className="card-form">
                      <div className="supplier-input-labels">
                        <div className="d-flex gap-2 align-items-baseline">
                          <div className="supplier-input-label">
                            Account Name
                          </div>
                          <input
                            type="text"
                            className="supplier-text-input"
                            required
                            onChange={(e) => setAccountName(e.target.value)}
                          />
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          <div className="supplier-input-label">Bank Name</div>
                          <input
                            type="text"
                            className="supplier-text-input"
                            required
                            onChange={(e) => setBank_name(e.target.value)}
                          />
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          {" "}
                          <div className="supplier-input-label">Account No</div>
                          <input
                            type="text"
                            className="supplier-text-input"
                            required
                            onChange={(e) => setAccount(e.target.value)}
                          />
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          {" "}
                          <div className="supplier-input-label">Swift Code</div>
                          <input
                            type="text"
                            className="supplier-text-input"
                            required
                            onChange={(e) => setSwift(e.target.value)}
                          />
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          {" "}
                          <div className="supplier-input-label">IBAN</div>
                          <input
                            type="text"
                            className="supplier-text-input"
                            required
                            onChange={(e) => setIban(e.target.value)}
                          />
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          {" "}
                          <div className="supplier-input-label">
                            Bank Country
                          </div>
                          <select
                            name=""
                            id=""
                            className="supplier-select-option"
                            required
                            onChange={(e) => setBankcountry(e.target.value)}
                          >
                            <option value="" disabled selected hidden>
                              Select Bank Country
                            </option>
                            {origin?.data?.map((items) => (
                              <option value={items.id}>{items.name}</option>
                            ))}
                          </select>
                        </div>
                        <div className="d-flex gap-2 align-items-baseline">
                          {" "}
                          <div className="supplier-input-label">
                            Bank Currency
                          </div>
                          <select
                            name=""
                            id=""
                            className="supplier-select-option"
                            required
                            onChange={(e) => setBankcurrency(e.target.value)}
                          >
                            <option value="" disabled selected hidden>
                              Select Bank Currency
                            </option>
                            {currencies?.data?.map((items) => (
                              <option value={items.name}>{items.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-card-information">
                <div className="project-information">
                  <div className="form-card-title">Contact Person</div>
                  <div className="card-center">
                    <div className="card-form">
                      <div className="d-flex gap-3">
                        <div className="supplier-input-labels">
                          <div className="input-label">Full Name</div>
                          <div className="input-label">Email</div>
                          <div className="input-label">Role</div>
                          <div className="input-label">Phone</div>
                        </div>
                        <div className="input-fields">
                          <input
                            type="text"
                            className="text-input"
                            required
                            onChange={(e) => setFullname(e.target.value)}
                          />
                          <input
                            type="email"
                            className="text-input"
                            required
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <input
                            type="text"
                            className="text-input"
                            required
                            onChange={(e) => setRole(e.target.value)}
                          />

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginLeft: "13%",
                              gap: "4px",
                            }}
                          >
                            <div className="d-flex">
                              <input
                                type={"number"}
                                className="supplier-text-input"
                                required
                                onChange={(event) => handleChange(event, 0)}
                              ></input>
                              <button
                                className="btn-add"
                                onClick={(event) => {
                                  setCount((current) => [
                                    ...current,
                                    count[1] + 1,
                                  ]);
                                  event.preventDefault();
                                }}
                              >
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M10.0688 14.5834C10.2674 14.5834 10.4316 14.5184 10.5615 14.3886C10.6914 14.2587 10.7563 14.0945 10.7563 13.8959V10.7792H13.9188C14.1021 10.7792 14.2587 10.7143 14.3886 10.5844C14.5184 10.4546 14.5834 10.2903 14.5834 10.0917C14.5834 9.8931 14.5184 9.72886 14.3886 9.599C14.2587 9.46914 14.0945 9.40421 13.8959 9.40421H10.7563V6.08129C10.7563 5.89796 10.6914 5.74136 10.5615 5.6115C10.4316 5.48164 10.2674 5.41671 10.0688 5.41671C9.87018 5.41671 9.70594 5.48164 9.57608 5.6115C9.44622 5.74136 9.38129 5.9056 9.38129 6.10421V9.40421H6.08129C5.89796 9.40421 5.74136 9.46914 5.6115 9.599C5.48164 9.72886 5.41671 9.8931 5.41671 10.0917C5.41671 10.2903 5.48164 10.4546 5.6115 10.5844C5.74136 10.7143 5.9056 10.7792 6.10421 10.7792H9.38129V13.9188C9.38129 14.1021 9.44622 14.2587 9.57608 14.3886C9.70594 14.5184 9.87018 14.5834 10.0688 14.5834ZM10 19.1667C8.70143 19.1667 7.49449 18.9337 6.37921 18.4677C5.26393 18.0018 4.29379 17.3563 3.46879 16.5313C2.64379 15.7063 1.9983 14.7362 1.53233 13.6209C1.06636 12.5056 0.833374 11.2987 0.833374 10C0.833374 8.71671 1.06636 7.5174 1.53233 6.40212C1.9983 5.28685 2.64379 4.31671 3.46879 3.49171C4.29379 2.66671 5.26393 2.0174 6.37921 1.54379C7.49449 1.07018 8.70143 0.833374 10 0.833374C11.2834 0.833374 12.4827 1.07018 13.598 1.54379C14.7132 2.0174 15.6834 2.66671 16.5084 3.49171C17.3334 4.31671 17.9827 5.28685 18.4563 6.40212C18.9299 7.5174 19.1667 8.71671 19.1667 10C19.1667 11.2987 18.9299 12.5056 18.4563 13.6209C17.9827 14.7362 17.3334 15.7063 16.5084 16.5313C15.6834 17.3563 14.7132 18.0018 13.598 18.4677C12.4827 18.9337 11.2834 19.1667 10 19.1667ZM10 17.7917C12.1389 17.7917 13.9723 17.0278 15.5 15.5C17.0278 13.9723 17.7917 12.1389 17.7917 10C17.7917 7.86115 17.0278 6.02782 15.5 4.50004C13.9723 2.97226 12.1389 2.20837 10 2.20837C7.86115 2.20837 6.02782 2.97226 4.50004 4.50004C2.97226 6.02782 2.20837 7.86115 2.20837 10C2.20837 12.1389 2.97226 13.9723 4.50004 15.5C6.02782 17.0278 7.86115 17.7917 10 17.7917Z"
                                    fill="#186569"
                                  />
                                </svg>
                              </button>
                            </div>
                            {count?.map((item, i) => (
                              <input
                                type={"number"}
                                className="supplier-text-input"
                                required
                                onChange={(event) => handleChange(event, i + 1)}
                              ></input>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-buttons">
                <button
                  type="cancel"
                  className="form-cancel"
                  onClick={HandleCancel}
                >
                  {" "}
                  Cancel
                </button>
                <button type="submit" className="form-save">
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
