import React, { useState } from "react";
import { batch } from "react-redux";
import { NavLink } from "react-router-dom";
import { useGetBatchesQuery } from "../../features/SCMApi";
import CreateBatchModal from "../common/CreateBatchModal";
import BatchCard from "../Logistics/BatchCard";

export default function SourcingBatch() {
  const [createBatchModal, setCreateBatchModal] = useState(false);
  const { data: batches } = useGetBatchesQuery();

  return (
    <>
      <div
        className="create-project-btn mt-4"
        onClick={(e) => setCreateBatchModal(true)}
      >
        Create Batch
      </div>
      <div className="mt-4 logistics-batch-container ">
        {batches?.data?.map((items) => (
          <BatchCard batch={items} />
        ))}
      </div>
      {createBatchModal ? (
        <CreateBatchModal ToggleModal={setCreateBatchModal} />
      ) : (
        <></>
      )}
    </>
  );
}
