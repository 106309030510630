import * as React from "react";
import { NavLink } from "react-router-dom";
import { useGetUserInfoQuery } from "../../../features/SCMApi";
import NavBar from "../../../Layout/NavBar";
import CheckPermission from "../../common/checkPermission/checkPermission";
import "../forex.style.css";
import "../guarantee.style.css";
import { Breadcrumb } from "react-bootstrap";

export default function Index(props) {
  const { data: userinfo } = useGetUserInfoQuery();

  return (
    <>
      <NavBar
        handleSearchChange={props?.handleSearchChange}
        placeholder={props?.placeholder}
      />
      <div className="all-breadcrumb">
        <Breadcrumb className="">
          <Breadcrumb.Item className="prg" href="/payment">
            Payment
          </Breadcrumb.Item>
          {/* <NavLink className="prg" to="smm">
            Payment
          </NavLink> */}
          <Breadcrumb.Item className="prg" href={props.navlink}>
            {props.title}
          </Breadcrumb.Item>
          {props.name === undefined ? (
            ""
          ) : (
            <Breadcrumb.Item className="prg-pri" active>
              {props.name}
            </Breadcrumb.Item>
          )}
        </Breadcrumb>
      </div>
      <div className="page">
        <div className="block ">
          <div className="">
            <div className="">{props.children}</div>
          </div>
        </div>
      </div>
    </>
  );
}
