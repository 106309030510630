import React from "react";
import { useState } from "react";

export default function InventoryApprovalRejectModal(props) {
  return (
    <div
      id="Modal"
      className="dialog-modal "
      onClick={(e) => {
        props?.setDialog(false);
        props?.setters(false);
      }}
    >
      <div
        className="stock-dialog-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="dialog-info">
          Are you sure to <span style={{ color: "red" }}>Reject</span>
        </div>
        {/* <div className="dialog-info">{props?.dialog}</div> */}

        {props?.setComment != undefined ? (
          <div className="d-grid gap-1 align-items-baseline">
            <div className="supplier-input-label">Comment</div>
            <textarea
              cols="30"
              rows="4"
              onChange={(e) => props?.setComment(e.target.value)}
            ></textarea>
          </div>
        ) : (
          ""
        )}

        <div className="confirm-dialog-controller">
          <div
            className="dialog-cancel-btn"
            onClick={(e) => {
              props?.setDialog(false);
            }}
          >
            No
          </div>
          <button
            type={"submit"}
            onClick={(e) => {
              // props.setDialog(false);
              props.action(e, props?.dialog?.id);
            }}
            className="form-save"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
}
