import React, { useState } from "react";
import Settingsidebar from "../../pages/Settings/SettingIndex";
import "./paymentSettings.css";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddIcon from "@mui/icons-material/Add";
import AddPaymentModal from "./SettingModal/AddPaymentModal";
import {
  useDeleteSettingBankinformationMutation,
  useGetSettingBankQuery,
} from "../../features/SCMApi";
import usePagination from "../Pagination/Pagination";
import Pagination from "@mui/material/Pagination";
import Tooltip from "@mui/material/Tooltip";
import { Button } from "@mui/material";
import AddBankinformation from "./SettingModal/AddBankinformation";
import EditBankInformation from "./SettingModal/EditBankInformation";
export default function BankSettings() {
  const [addBankModal, setAddBankModal] = useState(false);
  const [editBankModal, setEditBankModal] = useState(false);
  const [bankdetail, setbank] = useState(null);
  const { data: bank } = useGetSettingBankQuery();
  
  const [Messages, setMessages] = useState(null);
  let [page, setPage] = useState(1);
  let [pagebank, setPagebank] = useState(1);
  const PER_PAGE = 5;
  const countbank = Math.ceil(bank?.data?.length / PER_PAGE);
  const _DATABank = usePagination(bank?.data, PER_PAGE);

  const handleChangeBank = (e, p) => {
    setPagebank(p);
    _DATABank.jump(p);
  };
  
  const [deletedBank, res] = useDeleteSettingBankinformationMutation();
  const HandleDeleteBank = (e, items) => {
    let bankdeleted = { id: items };
    e.preventDefault();
    deletedBank(bankdeleted)
      .unwrap()
      .then((response) => {
        
        setMessages("Bank Information Deleted successfully");
      })
      .catch((error) => {
        
      });
  };
  const [search, setSearch] = useState("");
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    
  };
  return (
    <Settingsidebar
      handleSearchChange={handleSearchChange}
      placeholder={"search Bank..."}
      Messages={Messages}
      name={"Bank"}
    >
      <div className="page ">
        <div className="bnk-container">
          <div className="bnk-header">
            <span>Bank Name</span>
            <span>Address</span>

            <span>P.O Box</span>
            <span>Telephone</span>
          </div>
          {_DATABank
            ?.currentData()
            ?.filter((items) => {
              if (search === "") {
                return items;
              } else if (items.bank_name?.toLowerCase().includes(search)) {
                return items;
              }
            })
            .map((items) => (
              <Tooltip title={items.bank_name} arrow>
                <div className="bnk-body">
                  <section className="bnk-detail">
                    <div class="setting-representative-container">
                      <div class="prg representative-header">Bank Name</div>
                      <span>{items.abbreviation}</span>
                    </div>
                    {items?.bank_address?.map((addressitems) => (
                      <>
                        <div class="setting-representative-container">
                          <div class="prg representative-header">Address</div>
                          <span>
                            {addressitems.address === null
                              ? "Unknown"
                              : addressitems.address}
                          </span>
                        </div>

                        <div class="setting-representative-container">
                          <div class="prg representative-header">P.O.Box</div>
                          <span>
                            {addressitems.postal_code === null
                              ? "Unknown"
                              : addressitems.postal_code}
                          </span>
                        </div>
                        <div class="setting-representative-container">
                          <div class="prg representative-header">Telephone</div>
                          <span>
                            {addressitems?.phone === null
                              ? "Unknown"
                              : addressitems?.phone}
                          </span>
                        </div>
                      </>
                    ))}
                    <div class="setting-representative-container">
                      <div class="prg representative-header">Action</div>
                      <span>
                        <ModeEditOutlineOutlinedIcon
                          onClick={() => {
                            setEditBankModal(true);
                            setbank(items);
                          }}
                          sx={{
                            color: "#52FFAC",
                            "&:hover": {
                              backgroundColor: "#fff",
                              color: "#52FFAC",
                              border: "1px solid #52FFAC",
                              borderRadius: 25,
                            },
                          }}
                        />
                        <DeleteOutlineOutlinedIcon
                          onClick={(e) => {
                            HandleDeleteBank(e, items.id);
                          }}
                          sx={{
                            color: "#FF5252",
                            "&:hover": {
                              backgroundColor: "#fff",
                              color: "#FF5252",
                              border: "1px solid #FF5252",
                              borderRadius: 25,
                            },
                          }}
                        />
                      </span>
                    </div>
                  </section>
                </div>
              </Tooltip>
            ))}

          <div className="pi-footer">
            {countbank > 1 ? (
              <Pagination
                count={countbank}
                size="large"
                page={pagebank}
                variant="outlined"
                shape="rounded"
                onChange={handleChangeBank}
              />
            ) : (
              ""
            )}
            <div
              onClick={() => {
                setAddBankModal(true);
              }}
              className="pi-footer-icon bnk-footer"
            >
              <AddIcon />
            </div>
          </div>
        </div>
      </div>

      {addBankModal ? (
        <AddBankinformation modal={setAddBankModal} setMessages={setMessages} />
      ) : (
        <></>
      )}
      {editBankModal ? (
        <EditBankInformation
          modal={setEditBankModal}
          setMessages={setMessages}
          bank={bankdetail}
        />
      ) : (
        <></>
      )}
    </Settingsidebar>
  );
}
