import React from "react";
import Accordion from "react-bootstrap/Accordion";

export default function BatchAccordion(props) {
  return (
    <div className="according-container">
      {" "}
      <Accordion defaultActiveKey="0" flush>
        <Accordion.Item eventKey={props.key}>
          <Accordion.Header>Commercial Invoice</Accordion.Header>
          <Accordion.Body>
            <div className="row gap-3">
              <div className="row align-items-center col-12">
                <div className="col-4">
                  <div className="owner-name">Meried Bekele</div>
                </div>
                <div className="col-4">
                  <div className="status-approved-doc row text-danger  align-items-center gap-3">
                    <div className="col-2  ">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.1875 11.6L8 8.7875L10.8125 11.6L11.6 10.8125L8.7875 8L11.6 5.1875L10.8125 4.4L8 7.2125L5.1875 4.4L4.4 5.1875L7.2125 8L4.4 10.8125L5.1875 11.6ZM8 15.5C6.975 15.5 6.00625 15.3031 5.09375 14.9094C4.18125 14.5156 3.38438 13.9781 2.70312 13.2969C2.02187 12.6156 1.48437 11.8188 1.09062 10.9062C0.696875 9.99375 0.5 9.025 0.5 8C0.5 6.9625 0.696875 5.9875 1.09062 5.075C1.48437 4.1625 2.02187 3.36875 2.70312 2.69375C3.38438 2.01875 4.18125 1.48437 5.09375 1.09062C6.00625 0.696875 6.975 0.5 8 0.5C9.0375 0.5 10.0125 0.696875 10.925 1.09062C11.8375 1.48437 12.6313 2.01875 13.3063 2.69375C13.9813 3.36875 14.5156 4.1625 14.9094 5.075C15.3031 5.9875 15.5 6.9625 15.5 8C15.5 9.025 15.3031 9.99375 14.9094 10.9062C14.5156 11.8188 13.9813 12.6156 13.3063 13.2969C12.6313 13.9781 11.8375 14.5156 10.925 14.9094C10.0125 15.3031 9.0375 15.5 8 15.5ZM8 14.375C9.775 14.375 11.2812 13.7531 12.5187 12.5094C13.7562 11.2656 14.375 9.7625 14.375 8C14.375 6.225 13.7562 4.71875 12.5187 3.48125C11.2812 2.24375 9.775 1.625 8 1.625C6.2375 1.625 4.73438 2.24375 3.49063 3.48125C2.24688 4.71875 1.625 6.225 1.625 8C1.625 9.7625 2.24688 11.2656 3.49063 12.5094C4.73438 13.7531 6.2375 14.375 8 14.375Z"
                          fill="#FF5252"
                        />
                      </svg>
                    </div>
                    Not Approved
                  </div>
                  <div className="status-declined-doc row"></div>
                </div>
                <div className="col-4 row">
                  <div className="col-12 row justify-content-center">
                    comment
                  </div>
                  <div className="col-12 batch-comment">
                    Please fix the issues on lorem ipsum doret sit amet lorem
                    ipsum doret sit amet lorem ipsum doret sit amet
                  </div>
                </div>
              </div>
              <div className="row align-items-center col-12">
                <div className="col-4">
                  <div className="owner-name">Girma Beyene</div>
                </div>
                <div className="col-4">
                  <div className="status-approved-doc row text-success  align-items-center gap-3">
                    <div className="col-2  ">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.89375 11.4125L12.2 6.10625L11.3375 5.2625L6.89375 9.70625L4.64375 7.45625L3.8 8.3L6.89375 11.4125ZM8 15.5C6.975 15.5 6.00625 15.3031 5.09375 14.9094C4.18125 14.5156 3.38438 13.9781 2.70312 13.2969C2.02188 12.6156 1.48437 11.8188 1.09062 10.9062C0.696875 9.99375 0.5 9.025 0.5 8C0.5 6.9625 0.696875 5.9875 1.09062 5.075C1.48437 4.1625 2.02188 3.36875 2.70312 2.69375C3.38438 2.01875 4.18125 1.48437 5.09375 1.09062C6.00625 0.696875 6.975 0.5 8 0.5C9.0375 0.5 10.0125 0.696875 10.925 1.09062C11.8375 1.48437 12.6313 2.01875 13.3063 2.69375C13.9813 3.36875 14.5156 4.1625 14.9094 5.075C15.3031 5.9875 15.5 6.9625 15.5 8C15.5 9.025 15.3031 9.99375 14.9094 10.9062C14.5156 11.8188 13.9813 12.6156 13.3063 13.2969C12.6313 13.9781 11.8375 14.5156 10.925 14.9094C10.0125 15.3031 9.0375 15.5 8 15.5ZM8 14.375C9.775 14.375 11.2812 13.7531 12.5187 12.5094C13.7562 11.2656 14.375 9.7625 14.375 8C14.375 6.225 13.7562 4.71875 12.5187 3.48125C11.2812 2.24375 9.775 1.625 8 1.625C6.2375 1.625 4.73438 2.24375 3.49063 3.48125C2.24688 4.71875 1.625 6.225 1.625 8C1.625 9.7625 2.24688 11.2656 3.49063 12.5094C4.73438 13.7531 6.2375 14.375 8 14.375Z"
                          fill="#52FFAC"
                        />
                      </svg>
                    </div>
                    Approved
                  </div>
                  <div className="status-declined-doc row"></div>
                </div>
                <div className="col-4 row"></div>
              </div>
              <div className="row align-items-center col-12">
                <div className="col-4">
                  <div className="owner-name">Hannah Girma</div>
                </div>
                <div className="col-4">
                  <div className="status-approved-doc row text-success  align-items-center gap-3">
                    <div className="col-2  ">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.89375 11.4125L12.2 6.10625L11.3375 5.2625L6.89375 9.70625L4.64375 7.45625L3.8 8.3L6.89375 11.4125ZM8 15.5C6.975 15.5 6.00625 15.3031 5.09375 14.9094C4.18125 14.5156 3.38438 13.9781 2.70312 13.2969C2.02188 12.6156 1.48437 11.8188 1.09062 10.9062C0.696875 9.99375 0.5 9.025 0.5 8C0.5 6.9625 0.696875 5.9875 1.09062 5.075C1.48437 4.1625 2.02188 3.36875 2.70312 2.69375C3.38438 2.01875 4.18125 1.48437 5.09375 1.09062C6.00625 0.696875 6.975 0.5 8 0.5C9.0375 0.5 10.0125 0.696875 10.925 1.09062C11.8375 1.48437 12.6313 2.01875 13.3063 2.69375C13.9813 3.36875 14.5156 4.1625 14.9094 5.075C15.3031 5.9875 15.5 6.9625 15.5 8C15.5 9.025 15.3031 9.99375 14.9094 10.9062C14.5156 11.8188 13.9813 12.6156 13.3063 13.2969C12.6313 13.9781 11.8375 14.5156 10.925 14.9094C10.0125 15.3031 9.0375 15.5 8 15.5ZM8 14.375C9.775 14.375 11.2812 13.7531 12.5187 12.5094C13.7562 11.2656 14.375 9.7625 14.375 8C14.375 6.225 13.7562 4.71875 12.5187 3.48125C11.2812 2.24375 9.775 1.625 8 1.625C6.2375 1.625 4.73438 2.24375 3.49063 3.48125C2.24688 4.71875 1.625 6.225 1.625 8C1.625 9.7625 2.24688 11.2656 3.49063 12.5094C4.73438 13.7531 6.2375 14.375 8 14.375Z"
                          fill="#52FFAC"
                        />
                      </svg>
                    </div>
                    Approved
                  </div>
                  <div className="status-declined-doc row"></div>
                </div>
                <div className="col-4 row"></div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
