import axios from "axios";
import React, { useState } from "react";
import { API_BASE_URL } from "../../../api/endPoint";
import { useEditSettingBankinformationMutation } from "../../../features/SCMApi";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import CreatePermission from "../SettingModal/CreatePermission";
export default function EditBankInformation(props) {
  const [bank_name, setbank_name] = useState(props.bank?.bank_name);
  const [swift_code, setSwiftcode] = useState(
    props.bank?.bank_address[0]?.swift_code
  );
  const [address, setAddress] = useState(props.bank?.bank_address[0]?.address);
  const [postal_code, setPostalCode] = useState(
    props.bank?.bank_address[0]?.postal_code
  );
  const [phone, setPhone_number] = useState(props.bank?.bank_address[0]?.phone);
  const [fax, setfax] = useState(props.bank?.bank_address[0]?.fax);
  const [abbreviation, setabbreviation] = useState(props?.bank?.abbreviation);
  let information = {
    id: props?.bank?.id,
    bank_name,
    swift_code,
    address,
    phone,
    postal_code,
    fax,
    abbreviation,
  };

  function HandleCancel() {
    props.modal(false);
  }
  
  const [updateBank, res] = useEditSettingBankinformationMutation();

  const HandleSubmit = async (e) => {
    e.preventDefault();
    // axios
    //   .post(
    //     `${API_BASE_URL}/scm/settings/bank-details/${props?.bank?.id}`,
    //     information,
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   )
    //   .then((response) => {
    
    //     props.modal(false);
    //     props.setMessages("Bank Information Updated Successfully");
    //   })
    //   .then((error) => {
    
    //     props.onClose();
    //   });
    updateBank(information)
      .unwrap()
      .then((response) => {
       
        props.modal(false);
        props.setMessages("Bank Information Updated Successfully");
      })
      .then((error) => {
        
      });
  };
  return (
    <div>
      <div id="myModal" className="modal" onClick={HandleCancel}>
        <div className="modal-content-md" onClick={(e) => e.stopPropagation()}>
          <div className="create-project-modal-title clientRepModal">
            <div className="">Edit Bank Information</div>
            <div onClick={HandleCancel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="42"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>

          <form className="form-container" onSubmit={HandleSubmit}>
            <div className="form-card-container">
              <div className="form-card-information">
                <div className="">
                  <div className="card-center">
                    <div className="card-form">
                      <div className="input-labels">
                        <div className="input-label">Name</div>
                        <div className="input-label"> Abbreviation</div>
                        <div className="input-label">Address</div>
                        <div className="input-label">Swift Code</div>
                        <div className="input-label">P.O.BOX</div>
                        <div className="input-label">Fax</div>
                        <div className="input-label">Telephone</div>
                      </div>
                      <div className="input-fields">
                        <input
                          type="text"
                          className="text-input"
                          defaultValue={bank_name}
                          onChange={(e) => setbank_name(e.target.value)}
                        />
                        <input
                          type="text"
                          className="text-input"
                          defaultValue={abbreviation}
                          onChange={(e) => setabbreviation(e.target.value)}
                        />
                        <input
                          type="text"
                          className="text-input"
                          defaultValue={address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                        <input
                          type="text"
                          className="text-input"
                          defaultValue={swift_code}
                          onChange={(e) => setSwiftcode(e.target.value)}
                        />
                        <input
                          type="text"
                          className="text-input"
                          defaultValue={postal_code}
                          onChange={(e) => setPostalCode(e.target.value)}
                        />
                        <input
                          type="text"
                          className="text-input"
                          defaultValue={fax}
                          onChange={(e) => setfax(e.target.value)}
                        />
                        <input
                          type="text"
                          className="text-input"
                          defaultValue={phone}
                          onChange={(e) => setPhone_number(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-buttons repreForm">
                <button
                  type="cancel"
                  className="form-cancel repreCanel"
                  onClick={HandleCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="form-save repreSave"
                  onClick={HandleSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
