import React from "react";
import { useGetSupplierOriginsQuery } from "../../features/SCMApi";
import "../../pages/Payment/Payable/aging.css";
import { useGetSuppliersOrdersQuery } from "../../features/SCMApi";

export default function SupplierOffShoreFilter({
  handleSearchSubmit,
  searchCountry,
  setSearchCountry,
  searchSupplier,
  setSearchSupplier,
}) {
  const { data: response } = useGetSupplierOriginsQuery();
  const suppliersList = [];
  const { data: Suppliers } = useGetSuppliersOrdersQuery();
  Suppliers?.data?.map((items) =>
    suppliersList.push({
      value: items?.id,
      label: items?.distributor_name,
    })
  );
  return (
    <div>
      <form className="payable-selector" onSubmit={handleSearchSubmit}>
        <div>
          <label className="create-bach-input-label" htmlFor="origin">
            Country
          </label>
          <select
            className="custom-select prg"
            name="country"
            id="country"
            placeholder="Country"
            value={searchCountry}
            onChange={(e) => setSearchCountry(e.target.value)}
          >
            <option value="">Choose origin</option>
            {response?.data?.map((items) => (
              <option value={items?.name}>{items?.name}</option>
            ))}
          </select>
        </div>
        <div>
          <label className="create-bach-input-label" htmlFor="origin">
            Supplier
          </label>
          <select
            className="custom-select prg"
            name="supplier"
            id="supplier"
            placeholder="supplier"
            value={searchSupplier}
            onChange={(e) => setSearchSupplier(e.target.value)}
          >
            <option value="">Select supplier</option>
            {suppliersList.map((item) => (
              <option key={item.value} value={item.label}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
      </form>
    </div>
  );
}
