import React, { useState } from "react";
import {
  useGetForexNeedsQuery,
  useGetPaymentTrackingForProjectReportQuery,
} from "../../features/SCMApi";
import ForexNeed from "../Payment/ForexPlanning/ForexNeed";
import { Pagination, Tooltip } from "@mui/material";
import usePagination from "../Pagination/Pagination";
import DownloadReport from "./DownloadReport";
import FinancialReport from "./FinancialReport";

export default function FinanceReport({ modal, project_id, project }) {
  function HandleModal() {
    modal(true);
  }
  const { data: forexNeedData } =
    useGetPaymentTrackingForProjectReportQuery(project_id);
  
  const { data: forexNeedDatas } = useGetForexNeedsQuery();

  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(forexNeedData?.data?.length / PER_PAGE);
  const _DATA = usePagination(forexNeedData?.data, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  
  return (
    <>
      <div className="report-sourcing-container">
        <div className="d-flex justify-content-end align-items-center">
          {project_id != null ? (
            <DownloadReport project_id={project_id} project={project} />
          ) : (
            ""
          )}
          {project_id != null ? (
            <FinancialReport project_id={project_id} project={project} />
          ) : (
            ""
          )}{" "}
        </div>
        {forexNeedData?.data?.length != 0 ? (
          <div className="d-grid gap-2 mb-4">
            <div className="report-sourcing-table-header">
              <div className="finance-table-headers">
                <div className="">Order Name</div>
                <div className="">Supplier</div>
                <div>Payment Mode</div>
                <div>Total Amount</div>
                <div>Advanced Payment</div>
                <div>Paid Amount</div>
                <div>Balance Amount</div>
                <div>Invoice Data</div>
                <div>Due Data</div>
              </div>
            </div>
            {_DATA?.currentData()?.map((items) => (
              <div className="report-sourcing-table-header">
                <div className="finance-table-headers">
                  <Tooltip title={items?.order_name}>
                    <div>
                      {items?.order_name?.length >= 50
                        ? items?.order_name.slice(0, 50) + "..."
                        : items?.order_name}
                    </div>
                  </Tooltip>

                  <Tooltip title={items?.distributor_name}>
                    <div>
                      {items?.distributor_name?.length >= 30
                        ? items?.distributor_name.slice(0, 30) + "..."
                        : items?.distributor_name}
                    </div>
                  </Tooltip>
                  <div>{items?.payment_mode}</div>
                  <div>{items?.total_amount?.toLocaleString()}</div>
                  <div>{items?.advance_percentage}%</div>
                  <div>{items?.paid_amount}</div>
                  <div>
                    {(
                      items?.total_amount - items?.paid_amount
                    ).toLocaleString()}
                  </div>
                  <div>
                    {items?.receipt_date == null ? "NA" : items?.receipt_date}
                  </div>
                  <div>{items?.due_date == null ? "NA" : items?.due_date}</div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          ""
        )}
        {count > 1 ? (
          <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            className="p-2"
          />
        ) : (
          ""
        )}
      </div>
      <div className="gap-analysis-web-view">
        <ForexNeed isfinance={true} data={forexNeedDatas} />
      </div>
    </>
  );
}
