import { Tooltip } from "chart.js";
import React, { useState } from "react";
import DispatchedModal from "./DispatchedModal";
import ReturnedModal from "./ReturnedModal";

export default function ReturnedTools() {
  const [dialog, setDialog] = useState(false);
  function HandleDialog() {
    setDialog(true);
  }


  return (
    // <Tooltip title="Dispatched Report">
    <div className="d-flex justify-content-center">
      <button onClick={HandleDialog} className="add-client-representative">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14V11H2V14H14V11H16V14C16 14.55 15.8043 15.021 15.413 15.413C15.021 15.8043 14.55 16 14 16H2ZM8 12L3 7L4.4 5.55L7 8.15V0H9V8.15L11.6 5.55L13 7L8 12Z"
            fill="white"
          />
        </svg>
      </button>
      {dialog ? <ReturnedModal setDialog={setDialog} return={true} /> : ""}
    </div>

    // </Tooltip>
  );
}
