import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const BankBalance = styled(Paper)(({ theme }) => ({
  elevation: "0px",
  height: "90px",
  width: "362px",
  [theme.breakpoints.between("md", "lg")]: {
    marginLeft: "35px",
    marginRight: "-45px",
  },
  [theme.breakpoints.between("xs", "sm", "md")]: {
    marginTop: "0px",
  },
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  border: "1px solid #AEB8C9",
  borderRadius: "6px",
}));

const BankBalanceContainer = (props) => {

  return (
    <>
      <BankBalance elevation={0}>
        <div className="gap-analysis-mobile-view-detail-card">
          <ul className="paymentTermAndConditionsLabel">
            <li>
              <span className="mobile-view-label">Bank Name</span>
            </li>
            <li>
              <span className="mobile-view-label">Amount</span>
            </li>
          </ul>

          <ul className="paymentTermAndConditionsValue">
            <li>
              <span className="paymentTcValue">
                {props?.data?.bank_balance?.bank_name}
              </span>
            </li>
            <li>
              <span className="paymentTcValue">
                {Number(props?.data?.amount)?.toLocaleString()}
              </span>
            </li>
          </ul>
        </div>
      </BankBalance>
    </>
  );
};

export default BankBalanceContainer;
