import React from "react";
import ForexSideBar from "../../../components/Payment/ForexApproval/PaymentSideBar";
import ForexPlaningNav from "../../../components/Payment/ForexPlanning/ForexPlannigNav";

const ForexApprovalIndex = () => {
  return (
    <ForexSideBar>
      <ForexPlaningNav />
    </ForexSideBar>
  );
};

export default ForexApprovalIndex;
