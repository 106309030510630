import React, { useState } from "react";
import { Breadcrumb, Dropdown, Tab, Tabs } from "react-bootstrap";
import NavBar from "../../Layout/NavBar";
import Request from "../../components/Stock/Request/PurchasedRequest";
import "./stock.style.css";
import PurchaseRequest from "../../components/Stock/Request/PurchasedRequest";
import InStoreItems from "../../components/Stock/Request/InStoreItems";
import Inventorys from "../../components/Stock/Inventory";
import Tools from "../../components/Stock/Tool";
import Projects from "../../components/Stock/Project";
import OnUse from "../../components/Stock/Items/OnUse";
import History from "../../components/Stock/Items/History";
import Incoming from "../../components/Stock/Incoming";
import { Flag } from "@mui/icons-material";
import SuccessMessage from "../../components/common/SuccessMessage";
import CloseIcon from "@mui/icons-material/Close";

import { Alert, Box, Collapse, IconButton } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
export default function StockIndex(props) {
  const [isRequestTabs, setRequestTabs] = useState(0);
  const [isRequest, setRequest] = useState(false);
  const [isItem, setItem] = useState(false);
  function HandleRequest(key) {
    if (key === "request") {
      setRequest(true);
      setItem(false);
    } else if (key === "item") {
      setRequest(false);
      setItem(true);
    } else {
      setRequest(false);
      setItem(false);
    }
  }
  function HandleOnUse() {
    setRequestTabs(0);
    setRequest(false);
    setItem(false);
  }
  function HandleHistory() {
    setItem(false);
    setRequestTabs(1);
    setRequest(false);
  }
  // setTimeout(() => {
  //   setRequest(false);
  //   setItem(false);
  // }, 5000);
  const [open, setOpen] = useState(true);

  const [Message, setMessage] = React.useState(null);
  const location = useLocation();

  return (
    <>
      <NavBar
        placeholder={props?.placeholder}
        handleSearchChange={props?.handleSearchChange}
      />
      <div className="all-breadcrumb">
        <Breadcrumb className="ms-3">
          <Breadcrumb.Item className="prg" href="/stocks">
            Stock
          </Breadcrumb.Item>

          {props.name == undefined ? (
            ""
          ) : (
            <Breadcrumb.Item className="prg-pri" active>
              {props.name}
            </Breadcrumb.Item>
          )}
        </Breadcrumb>
      </div>
      <div className="page">
        {Message !== null ? (
          <Box sx={{ width: "100%", color: "#FFF" }}>
            <Collapse in={open}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{
                  mb: 2,
                  backgroundColor: "#52FFAB96",
                  color: "#000",
                  "& .MuiAlert-icon": {
                    fontSize: 25,
                    color: "#186569",
                  },
                }}
              >
                {props?.Message}
              </Alert>
            </Collapse>
          </Box>
        ) : (
          ""
        )}
        <div className="Tab-stock">
          <NavLink
            className={
              location.pathname == "/stocks"
                ? "stock-nav-link"
                : "stock-nav-link-index"
            }
            to="/stocks"
          >
            Items
          </NavLink>
          <NavLink
            className="stock-nav-link"
            to="/stocks/tools"
            activeClassName="active"
          >
            Tools
          </NavLink>
          <NavLink
            className="stock-nav-link"
            to="/stocks/assets"
            activeClassName="active"
          >
            Assets
          </NavLink>
          <NavLink
            className="stock-nav-link"
            to="/stocks/projects"
            activeClassName="active"
          >
            Project
          </NavLink>
          {/* <NavLink
            className="stock-nav-link"
            to="/stocks/requests"
            activeClassName="active"
          >
            Request
          </NavLink> */}
          {/* <div class="dropdown show">
            <NavLink
              className="stock-nav-link  dropdown-toggle"
              to="/stocks/requests"
              role="button"
              activeClassName="active"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Dropdown link
            </NavLink>

            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <a class="dropdown-item" href="#">
                Action
              </a>
              <a class="dropdown-item" href="#">
                Another action
              </a>
              <a class="dropdown-item" href="#">
                Something else here
              </a>
            </div>
          </div> */}
          <UncontrolledDropdown className="me-2">
            <DropdownToggle>
              <NavLink
                activeClassName="active"
                className="stock-nav-link"
                style={{ width: "150px" }}
                to="/stocks/requests"
              >
                Request
              </NavLink>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem header>
                <NavLink className="" to="/stocks/requests">
                  InStore Items
                </NavLink>
              </DropdownItem>
              <DropdownItem header>
                <NavLink className="" to="/stocks/requests/purchase">
                  Purchase Order
                </NavLink>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown className="me-2">
            <DropdownToggle>
              <NavLink
                activeClassName="active"
                className="stock-nav-link"
                style={{ width: "150px" }}
                to="/stocks/onuse"
              >
                On Use
              </NavLink>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem header>
                <NavLink className="" to="/stocks/onuse">
                  On Use
                </NavLink>
              </DropdownItem>
              <DropdownItem header>
                <NavLink className="" to="/stocks/onuse/history">
                  History
                </NavLink>
              </DropdownItem>
              <DropdownItem header>
                <NavLink className="" to="/stocks/onuse/transaction">
                  Transaction
                </NavLink>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>

          {/* <NavLink
            className="stock-nav-link"
            to="/stocks/incoming"
            activeClassName="active"
          >
            Incoming
          </NavLink> */}
          <NavLink
            className="stock-nav-link"
            to="/stocks/GRN"
            activeClassName="active"
          >
            GRN report
          </NavLink>
        </div>
        {props?.children}
      </div>
    </>
  );
}
