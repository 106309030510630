import React from "react";
import { Workbook } from "exceljs";
import saveAs from "file-saver";

const EmployeeReceivableReport = ({ data }) => {
  let title = "IE NETWORKS SOLUTIONS PLC";
  let title2 = "Employee Receivable";
  let title3 = "Report";
  let count = 0;

  const downloadExcel = async () => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet();

    worksheet.mergeCells("A1:E1");
    worksheet.mergeCells("A2:E2");
    worksheet.mergeCells("A3:E3");
    const titleCell = worksheet.getCell("A1");
    const titleCell2 = worksheet.getCell("A2");
    const titleCell3 = worksheet.getCell("A3");

    titleCell.value = title;
    titleCell.alignment = { horizontal: "center", vertical: "middle" };
    titleCell2.value = title2;
    titleCell2.alignment = { horizontal: "center", vertical: "middle" };
    titleCell3.value = title3;
    titleCell3.alignment = { horizontal: "center", vertical: "middle" };

    titleCell3.font = {
      name: "Times New Roman",
    };
    titleCell3.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "8EA9DB" },
    };
    titleCell2.font = {
      name: "Times New Roman",
    };
    titleCell2.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "8EA9DB" },
    };
    titleCell.font = {
      name: "Times New Roman",
    };

    titleCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "8EA9DB" },
    };

    // Create the table headers
    const headerRow = worksheet.addRow([
      "No",
      "Employee Name",
      "Car Maintenance",
      "PMA",
      "Total",
    ]);
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "BFBFBF" },
      };
      cell.font = {
        color: { argb: "000000" },
        bold: true,
        size: 12,
        name: "Times New Roman",
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });

    // Set column widths
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 40;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 15;

    worksheet.getRow(2).font = { bold: true };
    worksheet.getRow(4);

    let totalCarMaintenance = 0;
    let totalPMA = 0;
    let totalAmount = 0;

    // Populate the table with data from the map
    data?.forEach((items) => {
      const carMaintenanceValue = parseFloat(items?.car_maintenance) || 0;
      const pmaValue = parseFloat(items?.pma) || 0;
      const totalValue = parseFloat(items?.total) || 0;

      totalCarMaintenance += carMaintenanceValue;
      totalPMA += pmaValue;
      totalAmount += totalValue;

      const dataRow = worksheet.addRow([
        (count += 1),
        items?.employee_name,
        carMaintenanceValue,
        pmaValue,
        totalValue,
      ]);
      dataRow.eachCell((cell, colNumber) => {
        cell.border = {
          top: { style: "thin", color: { argb: "000000" } },
          left: { style: "thin", color: { argb: "000000" } },
          bottom: { style: "thin", color: { argb: "000000" } },
          right: { style: "thin", color: { argb: "000000" } },
        };

        if (colNumber > 2 && !isNaN(cell.value)) {
          cell.numFmt = "#,##0";
        }
      });
    });

    const totalRow = worksheet.addRow([
      "Total",
      "",
      totalCarMaintenance,
      totalPMA,
      totalAmount,
    ]);

    totalRow.eachCell((cell, colNumber) => {
      cell.font = {
        bold: true,
        name: "Times New Roman",
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };

      if (colNumber > 2 && !isNaN(cell.value)) {
        cell.numFmt = "#,##0";
      }
    });

    // Save the workbook and download the Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "Employee Receivable Report");
  };

  return (
    <button
      onClick={downloadExcel}
      style={{
        padding: "10px 20px",
      }}
      className="create-project-btn"
    >
      Export{" "}
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14V11H2V14H14V11H16V14C16 14.55 15.8043 15.021 15.413 15.413C15.021 15.8043 14.55 16 14 16H2ZM8 12L3 7L4.4 5.55L7 8.15V0H9V8.15L11.6 5.55L13 7L8 12Z"
          fill="white"
        />
      </svg>
    </button>
  );
};

export default EmployeeReceivableReport;
