import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { styled } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import SuccessMessage from "../../SuccessMessage";
import {
  useGetProjectsQuery,
  useGetUserInfoQuery,
  useViewSupplierCreditHistoryQuery,
} from "../../../../features/SCMApi";
import CheckPermission from "../../../common/checkPermission/checkPermission";
import Select from "../../ForexApproval/Select";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { Tooltip } from "chart.js";
// import EditSupplierPaymentButton from "./Modal/EditSupplierPaymentButton";
import DebitHistoryDetailButton from "./SupplierCreditHistory";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AddSupplierCredit from "../Modal/AddSupplierCredit";
import AddSupplierCreditButton from "../Modal/AddCreditContainer";
import PaymentsIcon from "@mui/icons-material/Payments";
import SupplierCreditPayment from "../Modal/SupplierCreditPayment";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import EditSupplierCredit from "../Modal/EditSupplierCredit";
import SupplierCreditReport from "../DownloadReport/SupplierCreditReport";
const TCell = styled(TableCell)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "10.5px",
  lineHeight: "12px",
  color: "#353945",
  width: "45px",
  padding: "0 0 0 5px",
  // textAlign: "left",
}));
const DayDisplay = styled("div")(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "12px",
  lineHeight: "12px",
  color: "#353945",
  // width: "50px",
  // padding: "0 0 0 5px",
  // // textAlign: "left",
}));
const TCellForOrder = styled(TableCell)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "10.5px",
  lineHeight: "12px",
  color: "#353945",
  width: "150px",
  padding: "0 5px 0 0",
}));

const TCellHeader = styled(TableCell)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "11px",
  lineHeight: "12px",
  color: "#353945",
  padding: "1px",
}));
const GrandTotal = styled(TableCell)(({ theme }) => ({
  display: "flex",
  justifyContent: "end",
  borderBottom: "#fff",
}));

const Pagination = styled(TablePagination)(({ theme }) => ({
  "& .css-pdct74-MuiTablePagination-selectLabel": {
    display: "none",
  },
  "& .css-levciy-MuiTablePagination-displayedRows": {
    marginTop: "19px",
  },
  height: "55px",
  marginBottom: "-25px",
  ".MuiInputBase-root": {
    marginTop: "4.5px",
    marginRight: "10px",
  },
  "& .MuiTablePagination-actions": {
    marginTop: "2px",
    marginLeft: "8px",
  },
}));

export default function EnhancedTable(props) {
  const [successMessage, setSuccessMessage] = React.useState(false);
  const [message, setMessage] = React.useState("");
  successMessage &&
    setTimeout(() => {
      setSuccessMessage(false);
    }, 3000);
  const rows = props?.trackingSheet !== undefined ? props.trackingSheet : [];

  const { data: userinfo } = useGetUserInfoQuery();


  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("no");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [project_id, setProject] = React.useState("");
  const { data: projects } = useGetProjectsQuery();

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const headCells = [
    {
      id: "no",
      numeric: true,
      disablePadding: true,
      label: "\xa0\xa0\xa0\xa0#",
    },
    {
      id: "poNumber",
      numeric: true,
      disablePadding: false,
      label: "PO NUMBER",
    },
    {
      id: "project",
      numeric: true,
      disablePadding: false,
      label: "PROJECT",
    },
    {
      id: "creditAmount",
      numeric: false,
      disablePadding: false,
      label: "PAYMENT MODE",
    },
    {
      id: "creditAmount",
      numeric: false,
      disablePadding: false,
      label: "CREDIT AMOUNT",
    },

    {
      id: "creditAmount",
      numeric: false,
      disablePadding: false,
      label: "PAID AMOUNT",
    },
    {
      id: "creditAmount",
      numeric: false,
      disablePadding: false,
      label: "REMAINING CREDIT AMOUNT",
    },
    {
      id: "currency",
      numeric: true,
      disablePadding: false,
      label: "CURRENCY",
    },
    {
      id: "description",
      numeric: true,
      disablePadding: false,
      label: "DESCRIPTION",
    },
  ];

  function EnhancedTableHead(props) {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead
        className="paymentTableHeader"
        sx={{
          color: "#353945",
        }}
      >
        <TableRow>
          {headCells.map((headCell) => (
            <TCellHeader
              key={headCell.id}
              align={headCell.numeric ? "left" : "left"}
              padding="none"
              sortDirection={orderBy === headCell.id ? order : false}
              height="50px"
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TCellHeader>
          ))}
          <TCellHeader></TCellHeader>
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows?.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  let counter = 0;
  const currentDate = new Date();


  const [filter, setFilter] = React.useState(false);
  function HandleFilter() {
    setFilter(!filter);
    setProject("");
  }
  const [payment, setPayment] = React.useState(false);
  const [updateCredit, setUpdateCredit] = React.useState(false);
  const [paymentdata, setPaymentData] = React.useState(null);
  function HandleSupplierCreditPayment(e, row) {
    setPayment(true);
    setPaymentData(row);
  }
  function HandleUpdateCreditPayment(e, row) {
    setUpdateCredit(true);
    setPaymentData(row);
  }
  const [credit_id, setCredit] = React.useState(null);


  return (
    <>
      <div className="d-flex gap-3 align-items-center">
        {filter ? (
          <FilterAltIcon
            onClick={HandleFilter}
            style={{ fontSize: "32px", color: "#064b4f" }}
          />
        ) : (
          <FilterAltOffIcon
            onClick={HandleFilter}
            style={{ fontSize: "32px", color: "#064b4f" }}
          />
        )}
        {filter ? (
          <select
            className="report-filter-selector border"
            onChange={(e) => setProject(e.target.value)}
          >
            <option value="" selected>
              Project
            </option>
            {projects?.data?.map((items) => (
              <option value={items?.id}>{items?.project_name}</option>
            ))}
          </select>
        ) : (
          ""
        )}
      </div>

      <Table
        sx={{ width: "103.2%", marginLeft: "-15px", marginBottom: "-5px" }}
        aria-labelledby="tableTitle"
        size="small"
      >
        <>
          {successMessage === true ? (
            <>
              <SuccessMessage
                style={{
                  marginTop: "5px",
                  marginLeft: "10px",
                  backgroundColor: "#52ffab96",
                  width: "362%",
                }}
                Message={message}
              />
            </>
          ) : (
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
            />
          )}
        </>

        <TableBody>
          {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
          {stableSort(rows, getComparator(order, orderBy))
            ?.filter((items) => {
              if (project_id == "") {
                return rows;
              } else if (items?.project_id == project_id) {
                return rows;
              }
            })
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              const isItemSelected = isSelected(row);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <>
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row}
                    selected={isItemSelected}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      align="left"
                      sx={{
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "10.5px",
                        lineHeight: "12px",
                        color: "#353945",
                      }}
                    >
                      {(counter += 1)}
                    </TableCell>
                    <TCell>{row.order_name}</TCell>
                    <TableCell
                      sx={{
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "10.5px",
                        lineHeight: "12px",
                        color: "#353945",
                      }}
                    >
                      {row.project_name}
                    </TableCell>

                    <TableCell
                      sx={{
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "10.5px",
                        lineHeight: "12px",
                        color: "#353945",
                      }}
                    >
                      {row?.payment_mode == null ? "-" : row?.payment_mode}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "10.5px",
                        lineHeight: "12px",
                        color: "#353945",
                      }}
                    >
                      {Number(row.credit_amount)?.toLocaleString()}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "10.5px",
                        lineHeight: "12px",
                        color: "#353945",
                      }}
                    >
                      {Number(row.paid_amount)?.toLocaleString()}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "10.5px",
                        lineHeight: "12px",
                        color: "#353945",
                      }}
                    >
                      {Number(
                        row.credit_amount - row.paid_amount
                      )?.toLocaleString()}
                    </TableCell>

                    <TableCell
                      sx={{
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "10.5px",
                        lineHeight: "12px",
                        color: "#353945",
                      }}
                    >
                      {row?.currency_name}
                    </TableCell>
                    <TCell>{row?.description}</TCell>

                    <TableCell align="right" padding="0px">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <SupplierCreditReport
                          data={row}
                          setCredit={setCredit}
                          credit_id={credit_id}
                        />
                        <IconButton
                          variant="text"
                          size="small"
                          disabled={
                            Number(row?.credit_amount - row?.paid_amount) == 0
                              ? true
                              : false
                          }
                          sx={{
                            backgroundColor: "#eff2f3",
                            color: "#064B4F",
                            "&:hover": {
                              backgroundColor: "#186569",
                              color: "#fff",
                            },
                          }}
                          onClick={(e) => HandleSupplierCreditPayment(e, row)}
                        >
                          <PaymentsIcon sx={{ fontSize: "14px" }} />
                        </IconButton>

                        <DebitHistoryDetailButton
                          editData={row}
                          disabled={
                            CheckPermission("view_payment_history", userinfo)
                              ? false
                              : true
                          }
                        />
                        <IconButton
                          variant="text"
                          size="small"
                          // disabled={
                          //   Number(row?.credit_amount - row?.paid_amount) == 0
                          //     ? true
                          //     : false
                          // }
                          sx={{
                            backgroundColor: "#eff2f3",
                            color: "#064B4F",
                            "&:hover": {
                              backgroundColor: "#186569",
                              color: "#fff",
                            },
                          }}
                          onClick={(e) => HandleUpdateCreditPayment(e, row)}
                        >
                          <ModeEditOutlineIcon sx={{ fontSize: "14px" }} />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                </>
              );
            })}

          <TableRow>
            <TableCell colSpan={12}>
              <div className="d-flex justify-content-between align-items-center">
                <button className="form-save">Export</button>
                {/* <IconButton
                  // size="small"
                  sx={{
                    width: "35px",
                    height: "35px",
                    backgroundColor: "#064b4f",
                    color: "#ececec",
                    "&:hover": {
                      backgroundColor: "#186569",
                      color: "#ececec",
                    },
                  }}
                >
                  <AddIcon style={{ fontSize: 18 }} />
                </IconButton> */}
                <AddSupplierCreditButton
                  name="paymentTracking"
                  editData={[]}
                  supplier_id={props?.supplier_id}
                  isSupplier={true}
                  message={setMessage}
                  successMessage={setSuccessMessage}
                  totalPayment={props?.totalPayment}
                />
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Pagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {payment ? (
        <SupplierCreditPayment
          name="paymentTracking"
          data={paymentdata}
          modal={setPayment}
          supplier_id={props?.supplier_id}
          isSupplier={true}
          message={setMessage}
          successMessage={setSuccessMessage}
          totalPayment={props?.totalPayment}
        />
      ) : (
        ""
      )}
      {updateCredit ? (
        <EditSupplierCredit
          name="paymentTracking"
          data={paymentdata}
          modal={setUpdateCredit}
          supplier_id={props?.supplier_id}
          isSupplier={true}
          message={setMessage}
          successMessage={setSuccessMessage}
          totalPayment={props?.totalPayment}
        />
      ) : (
        ""
      )}
    </>
  );
}
