import React from "react";
import WestIcon from "@mui/icons-material/West";
import { IMG_BASE_URL } from "../../../../api/endPoint";
import { IconButton, Tooltip } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { NavLink, useParams, useLocation } from "react-router-dom";
import { API_BASE_URL } from "../../../../api/endPoint";
import {
  useApprovalApproveMutation,
  useApprovalRejectMutation,
  useGetToolRequestedQuery,
  useGetToolsRequestsQuery,
  useGetUserInfoQuery,
  useGetApproverQuery,
  useInStoreItemsRequestMutation,
  useItemsAcceptMutation,
  useItemsInventoryConfirmMutation,
  useItemsInventoryAcceptMutation,
  useGetInventoryRequestedQuery,
  useItemsAssetsConfirmMutation,
  useAssetAcceptMutation,
} from "../../../../features/SCMApi";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import StockDialog from "../../../common/StockDialog";
import InventoryApprovalModal from "../../../common/InventoryApprovalModal";
import InventoryApprovalRejectModal from "../../../common/InventoryApprovalRejectModal";

export default function RequestAssetsItemDescription(props) {
  const [confirm, setConfirm] = useState(false);
  const [quantity, setQuantity] = useState(null);
  const [Accept, setAccept] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [remark, setRemark] = useState(null);
  const [error, setError] = useState(null);
  const BearerToken = localStorage.getItem("accessToken");
  let location = useLocation();
  const state = location.state;
  
  
  function HandleRequest() {
    setConfirm(true);
  }
  function HandleAccept() {
    setAccept(true);
  }
  const [ItemConfirm, src] = useItemsAssetsConfirmMutation();
  const [ItemAccept, acce] = useAssetAcceptMutation();

  //Inventory approval initiated
  const [approverData, setApproverData] = useState({});
  const [approveConfirm, setApproveConfirm] = useState(false);
  const [approvalRejected, setApprovalRejected] = useState(false);
  const [hideApprovalButton, setHideApprovalButton] = useState(false);
  const [feedBack, setFeedBack] = useState(null);

  const { data: loggedUser } = useGetUserInfoQuery();
  const [approvalApprove] = useApprovalApproveMutation();
  const [approvalReject] = useApprovalRejectMutation();

  
  let module_name = "";
  if (props?.data?.is_consumable == 1) {
    module_name = "consumableAssets";
  } else if (
    props?.data?.fixed_asset_category_id ==
    "abc87cad-0096-4113-8e50-6c3d583692ec"
  ) {
    module_name = "ItAssets";
  } else {
    module_name = "FixedAssets";
  }
  const handleApprovals = (id) => {
    let dataForApproval = {
      module_name: module_name,
      module_id: id,
      user_id: loggedUser?.id,
      feedback: feedBack,
    };
    approvalApprove(dataForApproval)
      .unwrap()
      .then((response) => {
        
        setApproveConfirm(false);
        setHideApprovalButton(true);
      })
      .then((error) => {
        
      });
    // axios
    //   .post(`${API_BASE_URL}/scm/approval/approve`, dataForApproval)
    //   .then((response) => {
    //     setApproveConfirm(false);
    //     setHideApprovalButton(true);
 
    //   })
    //   .catch((err) =>

    //   );
  };
  const handleDecline = (id) => {
    let dataForApproval = {
      module_name: module_name,
      module_id: id,
      user_id: loggedUser?.id,
      feedback: feedBack,
    };
    approvalReject(dataForApproval)
      .unwrap()
      .then((response) => {

        setApprovalRejected(false);
        setHideApprovalButton(true);
      })
      .then((error) => {

      });

    // axios
    //   .post(`${API_BASE_URL}/scm/approval/reject`, dataForApproval)
    //   .then((response) => {
    //     setApprovalRejected(false);
    //     setHideApprovalButton(true);

    //   })
    //   .catch((err) =>

    //   );
  };
  const params = useParams();
  function HandleAssetRequest(e, items) {
    let requested = {
      id: params?.id,
      pivot_id: items?.id,
      quantity,
      confirmed_by: loggedUser?.id,
    };
    ItemConfirm(requested)
      .unwrap()
      .then((response) => {
        setConfirm(false);

      })
      .catch((error) => {

        setError(error?.data?.message);
      });

    // axios
    //   .post(
    //     `${API_BASE_URL}/scm/stock/inventory/storeKeeperConfirmation/${id}`,
    //     requested,
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //         authorization: "Bearer " + BearerToken,
    //       },
    //     }
    //   )
    //   .then(function (response) {

    //   })
    //   .catch(function (error) {

    //   });
  }
  function HandleToolAccept(e, items) {
    let requested = {
      id: items?.id,
      asset_id: items?.asset_id,
      remark,
      items,
      project_id: items?.requested_project,
      approved_at: items?.returned_date,
      transaction_type: "return",
      inventory_id: items?.tool_id,
      inventory_type: "asset",
      qty: items?.current_return_amount,
      source: items?.requested_project,
      destination: items?.store_id,
      requested_by: items?.requester_id,
      approved_by: loggedUser?.id,
      status: items?.status_name,
    };
    ItemAccept(requested)
      .unwrap()
      .then((response) => {
        setAccept(false);

      })
      .then((error) => {

      });

    //   .post(`${API_BASE_URL}/scm/stock/tools/request/${id}`, {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   })
    //   .then(function (response) {

    //   })
    //   .catch(function (error) {

    //   });
  }
  const { data: userinfo } = useGetUserInfoQuery();
  let SK = userinfo?.role?.role_name == "Store Keeper";
  let FE = userinfo?.role?.role_name == "Field Engineer";
  let PM = userinfo?.role?.role_name == "Project Manager";
  let confirmed_by = userinfo?.id;
  const { data: getApprover } = useGetApproverQuery({
    module_name: module_name,
    id: props?.data?.id,
  });

  

  return (
    <div className="tool-item-description mt-3">
      <div className="d-flex justify-content-between">
      <NavLink
          className="create-project-btn mb-3"
          to={state ? `/stocks/requests/returned/assets` : `/stocks/requests/requested/assets`}
        >
          <WestIcon
            sx={{
              "&:hover": {
                backgroundColor: "#fff",
                color: "#186569",
              },
            }}
          />
          Go Back
        </NavLink>
        {/* <button
          className="form-save"
          onClick={() => {
            HandleRequest();
            setDialog(props?.data);
          }}
        >
          Confirm
        </button>
        <button
          className="form-save"
          onClick={() => {
            HandleAccept();
            setDialog(props?.data);
          }}
        >
          Accept
        </button> */}
      </div>
      <div className="d-flex align-items-center gap-5">
        {props?.data?.image == null ? (
          <img
            className="tool-item-image"
            src="https://flowbite.com/docs/images/examples/image-1@2x.jpg"
            alt={props?.data?.item_name}
          />
        ) : (
          <img
            className="tool-item-image"
            src={`${IMG_BASE_URL}${props?.data?.image}`}
            alt={props?.data?.item_name}
          />
        )}

        <div className="">
          <div className="h4">{props?.data?.item_name}</div>
          <div className="d-flex align-items-baseline gap-1 mt-3 mb-4">
            <div className="tool-item-title">Location:</div>
            <div className="small mt-0">
              {props?.data?.store_name == null
                ? "Unknown"
                : props?.data?.store_name}
            </div>
          </div>
          <div className="d-flex align-items-baseline gap-1 mt-3 mb-4">
            <div className="tool-item-title">Requested Date:</div>
            <div className="small mt-0">
              {props?.data?.requested_date == null
                ? "Unknown"
                : props?.data?.requested_date}
            </div>
          </div>
          <div className="d-flex align-items-baseline gap-1 mt-3 mb-4">
            <div className="tool-item-title">Requested By:</div>
            <div className="small mt-0">
              {props?.data?.requester_name == null
                ? "Unknown"
                : props?.data?.requester_name}
            </div>
          </div>
          <div className="d-flex align-items-baseline gap-1 mt-3 mb-4">
            <div className="tool-item-title">Approved By:</div>
            <div className="small mt-0">
              {props?.data?.confirmed_by == null
                ? "Unknown"
                : props?.data?.confirmed_by}
            </div>
          </div>
          <div className="d-flex align-items-baseline gap-1 mt-3 mb-4">
            <div className="tool-item-title">Approved Date:</div>
            <div className="small mt-0">
              {props?.data?.confirmed_date == null
                ? "Unknown"
                : props?.data?.confirmed_date}
            </div>
          </div>
          <div className="d-flex align-items-baseline gap-1 mt-3 mb-4">
            <div className="tool-item-title">Returned Date:</div>
            <div className="small mt-0">
              {props?.data?.returned_date == null
                ? "Unknown"
                : props?.data?.returned_date}
            </div>
          </div>
          <div className="d-flex align-items-baseline gap-1 mt-3 mb-4">
            <div className="tool-item-title">Project :</div>
            <div className="small mt-0">
              {props?.data?.requested_project_name == null
                ? "Unknown"
                : props?.data?.requested_project_name}
            </div>
          </div>
          <div className="d-flex align-items-baseline gap-1 mt-3 mb-4">
            <div className="tool-item-title">Quantity :</div>
            <div className="small mt-0">
              {props?.data?.requested_quantity == null
                ? "Unknown"
                : props?.data?.requested_quantity}
            </div>
          </div>
          <div className="d-grid  gap-3 mt-3 mb-4">
            <div className="tool-item-title">Description</div>
            <div className="small mt-0">
              {props?.data?.description == null
                ? "-"
                : props?.data?.description}
            </div>
          </div>
          {props?.data?.status_name != null ? (
            <div className="d-flex  gap-1 mt-3 align-items-baseline">
              <div className="tool-item-title">Status</div>
              <div className="small">
                {props?.data?.status_name}
                {props?.data?.description == null
                  ? "-"
                  : props?.data?.description}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="d-flex  gap-1 mt-3 align-items-baseline">
            <div className="tool-item-title">Approval Status</div>
            {getApprover?.data?.current == undefined ? (
              ""
            ) : (
              <div className="tool-item-status">
                {getApprover?.data?.current?.length == 0
                  ? "No need for approval"
                  : getApprover?.data?.current[0]?.is_approved == 0
                    ? `${getApprover?.data?.current[0]?.role_name} approval pending`
                    : getApprover?.data?.current[0]?.is_approved == 2
                      ? "Rejected"
                      : "Approved"}
              </div>
            )}
          </div>
          <td className="text-start" style={{ display: "flex" }}>
            {props?.data?.is_approved == 1 ||
            getApprover?.data?.current?.length === 0 ? (
              true ? (
                props?.data?.is_returned == 0 &&
                props?.data?.is_confirmed == 0 &&
                loggedUser?.id === props?.data?.store_keeper_id ? (
                  <button
                    className="form-save"
                    onClick={() => {
                      HandleRequest();
                      setDialog(props?.data);
                    }}
                  >
                    Confirm
                  </button>
                ) : props?.data?.is_returned == 1 &&
                  props?.data?.is_confirmed == 1 &&
                  props?.data?.current_return_amount > 0 &&
                  loggedUser?.id === props?.data?.store_keeper_id ? (
                  <button
                    className="form-save"
                    onClick={() => {
                      HandleAccept();
                      setDialog(props?.data);
                    }}
                  >
                    Accept
                  </button>
                ) : (
                  ""
                )
              ) : FE ? (
                <div className="d-flex justify-content-start gap-2 align-items-center">
                  {props?.data?.is_returned == 0 ? (
                    <ModeEditOutlineOutlinedIcon
                      // onClick={() => {
                      //   HandleEditToolType(true);
                      //   setupdateToolType(items);
                      // }}
                      sx={{
                        color: "#52FFAC",
                        "&:hover": {
                          backgroundColor: "#fff",
                          color: "#52FFAC",
                          border: "1px solid #52FFAC",
                          borderRadius: 25,
                        },
                      }}
                    />
                  ) : (
                    "waiting for Acceptance"
                  )}
                </div>
              ) : (
                ""
              )
            ) : !hideApprovalButton &&
              getApprover?.data?.current[0]?.is_approved == 0 &&
              getApprover?.data?.current?.length &&
              getApprover?.data?.current?.find(
                (user) => user?.user_id == loggedUser?.id
              ) ? (
              <>
                <Tooltip title="Approve">
                  <IconButton
                    onClick={() => {
                      setApproveConfirm(true);
                      setDialog(props?.data);
                    }}
                  >
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.3775 16.005L17.16 8.2225L15.895 6.985L9.3775 13.5025L6.0775 10.2025L4.84 11.44L9.3775 16.005ZM11 22C9.49667 22 8.07583 21.7113 6.7375 21.1338C5.39917 20.5562 4.23042 19.7679 3.23125 18.7688C2.23208 17.7696 1.44375 16.6008 0.86625 15.2625C0.28875 13.9242 0 12.5033 0 11C0 9.47833 0.28875 8.04833 0.86625 6.71C1.44375 5.37167 2.23208 4.2075 3.23125 3.2175C4.23042 2.2275 5.39917 1.44375 6.7375 0.86625C8.07583 0.28875 9.49667 0 11 0C12.5217 0 13.9517 0.28875 15.29 0.86625C16.6283 1.44375 17.7925 2.2275 18.7825 3.2175C19.7725 4.2075 20.5562 5.37167 21.1338 6.71C21.7113 8.04833 22 9.47833 22 11C22 12.5033 21.7113 13.9242 21.1338 15.2625C20.5562 16.6008 19.7725 17.7696 18.7825 18.7688C17.7925 19.7679 16.6283 20.5562 15.29 21.1338C13.9517 21.7113 12.5217 22 11 22ZM11 20.35C13.6033 20.35 15.8125 19.4379 17.6275 17.6138C19.4425 15.7896 20.35 13.585 20.35 11C20.35 8.39667 19.4425 6.1875 17.6275 4.3725C15.8125 2.5575 13.6033 1.65 11 1.65C8.415 1.65 6.21042 2.5575 4.38625 4.3725C2.56208 6.1875 1.65 8.39667 1.65 11C1.65 13.585 2.56208 15.7896 4.38625 17.6138C6.21042 19.4379 8.415 20.35 11 20.35Z"
                        fill="#52FFAC"
                      />
                    </svg>
                  </IconButton>
                </Tooltip>

                <Tooltip title="Decline">
                  <IconButton
                    onClick={() => {
                      setApprovalRejected(true);
                      setDialog(props?.data);
                    }}
                  >
                    <svg
                      width="23"
                      height="22"
                      viewBox="0 0 23 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.54169 16.28L11.6667 12.155L15.7917 16.28L16.9467 15.125L12.8217 11L16.9467 6.875L15.7917 5.72L11.6667 9.845L7.54169 5.72L6.38669 6.875L10.5117 11L6.38669 15.125L7.54169 16.28ZM11.6667 22C10.1634 22 8.74252 21.7113 7.40419 21.1338C6.06585 20.5562 4.8971 19.7679 3.89794 18.7688C2.89877 17.7696 2.11044 16.6008 1.53294 15.2625C0.955437 13.9242 0.666687 12.5033 0.666687 11C0.666687 9.47833 0.955437 8.04833 1.53294 6.71C2.11044 5.37167 2.89877 4.2075 3.89794 3.2175C4.8971 2.2275 6.06585 1.44375 7.40419 0.86625C8.74252 0.28875 10.1634 0 11.6667 0C13.1884 0 14.6184 0.28875 15.9567 0.86625C17.295 1.44375 18.4592 2.2275 19.4492 3.2175C20.4392 4.2075 21.2229 5.37167 21.8004 6.71C22.3779 8.04833 22.6667 9.47833 22.6667 11C22.6667 12.5033 22.3779 13.9242 21.8004 15.2625C21.2229 16.6008 20.4392 17.7696 19.4492 18.7688C18.4592 19.7679 17.295 20.5562 15.9567 21.1338C14.6184 21.7113 13.1884 22 11.6667 22ZM11.6667 20.35C14.27 20.35 16.4792 19.4379 18.2942 17.6138C20.1092 15.7896 21.0167 13.585 21.0167 11C21.0167 8.39667 20.1092 6.1875 18.2942 4.3725C16.4792 2.5575 14.27 1.65 11.6667 1.65C9.08169 1.65 6.8771 2.5575 5.05294 4.3725C3.22877 6.1875 2.31669 8.39667 2.31669 11C2.31669 13.585 3.22877 15.7896 5.05294 17.6138C6.8771 19.4379 9.08169 20.35 11.6667 20.35Z"
                        fill="#FF5252"
                      />
                      <path
                        d="M7.54169 16.28L11.6667 12.155L15.7917 16.28L16.9467 15.125L12.8217 11L16.9467 6.875L15.7917 5.72L11.6667 9.845L7.54169 5.72L6.38669 6.875L10.5117 11L6.38669 15.125L7.54169 16.28ZM11.6667 22C10.1634 22 8.74252 21.7113 7.40419 21.1338C6.06585 20.5562 4.8971 19.7679 3.89794 18.7688C2.89877 17.7696 2.11044 16.6008 1.53294 15.2625C0.955437 13.9242 0.666687 12.5033 0.666687 11C0.666687 9.47833 0.955437 8.04833 1.53294 6.71C2.11044 5.37167 2.89877 4.2075 3.89794 3.2175C4.8971 2.2275 6.06585 1.44375 7.40419 0.86625C8.74252 0.28875 10.1634 0 11.6667 0C13.1884 0 14.6184 0.28875 15.9567 0.86625C17.295 1.44375 18.4592 2.2275 19.4492 3.2175C20.4392 4.2075 21.2229 5.37167 21.8004 6.71C22.3779 8.04833 22.6667 9.47833 22.6667 11C22.6667 12.5033 22.3779 13.9242 21.8004 15.2625C21.2229 16.6008 20.4392 17.7696 19.4492 18.7688C18.4592 19.7679 17.295 20.5562 15.9567 21.1338C14.6184 21.7113 13.1884 22 11.6667 22ZM11.6667 20.35C14.27 20.35 16.4792 19.4379 18.2942 17.6138C20.1092 15.7896 21.0167 13.585 21.0167 11C21.0167 8.39667 20.1092 6.1875 18.2942 4.3725C16.4792 2.5575 14.27 1.65 11.6667 1.65C9.08169 1.65 6.8771 2.5575 5.05294 4.3725C3.22877 6.1875 2.31669 8.39667 2.31669 11C2.31669 13.585 3.22877 15.7896 5.05294 17.6138C6.8771 19.4379 9.08169 20.35 11.6667 20.35Z"
                        fill="#FF5252"
                      />
                    </svg>
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <></>
            )}
          </td>
        </div>
      </div>
      {approvalRejected ? (
        <InventoryApprovalRejectModal
          setDialog={setApprovalRejected}
          dialog={`${dialog?.item_name}`}
          data={dialog}
          setComment={setFeedBack}
          action={(e) => handleDecline(dialog?.id)}
        />
      ) : (
        <></>
      )}
      {approveConfirm ? (
        <InventoryApprovalModal
          setDialog={setApproveConfirm}
          dialog={dialog?.item_name}
          data={dialog}
          action={(e) => handleApprovals(dialog?.id)}
        />
      ) : (
        <></>
      )}
      {confirm ? (
        <StockDialog
          project_id={1}
          setDialog={setConfirm}
          dialog={`${dialog?.item_name} `}
          data={dialog}
          receive_title={"ho"}
          name="confirm"
          action={(e) => HandleAssetRequest(e, dialog)}
          setQuantity={setQuantity}
          error={error}
        />
      ) : (
        ""
      )}
      {Accept ? (
        <StockDialog
          project_id={1}
          setDialog={setAccept}
          dialog={`${dialog?.item_name} `}
          data={dialog}
          name="confirm"
          receive_title={"To Accept"}
          setRemark={setRemark}
          action={(e) => HandleToolAccept(e, dialog)}
        />
      ) : (
        ""
      )}
    </div>
  );
}
