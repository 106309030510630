import React, { useEffect } from "react";
import { useState } from "react";
import {
  useAssetReturnMutation,
  useAssetsReceiveMutation,
  useGetAssetsRequestsQuery,
  useGetItemCategorysQuery,
  useGetStoreQuery,
  useGetToolsQuery,
  useGetToolsRequestsQuery,
  useGetUserInfoQuery,
  useItemsRequestMutation,
  useItemsReturnMutation,
} from "../../../features/SCMApi";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { IconButton, Pagination } from "@mui/material";
import StockDialog from "../../common/StockDialog";
import NoRecord from "../../common/NoRecord";
import { IMG_BASE_URL } from "../../../api/endPoint";
import usePagination from "../../Pagination/Pagination";
import { NavLink, useLocation } from "react-router-dom";
import BatchItems from "../../../assets/SVG/Batchitems.png";

export default function OnUseAssets() {
  const { data: RequestedAssets } = useGetAssetsRequestsQuery();

  const { data: loggedUser } = useGetUserInfoQuery();

  let notRequest = [];
  RequestedAssets?.data?.map((items) =>
    items?.is_requested == 1 &&
    items?.is_confirmed == 1 &&
    items?.requester_id == loggedUser?.id
      ? notRequest.push(items)
      : ""
  );
  const userdata = notRequest?.filter(
    (items) =>
      items?.requester_id == loggedUser?.id ||
      items?.store_keeper_id == loggedUser?.id
  );
  const [search, setSearch] = useState("");
  const [store_id, setStore_id] = useState("");
  const [category, setCategory] = useState("");
  const { data: store } = useGetStoreQuery();
  const { data: ItemCategory } = useGetItemCategorysQuery();
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const filteredData = (search, category, store_id) => {
    return notRequest
      ?.filter((items) => {
        if (search == "") {
          return items;
        } else if (
          items.item_name?.toLowerCase().includes(search.toLowerCase()) ||
          items.model?.toLowerCase().includes(search.toLowerCase()) ||
          items.serial_number?.toLowerCase().includes(search.toLowerCase())
        ) {
          return items;
        }
      })
      ?.filter((items) => {
        if (category === "") {
          return items;
        } else if (items?.requested_date?.substring(0, 10) == category) {
          return items;
        }
      })
      ?.filter((items) => {
        if (store_id === "") {
          return items;
        } else if (items?.store_id == store_id) {
          return items;
        }
      })
      .map((items) => items);
  };
  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pagenum = parseInt(queryParams.get("page"), 10);
  useEffect(() => {
    if (pagenum) {
      setPage(pagenum);
    }
  }, [pagenum]);

  const count = Math.ceil(
    filteredData(search, category, store_id)?.length / PER_PAGE
  );
  const _DATA = usePagination(
    filteredData(search, category, store_id),
    PER_PAGE
  );
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  const [confirm, setConfirm] = useState(false);
  const [confirmReturn, setConfirmReturn] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [returned_quantity, setQuantity] = useState(null);

  function HandleReceive() {
    setConfirm(true);
  }
  function HandleReturn() {
    setConfirmReturn(true);
  }
  const [ItemReceive, src] = useAssetsReceiveMutation();
  const [ItemReturn, retrun] = useAssetReturnMutation();
  const [error, setError] = useState();
  function HandleToolReceive(e, dialog) {
    let requested = {
      id: dialog?.id,
      received_by: userinfo?.id,
      requested_quantity: dialog?.requested_quantity,

      project_id: dialog?.requested_project,
      approved_at: dialog?.confirmed_date,
      transaction_type: "request",
      inventory_id: dialog?.tool_id,
      transaction_id: dialog?.id,
      inventory_type: "asset",
      qty: dialog?.requested_quantity,
      source: dialog?.store_name,
      destination: dialog?.requested_project,
      requested_by: dialog?.requester_id,
      approved_by: dialog?.confirmed_by,
      status: dialog?.status_name,
      remark: dialog?.remark,
    };

    ItemReceive(requested)
      .unwrap()
      .then((response) => {
        setConfirm(false);
      })
      .catch((error) => {
        setError(error?.data?.message);
      });
  }
  function HandleToolReturn(e, items) {
    let requested = {
      id: items?.id,
      tool_id: items?.tool_id,
      returned_quantity,
    };

    ItemReturn(requested)
      .unwrap()
      .then((response) => {
        setConfirmReturn(false);
      })
      .then((error) => {});
  }
  const { data: userinfo } = useGetUserInfoQuery();
  let SK = userinfo?.role?.role_name == "Store Keeper";
  let FE = userinfo?.role?.role_name == "Field Engineer";
  let PM = userinfo?.role?.role_name == "Project Manager";

  return (
    <div>
      <div className="tool-header-container d-flex gap-3 justify-content-center">
        <input
          onChange={handleSearchChange}
          placeholder="search"
          type="search"
          className="inventory-search-input"
        />
        <select
          type="search"
          className="inventory-search-input"
          onChange={(e) => setStore_id(e.target.value)}
        >
          <option value="">Store</option>
          {store?.data?.map((items) => (
            <option value={items?.id}>{items?.store_name}</option>
          ))}
        </select>
        <input
          type="date"
          className="inventory-search-input"
          onChange={(e) => setCategory(e.target.value)}
        />
        {/* <select
          type="search"
          className="inventory-search-input"
          onChange={(e) => setCategory(e.target.value)}
        >
          <option value="">Item Group</option>
          {ItemCategory?.data?.map((items) => (
            <option value={items?.id}>{items?.category}</option>
          ))}
        </select> */}
      </div>
      {_DATA?.currentData()?.length !== 0 ? (
        <div className="finance-table-container">
          <table
            className="table table-striped table-bordered "
            style={{
              width: "auto",
              minWidth: "1500px",
              borderCollapse: "collapse",
            }}
          >
            {" "}
            <thead className="stock-table-header">
              <tr>
                <th style={{ width: "5%" }}></th>
                <th style={{ width: "10%" }}>Item Name</th>
                <th style={{ width: "8%" }}>Model</th>
                <th style={{ width: "8%" }}>Project</th>
                <th style={{ width: "8%" }}>Status</th>
                <th style={{ width: "8%" }}>Store</th>
                <th style={{ width: "8%" }}>Aproved By</th>
                <th style={{ width: "8%" }}>Received Date</th>
                <th style={{ width: "8%" }}>Returned Date</th>
                <th style={{ width: "8%" }}>Action</th>
                <th style={{ width: "10%" }}>Remark</th>
              </tr>
            </thead>
            <tbody>
              {_DATA?.currentData()?.map((tool) => (
                <tr className="stock-table-row">
                  <td className="">
                    {tool?.image != null ? (
                      <img
                        className="stock-img"
                        src={`${IMG_BASE_URL}${tool?.image}`}
                        alt=""
                      />
                    ) : (
                      <img
                        sx={{ width: 50, height: 50, borderRadius: 0 }}
                        src={BatchItems}
                        alt=""
                      />
                    )}
                  </td>
                  <td className="text-start">{tool?.item_name}</td>
                  <td className="text-start">
                    {tool?.model === null ? "-" : tool?.model}
                  </td>
                  <td className="text-start">{tool?.requested_project_name}</td>
                  <td className="text-start">{tool?.status_name}</td>
                  <td className="text-start">{tool?.store_name}</td>
                  <td className="text-start">{tool?.store_keeper_name}</td>
                  <td className="text-start">
                    {tool?.requester_received_date
                      ? tool?.requester_received_date
                      : "-"}
                  </td>
                  <td className="text-start">
                    {tool?.is_returned
                      ? `Returned ${tool?.returned_quantity} ${tool?.uom_name} at ${tool?.returned_date}`
                      : ""}
                  </td>
                  <td className="text-start m-2">
                    {
                      //tool?.returned_quantity == tool?.requested_quantity ? (
                      //""
                      //) :
                      true ? (
                        tool.is_requester_received == 0 &&
                        userinfo?.id == tool?.requester_id ? (
                          tool?.is_batch == 1 ? (
                            <NavLink
                              className="form-save p-2"
                              to={`receivedbatchassets/${tool?.id}`}
                            >
                              View More
                            </NavLink>
                          ) : (
                            <button
                              className="form-save"
                              onClick={() => {
                                HandleReceive();
                                setDialog(tool);
                              }}
                            >
                              Receive
                            </button>
                          )
                        ) : userinfo?.id == tool?.requester_id &&
                          tool?.is_storekeeper_received == 0 ? (
                          tool?.is_batch == 1 ? (
                            <NavLink
                              className="form-save p-2"
                              to={`receivedbatchassets/${tool?.id}`}
                            >
                              View More
                            </NavLink>
                          ) : (
                            <button
                              className="form-save"
                              onClick={() => {
                                HandleReturn();
                                setDialog(tool);
                              }}
                            >
                              Return
                            </button>
                          )
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )
                    }
                  </td>
                  <td className="text-start">
                    {tool?.is_storekeeper_received ? (
                      <>
                        {/* {" "}
                        Received by :{" "}
                        {tool?.store_keeper_name
                          ? tool?.store_keeper_name
                          : "-"}
                        ,
                        <br /> */}
                        Remark : {tool?.remark ? tool?.remark : "no remark"}
                      </>
                    ) : (
                      ""
                    )}
                  </td>{" "}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <NoRecord />
      )}
      {count > 1 ? (
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
          className="p-2"
        />
      ) : (
        ""
      )}
      {confirm ? (
        <StockDialog
          project_id={1}
          setDialog={setConfirm}
          dialog={`${dialog?.item_name} `}
          data={dialog}
          error={error}
          name={"Receive"}
          action={(e) => HandleToolReceive(e, dialog)}
        />
      ) : (
        ""
      )}
      {confirmReturn ? (
        <StockDialog
          project_id={1}
          setDialog={setConfirmReturn}
          dialog={`${dialog?.item_name} `}
          receive_title={"You Received"}
          data={dialog}
          action={(e) => HandleToolReturn(e, dialog)}
          setQuantity={setQuantity}
          is_return={true}
          name="return"
          quantity={returned_quantity}
        />
      ) : (
        ""
      )}
    </div>
  );
}
