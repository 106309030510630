import { Alert, Box, Collapse, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import React from "react";

export default function SuccessMessage(props) {
  return (
    <Box sx={{ width: "100%", color: "#FFF" }}>
      <Collapse in={props?.state}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                props.ToggleModal(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {props?.Message}
        </Alert>
      </Collapse>
    </Box>
  );
}
