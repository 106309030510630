import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import "../forex.style.css";
import SupplierPaymentContainer from "./SupplierPaymentContainer";
import SoaContainer from "./SOA/SoaContainer";
import SupplierCreditContainer from "./SupplierCredit/SupplierCreditContainer";
// import PaymentDataTable from "./PaymentDataTable";
export default function SupplierTrackingNav() {
  const [search, setSearch] = useState("");

  let title1 = "\xa0\xa0\xa0Project\xa0\xa0\xa0";
  let title2 = "\xa0\xa0\xa0Supplier Payment\xa0\xa0\xa0";
  let title3 = "\xa0\xa0\xa0Credit Note\xa0\xa0\xa0";
  let soa = "\xa0\xa0\xa0SOA\xa0\xa0\xa0";
  let sc = "\xa0\xa0\xa0Supplier Credit\xa0\xa0\xa0";

  return (
    <Tabs
      defaultActiveKey="supplier"
      id="uncontrolled-tab-example"
      className="mb-3 d-flex justify-content-center bg-white rounded gap-4 "
    >
      {/* <Tab eventKey="project" title={title1}>
        <PaymentTrackingProject />
      </Tab> */}

      <Tab eventKey="supplier" title={title2}>
        <SupplierPaymentContainer />
      </Tab>
      <Tab eventKey="soa" title={soa}>
        <SoaContainer />
      </Tab>
      <Tab eventKey="sc" title={sc}>
        <SupplierCreditContainer />
      </Tab>
      {/* <Tab eventKey="creditnote" title={title3}>
        <CreditNodeVendor />
      </Tab> */}
    </Tabs>
  );
}
