import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { styled } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import EditPaymentButton from "../ForexPlanning/EditPaymentButton";
import PaymentDetailButton from "./PaymentDetailButton";
import SuccessMessage from "../SuccessMessage";
import { useGetUserInfoQuery } from "../../../features/SCMApi";
import CheckPermission from "../../common/checkPermission/checkPermission";

const TCell = styled(TableCell)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "10.5px",
  lineHeight: "12px",
  color: "#353945",
  width: "45px",
  padding: "0 0 0 5px",
  // textAlign: "left",
}));
const DayDisplay = styled("div")(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "12px",
  lineHeight: "12px",
  color: "#353945",
  // width: "50px",
  // padding: "0 0 0 5px",
  // // textAlign: "left",
}));
const TCellForOrder = styled(TableCell)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "10.5px",
  lineHeight: "12px",
  color: "#353945",
  width: "150px",
  padding: "0 5px 0 0",
}));

const TCellHeader = styled(TableCell)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "11px",
  lineHeight: "12px",
  color: "#353945",
  padding: "1px",
}));
const GrandTotal = styled(TableCell)(({ theme }) => ({
  display: "flex",
  justifyContent: "end",
  borderBottom: "#fff",
}));

const Pagination = styled(TablePagination)(({ theme }) => ({
  "& .css-pdct74-MuiTablePagination-selectLabel": {
    display: "none",
  },
  "& .css-levciy-MuiTablePagination-displayedRows": {
    marginTop: "19px",
  },
  height: "55px",
  marginBottom: "-25px",
  ".MuiInputBase-root": {
    marginTop: "4.5px",
    marginRight: "10px",
  },
  "& .MuiTablePagination-actions": {
    marginTop: "2px",
    marginLeft: "8px",
  },
}));

export default function EnhancedTable(props) {
  const [successMessage, setSuccessMessage] = React.useState(false);
  const [message, setMessage] = React.useState("");
  successMessage &&
    setTimeout(() => {
      setSuccessMessage(false);
    }, 3000);
  const rows = props?.trackingSheet !== undefined ? props.trackingSheet : [];
  const { data: userinfo } = useGetUserInfoQuery();


  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("no");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const headCells = [
    {
      id: "no",
      numeric: true,
      disablePadding: true,
      label: "\xa0\xa0\xa0\xa0#",
    },
    {
      id: "purchaseOrder",
      numeric: true,
      disablePadding: false,
      label: "PURCHASE ORDER",
    },
    {
      id: "projectSupplier",
      numeric: true,
      disablePadding: false,
      label:
        props?.name === "paymentTrackingProject"
          ? "\xa0\xa0SUPPLIER"
          : "PROJECT",
    },
    {
      id: "paymentMode",
      numeric: false,
      disablePadding: false,
      label: "PAYMENT MODE",
    },
    {
      id: "totalPayment",
      numeric: true,
      disablePadding: false,
      label: "TOTAL PAYMENT",
    },
    {
      id: "advancePayment",
      numeric: true,
      disablePadding: false,
      label: "ADVANCE PAYMENT",
    },
    {
      id: "paidAmount",
      numeric: true,
      disablePadding: false,
      label: "PAID AMOUNT",
    },
    {
      id: "balancePayment",
      numeric: true,
      disablePadding: false,
      label: "BALANCE PAYMENT",
    },
    {
      id: "receiptDate",
      numeric: true,
      disablePadding: false,
      label: "INVOICE DATE",
    },
    {
      id: "dueDate",
      numeric: true,
      disablePadding: false,
      label: "\xa0\xa0\xa0DUE DATE",
    },
  ];

  function EnhancedTableHead(props) {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead
        className="paymentTableHeader"
        sx={{
          color: "#353945",
        }}
      >
        <TableRow>
          {headCells.map((headCell) => (
            <TCellHeader
              key={headCell.id}
              align={headCell.numeric ? "left" : "left"}
              padding="none"
              sortDirection={orderBy === headCell.id ? order : false}
              height="50px"
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TCellHeader>
          ))}
          <TCellHeader></TCellHeader>
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows?.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  let counter = 0;
  const currentDate = new Date();


  return (
    <>
      <Table
        sx={{ width: "103.2%", marginLeft: "-15px", marginBottom: "-5px" }}
        aria-labelledby="tableTitle"
        size="small"
      >
        <>
          {successMessage === true ? (
            <>
              <SuccessMessage
                style={{
                  marginTop: "5px",
                  marginLeft: "10px",
                  backgroundColor: "#52ffab96",
                  width: "362%",
                }}
                Message={message}
              />
            </>
          ) : (
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
            />
          )}
        </>

        <TableBody>
          {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
          {stableSort(rows, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              const isItemSelected = isSelected(row);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <>
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row}
                    selected={isItemSelected}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      align="left"
                      sx={{
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "10.5px",
                        lineHeight: "12px",
                        color: "#353945",
                      }}
                    >
                      {(counter += 1)}
                    </TableCell>
                    <TCellForOrder>{row.order_name}</TCellForOrder>
                    <TCell>
                      {props?.name === "paymentTrackingVendor"
                        ? row.project_name
                        : row.distributor_name}
                    </TCell>
                    <TCell
                      sx={{
                        textAlign: "center",
                        paddingRight: "10px",
                        paddingLeft: "0px",
                      }}
                    >
                      {row.payment_mode}
                    </TCell>

                    <TCell>
                      {(
                        Math.round(
                          (Number(row.total_amount) + Number.EPSILON) * 10000
                        ) / 10000
                      ).toLocaleString()}
                    </TCell>
                    <TCell>
                      {Number(row.advance_percentage) !== 0
                        ? `${(
                            Math.round(
                              (Number(row.advance_percentage) +
                                Number.EPSILON) *
                                10000
                            ) / 10000
                          ).toLocaleString()}%`
                        : "NA"}
                    </TCell>
                    <TCell>
                      {(
                        Math.round(
                          (Number(row.paid_amount) + Number.EPSILON) * 10000
                        ) / 10000
                      )?.toLocaleString()}
                    </TCell>
                    <TCell>
                      {(
                        Math.round(
                          (Number(row.total_amount) -
                            Number(row?.paid_amount) +
                            Number.EPSILON) *
                            10000
                        ) / 10000
                      ).toLocaleString()}
                    </TCell>
                    <TCell>
                      {row.receipt_date !== null ? row.receipt_date : "NA"}
                    </TCell>
                    <TCell sx={{ width: "120px" }}>
                      {row.due_date !== null ? row.due_date : "NA"}
                      {row.due_date !== null ? (
                        Math.floor(
                          (new Date(row.due_date) - currentDate) /
                            (1000 * 3600 * 24)
                        )
                          .toString()
                          .includes("-") ? (
                          <DayDisplay sx={{ color: "#FF5252" }}>
                            (
                            {Math.floor(
                              (new Date(row.due_date) - currentDate) /
                                (1000 * 3600 * 24)
                            )
                              .toString()
                              .substring(1)}{" "}
                            days passed)
                          </DayDisplay>
                        ) : Math.floor(
                            (new Date(row.due_date) - currentDate) /
                              (1000 * 3600 * 24)
                          ) <= 15 ? (
                          <DayDisplay sx={{ color: "#FABB06" }}>
                            (In{" "}
                            {Math.floor(
                              (new Date(row.due_date) - currentDate) /
                                (1000 * 3600 * 24)
                            )}{" "}
                            days)
                          </DayDisplay>
                        ) : (
                          <DayDisplay sx={{ color: "#00DD9B" }}>
                            (In{" "}
                            {Math.floor(
                              (new Date(row.due_date) - currentDate) /
                                (1000 * 3600 * 24)
                            )}{" "}
                            days)
                          </DayDisplay>
                        )
                      ) : (
                        <></>
                      )}
                    </TCell>
                    <TableCell align="right" padding="0px">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <EditPaymentButton
                          name="paymentTracking"
                          editData={row}
                          message={setMessage}
                          successMessage={setSuccessMessage}
                          disabled={
                            CheckPermission(
                              "update_payment_status",
                              userinfo
                            ) &&
                            Number(row.paid_amount) != Number(row.total_amount)
                              ? false
                              : true
                          }
                        />
                        <PaymentDetailButton
                          editData={row}
                          disabled={
                            CheckPermission("view_payment_history", userinfo)
                              ? false
                              : true
                          }
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                </>
              );
            })}
          <TableRow>
            <TableCell colSpan={12}>
              <GrandTotal>
                <span className="paymentTrackingProjectTotal">
                  Total payment
                </span>
                <span className="paymentTrackingTotalAmount">
                  {props?.totalPayment?.toLocaleString()}
                </span>
              </GrandTotal>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={12}>
              <GrandTotal>
                <span className="paymentTrackingProjectTotal">
                  Balance payment
                </span>
                <span className="paymentTrackingTotalAmount">
                  {(
                    Number(props?.totalPayment) - Number(props?.paidAmount)
                  )?.toLocaleString()}
                </span>
              </GrandTotal>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={12}>
              <GrandTotal>
                <span className="paymentTrackingProjectTotal">Paid amount</span>
                <span className="paymentTrackingTotalAmount">
                  {Number(props?.paidAmount)?.toLocaleString()}
                </span>
              </GrandTotal>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Pagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
