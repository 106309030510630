import React from "react";
import { useState } from "react";

// import { useInventoryRequestMutation } from "../../../features/SCMApi";

export default function ConfirmDialog(props) {
  return (
    <div
      id="Modal"
      className="dialog-modal "
      onClick={(e) => {
        props?.setDialog(false);
        props?.setters(false);
      }}
    >
      <div
        className="dialog-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="dialog-info">{props?.dialog}</div>
        <div className="confirm-dialog-controller">
          <div
            className="dialog-cancel-btn"
            onClick={(e) => {
              props?.setDialog(false);
              props?.setters(false);
            }}
          >
            No
          </div>
          <button
            type={"submit"}
            onClick={(e) => {
              // props.setDialog(false);
              props.setters(true);
              props.action();
            }}
            className="form-save"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
}
