import Close from "@mui/icons-material/Close";
import React from "react";
import { useState } from "react";
import {
  useAssetsAcceptsBatchMutation,
  useConsumeAproveBatchMutation,
  useGetBatchAssetRequestQuery,
  useGetBatchInventoryRequestQuery,
  useGetBatchToolRequestQuery,
  useGetUserInfoQuery,
  useInventorysAcceptsBatchMutation,
  useToolsAcceptsBatchMutation,
} from "../../../features/SCMApi";
import { IMG_BASE_URL } from "../../../api/endPoint";
import ErrorMessage from "../../Payment/ErrorMessage";
import { useParams } from "react-router-dom";

export default function BatchAccept(props) {
  function HandleCancel() {
    props.modal(false);
  }
  const filter_data = props?.data?.filter((item) => item?.returned_amount > 0);

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(filter_data);

  const { data: userinfo } = useGetUserInfoQuery();

  const params = useParams();

  const { data: BatchItem } = useGetBatchInventoryRequestQuery(
    props?.items?.data?.inventory_user_id
  );
  const { data: BatchTool } = useGetBatchToolRequestQuery(
    props?.items?.data?.tool_user_id
  );
  const { data: BatchAsset } = useGetBatchAssetRequestQuery(
    props?.items?.data?.asset_user_id
  );
  let newTools;
  props?.isAssets
    ? (newTools = BatchAsset?.data == undefined ? "[]" : BatchAsset?.data)
    : props?.is_item == true
      ? (newTools = BatchItem?.data == undefined ? "[]" : BatchItem?.data)
      : (newTools = BatchTool?.data == undefined ? "[]" : BatchTool?.data);

  let inventoryType;
  props?.isAssets
    ? (inventoryType = "asset")
    : props?.is_item == true
      ? (inventoryType = "item")
      : (inventoryType = "tool");

  const newUpdatedArray = formData.map((item) => ({
    ...item,
    confirmed_by: newTools?.confirmed_by,
    created_at: newTools?.created_at,
    transaction_id: newTools?.id,
    inventory_type: inventoryType,
    transaction_type: "return",
    requester_id: newTools?.requester_id,
    requested_project: newTools?.requested_project,
    requested_date: newTools?.requested_date,
    store_name: newTools?.store_name,
  }));

  let batchAccepts = {
    id: params?.id,
    newUpdatedArray,
  };
  const [InventoryAccept, res] = useInventorysAcceptsBatchMutation();
  const [AssetsAccept] = useAssetsAcceptsBatchMutation();
  const [consumeApprove] = useConsumeAproveBatchMutation();
  const [ToolsAccept] = useToolsAcceptsBatchMutation();
  const [errordisplay, setErrorDisplay] = useState(false);
  const [error, setError] = useState();

  const HandleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    props?.is_item == true
      ? InventoryAccept(batchAccepts)
          .unwrap()
          .then((response) => {
            props.modal(false);
            setLoading(true);
          })
          .catch((error) => {
            setError(error?.data?.message);
            setErrorDisplay(true);

            setLoading(false);
          })
      : ToolsAccept(batchAccepts)
          .unwrap()
          .then((response) => {
            props.modal(false);
            setLoading(true);
          })
          .catch((error) => {
            setError(error?.data?.message);
            setErrorDisplay(true);

            setLoading(false);
          });
  };
  const HandleSubmitAssets = async (e) => {
    setLoading(true);
    e.preventDefault();
    AssetsAccept(batchAccepts)
      .unwrap()
      .then((response) => {
        props.modal(false);
        setLoading(true);
      })
      .catch((error) => {
        setError(error?.data?.message);
        setErrorDisplay(true);

        setLoading(false);
      });
  };
  const HandleApproveConsume = async (e) => {
    setLoading(true);
    e.preventDefault();
    consumeApprove(batchAccepts)
      .unwrap()
      .then((response) => {
        props.modal(false);
        setLoading(true);
      })
      .catch((error) => {
        setError(error?.data?.message);
        setErrorDisplay(true);

        setLoading(false);
      });
  };

  let count = 0;

  setTimeout(() => {
    setErrorDisplay(false);
  }, 10000);

  return (
    <div id="mymodal" className="modal" onClick={() => HandleCancel()}>
      <div className="modal-content-md" onClick={(e) => e.stopPropagation()}>
        <div className="create-project-modal-title">
          <h5>
            Batch{props?.is_consumed ? " consume approve" : " Accept For"}
            {props?.isAssets
              ? "Assets"
              : props?.is_item
                ? "Items"
                : "Tools"}{" "}
          </h5>
          <Close onClick={HandleCancel} />
        </div>

        {errordisplay ? <ErrorMessage Message={error} /> : ""}

        <table style={{ width: "100%" }}>
          <thead className="stock-table-header">
            <tr>
              <th
                className="stock-table-header-text text-center"
                style={{ width: "3%" }}
              >
                No
              </th>

              <th
                className="stock-table-header-text text-center"
                style={{ width: "10%" }}
              >
                Image
              </th>

              <th
                className="stock-table-header-text text-center"
                style={{ width: "10%" }}
              >
                Item Name
              </th>

              <th
                className="stock-table-header-text text-center"
                style={{ width: "10%" }}
              >
                Requested Amount
              </th>
            </tr>
          </thead>
          <tbody>
            {formData?.map((items, index) => (
              <tr className="stock-table-row">
                <td className="text-center">{(count += 1)}</td>

                <td className="">
                  <img
                    className="stock-img"
                    src={`${IMG_BASE_URL}${items?.image}`}
                    alt=""
                  />
                </td>
                <td className="text-start">{items?.item_name}</td>
                <td className="text-center">
                  {props?.is_consumed
                    ? items?.current_consum_amount
                    : items?.current_return_amount}
                  {/* {formData[index]?.returned_amount > items?.requested_amount ||
                  formData[index]?.returned_amount <= 0 ? (
                    <span className="prg-error text-danger">
                      Please enter correct value
                    </span>
                  ) : (
                    items?.returned_amount
                  )} */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pi-modal-footer d-flex justify-content-center">
          <button onClick={HandleCancel}>Cancel</button>
          {props?.is_consumed ? (
            <button
              disabled={formData?.length == 0}
              type="submit"
              className={
                formData?.length >= 1
                  ? "form-save "
                  : loading
                    ? "form-save bg-secondary text-white"
                    : "form-save"
              }
              class={
                loading ? "form-save bg-secondary text-white" : "form-save"
              }
              onClick={(e) => HandleApproveConsume(e)}
            >
              {loading ? "Loading..." : "Approve Consume"}
            </button>
          ) : (
            <button
              disabled={formData?.length == 0}
              type="submit"
              className={
                formData?.length >= 1
                  ? "form-save "
                  : loading
                    ? "form-save bg-secondary text-white"
                    : "form-save"
              }
              class={
                loading ? "form-save bg-secondary text-white" : "form-save"
              }
              onClick={(e) =>
                !props?.isAssets ? HandleSubmit(e) : HandleSubmitAssets(e)
              }
            >
              {loading ? "Loading..." : "Accept Return"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
