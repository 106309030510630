import { Tooltip } from "@mui/material";
import React from "react";
import { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useGetProjectDocumentsQuery } from "../../features/SCMApi";
import Documentindex from "../../pages/Documents";
import "./documentCard.style.css";
import Utilities from "./Utilities";

export default function DocumentCard() {
  // const projectDocument = [
  //   {
  //     projectName: "ERA",
  //     numberofdocument: 12,
  //     numberoforder: 6,
  //     isActive: true,
  //   },
  //   {
  //     projectName: "MOTL",
  //     numberofdocument: 1,
  //     numberoforder: 5,
  //     isActive: true,
  //   },
  //   {
  //     projectName: "CSA",
  //     numberofdocument: 10,
  //     numberoforder: 5,
  //     isActive: false,
  //   },
  // ];
  const { data: projectDocument } = useGetProjectDocumentsQuery();
 
  const [search, setSearch] = useState("");

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    
  };
  return (
    <Documentindex
      placeholder={"search Document..."}
      handleSearchChange={handleSearchChange}
    >
      <div className="document-card-container">
        {projectDocument?.data
          ?.filter((items) => {
            if (search === "") {
              return items;
            } else if (items.project_name?.toLowerCase().includes(search)) {
              return items;
            }
          })
          .map((items) => (
            <NavLink to={`document/${items.id}`} sta>
              <div className="document-card">
                <div className="card-content">
                  <div className="document-title">
                    <Tooltip title={items.project_name}>
                      <div className="document-name">
                        {items?.project_name?.length >= 15
                          ? items?.project_name?.slice(0, 10) + "..."
                          : items?.project_name}
                      </div>
                    </Tooltip>
                  </div>
                  <div className="detail">
                    <div className="left-detail">
                      <div className="number">{items?.orders?.length}</div>
                      <div className="type">Document</div>
                    </div>
                    <div className="right-detail">
                      <div className="number">{items?.orders?.length}</div>
                      <div className="type">Order</div>
                    </div>
                  </div>
                </div>
                <div className="divider"></div>
                {items.isActive == 1 ? (
                  <div className="status"> Active</div>
                ) : (
                  <div className="status"> Closed</div>
                )}
              </div>
            </NavLink>
          ))}
      </div>
    </Documentindex>
  );
}
