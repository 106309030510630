import React from "react";
import Grid from "@mui/material/Grid";

const ForexDetailsGridContainer = (props) => {
  return (
    <>
      <Grid
        item
        container
        direction={{
          xs: "row",
          sm: "row",
          md: "column",
          lg: "column",
          xl: "column",
        }}
        xs={12}
        sm={12}
        md={12}
        lg={4.5}
        xl={4.5}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={4.5}
          xl={4.5}
          marginTop={{
            xs: "20px",
            sm: "20px",
            md: "0px",
            lg: "20px",
            xl: "20px",
          }}
          marginRight={{ xs: "16px", sm: "0px", md: "0px" }}
          marginLeft={{ xs: "16px", sm: "0px", md: "0px" }}
        >
          {props.children}
        </Grid>
      </Grid>
    </>
  );
};

export default ForexDetailsGridContainer;
