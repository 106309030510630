import React, { useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../../api/endPoint";
import "../../../../components/Logistics/logistics.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
// import Select from "../../ForexApproval/Select";
import Select from "@mui/material/Select";
import {
  useAddPaySupplierCreditMutation,
  useAddSupplierCreditMutation,
  useGetCurrenciesQuery,
  useGetProjectItemQuery,
  useGetProjectsQuery,
  useGetSuppliersQuery,
  useGetpaymentModeQuery,
  useUpdatePaymentOnPaymentTrackingMutation,
} from "../../../../features/SCMApi";
import "../../forex.style.css";
import DatePicker from "../../ForexPlanning/DatePicker";
import AdvancePaymentCheck from "./AdvancePaidCheckBox";
import { MenuItem, OutlinedInput } from "@mui/material";

export default function SupplierCreditPayment(props) {
  const [PaySupplierCredit] = useAddPaySupplierCreditMutation();

  const [tobe_paid, setPaid] = useState(null);
  const [credit_amount, setCreditAmount] = useState(props?.data?.credit_amount);
  const [po_id, setPo] = useState(props?.data?.po_id);
  const [project_id, setProject] = React.useState(props?.data?.project_id);
  const [currency_id, setCurrency] = React.useState(props?.data?.currency_id);
  const [description, setDescription] = React.useState(null);
  const [supplier_id, setSupplier] = React.useState(props?.data?.supplier_id);
  const [payment_mode_id, setPaymentMode] = React.useState(null);
  const { data: currency } = useGetCurrenciesQuery();
  const { data: project } = useGetProjectsQuery();
  const { data: suppliers } = useGetSuppliersQuery();
  const { data: paymentMode } = useGetpaymentModeQuery();

  const previousData = props.previousData && props.previousData;
  const previousAdvancePercentage = Number(previousData?.advance_percentage);
  const previousAdvancePaid = Number(previousData?.advance_paid);
  const previousTotalAmount = Number(previousData?.credit_amount);
  const previousInvoiceDate = props?.previousData?.receipt_date;
  let params = {
    supplier_id,
    credit_amount,
    credit_id: props?.data?.id,
    po_id,
    project_id,
    tobe_paid,
    currency_id,
    description,
    payment_mode_id,
  };

  function HandleCancel() {
    props.modal(false);
  }

  

  const HandleSubmit = async (e) => {
    e.preventDefault();
    PaySupplierCredit(params)
      .unwrap()
      .then((response) => {
        props?.successMessage(true);
        props?.message("Supplier Credit Paid Successfully!!!");

        HandleCancel();
      })
      .catch((error) => {

      });
    // axios
    //   .post(`${API_BASE_URL}/scm/payment/supplier-credit`, params, {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   })
    //   .then((response) => {

    //     props?.successMessage(true);
    //     props?.message("Supplier Credit  Created Successfully!!!");

    //     HandleCancel();
    //     // props.refreshe();
    //   })
    //   .then((error) => {

    //   });
  };

  const style = {
    width: 260,
    height: 32,
    backgroundColor: "#ececec",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: props?.style?.fontSize ? props?.style?.fontSize : "11px",
    lineHeight: "14px",
    letterSpacing: "0.105em",
    color: "#353945",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiOutlinedInput-input": {
      marginTop: props?.style?.marginTop,
    },
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 0;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: props?.width ? props?.width : 226,
        color: "#186569",
        marginTop: -10,
      },
    },
    disableScrollLock: true,
  };
  const { data: poindex } = useGetProjectItemQuery(project_id);

  let po = poindex?.data?.po
    ?.filter((items) => {
      if (items?.order?.is_offshore == 1) {
        return items;
      }
    })
    .map((items) => items);

  return (
    <>
      <div id="myModal" className="modal" onClick={HandleCancel}>
        <div
          className="mini-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="d-flex align-items-center justify-content-between border border-bottom p-2">
            <div className="h4-pri">Credit Payment</div>
            <div onClick={HandleCancel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="42"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>

          <form className="paymentTrackingForm" onSubmit={HandleSubmit}>
            <div className="form-card-container">
              <div className="form-card-informationPayment">
                <div className="card-center my-5">
                  <div className="card-form">
                    <div className="input-labels">
                      <div className="paymentPlanningInputLabel">
                        Total Credit
                      </div>
                      <div
                        className="paymentPlanningInputLabel"
                        style={{ marginTop: "-10px" }}
                      >
                        Paid Amount
                      </div>

                      <div
                        className="paymentPlanningInputLabel"
                        style={{ marginTop: "-10px" }}
                      >
                        Remaining Amount
                      </div>
                    </div>

                    <div className="payment-input-labels">
                      <div className="paymentPlanningInputFields">
                        ${Number(props?.data?.credit_amount).toLocaleString()}
                      </div>
                      <div
                        className="paymentPlanningInputFields"
                        style={{ marginTop: "-10px" }}
                      >
                        ${Number(props?.data?.paid_amount).toLocaleString()}
                      </div>
                      <div
                        className="paymentPlanningInputFields"
                        style={{ marginTop: "-10px" }}
                      >
                        $
                        {Number(
                          props?.data?.credit_amount - props?.data?.paid_amount
                        ).toLocaleString()}
                      </div>
                    </div>
                  </div>
                </div>
                <>
                  <div className="card-center">
                    <div className="card-form">
                      <div className="supplier-input-labels">
                        {Number(
                          props?.data?.credit_amount - props?.data?.paid_amount
                        ) < Number(tobe_paid) ? (
                          <label className="prg-error text-danger d-flex justify-content-center">
                            Please enter valid Payment Amount
                          </label>
                        ) : (
                          ""
                        )}
                        <div className="d-flex gap-2 align-items-baseline">
                          <div className="supplier-input-label">
                            Payment Amount
                          </div>
                          <input
                            required
                            step="any"
                            type="number"
                            className="forex-select-option"
                            style={{ width: "260px" }}
                            min="0"
                            placeholder={"Amount"}
                            onChange={(event) => setPaid(event.target.value)}
                          />
                        </div>

                        <div className="d-flex gap-2 align-items-baseline">
                          <div className="supplier-input-label">
                            Payment Mode
                          </div>

                          <Select
                            input={<OutlinedInput label="Name" />}
                            MenuProps={MenuProps}
                            sx={style}
                            placeHolder={"Select Payment Mode"}
                            value={payment_mode_id}
                            onChange={(e) => setPaymentMode(e.target.value)}
                          >
                            {paymentMode?.data?.map((items) => (
                              <MenuItem value={items?.id}>
                                {items?.payment_mode}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>

                        <div className="d-flex gap-2 align-items-baseline">
                          <div className="supplier-input-label">
                            Description
                          </div>
                          <textarea
                            type="text"
                            className="sourcing-text-input-textarea"
                            rows={5}
                            cols={50}
                            onChange={(e) => setDescription(e.target.value)}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </div>
              <div className="form-buttons clientForm">
                <button
                  type="cancel"
                  className="form-cancel clientCancel"
                  style={{ width: "112px", height: "32px" }}
                  onClick={HandleCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={
                    Number(
                      props?.data?.credit_amount - props?.data?.paid_amount
                    ) < Number(tobe_paid)
                      ? true
                      : false
                  }
                  className={
                    Number(
                      props?.data?.credit_amount - props?.data?.paid_amount
                    ) < Number(tobe_paid)
                      ? "form-save bg-secondary text-white"
                      : "form-save"
                  }
                  style={{ width: "112px", height: "32px" }}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
