import { Pagination, Tooltip } from "@mui/material";
import React from "react";
import { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { IMG_BASE_URL } from "../../api/endPoint";
import {
  useGetOrderItemQuery,
  useGetProjectItemQuery,
  useGetProjectsQuery,
  useGetSuppliersQuery,
} from "../../features/SCMApi";
import SourcingIndex from "../../pages/Sourcing/SourcingIndex";
import NoRecord from "../common/NoRecord";
import usePagination from "../Pagination/Pagination";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import RecommendIcon from "@mui/icons-material/Recommend";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

export default function PoStage(props) {
  const params = useParams();
  const orderpush = [];
  const { data: suppliers } = useGetSuppliersQuery();

  const { data: poindex } = useGetProjectItemQuery(params.id);
  const orders = poindex?.data?.po?.map((items) =>
    items?.pa_status === 1 ? items : ""
  );
  
  const { data: response } = useGetProjectsQuery();
  const Projectdata = [];
  response?.data?.map((items) => {
    if (params.id === items.id) {
      Projectdata.push(items);
    }
  });
  
  const filteredPo = poindex?.data?.po?.filter(
    (items) => items?.order?.service_type == "Service"
  );
  const filteredPoid = poindex?.data?.po?.filter(
    (items) => items?.order_id == "2dea834f-1f71-49b3-9ad9-41d341603f15"
  );
  
  let avatar = [];
  const [filter, setFilter] = React.useState(false);
  const [order_type, setOrderType] = useState(props?.order_type);
  const [supplier, setSupplier] = useState("");
  
  function HandleFilter() {
    setFilter(!filter);
    setSupplier("");
    // setOrderType("");
  }
  // const [serviceType, setServiceType] = useState(null);
  // setServiceType(props?.serviceType);

  const filteredData = (supplier, order_type) => {
    return (
      poindex?.data?.po
        ?.filter((items) => {
          if (order_type == "") {
            return items;
          } else if (items?.order?.order_type == order_type) {
            return items;
          }
        })
        // if(order_type == "Service")

        ?.filter((items) => {
          if (props.serviceType == "") {
            return items;
          } else if (items?.order?.service_type == props.serviceType) {
            return items;
          }
        })

        ?.filter((items) => {
          if (supplier == "") {
            return items;
          } else if (items?.order?.supplier_id == supplier) {
            return items;
          }
        })
        ?.filter((items) => {
          if (props?.search === "") {
            return items;
          } else if (
            items?.order.order_name
              ?.toLowerCase()
              .includes(props?.search.toLowerCase())
          ) {
            return items;
          }
        })

        .map((items) => items)
    );
  };

  let [page, setPage] = React.useState(1);
  const PER_PAGE = 12;

  const count = Math.ceil(
    filteredData(supplier, order_type)?.length / PER_PAGE
  );
  const _DATA = usePagination(filteredData(supplier, order_type), PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  
  const [approvalHistory, setApprovalHistory] = useState(false);

  return (
    <>
      <div className="d-flex gap-4 ms-1 align-items-center ">
        {filter ? (
          <Tooltip title="Filter On">
            <FilterAltIcon
              onClick={HandleFilter}
              style={{ fontSize: "32px", color: "#064b4f" }}
            />
          </Tooltip>
        ) : (
          <Tooltip title="Filter Off">
            <FilterAltOffIcon
              onClick={HandleFilter}
              style={{ fontSize: "32px", color: "#064b4f" }}
            />
          </Tooltip>
        )}
        {filter ? (
          <>
            <select
              className="select-option-create-order border"
              onChange={(e) => setSupplier(e.target.value)}
            >
              <option value="" selected>
                Supplier
              </option>
              {suppliers?.data?.map((items) => (
                <option value={items?.id}>{items?.distributor_name}</option>
              ))}
            </select>

            {/* <select
              name=""
              id=""
              className="select-option-create-order border"
              onChange={(e) => setOrderType(e.target.value)}
            >
              <option value="" selected>
                Order Type
              </option>
              <option value="Training">Training</option>
              <option value="Service">Service</option>
              <option value="Product">Product</option>
            </select> */}
          </>
        ) : (
          ""
        )}
      </div>

      {_DATA.currentData()?.length !== 0 ? (
        <>
          {/* {order_type == "Service" ? "Service" : "other"} */}
          <div className="order-card-container mt-3">
            {_DATA.currentData()?.map((items) => (
              <NavLink
                to={`/poStageDetail/${items?.order?.id}`}
                state={{
                  name: props.name,
                  names: items?.order_name,
                  ordername: items?.order?.supplier?.distributor_name,
                  members: Projectdata[0]?.project_members,
                }}
                className="order-card"
              >
                <div className="ms-2 mt-2">
                  {items?.po_approval == 0 ? (
                    <Tooltip title={"Pending"}>
                      <PendingActionsIcon sx={{ color: "#ffcc00" }} />
                    </Tooltip>
                  ) : items?.po_approval == 1 ? (
                    <Tooltip title={"Approved"}>
                      <RecommendIcon sx={{ color: "#064b4f" }} />
                    </Tooltip>
                  ) : items?.po_approval == null ? (
                    <Tooltip title={"Waiting to send for approver"}>
                      <HourglassTopIcon sx={{ color: "#064b4f" }} />
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </div>

                <div className="order-info">
                  <div className="order-project">
                    <div>
                      {items?.order?.supplier_id != null ? (
                        <div className="project-name">
                          <Tooltip
                            title={items?.order?.supplier?.distributor_name}
                          >
                            <div className="project-name">
                              {items?.order?.supplier?.distributor_name
                                ?.length >= 20
                                ? items?.order?.supplier?.distributor_name?.slice(
                                    0,
                                    20
                                  ) + "..."
                                : items?.order?.supplier?.distributor_name}
                            </div>
                          </Tooltip>
                        </div>
                      ) : (
                        <div className="project-name">
                          <Tooltip
                            title={items?.order?.bill_to?.distributor_name}
                          >
                            <div className="project-name">
                              {items?.order?.bill_to?.distributor_name
                                ?.length >= 20
                                ? items?.order?.bill_to?.distributor_name?.slice(
                                    0,
                                    20
                                  ) + "..."
                                : items?.order?.bill_to?.distributor_name}
                            </div>
                          </Tooltip>
                        </div>
                      )}
                      <div className="project-label">
                        {items?.order?.supplier_id != null
                          ? "Supplier"
                          : "Bill to"}
                      </div>
                    </div>

                    <div>
                      <div className="project-name">
                        {items?.order?.order_type}
                      </div>
                      <div className="project-label">Order Type</div>
                    </div>
                  </div>
                </div>
                <div className="divider"></div>
                <div className="order-supplier">
                  <Tooltip title={items?.order?.order_name}>
                    <div className="order-supplier">
                      {items?.order?.order_name?.length >= 60
                        ? items?.order?.order_name?.slice(0, 60) + "..."
                        : items?.order?.order_name}
                    </div>
                  </Tooltip>
                </div>
              </NavLink>
            ))}
          </div>
        </>
      ) : (
        <NoRecord />
      )}
      <div className="mt-3">
        {count > 1 ? (
          <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              margin: "5px",
            }}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}
