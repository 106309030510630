import * as React from "react";
import { NavLink } from "react-router-dom";
import NavBar from "../../Layout/NavBar";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Alert, Box, Collapse, IconButton } from "@mui/material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

export default function Index(props) {
  const [open, setOpen] = useState(true);
  return (
    <>
      <NavBar
        placeholder={props?.placeholder}
        handleSearchChange={props.handleSearchChange}
      />
      <div className="">
        <div className="block ">
          <div className="all-breadcrumb">
            <Breadcrumb className="">
              <Breadcrumb.Item className="prg" href="/settings">
                Settings
              </Breadcrumb.Item>

              {props.name === undefined ? (
                ""
              ) : (
                <Breadcrumb.Item className="prg-pri" active>
                  {props.name}
                </Breadcrumb.Item>
              )}
              {props.title === undefined ? (
                ""
              ) : (
                <Breadcrumb.Item className="prg-pri" active>
                  {props.title}
                </Breadcrumb.Item>
              )}
            </Breadcrumb>
          </div>
          <div className="page">
            <div className="">
              {props?.Messages !== null && props?.Messages !== undefined ? (
                <Box sx={{ width: "100%", color: "#FFF" }}>
                  <Collapse in={open}>
                    <Alert
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setOpen(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                      sx={{
                        mb: 2,
                        backgroundColor: "#52FFAB96",
                        color: "#000",
                        "& .MuiAlert-icon": {
                          fontSize: 25,
                          color: "#186569",
                        },
                      }}
                    >
                      {props?.Messages}
                    </Alert>
                  </Collapse>
                </Box>
              ) : (
                ""
              )}
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
