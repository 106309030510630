import React from "react";
import { useState } from "react";
import {
  useClearGuaranteeMutation,
  useRequestPrfMutation,
} from "../../../features/SCMApi";
export default function ClearGuarantee(props) {

  const [clearGuarantee, res] = useClearGuaranteeMutation();
  let clear = {
    id: props?.data?.id,
  };
  const HandleSubmit = async (e) => {
    e.preventDefault();
    clearGuarantee(clear)
      .unwrap()
      .then((response) => {
        
        props.modal(false);
      })
      .then((error) => {
       
      });
  };
  return (
    <div
      id="Modal"
      className="dialog-modal "
      onClick={(e) => {
        props?.modal(false);
        props?.setters(false);
      }}
    >
      <div
        className="stock-dialog-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="dialog-info">Are you sure to Clear</div>
        <div className="prg-pri text-center">
          {props?.data?.order?.order_name}
        </div>

        <div className="confirm-dialog-controller">
          <div
            className="dialog-cancel-btn"
            onClick={(e) => {
              props?.modal(false);
            }}
          >
            Cancel
          </div>
          <button
            type={"submit"}
            onClick={(e) => HandleSubmit(e)}
            className={"form-save "}
          >
            Clear
          </button>
        </div>
      </div>
    </div>
  );
}
