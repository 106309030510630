import { useState } from "react";

export default function AccountNumberModal(props) {
  return (
    <div
      id="Modal"
      className="dialog-modal "
      onClick={(e) => {
        props?.modal(false);
      }}
    >
      <div
        className="stock-dialog-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="dialog-info">Account Number</div>
        <ui className="h5-pri">
          {props?.data?.map((items) => (
            <li>{`${items?.serviceCategoryName}: ${items?.accountNumber}`}</li>
          ))}
        </ui>
        <div className="prg text-danger">
          Please Use This account number as a item part number
        </div>
      </div>
    </div>
  );
}
