import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddItemsCategory from "./SettingModal/AddItemsCategory";
import EditItemsCategory from "./SettingModal/EditItemCategory";
import {
  useDeleteItemCategoryMutation,
  useGetItemCategorysQuery,
} from "../../features/SCMApi";
import DeleteModal from "../Supplier/SupplierModal/DeleteModal";

export default function ItemCategory() {
  const [addItemCategory, setItemCategory] = useState(false);
  const [editItemCategory, setEditItemCategory] = useState(false);
  const [showClientDeleteModal, setShowClientDeleteModal] = useState(false);
  const [itemCategoryToDeleteId, setItemCategoryToDeleteId] = useState(null);
  const [currentItemCategory, setCurrentItemCategory] = useState(null);

  const { data: itemCategory } = useGetItemCategorysQuery();
  const [deleteItemCategory] = useDeleteItemCategoryMutation();

  const handleAddItemCategoryModal = () => {
    setItemCategory(true);
  };

  const handleEditItemCategoryModal = (items) => {
    setEditItemCategory(true);
    setCurrentItemCategory(items);
  };

  const toggleDeleteModal = () => {
    setShowClientDeleteModal(!showClientDeleteModal);
  };

  const handleOpenDeleteModal = (id) => {
    setItemCategoryToDeleteId(id);
    toggleDeleteModal();
  };

  const handleDeleteItemCategory = () => {
    const deleted = {
      id: itemCategoryToDeleteId,
    };
    deleteItemCategory(deleted)
      .unwrap()
      .then(() => {
        console.log("Item category deleted successfully");
      })
      .catch((error) => {
        console.error("Error deleting item category:", error);
      });
    toggleDeleteModal();
  };

  return (
    <div className="pcard incoterm">
      <section className="pi-header">
        <h3>Item Category</h3>
      </section>
      {itemCategory?.data?.map((items) => (
        <section className="pi-body" key={items.id}>
          <p>{items?.category}</p>
          <p>{items?.description}</p>
          <div>
            <ModeEditOutlineOutlinedIcon
              onClick={() => handleEditItemCategoryModal(items)}
              sx={{
                color: "#52FFAC",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#52FFAC",
                  border: "1px solid #52FFAC",
                  borderRadius: 25,
                },
              }}
            />
            <DeleteOutlineOutlinedIcon
              onClick={() => handleOpenDeleteModal(items.id)}
              sx={{
                color: "#FF5252",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#FF5252",
                  border: "1px solid #FF5252",
                  borderRadius: 25,
                },
              }}
            />
          </div>
        </section>
      ))}

      <section className="pi-footer">
        <div onClick={handleAddItemCategoryModal} className="pi-footer-icon">
          <AddIcon />
        </div>
      </section>

      {addItemCategory && <AddItemsCategory modal={setItemCategory} />}
      {editItemCategory && (
        <EditItemsCategory
          modal={setEditItemCategory}
          data={currentItemCategory}
        />
      )}

      <DeleteModal
        show={showClientDeleteModal}
        handleDeleteModal={toggleDeleteModal}
        name={"item category"}
        deletedId={itemCategoryToDeleteId}
        navigationto={"smm/item-category"}
        handleDelete={handleDeleteItemCategory}
      />
    </div>
  );
}
