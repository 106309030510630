import React from "react";
import ForexSideBar from "../../../../components/Payment/ForexApproval/PaymentSideBar";
import Delinquent from "../../../../assets/SVG/delinquent.png";
import Exchange from "../../../../assets/SVG/exchange.png";
import { NavLink } from "react-router-dom";
export default function index() {
  return (
    <ForexSideBar>
      <div className="supplier-menu-container">
        <NavLink to={"Delinquent"} className="payment-menu-card">
          <div className="text-center">
            <img
              src={Delinquent}
              style={{ width: 80, height: 80, borderRadius: "0px" }}
            />
          </div>
          <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
            Delinquent
          </span>
        </NavLink>
        <NavLink to={"foreexpiration"} className="payment-menu-card">
          <div className="text-center">
            <img
              src={Exchange}
              style={{ width: 80, height: 80, borderRadius: "0px" }}
            />
          </div>
          <span className="h5-pri text-center" style={{ fontSize: "20px" }}>
            Forex Expiration
          </span>
        </NavLink>
      </div>
    </ForexSideBar>
  );
}
