import { Button } from "@mui/material";
import React from "react";
import { useState } from "react";
import {
  useAttachDeliveryNoteMutation,
  useGetBatchGroupQuery,
  useGetProjectsQuery,
  useRequestGrnForGroupItemsByGroupIdMutation,
} from "../../features/SCMApi";
import FileChooser from "../common/FileChooser";
import DeliveryNoteTemplate from "../../assets/Template/IEDelivery note template.docx";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../api/endPoint";
import AutoClosePopup from "../../pages/GRN/SuccessMessage/AutoClosePopup ";
export default function ExistingGroupModal(props) {
  const [attachDeliveryNote] = useAttachDeliveryNoteMutation();
  const { data: project } = useGetProjectsQuery();
  const [delivery_note, setDeliveryNote] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [type, setType] = useState("success");
  const [RequestSuccess, setRequestSuccess] = useState(false);

  const [requestGrnForGroupItemsByGroupId] =
    useRequestGrnForGroupItemsByGroupIdMutation();
  let selectedProject = [];
  const { ProjectId } = useParams();
  selectedProject.push(
    project?.data?.filter((project) => project?.id == ProjectId)
  );

  // selectedProject = [...selectedProject]; 
  const { batchId } = useParams();
  const { data: batchGroup } = useGetBatchGroupQuery(batchId);
  const [selectedGroup, setGroup] = useState([{ name: "hh" }]);
  let selectedGroupItem = [];
  let selectedGroupClientItem = [];
  let docgroup = "";
  // batchGroup?.data
  //   ?.filter((items) => items?.id == selectedGroup)
  //   ?.map((group) => (selectedGroupItem=group?.item));

  batchGroup?.data
    ?.filter((items) => items?.id == selectedGroup)
    ?.map((group) => (selectedGroupItem = group?.client_item ?? []));
  // batchGroup?.data
  // ?.filter((items) => items?.id == selectedGroup)
  // ?.map((group) => (selectedGroupClientItem=group?.client_item));
  

  batchGroup?.data
    ?.filter((items) => items?.id == selectedGroup)
    ?.map((group) => (docgroup = group?.delivery_note));

  // const item = [];
  // selectedGroupItem?.map((item, index) =>
  //   item.push({ ...item, table_index: index })
  // );

  
  // selectedGroupItem?.map((item, index) => item.table_index = index + 1);
  selectedGroupItem?.map((item, index) => {
    return {
      ...item,
      table_index: index + 1,
    };
  });

  const items = [...selectedGroupItem];

  const deliveryNotData = {
    ...selectedProject[0]?.["0"],
    items: items,
    client_name: selectedProject[0]?.["0"]?.client?.client_name,
  };

  function loadFile(url, callback) {
    PizZipUtils.getBinaryContent(url, callback);
  }
  const generateDocument = (template) => {
    loadFile(DeliveryNoteTemplate, function (error, content) {
      if (error) {
        throw error;
      }
      var zip = new PizZip(content);
      var doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      });
      doc.setData(deliveryNotData);
      try {
        // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
        doc.render();
      } catch (error) {
        // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
        function replaceErrors(key, value) {
          if (value instanceof Error) {
            return Object.getOwnPropertyNames(value).reduce(function (
              error,
              key
            ) {
              error[key] = value[key];
              return error;
            },
            {});
          }
          return value;
        }
       

        if (error.properties && error.properties.errors instanceof Array) {
          const errorMessages = error.properties.errors
            .map(function (error) {
              return error.properties.explanation;
            })
            .join("\n");
          
          // errorMessages is a humanly readable message looking like this :
          // 'The tag beginning with "foobar" is unopened'
        }
        throw error;
      }
      var out = doc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      }); //Output the document using Data-URI
      saveAs(out, "DeliveryNote.docx");
    });
  };

  function HandleCancel(e) {
    e.preventDefault();
    props.ToggleModal(false);
    
  }
  function handleSubmit(e) {
    e.preventDefault();
    const DeliveryNoteForm = {
      id: selectedGroup,
      delivery_note,
    };
    
    axios({
      method: "post",
      url: `${API_BASE_URL}/scm/item-delivery/delivery-note/${DeliveryNoteForm?.id}`,
      data: DeliveryNoteForm,
      headers: { "Content-type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        HandleCancel(e);
        
      })
      .catch(function (response) {
        //handle error
        
      });
    // attachDeliveryNote(DeliveryNoteForm)
    //   .unwrap()
    //   .then((response) => {
    
    //   })
    //   .then((error) => {
    
    //   });
  }

  const handleRequestGRN = (selectedGroup) => {
    const requestGrn = () => {
      
      requestGrnForGroupItemsByGroupId(selectedGroup)
        .unwrap()
        .then((response) => {
          setIsOpen(true);
          setMessage(response?.message);
          setType("success");
          setRequestSuccess(true);
          
        })
        .catch((error) => {
          setIsOpen(true);
          setMessage(error?.data?.message);
          setType("danger");
          setRequestSuccess(false);
          
        });
    };
    requestGrn();
  };

 

  return (
    <div>
      {" "}
      <AutoClosePopup
        type={type}
        message={message}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
      <form className="form-container ">
        <div className="form-card-container">
          <div className="form-card-information">
            <div className="card-center">
              <div className="ms-5">
                <select
                  name=""
                  id=""
                  className="existing-group-selector"
                  required
                  onChange={(e) => setGroup(e.target.value)}
                >
                  <option value="" disabled selected hidden>
                    Select Group
                  </option>
                  {batchGroup?.data?.map((item) => (
                    <option value={item?.id}>{item?.group_name}</option>
                  ))}
                </select>

                {selectedGroup !== null ? (
                  <>
                    <div className="d-grid justify-content-center gap-2">
                      <div className="h5-pri text-center">Group Name</div>
                      <div className="divider"></div>
                      <ul>
                        
                        {selectedGroupItem.map((item) => (
                          
                          <li className="prg mb-1">{item?.item_description}</li>
                        ))}
                        {/* {Object.keys(batchGroup?.data)?.map(
                      (batch) => 
                      // JSON.parse(batchGroup?.data?.batch)
                      //   ?.filter((item) => item?.id == selectedGroup)
                      //   ?.map((group) => (
                      //     <li className="prg mb-1">
                      //      
                      //     </li>
                      //   ))
                    )} */}
                      </ul>
                    </div>
                   
                    <div className="existingDownloadBox">
                      <span className="rmaDownloadLabel">Delivery Note</span>
                      <Button
                        sx={{}}
                        onClick={async (e) => {
                          await handleRequestGRN(selectedGroup);
                          if (
                            (RequestSuccess ||
                              message ==
                                "you can not request GRN twice for a single delivery group") &&
                            selectedGroup !== undefined
                          ) {
                            await generateDocument();
                          }
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="#186569"
                          class="bi bi-download"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                        </svg>
                      </Button>
                    </div>
                    <span
                      className="rmaDownloadLabel fs-6"
                      style={{ color: "red", fontStyle: "italic" }}
                    >
                      {message}
                    </span>
                    <div className="d-grid justify-content-center gap-2">
                      <div className="h5-pri text-center mt-3">
                        Attach Signed Delivery Note
                      </div>
                      <div className="divider"></div>
                      <div className="d-flex gap-2 align-items-center">
                        <div className="supplier-input-label">
                          Delivery Note
                        </div>
                        <FileChooser
                          notRequired={true}
                          state={delivery_note}
                          setter={setDeliveryNote}
                          id={"delivery_note"}
                        />
                        {docgroup != null ? (
                          <Button
                            href={`https://scm-back.ienetworks.co/api/scm/document/download?file_path=${docgroup}`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#186569"
                              class="bi bi-download"
                              viewBox="0 0 16 16"
                            >
                              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                            </svg>
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center gap-3 mt-2">
          <button
            type="cancel"
            className="form-cancel"
            onClick={(e) => HandleCancel(e)}
          >
            {" "}
            Cancel
          </button>
          <button
            type="submit"
            className="form-save"
            onClick={(e) => handleSubmit(e)}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}
