import React from "react";
import {
  useDeletesupplierBankMutation,
  useDeletesupplierRepersentativeMutation,
  useGetCurrenciesQuery,
  useGetManufacturerQuery,
  useGetSuppliersQuery,
  useGetUserInfoQuery,
} from "../../features/SCMApi";
import { useParams } from "react-router-dom";
import { useState } from "react";
import Supplier from "../../pages/Supplier";
import RepresentativeModal from "./SupplierModal/RepresentativeModal";
import BankinformationModal from "./SupplierModal/BankInformationModal";
import ManufacturerBankinformationModal from "./SupplierModal/ManufacturerBankinformationModal";
import ManufacturerRepresentativeModal from "./SupplierModal/ManufaturerReperesetativeModal";
import axios from "axios";
import { API_BASE_URL } from "../../api/endPoint";
import SupplierRepresentativeEditModal from "./SupplierModal/SupplierRepresentativeEditModal";
import SupplierBankDetailEditModal from "./SupplierModal/SupplierBankDetailEditModal";
import SupplierEditAddress from "./SupplierEditAddress";
import ManufacturerEditAddress from "./ManufacturerEditAddress";
import DeleteModal from "./SupplierModal/DeleteModal";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import { height } from "@mui/system";
import usePagination from "../Pagination/Pagination";
import { Pagination, Tooltip } from "@mui/material";
import noitems from "../../assets/images/onitem.png";
import NoRecord from "../common/NoRecord";
import IsSupplier from "../common/IsSupplier";
import CheckPermission from "../common/checkPermission/checkPermission";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ConfirmDelete from "../common/ConfirmDelete";

export default function ManufacturerDetail() {
  const [show, setShow] = useState(false);
  const [showaddress, setShowAddress] = useState(false);
  const [showBank, setShowBank] = useState(false);
  const params_id = useParams();
  const [supplier_id, setSupplierid] = useState();
  const [product_id, setProductid] = useState(params_id.id);
  const [distributor_name, setManufacturer_name] = useState();
  const [origin_id, setManufacturer_origin] = useState();
  const [website, setManufacturer_website] = useState();
  const [address, setManufacturer_address] = useState();
  const [product_ids, setManufacturer] = useState([]);
  const [postal_code, setPobox] = useState();
  // bank detail
  const [bank_name, setBank_name] = useState();
  const [account_number, setAccount] = useState();
  const [swift_code, setSwift] = useState();
  const [iban, setIban] = useState();
  const [bank_country, setBankcountry] = useState();
  const [bank_currency, setBankcurrency] = useState();
  const [city, setManufacturer_city] = useState();
  const [
    createSupplierManufatcurerEditModal,
    setCreatesupplierManufacturerEdit,
  ] = useState(false);
  const [EditSupplierBankinfoEditModal, setEditsupplierBankinfo] =
    useState(false);

  const [createRepresentatives, setRepresentative] = useState(false);
  function createRepresentativeHandler() {
    setRepresentative(true);
  }
  function editBankDetailHandler(items) {
    setEditsupplierBankinfo(true);
    setEditsupplierBankinfo(items);
  }
  const [createBankinformation, setBankinformation] = useState(false);
  const [EditAddress, setEditaddressModal] = useState(false);
  function EditAddressHandler() {
    setEditaddressModal(true);
  }
  function createBankinformationHandler() {
    setBankinformation(true);
  }
  function createSupplierEditManufacturerHandler(items) {
    setCreatesupplierManufacturerEdit(true);
    setCreatesupplierManufacturerEdit(items);
  }
  const params = useParams();
  const {
    data: manufacturerDetail,
    isError,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetManufacturerQuery(params.id);
  const {
    data: origin,
    isErrors,
    errors,
    isLoadings,
    isFetchings,
    isSuccesss,
  } = useGetCurrenciesQuery();

  const [showClientDeleteModal, toggelClientDeleteModal] = useState(false);
  const toggleClientDeleteHandler = (e) => {
    toggelClientDeleteModal(!showClientDeleteModal);
  };
  const HandleSubmit = async (e) => {
    e.preventDefault();
    axios
      .post(
        `${API_BASE_URL}/scm/supplier/manufacturer-suppliers`,
        supplierProduct,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        
      })
      .then((error) => {
        
      });
    // createSupplier(params)
    //   .unwrap()
    //   .then((response) => {

    //   })
    //   .then((error) => {

    //   });
  };
  let supplierProduct = {
    supplier_id,
    product_id,
  };
  const [isSupplier, setIsSupplier] = useState(true);
  let addressmanufacturer = {
    distributor_name,
    origin_id,
    city,
    website,
    address,
    postal_code,
    isSupplier,
  };

  const [manufactureId, setManufactureId] = useState(null);

  let [page, setPage] = useState(1);
  let [pagebank, setPagebank] = useState(1);
  const PER_PAGE = 5;
  const count = Math.ceil(
    manufacturerDetail?.data?.manufacture_representatives?.length / PER_PAGE
  );
  const countbank = Math.ceil(
    manufacturerDetail?.data?.manufacturer_bank?.length / PER_PAGE
  );
  const _DATA = usePagination(
    manufacturerDetail?.data?.manufacture_representatives,
    PER_PAGE
  );
  const _DATABank = usePagination(
    manufacturerDetail?.data?.manufacturer_bank,
    PER_PAGE
  );
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  const handleChangeBank = (e, p) => {
    setPagebank(p);
    _DATABank.jump(p);
  };
  const [messages, setMessages] = useState(null);
  const { data: userinfo } = useGetUserInfoQuery();
  const [id, setID] = useState();
  const [representative, setRepresentatives] = useState();
  const [deleteDialog, setDialog] = useState(false);
  const [isConfirmation, setConfirmation] = useState(false);
  const [deleteBankDialog, setBankDialog] = useState(false);

  const DeleteHandler = () => {
    setDialog(true);
  };
  const DeleteBankHandler = () => {
    setBankDialog(true);
  };
  const [deleteRepresentative, res] = useDeletesupplierRepersentativeMutation();
  const [deleteBank, ress] = useDeletesupplierBankMutation();
  console?.log(id, "hahah id");
  let deletedId = {
    id: id,
  };
  const actionRemove = () => {
    deleteRepresentative(deletedId)
      .unwrap()
      .then((response) => {

        setDialog(false);
        setMessages("Representative deleted successfully");
        // window.location.reload();
      })
      .then((error) => {

      });
  };
  const actionRemoveBank = () => {
    deleteBank(deletedId)
      .unwrap()
      .then((response) => {

        setBankDialog(false);
        setMessages("Bank information deleted successfully");
        // window.location.reload();
      })
      .then((error) => {

      });
  };

  return (
    <Supplier
      name={manufacturerDetail?.data?.manufacturer_name}
      title={"Manufacturer"}
      navlink={"/smm/manufacturers"}
      actives={"active"}
      Message={messages}
    >
      <div className="page">
        <div className="detail-address-container">
          <div className="">
            <div className="card manufacturer-product-container bg-white shadow-sm mb-3">
              <div className="h5 mx-2 ">Suppliers</div>
              <div className="card-body">
                {manufacturerDetail?.data?.isSupplier == 1 ? (
                  <IsSupplier
                    name={manufacturerDetail?.data?.manufacturer_name}
                  />
                ) : manufacturerDetail?.data?.isSupplier == 0 &&
                  manufacturerDetail?.data?.product_supplier.length > 0 ? (
                  <div className="border rounded">
                    <div className="row">
                      {manufacturerDetail?.data?.product_supplier.map(
                        (items) => (
                          <div className="supplier-product-rounded my-2 mx-3 mb-3 col-3">
                            <div className="supplier-product-rounded-text mx-2">
                              {items.distributor_name}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                ) : manufacturerDetail?.data?.isSupplier === 0 &&
                  manufacturerDetail?.data?.product_supplier.length === 0 ? (
                  <NoRecord />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="">
            <div className="card supplier-card-address shadow-sm mb-3">
              <div className="text-center h5-pri mt-1 mb-3 mt-3">Address</div>
              {!showaddress && (
                <>
                  <Tooltip title={manufacturerDetail?.data?.address}>
                    <div className="text-center h5 mt-1 mb-2 prg">
                      {manufacturerDetail?.data?.address?.length >= 20
                        ? manufacturerDetail?.data?.address?.slice(0, 30) +
                          "..."
                        : manufacturerDetail?.data?.address}
                    </div>
                  </Tooltip>

                  <div className="text-center plg mt-1 mb-2">
                    P.O BOX {manufacturerDetail?.data?.postal_code}
                  </div>
                  <Tooltip title={manufacturerDetail?.data?.website}>
                    <div className="text-center plg mt-1 mb-2">
                      {manufacturerDetail?.data?.website?.length >= 20
                        ? manufacturerDetail?.data?.website?.slice(0, 30) +
                          "..."
                        : manufacturerDetail?.data?.website}
                    </div>
                  </Tooltip>

                  <div className="text-center plg mt-1 mb-2">
                    {manufacturerDetail?.data?.origin}
                  </div>
                </>
              )}
              {!showaddress && (
                <div className="d-flex justify-content-end p-2">
                  {CheckPermission("supplier_edit_manufacturer", userinfo) ? (
                    <IconButton
                      variant="text"
                      size="medium"
                      sx={{
                        backgroundColor: "#186569",
                        color: "#fff",
                        border: "1px solid #186569",
                        "&:hover": {
                          backgroundColor: "#fff",
                          color: "#186569",
                          border: "1px solid #186569",
                        },
                        marginTop: "3%",
                      }}
                      onClick={EditAddressHandler}
                    >
                      <ModeEditOutlinedIcon />
                    </IconButton>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-12 mb-3">
          <div className="card supplier-card-representative bg-white shadow-sm">
            <div className="d-flex justify-content-between">
              <div className="h5 mx-4 my-3">Manufacturer Representatives</div>
            </div>
            <div className="card-body">
              <div className="d-grid gap-3 ">
                {_DATA.currentData()?.map((items) => (
                  <div className="supplier-representative">
                    <div className="d-flex justify-content-between supplier-representative-sm p-3">
                      <div className="prg supplier-representative-container">
                        <div className="representative-header">Full Name</div>
                        {items.full_name}
                      </div>
                      <div className="prg supplier-representative-container">
                        <div className="representative-header">Email</div>
                        {items.email}
                      </div>

                      <div className="prg supplier-representative-container">
                        <div className="representative-header">
                          Phone number
                        </div>
                        {items.s_r_phone_number.map((item) => (
                          <>
                            {item.phone_number}
                            <br />
                          </>
                        ))}
                      </div>
                      {/* edit modal */}
                      <div className="supplier-representative-container">
                        {CheckPermission(
                          "supplier_update_manufacturer_rep",
                          userinfo
                        ) ? (
                          <>
                            <div className="prg representative-header">
                              Action
                            </div>
                            <IconButton
                              variant="text"
                              size="small"
                              sx={{
                                zIndex: 1,
                                backgroundColor: "#fff",
                                color: "#186569",

                                "&:hover": {
                                  backgroundColor: "#186569",
                                  color: "#fff",
                                },

                                marginBottom: "3px",
                              }}
                              onClick={() =>
                                createSupplierEditManufacturerHandler(items)
                              }
                            >
                              <ModeEditOutlinedIcon />
                            </IconButton>
                            <IconButton
                              variant="text"
                              size="small"
                              sx={{
                                zIndex: 1,
                                backgroundColor: "#fff",
                                color: "#ff5252",

                                "&:hover": {
                                  backgroundColor: "#ff5252",
                                  color: "#fff",
                                },
                                marginBottom: "3px",
                              }}
                              onClick={(e) => {
                                DeleteHandler();
                                setRepresentatives(items.full_name);
                                setID(items?.id);
                              }}
                            >
                              <DeleteOutlineIcon />
                            </IconButton>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="d-flex justify-content-between align-items-center mt-1 float-end">
                {count > 1 ? (
                  <Pagination
                    count={count}
                    size="large"
                    page={page}
                    variant="outlined"
                    shape="rounded"
                    onChange={handleChange}
                  />
                ) : (
                  ""
                )}
                {CheckPermission(
                  "supplier_update_manufacturer_rep",
                  userinfo
                ) ? (
                  <IconButton
                    variant="text"
                    size="medium"
                    className=""
                    sx={{
                      backgroundColor: "#186569",
                      color: "#fff",
                      border: "1px solid #186569",

                      "&:hover": {
                        backgroundColor: "#fff",
                        color: "#186569",
                        border: "1px solid #186569",
                      },
                    }}
                    onClick={createRepresentativeHandler}
                  >
                    <AddIcon />
                  </IconButton>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 mb-3">
          <div className="card supplier-card-representative bg-white shadow-sm">
            <div className="h5-pri text-center my-2 ">Bank Detail</div>
            <div className="divider"></div>
            <div className="supplier-bank-header text-center p-3">
              <div className="prg fw-bold">Currency</div>
              <div className="prg fw-bold">Account Name</div>
              <div className="prg fw-bold">Account Number</div>
              <div className="prg fw-bold">Bank</div>
              <div className="prg fw-bold">Swift Code</div>
              <div className="prg fw-bold">IBAN</div>
              <div className="prg"></div>
            </div>
            <div className="card-body">
              <div className="d-grid gap-3 ">
                {_DATABank?.currentData()?.map((items) =>
                  items.isDeleted == 0 ? (
                    <div className="supplier-representative">
                      {!showBank && (
                        <>
                          <div className="supplier-bank-body text-center p-3">
                            <div className="prg supplier-representative-container">
                              <div className="representative-header">
                                Currency
                              </div>
                              {items.bank_currency}
                            </div>
                            <div className="prg supplier-representative-container">
                              <div className="representative-header">
                                Account Name
                              </div>
                              {items?.account_name === null
                                ? "Unknown"
                                : items?.account_name}
                            </div>
                            <div className="prg supplier-representative-container">
                              <div className="representative-header">
                                Account Number
                              </div>
                              {items.account_number}
                            </div>
                            <div className="prg supplier-representative-container">
                              <div className="representative-header">
                                Bank Name
                              </div>
                              {items.bank_name}
                            </div>
                            <div className="prg supplier-representative-container">
                              <div className="representative-header">
                                Swift code
                              </div>
                              {items.swift_code}
                            </div>
                            <div className="prg supplier-representative-container">
                              <div className="representative-header">IBAN</div>
                              {items.iban}
                            </div>
                            <div className="prg d-flex supplier-representative-container">
                              {CheckPermission(
                                "supplier_update_manufacturer_bank_detail",
                                userinfo
                              ) ? (
                                <>
                                  <div className="representative-header">
                                    Action
                                  </div>
                                  <IconButton
                                    variant="text"
                                    size="small"
                                    sx={{
                                      zIndex: 1,
                                      backgroundColor: "#fff",
                                      color: "#186569",

                                      "&:hover": {
                                        backgroundColor: "#186569",
                                        color: "#fff",
                                      },

                                      marginBottom: "3px",
                                    }}
                                    onClick={() => editBankDetailHandler(items)}
                                  >
                                    <ModeEditOutlinedIcon />
                                  </IconButton>
                                  <IconButton
                                    variant="text"
                                    size="small"
                                    sx={{
                                      zIndex: 1,
                                      backgroundColor: "#fff",
                                      color: "#ff5252",

                                      "&:hover": {
                                        backgroundColor: "#ff5252",
                                        color: "#fff",
                                      },
                                      marginBottom: "3px",
                                    }}
                                    onClick={(e) => {
                                      DeleteBankHandler();
                                      setRepresentatives(items.bank_name);
                                      setID(items?.id);
                                    }}
                                  >
                                    <DeleteOutlineIcon />
                                  </IconButton>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    ""
                  )
                )}
              </div>
              <div className="d-flex justify-content-between align-items-center mt-1 float-end">
                {countbank > 1 ? (
                  <Pagination
                    count={countbank}
                    size="large"
                    page={pagebank}
                    variant="outlined"
                    shape="rounded"
                    onChange={handleChangeBank}
                  />
                ) : (
                  ""
                )}
                {CheckPermission(
                  "supplier_add_manufacturer_bank_detail",
                  userinfo
                ) ? (
                  <IconButton
                    variant="text"
                    size="medium"
                    sx={{
                      backgroundColor: "#186569",
                      color: "#fff",
                      border: "1px solid #186569",

                      "&:hover": {
                        backgroundColor: "#fff",
                        color: "#186569",
                        border: "1px solid #186569",
                      },
                    }}
                    onClick={createBankinformationHandler}
                  >
                    <AddIcon />
                  </IconButton>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        {CheckPermission("supplier_remove_manufacturer", userinfo) ? (
          <div
            className="d-flex justify-content-center
          mb-3"
          >
            <button
              type="submit"
              className="supplier-remove-btn"
              onClick={(e) => {
                setManufactureId(params?.id);
                toggleClientDeleteHandler();
              }}
            >
              Remove Manufacturer
            </button>
          </div>
        ) : (
          ""
        )}
        {createSupplierManufatcurerEditModal ? (
          <SupplierRepresentativeEditModal
            modal={setCreatesupplierManufacturerEdit}
            value={createSupplierManufatcurerEditModal}
            setMessages={setMessages}
          />
        ) : (
          <></>
        )}
        {createRepresentatives ? (
          <ManufacturerRepresentativeModal
            modal={setRepresentative}
            setMessages={setMessages}
          />
        ) : (
          <></>
        )}
        {EditSupplierBankinfoEditModal ? (
          <SupplierBankDetailEditModal
            modal={setEditsupplierBankinfo}
            value={EditSupplierBankinfoEditModal}
            setMessages={setMessages}
          />
        ) : (
          <></>
        )}
        {createBankinformation ? (
          <ManufacturerBankinformationModal
            modal={setBankinformation}
            setMessages={setMessages}
          />
        ) : (
          <></>
        )}
        {EditAddress ? (
          <ManufacturerEditAddress
            value={manufacturerDetail}
            showaddress={setEditaddressModal}
            setMessages={setMessages}
          />
        ) : (
          <></>
        )}
        <DeleteModal
          show={showClientDeleteModal}
          handleDeleteModal={toggleClientDeleteHandler}
          name={"manufacturers"}
          deletedId={manufactureId}
          navigationto={"smm/manufacturers"}
        />
        {deleteDialog ? (
          <ConfirmDelete
            action={actionRemove}
            setters={setConfirmation}
            setDialog={setDialog}
            dialog={`Are You Sure to Delete ${representative} ?`}
          />
        ) : (
          ""
        )}
        {deleteBankDialog ? (
          <ConfirmDelete
            action={actionRemoveBank}
            setters={setConfirmation}
            setDialog={setBankDialog}
            dialog={`Are You Sure to Delete ${representative} ?`}
          />
        ) : (
          ""
        )}
      </div>
    </Supplier>
  );
}
