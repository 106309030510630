import React from 'react'
import moment from 'moment/moment';
// import { Link } from 'react-router-dom';
function LCTracking(props) {

    return (
             <>
                { props?.prfType =='LCByClient' && !props?.showLCItemDetail && 
                          <>
                              <div className='row justify-content-center'>
                                  {props?.lcItem?.map((data)=>(
                                      <div className='col-md-4 mb-3'>
                                        <div className="batch-detail-card missed-item-card">
                                          <div className="batch-detail-card-sub">
                                            <div className="batch-card-left-grid">
                                              <div className="batch-container">
                                                <div className="missed-item-logistics-batch-name font-bold " style={{marginTop:'50px'}}>
                                                        <div>{moment(data?.created_at).format('YYYY-MM-DD')}</div>
                                                </div>
                                              </div>
                                              <div className="missed-item-origin-group" style={{marginBottom:'60px',marginTop:'40px'}}>
                                                <div className="origin-value">
                                                  {data?.group_name}
                                                </div>
                                                <div className="origin-label">Group name</div>
                                              </div>
                                            </div>
                                
                                            <div className="logistics-batch-items-container">
                                            {/* <Link to={`LCitemDetail/${props?.data?.id}`}> */}
                                              <button className='px-3 py-2' style={{ color: 'white', backgroundColor: '#046475',marginTop:'40px', borderRadius: '8px' }} onClick={()=>{props?.setShowLCItemDetail(true); props?.setLCItemDetailId(data?.item_delivery_id)}} >View More</button>
                                            {/* onClick={()=>{props?.setShowLCItemDetail(true); props?.setLCItemDetailId(props?.data?.id)}} */}

                                              <div className="missed-items-batch-item" style={{marginTop:'10px',marginLeft:'20px'}}>
                                                <div
                                                  className="logistics-batch-value " 
                                                  style={{ marginLeft: "15px" }}
                                                >
                                                  {JSON.parse(data?.item).length}
                                                </div>
                                                <div className="logistics-batch-label">
                                                    Items</div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      ))}
                                </div>
                          </>
                }
            </>
      );
}

export default LCTracking