import * as React from "react";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import "../../Settings/Settings.css";
import "../forex.style.css";
import Select from "../ForexApproval/Select";
import GapAnalysisTable from "./GAPAnalysis";
import {
  useGetGapAnalysisForBankQuery,
  useGetBankListForGapAnalysisQuery,
} from "../../../features/SCMApi";
import GapAnalysisMobileComponent from "./GapAnalysisMobileComponent";
import usePagination from "../../Pagination/Pagination";
import { Pagination } from "@mui/material";
import { styled } from "@mui/material/styles";

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
   {}
  );

  return (
    <p className="setting-accordion-p mb-0" onClick={decoratedOnClick}>
      {children}
    </p>
  );
}

export default function GapAnalysisDisplay(props) {
  const TotalDisplay1 = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    height: "50px",
    borderRadius: "5px",
    justifyContent: "center",
    color: "#186569",
    backgroundColor: "#FFFFFF",
    border: "1px solid #186569",
    width: "362px",
    marginTop: "-4%",
  }));
  const TotalDisplay2 = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    height: "50px",
    borderRadius: "5px",
    justifyContent: "center",
    color: "#186569",
    backgroundColor: "#FFFFFF",
    border: "1px solid #186569",
    width: "362px",
    marginTop: "4%",
  }));

  const { data: bankList } = useGetBankListForGapAnalysisQuery();
  const [selectedBank, setSelectedBank] = React.useState(null);
  const filteredBankList = bankList?.data?.map((bank) => bank?.bank_name);
  const bankId =
    selectedBank === null
      ? bankList?.data[0]?.id
      : bankList?.data !== []
      ? bankList?.data
          ?.filter((bank) => bank?.bank_name === selectedBank)
          ?.map((bank) => bank?.id)
          .flat()
          .toString()
      : "0";
  const { data: gapAnalysisForBank } = useGetGapAnalysisForBankQuery(bankId);
  const defaultBank = bankList?.data
    .filter((bank) => bank?.bank_name === bankList?.data[0]?.bank_name)
    ?.map((bank) => bank?.bank_name)
    .toString();

  const data = [];
  for (let forexNeed in gapAnalysisForBank?.data?.forexNeed) {
    data.push({
      ...gapAnalysisForBank?.data?.forexNeed[forexNeed],
      balance:
        gapAnalysisForBank?.data?.forexBalance !== null
          ? gapAnalysisForBank?.data?.forexBalance?.amount
          : 0,
      receivable:
        gapAnalysisForBank?.data?.receivableAmount !== null
          ? gapAnalysisForBank?.data?.receivableAmount
          : 0,
    });
  }

  const totalReceivable =
    gapAnalysisForBank?.data?.receivableAmount !== null
      ? gapAnalysisForBank?.data?.receivableAmount
      : 0;
  const totalBalance =
    gapAnalysisForBank?.data?.forexBalance !== null
      ? gapAnalysisForBank?.data?.forexBalance?.amount
      : 0;

  let [page, setPage] = React.useState(1);
  const PER_PAGE = 5;
  const count = Math.ceil(data?.length && data.length / PER_PAGE);
  const _DATA = usePagination(data, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const totalNeed =
    Math.round(
      (data?.reduce((total, item) => (total += Number(item.amount) || 0), 0) ||
        0 + Number.EPSILON) * 1000
    ) / 1000;
  const totalGap =
    Math.round(
      (totalBalance + totalReceivable - totalNeed + Number.EPSILON) * 1000
    ) / 1000;

  return (
    <>
      <div className="card paymentTrackingContainer gap-analysis-web-view">
        <div className="card-body">
          <div className="paymentTrackingSelectGapAnalysis">
            <Select
              name={filteredBankList}
              placeHolder={"Select Bank..."}
              onChange={setSelectedBank}
              style={{ width: 250, fontSize: 11, marginTop: 1.5 }}
              passedValue={defaultBank}
              // parentName="gapAnalysis"
            />
          </div>
          <GapAnalysisTable gapAnalysisData={gapAnalysisForBank} />
        </div>
      </div>
      <div className="gap-analysis-mobile-view-container">
        <div style={{ marginBottom: "30px" }}>
          <Select
            name={filteredBankList}
            placeHolder={"Select Bank..."}
            onChange={setSelectedBank}
            style={{ width: 362, fontSize: 11 }}
            passedValue={defaultBank}
            // parentName="gapAnalysis"
          />
        </div>
        <TotalDisplay1>
          {/* {grandTotal.toLocaleString()} */}
          <span className="gap-analysis-mobile-label">
            Total Need:{" "}
            <span className="gap-analysis-mobile-amount">
              {totalNeed !== undefined
                ? `\xa0\xa0${Number(totalNeed)?.toLocaleString()}`
                : ""}
            </span>
          </span>
        </TotalDisplay1>
        <TotalDisplay2>
          <span className="gap-analysis-mobile-label">
            Gap:
            <span
              className="gap-analysis-mobile-amount"
              style={{
                color:
                  totalGap <= -1
                    ? "#FF5252"
                    : Number(totalGap) === 0
                    ? "#FABB06"
                    : "#00DD9B",
              }}
            >
              {totalGap !== undefined
                ? `\xa0\xa0${Number(totalGap)?.toLocaleString()}`
                : ""}
            </span>
          </span>
        </TotalDisplay2>

        <div className="gap-analysis-mobile-view">
          {_DATA?.currentData()?.map((bankGap) => (
            <GapAnalysisMobileComponent gapAnalysisData={bankGap} />
          ))}
          {count > 1 ? (
            <Pagination
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                margin: "5px",
              }}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}
