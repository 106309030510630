import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import "../forex.style.css";
import PaymentTrackingProject from "./PaymentTrackingProject";
import PaymentTrackingVendor from "./PaymentTrackingVendor";
import CreditNodeVendor from "./CreditNoteVendors";
// import PaymentDataTable from "./PaymentDataTable";
export default function PaymentTracking() {
  const [search, setSearch] = useState("");

  let title1 = "\xa0\xa0\xa0Project\xa0\xa0\xa0";
  let title2 = "\xa0\xa0\xa0Vendor\xa0\xa0\xa0";
  let title3 = "\xa0\xa0\xa0Credit Note\xa0\xa0\xa0";

  return (
    <Tabs
      defaultActiveKey="project"
      id="uncontrolled-tab-example"
      className="mb-3 d-flex justify-content-center bg-white rounded gap-4 border forexPlaningNav"
    >
      <Tab eventKey="project" title={title1}>
        <PaymentTrackingProject />
      </Tab>
      <Tab eventKey="vendor" title={title2}>
        <PaymentTrackingVendor />
      </Tab>
      <Tab eventKey="creditnote" title={title3}>
        <CreditNodeVendor />
      </Tab>
    </Tabs>
  );
}
