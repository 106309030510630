import React, { useEffect } from "react";
import { useState } from "react";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { Pagination, Tooltip } from "@mui/material";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import {
  useGetItemCategorysQuery,
  useGetStoreQuery,
  usegetAssetsQuery,
  useGetUserInfoQuery,
  useToolRequestMutation,
  useToolRequestRequestMutation,
  useGetFixedAssetsQuery,
  useAssetRequestMutation,
  useGetGeneralFixedAssetsQuery,
  useGetAssetsTransactionQuery,
} from "../../../features/SCMApi";
import AddIcon from "@mui/icons-material/Add";
import RegisterTool from "../StockModal/RegisterTool";
import NoRecord from "../../common/NoRecord";
import ConfirmDialog from "../../common/ConfirmDialog";
import StockDialog from "../../common/StockDialog";
import axios from "axios";
import { API_BASE_URL, IMG_BASE_URL } from "../../../api/endPoint";
import { Toll } from "@mui/icons-material";
import ConfirmRequest from "../StockModal/ConfirmRequest";
import InventoryReport from "../DownloadReports/InventoryReport";
import usePagination from "../../Pagination/Pagination";
import EditAssets from "../StockModal/EditAssets";
import GeneralReport from "../DownloadReports/GeneralReport";
import TransactionReport from "../Items/transaction/TransactionReport";
import { useLocation } from "react-router-dom";

export default function AssetTools(props) {
  function HandleRequest() {
    setConfirm(true);
  }
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const HandleFragment = (tool) => {
    // props?.setFragment("detail");
    props?.setData(tool);
  };
  const { data: getAssets } = useGetFixedAssetsQuery();
  const { data: genearlAssets } = useGetGeneralFixedAssetsQuery();

  const [registerTool, setRegisterTool] = useState(false);
  const [editAsset, setEditAsset] = useState(false);
  const [updateTool, setupdateTool] = useState(null);
  const [store_id, setStore_id] = useState("");
  const [category, setCategory] = useState("");
  function HandleItem() {
    setRegisterTool(true);
    setupdateTool(null);
  }
  const [confirm, setConfirm] = useState(false);
  const [dialog, setDialog] = useState(false);

  const [toolRequest, src] = useAssetRequestMutation();
  const [requested_quantity, setQuantity] = useState(null);
  const [requestedProject, setRequestedProject] = useState("");

  const { data: userinfo } = useGetUserInfoQuery();

  function HandleToolRequest(e, id) {
    let requested = {
      id: id,
      requested_quantity,
      requester_id: userinfo?.id,
      requested_project: requestedProject,
    };
    toolRequest(requested)
      .unwrap()
      .then((response) => {
        setConfirm(false);

        // props?.setMessages("Tool Requested successfully");
      })
      .then((error) => {});
    // axios
    //   .post(`${API_BASE_URL}/scm/stock/tools/request/${id}`, {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   })
    //   .then(function (response) {

    //   })
    //   .catch(function (error) {

    //   });
  }

  let notRequest = [];
  getAssets?.data?.map((items) =>
    items?.is_requested == 0 || items?.is_requested == 1
      ? notRequest.push(items)
      : ""
  );

  const { data: store } = useGetStoreQuery();
  const { data: ItemCategory } = useGetItemCategorysQuery();
  const storeData = store?.data?.filter((item) => item?.is_permanent == 1);

  let SK =
    userinfo?.role?.role_name == "Store Keeper" ||
    userinfo?.role?.role_name == "Super Admin";
  let FE = userinfo?.role?.role_name == "Field Engineer";
  let PM = userinfo?.role?.role_name == "Project Manager";
  // userinfo?.role?.role_name == "Super Admin"; // userinfo?.role?.role_name == "Super Admin" ||
  // userinfo?.role?.role_name == "Field Engineer";
  let create_by = userinfo?.id;

  const HandleEditTool = () => {
    setEditAsset(true);
  };

  const [amount, setAmount] = useState();
  const [confirmRequest, setConfirmRequest] = useState(false);
  const [confirmRequestItems, setConfirmRequestItems] = useState([]);
  function HandleConfirmRequest() {
    setConfirmRequest(true);
  }
  function handleBatchRequest(e, items, i) {
    e.preventDefault();
    const value = e.target.value;
    const tools = [...confirmRequestItems];
    tools[i] = { ...items, requested_amount: value };
    //  setAmount(tools);
    setConfirmRequestItems(tools);
  }
  let data;
  data = confirmRequestItems.filter(function (element) {
    return element !== undefined;
  });

  const filteredData = (search, category, store_id) => {
    return notRequest
      ?.filter((items) => {
        if (search === "" || items?.store_id == null) {
          return items;
        } else if (
          items.item_name?.toLowerCase().includes(search.toLowerCase()) ||
          items.model?.toLowerCase().includes(search.toLowerCase()) ||
          items.serial_number?.toLowerCase().includes(search.toLowerCase())
        ) {
          return items;
        }
      })
      ?.filter((items) => {
        if (category === "") {
          return items;
        } else if (items?.category_id == category) {
          return items;
        }
      })
      ?.filter((items) => {
        if (store_id === "") {
          return items;
        } else if (items?.store?.id == store_id) {
          return items;
        }
      })
      .map((items) => items);
  };

  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pagenum = parseInt(queryParams.get("page"), 10);
  useEffect(() => {
    if (pagenum) {
      setPage(pagenum);
    }
  }, [pagenum]);

  const count = Math.ceil(
    filteredData(search, category, store_id)?.length / PER_PAGE
  );
  const _DATA = usePagination(
    filteredData(search, category, store_id),
    PER_PAGE
  );
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const { data: transactionAsset } = useGetAssetsTransactionQuery();

  const safeTransaction = Array.isArray(transactionAsset)
    ? transactionAsset
    : [];
  const newArrayData = safeTransaction.map((obj) => {
    const getFirstObject = (array) =>
      Array.isArray(array) && array.length > 0 ? array[0] : {};
    const asset_transactions = getFirstObject(obj.asset_transactions);
    const asset_user_transactions = getFirstObject(obj.asset_user_transactions);

    let additionalProps = {};

    additionalProps = {
      item_name: asset_transactions.item_name || "",
      image: asset_transactions.image || "",
      model: asset_transactions.model || "",
      serial_number: asset_transactions.serial_number || "",
      purchased_date: asset_transactions.date_of_purchased || "",
      uom: asset_transactions.uom_id || "",
      currencies: asset_transactions.currency_id || "",
      price: asset_transactions.price || "",
      is_batch: asset_user_transactions?.is_batch || "",
    };

    return {
      ...obj,
      ...additionalProps,
    };
  });

  return (
    <div>
      {SK ? (
        <div className="d-flex justify-content-center mb-3 mt-3">
          <div className="create-project-btn" onClick={HandleItem}>
            <AddIcon
              sx={{
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#186569",
                },
              }}
            />
            Register Asset
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="inventory-header-container">
        <Tooltip title="Transaction Report">
          <TransactionReport
            data={newArrayData}
            type={"Assets Transaction Report"}
            inventory_type={"Asset"}
          />
        </Tooltip>
        <Tooltip title="Detail Report">
          <InventoryReport
            data={filteredData(search, category, store_id)}
            type="Asset Lists"
            is_tool={true}
          />
        </Tooltip>
        <Tooltip title="General Report">
          {" "}
          <GeneralReport data={genearlAssets?.data} type="Asset Lists" />
        </Tooltip>

        {/* <Tooltip title="Batch Request">
          <Inventory2Icon
            sx={{ color: "#064b4f" }}
            onClick={HandleConfirmRequest}
          />
        </Tooltip> */}
        <input
          onChange={handleSearchChange}
          placeholder="search"
          type="search"
          className="inventory-search-input"
        />
        <select
          type="search"
          className="inventory-search-input"
          onChange={(e) => setStore_id(e.target.value)}
        >
          <option value="">Store</option>
          {storeData?.map((items) => (
            <option value={items?.id}>{items?.store_name}</option>
          ))}
        </select>

        <select
          type="search"
          className="inventory-search-input"
          onChange={(e) => setCategory(e.target.value)}
        >
          <option value="">Item Group</option>
          {ItemCategory?.data?.map((items) => (
            <option value={items?.id}>{items?.category}</option>
          ))}
        </select>

        <div className="items-counter">
          <div className="text-center">
            {filteredData(search, category, store_id)?.length}
          </div>
          <div>Items Count</div>
        </div>
      </div>
      {_DATA?.currentData()?.length !== 0 ? (
        <>
          <div className="finance-table-container">
            <table
              className="table table-striped table-bordered "
              style={{
                width: "auto",
                minWidth: "2000px",
                borderCollapse: "collapse",
              }}
            >
              <thead className="stock-table-header">
                <tr>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "10%" }}
                  ></th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "10%" }}
                  >
                    Asset Name
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "8%" }}
                  >
                    Model
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "7%" }}
                  >
                    Serial Number
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "10%" }}
                  >
                    Type
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "7%" }}
                  >
                    Store
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "5%" }}
                  >
                    Quantity
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "15%" }}
                  >
                    Project
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "7%" }}
                  >
                    Item Number
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "5%" }}
                  >
                    Price
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "5%" }}
                  >
                    Total Price
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "7%" }}
                  >
                    Date Of Purchase
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "7%" }}
                  >
                    Currency
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "10%" }}
                  >
                    Department
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "15%" }}
                  >
                    Category
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "10%" }}
                  >
                    Manufacturer
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "10%" }}
                  >
                    Status
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "10%" }}
                  >
                    Remark
                  </th>
                  <th
                    className="stock-table-header-text"
                    style={{ width: "15%" }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {_DATA?.currentData()?.map((tool, index) => (
                  <tr className="stock-table-row">
                    <td
                      className=""
                      onClick={() => {
                        HandleFragment(tool);
                        setupdateTool(null);
                      }}
                    >
                      <img
                        className="stock-img"
                        src={`${IMG_BASE_URL}${tool?.image}`}
                        alt=""
                      />
                    </td>
                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {tool?.item_name}
                    </td>
                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {tool?.model === null ? "-" : tool?.model}
                    </td>
                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {tool?.serial_number === null ? "-" : tool?.serial_number}
                    </td>

                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {tool?.tool_type_id === null
                        ? "-"
                        : tool?.tool_type?.tool_type}
                    </td>
                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {tool?.store_id === null ? "-" : tool?.store?.store_name}
                    </td>
                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {`${tool?.amount === null ? "-" : tool?.amount} ${
                        tool?.uom_id == null ? "-" : tool?.uom?.name
                      }`}
                    </td>
                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {tool?.project === null
                        ? "-"
                        : tool?.project.project_name}
                    </td>
                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {tool?.item_number === null ? "-" : tool?.item_number}
                    </td>
                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {tool?.price === null ? "-" : tool?.price}
                    </td>
                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {tool?.price !== null && tool?.amount !== null
                        ? tool.price * tool.amount
                        : "-"}
                    </td>
                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {tool?.date_of_purchased === null
                        ? "-"
                        : tool?.date_of_purchased}
                    </td>
                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {tool?.currency === null ? "-" : tool?.currency.name}
                    </td>

                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {tool?.department === null
                        ? "-"
                        : tool?.department.department_name}
                    </td>
                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {tool?.category == null ? "-" : tool?.category?.name}
                    </td>
                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {tool?.manufacturer === null
                        ? "-"
                        : tool?.manufacturer.manufacturer_name}
                    </td>
                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {tool?.status_id == null
                        ? "-"
                        : tool?.status?.status_name}
                    </td>
                    <td
                      className="text-start"
                      onClick={() => {
                        HandleFragment(tool);
                      }}
                    >
                      {tool?.remark === null ? "-" : tool?.remark}
                    </td>
                    <td className="text-start">
                      {tool?.amount != 0 ? (
                        <>
                          {tool?.store?.is_permanent == 1 ? (
                            <div className="d-grid gap-3">
                              <button
                                className="form-save"
                                onClick={() => {
                                  HandleRequest();
                                  setDialog(tool);
                                }}
                              >
                                Request
                              </button>
                              <ModeEditOutlineOutlinedIcon
                                onClick={() => {
                                  HandleEditTool(true);
                                  setupdateTool(tool);
                                }}
                                sx={{
                                  color: "#52FFAC",
                                  "&:hover": {
                                    backgroundColor: "#fff",
                                    color: "#52FFAC",
                                    border: "1px solid #52FFAC",
                                    borderRadius: 25,
                                  },
                                }}
                              />
                            </div>
                          ) : (
                            ""
                          )}

                          {PM ? (
                            tool?.is_group_item == 1 ? (
                              <div></div>
                              //<div className="d-grid gap-3">
                              //  <button
                                //  className="form-save"
                               //   onClick={() => {
                               //     HandleRequest();
                               //     setDialog(tool);
                                //  }}
                               // >
                               //   Request
                               // </button>
                              //</div>
                            ) : (
                              ""
                            )
                          ) : SK ? (
                            tool?.created_by == userinfo?.id ? (
                              <div className="d-flex justify-content-center gap-2 align-items-center">
                                <ModeEditOutlineOutlinedIcon
                                  onClick={() => {
                                    HandleEditTool(true);
                                    setupdateTool(tool);
                                  }}
                                  sx={{
                                    color: "#52FFAC",
                                    "&:hover": {
                                      backgroundColor: "#fff",
                                      color: "#52FFAC",
                                      border: "1px solid #52FFAC",
                                      borderRadius: 25,
                                    },
                                  }}
                                />
                              </div>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        `${tool?.item_name} has no amount`
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {data?.length >= 2 ? (
            <div className="inventory-header-container">
              <button className="form-save " onClick={HandleConfirmRequest}>
                Batch Request
              </button>
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        <NoRecord />
      )}

      {registerTool ? (
        <RegisterTool
          modal={setRegisterTool}
          // data={updateTool}
          isFixed={true}
        />
      ) : confirmRequest ? (
        <ConfirmRequest
          assets={notRequest}
          data={data}
          modal={setConfirmRequest}
          isTools={false}
          isAsset={true}
        />
      ) : (
        ""
      )}
      {editAsset ? <EditAssets modal={setEditAsset} data={updateTool} /> : ""}
      {confirm ? (
        <StockDialog
          setDialog={setConfirm}
          projectId={setRequestedProject}
          dialog={`${dialog?.item_name} `}
          data={dialog}
          name="request"
          action={(e) => HandleToolRequest(e, dialog?.id)}
          setQuantity={setQuantity}
        />
      ) : (
        ""
      )}
      {count > 1 ? (
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
          className="p-2"
        />
      ) : (
        ""
      )}
    </div>
  );
}
