import { useStepContext } from "@mui/material";
import React, { useState } from "react";
import {
  useGetBatchesQuery,
  useGetOrderStageQuery,
  useGetOriginsQuery,
  useGetPaymentsModeQuery,
  useGetProjectQuery,
  useGetProjectsQuery,
  useGetSorcingStageQuery,
  useGetSupplierOriginsQuery,
  useGetSuppliersOrdersQuery,
  useGetSuppliersQuery,
} from "../../features/SCMApi";
export default function ReportFilter({
  setCountry,
  setSupplier,
  setProfit,
  project_id,
  setBatch,
  setProjectName,
  setStage,
  module,
}) {
  const { data: projects } = useGetProjectsQuery();
  const { data: suppliers } = useGetSuppliersOrdersQuery();
  const { data: countries } = useGetSupplierOriginsQuery();
  const { data: paymentmode } = useGetPaymentsModeQuery();
  const { data: sorcingStage } = useGetSorcingStageQuery();
  const { data: logsticStage } = useGetOrderStageQuery();
  const { data: batches } = useGetBatchesQuery(project_id);
  const { data: project } = useGetProjectQuery(project_id);
  setProjectName(project);
  

  return (
    <div className="d-flex align-items-center gap-3">
      <select
        className="report-filter-selector"
        onChange={(e) => setProfit(e.target.value)}
      >
        <option value="">Profit/Loss</option>
        <option value="profit">Profit</option>
        <option value="loss">Loss</option>
        <option value="breakeven">Break Even</option>
      </select>

      <select
        className="report-filter-selector"
        onChange={(e) => setCountry(e.target.value)}
      >
        <option value="" selected>
          Country
        </option>
        {countries?.data?.map((items) => (
          <option value={items?.id}>{items?.name}</option>
        ))}
      </select>
      <select
        className="report-filter-selector"
        onChange={(e) => setSupplier(e.target.value)}
      >
        <option value="" selected>
          Supplier
        </option>
        {suppliers?.data?.map((items) => (
          <option value={items?.id}>{items?.distributor_name}</option>
        ))}
      </select>
      <select className="report-filter-selector">
        <option value="" selected>
          Payment
        </option>
        {paymentmode?.data?.map((items) => (
          <option value={items?.id}>{items?.payment_mode}</option>
        ))}
      </select>
      <select
        onChange={(e) => setBatch(e.target.value)}
        className="report-filter-selector"
      >
        <option value="" selected>
          Batch
        </option>
        {batches?.data?.map((items) => (
          <option value={items?.id}>{items?.batch_name}</option>
        ))}
      </select>
      <select
        onChange={(e) => setStage(e.target.value)}
        className="report-filter-selector"
      >
        <option value="" selected>
          Stage
        </option>
        {module === "sourcing"
          ? sorcingStage?.data?.map((items) => (
              <option value={items?.id}>{items?.status_name}</option>
            ))
          : ""}
        {module === "logistics"
          ? logsticStage?.data?.map((items) => (
              <option value={items?.id}>{items?.status_name}</option>
            ))
          : ""}
      </select>
    </div>
  );
}
