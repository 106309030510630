import NavBar from "../../Layout/NavBar";
import "./Faq.css";
import SearchIcon from "@mui/icons-material/Search";
import faqVideos1 from "./../../assets/videos/video_2.mp4";
import thumbnail from "./../../assets/images/thumbnail.png";
import { Pagination } from "@mui/material";
import { Breadcrumb } from "react-bootstrap";
import NoRecord from "./../../components/common/NoRecord";
import Faq from "./Faq";
import {
  useGetFaqVideosQuery,
  useUploadFaqVideoMutation,
} from "../../features/SCMApi";
import { IMG_BASE_URL } from "../../api/endPoint";
import { useState } from "react";
import RespondFaqVideo from "../../components/home/RespondFaqVideo";
import usePagination from "../../components/Pagination/Pagination";

export default function FaqVideos(props) {
  const { data: faqVideos } = useGetFaqVideosQuery();
  const [responedToFaqModal, setRespondToFaqModal] = useState(false);
  let [page, setPage] = useState(1);
  const PER_PAGE = 8;
  const count = Math.ceil(faqVideos?.data?.length / PER_PAGE);
  const _DATA = usePagination(faqVideos?.data, PER_PAGE);


  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <Faq title={"FAQ Videos"} navlink={`/Faq/FaqVideos`}>
      {responedToFaqModal ? (
        <RespondFaqVideo ToggleModal={setRespondToFaqModal} />
      ) : (
        <></>
      )}
      <div className="faq-videos-container">
        <div className="faq-top-section">
          <div className="faq-search-bar">
            <SearchIcon />
            <input type="search" className="search-input" name="" id="" />
          </div>
          {faqVideos?.data ? (
            Object.keys(faqVideos?.data).length === 0 ? (
              <NoRecord />
            ) : (
              <div className="video-container">

                {_DATA?.currentData().map((item) => (
                  <div class="video-container ratio ratio-16x9">
                    <label class="title-label">
                      {/* {faqVideos?.data[item].title} */}
                    </label>
                    <video
                      controls
                      // src={faqVideos1}
                      title="Video of Bucky Bunny"
                      src={`${IMG_BASE_URL}/${item?.video}`}
                      poster={`${IMG_BASE_URL}/${item?.thumbnail}`}
                      // poster={thumbnail}
                    ></video>
                  </div>
                ))}
              </div>
            )
          ) : (
            <NoRecord />
          )}
        </div>
        <div className="faq-pagination-container">
          {count > 1 ? (
            <Pagination
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                margin: "5px",
              }}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </Faq>
  );
}
