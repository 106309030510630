import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import LaunchIcon from "@mui/icons-material/Launch";
import RMAReportModal from "../DeliveredItemModals/RMAReportModal";

import CheckProjectPermission from "../../../common/checkPermission/CheckProjectPermission";
import {
  useGetUserInfoQuery,
  useGetProjectQuery,
  useGetProjectRolePermissionQuery,
} from "../../../../features/SCMApi";
import DeliveredItemDetailStagesContainer from "./DeliveredItemDetailStagesContainer";
import SendToInventoryModal from "../DeliveredItemModals/SendToInventoryModal";

const Stages = styled(Paper)(({ theme }) => ({
  elevation: "0px",
  height: "120px",
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  marginTop: "20px",
  border: "1px solid #AEB8C9",
  borderRadius: "6px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const DeliveredItemDetailStages = (props) => {
  //   //DeliveredItem Stages Modal states
  const [ReportRMAModal, setReportRMAModal] = React.useState(false);
  const [sendToInventory, setSendToInventory] = React.useState(false);

  // DeliveredItem Stages Modal handlers
  function RMAReportModalHandler() {
    setReportRMAModal(true);
  }
  function SendToInventoryModalHandler() {
    setSendToInventory(true);
  }

  let importNames = [{ name: "Send to Inventory" }, { name: "Report RMA" }];

  const handleStagesButtonClick = (e, name) => {
    return name === "Report RMA"
      ? RMAReportModalHandler()
      : SendToInventoryModalHandler();
  };

  const { data: userinfo } = useGetUserInfoQuery();
  const { data: projectmembers, isSuccess } = useGetProjectQuery(
    props?.projectId
  );
  const { data: roleRoles } = useGetProjectRolePermissionQuery(
    props?.projectId
  );
  return (
    <>
      <DeliveredItemDetailStagesContainer>
        <Stages elevation={0}>
          <div className="rmaImportExportContainer">
            {importNames?.map((item) => (
              <>
                <Button
                  sx={{
                    width: "100%",
                    marginTop: "10px",
                    fontSize: "16px",
                    fontWeight: "400",
                    color: CheckProjectPermission(
                      "missed_item_update_status",
                      projectmembers,
                      userinfo,
                      roleRoles
                    )
                      ? "#353945"
                      : "#aeb8c9",
                    justifyContent: "flex-start",
                    "&:hover": {
                      color: "#186569",
                    },
                  }}
                  onClick={(e) => handleStagesButtonClick(e, item?.name)}
                  disabled={
                    CheckProjectPermission(
                      "missed_item_update_status",
                      projectmembers,
                      userinfo,
                      roleRoles
                    )
                      ? false
                      : true
                  }
                >
                  <LaunchIcon className="rmaExportImportLinkIcon" />
                  <span className="forexApprovalStageLabels">{item?.name}</span>
                </Button>
              </>
            ))}
          </div>
        </Stages>
      </DeliveredItemDetailStagesContainer>
      {ReportRMAModal && (
        <RMAReportModal
          modal={setReportRMAModal}
          batchId={props?.stageData?.id}
          projectId={props?.stageData?.project_id}
          successMessage={props?.successMessage}
          message={props?.message}
          items={props?.stageData?.items}
        />
      )}
      {sendToInventory && (
        <SendToInventoryModal
          modal={setSendToInventory}
          batchId={props?.stageData?.id}
          projectId={props?.stageData?.project_id}
          successMessage={props?.successMessage}
          message={props?.message}
          items={props?.stageData?.items}
          name={"batch"}
        />
      )}
    </>
  );
};

export default DeliveredItemDetailStages;
