import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Close from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useAssetsReturnBatchMutation,
  useItemsReturnBatchMutation,
  useToolsReturnMutation,
  useGetUserInfoQuery,
  useAssetsConsumeBatchMutation,
} from "../../../features/SCMApi";
import { IMG_BASE_URL } from "../../../api/endPoint";
import ErrorMessage from "../../Payment/ErrorMessage";

export default function BatchReturn(props) {
  function HandleCancel() {
    props.modal(false);
  }

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(props?.data);
  const [errordisplay, setErrorDisplay] = useState(false);
  const [error, setError] = useState();
  const params = useParams();
  const { data: userinfo } = useGetUserInfoQuery();

  const [ToolsReturn] = useToolsReturnMutation();
  const [ItemsReturn] = useItemsReturnBatchMutation();
  const [AssetsReturn] = useAssetsReturnBatchMutation();
  const [BatchConsume] = useAssetsConsumeBatchMutation();

  useEffect(() => {
    setErrorDisplay(false);
  }, [formData]);

  function handleBatchRequest(e, items, i) {
    const value = parseInt(e.target.value);
    const updatedItems = [...formData];
    updatedItems[i] = {
      ...items,
      current_return_amount: value,
      is_returned: true,
    };
    setFormData(updatedItems);
  }

  const removeForm = (id) => {
    const filteredArray = formData.filter((obj) => obj.id !== id);
    setFormData(filteredArray);
  };

  const isFormValid = () => {
    return formData.every((item) =>
      item.current_return_amount > 0 && item.returned_amount
        ? item.current_return_amount <=
          item.requested_amount - item.returned_amount
        : item.current_return_amount <= item.requested_amount - 0
    );
  };

  const transformData = () => {
    let data = formData
      .map((item) => `${item.id}:${item.current_return_amount}`)
      .join(", ");
    let finalData = data.split("[");
    const keyValuePairs = finalData[0].split(", ");

    const result = {};
    keyValuePairs.forEach((pair) => {
      const [key, value] = pair.split(":");
      result[key] = parseInt(value);
    });

    return result;
  };

  const batchReturn = {
    id: params?.id,
    returnedAmounts: transformData(),
    requested_by: userinfo?.id,
  };

  const HandleSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();
    props?.is_items == true
      ? ItemsReturn(batchReturn)
          .unwrap()
          .then((response) => {
            props.modal(false);
            setLoading(true);
          })
          .catch((error) => {
            setError(error?.data?.message);
            setErrorDisplay(true);

            setLoading(false);
          })
      : ToolsReturn(batchReturn)
          .unwrap()
          .then((response) => {
            props.modal(false);
            setLoading(true);
          })
          .catch((error) => {
            setError(error?.data?.message);
            setErrorDisplay(true);

            setLoading(false);
          });
  };
  const HandleSubmitAssets = async (e) => {
    setLoading(true);
    e.preventDefault();
    AssetsReturn(batchReturn)
      .unwrap()
      .then((response) => {
        props.modal(false);
        setLoading(true);
      })
      .catch((error) => {
        setError(error?.data?.message);
        setErrorDisplay(true);

        setLoading(false);
      });
  };

  const HandleConsume = async (e) => {
    setLoading(true);
    e.preventDefault();
    BatchConsume(batchReturn)
      .unwrap()
      .then((response) => {
        props.modal(false);
        setLoading(false);
      })
      .catch((error) => {
        setError(error?.data?.message);
        setErrorDisplay(true);

        setLoading(false);
        console.log(error, "erorrrrr");
      });
  };

  let count = 0;

  return (
    <div id="mymodal" className="modal" onClick={HandleCancel}>
      <div className="modal-content-md" onClick={(e) => e.stopPropagation()}>
        <div className="create-project-modal-title">
          <h5>
            {" "}
            {props?.is_consumed
              ? "batch consume"
              : props?.isAssets
                ? "Assets Batch Return"
                : props?.is_items
                  ? "Item Batch Return"
                  : "Tool Batch Return"}
          </h5>

          <Close onClick={HandleCancel} />
        </div>

        {errordisplay && <ErrorMessage Message={error} />}

        <table style={{ width: "100%" }}>
          <thead className="stock-table-header">
            <tr>
              <th
                className="stock-table-header-text text-center"
                style={{ width: "3%" }}
              >
                No
              </th>
              <th
                className="stock-table-header-text text-center"
                style={{ width: "15%" }}
              >
                Return Amount
              </th>
              <th
                className="stock-table-header-text text-center"
                style={{ width: "10%" }}
              ></th>
              <th
                className="stock-table-header-text text-center"
                style={{ width: "10%" }}
              >
                Item Name
              </th>
              <th
                className="stock-table-header-text text-center"
                style={{ width: "10%" }}
              >
                Requested Amount
              </th>
              <th
                className="stock-table-header-text text-center"
                style={{ width: "10%" }}
              ></th>
            </tr>
          </thead>
          <tbody>
            {formData?.map((items, index) => (
              <tr className="stock-table-row" key={items.id}>
                <td className="text-center">{++count}</td>
                <td className="text-center">
                  <input
                    onChange={(e) => handleBatchRequest(e, items, index)}
                    type="number"
                    className="store-amount-input"
                    value={items.current_return_amount || ""}
                    min="1"
                    // max={items?.requested_amount}
                    max={
                      items?.returned_amount
                        ? items?.current_consum_amount
                          ? items?.requested_amount -
                            (items?.returned_amount +
                              items?.current_consum_amount)
                          : items?.requested_amount - items?.returned_amount
                        : items?.requested_amount
                    }
                  />
                </td>
                <td>
                  <img
                    className="stock-img"
                    src={`${IMG_BASE_URL}${items?.image}`}
                    alt=""
                  />
                </td>
                <td className="text-start">{items?.item_name}</td>
                <td className="text-center">
                  {items?.returned_amount
                    ? items?.current_consum_amount
                      ? items?.requested_amount -
                        (items?.returned_amount + items?.current_consum_amount)
                      : items?.requested_amount - items?.returned_amount
                    : items?.requested_amount}
                </td>
                <td className="text-center">
                  {formData.length > 1 && (
                    <DeleteIcon
                      onClick={() => removeForm(items?.id)}
                      style={{ color: "red", fontSize: 20 }}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pi-modal-footer d-flex justify-content-center">
          <button onClick={HandleCancel}>Cancel</button>
          <button
            disabled={!isFormValid() || loading}
            type="submit"
            className={`form-save ${loading ? "bg-secondary text-white" : ""}`}
            onClick={(e) =>
              props?.is_consumed
                ? HandleConsume(e)
                : props?.isAssets
                  ? HandleSubmitAssets(e)
                  : HandleSubmit(e)
            }
          >
            {loading ? "Loading..." : props?.is_consumed ? "consume" : "Return"}
          </button>
        </div>
      </div>
    </div>
  );
}
