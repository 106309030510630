import React, { useState } from "react";
import "./login.css";
import axios from "axios";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import googleLogo from "./GoogleLogo.png";
import microsoftLog from "./MicrosoftLogo.png";
import IeLogo from "./IeLogo.png";
import {
  LOGIN_REDIRECT_URL,
  LOGIN_REDIRECT_URL_GOOGLE,
} from "../../api/endPoint";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const loginButtonStyle = {
  marginBottom: "15px",
  maxHeight: "36px",
  width: "60%",
  textTransform: "none",
  borderRadius: "25px",
  color: "#fff",
  fontSize: "14px",
  fontWeight: "normal",
  backgroundColor: "#064b4f",
  "&:hover": {
    backgroundColor: "#166569",
  },
  justifyContent: "flex-start",
};
const svgStyle = {
  height: "35px",
  width: "35px",
  backgroundColor: "#fff",
  border: "1px solid #ecec",
  marginLeft: "-17px",
};

export const Login = () => {
  const [rolling, setRolling] = useState(null);

  const loginAuth = () => {
    axios
      .get(`${LOGIN_REDIRECT_URL}`)
      .then((res) => {
        window.location.href = res?.data;
        
      })
      .catch((err) => console.log(err));
  };

  const loginAuthGoogle = () => {
    axios
      .get(`${LOGIN_REDIRECT_URL_GOOGLE}`)
      .then((res) => {
        window.location.href = res?.data;
        
      })
      .catch((err) => console.log(err));
  };

  const google = "Google\xa0\xa0\xa0";

  return (
    <div className="block">
      <div className="row h-100 login-page-main-container">
        <div className="loginLeft col-md-8 bg-success login-window-view">
          <div className="systemInfo bg-white">
            <span className="loginHeading">Supply Chain Management</span>
            <p className="loginDetail">
              It is a system to manages or handles the entire workflow of the
              supply chain from order to delivery. It comprises of eight
              significant modules: Project, Product, Supplier, Sourcing,
              Payment, Logistics, Client, and Document.
            </p>
          </div>
        </div>
        <div className="col-md-4 bg-white">
          <div className="loginPageContainer login-mobile-view">
            <img
              src={IeLogo}
              alt="ie logo"
              loading="lazy"
              className="loginLogo"
            />

            <Button
              variant="contained"
              disableElevation
              size="medium"
              sx={loginButtonStyle}
              onClick={(e) => loginAuthGoogle(e)}
            >
              <Stack direction="row" alignItems="center" gap={9}>
                <Avatar sx={svgStyle} src={googleLogo} />
                <Typography variant="body1">{google}</Typography>
              </Stack>
            </Button>
            <Button
              variant="contained"
              disableElevation
              size="medium"
              sx={loginButtonStyle}
              onClick={(e) => loginAuth(e)}
            >
              <Stack direction="row" alignItems="center" gap={9}>
                <Avatar sx={svgStyle} style={rolling} src={microsoftLog} />
                <Typography variant="body1">Microsoft</Typography>
              </Stack>
            </Button>
            <span className="loginFooter">GET THE JOB DONE</span>
          </div>
        </div>
      </div>
    </div>
  );
};
