import React from "react";

export default function EditBatchStatus(props) {
  function handleSubmit(e) {
    e.preventDefault();
    props?.editAction();
  }
  return (
    <div>
      <div
        id="Modal"
        className="edit-mini-modal"
        onClick={(e) => {
          props?.ToggleModal(false);
          
        }}
      >
        <form
          onSubmit={(e) => handleSubmit(e)}
          className="edit-mini-large-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="edit-dialog-info">Edit Status</div>
          <div className="edit-modal-divider"></div>
          <div className="row gap-3 centered-content-column">
            <div className="col-12  ">
              <div className="">
                <div className="edit-input-label mb-1">Status</div>
                <textarea
                  rows={8}
                  type={"text"}
                  className="term-values "
                  required
                  onChange={(e) => props?.setters(e.target.value)}
                  // defaultValue={solution_name}
                  //   placeholder={props?.current}
                  //   className="select-option"
                ></textarea>
              </div>
            </div>
          </div>
          <div className="confirm-dialog-controller">
            <button
              type="cancel"
              className="form-cancel"
              onClick={(e) => {
                props?.ToggleModal(false);
              }}
            >
              Cancel
            </button>
            <button
              className="form-save"
              type={"submit"}
              // onClick={(e) => {
              //   handleSubmit();
              // }}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
