import React from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import IconButton from "@mui/material/IconButton";
import EditEtdModal from "../../RMA/RmaModals/EditEtdModal";
import CheckProjectPermission from "../../../common/checkPermission/CheckProjectPermission";
import {
  useGetUserInfoQuery,
  useGetProjectQuery,
  useGetProjectRolePermissionQuery,
} from "../../../../features/SCMApi";

const Item = styled(Paper)(({ theme }) => ({
  height: "90px",
  color: theme.palette.text.secondary,
  alignItems: "center",
  border: "1px solid #AEB8C9",
}));

const MissedItemETD = (props) => {
  const [updatedEtd, setUpdatedEtd] = React.useState(null);

  //Update ETD toggle
  const [showUpdateEtdModal, toggleUpdateEtdModal] = React.useState(false);
  const toggleUpdateEtdHandler = (e) => {
    toggleUpdateEtdModal(!showUpdateEtdModal);
  };
  const { data: userinfo } = useGetUserInfoQuery();
  const { data: projectmembers, isSuccess } = useGetProjectQuery(
    props?.data?.project_id
  );
  const { data: roleRoles } = useGetProjectRolePermissionQuery(
    props?.data?.project_id
  );

  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={2}
        xl={2}
        xxl={2}
        marginRight={{ xs: "16px", sm: "0px", md: "0px" }}
        marginLeft={{ xs: "16px", sm: "0px", md: "0px" }}
      >
        <Item elevation={0}>
          <div className="origin-group rmaHeading">
            <div
              className="rmaValue"
              style={{ fontSize: "16px", marginTop: "10px" }}
            >
              {/* June 12, 2022
               */}
              {updatedEtd !== null
                ? updatedEtd
                : props?.data?.etd !== null
                ? props?.data?.etd
                : "Not registered"}
            </div>
            <div
              className="origin-label"
              style={{ fontSize: "14px", marginTop: "10px" }}
            >
              ETD
            </div>

            {CheckProjectPermission(
              "missed_item_update_etd",
              projectmembers,
              userinfo,
              roleRoles
            ) ? (
              <IconButton
                variant="text"
                sx={{
                  color: "#fff",
                  backgroundColor: "#064b4f",
                  "&:hover": {
                    backgroundColor: "#186569",
                    border: "1px solid #186569",
                  },
                  marginTop: "-10px",
                  marginLeft: "82%",
                  width: "25px",
                  height: "25px",
                }}
                onClick={toggleUpdateEtdHandler}
              >
                <ModeEditOutlinedIcon sx={{ fontSize: "20px" }} />
              </IconButton>
            ) : (
              <></>
            )}
          </div>
        </Item>
        <EditEtdModal
          show={showUpdateEtdModal}
          handleUpdateEtdModal={toggleUpdateEtdHandler}
          successMessage={props?.setSuccessMessage}
          message={props?.setMessage}
          name="MissedItem"
          setEtdDate={setUpdatedEtd}
          missedItemId={props?.data?.id}
          previousData={updatedEtd !== null ? updatedEtd : props?.data?.etd}
        />
      </Grid>
    </>
  );
};

export default MissedItemETD;
