import React, { useState } from 'react';
import ReactDOM from 'react-dom';
function PrfRequestComponent({ isOpen, onClose, orders }) {

      const [quantity, setQuantity] = useState(0);
      const handleQuantityChange = (event) => {
        setQuantity(event.target.value);
      };
      const handleClose = () => {
        setQuantity(0);
        onClose();
      };
    
      return (
    // isOpen ? ( 
                <div className="fixed inset-0 z-50 flex items-center justify-center">
                    <div className="absolute inset-0 bg-gray-900 opacity-50" onClick={handleClose}></div>
                        <div className="relative bg-white p-8 w-1/2">
                            <h2 className="text-2xl font-bold mb-4">Order Details</h2>
                            <div>
                            <label htmlFor="quantity" className="block mb-2">
                                Quantity:
                            </label>
                            <select
                                id="quantity"
                                name="quantity"
                                value={quantity}
                                onChange={handleQuantityChange}
                                className="w-full p-2 border border-gray-300 rounded"
                            >
                                {[...Array(orders.quantity).keys()].map((value) => (
                                <option key={value} value={value + 1}>
                                    {value + 1}
                                </option>
                                ))}
                            </select>
                            </div>
                            <div className="mt-4">
                            <button
                                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mr-2"
                                onClick={handleClose}
                            >
                                Close
                            </button>
                            <button className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
                                Save
                            </button>
                            </div>
                          </div>
                </div>
                )
                //  : null)
    };
    
export default PrfRequestComponent