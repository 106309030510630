import { Tooltip } from "@mui/material";
import React from "react";
import {
  useGetManufacturersQuery,
  useGetMappingsQuery,
} from "../../features/SCMApi";
export default function MappingCountry(props) {
  const {
    data: mappings,
    isError,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetMappingsQuery();

  return (
    <div className="">
      <>
        {mappings?.data?.countries
          ?.filter((items) => {
            if (props.search == "") {
              return items;
            } else if (
              items.name?.toLowerCase().includes(props.search.toLowerCase())
            ) {
              return items;
            }
          })
          .map((items) =>
            items?.origin_supplier.length != 0 ? (
              <div className="mapping-card-container primary pb-5 mb-3">
                <div>
                  <div className="mapping-card-header mt-4 ms-5">
                    {items.name}
                  </div>
                  <div className="row products_padding">
                    {items?.origin_supplier?.map((product) => (
                      <div class="col-sm-3 col-md-2 me-3">
                        <div class="card mapping">
                          <Tooltip title={product?.distributor_name}>
                            <div>
                              <p class="h5 text-center my-2">
                                {product?.distributor_name?.length >= 22
                                  ? product?.distributor_name.slice(0, 10) +
                                    "..."
                                  : product?.distributor_name}
                              </p>
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )
          )}
      </>
    </div>
  );
}
