import * as React from "react";
import Box from "@mui/material/Box";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function Checkboxes(props) {
  const advancePaid = props?.checkedStatus;
  const [state, setState] = React.useState({
    isPaid: advancePaid === 1 ? true : false,
  });

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
    setChecked1(true);
    props?.isPaid(event.target.checked);
  };

  const { isPaid } = state;
  const error = [isPaid].filter((v) => v).length !== 2;
  const [checked1, setChecked1] = React.useState(false);

  return (
    <>
      {props?.data?.map((dataName) => (
        <Box sx={{ display: "flex" }}>
          <FormControlLabel
            sx={{
              color: "#aeb8c9",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              ".MuiFormControlLabel-label": checked1 === true && {
                color: "#186569",
              },
            }}
            control={
              <Checkbox
                sx={{
                  color: "#aeb8c9",
                  "&.Mui-checked": {
                    color: advancePaid === 1 ? "#aeb8c9" : "#186569",
                  },
                }}
                checked={isPaid}
                onChange={(e) => handleChange(e)}
                disabled={advancePaid === 1 ? true : false}
                name="isPaid"
              />
            }
            label={dataName}
          />
        </Box>
      ))}
    </>
  );
}
