import React, { useState } from "react";
import ForexSideBar from "../../../components/Payment/ForexApproval/PaymentSideBar";
import NoRecord from "../../common/NoRecord";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import RecommendIcon from "@mui/icons-material/Recommend";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import ReactPaginate from "react-paginate";
import { NavLink, useParams } from "react-router-dom";
import {
  useGetOrderPoItemQuery,
  useGetPoPRFQuery,
  useGetProjectPRFQuery,
  useGetProjectQuery,
  useGetProjectRolePermissionQuery,
  useGetUserInfoQuery,
} from "../../../features/SCMApi";
import { Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import CheckProjectPermission from "../../common/checkPermission/CheckProjectPermission";
import CreatePrf from "../CreatePrf";
import SuccessMessage from "../../Payment/SuccessMessage";
export default function PrfIndexContainer() {
  const params = useParams();
  const { data: projectPrf } = useGetPoPRFQuery(params?.id);
  const { data: poprf } = useGetProjectPRFQuery(params?.project_id);
  // let PoData = "";
  let PoData = poprf?.data?.filter((items) => params?.id == items?.id);


  const { data: projectmembers, isSuccess } = useGetProjectQuery(
    params?.project_id
  );
  const { data: roleRoles } = useGetProjectRolePermissionQuery(
    params?.project_id
  );
  const { data: userinfo } = useGetUserInfoQuery();
  const [createPrf, setCreatePrf] = useState();
  function handleCreatePrf() {
    setCreatePrf(true);
  }

  function date(dates) {
    const dateStr = dates;
    const date = new Date(dateStr);
    const options = {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    

    return formattedDate;
  }

  const [successMessage, setSuccessMessage] = React.useState(false);
  const [message, setMessage] = React.useState("");
  successMessage &&
    setTimeout(() => {
      setSuccessMessage(false);
    }, 3000);
  return (
    <ForexSideBar>
      {CheckProjectPermission(
        "create_order",
        projectmembers,
        userinfo,
        roleRoles
      ) ? (
        <div className="create-project-btn mt-3" onClick={handleCreatePrf}>
          <AddIcon />
          Create PRF
        </div>
      ) : (
        ""
      )}
      {successMessage === true ? (
        <SuccessMessage
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            backgroundColor: "#52ffab96",
            marginRight: "10px",
            width: "362px",
          }}
          className={"success_messages_text_style_mobile_View"}
          Message={message}
        />
      ) : (
        <></>
      )}
      {projectPrf?.data?.length != 0 ? (
        <div className="order-card-container mt-3">
          {projectPrf?.data?.map((items) => (
            <NavLink to={`prfdetail/${items?.id}`} className="order-card">
              <div className="order-info">
                <div className="order-project">
                  <div>
                    <div className="project-name">
                      <Tooltip title={items?.requested_by?.name}>
                        <div className="project-name">
                          {items?.requested_by?.name?.length >= 20
                            ? items?.requested_by?.name?.slice(0, 20) + "..."
                            : items?.requested_by?.name}
                        </div>
                      </Tooltip>
                    </div>

                    <div className="project-label">Requested By</div>
                  </div>

                  <div>
                    <div className="project-name">
                      {items?.order?.order_type}
                    </div>
                    <div className="project-label">Order Type</div>
                  </div>
                </div>
              </div>
              <div className="divider"></div>
              <div className="order-supplier">
                <div className="order-supplier">{date(items?.created_at)}</div>
              </div>
            </NavLink>
          ))}
        </div>
      ) : (
        <NoRecord />
      )}
      {/* <div className="mt-3">
        {count > 1 ? (
          // <ReactPaginate
          //   previousLabel={"<"}
          //   nextLabel={">"}
          //   pageCount={count}
          //   onPageChange={handleChange}
          //   breakClassName={"page-item"}
          //   breakLinkClassName={"page-link"}
          //   containerClassName={"pagination"}
          //   pageClassName={"page-item"}
          //   pageLinkClassName={"page-link"}
          //   previousClassName={"page-item"}
          //   previousLinkClassName={"page-link"}
          //   nextClassName={"page-item"}
          //   nextLinkClassName={"page-link"}
          //   activeClassName={"active"}
          // />
        ) : (
          ""
        )}
      </div> */}
      {createPrf ? (
        <CreatePrf
          order_id={params?.id}
          modal={setCreatePrf}
          PoData={PoData && PoData[0]}
          message={setMessage}
          successMessage={setSuccessMessage}

          // setMessages={setMessages}
        />
      ) : (
        ""
      )}
    </ForexSideBar>
  );
}
