import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import AnswerFaqQuestions from "./AnswerFaqQuestions";
import VideoFaq from "./VideoFaq";

export default function RespondFaqVideo(props) {
  function HandleCancel() {
    props?.ToggleModal(false);
  }
  return (
    <div id="myModal" className="modal" onClick={HandleCancel}>
      <div className="modal-content-md" onClick={(e) => e.stopPropagation()}>
        <div className="create-project-modal-title">
          <div>Respond</div>

          <div className="close-modal-button" onClick={(e) => HandleCancel()}>
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.4612 5.78721L19.8657 7.19175L7.46095 19.5965L6.0564 18.192L18.4612 5.78721ZM7.17507 5.51376L20.3505 18.6891L18.8589 20.1807L5.68351 7.00531L7.17507 5.51376Z"
                fill="#353945"
              />
            </svg>
          </div>
        </div>

        <Tabs
          defaultActiveKey="UploadVideo"
          id="uncontrolled-tab-example"
          className="d-flex justify-content-center bg-white gap-5"
        >
          <Tab eventKey="UploadVideo" title="Upload Video" className="mb-3">
            <VideoFaq ToggleModal={props?.ToggleModal} />
          </Tab>

          <Tab eventKey="AnswerFAQ" title="Answer FAQ " className="mb-3">
            <AnswerFaqQuestions ToggleModal={props?.ToggleModal} />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}
