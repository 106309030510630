import * as React from "react";
import IconButton from "@mui/material/IconButton";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import DeletePaymentTrackingModal from "./Modal/DeletePaymentTrackingModal";

const DeletePaymentButton = (props) => {
  const [deleteData, setDeleteData] = React.useState({});

  //Check payment  toggle
  const [showDeletePaymentModal, toggelDeletePaymentModal] =
    React.useState(false);
  const toggleDeletePaymentHandler = (e) => {
    toggelDeletePaymentModal(!showDeletePaymentModal);
  };

  return (
    <>
      <IconButton
        variant="text"
        size="small"
        disabled={props?.disabled}
        sx={{
          backgroundColor: props?.style?.backgroundColor
            ? props?.style?.backgroundColor
            : "#fff",
          color: props?.style?.color ? props?.style?.color : "#064B4F",
          "&:hover": {
            backgroundColor: "#186569",
            color: "#fff",
          },
        }}
        onClick={(e) => (
          (setDeleteData({
            no: props?.deleteData?.no,
            vendor: props?.deleteData?.vendor,
            product: props?.deleteData?.product,
            description: props?.deleteData?.description,
            bank: props?.deleteData?.bank,
            expectedDate: props?.deleteData?.expectedDate,
            amount: props?.deleteData?.amount,
            wayOfPayment: props?.deleteData?.wayOfPayment,
          }),
          1),
          toggleDeletePaymentHandler()
        )}
      >
        <CheckOutlinedIcon sx={{ fontSize: "14px" }} />
      </IconButton>
      <DeletePaymentTrackingModal
        receivable={props?.receivable}
        show={showDeletePaymentModal}
        handleDeleteModal={toggleDeletePaymentHandler}
        data={props?.deleteData}
        successMessage={props?.successMessage}
        message={props?.message}
        width={props?.style?.width}
        //   refreshe={useGetSettingClientQuery}
      />
    </>
  );
};

export default DeletePaymentButton;
