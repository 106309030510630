import React from "react";
import { useState } from "react";
import {
  useAddInventoryTypeSettingMutation,
  useAddToolMutation,
  useGetCurrenciesQuery,
  useGetItemCategorysQuery,
  useGetItemTypeQuery,
  useGetManufacturersQuery,
  useGetProductsQuery,
  useGetProjectsQuery,
  useGetStatusQuery,
  useGetStoreQuery,
  useGetToolTypeQuery,
  useGetUnitOfMeasurementQuery,
  useGetUserInfoQuery,
  useInventoryUpdateMutation,
} from "../../../features/SCMApi";
import attachmentIcon from "../../../assets/SVG/attach.svg";
import { Category } from "@mui/icons-material";
import axios from "axios";
import { API_BASE_URL } from "../../../api/endPoint";
import Close from "@mui/icons-material/Close";

export default function EditInventory(props) {
  const [UpdateInventory, res] = useInventoryUpdateMutation();
  const [tool_type_id, setInventory_type] = useState(
    props?.data?.inventory_type_id
  );
  const [item_name, setItemName] = useState(props?.data?.item_name);
  const [model, setModel] = useState(props?.data?.model);
  const [serial_number, setSerialNumber] = useState(props?.data?.serial_number);
  const [serial_number_edit, setSerialNumberEdit] = useState(serial_number);
  const [item_number, setItemNumber] = useState(props?.data?.item_number);
  const [price, setPrice] = useState(props?.data?.price);
  const [price_edit, setPriceEdit] = useState(price);
  const [currency_id, setCurrency] = useState(props?.data?.currency_id);
  const [currency_edit, setCurrencyEdit] = useState(currency_id);
  const [pi, setpi] = useState(props?.data?.pi);
  const [store_id, setStore] = useState(props?.data?.store_id);
  const [department_id, setDepartment] = useState(props?.data?.department_id);
  const [category_id, setCategory] = useState(props?.data?.category_id);
  const [warranty, setWarranty] = useState(props?.data?.warranty);
  const [manufacturer_id, setManufacturer] = useState(
    props?.data?.manufacturer_id
  );
  const [image, setImage] = useState(props?.data?.image);
  const [status_id, setStatus] = useState(props?.data?.status_id);
  const [date_of_purchased, setDateofPurchased] = useState(
    props?.data?.date_of_purchased
  );
  const [amount, setAmount] = useState(props?.data?.amount);
  const [amount_edit, setAmountEdit] = useState(amount);
  const [uom_id, setUnit] = useState(props?.data?.uom_id);
  const [is_group_item, setGroup] = useState(
    props?.data?.is_group_item == 0 ? false : true
  );
  const [is_consumable, setConsumable] = useState(
    props?.data?.is_consumable == 0 ? false : true
  );
  const [is_fixed_asset, setFixedAsset] = useState(
    props?.data?.is_fixed_asset == 0 ? false : true
  );
  const [description, setDescription] = useState(props?.data?.description);
  let project_id = null;
  const { data: currency } = useGetCurrenciesQuery();
  const { data: store } = useGetStoreQuery();
  const { data: Departments } = useGetProductsQuery();
  const { data: ItemCategory } = useGetItemCategorysQuery();
  const { data: manufacturer } = useGetManufacturersQuery();
  const { data: itemstype } = useGetItemTypeQuery();
  const { data: status } = useGetStatusQuery();
  const { data: unit } = useGetUnitOfMeasurementQuery();
  const { data: userinfo } = useGetUserInfoQuery();
  let created_by = userinfo?.id;
  const handleChange = (e) => {
    const img = {
      name: e?.target?.files[0].name,
      data: e?.target?.files[0],
    };
    setImage(img);
  };
  const handleChangePi = (e) => {
    const pis = {
      namepi: e?.target?.files[0].name,
      datapi: e?.target?.files[0],
    };
    setpi(pis);
  };
  const handleChangeWar = (e) => {
    const warrantys = {
      namewar: e?.target?.files[0].name,
      datawar: e?.target?.files[0],
    };
    setWarranty(warrantys);
  };
  store?.data?.map((items) =>
    items.id == store_id ? (project_id = items?.project_id) : null
  );
  let inventory = {
    id: props?.data?.id,
    inventory_type_id: tool_type_id,
    item_name,
    model,
    serial_number,
    item_number,
    price,
    currency_id,
    pi_document: pi?.datapi,
    store_id,
    department_id,
    category_id,
    manufacturer_id,
    image: image?.data,
    warranty_document: warranty?.datawar,
    status_id,
    date_of_purchased,
    description,
    amount,
    uom_id,
    is_group_item,
    is_consumable,
    is_fixed_asset,
    project_id,
    created_by,
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    // UpdateInventory(inventory)
    //   .unwrap()
    //   .then((response) => {
    
    //     props.modal(false);
    //   })
    //   .then((error) => {
    
    //   });
    axios
      .post(
        `${API_BASE_URL}/scm/stock/inventory/update/${props?.data?.id}`,
        inventory,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(function (response) {
        
        props.modal(false);
      })
      .catch(function (error) {
        
      });
  };
  function HandleCancel() {
    props.modal(false);
  }

  function HandleCancel() {
    props.modal(false);
  }

  
  const [is_consumableform, setForms] = useState(false);
  
  const CheckBoxHandelGroup = () => {
    setGroup(!is_group_item);
  };
  const CheckBoxHandelConsumable = () => {
    setConsumable(!is_consumable);
    setForms(!is_consumableform);
  };
  const CheckBoxHandelFixedAsset = () => {
    setFixedAsset(!is_fixed_asset);
  };

  
  return (
    <div className="pi-modal" onClick={() => HandleCancel()}>
      <div className="pi-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="pi-modal-header">
          <div className="d-flex justify-content-between">
            <h5>Edit Inventory</h5>
            <Close onClick={HandleCancel} />
          </div>
        </div>
        <hr />
        <form className="pi-form" onSubmit={HandleSubmit}>
          <div className="d-grid gap-3">
            <div className="d-flex gap-0 align-items-baseline justify-content-center mt-2">
              <label className="d-flex gap-2 ">
                <input
                  defaultChecked={is_consumable}
                  type="checkbox"
                  className="stock-checkmark"
                  onChange={CheckBoxHandelConsumable}
                />
                <span className="h6">Consumable Item</span>
              </label>
            </div>
            <div className="d-flex gap-2 align-items-baseline">
              <div className="supplier-input-label">Type</div>
              <select
                value={tool_type_id}
                name=""
                id=""
                className="select-option-create-order "
                onChange={(e) => setInventory_type(e.target.value)}
              >
                {" "}
                <option selected="true" disabled="disabled">
                  Choose Type
                </option>
                {itemstype?.data?.map((items) => (
                  <option value={items.id}>{items?.inventory_type}</option>
                ))}
              </select>
            </div>

            <div className="d-flex gap-2 align-items-baseline">
              <div className="supplier-input-label">Item Name</div>
              <input
                value={item_name}
                onChange={(e) => setItemName(e.target.value)}
                type="text"
                className={`stock-text-input`}
                required
              />
            </div>
            <div className="d-flex gap-2 align-items-baseline">
              <div className="supplier-input-label">Image</div>
              <div
                className="file-chooser cl-9 px-0"
                style={{ backgroundImage: `url(${attachmentIcon})` }}
              >
                <input
                  id="chooser"
                  type={"file"}
                  class="file-input"
                  multiple
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                <label
                  htmlFor="chooser"
                  style={{
                    position: "relative",
                    top: "-28px",
                    left: "5px",
                    width: "10rem",
                    // zIndex: "-1",
                  }}
                >
                  {image
                    ? image?.name?.length > 16
                      ? image?.name?.slice(0, 15) + "..."
                      : image?.name
                    : "Choose File"}
                </label>
              </div>
            </div>
            {is_consumableform == false ? (
              <div className="d-flex gap-2 align-items-baseline">
                <div className="supplier-input-label">Model</div>
                <input
                  value={model}
                  onChange={(e) => setModel(e.target.value)}
                  type="text"
                  className={`stock-text-input`}
                  required
                />
              </div>
            ) : (
              ""
            )}

            <div className="d-flex gap-2 align-items-baseline">
              <div className="supplier-input-label">Status</div>
              <select
                value={status_id}
                name=""
                id=""
                className="select-option-create-order "
                onChange={(e) => setStatus(e.target.value)}
              >
                {" "}
                <option selected="true" disabled="disabled">
                  Choose Status
                </option>
                {status?.data?.map((items) => (
                  <option value={items.id}>{items?.status_name}</option>
                ))}
              </select>
            </div>
            {is_consumableform == false ? (
              <>
                <div className="d-flex gap-2 align-items-baseline">
                  <div className="supplier-input-label">Serial Number</div>
                  <input
                    value={serial_number}
                    onChange={(e) => setSerialNumber(e.target.value)}
                    type="text"
                    className="stock-text-input"
                    required
                    // disabled={serial_number_edit}
                  />
                </div>

                <div className="d-flex gap-2 align-items-baseline">
                  <div className="supplier-input-label">Item Number</div>
                  <input
                    value={item_number}
                    onChange={(e) => setItemNumber(e.target.value)}
                    type="text"
                    className={`stock-text-input`}
                    required
                  />
                </div>
              </>
            ) : (
              ""
            )}

            <div className="d-flex gap-2 align-items-baseline">
              <div className="supplier-input-label">Unit of Measurement</div>
              <select
                value={uom_id}
                name=""
                id=""
                className="select-option-create-order "
                onChange={(e) => setUnit(e.target.value)}
              >
                {" "}
                <option selected="true" disabled="disabled">
                  Choose unit of Measurement
                </option>
                {unit?.data?.map((items) => (
                  <option value={items.id}>{items?.name}</option>
                ))}
              </select>
            </div>
            <div className="d-flex gap-2 align-items-baseline">
              <div className="supplier-input-label">Amount</div>
              <input
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                type="number"
                className={`stock-text-input`}
                required
                // disabled={amount_edit}
              />
            </div>
            <div className="d-flex gap-2 align-items-baseline">
              <div className="supplier-input-label">Price</div>
              <input
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                type="text"
                className={`stock-text-input`}
                required
                // disabled={price_edit}
              />
            </div>
            <div className="d-flex gap-2 align-items-baseline">
              <div className="supplier-input-label">Date of Purchased</div>
              <input
                value={date_of_purchased}
                onChange={(e) => setDateofPurchased(e.target.value)}
                type="date"
                className={`stock-text-input`}
                required
              />
            </div>
            <div className="d-flex gap-2 align-items-baseline">
              <div className="supplier-input-label">Currency</div>
              <select
                value={currency_id}
                name=""
                id=""
                className="select-option-create-order "
                onChange={(e) => setCurrency(e.target.value)}
                // disabled={currency_edit}
              >
                {" "}
                <option selected="true" disabled="disabled">
                  Choose Currency
                </option>
                {currency?.data?.map((items) => (
                  <option value={items.id}>{items?.name}</option>
                ))}
              </select>
            </div>
            <div className="d-flex gap-2 align-items-baseline">
              <div className="supplier-input-label">PI Document</div>
              <div
                className="file-chooser cl-9 px-0"
                style={{ backgroundImage: `url(${attachmentIcon})` }}
              >
                <input
                  id="chooser"
                  type={"file"}
                  class="file-input"
                  multiple
                  onChange={(e) => {
                    
                    handleChangePi(e);
                  }}
                />
                <label
                  htmlFor="chooser"
                  style={{
                    position: "relative",
                    top: "-28px",
                    left: "5px",
                    width: "10rem",
                    // zIndex: "-1",
                  }}
                >
                  {pi
                    ? pi?.namepi?.length > 16
                      ? pi?.namepi?.slice(0, 15) + "..."
                      : pi?.namepi
                    : "Choose File"}
                </label>
              </div>
            </div>
            <div className="d-flex gap-2 align-items-baseline">
              <div className="supplier-input-label">Store</div>
              <select
                value={store_id}
                name=""
                id=""
                className="select-option-create-order "
                onChange={(e) => setStore(e.target.value)}
              >
                {" "}
                <option selected="true" disabled="disabled">
                  Choose Store
                </option>
                {store?.data?.map((items) => (
                  <option value={items.id}>{items?.store_name}</option>
                ))}
              </select>
            </div>
            <div className="d-flex gap-2 align-items-baseline">
              <div className="supplier-input-label">Department</div>
              <select
                value={department_id}
                name=""
                id=""
                className="select-option-create-order "
                onChange={(e) => setDepartment(e.target.value)}
              >
                {" "}
                <option selected="true" disabled="disabled">
                  Choose Department
                </option>
                {Departments?.data?.map((items) => (
                  <option value={items.id}>{items?.department_name}</option>
                ))}
              </select>
            </div>
            <div className="d-flex gap-2 align-items-baseline">
              <div className="supplier-input-label">Category</div>
              <select
                value={category_id}
                name=""
                id=""
                className="select-option-create-order "
                onChange={(e) => setCategory(e.target.value)}
              >
                {" "}
                <option selected="true" disabled="disabled">
                  Choose Category
                </option>
                {ItemCategory?.data?.map((items) => (
                  <option value={items.id}>{items?.category}</option>
                ))}
              </select>
            </div>
            <div className="d-flex gap-2 align-items-baseline">
              <div className="supplier-input-label">Warranty</div>
              <div
                className="file-chooser cl-9 px-0"
                style={{ backgroundImage: `url(${attachmentIcon})` }}
              >
                <input
                  id="chooser"
                  type={"file"}
                  class="file-input"
                  multiple
                  onChange={(e) => {
                   
                    handleChangeWar(e);
                  }}
                />
                <label
                  htmlFor="chooser"
                  style={{
                    position: "relative",
                    top: "-28px",
                    left: "5px",
                    width: "10rem",
                    // zIndex: "-1",
                  }}
                >
                  {warranty
                    ? warranty?.namewar?.length > 16
                      ? warranty?.namewar?.slice(0, 15) + "..."
                      : warranty?.namewar
                    : "Choose File"}
                </label>
              </div>
            </div>
            <div className="d-flex gap-2 align-items-baseline">
              <div className="supplier-input-label">Manufacturer</div>
              <select
                value={manufacturer_id}
                name=""
                id=""
                className="select-option-create-order "
                onChange={(e) => setManufacturer(e.target.value)}
              >
                {" "}
                <option selected="true" disabled="disabled">
                  Choose Manufacturer
                </option>
                {manufacturer?.data?.map((items) => (
                  <option value={items.id}>{items?.manufacturer_name}</option>
                ))}
              </select>
            </div>
            <div className="d-flex gap-0 align-items-baseline">
              <div className="supplier-input-label">Description</div>
              <textarea
                cols="30"
                rows="5"
                onChange={(e) => setDescription(e.target.value)}
              >
                {description}
              </textarea>
            </div>
          </div>
          <div className="pi-modal-footer">
            <button onClick={HandleCancel}>Cancel</button>
            <button type="submit">Save</button>
          </div>
        </form>
      </div>
    </div>
  );
}
