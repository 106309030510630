import React from "react";
import { useState } from "react";

export default function InventoryApprovalModal(props) {
  
  return (
    <div
      id="Modal"
      className="dialog-modal "
      onClick={(e) => {
        props?.setDialog(false);
        props?.setters(false);
      }}
    >
      <div
        className="stock-dialog-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="dialog-info">
          Are you sure to <strong>Approve</strong>
        </div>
        <div className="dialog-info">
          {props?.dialog == undefined
            ? `${props?.length} of Batch items`
            : props?.dialog}
        </div>
        {/* {props?.setRemark != undefined ? (
          <div className="d-grid gap-1 align-items-baseline">
            <div className="supplier-input-label">Remark</div>
            <textarea
              cols="30"
              rows="5"
              onChange={(e) => props?.setRemark(e.target.value)}
            ></textarea>
          </div>
        ) : (
          ""
        )} */}

        <div className="confirm-dialog-controller">
          <div
            className="dialog-cancel-btn"
            onClick={(e) => {
              props?.setDialog(false);
            }}
          >
            No
          </div>
          <button
            type={"submit"}
            onClick={(e) => {
              // props.setDialog(false);
              props.action(e, props?.dialog?.id);
            }}
            className="form-save"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
}
