import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import Layout from "./Layout";
import Login from "./pages/Login/index";
import RequireAuth from "./helpers/auth/RequireAuth";
import RequireGuest from "./helpers/auth/RequireGuste";
import LoginSuccess from "./components/Login/LoginSuccess";
// import {FRONT_END_BASE_URL} from "./api/endPoint"

//All other routes
import Home from "./pages/Home";
import ProjectDocument from "./pages/Documents/ProjectDocument";
import Index from "./pages/products/Index";
import Project from "./pages/Projects/Project";
import DepartmentsDetail from "./pages/Projects/DepartmentsDetail";
import Boq from "./pages/products/Boq";
import Solution from "./pages/products/Solution";
import SpsMapping from "./pages/Supplier/SpsMapping";
import Orders from "./pages/Sourcing/Orders";
import OrderTranings from "./components/Sourcing/OrderTranings";
import OrderProduct from "./components/Sourcing/OrderProduct";
import OrderService from "./components/Sourcing/OrderService";
import PoStageProduct from "./components/Sourcing/poStageProduct";
import PoStageService from "./components/Sourcing/PoStageService";
import PoStageTraning from "./components/Sourcing/PoStageTraning";
import EtaProduct from "./components/Sourcing/EtaProduct";
import EtaService from "./components/Sourcing/EtaService";
import EtaTraning from "./components/Sourcing/EtaTraning";
import PaSummary from "./pages/Sourcing/PaSummary";
import CiIndex from "./pages/Logistics/CiIndex";
import CiComparison from "./pages/Logistics/CiComparison";
import SupplierManufacturer from "./components/Supplier/SupplierManufacturer";
import SupplierMapping from "./components/Supplier/SupplierMapping";
import SupplierDetail from "./components/Supplier/SupplierDetail";
import SupplierContainer from "./components/Supplier/SupplierContainer";
import FreightForwarder from "./components/Supplier/FreightForwarder";
import FreightForwarderDetail from "./components/Supplier/FreightForwarderDetail";
import ManufacturerDetail from "./components/Supplier/ManufacturerDetail";
import SettingProjects from "./components/Settings/SettingProjects";
import SettingShipment from "./components/Settings/SettingShipment";
import Payment from "./components/Settings/PaymentSetting";
import Bank from "./components/Settings/BankSettings";
import Approval from "./components/Settings/ApprovalSetting";
import PaManufacturer from "./pages/Sourcing/PaManufacturer";
import PoStage from "./pages/Sourcing/PoStage";
import PoStageDetail from "./components/Sourcing/PoStageDetail";
import Prf from "./components/Sourcing/PRF";
import Batch from "./pages/Sourcing/Batch";
import BatchDetail from "./pages/Logistics/Index/BatchDetail";
import EtaIndex from "./pages/Sourcing/EtaIndex";
import EtaFollowupDetail from "./components/Sourcing/EtaFollowupDetail";
import PaymentTrackingindex from "./pages/Sourcing/PaymentTrackingindex";
import LogisticsIndex from "./pages/Logistics/Index/LogisticsIndex";
import LogisticsBatchDetail from "./pages/Logistics/Index/LogisticsDocumentApproval";
import SourcingContainer from "./components/Sourcing/SourcingContainer";
import Batches from "./pages/Logistics/Index/Batches";
import PaStageindex from "./components/Sourcing/PaStageindex";
import RMAIndex from "./pages/Logistics/RMA/RMAIndex";
import RMABatchDetail from "./pages/Logistics/RMA/RMABatchDetails";

//Logistics Missed Item
import MissedItemIndex from "./pages/Logistics/MissedItem/MissedItemIndex";
import MissedItemBatchDetail from "./pages/Logistics/MissedItem/MissedItemBatchDetails";

//Forex module
import ForexApproval from "./pages/Payment/ForexApproval/ForexApprovalIndex";
import PaymentCollection from "./components/Payment/Finance/PaymentCollection";
import VarianceAnalysis from "./components/Payment/Finance/VarianceAnalysis";
import ForexPlaning from "./pages/Payment/ForexPlanning/ForexPlaningIndex";
import PaymentTracking from "./pages/Payment/PaymentTracking/PaymnentTrackingIndex";
import ForexApprovalDetailPage from "./pages/Payment/ForexApproval/ForexApprovalDetailPage";
import ForexReport from "./pages/Payment/ForexApproval/Reports";
import Notfound from "./pages/NotFound/Notfound";
import ProjectContainer from "./components/project/ProjectContainer";
import DocumentCard from "./components/documents/DocumentCard";
import Account from "./pages/Account/Account";
import UserAccess from "./components/Settings/UserAccess";
import EditPermission from "./components/Settings/UserAccess/Users";
import FaqVideos from "./pages/Account/FaqVideos";
import FaqIndex from "./pages/Account/FaqIndex";
import OrderDetail from "./components/Sourcing/OrderDetail";
import EditPaymentModal from "./components/Sourcing/SourcingModal/EditPaymentModal";
import TotalQuestion from "./pages/Account/TotalQuestion";
import Users from "./components/Settings/UserAccess/Users";
import PriceComparison from "./components/Sourcing/PriceComparison";

//Unused routes
import Projects from "./pages/Projects";
import Document from "./pages/Documents";
import Supplier from "./pages/Supplier";
import LogisticsDocumentApproval from "./pages/Logistics/Index/LogisticsDocumentApproval";
import PaStageManufacturer from "./components/Sourcing/PaStageManufacturer";
import PaStage from "./pages/Sourcing/PaStage";
import SupplierRepresentative from "./components/Supplier/SupplierRepresentative";
import SourcingDetail from "./components/Sourcing/SourcingDetail";
import Products from "./components/products/Products";
import SourcingIndex from "./pages/Sourcing/SourcingIndex";
import Faq from "./pages/Account/Faq";
import LoginTempo from "./components/Login/LoginTempo/LoginTempo";
import SCMTest from "./components/common/SCMTest";
import CheckPermission from "./components/common/checkPermission/checkPermission";
import { useGetUserInfoQuery } from "./features/SCMApi";
import LoginSuccessGoogle from "./components/Login/LoginSuccessGoogle";
import NotificationPage from "./pages/Notification/NotificationPage";
import Stocks from "./pages/Stocks/StockIndex";
import RequestToolDetail from "./components/Stock/Request/RequestTool/RequestToolDetail";
import InventoryDetail from "./components/Stock/Inventory/InventoryDetail";
import StockSettingTab from "./components/Settings/StockSettingTab";
import Dashboard from "./pages/Home";
import InStoreItems from "./components/Stock/Request/InStoreItems";

import RequestInventoryDetail from "./components/Stock/Request/RequestInventory/RequestInventoryDetail";
import ConsumedInventoryDetail from "./components/Stock/Request/RequestInventory/ConsumedInventoryDetail";
import Reports from "./components/Reports";
import RequestBatch from "./components/Stock/Request/RequestTool/RequestBatchTool";
import DeliveredItemIndex from "./pages/Logistics/DeliveredItem/DeliveredItemIndex";
import DeliveredBatchDetails from "./pages/Logistics/DeliveredItem/DeliveredItemBatchDetails";
import RequestBatchTool from "./components/Stock/Request/RequestTool/RequestBatchTool";
import RequestBatchInventories from "./components/Stock/Request/RequestInventory/RequestBatchInventories";
import ImageToDoc from "./components/home/ImagetoWord";
import Inventories from "./components/Stock/Inventory";
import { Toolbar } from "@mui/material";
import Tools from "./components/Stock/Tool";
import StockProjects from "./components/Stock/Project";
import PurchaseRequest from "./components/Stock/Request/PurchasedRequest";
import OnUse from "./components/Stock/Items/OnUse";
import History from "./components/Stock/Items/History";
import Incoming from "./components/Stock/Incoming";
import PaymentCollectionIndex from "./pages/Payment/Finance/PaymentCollection";
import PaymentCollectionHome from "./pages/Payment/Finance/PaymentCollectionHome";
import VarianceAnalysisIndex from "./pages/Payment/Finance/VarianceAnalysis";
import Assets from "./components/Stock/Assets";
import SupplierPaymentIndex from "./pages/Payment/SupplierTracking/SupplierTrackingIndex";
import SettingMenu from "./components/Settings/SettingMenu";
import SupplierMenu from "./components/Supplier/SupplierMenu";
import PaymentMenu from "./pages/Payment/PaymentMenu";
import PrfIndex from "./components/Sourcing/PRF/PrfIndex";
import PrfPoIndex from "./components/Sourcing/PRF/PrfPoIndex";
import PrfIndexContainer from "./components/Sourcing/PRF/PrfIndexContainer";
import CompariedDataModal from "./components/Sourcing/PriceComparison/CompariedDataModal";
import CreatePriceComparisonOrder from "./components/Sourcing/PriceComparison/CreatePriceComparisonOrder";
import ComparedPrice from "./components/Sourcing/PriceComparison/ComparedPrice";
import GuaranteeIndex from "./pages/Payment/Guarantee";
import CostBuildUp from "./pages/Payment/CostBuildup";
import GrossProfit from "./components/Payment/GrossProfit";
import AllGuarantee from "./components/Payment/Guarantee/AllGuarantee";
import GuaranteeProjectDetail from "./components/Payment/Guarantee/GuaranteeProjectDetail";
import ReceivedBatchTools from "./components/Stock/Request/RequestTool/ReceivedBatchTools";
import ReceivedBatchItems from "./components/Stock/Request/RequestTool/ReceivedBatchItems";
import CostBuildUpDetail from "./pages/Payment/CostBuildup/costBuildUpDetail";
import InStoreItemsTools from "./components/Stock/Request/InStoreItemsTools";
import ReturnedInStoreItems from "./components/Stock/Request/ReturnedInStoreItems";
import ConsumedInStoreItems from "./components/Stock/Request/ConsumedInStoreItems";
import InStoreItemsInventory from "./components/Stock/Request/InStoreItemsInventory";
import GrossProfitProduct from "./components/Payment/GrossProfit/GrossProfitProduct";
import InStoreAssets from "./components/Stock/Request/InStoreAssets";
import RequestAssetDetail from "./components/Stock/Request/RequestAssets/RequestAssetDetail";
import RequestBatchAssets from "./components/Stock/Request/RequestAssets/RequestBatchAssets";
import InStoreReturnedAssets from "./components/Stock/Request/InStoreReturnedAssets";
import ReceivedBatchAssets from "./components/Stock/Request/RequestAssets/ReceivedBatchAssets";
import InStoreReturnedTools from "./components/Stock/Request/InstoreReturnedTools";
import ApprovalDetail from "./components/Settings/ApprovalDetail";
import ForexExpiration from "./pages/Payment/ForexApproval/Reports/ForexExpiration";
import Delinquent from "./pages/Payment/ForexApproval/Reports/Delinquent";
import BcIndex from "./components/Sourcing/BcIndex";
import BcStageDetail from "./pages/Sourcing/BcStageDetail";
import BudgetComparison from "./pages/Sourcing/BudgetComparision";
import GRNReportGenerate from "./pages/GRN/GRNReportGenerate";
import ScmGrnTemplate from "./pages/GRN/grn/ScmGrnTemplate";
import DeliveryItemsInGroupId from "./pages/Logistics/DeliveredItem/DeliveryItemsInGroupId";
import LCItemTrackingDetail from "./pages/GRN/LCItemTrackingDetail";
import Budget from "./pages/Payment/Budget";
// import AgingIndex from "./pages/Payment/Aging/AgingIndex";
import ReceivableIndex from "./pages/Payment/Recievable/RecievableIndex";
import SettingSupplierCategory from "./components/Settings/SettingSupplierCategory";
import SupplierReportsIndex from "./components/Supplier/SupplierReportsIndex";
import PayableIndex from "./pages/Payment/Payable/PayableIndex";
import StoreTransferTool from "./components/Stock/Request/StoreTransferTool";
import StoreTransferItem from "./components/Stock/Request/StoreTransferItem";
import StoreTransferAsset from "./components/Stock/Request/StoreTransferAsset";
import BcSummary from "./components/Sourcing/BcSummary";
import BcStageData from "./components/Sourcing/BcStageData";
import axios from "axios";
import ForexApprovalNavBar from "./components/Payment/ForexPlanning/ForexPlannigNav";
import PrfChecker from "./components/Sourcing/PrfChecker";
import { API_BASE_URL } from "./api/endPoint";
import SOAViewComponent from "./components/Payment/SupplierTracking/SOA/SOAViewComponent";
import ViewAttachedPI from "./components/Sourcing/PriceComparison/viewAttachedPI";
import ReturnBatchInventory from "./components/Stock/Request/RequestInventory/ReturnBatchInventory";
import ConsumedBatchInventory from "./components/Stock/Request/RequestInventory/ConsumedBatchInventory";
import ReturnBatchTool from "./components/Stock/Request/ReturnBatchTool";
import ReturnBatchAssets from "./components/Stock/Request/RequestAssets/ReturnBatchAssets";
import Transaction from "./components/Stock/Items/Transaction";
import EtaSummaryPage from "./components/Sourcing/EtaSummaryPage";

function App() {
  const { data: userinfo } = useGetUserInfoQuery();
  const BearerToken = localStorage.getItem("accessToken");
  const getUserInfo = async (BearerToken) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/logdUserData`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${BearerToken}`,
        },
      });
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem("accessToken");
      }
    }
  };
  useEffect(() => {
    getUserInfo(BearerToken);
  }, [BearerToken]);

  // Make sure you have this setup before you make any request with Axios in your application.

  return (
    <BrowserRouter basename="/">
      {/*<Route path="/Logistics">
            <Route index element={<CiIndex />} />
            <Route path="CiIndex" element={<CiIndex />} />
            <Route path="CiComparison" element={<CiComparison />} />
      </Route>*/}
      <Layout>
        <Routes>
          <Route path="">
            <Route path="*" element={<Notfound />} />
            <Route element={<RequireAuth />}>
              <Route path="/notification">
                <Route index element={<NotificationPage />} />
              </Route>
              {/* <Route path="/notification" element={<NotificationPage />} /> */}
              <Route path="/" element={<Dashboard />} />

              {/* <Route path="/" element={<SCMTest />} /> */}
              <Route path="/projects">
                <Route index element={<ProjectContainer />} />
                <Route path=":projectId">
                  <Route index element={<Project />} />
                  <Route
                    path="DepartmentsDetail/:pId/:sId"
                    element={<DepartmentsDetail />}
                  />
                </Route>
              </Route>
              <Route path="/documents">
                <Route index element={<DocumentCard />} />
                <Route path="document/:id" element={<ProjectDocument />} />
              </Route>
              <Route path="/Solutions">
                <Route index element={<Products />} />
                <Route path="solution" element={<Index />} />
                <Route path="document" element={<ProjectDocument />} />
                <Route path="boq">
                  <Route index element={<Boq />} />
                </Route>
              </Route>
              <Route path="/sourcing">
                <Route index element={<SourcingContainer />} />
                {/* <Route path="orders/:id" element={<Orders />} /> */}
                <Route path="orders/:id">
                  <Route index element={<Orders />} />
                  <Route path="products" element={<OrderProduct />} />
                  <Route path="service" element={<OrderService />} />
                  <Route path="training" element={<OrderTranings />} />
                </Route>
                <Route path="orderdetail/:id" element={<OrderDetail />} />
                <Route path="paStage/:id" element={<PaStageindex />} />
                <Route path="paSummary/:id" element={<PaSummary />} />
                <Route path="bcStage/:id" element={<BcIndex />} />
                <Route path="bcSummary/:id" element={<BudgetComparison />} />
                <Route
                  path="paStageManufacturer/:id"
                  element={<PaManufacturer />}
                />
                <Route path="BcStage/detail/:id" element={<BcStageDetail />} />{" "}
                <Route path="bcStage/:id" element={<BcIndex />} />
                <Route path="bcSummary/:id" element={<BudgetComparison />} />
                <Route path="poStage/:id">
                  <Route index element={<PoStage />} />
                  <Route path="PoStageProduct" element={<PoStageProduct />} />
                  <Route path="PoStageService" element={<PoStageService />} />
                  <Route path="PoStageTraning" element={<PoStageTraning />} />
                </Route>
                <Route path="batch" element={<Batch />} />
                <Route path="batchDetail" element={<BatchDetail />} />
                <Route path="eta/:id">
                  <Route index element={<EtaIndex />} />
                  <Route path="etaSummary" element={<EtaSummaryPage />} />
                  <Route path="EtaProduct">
                    <Route index element={<EtaProduct />} />
                  </Route>
                  <Route path="EtaService">
                    <Route index element={<EtaService />} />
                  </Route>
                  <Route path="EtaTraning">
                    <Route index element={<EtaTraning />} />
                  </Route>
                </Route>
                <Route
                  path="eta/etaDetail/:id"
                  element={<EtaFollowupDetail />}
                />
                <Route path="pricecomparison/:id">
                  <Route
                    index
                    element={<PriceComparison order={"product"} />}
                  />
                  <Route
                    path="compared/:c_id"
                    element={<CompariedDataModal />}
                  />
                </Route>
                <Route path="traningpricecomparison/:id">
                  <Route
                    index
                    element={<PriceComparison order={"training"} />}
                  />{" "}
                  <Route
                    path="compared/:c_id"
                    element={<CompariedDataModal />}
                  />
                </Route>
                <Route path="comparedprices/:id">
                  <Route index element={<ComparedPrice />} />
                  <Route path="final/:f_id">
                    <Route index element={<CreatePriceComparisonOrder />} />
                    <Route path="viewAttachedPI" element={<ViewAttachedPI />} />
                  </Route>
                </Route>
              </Route>

              <Route
                path={
                  CheckPermission("view_payment_tracking", userinfo)
                    ? "paymenttracking"
                    : "*"
                }
                element={<PaymentTrackingindex />}
              />

              <Route path="poStageDetail/:id" element={<PoStageDetail />} />

              <Route path="payment">
                <Route index element={<PaymentMenu />} />
                <Route path="forexApproval">
                  <Route index element={<ForexApproval />} />
                  <Route
                    path="Detail/:id"
                    element={<ForexApprovalDetailPage />}
                  />
                  <Route path="Reports">
                    <Route index element={<ForexReport />} />
                    <Route
                      path="foreexpiration"
                      element={<ForexExpiration />}
                    />
                    <Route path="Delinquent" element={<Delinquent />} />
                  </Route>
                </Route>

                <Route path="forexPlaning">
                  <Route index element={<ForexPlaning />} />
                  <Route path=":tabKey" element={<ForexApprovalNavBar />} />
                </Route>
                <Route path="PrfChecker">
                  <Route index element={<PrfChecker />} />
                </Route>

                <Route path="prf">
                  <Route index element={<PrfIndex />} />
                  <Route path=":project_id">
                    <Route index element={<PrfPoIndex />} />
                    <Route path="prfs/:id">
                      <Route index element={<PrfIndexContainer />} />
                      <Route path="prfdetail/:prf_id" element={<Prf />} />
                    </Route>
                  </Route>
                </Route>
                <Route path={"supplierpayment"}>
                  <Route path="soaSummary" element={<SOAViewComponent />} />
                  <Route
                    index
                    element={
                      CheckPermission("view_payment_tracking", userinfo) ? (
                        <SupplierPaymentIndex />
                      ) : (
                        <Notfound />
                      )
                    }
                  />
                </Route>
                <Route path="guarantee">
                  <Route index element={<GuaranteeIndex />} />
                  <Route
                    path="project/:id"
                    element={<GuaranteeProjectDetail />}
                  />
                </Route>
                <Route path="costbuildup">
                  <Route index element={<CostBuildUp />} />
                  <Route path=":id" element={<CostBuildUpDetail />} />
                </Route>
                <Route path="grossprofit">
                  {/* <Route index element={<GrossProfit/>}/> */}
                  <Route index element={<GrossProfitProduct />} />
                  <Route path=":product" element={<GrossProfitProduct />} />
                </Route>

                <Route path="paymentcollections">
                  <Route index element={<PaymentCollectionHome />} />
                  <Route path="OnshorePaymentCollection">
                    <Route
                      index
                      element={<PaymentCollectionIndex type={true} />}
                    />
                    <Route
                      path=":id"
                      element={<PaymentCollection type={true} />}
                    />
                  </Route>
                  <Route path="offshorePaymentCollection">
                    <Route
                      index
                      element={<PaymentCollectionIndex type={false} />}
                    />
                    <Route
                      path=":id"
                      element={<PaymentCollection type={false} />}
                    />
                  </Route>
                </Route>
                <Route path="Payable">
                  <Route index element={<PayableIndex />} />
                </Route>
                <Route path="budget">
                  <Route index element={<Budget />} />
                </Route>
                <Route path="Receivable">
                  <Route index element={<ReceivableIndex />} />
                </Route>
                <Route path="varianceanalysis">
                  <Route index element={<VarianceAnalysisIndex />} />
                  <Route
                    path="varianceanalysis/:id"
                    element={<VarianceAnalysis />}
                  />
                </Route>
              </Route>

              <Route path={"/paymenttracking"}>
                <Route
                  index
                  element={
                    CheckPermission("view_payment_tracking", userinfo) ? (
                      <PaymentTracking />
                    ) : (
                      <Notfound />
                    )
                  }
                />
              </Route>

              <Route path="account" index element={<Account />} />
              <Route path="/Faq">
                <Route index element={<FaqIndex />} />
                <Route path="FaqVideos" element={<FaqVideos />} />
                <Route path="TotalQuestions" element={<TotalQuestion />} />
              </Route>
              <Route path="boq" element={<Boq />} />

              <Route path="settings">
                <Route index element={<SettingMenu />} />
                <Route
                  path="supplierCategory"
                  element={<SettingSupplierCategory />}
                />
                <Route path="clients" element={<SettingProjects />} />
                <Route path="shipment" element={<SettingShipment />} />
                <Route path="payment" element={<Payment />} />
                <Route path="bank" element={<Bank />} />
                <Route path="stock" element={<StockSettingTab />} />
                <Route path="approval">
                  <Route index element={<Approval />} />
                  <Route path=":id" element={<ApprovalDetail />} />
                </Route>

                <Route
                  path={
                    CheckPermission("user_access", userinfo)
                      ? "useraccess"
                      : "*"
                  }
                  element={<UserAccess />}
                />
                <Route
                  path={CheckPermission("user", userinfo) ? "users" : "*"}
                  element={<Users />}
                />
              </Route>
              <Route path="editpermission" element={<EditPermission />} />
              <Route path="/smm">
                <Route index element={<SupplierMenu />} />
                <Route path="reports" element={<SupplierReportsIndex />} />

                <Route path="suppliers">
                  <Route index element={<SupplierContainer />} />
                  <Route path=":id" element={<SupplierDetail />} />
                </Route>
                <Route path="manufacturers">
                  <Route index element={<SupplierManufacturer />} />
                  <Route path=":id" element={<ManufacturerDetail />} />
                </Route>
                <Route path="mapping" element={<SupplierMapping />} />
              </Route>
              <Route path="freightforwarder">
                <Route index element={<FreightForwarder />} />
                <Route path=":id" element={<FreightForwarderDetail />} />
              </Route>
              <Route path="SPSMapping" element={<SpsMapping />} />

              <Route path="/logistics">
                <Route path="batches/:ProjectId">
                  <Route index element={<Batches />} />
                  {/* <Route path="documentPreparation">
                    <Route index path=":CiId" />
                  </Route> */}
                  <Route path="batchDetail/:batchId">
                    <Route index element={<BatchDetail />} />
                    <Route
                      path="documentPreparation"
                      element={<CiComparison />}
                    />
                  </Route>
                </Route>
                <Route index element={<LogisticsIndex />} />
                <Route path="batchDetail" element={<LogisticsBatchDetail />} />
                <Route path="batches" element={<Batches />} />
                <Route path="rmaBatches/:ProjectId">
                  <Route index element={<RMAIndex />} />
                  <Route path="details/:batchId" element={<RMABatchDetail />} />
                </Route>
                <Route path="missedItemBatches/:ProjectId">
                  <Route index element={<MissedItemIndex />} />
                  <Route
                    path="details/:missedItemId"
                    element={<MissedItemBatchDetail />}
                  />
                </Route>
                <Route path="deliveredBatches/:ProjectId">
                  <Route index element={<DeliveredItemIndex />} />
                  <Route
                    path="details/:deliverdItems"
                    element={<DeliveredBatchDetails />}
                  />
                  <Route path="details/:deliverdItems">
                    <Route index element={<DeliveredBatchDetails />} />
                    <Route
                      path=":groupId"
                      element={<DeliveryItemsInGroupId />}
                    />
                  </Route>
                </Route>
              </Route>
              <Route path="/stocks">
                <Route index element={<Inventories />}></Route>
                <Route path="tools" element={<Tools />}></Route>
                <Route path="assets" element={<Assets />}></Route>
                <Route path="Projects" element={<StockProjects />}></Route>
                <Route path="request" element={<StockProjects />}></Route>

                <Route path="requests">
                  <Route index element={<InStoreItems />} />
                  <Route path="purchase" element={<PurchaseRequest />} />
                  <Route
                    path="inventory/:id"
                    element={<RequestInventoryDetail />}
                  />
                  <Route path="assets/:id" element={<RequestAssetDetail />} />

                  <Route path="requested">
                    <Route index element={<InStoreItemsTools />} />
                    <Route path=":id" element={<RequestToolDetail />} />
                  </Route>
                  <Route path="storeTransfer">
                    <Route index element={<StoreTransferTool />} />
                  </Route>
                  <Route path="returned">
                    <Route index element={<InStoreReturnedTools />} />
                    <Route path=":id" element={<RequestToolDetail />} />
                  </Route>

                  <Route path="requested/items">
                    <Route index element={<InStoreItemsInventory />} />
                    <Route path=":id" element={<RequestInventoryDetail />} />
                  </Route>
                  <Route path="returned/items">
                    <Route index element={<ReturnedInStoreItems />} />
                    <Route path=":id" element={<RequestInventoryDetail />} />
                  </Route>
                  <Route path="consumed/items">
                    <Route index element={<ConsumedInStoreItems />} />
                    <Route
                      path="consumed/items/:id"
                      element={<ConsumedInventoryDetail />}
                    />
                  </Route>
                  <Route path="storeTransferItem">
                    <Route index element={<StoreTransferItem />} />
                  </Route>

                  <Route path="requested/assets">
                    <Route index element={<InStoreAssets />} />
                    <Route path=":id" element={<RequestAssetDetail />} />
                  </Route>
                  <Route path="returned/assets">
                    <Route index element={<InStoreReturnedAssets />} />
                    <Route path=":id" element={<RequestAssetDetail />} />
                  </Route>
                  <Route path="storeTransferAsset">
                    <Route index element={<StoreTransferAsset />} />
                  </Route>
                  <Route
                    path="requestbatchtool/:id"
                    element={<RequestBatchTool />}
                  />
                  <Route
                    path="requestbatchinventory/:id"
                    element={<RequestBatchInventories />}
                  />
                </Route>
                <Route
                  path="requestbatchasset/:id"
                  element={<RequestBatchAssets />}
                />
                <Route path="onuse">
                  <Route index element={<OnUse />} />
                  <Route path="history" element={<History />} />
                  <Route path="transaction">
                    <Route index element={<Transaction />} />
                  </Route>
                  <Route
                    path="receivedbatchtool/:id"
                    element={<ReceivedBatchTools />}
                  />
                  <Route
                    path="receivedbatchassets/:id"
                    element={<ReceivedBatchAssets />}
                  />
                  <Route
                    path="receivedbatchitems/:id"
                    element={<ReceivedBatchItems />}
                  />
                </Route>
                <Route path="incoming">
                  <Route index element={<Incoming />} />
                </Route>
                <Route path="GRN">
                  <Route index element={<GRNReportGenerate />} />
                </Route>
                <Route
                  path="requestbatchtool/:id"
                  element={<RequestBatchTool />}
                />
                <Route
                  path="returnbatchinventory/:id"
                  element={<ReturnBatchInventory />}
                />
                <Route
                  path="consumebatchinventory/:id"
                  element={<ConsumedBatchInventory />}
                />
                <Route
                  path="consumed/:id"
                  element={<ConsumedInventoryDetail />}
                />
                <Route
                  path="returnbatchasset/:id"
                  element={<ReturnBatchAssets />}
                />
                <Route
                  path="returnbatchtool/:id"
                  element={<ReturnBatchTool />}
                />
                <Route
                  path="requestbatchinventory/:id"
                  element={<RequestBatchInventories />}
                />
              </Route>
              <Route path="/reports">
                <Route index element={<Reports />} />
              </Route>
            </Route>

            <Route element={<RequireGuest />}>
              <Route path="/login" element={<Login />} />
              <Route path="/api/o365callback" element={<LoginSuccess />} />
              <Route
                path="/api/googlecallback"
                element={<LoginSuccessGoogle />}
              />
            </Route>
          </Route>
          <Route path="/docimage">
            <Route index element={<ImageToDoc />} />
          </Route>

          {/* ///////////////////////////////////route for grn template will download  */}
          <Route path="/download/grn-pdf/:id">
            <Route index element={<ScmGrnTemplate />} />
          </Route>
          <Route path="stocks/GRN">
            <Route index element={<GRNReportGenerate />} />
          </Route>
          <Route path="stocks/GRN/LCitemDetail/:groupId">
            <Route index element={<LCItemTrackingDetail />} />
          </Route>
          {/* ///////////////////////// ////////////////////////////// */}
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
