import { IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { API_BASE_URL, IMG_BASE_URL } from "../../api/endPoint";
import {
  useAddBillToMutation,
  useDeleteOrderMutation,
  useEditBatchMutation,
  useEditPiNumberMutation,
  useEditStageMutation,
  useGetBatchQuery,
  useGetEtaDetailQuery,
  useGetOrderPoItemQuery,
  useGetProjectBatchQuery,
  useGetProjectQuery,
  useGetProjectRolePermissionQuery,
  useGetProjectRolesQuery,
  useGetSorcingStageQuery,
  useGetSuppliersOrdersQuery,
  useGetUserInfoQuery,
  useUpdateOrderSourceMutation,
} from "../../features/SCMApi";
import NavBar from "../../Layout/NavBar";
import SourcingIndex from "../../pages/Sourcing/SourcingIndex";
import EtaPoDetail from "./EtaPoDetail";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import NoRecord from "../common/NoRecord";
import CheckProjectPermission from "../common/checkPermission/CheckProjectPermission";
import { Edit } from "@mui/icons-material";
import EditPi from "./EditPi";
import CheckPermission from "../common/checkPermission/checkPermission";
import OrderDescription from "./OrderDescription";
import CurrentStatus from "./CurrentStatus";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

export default function OrderDetail(props) {
  const params = useParams();

  const { data: etaDetail } = useGetEtaDetailQuery(params.id);
  const { data: batchDetail } = useGetBatchQuery(
    etaDetail?.data[0]?.project?.id
  );
  const { data: orderpo } = useGetOrderPoItemQuery(params.id);
  const { data: sorcingStages } = useGetSorcingStageQuery();

  const location = useLocation();
  const [bill_to, setbill] = useState();
  let manufacturer_id = etaDetail?.data[0]?.pis[0]?.manufacurer_id;
  let billto = {
    id: params.id,
    manufacturer_id: manufacturer_id,
    bill_to: bill_to,
  };

  const pis = etaDetail?.data[0]?.pis;
  let counter = 0;
  let total_line_price = 0;
  const { data: response, isSuccess } = useGetProjectQuery(
    etaDetail?.data[0]?.project?.id
  );

  const { data: roles } = useGetProjectRolesQuery();

  let avatar = [];
  const [deletedorder, setDeletedOrder] = useState();
  const [confirmation, setConfirmation] = useState(false);
  const [Message, setMessage] = useState(null);
  function confirmationHandler() {
    setConfirmation(true);
  }

  let deleted = {
    id: params.id,
  };
  let navigate = useNavigate();
  const [deleteorders, res] = useDeleteOrderMutation();
  const HandleDelete = () => {
    deleteorders(deleted)
      .unwrap()
      .then((response) => {
        setMessage("Order Deleted Successfully");
        setConfirmation(false);
        navigate(`/sourcing/orders/${etaDetail?.data[0]?.project?.id}`, {
          state: { success: "Order Deleted Successfully" },
        });
      })
      .then((error) => {});
  };
  const { data: roleRoles } = useGetProjectRolePermissionQuery(
    etaDetail?.data[0]?.project?.id
  );
  const { data: userinfo } = useGetUserInfoQuery();
  const { data: projectmembers, isSuccesss } = useGetProjectQuery(
    etaDetail?.data[0]?.project?.id
  );
  const { data: Suppliers } = useGetSuppliersOrdersQuery();

  let flatData = etaDetail?.data?.flat(1);
  const [createOrderModalToggle, setCreateOrderModalToggle] = useState(false);
  function handleCreateOrderModal() {
    setCreateOrderModalToggle(true);
  }
  let orderDetail = etaDetail?.data && etaDetail?.data[0];
  const [show, setShow] = useState();
  const [showedit, setShowEdit] = useState();
  const [showProduct, setShowProduct] = useState();
  const [showStage, setShowStage] = useState();
  const [showBatch, setShowBatch] = useState();
  const [showpi, setpi] = useState();
  const previousOrderType = orderDetail && orderDetail?.order_type;
  const previousStageType = orderDetail && orderDetail?.status?.id;
  const previousBatchType = orderDetail && orderDetail?.batch;
  const previousIsOffshore = etaDetail?.data && etaDetail?.data[0]?.is_offshore;
  const is_off =
    previousIsOffshore == undefined
      ? previousIsOffshore == 1
        ? true
        : false
      : previousIsOffshore == 1
        ? true
        : false;
  const [order_type, setOrderType] = useState(previousOrderType);
  const [stageType, setStageType] = useState(previousStageType);
  const [batchType, setBatchType] = useState("");

  let stageEdit = {
    id: params.id,
    status_id: stageType,
  };
  let batchEdit = {
    id: params.id,
    batch_id: batchType,
  };

  const [is_offshore, setOffshore] = React.useState();

  const [AddBillto, success] = useAddBillToMutation();
  const [EditStage, editStageSuccess] = useEditStageMutation();
  const [EditBatch, editbatchSuccess] = useEditBatchMutation();
  const [editPiNumber] = useEditPiNumberMutation();
  const [pi_number, setPiNumber] = useState(etaDetail?.data[0]?.pi_number);
  let pi = {
    id: params.id,
    pi_number: pi_number,
  };

  const HandleUpdateSubmit = async (e) => {
    e.preventDefault();
    AddBillto(billto)
      .unwrap()
      .then((response) => {
        setMessage("Bill To Updated Successfully");
        setShowEdit(false);
      })
      .then((error) => {});
    setShowEdit(false);
  };
  const HandleUpdatePi = async (e) => {
    e.preventDefault();
    editPiNumber(pi)
      .unwrap()
      .then((response) => {
        setMessage("Pi Number Updated Successfully");
        setpi(false);
      })
      .then((error) => {});
    setpi(false);
  };
  const HandleCreateSubmit = async (e) => {
    e.preventDefault();
    AddBillto(billto)
      .unwrap()
      .then((response) => {
        setMessage("Bill To Updated Successfully");
        setShow(false);
      })
      .then((error) => {});
    setShowEdit(false);
  };
  const HandleUpdateStage = async (e) => {
    e.preventDefault();
    EditStage(stageEdit)
      .unwrap()
      .then((response) => {
        setMessage("Stage Updated Successfully");
        // setShow(false);
      })
      .then((error) => {});
    setShowStage(false);
  };
  const HandleUpdateBatch = async (e) => {
    e.preventDefault();
    EditBatch(batchEdit)
      .unwrap()
      .then((response) => {
        setMessage("Batch Updated Successfully");
        // setShow(false);
      })
      .then((error) => {});
    setShowBatch(false);
  };
  const [updateOrderType, src] = useUpdateOrderSourceMutation();
  const HandleUpdateProduct = async (e) => {
    let updateordertype = {
      id: params?.id,
      order_type,
      is_offshore,
    };
    e.preventDefault();
    updateOrderType(updateordertype)
      .unwrap()
      .then((response) => {
        setMessage("Updated Successfully");
        setShowProduct(false);
      })
      .then((error) => {});
    setShowEdit(false);
  };
  let suppliersList = [];
  Suppliers?.data?.map((items) =>
    suppliersList.push({
      value: items?.id,
      label: items?.distributor_name,
    })
  );
  const CheckBoxHandelonShore = () => {
    setOffshore(!is_off);
  };

  let orderTypes = [
    { name: "Training", id: 1 },
    { name: "Service", id: 2 },
    { name: "Product", id: 3 },
  ];

  return (
    <SourcingIndex
      name={etaDetail?.data[0]?.order_name}
      title={"Orders"}
      navlink={`/sourcing/orders/${etaDetail?.data[0]?.project?.id}`}
      Message={Message}
    >
      <div className="row mt-3">
        <div
          className="supplier-user-container"
          style={{ gridTemplateColumns: "24% 24% 25% 25%" }}
        >
          {etaDetail?.data?.map((items) => (
            <>
              {etaDetail?.data[0]?.supplier != null ? (
                <div className="eta-card">
                  <div className="Lc-card">
                    <Tooltip title={items?.supplier?.distributor_name}>
                      <div className="h4-pri mt-2">
                        {items?.supplier?.distributor_name?.length >= 20
                          ? items?.supplier?.distributor_name?.slice(0, 18) +
                            "..."
                          : items.supplier?.distributor_name}
                      </div>
                    </Tooltip>
                    <div className="lc-label mb-2">Supplier</div>
                  </div>
                </div>
              ) : (
                <div className="eta-card">
                  <div className="Lc-card">
                    <Tooltip title={items?.bill_to?.distributor_name}>
                      <div className="h4-pri mt-2">
                        {items?.bill_to?.distributor_name?.length >= 20
                          ? items?.bill_to?.distributor_name?.slice(0, 18) +
                            "..."
                          : items.bill_to?.distributor_name}
                      </div>
                    </Tooltip>
                    <div className="lc-label mb-2">Bill to</div>
                  </div>
                </div>
              )}
            </>
          ))}
          {etaDetail?.data?.map((items) => (
            <div className="eta-card">
              <div className="Lc-card">
                {!showpi && (
                  <Tooltip title={items?.pi_number}>
                    <div
                      className="h4-pri mt-2"
                      onClick={() => setpi((prev) => !prev)}
                    >
                      {items?.pi_number == null
                        ? "Unknown"
                        : items?.pi_number?.length >= 20
                          ? items?.pi_number?.slice(0, 18) + "..."
                          : items?.pi_number}
                    </div>
                  </Tooltip>
                )}
                {showpi && (
                  <div className="d-flex align-items-center p-1">
                    <div className="input-groups">
                      <input
                        typ="text"
                        defaultValue={etaDetail?.data[0]?.pi_number}
                        className="select-option-create-order"
                        style={{ width: "195px" }}
                        onChange={(e) => setPiNumber(e.target.value)}
                      />
                    </div>
                    <IconButton
                      variant="text"
                      size="small"
                      sx={{
                        zIndex: 1,
                        backgroundColor: "#fff",
                        color: "#186569",

                        "&:hover": {
                          backgroundColor: "#186569",
                          color: "#fff",
                        },

                        marginBottom: "3px",
                      }}
                    >
                      <ModeEditOutlinedIcon onClick={HandleUpdatePi} />
                    </IconButton>
                    <IconButton
                      variant="text"
                      size="small"
                      sx={{
                        zIndex: 1,
                        backgroundColor: "#fff",
                        color: "#186569",

                        "&:hover": {
                          backgroundColor: "#186569",
                          color: "#fff",
                        },

                        marginBottom: "3px",
                      }}
                      onClick={() => setpi((prev) => !prev)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                )}
                <div className="lc-label mb-2">Pi Number</div>
              </div>
            </div>
          ))}
          <div className="user-container-card overflow-auto">
            <div className="Lc-card">
              {projectmembers?.data?.users.map((items) =>
                roles?.data?.map((role) =>
                  role.id === items?.pivot?.role_id ? (
                    role?.role_name === "Sourcing Officer" ? (
                      <div className="d-flex align-items-center gap-3">
                        <div className="participantManufacturer-img">
                          {items.avatar !== null ? (
                            <img
                              src={`${IMG_BASE_URL}${items.avatar}`}
                              alt=""
                            />
                          ) : (
                            <div className="default-avatar">
                              <div style={{ display: "none" }}>
                                {(avatar = items?.name?.split(" "))}
                              </div>
                              {avatar[0]?.charAt(0)}
                              {avatar[1]?.charAt(0)}
                            </div>
                          )}
                        </div>
                        <div>
                          <div className="lc-value">{items.name}</div>
                          <div className="lc-label">{role?.role_name}</div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )
                )
              )}
            </div>
          </div>
          <div className="user-container-card overflow-auto">
            <div className="Lc-card">
              {projectmembers?.data?.users.map((items) =>
                roles?.data?.map((role) =>
                  role.id === items?.pivot?.role_id ? (
                    role?.role_name === "Logistics Officer" ? (
                      <div className="d-flex align-items-center gap-3">
                        <div className="participantManufacturer-img">
                          {items.avatar !== null ? (
                            <img
                              src={`${IMG_BASE_URL}${items.avatar}`}
                              alt=""
                            />
                          ) : (
                            <div className="default-avatar">
                              <div style={{ display: "none" }}>
                                {(avatar = items?.name?.split(" "))}
                              </div>
                              {avatar[0]?.charAt(0)}
                              {avatar[1]?.charAt(0)}
                            </div>
                          )}
                        </div>
                        <div>
                          <div className="lc-value">{items.name}</div>
                          <div className="lc-label">{role?.role_name}</div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )
                )
              )}
            </div>
          </div>
        </div>

        {pis?.length !== 0 ? (
          etaDetail?.data[0]?.supplier != null ? (
            <div className="col-md-8">
              <div className=" po-summary-table">
                <a
                  className="download-boq m-3"
                  href={
                    API_BASE_URL +
                    `/scm/sourcing/download-pi/${etaDetail?.data[0]?.id}`
                  }
                  target="_blank"
                >
                  Download Attached PI
                  <FileDownloadIcon sx={{ fontSize: "15px" }} />
                </a>
                <div className=" summarymanufacturer-header orderStage-row">
                  <div className="no"></div>
                  <div className="no">No</div>
                  <div>Part Number</div>
                  <div className="no">Item Description</div>
                  <div>qty</div>
                  <div>Unit Price</div>
                  <div>Total price</div>
                  <div className="no"></div>
                </div>
                {etaDetail?.data?.map((items) =>
                  items?.pis?.map((poitems) =>
                    poitems?.pi?.map((items) => (
                      <div className="orderStage-row mb-2">
                        <div className="no"></div>
                        <div className="label-container">
                          <div className="pa-label">NO</div>
                          <div className="">
                            <div>{(counter += 1)}</div>
                          </div>
                        </div>
                        <div className="label-container">
                          <div className="pa-label">Part Number</div>
                          <div>
                            {items?.item_part_number
                              ? items?.item_part_number
                              : items?.course}
                          </div>
                        </div>
                        <div className="label-container">
                          <div className="pa-label">Item Description</div>
                          <Tooltip
                            title={
                              items?.item_description
                                ? items?.item_description
                                : items?.course_description
                            }
                          >
                            <div>
                              {items?.item_description
                                ? items?.item_description
                                : items?.course_description?.slice(0, 30) +
                                  "..."}
                            </div>
                          </Tooltip>
                        </div>
                        <div className="label-container">
                          <div className="pa-label">qty</div>
                          <div>{items?.qty}</div>
                        </div>
                        <div className="label-container">
                          <div className="pa-label">Unit Price</div>
                          <div>
                            {items?.unit_cost === 0
                              ? items?.unit_cost?.toLocaleString()
                              : items?.unit_cost?.toLocaleString()}
                          </div>
                        </div>
                        <div className="label-container">
                          <div className="pa-label">Total price</div>
                          <div>
                            {
                              ((total_line_price += items.total_cost),
                              items.total_cost === 0
                                ? items.total_cost?.toLocaleString()
                                : items.total_cost?.toLocaleString())
                            }
                          </div>
                        </div>
                      </div>
                    ))
                  )
                )}

                <div className="d-flex justify-content-end gap-3">
                  <div className="payment-text-bold mt-2 mb-0">Sub Total</div>

                  <div
                    className={`payment-text-bold mt-2 mb-0 ${
                      total_line_price < 0 ? "text-danger" : "text-success"
                    }`}
                  >
                    {total_line_price === 0
                      ? total_line_price?.toLocaleString()
                      : total_line_price?.toLocaleString()}
                  </div>
                </div>

                <div className="divider"></div>
                <div className="d-flex justify-content-end">
                  {" "}
                  <IconButton
                    variant="text"
                    size="small"
                    sx={{
                      zIndex: 1,
                      backgroundColor: "#fff",
                      color: "#186569",
                      width: 40,
                      height: 40,

                      "&:hover": {
                        backgroundColor: "#186569",
                        color: "#fff",
                        width: 40,
                        height: 40,
                      },

                      marginBottom: "3px",
                    }}
                    onClick={handleCreateOrderModal}
                  >
                    <ModeEditOutlinedIcon />
                  </IconButton>
                </div>
              </div>
              {CheckProjectPermission(
                "delete_order",
                response,
                userinfo,
                roleRoles
              ) ? (
                <div className="d-flex justify-content-center mt-3">
                  <button
                    type="submit"
                    className="supplier-remove-btn"
                    onClick={(e) => confirmationHandler(e)}
                  >
                    Remove Order
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div className="col-md-8">
              <div className="supplier-po-summary-table">
                <div className="summarymanufacturer-header supplier-orderStage-row">
                  <div className="no">No</div>
                  <div>Supplier</div>
                  <div>Part Number</div>
                  <div className="no">Item Description</div>
                  <div>Unit Price</div>
                  <div>Total price</div>
                </div>
                {etaDetail?.data?.map((items) =>
                  items?.pis?.map((poitems) =>
                    poitems?.pi?.map((items) => (
                      <div className="supplier-orderStage-row mb-2">
                        <div className="label-container">
                          <div className="pa-label">NO</div>
                          <div className="">
                            <div>{(counter += 1)}</div>
                          </div>
                        </div>
                        <div className="label-container">
                          <div className="pa-label text-left">Supplier</div>
                          <div>{items?.SupplierName}</div>
                        </div>
                        <div className="label-container">
                          <div className="pa-label">Part Number</div>
                          <div>{items?.item_part_number}</div>
                        </div>

                        <div className="label-container">
                          <div className="pa-label">Item Description</div>
                          <Tooltip title={items?.item_description}>
                            <div>
                              {items?.item_description?.slice(0, 20) + "..."}
                            </div>
                          </Tooltip>
                        </div>

                        <div className="label-container">
                          <div className="pa-label">Unit Price</div>
                          <div>
                            {items?.unit_cost === 0
                              ? items?.unit_cost?.toLocaleString()
                              : items?.unit_cost?.toLocaleString()}
                          </div>
                        </div>
                        <div className="label-container">
                          <div className="pa-label">Total price</div>
                          <div>
                            {
                              ((total_line_price += items.total_cost),
                              items.total_cost === 0
                                ? items.total_cost?.toLocaleString()
                                : items.total_cost?.toLocaleString())
                            }
                          </div>
                        </div>
                      </div>
                    ))
                  )
                )}

                <div className="d-flex justify-content-end gap-3">
                  <div className="payment-text-bold mt-2 mb-0">Sub Total</div>

                  <div
                    className={`payment-text-bold mt-2 mb-0 ${
                      total_line_price < 0 ? "text-danger" : "text-success"
                    }`}
                  >
                    {total_line_price === 0
                      ? total_line_price?.toLocaleString()
                      : total_line_price?.toLocaleString()}
                  </div>
                </div>

                <div className="divider"></div>
                <div className="d-flex justify-content-end">
                  {" "}
                  <IconButton
                    variant="text"
                    size="small"
                    sx={{
                      zIndex: 1,
                      backgroundColor: "#fff",
                      color: "#186569",
                      width: 40,
                      height: 40,

                      "&:hover": {
                        backgroundColor: "#186569",
                        color: "#fff",
                        width: 40,
                        height: 40,
                      },

                      marginBottom: "3px",
                    }}
                    onClick={handleCreateOrderModal}
                  >
                    <ModeEditOutlinedIcon />
                  </IconButton>
                </div>
              </div>
              {CheckProjectPermission(
                "delete_order",
                response,
                userinfo,
                roleRoles
              ) ? (
                <div className="d-flex justify-content-center mt-3">
                  <button
                    type="submit"
                    className="supplier-remove-btn"
                    onClick={(e) => confirmationHandler(e)}
                  >
                    Remove Order
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          )
        ) : (
          <div className=" po-summary-table col-md-8 ">
            <NoRecord />
            {CheckProjectPermission(
              "delete_order",
              response,
              userinfo,
              roleRoles
            ) ? (
              <div className="d-flex justify-content-center mt-3">
                <button
                  type="submit"
                  className="supplier-remove-btn"
                  onClick={(e) => confirmationHandler(e)}
                >
                  Remove Order
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        )}

        <div className="col-md-4">
          {etaDetail?.data?.map((items) => (
            <>
              <OrderDescription setMessages={setMessage} />
              <CurrentStatus setMessages={setMessage} />
              <div className="bill-card p-1">
                {!showProduct && (
                  <div className="d-flex gap-4 justify-content-center align-items-center">
                    <div className="h4-pri mt-2 text-center">
                      {items?.order_type}
                    </div>
                    <IconButton
                      variant="text"
                      size="small"
                      sx={{
                        zIndex: 1,
                        backgroundColor: "#fff",
                        color: "#186569",

                        "&:hover": {
                          backgroundColor: "#186569",
                          color: "#fff",
                        },
                      }}
                      disabled
                      onClick={() => setShowProduct((prev) => !prev)}
                    >
                      <ModeEditOutlinedIcon />
                    </IconButton>
                  </div>
                )}
                {showProduct && (
                  <>
                    {" "}
                    <div className="d-flex  justify-content-center gap-2 px-2">
                      <input
                        defaultChecked={is_off}
                        type="checkbox"
                        className="stock-checkmark"
                        onClick={() => setOffshore(!is_off)}
                      />
                      <span className="h6">Off Shore</span>
                    </div>
                    <div className="d-grid justify-content-center gap-3">
                      <select
                        value={order_type ? order_type : previousOrderType}
                        name=""
                        id=""
                        className="select-option-create-order d-flex justify-content-center "
                        onChange={(e) => setOrderType(e.target.value)}
                      >
                        <option selected="true" disabled="disabled">
                          Choose Order Type
                        </option>
                        {orderTypes?.map((items) => (
                          <option value={items?.name}>{items?.name}</option>
                        ))}
                      </select>

                      <div className="d-flex justify-content-center gap-3">
                        <IconButton
                          variant="text"
                          size="small"
                          sx={{
                            zIndex: 1,
                            backgroundColor: "#fff",
                            color: "#186569",

                            "&:hover": {
                              backgroundColor: "#186569",
                              color: "#fff",
                            },
                          }}
                          onClick={HandleUpdateProduct}
                        >
                          <CheckIcon />
                        </IconButton>
                        <IconButton
                          variant="text"
                          size="small"
                          sx={{
                            zIndex: 1,
                            backgroundColor: "#fff",
                            color: "#186569",

                            "&:hover": {
                              backgroundColor: "#186569",
                              color: "#fff",
                            },
                          }}
                          onClick={() => setShowProduct((prev) => !prev)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </div>
                  </>
                )}
                <div className="lc-label mb-2 text-center">Order Type</div>
              </div>
              <div className="bill-card p-1 mt-2">
                <div className="">
                  {" "}
                  <div className="d-flex justify-content-center align-items-center gap-5 ">
                    {!show && !showedit && (
                      <Tooltip title={items?.bill_to?.distributor_name}>
                        {items?.bill_to !== null ? (
                          <div className="h5-pri">
                            {items?.bill_to?.distributor_name?.length >= 20
                              ? items?.bill_to?.distributor_name?.slice(0, 18) +
                                "..."
                              : items?.bill_to?.distributor_name}
                          </div>
                        ) : (
                          <div className="h5-pri ">Unknown</div>
                        )}
                      </Tooltip>
                    )}
                    {show && (
                      <div className="input-groups">
                        <select
                          name=""
                          id=""
                          className="select-option-create-order "
                          onChange={(e) => setbill(e.target.value)}
                        >
                          {" "}
                          <option selected="true" disabled="disabled">
                            Choose Bill To
                          </option>
                          {suppliersList?.map((items) => (
                            <option value={items.value}>{items?.label}</option>
                          ))}
                        </select>
                      </div>
                    )}

                    {showedit && (
                      <>
                        <div className="input-groups">
                          <select
                            defaultValue={items.bill_to.id}
                            id=""
                            className="select-option-create-order "
                            onChange={(e) => setbill(e.target.value)}
                          >
                            {" "}
                            <option selected="true" disabled="disabled">
                              Choose Bill To
                            </option>
                            {suppliersList?.map((items) => (
                              <option value={items.value}>
                                {items?.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        <IconButton
                          variant="text"
                          size="small"
                          sx={{
                            zIndex: 1,
                            backgroundColor: "#fff",
                            color: "#186569",

                            "&:hover": {
                              backgroundColor: "#186569",
                              color: "#fff",
                            },
                            marginLeft: "-30px",
                            marginBottom: "3px",
                          }}
                        >
                          <ModeEditOutlinedIcon onClick={HandleUpdateSubmit} />
                        </IconButton>
                        <IconButton
                          variant="text"
                          size="small"
                          sx={{
                            zIndex: 1,
                            backgroundColor: "#fff",
                            color: "#186569",

                            "&:hover": {
                              backgroundColor: "#186569",
                              color: "#fff",
                            },
                            marginLeft: "-30px",
                            marginBottom: "3px",
                          }}
                          onClick={() => setShowEdit((prev) => !prev)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </>
                    )}

                    {items?.bill_to !== null ? (
                      <>
                        {" "}
                        {CheckPermission("create_order", userinfo)
                          ? !showedit && (
                              <IconButton
                                variant="text"
                                size="small"
                                sx={{
                                  zIndex: 1,
                                  backgroundColor: "#fff",
                                  color: "#186569",

                                  "&:hover": {
                                    backgroundColor: "#186569",
                                    color: "#fff",
                                  },
                                  marginLeft: "-30px",
                                  marginBottom: "3px",
                                }}
                              >
                                <ModeEditOutlinedIcon
                                  onClick={() => setShowEdit((prev) => !prev)}
                                />
                              </IconButton>
                            )
                          : ""}
                      </>
                    ) : (
                      <>
                        {CheckProjectPermission(
                          "create_order",
                          projectmembers,
                          userinfo,
                          roleRoles
                        )
                          ? !show && (
                              <IconButton
                                variant="text"
                                size="small"
                                sx={{
                                  zIndex: 1,
                                  backgroundColor: "#fff",
                                  color: "#186569",

                                  "&:hover": {
                                    backgroundColor: "#186569",
                                    color: "#fff",
                                  },
                                  marginLeft: "-30px",
                                }}
                                onClick={() => setShow((prev) => !prev)}
                              >
                                <AddIcon />
                              </IconButton>
                            )
                          : ""}

                        {show && (
                          <>
                            <IconButton
                              variant="text"
                              size="small"
                              sx={{
                                zIndex: 1,
                                backgroundColor: "#fff",
                                color: "#186569",

                                "&:hover": {
                                  backgroundColor: "#186569",
                                  color: "#fff",
                                },
                                marginLeft: "-30px",
                                marginBottom: "3px",
                              }}
                              onClick={HandleCreateSubmit}
                            >
                              <CheckIcon />
                            </IconButton>
                            <IconButton
                              variant="text"
                              size="small"
                              sx={{
                                zIndex: 1,
                                backgroundColor: "#fff",
                                color: "#186569",

                                "&:hover": {
                                  backgroundColor: "#186569",
                                  color: "#fff",
                                },
                                marginLeft: "-30px",
                                marginBottom: "3px",
                              }}
                              onClick={() => setShow((prev) => !prev)}
                            >
                              <CloseIcon />
                            </IconButton>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="lc-label mb-2 text-center">Bill To</div>
              </div>
              <div className="bill-card p-1 mt-2">
                {!showStage && (
                  <div className="d-flex gap-4 justify-content-center align-items-center">
                    <div className="h4-pri mt-2 text-center">
                      {items?.status?.status_name}
                    </div>
                    <IconButton
                      variant="text"
                      size="small"
                      sx={{
                        zIndex: 1,
                        backgroundColor: "#fff",
                        color: "#186569",

                        "&:hover": {
                          backgroundColor: "#186569",
                          color: "#fff",
                        },
                      }}
                      onClick={() => setShowStage((prev) => !prev)}
                    >
                      <ModeEditOutlinedIcon />
                    </IconButton>
                  </div>
                )}
                {showStage && (
                  <>
                    <div className="d-grid justify-content-center gap-3">
                      <select
                        value={stageType ? stageType : previousStageType}
                        name=""
                        id=""
                        className="select-option-create-order d-flex justify-content-center "
                        onChange={(e) => setStageType(e.target.value)}
                      >
                        <option selected="true" disabled="disabled">
                          Choose Stage
                        </option>
                        {sorcingStages?.data?.map((items) => (
                          <option value={items?.id}>
                            {items?.status_name}
                          </option>
                        ))}
                      </select>

                      <div className="d-flex justify-content-center gap-3">
                        <IconButton
                          variant="text"
                          size="small"
                          sx={{
                            zIndex: 1,
                            backgroundColor: "#fff",
                            color: "#186569",

                            "&:hover": {
                              backgroundColor: "#186569",
                              color: "#fff",
                            },
                          }}
                          onClick={HandleUpdateStage}
                        >
                          <CheckIcon />
                        </IconButton>
                        <IconButton
                          variant="text"
                          size="small"
                          sx={{
                            zIndex: 1,
                            backgroundColor: "#fff",
                            color: "#186569",

                            "&:hover": {
                              backgroundColor: "#186569",
                              color: "#fff",
                            },
                          }}
                          onClick={() => setShowStage((prev) => !prev)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </div>
                  </>
                )}
                <div className="lc-label mb-2 text-center">Stage</div>
              </div>{" "}
              {items?.order_type === "Service" ? (
                <div className="bill-card p-1 mt-2">
                  {!showBatch && (
                    <div className="d-flex gap-4 justify-content-center align-items-center">
                      <div className="h4-pri mt-2 text-center">
                        {/* {batchDetail?.data[0]?.batch_name} */}
                        {items?.batch?.batch_name}
                      </div>
                      <IconButton
                        variant="text"
                        size="small"
                        sx={{
                          zIndex: 1,
                          backgroundColor: "#fff",
                          color: "#186569",

                          "&:hover": {
                            backgroundColor: "#186569",
                            color: "#fff",
                          },
                        }}
                        onClick={() => setShowBatch((prev) => !prev)}
                      >
                        <ModeEditOutlinedIcon />
                      </IconButton>
                    </div>
                  )}
                  {showBatch && (
                    <>
                      <div className="d-grid justify-content-center gap-3">
                        <select
                          value={stageType ? stageType : previousStageType}
                          name=""
                          id=""
                          className="select-option-create-order d-flex justify-content-center "
                          onChange={(e) => setBatchType(e.target.value)}
                        >
                          <option selected="true" disabled="disabled">
                            Choose Batch
                          </option>
                          {batchDetail?.data?.map((items) => (
                            <option value={items?.id}>
                              {items?.batch_name}
                            </option>
                          ))}
                        </select>

                        <div className="d-flex justify-content-center gap-3">
                          <IconButton
                            variant="text"
                            size="small"
                            sx={{
                              zIndex: 1,
                              backgroundColor: "#fff",
                              color: "#186569",

                              "&:hover": {
                                backgroundColor: "#186569",
                                color: "#fff",
                              },
                            }}
                            onClick={HandleUpdateBatch}
                          >
                            <CheckIcon />
                          </IconButton>
                          <IconButton
                            variant="text"
                            size="small"
                            sx={{
                              zIndex: 1,
                              backgroundColor: "#fff",
                              color: "#186569",

                              "&:hover": {
                                backgroundColor: "#186569",
                                color: "#fff",
                              },
                            }}
                            onClick={() => setShowBatch((prev) => !prev)}
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="lc-label mb-2 text-center">Batch</div>
                </div>
              ) : (
                ""
              )}
            </>
          ))}
        </div>
      </div>
      {confirmation ? (
        <div
          id="Modal"
          className="dialog-modal "
          onClick={(e) => {
            setConfirmation(false);
          }}
        >
          <div className="card p-5" onClick={(e) => e.stopPropagation()}>
            <div className="dialog-info text-center">
              Are you sure to Delete
              <div className="prg text-center mt-2">
                {etaDetail?.data[0]?.order_name}
              </div>
            </div>
            <div className="d-flex justify-content-center gap-3 mt-4">
              <div
                className="dialog-cancel-btn"
                onClick={(e) => {
                  setConfirmation(false);
                }}
              >
                Cancel
              </div>
              <button
                type={"submit"}
                onClick={HandleDelete}
                className="dialog-delete-btn"
              >
                Delete order
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {createOrderModalToggle ? (
        <EditPi
          modal={setCreateOrderModalToggle}
          setMessage={props?.setMessage}
          project_id={etaDetail?.data[0]?.project?.id}
          supplier_id={etaDetail?.data[0]?.supplier_id}
          Manufacturer_id={etaDetail?.data[0]?.pis[0]?.manufacurer_id}
        />
      ) : (
        <></>
      )}
    </SourcingIndex>
  );
}
