import React, { useState } from "react";
import Pagination from "@mui/material/Pagination";
import { ClipLoader } from "react-spinners";
import moment from "moment";

const OverallPaymentSummary = ({
  Suppliers,
  unpaidPrfs,
  selectedRows,
  handleRowSelect,
  handleSelectionSubmit,
  submitting,
}) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);

  React.useEffect(() => {
    setLoading(!Suppliers);
  }, [Suppliers]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <div>
      {loading ? (
        <div className="justify-content-center ">
          <ClipLoader size={"10px"} />
          <div className="prg">Loading ...</div>
        </div>
      ) : (
        <div class="table-responsive border m-[20px]">
          <table className="table">
            <thead>
              <tr style={{ backgroundColor: "rgba(86, 184, 183, 0.2)" }}>
                <th>Select</th>
                <th>Supplier Name</th>
                <th>Project</th>
                <th>Amount</th>
                <th>Created at</th>
                <th>Reason </th>
              </tr>
            </thead>
            <tbody>
              {(rowsPerPage > 0
                ? unpaidPrfs?.data?.slice(
                    (page - 1) * rowsPerPage,
                    page * rowsPerPage
                  ) ?? []
                : unpaidPrfs?.data ?? []
              ).map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedRows.includes(item.id)}
                        onChange={() => handleRowSelect(item.id)}
                      />
                    </td>
                    <td>{item?.order?.supplier?.distributor_name}</td>
                    <td>{item.order?.project?.project_name}</td>
                    <td>{item?.total_payment}</td>
                    <td>{moment(item?.created_at).format("MMMM D, YYYY")}</td>
                    <td>{item?.reason_for}</td>
                  </tr>
                );
                // }
              })}
            </tbody>
          </table>

          {selectedRows.length > 0 && (
            <>
              {submitting ? (
                <div className="form-save m-3">
                  <ClipLoader size={10} color={"#123abc"} />
                  <div className="prg">Loading ...</div>
                </div>
              ) : (
                <button
                  className="form-save m-3"
                  onClick={handleSelectionSubmit}
                >
                  Mark Selected PRF as paid
                </button>
              )}
            </>
          )}
          <Pagination
            count={Math.ceil(unpaidPrfs?.data?.length / rowsPerPage)}
            page={page}
            className="d-flex justify-content-end mb-3"
            onChange={handleChangePage}
            rowsPerPageOptions={[10, 15, 25]}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setPage(1);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default OverallPaymentSummary;
