import React from "react";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import {
  useCreatePaymentCollectionMutation,
  useGetPaymentCollectionsQuery,
  useGetProjectPaymentTermQuery,
  useGetProjectQuery,
  useGetProjectsQuery,
} from "../../../features/SCMApi";

export default function PaymentCollectionModal(props) {
  const params = useParams();
  const [name, setName] = useState();
  const [date, setDate] = useState();
  const [planed_collection_date, setPlannedCollectionDate] = useState();
  const [project_payment_term_id, setPaymentTerm] = useState();
  const [milestone, setMilestone] = useState(null);
  const [todays_progress, setTodaysProgress] = useState(null);
  const [project, setProject] = useState(null);
  const [next_action, setNextAction] = useState(null);
  const [is_collected_by_date, setIsCollected] = useState(null);
  const pro_id = props?.all != true ? params?.id : project;
  const { data: projectid } = useGetProjectQuery(pro_id);
  const { data: projects } = useGetProjectsQuery();

  function HandleCancel() {
    props.modal(false);
  }
  let form = {
    name,
    project_id: params?.id,
    planed_collection_date,
    project_payment_term_id,
    by_payment_term: true,
    milestone,
  };
  const [CreatePaymentCollection] = useCreatePaymentCollectionMutation();
  const { data: terms } = useGetProjectPaymentTermQuery(params?.id);
  let onshoreTerms = [];
  terms?.data?.map((items) => {
    if (items?.is_offshore == 0) {
      onshoreTerms.push(items);
    }
  });
  function handleSubmit(e) {
    e.preventDefault();
    CreatePaymentCollection(form)
      .unwrap()
      .then((response) => {
        props.modal(false);

      })
      .catch((error) => {

      });
  }

  let milestoneamount = projectid?.data?.milestone_amount;
  let milestonepush = [];
  for (let i = 1; i <= milestoneamount; i++) {
    milestonepush.push({
      id: i,
      name: `milestone ${i}`,
    });
  }
  const [final_paymnet, setFinalPayment] = useState();

  return (
    <div>
      <div id="myModal" className="medium-modal" onClick={HandleCancel}>
        <div
          className="medium-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="create-project-modal-title">
            <div>Add Payment Collection</div>
            <CloseIcon onClick={HandleCancel} />
          </div>
          <form
            className="create-order-form mt-4"
            enctype="multipart/form-data"
            onSubmit={(e) => handleSubmit(e)}
          >
            <div className="order-supplier">
              <div className="input-groups">
                <div className="supplier-input-label">Name</div>
                <input
                  type="text"
                  className={`finance-input-field `}
                  required
                  onChange={(e) => setName(e.target.value)}
                />

                <div className="supplier-input-label w-100">
                  Planned Collection Date
                </div>
                <input
                  type="Date"
                  className={`finance-input-field`}
                  required
                  onChange={(e) => setPlannedCollectionDate(e.target.value)}
                />
                <div className="input-label">Millstone</div>
                <select
                  required
                  name=""
                  id=""
                  className="finance-input-field"
                  onChange={(e) => setMilestone(e.target.value)}
                >
                  <option selected="true" disabled="disabled">
                    Choose Millstone
                  </option>
                  {milestonepush?.map((items) => (
                    <option value={items.id}>{items?.name}</option>
                  ))}
                </select>
                <div className="input-label">Payment Term</div>
                <select
                  required
                  name=""
                  id=""
                  className="finance-input-field"
                  onChange={(e) => setPaymentTerm(e.target.value)}
                >
                  <option selected="true" disabled="disabled">
                    Choose Payment Term
                  </option>
                  {onshoreTerms?.map((items) => (
                    <option value={items.id}>{items?.reason}</option>
                  ))}
                </select>
              </div>
            </div>
            {/* {Number(planned_amount) < Number(actual_amount) ? (
              <label className="prg-error text-danger d-flex">
                Please enter valid Actual Amount
              </label>
            ) : (
              ""
            )} */}
            <div class="form-buttons ">
              <button type="cancel" class="form-cancel" onClick={HandleCancel}>
                Cancel
              </button>
              <button
                // disabled={
                //   Number(planned_amount) < Number(actual_amount) ? true : false
                // }
                type="submit"
                className={
                  // Number(planned_amount) < Number(actual_amount)
                  // ? "form-save bg-secondary text-white"
                  "form-save"
                }
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
