import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import EditProjectModal from "./EditProjectModal";
import { useState } from "react";

export default function Budget(props) {
  
  const [editProjectModal, setEditProjectModal] = useState(false);
  const [previewsData, setProps] = useState(null);
  return (
    <>
      {editProjectModal ? (
        <EditProjectModal
          ToggleModal={setEditProjectModal}
          previewsData={previewsData}
        />
      ) : (
        <></>
      )}
      <div className="merging-component w-100">
        <div className="budget-container">
          <div className="budget-cash">{`${parseInt(
            props?.budget
          ).toLocaleString()} ${
            props?.currency?.code ? props?.currency?.code : "N/A"
          }`}</div>
          <div className="budget-title">Budget</div>
        </div>
        <div className="divider"></div>
        <div className="budget-container">
          <div className="budget-cash">{`${props.milestones} `}</div>
          <div className="budget-title">Milestones </div>
        </div>
        <div className="divider"></div>

        <div className="budget-container">
          <div className="budget-cash">Forex resource</div>

          <div className=" budget-title">{`${
            props?.forex == 0 ? "By IE Network Solutions" : "By Client"
          } `}</div>
        </div>
        <div className="divider"></div>
        <div className="budget-container">
          <div className="budget-cash">Contract Sign Date</div>
          <div className="budget-title">{props.contract_sign_date} </div>
        </div>
        <div className="budget-container">
          <div className="budget-cash">Project End Date</div>
          <div className="budget-title">
            {props?.data?.end_date == null ? "Not set" : props?.data?.end_date}{" "}
          </div>
        </div>

        <div className="divider"></div>
        {/* ToDoo replace create_project permission to edit_project */}
        {props?.checkPermission(
          "create_project",
          props?.projectmembers,
          props?.userinfo,
          props?.roleRoles
        ) ? (
          <div className="d-flex justify-content-end">
            <button
              className="edit-project-btn me-3"
              onClick={(e) => {
                e.preventDefault();
                setProps(props);
                setEditProjectModal(true);
              }}
            >
              <EditIcon />
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
