import { useState } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import ClearIcon from "@mui/icons-material/Clear";
import "./notificationPage.style.css";
import NavBar from "../../Layout/NavBar";
import { NavLink, useLocation } from "react-router-dom";
import { useEffect } from "react";
import {
  useGetNotificationQuery,
  useReadNotificationMutation,
} from "../../features/SCMApi";
const NotificationPage = ({ showSeen, showNew }) => {
  const location = useLocation();
  const { state } = location;

  const [newNotification, setNewNotification] = useState(null);
  const [seenNotification, setSeenNotification] = useState(null);
  const [newDefaultNotification, setNewDefaultNotification] = useState(true);

  const handelNewNotification = (e) => {
    setSeenNotification(false);
    setNewNotification(true);
  };
  const handelSeenNotification = (e) => {
    setNewNotification(false);
    setSeenNotification(true);
    setNewDefaultNotification(false);
  };

  useEffect(() => {
    if (state?.new === true) {
      setSeenNotification(false);
      setNewNotification(true);
    } else if (state?.seen === true) {
      setNewNotification(false);
      setSeenNotification(true);
      setNewDefaultNotification(false);
    }
  }, [state]);

  const { data: notifications } = useGetNotificationQuery();
  const [readNotification] = useReadNotificationMutation();

  const unreadNotifications =
    notifications &&
    notifications?.filter((notification) => notification?.read_at == null);
  const readNotifications =
    notifications &&
    notifications?.filter((notification) => notification?.read_at !== null);

  const handleLocation = () => {
    window.location.href = "/";
  };
  const handleRead = (id) => {
    const params = { id };


    readNotification(params)
      .unwrap()
      .then((response) => {

      })
      .catch((error) => {

      });
  };

  return (
    <>
      <NavBar />
      <div className="notification-page-container">
        <div className="notification-tab-container">
          <div
            onClick={handelNewNotification}
            className={
              newNotification === true || newDefaultNotification === true
                ? "notification-new-tab"
                : "notification-seen-tab"
            }
            style={{
              borderTopLeftRadius: "30px",
              borderBottomLeftRadius: "30px",
            }}
          >
            New
          </div>
          <div
            onClick={handelSeenNotification}
            className={
              seenNotification === true
                ? "notification-new-tab"
                : "notification-seen-tab"
            }
            style={{
              borderTopRightRadius: "30px",
              borderBottomRightRadius: "30px",
            }}
          >
            Seen
          </div>
        </div>
        {newNotification === true || newDefaultNotification === true ? (
          <div className="notifications-container">
            <div
              className="notification-detail-mark-all-as-read"
              onClick={() => handleRead()}
            >
              Mark all as read
            </div>
            <div className="notifications-list-container">
              {unreadNotifications?.map((notification) => (
                <NavLink
                  onClick={() => handleRead(notification?.id)}
                  to={
                    notification?.data?.type == "Document"
                      ? `/logistics/batches/${notification?.data?.project_id}/batchDetail/${notification?.data?.document_id}/documentPreparation`
                      : notification?.data?.type == "PA"
                      ? `/sourcing/paStageManufacturer/${notification?.data?.order_id}`
                      : notification?.data?.type == "PO"
                      ? `/poStageDetail/${notification?.data?.po_id}`
                      : ""
                  }
                >
                  <div
                    className="single-notification-container detail-page-single-notifications"
                    key={notification?.id}
                  >
                    <div className="notification-value">
                      {notification?.data?.message}{" "}
                    </div>
                    <div className="notification-module-name-and-date">
                      <div>
                        {notification?.data?.type == "Document"
                          ? "Logistics"
                          : "Sourcing"}
                      </div>
                      <div
                        className="notification-module-date-divider"
                        style={{ color: "#353945d3" }}
                      >
                        .
                      </div>
                      <div>
                        {new Date(notification?.created_at)
                          ?.toLocaleString("en-US", {
                            hour12: true,
                          })
                          ?.replace(",", "")}
                      </div>
                    </div>
                    <span
                      className="notification-status-icon"
                      // onClick={() => handleRead(notification?.id)}
                    >
                      <CircleIcon
                        style={{ fontSize: "13px", color: "#FF5252" }}
                      />
                    </span>
                    <span id="cancel" className="notification-status-icon">
                      <ClearIcon
                        style={{
                          fontSize: "13px",
                          color: "#FF5252",
                          fontWeight: "bolder",
                        }}
                        onClick={() => handleRead(notification?.id)}
                      />
                    </span>
                  </div>
                </NavLink>
              ))}
            </div>
          </div>
        ) : (
          <></>
        )}
        {seenNotification === true ? (
          <div className="notifications-container">
            <div className="notifications-list-container">
              {readNotifications?.map((notification) => (
                <NavLink
                  onClick={() => handleRead(notification?.id)}
                  to={
                    notification?.data?.type == "Document"
                      ? `/logistics/batches/${notification?.data?.project_id}/batchDetail/${notification?.data?.document_id}/documentPreparation`
                      : notification?.data?.type == "PA"
                      ? `/sourcing/paStageManufacturer/${notification?.data?.order_id}`
                      : notification?.data?.type == "PO"
                      ? `/poStageDetail/${notification?.data?.po_id}`
                      : ""
                  }
                >
                  <div
                    className="single-notification-container detail-page-single-notifications-for-seen"
                    key={notification?.id}
                  >
                    <div className="notification-value">
                      {notification?.data?.message}{" "}
                    </div>
                    <div className="notification-module-name-and-date">
                      <div>
                        {notification?.data?.type == "Document"
                          ? "Logistics"
                          : "Sourcing"}
                      </div>
                      <div
                        className="notification-module-date-divider"
                        style={{ color: "#353945d3" }}
                      >
                        .
                      </div>
                      <div>
                        {" "}
                        {new Date(notification?.created_at)
                          ?.toLocaleString("en-US", {
                            hour12: true,
                          })
                          ?.replace(",", "")}
                      </div>
                    </div>
                  </div>
                </NavLink>
              ))}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default NotificationPage;
