//

import React, { useState } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const MyComponent = () => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = (event) => {
    setSelectedOptions(event.target.value);
  };

  return (
    <FormControl>
      <InputLabel id="multi-select-label">Select Options</InputLabel>
      <Select
        labelId="multi-select-label"
        id="multi-select"
        multiple
        value={selectedOptions}
        onChange={handleChange}
      >
        <MenuItem value="option1">Option 1</MenuItem>
        <MenuItem value="option2">Option 2</MenuItem>
        <MenuItem value="option3">Option 3</MenuItem>
        {/* Add more menu items as needed */}
      </Select>
    </FormControl>
  );
};

export default MyComponent;
