import React, { useState } from "react";
import usePagination from "../../Pagination/Pagination";
import { Pagination } from "@mui/material";
import NoRecord from "../../common/NoRecord";
import { FaSearch } from "react-icons/fa";

const Revenue = ({ data }) => {
  let [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const PER_PAGE = 6;

  const filteredData = () => {
    return data
      ?.filter((items) => {
        if (search === "") {
          return items;
        } else if (items?.name?.toLowerCase().includes(search.toLowerCase())) {
          return items;
        }
      })
      .map((items) => items);
  };
  const _DATA = usePagination(filteredData(), PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const count = Math.ceil(filteredData().length / PER_PAGE);

  return (
    <div>
      <div className="input-group m-4">
        <div className="form-outline" data-mdb-input-init>
          <div style={{ position: "relative", width: "250px" }}>
            <input
              type="text"
              value={search}
              onChange={(e) => handleSearchChange(e)}
              placeholder="Enter task name ..."
              style={{
                width: "100%",
              }}
            />
          </div>
        </div>
        <button
          onClick={handleSearchChange}
          type="button"
          style={{ width: "30px", background: "#186569", color: "white" }}
          className="btn btn-success"
          data-mdb-ripple-init
        >
          <FaSearch />
        </button>
      </div>
      <div className="row">
        {_DATA?.currentData() && _DATA.currentData().length > 0 ? (
          _DATA.currentData().map((items) => (
            <div className="col-sm-6" key={items.id}>
              <div className="card m-2">
                <div
                  className="card-body d-flex"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "start",
                    }}
                  >
                    <p className="prg" style={{ fontSize: "12px" }}>
                      <strong>Task Name: </strong> {items?.name}
                    </p>
                    <p className="prg" style={{ fontSize: "12px" }}>
                      <strong>Revenue Date: </strong> {items?.revenue_date}
                    </p>
                    <p className="prg" style={{ fontSize: "12px" }}>
                      <strong>Amount:</strong>
                      {items?.actual_amount
                        ? (items?.actual_amount).toLocaleString()
                        : "-"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <NoRecord />
        )}
      </div>

      {count > 1 ? (
        <Pagination
          style={{
            display: "flex",
            justifyContent: "right",
            marginTop: "20px",
          }}
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Revenue;
