import React, { useEffect, useState } from "react";
import PayableHistorySummaryTable from "./PayableHistorySummaryTable";
import PayableHistoryFilter from "./PayableHistoryFilter";
import { API_BASE_URL } from "../../../api/endPoint";
import axios from "axios";

export default function PayableFilteredHistoryTable() {
  const [searchCurrency, setSearchCurrency] = useState("");
  const [searchProject, setSearchProject] = useState("");
  const [searchSupplier, setSearchSupplier] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [payableHistoryData, setPayableHistoryData] = useState([]);

  const agings = [
    { type: "current_upcoming" },
    { type: "1_30" },
    { type: "31_60" },
    { type: "61_90" },
    { type: "91_120" },
    { type: "121_150" },
    { type: "150_and_above" },
  ];

  let counter = 0;
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/scm/sourcing/supplier/payment-history_filtered`)
      .then((response) => {
        setPayableHistoryData(response?.data?.data);

        
      })
      .catch((error) => {});
  }, []);

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setCurrentPage(0);
  };
  useEffect(() => {
   
  }, [selectedFilter]);

  return (
    <div>
      <PayableHistoryFilter
        searchCurrency={searchCurrency}
        searchProject={searchProject}
        searchSupplier={searchSupplier}
        selectedFilter={selectedFilter}
        handleSearchSubmit={handleSearchSubmit}
        setSearchCurrency={setSearchCurrency}
        setSearchProject={setSearchProject}
        setSearchSupplier={setSearchSupplier}
        setSelectedFilter={setSelectedFilter}
      />

      {searchCurrency || searchProject || selectedFilter || searchSupplier ? (
        <div class="table-responsive border m-[20px]">
          <table class="table">
            <thead>
              <tr style={{ backgroundColor: "rgba(86, 184, 183, 0.2)" }}>
                <th>No.</th>
                <th>Project Name</th>
                <th>Supplier Name</th>
                <th>Amount</th>
                <th>Currency</th>
                <th>Due date</th>
                <th>Actual payment date</th>
                <th>Age of payable</th>
                <th>Previous Status</th>
                <th>Current Status</th>
                <th>Reason</th>
              </tr>
            </thead>
            <tbody>
              {payableHistoryData?.map((payables) => {
                if (
                  (payables?.currency_value &&
                    payables?.currency_value
                      .toLowerCase()
                      .includes(searchCurrency.toLowerCase()) &&
                    (searchProject === "" ||
                      payables.project_id === searchProject) &&
                    (searchSupplier === "" ||
                      payables.supplier_name === searchSupplier) &&
                    selectedFilter === "") ||
                  new Date(payables.Paid_date) >= new Date(selectedFilter)
                ) {
                  const dueDate = new Date(payables.Due_date);
                  const selectedDate = new Date(selectedFilter);
                  const timeDifference =
                    selectedDate.getTime() - dueDate.getTime();
                  const daysDifference = timeDifference / (1000 * 3600 * 24);
                  const aging =
                    daysDifference > 0 ? Math.floor(daysDifference) : 0;

                  const paidDate = new Date(payables.Paid_date);
                  const currentStatus = "Paid";
                  const previousStatus =
                    selectedDate > paidDate ? "Paid" : "Unpaid";

                  counter++;
                  return (
                    <tr>
                      <td>{counter}</td>
                      <td>{payables.project_name}</td>
                      <td>{payables.supplier_name}</td>
                      <td>{payables.amount.toLocaleString()}</td>
                      <td>
                        {payables.currency_value === "Other"
                          ? "Unknown"
                          : payables.currency_value}
                      </td>
                      <td>{payables.Due_date}</td>
                      <td>{payables.Paid_date}</td>
                      <td>{aging}</td>
                      <td>{previousStatus}</td>
                      <td>{currentStatus}</td>
                      <td>{payables.Reason}</td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <PayableHistorySummaryTable />
      )}
    </div>
  );
}
